/* eslint-disable */

/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Alert = $root.Alert = (() => {

    /**
     * Properties of an Alert.
     * @exports IAlert
     * @interface IAlert
     * @property {string|null} [body] Alert body
     * @property {Alert.Format|null} [format] Alert format
     * @property {string|null} [icon] Alert icon
     */

    /**
     * Constructs a new Alert.
     * @exports Alert
     * @classdesc Represents an Alert.
     * @implements IAlert
     * @constructor
     * @param {IAlert=} [properties] Properties to set
     */
    function Alert(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Alert body.
     * @member {string} body
     * @memberof Alert
     * @instance
     */
    Alert.prototype.body = "";

    /**
     * Alert format.
     * @member {Alert.Format} format
     * @memberof Alert
     * @instance
     */
    Alert.prototype.format = 0;

    /**
     * Alert icon.
     * @member {string} icon
     * @memberof Alert
     * @instance
     */
    Alert.prototype.icon = "";

    /**
     * Creates a new Alert instance using the specified properties.
     * @function create
     * @memberof Alert
     * @static
     * @param {IAlert=} [properties] Properties to set
     * @returns {Alert} Alert instance
     */
    Alert.create = function create(properties) {
        return new Alert(properties);
    };

    /**
     * Encodes the specified Alert message. Does not implicitly {@link Alert.verify|verify} messages.
     * @function encode
     * @memberof Alert
     * @static
     * @param {IAlert} message Alert message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Alert.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.format);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.icon);
        return writer;
    };

    /**
     * Encodes the specified Alert message, length delimited. Does not implicitly {@link Alert.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Alert
     * @static
     * @param {IAlert} message Alert message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Alert.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Alert message from the specified reader or buffer.
     * @function decode
     * @memberof Alert
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Alert} Alert
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Alert.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Alert();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            case 2: {
                    message.format = reader.int32();
                    break;
                }
            case 3: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Alert message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Alert
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Alert} Alert
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Alert.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Alert message.
     * @function verify
     * @memberof Alert
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Alert.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.format != null && message.hasOwnProperty("format"))
            switch (message.format) {
            default:
                return "format: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        return null;
    };

    /**
     * Creates an Alert message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Alert
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Alert} Alert
     */
    Alert.fromObject = function fromObject(object) {
        if (object instanceof $root.Alert)
            return object;
        let message = new $root.Alert();
        if (object.body != null)
            message.body = String(object.body);
        switch (object.format) {
        default:
            if (typeof object.format === "number") {
                message.format = object.format;
                break;
            }
            break;
        case "UNUSED":
        case 0:
            message.format = 0;
            break;
        case "ERROR":
        case 1:
            message.format = 1;
            break;
        case "WARNING":
        case 2:
            message.format = 2;
            break;
        case "INFO":
        case 3:
            message.format = 3;
            break;
        case "SUCCESS":
        case 4:
            message.format = 4;
            break;
        }
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    /**
     * Creates a plain object from an Alert message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Alert
     * @static
     * @param {Alert} message Alert
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Alert.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.body = "";
            object.format = options.enums === String ? "UNUSED" : 0;
            object.icon = "";
        }
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.format != null && message.hasOwnProperty("format"))
            object.format = options.enums === String ? $root.Alert.Format[message.format] === undefined ? message.format : $root.Alert.Format[message.format] : message.format;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    /**
     * Converts this Alert to JSON.
     * @function toJSON
     * @memberof Alert
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Alert.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Alert
     * @function getTypeUrl
     * @memberof Alert
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Alert.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Alert";
    };

    /**
     * Format enum.
     * @name Alert.Format
     * @enum {number}
     * @property {number} UNUSED=0 UNUSED value
     * @property {number} ERROR=1 ERROR value
     * @property {number} WARNING=2 WARNING value
     * @property {number} INFO=3 INFO value
     * @property {number} SUCCESS=4 SUCCESS value
     */
    Alert.Format = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNUSED"] = 0;
        values[valuesById[1] = "ERROR"] = 1;
        values[valuesById[2] = "WARNING"] = 2;
        values[valuesById[3] = "INFO"] = 3;
        values[valuesById[4] = "SUCCESS"] = 4;
        return values;
    })();

    return Alert;
})();

export const AppPage = $root.AppPage = (() => {

    /**
     * Properties of an AppPage.
     * @exports IAppPage
     * @interface IAppPage
     * @property {string|null} [pageScriptHash] AppPage pageScriptHash
     * @property {string|null} [pageName] AppPage pageName
     * @property {string|null} [icon] AppPage icon
     * @property {boolean|null} [isDefault] AppPage isDefault
     * @property {string|null} [sectionHeader] AppPage sectionHeader
     * @property {string|null} [urlPathname] AppPage urlPathname
     */

    /**
     * Constructs a new AppPage.
     * @exports AppPage
     * @classdesc Represents an AppPage.
     * @implements IAppPage
     * @constructor
     * @param {IAppPage=} [properties] Properties to set
     */
    function AppPage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AppPage pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.pageScriptHash = "";

    /**
     * AppPage pageName.
     * @member {string} pageName
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.pageName = "";

    /**
     * AppPage icon.
     * @member {string} icon
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.icon = "";

    /**
     * AppPage isDefault.
     * @member {boolean} isDefault
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.isDefault = false;

    /**
     * AppPage sectionHeader.
     * @member {string} sectionHeader
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.sectionHeader = "";

    /**
     * AppPage urlPathname.
     * @member {string} urlPathname
     * @memberof AppPage
     * @instance
     */
    AppPage.prototype.urlPathname = "";

    /**
     * Creates a new AppPage instance using the specified properties.
     * @function create
     * @memberof AppPage
     * @static
     * @param {IAppPage=} [properties] Properties to set
     * @returns {AppPage} AppPage instance
     */
    AppPage.create = function create(properties) {
        return new AppPage(properties);
    };

    /**
     * Encodes the specified AppPage message. Does not implicitly {@link AppPage.verify|verify} messages.
     * @function encode
     * @memberof AppPage
     * @static
     * @param {IAppPage} message AppPage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AppPage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.pageScriptHash);
        if (message.pageName != null && Object.hasOwnProperty.call(message, "pageName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pageName);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.icon);
        if (message.isDefault != null && Object.hasOwnProperty.call(message, "isDefault"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDefault);
        if (message.sectionHeader != null && Object.hasOwnProperty.call(message, "sectionHeader"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.sectionHeader);
        if (message.urlPathname != null && Object.hasOwnProperty.call(message, "urlPathname"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.urlPathname);
        return writer;
    };

    /**
     * Encodes the specified AppPage message, length delimited. Does not implicitly {@link AppPage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AppPage
     * @static
     * @param {IAppPage} message AppPage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AppPage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AppPage message from the specified reader or buffer.
     * @function decode
     * @memberof AppPage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AppPage} AppPage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AppPage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AppPage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 2: {
                    message.pageName = reader.string();
                    break;
                }
            case 3: {
                    message.icon = reader.string();
                    break;
                }
            case 4: {
                    message.isDefault = reader.bool();
                    break;
                }
            case 5: {
                    message.sectionHeader = reader.string();
                    break;
                }
            case 6: {
                    message.urlPathname = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AppPage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AppPage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AppPage} AppPage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AppPage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AppPage message.
     * @function verify
     * @memberof AppPage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AppPage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            if (!$util.isString(message.pageName))
                return "pageName: string expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
            if (typeof message.isDefault !== "boolean")
                return "isDefault: boolean expected";
        if (message.sectionHeader != null && message.hasOwnProperty("sectionHeader"))
            if (!$util.isString(message.sectionHeader))
                return "sectionHeader: string expected";
        if (message.urlPathname != null && message.hasOwnProperty("urlPathname"))
            if (!$util.isString(message.urlPathname))
                return "urlPathname: string expected";
        return null;
    };

    /**
     * Creates an AppPage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AppPage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AppPage} AppPage
     */
    AppPage.fromObject = function fromObject(object) {
        if (object instanceof $root.AppPage)
            return object;
        let message = new $root.AppPage();
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.pageName != null)
            message.pageName = String(object.pageName);
        if (object.icon != null)
            message.icon = String(object.icon);
        if (object.isDefault != null)
            message.isDefault = Boolean(object.isDefault);
        if (object.sectionHeader != null)
            message.sectionHeader = String(object.sectionHeader);
        if (object.urlPathname != null)
            message.urlPathname = String(object.urlPathname);
        return message;
    };

    /**
     * Creates a plain object from an AppPage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AppPage
     * @static
     * @param {AppPage} message AppPage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AppPage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.pageScriptHash = "";
            object.pageName = "";
            object.icon = "";
            object.isDefault = false;
            object.sectionHeader = "";
            object.urlPathname = "";
        }
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            object.pageName = message.pageName;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
            object.isDefault = message.isDefault;
        if (message.sectionHeader != null && message.hasOwnProperty("sectionHeader"))
            object.sectionHeader = message.sectionHeader;
        if (message.urlPathname != null && message.hasOwnProperty("urlPathname"))
            object.urlPathname = message.urlPathname;
        return object;
    };

    /**
     * Converts this AppPage to JSON.
     * @function toJSON
     * @memberof AppPage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AppPage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AppPage
     * @function getTypeUrl
     * @memberof AppPage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AppPage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AppPage";
    };

    return AppPage;
})();

export const Arrow = $root.Arrow = (() => {

    /**
     * Properties of an Arrow.
     * @exports IArrow
     * @interface IArrow
     * @property {Uint8Array|null} [data] Arrow data
     * @property {IStyler|null} [styler] Arrow styler
     * @property {number|null} [width] Arrow width
     * @property {number|null} [height] Arrow height
     * @property {boolean|null} [useContainerWidth] Arrow useContainerWidth
     * @property {string|null} [id] Arrow id
     * @property {string|null} [columns] Arrow columns
     * @property {Arrow.EditingMode|null} [editingMode] Arrow editingMode
     * @property {boolean|null} [disabled] Arrow disabled
     * @property {string|null} [formId] Arrow formId
     * @property {Array.<string>|null} [columnOrder] Arrow columnOrder
     * @property {Array.<Arrow.SelectionMode>|null} [selectionMode] Arrow selectionMode
     */

    /**
     * Constructs a new Arrow.
     * @exports Arrow
     * @classdesc Represents an Arrow.
     * @implements IArrow
     * @constructor
     * @param {IArrow=} [properties] Properties to set
     */
    function Arrow(properties) {
        this.columnOrder = [];
        this.selectionMode = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Arrow data.
     * @member {Uint8Array} data
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.data = $util.newBuffer([]);

    /**
     * Arrow styler.
     * @member {IStyler|null|undefined} styler
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.styler = null;

    /**
     * Arrow width.
     * @member {number} width
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.width = 0;

    /**
     * Arrow height.
     * @member {number} height
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.height = 0;

    /**
     * Arrow useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.useContainerWidth = false;

    /**
     * Arrow id.
     * @member {string} id
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.id = "";

    /**
     * Arrow columns.
     * @member {string} columns
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.columns = "";

    /**
     * Arrow editingMode.
     * @member {Arrow.EditingMode} editingMode
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.editingMode = 0;

    /**
     * Arrow disabled.
     * @member {boolean} disabled
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.disabled = false;

    /**
     * Arrow formId.
     * @member {string} formId
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.formId = "";

    /**
     * Arrow columnOrder.
     * @member {Array.<string>} columnOrder
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.columnOrder = $util.emptyArray;

    /**
     * Arrow selectionMode.
     * @member {Array.<Arrow.SelectionMode>} selectionMode
     * @memberof Arrow
     * @instance
     */
    Arrow.prototype.selectionMode = $util.emptyArray;

    /**
     * Creates a new Arrow instance using the specified properties.
     * @function create
     * @memberof Arrow
     * @static
     * @param {IArrow=} [properties] Properties to set
     * @returns {Arrow} Arrow instance
     */
    Arrow.create = function create(properties) {
        return new Arrow(properties);
    };

    /**
     * Encodes the specified Arrow message. Does not implicitly {@link Arrow.verify|verify} messages.
     * @function encode
     * @memberof Arrow
     * @static
     * @param {IArrow} message Arrow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Arrow.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
        if (message.styler != null && Object.hasOwnProperty.call(message, "styler"))
            $root.Styler.encode(message.styler, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.height);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useContainerWidth);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.id);
        if (message.columns != null && Object.hasOwnProperty.call(message, "columns"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.columns);
        if (message.editingMode != null && Object.hasOwnProperty.call(message, "editingMode"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.editingMode);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.disabled);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.formId);
        if (message.columnOrder != null && message.columnOrder.length)
            for (let i = 0; i < message.columnOrder.length; ++i)
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.columnOrder[i]);
        if (message.selectionMode != null && message.selectionMode.length) {
            writer.uint32(/* id 12, wireType 2 =*/98).fork();
            for (let i = 0; i < message.selectionMode.length; ++i)
                writer.int32(message.selectionMode[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified Arrow message, length delimited. Does not implicitly {@link Arrow.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Arrow
     * @static
     * @param {IArrow} message Arrow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Arrow.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Arrow message from the specified reader or buffer.
     * @function decode
     * @memberof Arrow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Arrow} Arrow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Arrow.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Arrow();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = reader.bytes();
                    break;
                }
            case 2: {
                    message.styler = $root.Styler.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.width = reader.uint32();
                    break;
                }
            case 4: {
                    message.height = reader.uint32();
                    break;
                }
            case 5: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 6: {
                    message.id = reader.string();
                    break;
                }
            case 7: {
                    message.columns = reader.string();
                    break;
                }
            case 8: {
                    message.editingMode = reader.int32();
                    break;
                }
            case 9: {
                    message.disabled = reader.bool();
                    break;
                }
            case 10: {
                    message.formId = reader.string();
                    break;
                }
            case 11: {
                    if (!(message.columnOrder && message.columnOrder.length))
                        message.columnOrder = [];
                    message.columnOrder.push(reader.string());
                    break;
                }
            case 12: {
                    if (!(message.selectionMode && message.selectionMode.length))
                        message.selectionMode = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.selectionMode.push(reader.int32());
                    } else
                        message.selectionMode.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Arrow message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Arrow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Arrow} Arrow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Arrow.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Arrow message.
     * @function verify
     * @memberof Arrow
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Arrow.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        if (message.styler != null && message.hasOwnProperty("styler")) {
            let error = $root.Styler.verify(message.styler);
            if (error)
                return "styler." + error;
        }
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width))
                return "width: integer expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.columns != null && message.hasOwnProperty("columns"))
            if (!$util.isString(message.columns))
                return "columns: string expected";
        if (message.editingMode != null && message.hasOwnProperty("editingMode"))
            switch (message.editingMode) {
            default:
                return "editingMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.columnOrder != null && message.hasOwnProperty("columnOrder")) {
            if (!Array.isArray(message.columnOrder))
                return "columnOrder: array expected";
            for (let i = 0; i < message.columnOrder.length; ++i)
                if (!$util.isString(message.columnOrder[i]))
                    return "columnOrder: string[] expected";
        }
        if (message.selectionMode != null && message.hasOwnProperty("selectionMode")) {
            if (!Array.isArray(message.selectionMode))
                return "selectionMode: array expected";
            for (let i = 0; i < message.selectionMode.length; ++i)
                switch (message.selectionMode[i]) {
                default:
                    return "selectionMode: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
        }
        return null;
    };

    /**
     * Creates an Arrow message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Arrow
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Arrow} Arrow
     */
    Arrow.fromObject = function fromObject(object) {
        if (object instanceof $root.Arrow)
            return object;
        let message = new $root.Arrow();
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        if (object.styler != null) {
            if (typeof object.styler !== "object")
                throw TypeError(".Arrow.styler: object expected");
            message.styler = $root.Styler.fromObject(object.styler);
        }
        if (object.width != null)
            message.width = object.width >>> 0;
        if (object.height != null)
            message.height = object.height >>> 0;
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.id != null)
            message.id = String(object.id);
        if (object.columns != null)
            message.columns = String(object.columns);
        switch (object.editingMode) {
        default:
            if (typeof object.editingMode === "number") {
                message.editingMode = object.editingMode;
                break;
            }
            break;
        case "READ_ONLY":
        case 0:
            message.editingMode = 0;
            break;
        case "FIXED":
        case 1:
            message.editingMode = 1;
            break;
        case "DYNAMIC":
        case 2:
            message.editingMode = 2;
            break;
        }
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.columnOrder) {
            if (!Array.isArray(object.columnOrder))
                throw TypeError(".Arrow.columnOrder: array expected");
            message.columnOrder = [];
            for (let i = 0; i < object.columnOrder.length; ++i)
                message.columnOrder[i] = String(object.columnOrder[i]);
        }
        if (object.selectionMode) {
            if (!Array.isArray(object.selectionMode))
                throw TypeError(".Arrow.selectionMode: array expected");
            message.selectionMode = [];
            for (let i = 0; i < object.selectionMode.length; ++i)
                switch (object.selectionMode[i]) {
                default:
                    if (typeof object.selectionMode[i] === "number") {
                        message.selectionMode[i] = object.selectionMode[i];
                        break;
                    }
                case "SINGLE_ROW":
                case 0:
                    message.selectionMode[i] = 0;
                    break;
                case "MULTI_ROW":
                case 1:
                    message.selectionMode[i] = 1;
                    break;
                case "SINGLE_COLUMN":
                case 2:
                    message.selectionMode[i] = 2;
                    break;
                case "MULTI_COLUMN":
                case 3:
                    message.selectionMode[i] = 3;
                    break;
                }
        }
        return message;
    };

    /**
     * Creates a plain object from an Arrow message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Arrow
     * @static
     * @param {Arrow} message Arrow
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Arrow.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.columnOrder = [];
            object.selectionMode = [];
        }
        if (options.defaults) {
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
            object.styler = null;
            object.width = 0;
            object.height = 0;
            object.useContainerWidth = false;
            object.id = "";
            object.columns = "";
            object.editingMode = options.enums === String ? "READ_ONLY" : 0;
            object.disabled = false;
            object.formId = "";
        }
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        if (message.styler != null && message.hasOwnProperty("styler"))
            object.styler = $root.Styler.toObject(message.styler, options);
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.columns != null && message.hasOwnProperty("columns"))
            object.columns = message.columns;
        if (message.editingMode != null && message.hasOwnProperty("editingMode"))
            object.editingMode = options.enums === String ? $root.Arrow.EditingMode[message.editingMode] === undefined ? message.editingMode : $root.Arrow.EditingMode[message.editingMode] : message.editingMode;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.columnOrder && message.columnOrder.length) {
            object.columnOrder = [];
            for (let j = 0; j < message.columnOrder.length; ++j)
                object.columnOrder[j] = message.columnOrder[j];
        }
        if (message.selectionMode && message.selectionMode.length) {
            object.selectionMode = [];
            for (let j = 0; j < message.selectionMode.length; ++j)
                object.selectionMode[j] = options.enums === String ? $root.Arrow.SelectionMode[message.selectionMode[j]] === undefined ? message.selectionMode[j] : $root.Arrow.SelectionMode[message.selectionMode[j]] : message.selectionMode[j];
        }
        return object;
    };

    /**
     * Converts this Arrow to JSON.
     * @function toJSON
     * @memberof Arrow
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Arrow.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Arrow
     * @function getTypeUrl
     * @memberof Arrow
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Arrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Arrow";
    };

    /**
     * EditingMode enum.
     * @name Arrow.EditingMode
     * @enum {number}
     * @property {number} READ_ONLY=0 READ_ONLY value
     * @property {number} FIXED=1 FIXED value
     * @property {number} DYNAMIC=2 DYNAMIC value
     */
    Arrow.EditingMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "READ_ONLY"] = 0;
        values[valuesById[1] = "FIXED"] = 1;
        values[valuesById[2] = "DYNAMIC"] = 2;
        return values;
    })();

    /**
     * SelectionMode enum.
     * @name Arrow.SelectionMode
     * @enum {number}
     * @property {number} SINGLE_ROW=0 SINGLE_ROW value
     * @property {number} MULTI_ROW=1 MULTI_ROW value
     * @property {number} SINGLE_COLUMN=2 SINGLE_COLUMN value
     * @property {number} MULTI_COLUMN=3 MULTI_COLUMN value
     */
    Arrow.SelectionMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SINGLE_ROW"] = 0;
        values[valuesById[1] = "MULTI_ROW"] = 1;
        values[valuesById[2] = "SINGLE_COLUMN"] = 2;
        values[valuesById[3] = "MULTI_COLUMN"] = 3;
        return values;
    })();

    return Arrow;
})();

export const Styler = $root.Styler = (() => {

    /**
     * Properties of a Styler.
     * @exports IStyler
     * @interface IStyler
     * @property {string|null} [uuid] Styler uuid
     * @property {string|null} [caption] Styler caption
     * @property {string|null} [styles] Styler styles
     * @property {Uint8Array|null} [displayValues] Styler displayValues
     */

    /**
     * Constructs a new Styler.
     * @exports Styler
     * @classdesc Represents a Styler.
     * @implements IStyler
     * @constructor
     * @param {IStyler=} [properties] Properties to set
     */
    function Styler(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Styler uuid.
     * @member {string} uuid
     * @memberof Styler
     * @instance
     */
    Styler.prototype.uuid = "";

    /**
     * Styler caption.
     * @member {string} caption
     * @memberof Styler
     * @instance
     */
    Styler.prototype.caption = "";

    /**
     * Styler styles.
     * @member {string} styles
     * @memberof Styler
     * @instance
     */
    Styler.prototype.styles = "";

    /**
     * Styler displayValues.
     * @member {Uint8Array} displayValues
     * @memberof Styler
     * @instance
     */
    Styler.prototype.displayValues = $util.newBuffer([]);

    /**
     * Creates a new Styler instance using the specified properties.
     * @function create
     * @memberof Styler
     * @static
     * @param {IStyler=} [properties] Properties to set
     * @returns {Styler} Styler instance
     */
    Styler.create = function create(properties) {
        return new Styler(properties);
    };

    /**
     * Encodes the specified Styler message. Does not implicitly {@link Styler.verify|verify} messages.
     * @function encode
     * @memberof Styler
     * @static
     * @param {IStyler} message Styler message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Styler.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.caption);
        if (message.styles != null && Object.hasOwnProperty.call(message, "styles"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.styles);
        if (message.displayValues != null && Object.hasOwnProperty.call(message, "displayValues"))
            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.displayValues);
        return writer;
    };

    /**
     * Encodes the specified Styler message, length delimited. Does not implicitly {@link Styler.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Styler
     * @static
     * @param {IStyler} message Styler message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Styler.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Styler message from the specified reader or buffer.
     * @function decode
     * @memberof Styler
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Styler} Styler
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Styler.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Styler();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uuid = reader.string();
                    break;
                }
            case 2: {
                    message.caption = reader.string();
                    break;
                }
            case 3: {
                    message.styles = reader.string();
                    break;
                }
            case 4: {
                    message.displayValues = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Styler message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Styler
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Styler} Styler
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Styler.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Styler message.
     * @function verify
     * @memberof Styler
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Styler.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            if (!$util.isString(message.uuid))
                return "uuid: string expected";
        if (message.caption != null && message.hasOwnProperty("caption"))
            if (!$util.isString(message.caption))
                return "caption: string expected";
        if (message.styles != null && message.hasOwnProperty("styles"))
            if (!$util.isString(message.styles))
                return "styles: string expected";
        if (message.displayValues != null && message.hasOwnProperty("displayValues"))
            if (!(message.displayValues && typeof message.displayValues.length === "number" || $util.isString(message.displayValues)))
                return "displayValues: buffer expected";
        return null;
    };

    /**
     * Creates a Styler message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Styler
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Styler} Styler
     */
    Styler.fromObject = function fromObject(object) {
        if (object instanceof $root.Styler)
            return object;
        let message = new $root.Styler();
        if (object.uuid != null)
            message.uuid = String(object.uuid);
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.styles != null)
            message.styles = String(object.styles);
        if (object.displayValues != null)
            if (typeof object.displayValues === "string")
                $util.base64.decode(object.displayValues, message.displayValues = $util.newBuffer($util.base64.length(object.displayValues)), 0);
            else if (object.displayValues.length >= 0)
                message.displayValues = object.displayValues;
        return message;
    };

    /**
     * Creates a plain object from a Styler message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Styler
     * @static
     * @param {Styler} message Styler
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Styler.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.uuid = "";
            object.caption = "";
            object.styles = "";
            if (options.bytes === String)
                object.displayValues = "";
            else {
                object.displayValues = [];
                if (options.bytes !== Array)
                    object.displayValues = $util.newBuffer(object.displayValues);
            }
        }
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            object.uuid = message.uuid;
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.styles != null && message.hasOwnProperty("styles"))
            object.styles = message.styles;
        if (message.displayValues != null && message.hasOwnProperty("displayValues"))
            object.displayValues = options.bytes === String ? $util.base64.encode(message.displayValues, 0, message.displayValues.length) : options.bytes === Array ? Array.prototype.slice.call(message.displayValues) : message.displayValues;
        return object;
    };

    /**
     * Converts this Styler to JSON.
     * @function toJSON
     * @memberof Styler
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Styler.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Styler
     * @function getTypeUrl
     * @memberof Styler
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Styler.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Styler";
    };

    return Styler;
})();

export const ArrowNamedDataSet = $root.ArrowNamedDataSet = (() => {

    /**
     * Properties of an ArrowNamedDataSet.
     * @exports IArrowNamedDataSet
     * @interface IArrowNamedDataSet
     * @property {string|null} [name] ArrowNamedDataSet name
     * @property {boolean|null} [hasName] ArrowNamedDataSet hasName
     * @property {IArrow|null} [data] ArrowNamedDataSet data
     */

    /**
     * Constructs a new ArrowNamedDataSet.
     * @exports ArrowNamedDataSet
     * @classdesc Represents an ArrowNamedDataSet.
     * @implements IArrowNamedDataSet
     * @constructor
     * @param {IArrowNamedDataSet=} [properties] Properties to set
     */
    function ArrowNamedDataSet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ArrowNamedDataSet name.
     * @member {string} name
     * @memberof ArrowNamedDataSet
     * @instance
     */
    ArrowNamedDataSet.prototype.name = "";

    /**
     * ArrowNamedDataSet hasName.
     * @member {boolean} hasName
     * @memberof ArrowNamedDataSet
     * @instance
     */
    ArrowNamedDataSet.prototype.hasName = false;

    /**
     * ArrowNamedDataSet data.
     * @member {IArrow|null|undefined} data
     * @memberof ArrowNamedDataSet
     * @instance
     */
    ArrowNamedDataSet.prototype.data = null;

    /**
     * Creates a new ArrowNamedDataSet instance using the specified properties.
     * @function create
     * @memberof ArrowNamedDataSet
     * @static
     * @param {IArrowNamedDataSet=} [properties] Properties to set
     * @returns {ArrowNamedDataSet} ArrowNamedDataSet instance
     */
    ArrowNamedDataSet.create = function create(properties) {
        return new ArrowNamedDataSet(properties);
    };

    /**
     * Encodes the specified ArrowNamedDataSet message. Does not implicitly {@link ArrowNamedDataSet.verify|verify} messages.
     * @function encode
     * @memberof ArrowNamedDataSet
     * @static
     * @param {IArrowNamedDataSet} message ArrowNamedDataSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowNamedDataSet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.Arrow.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.hasName != null && Object.hasOwnProperty.call(message, "hasName"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasName);
        return writer;
    };

    /**
     * Encodes the specified ArrowNamedDataSet message, length delimited. Does not implicitly {@link ArrowNamedDataSet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ArrowNamedDataSet
     * @static
     * @param {IArrowNamedDataSet} message ArrowNamedDataSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowNamedDataSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ArrowNamedDataSet message from the specified reader or buffer.
     * @function decode
     * @memberof ArrowNamedDataSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ArrowNamedDataSet} ArrowNamedDataSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowNamedDataSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ArrowNamedDataSet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.hasName = reader.bool();
                    break;
                }
            case 2: {
                    message.data = $root.Arrow.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ArrowNamedDataSet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ArrowNamedDataSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ArrowNamedDataSet} ArrowNamedDataSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowNamedDataSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ArrowNamedDataSet message.
     * @function verify
     * @memberof ArrowNamedDataSet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ArrowNamedDataSet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.hasName != null && message.hasOwnProperty("hasName"))
            if (typeof message.hasName !== "boolean")
                return "hasName: boolean expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.Arrow.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates an ArrowNamedDataSet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ArrowNamedDataSet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ArrowNamedDataSet} ArrowNamedDataSet
     */
    ArrowNamedDataSet.fromObject = function fromObject(object) {
        if (object instanceof $root.ArrowNamedDataSet)
            return object;
        let message = new $root.ArrowNamedDataSet();
        if (object.name != null)
            message.name = String(object.name);
        if (object.hasName != null)
            message.hasName = Boolean(object.hasName);
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".ArrowNamedDataSet.data: object expected");
            message.data = $root.Arrow.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from an ArrowNamedDataSet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ArrowNamedDataSet
     * @static
     * @param {ArrowNamedDataSet} message ArrowNamedDataSet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ArrowNamedDataSet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            object.data = null;
            object.hasName = false;
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.Arrow.toObject(message.data, options);
        if (message.hasName != null && message.hasOwnProperty("hasName"))
            object.hasName = message.hasName;
        return object;
    };

    /**
     * Converts this ArrowNamedDataSet to JSON.
     * @function toJSON
     * @memberof ArrowNamedDataSet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ArrowNamedDataSet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ArrowNamedDataSet
     * @function getTypeUrl
     * @memberof ArrowNamedDataSet
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ArrowNamedDataSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ArrowNamedDataSet";
    };

    return ArrowNamedDataSet;
})();

export const ArrowVegaLiteChart = $root.ArrowVegaLiteChart = (() => {

    /**
     * Properties of an ArrowVegaLiteChart.
     * @exports IArrowVegaLiteChart
     * @interface IArrowVegaLiteChart
     * @property {string|null} [spec] ArrowVegaLiteChart spec
     * @property {IArrow|null} [data] ArrowVegaLiteChart data
     * @property {Array.<IArrowNamedDataSet>|null} [datasets] ArrowVegaLiteChart datasets
     * @property {boolean|null} [useContainerWidth] ArrowVegaLiteChart useContainerWidth
     * @property {string|null} [theme] ArrowVegaLiteChart theme
     * @property {string|null} [id] ArrowVegaLiteChart id
     * @property {Array.<string>|null} [selectionMode] ArrowVegaLiteChart selectionMode
     * @property {string|null} [formId] ArrowVegaLiteChart formId
     */

    /**
     * Constructs a new ArrowVegaLiteChart.
     * @exports ArrowVegaLiteChart
     * @classdesc Represents an ArrowVegaLiteChart.
     * @implements IArrowVegaLiteChart
     * @constructor
     * @param {IArrowVegaLiteChart=} [properties] Properties to set
     */
    function ArrowVegaLiteChart(properties) {
        this.datasets = [];
        this.selectionMode = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ArrowVegaLiteChart spec.
     * @member {string} spec
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.spec = "";

    /**
     * ArrowVegaLiteChart data.
     * @member {IArrow|null|undefined} data
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.data = null;

    /**
     * ArrowVegaLiteChart datasets.
     * @member {Array.<IArrowNamedDataSet>} datasets
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.datasets = $util.emptyArray;

    /**
     * ArrowVegaLiteChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.useContainerWidth = false;

    /**
     * ArrowVegaLiteChart theme.
     * @member {string} theme
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.theme = "";

    /**
     * ArrowVegaLiteChart id.
     * @member {string} id
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.id = "";

    /**
     * ArrowVegaLiteChart selectionMode.
     * @member {Array.<string>} selectionMode
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.selectionMode = $util.emptyArray;

    /**
     * ArrowVegaLiteChart formId.
     * @member {string} formId
     * @memberof ArrowVegaLiteChart
     * @instance
     */
    ArrowVegaLiteChart.prototype.formId = "";

    /**
     * Creates a new ArrowVegaLiteChart instance using the specified properties.
     * @function create
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {IArrowVegaLiteChart=} [properties] Properties to set
     * @returns {ArrowVegaLiteChart} ArrowVegaLiteChart instance
     */
    ArrowVegaLiteChart.create = function create(properties) {
        return new ArrowVegaLiteChart(properties);
    };

    /**
     * Encodes the specified ArrowVegaLiteChart message. Does not implicitly {@link ArrowVegaLiteChart.verify|verify} messages.
     * @function encode
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {IArrowVegaLiteChart} message ArrowVegaLiteChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowVegaLiteChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.spec);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.Arrow.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.datasets != null && message.datasets.length)
            for (let i = 0; i < message.datasets.length; ++i)
                $root.ArrowNamedDataSet.encode(message.datasets[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useContainerWidth);
        if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.theme);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.id);
        if (message.selectionMode != null && message.selectionMode.length)
            for (let i = 0; i < message.selectionMode.length; ++i)
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.selectionMode[i]);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.formId);
        return writer;
    };

    /**
     * Encodes the specified ArrowVegaLiteChart message, length delimited. Does not implicitly {@link ArrowVegaLiteChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {IArrowVegaLiteChart} message ArrowVegaLiteChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowVegaLiteChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ArrowVegaLiteChart message from the specified reader or buffer.
     * @function decode
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ArrowVegaLiteChart} ArrowVegaLiteChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowVegaLiteChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ArrowVegaLiteChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.spec = reader.string();
                    break;
                }
            case 2: {
                    message.data = $root.Arrow.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    if (!(message.datasets && message.datasets.length))
                        message.datasets = [];
                    message.datasets.push($root.ArrowNamedDataSet.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 6: {
                    message.theme = reader.string();
                    break;
                }
            case 7: {
                    message.id = reader.string();
                    break;
                }
            case 8: {
                    if (!(message.selectionMode && message.selectionMode.length))
                        message.selectionMode = [];
                    message.selectionMode.push(reader.string());
                    break;
                }
            case 9: {
                    message.formId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ArrowVegaLiteChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ArrowVegaLiteChart} ArrowVegaLiteChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowVegaLiteChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ArrowVegaLiteChart message.
     * @function verify
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ArrowVegaLiteChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.spec != null && message.hasOwnProperty("spec"))
            if (!$util.isString(message.spec))
                return "spec: string expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.Arrow.verify(message.data);
            if (error)
                return "data." + error;
        }
        if (message.datasets != null && message.hasOwnProperty("datasets")) {
            if (!Array.isArray(message.datasets))
                return "datasets: array expected";
            for (let i = 0; i < message.datasets.length; ++i) {
                let error = $root.ArrowNamedDataSet.verify(message.datasets[i]);
                if (error)
                    return "datasets." + error;
            }
        }
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.theme != null && message.hasOwnProperty("theme"))
            if (!$util.isString(message.theme))
                return "theme: string expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.selectionMode != null && message.hasOwnProperty("selectionMode")) {
            if (!Array.isArray(message.selectionMode))
                return "selectionMode: array expected";
            for (let i = 0; i < message.selectionMode.length; ++i)
                if (!$util.isString(message.selectionMode[i]))
                    return "selectionMode: string[] expected";
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        return null;
    };

    /**
     * Creates an ArrowVegaLiteChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ArrowVegaLiteChart} ArrowVegaLiteChart
     */
    ArrowVegaLiteChart.fromObject = function fromObject(object) {
        if (object instanceof $root.ArrowVegaLiteChart)
            return object;
        let message = new $root.ArrowVegaLiteChart();
        if (object.spec != null)
            message.spec = String(object.spec);
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".ArrowVegaLiteChart.data: object expected");
            message.data = $root.Arrow.fromObject(object.data);
        }
        if (object.datasets) {
            if (!Array.isArray(object.datasets))
                throw TypeError(".ArrowVegaLiteChart.datasets: array expected");
            message.datasets = [];
            for (let i = 0; i < object.datasets.length; ++i) {
                if (typeof object.datasets[i] !== "object")
                    throw TypeError(".ArrowVegaLiteChart.datasets: object expected");
                message.datasets[i] = $root.ArrowNamedDataSet.fromObject(object.datasets[i]);
            }
        }
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.theme != null)
            message.theme = String(object.theme);
        if (object.id != null)
            message.id = String(object.id);
        if (object.selectionMode) {
            if (!Array.isArray(object.selectionMode))
                throw TypeError(".ArrowVegaLiteChart.selectionMode: array expected");
            message.selectionMode = [];
            for (let i = 0; i < object.selectionMode.length; ++i)
                message.selectionMode[i] = String(object.selectionMode[i]);
        }
        if (object.formId != null)
            message.formId = String(object.formId);
        return message;
    };

    /**
     * Creates a plain object from an ArrowVegaLiteChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {ArrowVegaLiteChart} message ArrowVegaLiteChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ArrowVegaLiteChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.datasets = [];
            object.selectionMode = [];
        }
        if (options.defaults) {
            object.spec = "";
            object.data = null;
            object.useContainerWidth = false;
            object.theme = "";
            object.id = "";
            object.formId = "";
        }
        if (message.spec != null && message.hasOwnProperty("spec"))
            object.spec = message.spec;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.Arrow.toObject(message.data, options);
        if (message.datasets && message.datasets.length) {
            object.datasets = [];
            for (let j = 0; j < message.datasets.length; ++j)
                object.datasets[j] = $root.ArrowNamedDataSet.toObject(message.datasets[j], options);
        }
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.theme != null && message.hasOwnProperty("theme"))
            object.theme = message.theme;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.selectionMode && message.selectionMode.length) {
            object.selectionMode = [];
            for (let j = 0; j < message.selectionMode.length; ++j)
                object.selectionMode[j] = message.selectionMode[j];
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        return object;
    };

    /**
     * Converts this ArrowVegaLiteChart to JSON.
     * @function toJSON
     * @memberof ArrowVegaLiteChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ArrowVegaLiteChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ArrowVegaLiteChart
     * @function getTypeUrl
     * @memberof ArrowVegaLiteChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ArrowVegaLiteChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ArrowVegaLiteChart";
    };

    return ArrowVegaLiteChart;
})();

export const Audio = $root.Audio = (() => {

    /**
     * Properties of an Audio.
     * @exports IAudio
     * @interface IAudio
     * @property {string|null} [url] Audio url
     * @property {number|null} [startTime] Audio startTime
     * @property {number|null} [endTime] Audio endTime
     * @property {boolean|null} [loop] Audio loop
     * @property {boolean|null} [autoplay] Audio autoplay
     * @property {string|null} [id] Audio id
     */

    /**
     * Constructs a new Audio.
     * @exports Audio
     * @classdesc Represents an Audio.
     * @implements IAudio
     * @constructor
     * @param {IAudio=} [properties] Properties to set
     */
    function Audio(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Audio url.
     * @member {string} url
     * @memberof Audio
     * @instance
     */
    Audio.prototype.url = "";

    /**
     * Audio startTime.
     * @member {number} startTime
     * @memberof Audio
     * @instance
     */
    Audio.prototype.startTime = 0;

    /**
     * Audio endTime.
     * @member {number} endTime
     * @memberof Audio
     * @instance
     */
    Audio.prototype.endTime = 0;

    /**
     * Audio loop.
     * @member {boolean} loop
     * @memberof Audio
     * @instance
     */
    Audio.prototype.loop = false;

    /**
     * Audio autoplay.
     * @member {boolean} autoplay
     * @memberof Audio
     * @instance
     */
    Audio.prototype.autoplay = false;

    /**
     * Audio id.
     * @member {string} id
     * @memberof Audio
     * @instance
     */
    Audio.prototype.id = "";

    /**
     * Creates a new Audio instance using the specified properties.
     * @function create
     * @memberof Audio
     * @static
     * @param {IAudio=} [properties] Properties to set
     * @returns {Audio} Audio instance
     */
    Audio.create = function create(properties) {
        return new Audio(properties);
    };

    /**
     * Encodes the specified Audio message. Does not implicitly {@link Audio.verify|verify} messages.
     * @function encode
     * @memberof Audio
     * @static
     * @param {IAudio} message Audio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Audio.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startTime);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.url);
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.endTime);
        if (message.loop != null && Object.hasOwnProperty.call(message, "loop"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.loop);
        if (message.autoplay != null && Object.hasOwnProperty.call(message, "autoplay"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.autoplay);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.id);
        return writer;
    };

    /**
     * Encodes the specified Audio message, length delimited. Does not implicitly {@link Audio.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Audio
     * @static
     * @param {IAudio} message Audio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Audio.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Audio message from the specified reader or buffer.
     * @function decode
     * @memberof Audio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Audio} Audio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Audio.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Audio();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 5: {
                    message.url = reader.string();
                    break;
                }
            case 3: {
                    message.startTime = reader.int32();
                    break;
                }
            case 6: {
                    message.endTime = reader.int32();
                    break;
                }
            case 7: {
                    message.loop = reader.bool();
                    break;
                }
            case 8: {
                    message.autoplay = reader.bool();
                    break;
                }
            case 9: {
                    message.id = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Audio message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Audio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Audio} Audio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Audio.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Audio message.
     * @function verify
     * @memberof Audio
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Audio.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            if (!$util.isInteger(message.startTime))
                return "startTime: integer expected";
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            if (!$util.isInteger(message.endTime))
                return "endTime: integer expected";
        if (message.loop != null && message.hasOwnProperty("loop"))
            if (typeof message.loop !== "boolean")
                return "loop: boolean expected";
        if (message.autoplay != null && message.hasOwnProperty("autoplay"))
            if (typeof message.autoplay !== "boolean")
                return "autoplay: boolean expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        return null;
    };

    /**
     * Creates an Audio message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Audio
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Audio} Audio
     */
    Audio.fromObject = function fromObject(object) {
        if (object instanceof $root.Audio)
            return object;
        let message = new $root.Audio();
        if (object.url != null)
            message.url = String(object.url);
        if (object.startTime != null)
            message.startTime = object.startTime | 0;
        if (object.endTime != null)
            message.endTime = object.endTime | 0;
        if (object.loop != null)
            message.loop = Boolean(object.loop);
        if (object.autoplay != null)
            message.autoplay = Boolean(object.autoplay);
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    /**
     * Creates a plain object from an Audio message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Audio
     * @static
     * @param {Audio} message Audio
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Audio.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.startTime = 0;
            object.url = "";
            object.endTime = 0;
            object.loop = false;
            object.autoplay = false;
            object.id = "";
        }
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = message.startTime;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = message.endTime;
        if (message.loop != null && message.hasOwnProperty("loop"))
            object.loop = message.loop;
        if (message.autoplay != null && message.hasOwnProperty("autoplay"))
            object.autoplay = message.autoplay;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        return object;
    };

    /**
     * Converts this Audio to JSON.
     * @function toJSON
     * @memberof Audio
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Audio.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Audio
     * @function getTypeUrl
     * @memberof Audio
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Audio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Audio";
    };

    return Audio;
})();

export const AudioInput = $root.AudioInput = (() => {

    /**
     * Properties of an AudioInput.
     * @exports IAudioInput
     * @interface IAudioInput
     * @property {string|null} [id] AudioInput id
     * @property {string|null} [label] AudioInput label
     * @property {string|null} [help] AudioInput help
     * @property {string|null} [formId] AudioInput formId
     * @property {boolean|null} [disabled] AudioInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] AudioInput labelVisibility
     */

    /**
     * Constructs a new AudioInput.
     * @exports AudioInput
     * @classdesc Represents an AudioInput.
     * @implements IAudioInput
     * @constructor
     * @param {IAudioInput=} [properties] Properties to set
     */
    function AudioInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AudioInput id.
     * @member {string} id
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.id = "";

    /**
     * AudioInput label.
     * @member {string} label
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.label = "";

    /**
     * AudioInput help.
     * @member {string} help
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.help = "";

    /**
     * AudioInput formId.
     * @member {string} formId
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.formId = "";

    /**
     * AudioInput disabled.
     * @member {boolean} disabled
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.disabled = false;

    /**
     * AudioInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof AudioInput
     * @instance
     */
    AudioInput.prototype.labelVisibility = null;

    /**
     * Creates a new AudioInput instance using the specified properties.
     * @function create
     * @memberof AudioInput
     * @static
     * @param {IAudioInput=} [properties] Properties to set
     * @returns {AudioInput} AudioInput instance
     */
    AudioInput.create = function create(properties) {
        return new AudioInput(properties);
    };

    /**
     * Encodes the specified AudioInput message. Does not implicitly {@link AudioInput.verify|verify} messages.
     * @function encode
     * @memberof AudioInput
     * @static
     * @param {IAudioInput} message AudioInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AudioInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.formId);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AudioInput message, length delimited. Does not implicitly {@link AudioInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AudioInput
     * @static
     * @param {IAudioInput} message AudioInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AudioInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AudioInput message from the specified reader or buffer.
     * @function decode
     * @memberof AudioInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AudioInput} AudioInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AudioInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AudioInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message.help = reader.string();
                    break;
                }
            case 4: {
                    message.formId = reader.string();
                    break;
                }
            case 5: {
                    message.disabled = reader.bool();
                    break;
                }
            case 6: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AudioInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AudioInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AudioInput} AudioInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AudioInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AudioInput message.
     * @function verify
     * @memberof AudioInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AudioInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates an AudioInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AudioInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AudioInput} AudioInput
     */
    AudioInput.fromObject = function fromObject(object) {
        if (object instanceof $root.AudioInput)
            return object;
        let message = new $root.AudioInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".AudioInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from an AudioInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AudioInput
     * @static
     * @param {AudioInput} message AudioInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AudioInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this AudioInput to JSON.
     * @function toJSON
     * @memberof AudioInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AudioInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AudioInput
     * @function getTypeUrl
     * @memberof AudioInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AudioInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AudioInput";
    };

    return AudioInput;
})();

export const LabelVisibilityMessage = $root.LabelVisibilityMessage = (() => {

    /**
     * Properties of a LabelVisibilityMessage.
     * @exports ILabelVisibilityMessage
     * @interface ILabelVisibilityMessage
     * @property {LabelVisibilityMessage.LabelVisibilityOptions|null} [value] LabelVisibilityMessage value
     */

    /**
     * Constructs a new LabelVisibilityMessage.
     * @exports LabelVisibilityMessage
     * @classdesc Represents a LabelVisibilityMessage.
     * @implements ILabelVisibilityMessage
     * @constructor
     * @param {ILabelVisibilityMessage=} [properties] Properties to set
     */
    function LabelVisibilityMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LabelVisibilityMessage value.
     * @member {LabelVisibilityMessage.LabelVisibilityOptions} value
     * @memberof LabelVisibilityMessage
     * @instance
     */
    LabelVisibilityMessage.prototype.value = 0;

    /**
     * Creates a new LabelVisibilityMessage instance using the specified properties.
     * @function create
     * @memberof LabelVisibilityMessage
     * @static
     * @param {ILabelVisibilityMessage=} [properties] Properties to set
     * @returns {LabelVisibilityMessage} LabelVisibilityMessage instance
     */
    LabelVisibilityMessage.create = function create(properties) {
        return new LabelVisibilityMessage(properties);
    };

    /**
     * Encodes the specified LabelVisibilityMessage message. Does not implicitly {@link LabelVisibilityMessage.verify|verify} messages.
     * @function encode
     * @memberof LabelVisibilityMessage
     * @static
     * @param {ILabelVisibilityMessage} message LabelVisibilityMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LabelVisibilityMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
        return writer;
    };

    /**
     * Encodes the specified LabelVisibilityMessage message, length delimited. Does not implicitly {@link LabelVisibilityMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LabelVisibilityMessage
     * @static
     * @param {ILabelVisibilityMessage} message LabelVisibilityMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LabelVisibilityMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LabelVisibilityMessage message from the specified reader or buffer.
     * @function decode
     * @memberof LabelVisibilityMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LabelVisibilityMessage} LabelVisibilityMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LabelVisibilityMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LabelVisibilityMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.value = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LabelVisibilityMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LabelVisibilityMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LabelVisibilityMessage} LabelVisibilityMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LabelVisibilityMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LabelVisibilityMessage message.
     * @function verify
     * @memberof LabelVisibilityMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LabelVisibilityMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.value != null && message.hasOwnProperty("value"))
            switch (message.value) {
            default:
                return "value: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        return null;
    };

    /**
     * Creates a LabelVisibilityMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LabelVisibilityMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LabelVisibilityMessage} LabelVisibilityMessage
     */
    LabelVisibilityMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.LabelVisibilityMessage)
            return object;
        let message = new $root.LabelVisibilityMessage();
        switch (object.value) {
        default:
            if (typeof object.value === "number") {
                message.value = object.value;
                break;
            }
            break;
        case "VISIBLE":
        case 0:
            message.value = 0;
            break;
        case "HIDDEN":
        case 1:
            message.value = 1;
            break;
        case "COLLAPSED":
        case 2:
            message.value = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a LabelVisibilityMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LabelVisibilityMessage
     * @static
     * @param {LabelVisibilityMessage} message LabelVisibilityMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LabelVisibilityMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.value = options.enums === String ? "VISIBLE" : 0;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = options.enums === String ? $root.LabelVisibilityMessage.LabelVisibilityOptions[message.value] === undefined ? message.value : $root.LabelVisibilityMessage.LabelVisibilityOptions[message.value] : message.value;
        return object;
    };

    /**
     * Converts this LabelVisibilityMessage to JSON.
     * @function toJSON
     * @memberof LabelVisibilityMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LabelVisibilityMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LabelVisibilityMessage
     * @function getTypeUrl
     * @memberof LabelVisibilityMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LabelVisibilityMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LabelVisibilityMessage";
    };

    /**
     * LabelVisibilityOptions enum.
     * @name LabelVisibilityMessage.LabelVisibilityOptions
     * @enum {number}
     * @property {number} VISIBLE=0 VISIBLE value
     * @property {number} HIDDEN=1 HIDDEN value
     * @property {number} COLLAPSED=2 COLLAPSED value
     */
    LabelVisibilityMessage.LabelVisibilityOptions = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VISIBLE"] = 0;
        values[valuesById[1] = "HIDDEN"] = 1;
        values[valuesById[2] = "COLLAPSED"] = 2;
        return values;
    })();

    return LabelVisibilityMessage;
})();

export const AutoRerun = $root.AutoRerun = (() => {

    /**
     * Properties of an AutoRerun.
     * @exports IAutoRerun
     * @interface IAutoRerun
     * @property {number|null} [interval] AutoRerun interval
     * @property {string|null} [fragmentId] AutoRerun fragmentId
     */

    /**
     * Constructs a new AutoRerun.
     * @exports AutoRerun
     * @classdesc Represents an AutoRerun.
     * @implements IAutoRerun
     * @constructor
     * @param {IAutoRerun=} [properties] Properties to set
     */
    function AutoRerun(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AutoRerun interval.
     * @member {number} interval
     * @memberof AutoRerun
     * @instance
     */
    AutoRerun.prototype.interval = 0;

    /**
     * AutoRerun fragmentId.
     * @member {string} fragmentId
     * @memberof AutoRerun
     * @instance
     */
    AutoRerun.prototype.fragmentId = "";

    /**
     * Creates a new AutoRerun instance using the specified properties.
     * @function create
     * @memberof AutoRerun
     * @static
     * @param {IAutoRerun=} [properties] Properties to set
     * @returns {AutoRerun} AutoRerun instance
     */
    AutoRerun.create = function create(properties) {
        return new AutoRerun(properties);
    };

    /**
     * Encodes the specified AutoRerun message. Does not implicitly {@link AutoRerun.verify|verify} messages.
     * @function encode
     * @memberof AutoRerun
     * @static
     * @param {IAutoRerun} message AutoRerun message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AutoRerun.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
            writer.uint32(/* id 1, wireType 5 =*/13).float(message.interval);
        if (message.fragmentId != null && Object.hasOwnProperty.call(message, "fragmentId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fragmentId);
        return writer;
    };

    /**
     * Encodes the specified AutoRerun message, length delimited. Does not implicitly {@link AutoRerun.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AutoRerun
     * @static
     * @param {IAutoRerun} message AutoRerun message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AutoRerun.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AutoRerun message from the specified reader or buffer.
     * @function decode
     * @memberof AutoRerun
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AutoRerun} AutoRerun
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AutoRerun.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AutoRerun();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.interval = reader.float();
                    break;
                }
            case 2: {
                    message.fragmentId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AutoRerun message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AutoRerun
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AutoRerun} AutoRerun
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AutoRerun.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AutoRerun message.
     * @function verify
     * @memberof AutoRerun
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AutoRerun.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.interval != null && message.hasOwnProperty("interval"))
            if (typeof message.interval !== "number")
                return "interval: number expected";
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            if (!$util.isString(message.fragmentId))
                return "fragmentId: string expected";
        return null;
    };

    /**
     * Creates an AutoRerun message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AutoRerun
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AutoRerun} AutoRerun
     */
    AutoRerun.fromObject = function fromObject(object) {
        if (object instanceof $root.AutoRerun)
            return object;
        let message = new $root.AutoRerun();
        if (object.interval != null)
            message.interval = Number(object.interval);
        if (object.fragmentId != null)
            message.fragmentId = String(object.fragmentId);
        return message;
    };

    /**
     * Creates a plain object from an AutoRerun message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AutoRerun
     * @static
     * @param {AutoRerun} message AutoRerun
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AutoRerun.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.interval = 0;
            object.fragmentId = "";
        }
        if (message.interval != null && message.hasOwnProperty("interval"))
            object.interval = options.json && !isFinite(message.interval) ? String(message.interval) : message.interval;
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            object.fragmentId = message.fragmentId;
        return object;
    };

    /**
     * Converts this AutoRerun to JSON.
     * @function toJSON
     * @memberof AutoRerun
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AutoRerun.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AutoRerun
     * @function getTypeUrl
     * @memberof AutoRerun
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AutoRerun.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AutoRerun";
    };

    return AutoRerun;
})();

export const BackMsg = $root.BackMsg = (() => {

    /**
     * Properties of a BackMsg.
     * @exports IBackMsg
     * @interface IBackMsg
     * @property {boolean|null} [clearCache] BackMsg clearCache
     * @property {boolean|null} [setRunOnSave] BackMsg setRunOnSave
     * @property {boolean|null} [stopScript] BackMsg stopScript
     * @property {IClientState|null} [rerunScript] BackMsg rerunScript
     * @property {boolean|null} [loadGitInfo] BackMsg loadGitInfo
     * @property {boolean|null} [debugDisconnectWebsocket] BackMsg debugDisconnectWebsocket
     * @property {boolean|null} [debugShutdownRuntime] BackMsg debugShutdownRuntime
     * @property {IFileURLsRequest|null} [fileUrlsRequest] BackMsg fileUrlsRequest
     * @property {boolean|null} [appHeartbeat] BackMsg appHeartbeat
     * @property {string|null} [debugLastBackmsgId] BackMsg debugLastBackmsgId
     */

    /**
     * Constructs a new BackMsg.
     * @exports BackMsg
     * @classdesc Represents a BackMsg.
     * @implements IBackMsg
     * @constructor
     * @param {IBackMsg=} [properties] Properties to set
     */
    function BackMsg(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BackMsg clearCache.
     * @member {boolean|null|undefined} clearCache
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.clearCache = null;

    /**
     * BackMsg setRunOnSave.
     * @member {boolean|null|undefined} setRunOnSave
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.setRunOnSave = null;

    /**
     * BackMsg stopScript.
     * @member {boolean|null|undefined} stopScript
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.stopScript = null;

    /**
     * BackMsg rerunScript.
     * @member {IClientState|null|undefined} rerunScript
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.rerunScript = null;

    /**
     * BackMsg loadGitInfo.
     * @member {boolean|null|undefined} loadGitInfo
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.loadGitInfo = null;

    /**
     * BackMsg debugDisconnectWebsocket.
     * @member {boolean|null|undefined} debugDisconnectWebsocket
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.debugDisconnectWebsocket = null;

    /**
     * BackMsg debugShutdownRuntime.
     * @member {boolean|null|undefined} debugShutdownRuntime
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.debugShutdownRuntime = null;

    /**
     * BackMsg fileUrlsRequest.
     * @member {IFileURLsRequest|null|undefined} fileUrlsRequest
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.fileUrlsRequest = null;

    /**
     * BackMsg appHeartbeat.
     * @member {boolean|null|undefined} appHeartbeat
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.appHeartbeat = null;

    /**
     * BackMsg debugLastBackmsgId.
     * @member {string} debugLastBackmsgId
     * @memberof BackMsg
     * @instance
     */
    BackMsg.prototype.debugLastBackmsgId = "";

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * BackMsg type.
     * @member {"clearCache"|"setRunOnSave"|"stopScript"|"rerunScript"|"loadGitInfo"|"debugDisconnectWebsocket"|"debugShutdownRuntime"|"fileUrlsRequest"|"appHeartbeat"|undefined} type
     * @memberof BackMsg
     * @instance
     */
    Object.defineProperty(BackMsg.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["clearCache", "setRunOnSave", "stopScript", "rerunScript", "loadGitInfo", "debugDisconnectWebsocket", "debugShutdownRuntime", "fileUrlsRequest", "appHeartbeat"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new BackMsg instance using the specified properties.
     * @function create
     * @memberof BackMsg
     * @static
     * @param {IBackMsg=} [properties] Properties to set
     * @returns {BackMsg} BackMsg instance
     */
    BackMsg.create = function create(properties) {
        return new BackMsg(properties);
    };

    /**
     * Encodes the specified BackMsg message. Does not implicitly {@link BackMsg.verify|verify} messages.
     * @function encode
     * @memberof BackMsg
     * @static
     * @param {IBackMsg} message BackMsg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BackMsg.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.clearCache != null && Object.hasOwnProperty.call(message, "clearCache"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clearCache);
        if (message.setRunOnSave != null && Object.hasOwnProperty.call(message, "setRunOnSave"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.setRunOnSave);
        if (message.stopScript != null && Object.hasOwnProperty.call(message, "stopScript"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.stopScript);
        if (message.rerunScript != null && Object.hasOwnProperty.call(message, "rerunScript"))
            $root.ClientState.encode(message.rerunScript, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.loadGitInfo != null && Object.hasOwnProperty.call(message, "loadGitInfo"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.loadGitInfo);
        if (message.debugLastBackmsgId != null && Object.hasOwnProperty.call(message, "debugLastBackmsgId"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.debugLastBackmsgId);
        if (message.debugDisconnectWebsocket != null && Object.hasOwnProperty.call(message, "debugDisconnectWebsocket"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.debugDisconnectWebsocket);
        if (message.debugShutdownRuntime != null && Object.hasOwnProperty.call(message, "debugShutdownRuntime"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.debugShutdownRuntime);
        if (message.fileUrlsRequest != null && Object.hasOwnProperty.call(message, "fileUrlsRequest"))
            $root.FileURLsRequest.encode(message.fileUrlsRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.appHeartbeat != null && Object.hasOwnProperty.call(message, "appHeartbeat"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.appHeartbeat);
        return writer;
    };

    /**
     * Encodes the specified BackMsg message, length delimited. Does not implicitly {@link BackMsg.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BackMsg
     * @static
     * @param {IBackMsg} message BackMsg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BackMsg.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BackMsg message from the specified reader or buffer.
     * @function decode
     * @memberof BackMsg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BackMsg} BackMsg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BackMsg.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BackMsg();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 5: {
                    message.clearCache = reader.bool();
                    break;
                }
            case 6: {
                    message.setRunOnSave = reader.bool();
                    break;
                }
            case 7: {
                    message.stopScript = reader.bool();
                    break;
                }
            case 11: {
                    message.rerunScript = $root.ClientState.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.loadGitInfo = reader.bool();
                    break;
                }
            case 14: {
                    message.debugDisconnectWebsocket = reader.bool();
                    break;
                }
            case 15: {
                    message.debugShutdownRuntime = reader.bool();
                    break;
                }
            case 16: {
                    message.fileUrlsRequest = $root.FileURLsRequest.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.appHeartbeat = reader.bool();
                    break;
                }
            case 13: {
                    message.debugLastBackmsgId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BackMsg message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BackMsg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BackMsg} BackMsg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BackMsg.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BackMsg message.
     * @function verify
     * @memberof BackMsg
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BackMsg.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.clearCache != null && message.hasOwnProperty("clearCache")) {
            properties.type = 1;
            if (typeof message.clearCache !== "boolean")
                return "clearCache: boolean expected";
        }
        if (message.setRunOnSave != null && message.hasOwnProperty("setRunOnSave")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.setRunOnSave !== "boolean")
                return "setRunOnSave: boolean expected";
        }
        if (message.stopScript != null && message.hasOwnProperty("stopScript")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.stopScript !== "boolean")
                return "stopScript: boolean expected";
        }
        if (message.rerunScript != null && message.hasOwnProperty("rerunScript")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ClientState.verify(message.rerunScript);
                if (error)
                    return "rerunScript." + error;
            }
        }
        if (message.loadGitInfo != null && message.hasOwnProperty("loadGitInfo")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.loadGitInfo !== "boolean")
                return "loadGitInfo: boolean expected";
        }
        if (message.debugDisconnectWebsocket != null && message.hasOwnProperty("debugDisconnectWebsocket")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.debugDisconnectWebsocket !== "boolean")
                return "debugDisconnectWebsocket: boolean expected";
        }
        if (message.debugShutdownRuntime != null && message.hasOwnProperty("debugShutdownRuntime")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.debugShutdownRuntime !== "boolean")
                return "debugShutdownRuntime: boolean expected";
        }
        if (message.fileUrlsRequest != null && message.hasOwnProperty("fileUrlsRequest")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.FileURLsRequest.verify(message.fileUrlsRequest);
                if (error)
                    return "fileUrlsRequest." + error;
            }
        }
        if (message.appHeartbeat != null && message.hasOwnProperty("appHeartbeat")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.appHeartbeat !== "boolean")
                return "appHeartbeat: boolean expected";
        }
        if (message.debugLastBackmsgId != null && message.hasOwnProperty("debugLastBackmsgId"))
            if (!$util.isString(message.debugLastBackmsgId))
                return "debugLastBackmsgId: string expected";
        return null;
    };

    /**
     * Creates a BackMsg message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BackMsg
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BackMsg} BackMsg
     */
    BackMsg.fromObject = function fromObject(object) {
        if (object instanceof $root.BackMsg)
            return object;
        let message = new $root.BackMsg();
        if (object.clearCache != null)
            message.clearCache = Boolean(object.clearCache);
        if (object.setRunOnSave != null)
            message.setRunOnSave = Boolean(object.setRunOnSave);
        if (object.stopScript != null)
            message.stopScript = Boolean(object.stopScript);
        if (object.rerunScript != null) {
            if (typeof object.rerunScript !== "object")
                throw TypeError(".BackMsg.rerunScript: object expected");
            message.rerunScript = $root.ClientState.fromObject(object.rerunScript);
        }
        if (object.loadGitInfo != null)
            message.loadGitInfo = Boolean(object.loadGitInfo);
        if (object.debugDisconnectWebsocket != null)
            message.debugDisconnectWebsocket = Boolean(object.debugDisconnectWebsocket);
        if (object.debugShutdownRuntime != null)
            message.debugShutdownRuntime = Boolean(object.debugShutdownRuntime);
        if (object.fileUrlsRequest != null) {
            if (typeof object.fileUrlsRequest !== "object")
                throw TypeError(".BackMsg.fileUrlsRequest: object expected");
            message.fileUrlsRequest = $root.FileURLsRequest.fromObject(object.fileUrlsRequest);
        }
        if (object.appHeartbeat != null)
            message.appHeartbeat = Boolean(object.appHeartbeat);
        if (object.debugLastBackmsgId != null)
            message.debugLastBackmsgId = String(object.debugLastBackmsgId);
        return message;
    };

    /**
     * Creates a plain object from a BackMsg message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BackMsg
     * @static
     * @param {BackMsg} message BackMsg
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BackMsg.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.debugLastBackmsgId = "";
        if (message.clearCache != null && message.hasOwnProperty("clearCache")) {
            object.clearCache = message.clearCache;
            if (options.oneofs)
                object.type = "clearCache";
        }
        if (message.setRunOnSave != null && message.hasOwnProperty("setRunOnSave")) {
            object.setRunOnSave = message.setRunOnSave;
            if (options.oneofs)
                object.type = "setRunOnSave";
        }
        if (message.stopScript != null && message.hasOwnProperty("stopScript")) {
            object.stopScript = message.stopScript;
            if (options.oneofs)
                object.type = "stopScript";
        }
        if (message.rerunScript != null && message.hasOwnProperty("rerunScript")) {
            object.rerunScript = $root.ClientState.toObject(message.rerunScript, options);
            if (options.oneofs)
                object.type = "rerunScript";
        }
        if (message.loadGitInfo != null && message.hasOwnProperty("loadGitInfo")) {
            object.loadGitInfo = message.loadGitInfo;
            if (options.oneofs)
                object.type = "loadGitInfo";
        }
        if (message.debugLastBackmsgId != null && message.hasOwnProperty("debugLastBackmsgId"))
            object.debugLastBackmsgId = message.debugLastBackmsgId;
        if (message.debugDisconnectWebsocket != null && message.hasOwnProperty("debugDisconnectWebsocket")) {
            object.debugDisconnectWebsocket = message.debugDisconnectWebsocket;
            if (options.oneofs)
                object.type = "debugDisconnectWebsocket";
        }
        if (message.debugShutdownRuntime != null && message.hasOwnProperty("debugShutdownRuntime")) {
            object.debugShutdownRuntime = message.debugShutdownRuntime;
            if (options.oneofs)
                object.type = "debugShutdownRuntime";
        }
        if (message.fileUrlsRequest != null && message.hasOwnProperty("fileUrlsRequest")) {
            object.fileUrlsRequest = $root.FileURLsRequest.toObject(message.fileUrlsRequest, options);
            if (options.oneofs)
                object.type = "fileUrlsRequest";
        }
        if (message.appHeartbeat != null && message.hasOwnProperty("appHeartbeat")) {
            object.appHeartbeat = message.appHeartbeat;
            if (options.oneofs)
                object.type = "appHeartbeat";
        }
        return object;
    };

    /**
     * Converts this BackMsg to JSON.
     * @function toJSON
     * @memberof BackMsg
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BackMsg.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BackMsg
     * @function getTypeUrl
     * @memberof BackMsg
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BackMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BackMsg";
    };

    return BackMsg;
})();

export const ClientState = $root.ClientState = (() => {

    /**
     * Properties of a ClientState.
     * @exports IClientState
     * @interface IClientState
     * @property {string|null} [queryString] ClientState queryString
     * @property {IWidgetStates|null} [widgetStates] ClientState widgetStates
     * @property {string|null} [pageScriptHash] ClientState pageScriptHash
     * @property {string|null} [pageName] ClientState pageName
     * @property {string|null} [fragmentId] ClientState fragmentId
     * @property {boolean|null} [isAutoRerun] ClientState isAutoRerun
     */

    /**
     * Constructs a new ClientState.
     * @exports ClientState
     * @classdesc Represents a ClientState.
     * @implements IClientState
     * @constructor
     * @param {IClientState=} [properties] Properties to set
     */
    function ClientState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientState queryString.
     * @member {string} queryString
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.queryString = "";

    /**
     * ClientState widgetStates.
     * @member {IWidgetStates|null|undefined} widgetStates
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.widgetStates = null;

    /**
     * ClientState pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.pageScriptHash = "";

    /**
     * ClientState pageName.
     * @member {string} pageName
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.pageName = "";

    /**
     * ClientState fragmentId.
     * @member {string} fragmentId
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.fragmentId = "";

    /**
     * ClientState isAutoRerun.
     * @member {boolean} isAutoRerun
     * @memberof ClientState
     * @instance
     */
    ClientState.prototype.isAutoRerun = false;

    /**
     * Creates a new ClientState instance using the specified properties.
     * @function create
     * @memberof ClientState
     * @static
     * @param {IClientState=} [properties] Properties to set
     * @returns {ClientState} ClientState instance
     */
    ClientState.create = function create(properties) {
        return new ClientState(properties);
    };

    /**
     * Encodes the specified ClientState message. Does not implicitly {@link ClientState.verify|verify} messages.
     * @function encode
     * @memberof ClientState
     * @static
     * @param {IClientState} message ClientState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.queryString != null && Object.hasOwnProperty.call(message, "queryString"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryString);
        if (message.widgetStates != null && Object.hasOwnProperty.call(message, "widgetStates"))
            $root.WidgetStates.encode(message.widgetStates, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pageScriptHash);
        if (message.pageName != null && Object.hasOwnProperty.call(message, "pageName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageName);
        if (message.fragmentId != null && Object.hasOwnProperty.call(message, "fragmentId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.fragmentId);
        if (message.isAutoRerun != null && Object.hasOwnProperty.call(message, "isAutoRerun"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isAutoRerun);
        return writer;
    };

    /**
     * Encodes the specified ClientState message, length delimited. Does not implicitly {@link ClientState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientState
     * @static
     * @param {IClientState} message ClientState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientState message from the specified reader or buffer.
     * @function decode
     * @memberof ClientState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientState} ClientState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.queryString = reader.string();
                    break;
                }
            case 2: {
                    message.widgetStates = $root.WidgetStates.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 4: {
                    message.pageName = reader.string();
                    break;
                }
            case 5: {
                    message.fragmentId = reader.string();
                    break;
                }
            case 6: {
                    message.isAutoRerun = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientState} ClientState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientState message.
     * @function verify
     * @memberof ClientState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.queryString != null && message.hasOwnProperty("queryString"))
            if (!$util.isString(message.queryString))
                return "queryString: string expected";
        if (message.widgetStates != null && message.hasOwnProperty("widgetStates")) {
            let error = $root.WidgetStates.verify(message.widgetStates);
            if (error)
                return "widgetStates." + error;
        }
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            if (!$util.isString(message.pageName))
                return "pageName: string expected";
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            if (!$util.isString(message.fragmentId))
                return "fragmentId: string expected";
        if (message.isAutoRerun != null && message.hasOwnProperty("isAutoRerun"))
            if (typeof message.isAutoRerun !== "boolean")
                return "isAutoRerun: boolean expected";
        return null;
    };

    /**
     * Creates a ClientState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientState} ClientState
     */
    ClientState.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientState)
            return object;
        let message = new $root.ClientState();
        if (object.queryString != null)
            message.queryString = String(object.queryString);
        if (object.widgetStates != null) {
            if (typeof object.widgetStates !== "object")
                throw TypeError(".ClientState.widgetStates: object expected");
            message.widgetStates = $root.WidgetStates.fromObject(object.widgetStates);
        }
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.pageName != null)
            message.pageName = String(object.pageName);
        if (object.fragmentId != null)
            message.fragmentId = String(object.fragmentId);
        if (object.isAutoRerun != null)
            message.isAutoRerun = Boolean(object.isAutoRerun);
        return message;
    };

    /**
     * Creates a plain object from a ClientState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientState
     * @static
     * @param {ClientState} message ClientState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.queryString = "";
            object.widgetStates = null;
            object.pageScriptHash = "";
            object.pageName = "";
            object.fragmentId = "";
            object.isAutoRerun = false;
        }
        if (message.queryString != null && message.hasOwnProperty("queryString"))
            object.queryString = message.queryString;
        if (message.widgetStates != null && message.hasOwnProperty("widgetStates"))
            object.widgetStates = $root.WidgetStates.toObject(message.widgetStates, options);
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            object.pageName = message.pageName;
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            object.fragmentId = message.fragmentId;
        if (message.isAutoRerun != null && message.hasOwnProperty("isAutoRerun"))
            object.isAutoRerun = message.isAutoRerun;
        return object;
    };

    /**
     * Converts this ClientState to JSON.
     * @function toJSON
     * @memberof ClientState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientState
     * @function getTypeUrl
     * @memberof ClientState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientState";
    };

    return ClientState;
})();

export const WidgetStates = $root.WidgetStates = (() => {

    /**
     * Properties of a WidgetStates.
     * @exports IWidgetStates
     * @interface IWidgetStates
     * @property {Array.<IWidgetState>|null} [widgets] WidgetStates widgets
     */

    /**
     * Constructs a new WidgetStates.
     * @exports WidgetStates
     * @classdesc Represents a WidgetStates.
     * @implements IWidgetStates
     * @constructor
     * @param {IWidgetStates=} [properties] Properties to set
     */
    function WidgetStates(properties) {
        this.widgets = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WidgetStates widgets.
     * @member {Array.<IWidgetState>} widgets
     * @memberof WidgetStates
     * @instance
     */
    WidgetStates.prototype.widgets = $util.emptyArray;

    /**
     * Creates a new WidgetStates instance using the specified properties.
     * @function create
     * @memberof WidgetStates
     * @static
     * @param {IWidgetStates=} [properties] Properties to set
     * @returns {WidgetStates} WidgetStates instance
     */
    WidgetStates.create = function create(properties) {
        return new WidgetStates(properties);
    };

    /**
     * Encodes the specified WidgetStates message. Does not implicitly {@link WidgetStates.verify|verify} messages.
     * @function encode
     * @memberof WidgetStates
     * @static
     * @param {IWidgetStates} message WidgetStates message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WidgetStates.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.widgets != null && message.widgets.length)
            for (let i = 0; i < message.widgets.length; ++i)
                $root.WidgetState.encode(message.widgets[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WidgetStates message, length delimited. Does not implicitly {@link WidgetStates.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WidgetStates
     * @static
     * @param {IWidgetStates} message WidgetStates message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WidgetStates.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WidgetStates message from the specified reader or buffer.
     * @function decode
     * @memberof WidgetStates
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WidgetStates} WidgetStates
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WidgetStates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WidgetStates();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.widgets && message.widgets.length))
                        message.widgets = [];
                    message.widgets.push($root.WidgetState.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WidgetStates message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WidgetStates
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WidgetStates} WidgetStates
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WidgetStates.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WidgetStates message.
     * @function verify
     * @memberof WidgetStates
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WidgetStates.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.widgets != null && message.hasOwnProperty("widgets")) {
            if (!Array.isArray(message.widgets))
                return "widgets: array expected";
            for (let i = 0; i < message.widgets.length; ++i) {
                let error = $root.WidgetState.verify(message.widgets[i]);
                if (error)
                    return "widgets." + error;
            }
        }
        return null;
    };

    /**
     * Creates a WidgetStates message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WidgetStates
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WidgetStates} WidgetStates
     */
    WidgetStates.fromObject = function fromObject(object) {
        if (object instanceof $root.WidgetStates)
            return object;
        let message = new $root.WidgetStates();
        if (object.widgets) {
            if (!Array.isArray(object.widgets))
                throw TypeError(".WidgetStates.widgets: array expected");
            message.widgets = [];
            for (let i = 0; i < object.widgets.length; ++i) {
                if (typeof object.widgets[i] !== "object")
                    throw TypeError(".WidgetStates.widgets: object expected");
                message.widgets[i] = $root.WidgetState.fromObject(object.widgets[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a WidgetStates message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WidgetStates
     * @static
     * @param {WidgetStates} message WidgetStates
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WidgetStates.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.widgets = [];
        if (message.widgets && message.widgets.length) {
            object.widgets = [];
            for (let j = 0; j < message.widgets.length; ++j)
                object.widgets[j] = $root.WidgetState.toObject(message.widgets[j], options);
        }
        return object;
    };

    /**
     * Converts this WidgetStates to JSON.
     * @function toJSON
     * @memberof WidgetStates
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WidgetStates.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WidgetStates
     * @function getTypeUrl
     * @memberof WidgetStates
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WidgetStates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WidgetStates";
    };

    return WidgetStates;
})();

export const WidgetState = $root.WidgetState = (() => {

    /**
     * Properties of a WidgetState.
     * @exports IWidgetState
     * @interface IWidgetState
     * @property {string|null} [id] WidgetState id
     * @property {boolean|null} [triggerValue] WidgetState triggerValue
     * @property {boolean|null} [boolValue] WidgetState boolValue
     * @property {number|null} [doubleValue] WidgetState doubleValue
     * @property {number|Long|null} [intValue] WidgetState intValue
     * @property {string|null} [stringValue] WidgetState stringValue
     * @property {IDoubleArray|null} [doubleArrayValue] WidgetState doubleArrayValue
     * @property {ISInt64Array|null} [intArrayValue] WidgetState intArrayValue
     * @property {IStringArray|null} [stringArrayValue] WidgetState stringArrayValue
     * @property {string|null} [jsonValue] WidgetState jsonValue
     * @property {IArrowTable|null} [arrowValue] WidgetState arrowValue
     * @property {Uint8Array|null} [bytesValue] WidgetState bytesValue
     * @property {IFileUploaderState|null} [fileUploaderStateValue] WidgetState fileUploaderStateValue
     * @property {IStringTriggerValue|null} [stringTriggerValue] WidgetState stringTriggerValue
     */

    /**
     * Constructs a new WidgetState.
     * @exports WidgetState
     * @classdesc Represents a WidgetState.
     * @implements IWidgetState
     * @constructor
     * @param {IWidgetState=} [properties] Properties to set
     */
    function WidgetState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WidgetState id.
     * @member {string} id
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.id = "";

    /**
     * WidgetState triggerValue.
     * @member {boolean|null|undefined} triggerValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.triggerValue = null;

    /**
     * WidgetState boolValue.
     * @member {boolean|null|undefined} boolValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.boolValue = null;

    /**
     * WidgetState doubleValue.
     * @member {number|null|undefined} doubleValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.doubleValue = null;

    /**
     * WidgetState intValue.
     * @member {number|Long|null|undefined} intValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.intValue = null;

    /**
     * WidgetState stringValue.
     * @member {string|null|undefined} stringValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.stringValue = null;

    /**
     * WidgetState doubleArrayValue.
     * @member {IDoubleArray|null|undefined} doubleArrayValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.doubleArrayValue = null;

    /**
     * WidgetState intArrayValue.
     * @member {ISInt64Array|null|undefined} intArrayValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.intArrayValue = null;

    /**
     * WidgetState stringArrayValue.
     * @member {IStringArray|null|undefined} stringArrayValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.stringArrayValue = null;

    /**
     * WidgetState jsonValue.
     * @member {string|null|undefined} jsonValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.jsonValue = null;

    /**
     * WidgetState arrowValue.
     * @member {IArrowTable|null|undefined} arrowValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.arrowValue = null;

    /**
     * WidgetState bytesValue.
     * @member {Uint8Array|null|undefined} bytesValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.bytesValue = null;

    /**
     * WidgetState fileUploaderStateValue.
     * @member {IFileUploaderState|null|undefined} fileUploaderStateValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.fileUploaderStateValue = null;

    /**
     * WidgetState stringTriggerValue.
     * @member {IStringTriggerValue|null|undefined} stringTriggerValue
     * @memberof WidgetState
     * @instance
     */
    WidgetState.prototype.stringTriggerValue = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * WidgetState value.
     * @member {"triggerValue"|"boolValue"|"doubleValue"|"intValue"|"stringValue"|"doubleArrayValue"|"intArrayValue"|"stringArrayValue"|"jsonValue"|"arrowValue"|"bytesValue"|"fileUploaderStateValue"|"stringTriggerValue"|undefined} value
     * @memberof WidgetState
     * @instance
     */
    Object.defineProperty(WidgetState.prototype, "value", {
        get: $util.oneOfGetter($oneOfFields = ["triggerValue", "boolValue", "doubleValue", "intValue", "stringValue", "doubleArrayValue", "intArrayValue", "stringArrayValue", "jsonValue", "arrowValue", "bytesValue", "fileUploaderStateValue", "stringTriggerValue"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new WidgetState instance using the specified properties.
     * @function create
     * @memberof WidgetState
     * @static
     * @param {IWidgetState=} [properties] Properties to set
     * @returns {WidgetState} WidgetState instance
     */
    WidgetState.create = function create(properties) {
        return new WidgetState(properties);
    };

    /**
     * Encodes the specified WidgetState message. Does not implicitly {@link WidgetState.verify|verify} messages.
     * @function encode
     * @memberof WidgetState
     * @static
     * @param {IWidgetState} message WidgetState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WidgetState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.triggerValue != null && Object.hasOwnProperty.call(message, "triggerValue"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.triggerValue);
        if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.boolValue);
        if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.doubleValue);
        if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
            writer.uint32(/* id 5, wireType 0 =*/40).sint64(message.intValue);
        if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.stringValue);
        if (message.doubleArrayValue != null && Object.hasOwnProperty.call(message, "doubleArrayValue"))
            $root.DoubleArray.encode(message.doubleArrayValue, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.intArrayValue != null && Object.hasOwnProperty.call(message, "intArrayValue"))
            $root.SInt64Array.encode(message.intArrayValue, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.stringArrayValue != null && Object.hasOwnProperty.call(message, "stringArrayValue"))
            $root.StringArray.encode(message.stringArrayValue, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.jsonValue != null && Object.hasOwnProperty.call(message, "jsonValue"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonValue);
        if (message.arrowValue != null && Object.hasOwnProperty.call(message, "arrowValue"))
            $root.ArrowTable.encode(message.arrowValue, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.bytesValue != null && Object.hasOwnProperty.call(message, "bytesValue"))
            writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.bytesValue);
        if (message.fileUploaderStateValue != null && Object.hasOwnProperty.call(message, "fileUploaderStateValue"))
            $root.FileUploaderState.encode(message.fileUploaderStateValue, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.stringTriggerValue != null && Object.hasOwnProperty.call(message, "stringTriggerValue"))
            $root.StringTriggerValue.encode(message.stringTriggerValue, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified WidgetState message, length delimited. Does not implicitly {@link WidgetState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WidgetState
     * @static
     * @param {IWidgetState} message WidgetState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WidgetState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WidgetState message from the specified reader or buffer.
     * @function decode
     * @memberof WidgetState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WidgetState} WidgetState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WidgetState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WidgetState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.triggerValue = reader.bool();
                    break;
                }
            case 3: {
                    message.boolValue = reader.bool();
                    break;
                }
            case 4: {
                    message.doubleValue = reader.double();
                    break;
                }
            case 5: {
                    message.intValue = reader.sint64();
                    break;
                }
            case 6: {
                    message.stringValue = reader.string();
                    break;
                }
            case 7: {
                    message.doubleArrayValue = $root.DoubleArray.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.intArrayValue = $root.SInt64Array.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.stringArrayValue = $root.StringArray.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.jsonValue = reader.string();
                    break;
                }
            case 11: {
                    message.arrowValue = $root.ArrowTable.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.bytesValue = reader.bytes();
                    break;
                }
            case 13: {
                    message.fileUploaderStateValue = $root.FileUploaderState.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.stringTriggerValue = $root.StringTriggerValue.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WidgetState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WidgetState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WidgetState} WidgetState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WidgetState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WidgetState message.
     * @function verify
     * @memberof WidgetState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WidgetState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.triggerValue != null && message.hasOwnProperty("triggerValue")) {
            properties.value = 1;
            if (typeof message.triggerValue !== "boolean")
                return "triggerValue: boolean expected";
        }
        if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (typeof message.boolValue !== "boolean")
                return "boolValue: boolean expected";
        }
        if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (typeof message.doubleValue !== "number")
                return "doubleValue: number expected";
        }
        if (message.intValue != null && message.hasOwnProperty("intValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                return "intValue: integer|Long expected";
        }
        if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (!$util.isString(message.stringValue))
                return "stringValue: string expected";
        }
        if (message.doubleArrayValue != null && message.hasOwnProperty("doubleArrayValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.DoubleArray.verify(message.doubleArrayValue);
                if (error)
                    return "doubleArrayValue." + error;
            }
        }
        if (message.intArrayValue != null && message.hasOwnProperty("intArrayValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.SInt64Array.verify(message.intArrayValue);
                if (error)
                    return "intArrayValue." + error;
            }
        }
        if (message.stringArrayValue != null && message.hasOwnProperty("stringArrayValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.StringArray.verify(message.stringArrayValue);
                if (error)
                    return "stringArrayValue." + error;
            }
        }
        if (message.jsonValue != null && message.hasOwnProperty("jsonValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (!$util.isString(message.jsonValue))
                return "jsonValue: string expected";
        }
        if (message.arrowValue != null && message.hasOwnProperty("arrowValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.ArrowTable.verify(message.arrowValue);
                if (error)
                    return "arrowValue." + error;
            }
        }
        if (message.bytesValue != null && message.hasOwnProperty("bytesValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (!(message.bytesValue && typeof message.bytesValue.length === "number" || $util.isString(message.bytesValue)))
                return "bytesValue: buffer expected";
        }
        if (message.fileUploaderStateValue != null && message.hasOwnProperty("fileUploaderStateValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.FileUploaderState.verify(message.fileUploaderStateValue);
                if (error)
                    return "fileUploaderStateValue." + error;
            }
        }
        if (message.stringTriggerValue != null && message.hasOwnProperty("stringTriggerValue")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            {
                let error = $root.StringTriggerValue.verify(message.stringTriggerValue);
                if (error)
                    return "stringTriggerValue." + error;
            }
        }
        return null;
    };

    /**
     * Creates a WidgetState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WidgetState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WidgetState} WidgetState
     */
    WidgetState.fromObject = function fromObject(object) {
        if (object instanceof $root.WidgetState)
            return object;
        let message = new $root.WidgetState();
        if (object.id != null)
            message.id = String(object.id);
        if (object.triggerValue != null)
            message.triggerValue = Boolean(object.triggerValue);
        if (object.boolValue != null)
            message.boolValue = Boolean(object.boolValue);
        if (object.doubleValue != null)
            message.doubleValue = Number(object.doubleValue);
        if (object.intValue != null)
            if ($util.Long)
                (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
            else if (typeof object.intValue === "string")
                message.intValue = parseInt(object.intValue, 10);
            else if (typeof object.intValue === "number")
                message.intValue = object.intValue;
            else if (typeof object.intValue === "object")
                message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
        if (object.stringValue != null)
            message.stringValue = String(object.stringValue);
        if (object.doubleArrayValue != null) {
            if (typeof object.doubleArrayValue !== "object")
                throw TypeError(".WidgetState.doubleArrayValue: object expected");
            message.doubleArrayValue = $root.DoubleArray.fromObject(object.doubleArrayValue);
        }
        if (object.intArrayValue != null) {
            if (typeof object.intArrayValue !== "object")
                throw TypeError(".WidgetState.intArrayValue: object expected");
            message.intArrayValue = $root.SInt64Array.fromObject(object.intArrayValue);
        }
        if (object.stringArrayValue != null) {
            if (typeof object.stringArrayValue !== "object")
                throw TypeError(".WidgetState.stringArrayValue: object expected");
            message.stringArrayValue = $root.StringArray.fromObject(object.stringArrayValue);
        }
        if (object.jsonValue != null)
            message.jsonValue = String(object.jsonValue);
        if (object.arrowValue != null) {
            if (typeof object.arrowValue !== "object")
                throw TypeError(".WidgetState.arrowValue: object expected");
            message.arrowValue = $root.ArrowTable.fromObject(object.arrowValue);
        }
        if (object.bytesValue != null)
            if (typeof object.bytesValue === "string")
                $util.base64.decode(object.bytesValue, message.bytesValue = $util.newBuffer($util.base64.length(object.bytesValue)), 0);
            else if (object.bytesValue.length >= 0)
                message.bytesValue = object.bytesValue;
        if (object.fileUploaderStateValue != null) {
            if (typeof object.fileUploaderStateValue !== "object")
                throw TypeError(".WidgetState.fileUploaderStateValue: object expected");
            message.fileUploaderStateValue = $root.FileUploaderState.fromObject(object.fileUploaderStateValue);
        }
        if (object.stringTriggerValue != null) {
            if (typeof object.stringTriggerValue !== "object")
                throw TypeError(".WidgetState.stringTriggerValue: object expected");
            message.stringTriggerValue = $root.StringTriggerValue.fromObject(object.stringTriggerValue);
        }
        return message;
    };

    /**
     * Creates a plain object from a WidgetState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WidgetState
     * @static
     * @param {WidgetState} message WidgetState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WidgetState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.triggerValue != null && message.hasOwnProperty("triggerValue")) {
            object.triggerValue = message.triggerValue;
            if (options.oneofs)
                object.value = "triggerValue";
        }
        if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
            object.boolValue = message.boolValue;
            if (options.oneofs)
                object.value = "boolValue";
        }
        if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
            object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
            if (options.oneofs)
                object.value = "doubleValue";
        }
        if (message.intValue != null && message.hasOwnProperty("intValue")) {
            if (typeof message.intValue === "number")
                object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
            else
                object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
            if (options.oneofs)
                object.value = "intValue";
        }
        if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
            object.stringValue = message.stringValue;
            if (options.oneofs)
                object.value = "stringValue";
        }
        if (message.doubleArrayValue != null && message.hasOwnProperty("doubleArrayValue")) {
            object.doubleArrayValue = $root.DoubleArray.toObject(message.doubleArrayValue, options);
            if (options.oneofs)
                object.value = "doubleArrayValue";
        }
        if (message.intArrayValue != null && message.hasOwnProperty("intArrayValue")) {
            object.intArrayValue = $root.SInt64Array.toObject(message.intArrayValue, options);
            if (options.oneofs)
                object.value = "intArrayValue";
        }
        if (message.stringArrayValue != null && message.hasOwnProperty("stringArrayValue")) {
            object.stringArrayValue = $root.StringArray.toObject(message.stringArrayValue, options);
            if (options.oneofs)
                object.value = "stringArrayValue";
        }
        if (message.jsonValue != null && message.hasOwnProperty("jsonValue")) {
            object.jsonValue = message.jsonValue;
            if (options.oneofs)
                object.value = "jsonValue";
        }
        if (message.arrowValue != null && message.hasOwnProperty("arrowValue")) {
            object.arrowValue = $root.ArrowTable.toObject(message.arrowValue, options);
            if (options.oneofs)
                object.value = "arrowValue";
        }
        if (message.bytesValue != null && message.hasOwnProperty("bytesValue")) {
            object.bytesValue = options.bytes === String ? $util.base64.encode(message.bytesValue, 0, message.bytesValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.bytesValue) : message.bytesValue;
            if (options.oneofs)
                object.value = "bytesValue";
        }
        if (message.fileUploaderStateValue != null && message.hasOwnProperty("fileUploaderStateValue")) {
            object.fileUploaderStateValue = $root.FileUploaderState.toObject(message.fileUploaderStateValue, options);
            if (options.oneofs)
                object.value = "fileUploaderStateValue";
        }
        if (message.stringTriggerValue != null && message.hasOwnProperty("stringTriggerValue")) {
            object.stringTriggerValue = $root.StringTriggerValue.toObject(message.stringTriggerValue, options);
            if (options.oneofs)
                object.value = "stringTriggerValue";
        }
        return object;
    };

    /**
     * Converts this WidgetState to JSON.
     * @function toJSON
     * @memberof WidgetState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WidgetState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WidgetState
     * @function getTypeUrl
     * @memberof WidgetState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WidgetState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WidgetState";
    };

    return WidgetState;
})();

export const StringArray = $root.StringArray = (() => {

    /**
     * Properties of a StringArray.
     * @exports IStringArray
     * @interface IStringArray
     * @property {Array.<string>|null} [data] StringArray data
     */

    /**
     * Constructs a new StringArray.
     * @exports StringArray
     * @classdesc Represents a StringArray.
     * @implements IStringArray
     * @constructor
     * @param {IStringArray=} [properties] Properties to set
     */
    function StringArray(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StringArray data.
     * @member {Array.<string>} data
     * @memberof StringArray
     * @instance
     */
    StringArray.prototype.data = $util.emptyArray;

    /**
     * Creates a new StringArray instance using the specified properties.
     * @function create
     * @memberof StringArray
     * @static
     * @param {IStringArray=} [properties] Properties to set
     * @returns {StringArray} StringArray instance
     */
    StringArray.create = function create(properties) {
        return new StringArray(properties);
    };

    /**
     * Encodes the specified StringArray message. Does not implicitly {@link StringArray.verify|verify} messages.
     * @function encode
     * @memberof StringArray
     * @static
     * @param {IStringArray} message StringArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StringArray.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length)
            for (let i = 0; i < message.data.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.data[i]);
        return writer;
    };

    /**
     * Encodes the specified StringArray message, length delimited. Does not implicitly {@link StringArray.verify|verify} messages.
     * @function encodeDelimited
     * @memberof StringArray
     * @static
     * @param {IStringArray} message StringArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StringArray.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StringArray message from the specified reader or buffer.
     * @function decode
     * @memberof StringArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StringArray} StringArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StringArray.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StringArray();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a StringArray message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof StringArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {StringArray} StringArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StringArray.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StringArray message.
     * @function verify
     * @memberof StringArray
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StringArray.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (!$util.isString(message.data[i]))
                    return "data: string[] expected";
        }
        return null;
    };

    /**
     * Creates a StringArray message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StringArray
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {StringArray} StringArray
     */
    StringArray.fromObject = function fromObject(object) {
        if (object instanceof $root.StringArray)
            return object;
        let message = new $root.StringArray();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".StringArray.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                message.data[i] = String(object.data[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a StringArray message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StringArray
     * @static
     * @param {StringArray} message StringArray
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StringArray.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                object.data[j] = message.data[j];
        }
        return object;
    };

    /**
     * Converts this StringArray to JSON.
     * @function toJSON
     * @memberof StringArray
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StringArray.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StringArray
     * @function getTypeUrl
     * @memberof StringArray
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StringArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StringArray";
    };

    return StringArray;
})();

export const DoubleArray = $root.DoubleArray = (() => {

    /**
     * Properties of a DoubleArray.
     * @exports IDoubleArray
     * @interface IDoubleArray
     * @property {Array.<number>|null} [data] DoubleArray data
     */

    /**
     * Constructs a new DoubleArray.
     * @exports DoubleArray
     * @classdesc Represents a DoubleArray.
     * @implements IDoubleArray
     * @constructor
     * @param {IDoubleArray=} [properties] Properties to set
     */
    function DoubleArray(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DoubleArray data.
     * @member {Array.<number>} data
     * @memberof DoubleArray
     * @instance
     */
    DoubleArray.prototype.data = $util.emptyArray;

    /**
     * Creates a new DoubleArray instance using the specified properties.
     * @function create
     * @memberof DoubleArray
     * @static
     * @param {IDoubleArray=} [properties] Properties to set
     * @returns {DoubleArray} DoubleArray instance
     */
    DoubleArray.create = function create(properties) {
        return new DoubleArray(properties);
    };

    /**
     * Encodes the specified DoubleArray message. Does not implicitly {@link DoubleArray.verify|verify} messages.
     * @function encode
     * @memberof DoubleArray
     * @static
     * @param {IDoubleArray} message DoubleArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DoubleArray.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (let i = 0; i < message.data.length; ++i)
                writer.double(message.data[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified DoubleArray message, length delimited. Does not implicitly {@link DoubleArray.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DoubleArray
     * @static
     * @param {IDoubleArray} message DoubleArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DoubleArray.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DoubleArray message from the specified reader or buffer.
     * @function decode
     * @memberof DoubleArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DoubleArray} DoubleArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DoubleArray.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DoubleArray();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.data.push(reader.double());
                    } else
                        message.data.push(reader.double());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DoubleArray message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DoubleArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DoubleArray} DoubleArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DoubleArray.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DoubleArray message.
     * @function verify
     * @memberof DoubleArray
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DoubleArray.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (typeof message.data[i] !== "number")
                    return "data: number[] expected";
        }
        return null;
    };

    /**
     * Creates a DoubleArray message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DoubleArray
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DoubleArray} DoubleArray
     */
    DoubleArray.fromObject = function fromObject(object) {
        if (object instanceof $root.DoubleArray)
            return object;
        let message = new $root.DoubleArray();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".DoubleArray.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                message.data[i] = Number(object.data[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a DoubleArray message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DoubleArray
     * @static
     * @param {DoubleArray} message DoubleArray
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DoubleArray.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                object.data[j] = options.json && !isFinite(message.data[j]) ? String(message.data[j]) : message.data[j];
        }
        return object;
    };

    /**
     * Converts this DoubleArray to JSON.
     * @function toJSON
     * @memberof DoubleArray
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DoubleArray.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DoubleArray
     * @function getTypeUrl
     * @memberof DoubleArray
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DoubleArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DoubleArray";
    };

    return DoubleArray;
})();

export const Int32Array = $root.Int32Array = (() => {

    /**
     * Properties of an Int32Array.
     * @exports IInt32Array
     * @interface IInt32Array
     * @property {Array.<number>|null} [data] Int32Array data
     */

    /**
     * Constructs a new Int32Array.
     * @exports Int32Array
     * @classdesc Represents an Int32Array.
     * @implements IInt32Array
     * @constructor
     * @param {IInt32Array=} [properties] Properties to set
     */
    function Int32Array(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Int32Array data.
     * @member {Array.<number>} data
     * @memberof Int32Array
     * @instance
     */
    Int32Array.prototype.data = $util.emptyArray;

    /**
     * Creates a new Int32Array instance using the specified properties.
     * @function create
     * @memberof Int32Array
     * @static
     * @param {IInt32Array=} [properties] Properties to set
     * @returns {Int32Array} Int32Array instance
     */
    Int32Array.create = function create(properties) {
        return new Int32Array(properties);
    };

    /**
     * Encodes the specified Int32Array message. Does not implicitly {@link Int32Array.verify|verify} messages.
     * @function encode
     * @memberof Int32Array
     * @static
     * @param {IInt32Array} message Int32Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int32Array.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (let i = 0; i < message.data.length; ++i)
                writer.int32(message.data[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified Int32Array message, length delimited. Does not implicitly {@link Int32Array.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Int32Array
     * @static
     * @param {IInt32Array} message Int32Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int32Array.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Int32Array message from the specified reader or buffer.
     * @function decode
     * @memberof Int32Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Int32Array} Int32Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int32Array.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Int32Array();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.data.push(reader.int32());
                    } else
                        message.data.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Int32Array message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Int32Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Int32Array} Int32Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int32Array.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Int32Array message.
     * @function verify
     * @memberof Int32Array
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Int32Array.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (!$util.isInteger(message.data[i]))
                    return "data: integer[] expected";
        }
        return null;
    };

    /**
     * Creates an Int32Array message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Int32Array
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Int32Array} Int32Array
     */
    Int32Array.fromObject = function fromObject(object) {
        if (object instanceof $root.Int32Array)
            return object;
        let message = new $root.Int32Array();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".Int32Array.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                message.data[i] = object.data[i] | 0;
        }
        return message;
    };

    /**
     * Creates a plain object from an Int32Array message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Int32Array
     * @static
     * @param {Int32Array} message Int32Array
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Int32Array.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                object.data[j] = message.data[j];
        }
        return object;
    };

    /**
     * Converts this Int32Array to JSON.
     * @function toJSON
     * @memberof Int32Array
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Int32Array.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Int32Array
     * @function getTypeUrl
     * @memberof Int32Array
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Int32Array.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Int32Array";
    };

    return Int32Array;
})();

export const Int64Array = $root.Int64Array = (() => {

    /**
     * Properties of an Int64Array.
     * @exports IInt64Array
     * @interface IInt64Array
     * @property {Array.<number|Long>|null} [data] Int64Array data
     */

    /**
     * Constructs a new Int64Array.
     * @exports Int64Array
     * @classdesc Represents an Int64Array.
     * @implements IInt64Array
     * @constructor
     * @param {IInt64Array=} [properties] Properties to set
     */
    function Int64Array(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Int64Array data.
     * @member {Array.<number|Long>} data
     * @memberof Int64Array
     * @instance
     */
    Int64Array.prototype.data = $util.emptyArray;

    /**
     * Creates a new Int64Array instance using the specified properties.
     * @function create
     * @memberof Int64Array
     * @static
     * @param {IInt64Array=} [properties] Properties to set
     * @returns {Int64Array} Int64Array instance
     */
    Int64Array.create = function create(properties) {
        return new Int64Array(properties);
    };

    /**
     * Encodes the specified Int64Array message. Does not implicitly {@link Int64Array.verify|verify} messages.
     * @function encode
     * @memberof Int64Array
     * @static
     * @param {IInt64Array} message Int64Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int64Array.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (let i = 0; i < message.data.length; ++i)
                writer.int64(message.data[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified Int64Array message, length delimited. Does not implicitly {@link Int64Array.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Int64Array
     * @static
     * @param {IInt64Array} message Int64Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int64Array.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Int64Array message from the specified reader or buffer.
     * @function decode
     * @memberof Int64Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Int64Array} Int64Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int64Array.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Int64Array();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.data.push(reader.int64());
                    } else
                        message.data.push(reader.int64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Int64Array message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Int64Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Int64Array} Int64Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int64Array.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Int64Array message.
     * @function verify
     * @memberof Int64Array
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Int64Array.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (!$util.isInteger(message.data[i]) && !(message.data[i] && $util.isInteger(message.data[i].low) && $util.isInteger(message.data[i].high)))
                    return "data: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates an Int64Array message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Int64Array
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Int64Array} Int64Array
     */
    Int64Array.fromObject = function fromObject(object) {
        if (object instanceof $root.Int64Array)
            return object;
        let message = new $root.Int64Array();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".Int64Array.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                if ($util.Long)
                    (message.data[i] = $util.Long.fromValue(object.data[i])).unsigned = false;
                else if (typeof object.data[i] === "string")
                    message.data[i] = parseInt(object.data[i], 10);
                else if (typeof object.data[i] === "number")
                    message.data[i] = object.data[i];
                else if (typeof object.data[i] === "object")
                    message.data[i] = new $util.LongBits(object.data[i].low >>> 0, object.data[i].high >>> 0).toNumber();
        }
        return message;
    };

    /**
     * Creates a plain object from an Int64Array message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Int64Array
     * @static
     * @param {Int64Array} message Int64Array
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Int64Array.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                if (typeof message.data[j] === "number")
                    object.data[j] = options.longs === String ? String(message.data[j]) : message.data[j];
                else
                    object.data[j] = options.longs === String ? $util.Long.prototype.toString.call(message.data[j]) : options.longs === Number ? new $util.LongBits(message.data[j].low >>> 0, message.data[j].high >>> 0).toNumber() : message.data[j];
        }
        return object;
    };

    /**
     * Converts this Int64Array to JSON.
     * @function toJSON
     * @memberof Int64Array
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Int64Array.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Int64Array
     * @function getTypeUrl
     * @memberof Int64Array
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Int64Array.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Int64Array";
    };

    return Int64Array;
})();

export const SInt64Array = $root.SInt64Array = (() => {

    /**
     * Properties of a SInt64Array.
     * @exports ISInt64Array
     * @interface ISInt64Array
     * @property {Array.<number|Long>|null} [data] SInt64Array data
     */

    /**
     * Constructs a new SInt64Array.
     * @exports SInt64Array
     * @classdesc Represents a SInt64Array.
     * @implements ISInt64Array
     * @constructor
     * @param {ISInt64Array=} [properties] Properties to set
     */
    function SInt64Array(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SInt64Array data.
     * @member {Array.<number|Long>} data
     * @memberof SInt64Array
     * @instance
     */
    SInt64Array.prototype.data = $util.emptyArray;

    /**
     * Creates a new SInt64Array instance using the specified properties.
     * @function create
     * @memberof SInt64Array
     * @static
     * @param {ISInt64Array=} [properties] Properties to set
     * @returns {SInt64Array} SInt64Array instance
     */
    SInt64Array.create = function create(properties) {
        return new SInt64Array(properties);
    };

    /**
     * Encodes the specified SInt64Array message. Does not implicitly {@link SInt64Array.verify|verify} messages.
     * @function encode
     * @memberof SInt64Array
     * @static
     * @param {ISInt64Array} message SInt64Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SInt64Array.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (let i = 0; i < message.data.length; ++i)
                writer.sint64(message.data[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified SInt64Array message, length delimited. Does not implicitly {@link SInt64Array.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SInt64Array
     * @static
     * @param {ISInt64Array} message SInt64Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SInt64Array.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SInt64Array message from the specified reader or buffer.
     * @function decode
     * @memberof SInt64Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SInt64Array} SInt64Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SInt64Array.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SInt64Array();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.data.push(reader.sint64());
                    } else
                        message.data.push(reader.sint64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SInt64Array message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SInt64Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SInt64Array} SInt64Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SInt64Array.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SInt64Array message.
     * @function verify
     * @memberof SInt64Array
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SInt64Array.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (!$util.isInteger(message.data[i]) && !(message.data[i] && $util.isInteger(message.data[i].low) && $util.isInteger(message.data[i].high)))
                    return "data: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a SInt64Array message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SInt64Array
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SInt64Array} SInt64Array
     */
    SInt64Array.fromObject = function fromObject(object) {
        if (object instanceof $root.SInt64Array)
            return object;
        let message = new $root.SInt64Array();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".SInt64Array.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                if ($util.Long)
                    (message.data[i] = $util.Long.fromValue(object.data[i])).unsigned = false;
                else if (typeof object.data[i] === "string")
                    message.data[i] = parseInt(object.data[i], 10);
                else if (typeof object.data[i] === "number")
                    message.data[i] = object.data[i];
                else if (typeof object.data[i] === "object")
                    message.data[i] = new $util.LongBits(object.data[i].low >>> 0, object.data[i].high >>> 0).toNumber();
        }
        return message;
    };

    /**
     * Creates a plain object from a SInt64Array message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SInt64Array
     * @static
     * @param {SInt64Array} message SInt64Array
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SInt64Array.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                if (typeof message.data[j] === "number")
                    object.data[j] = options.longs === String ? String(message.data[j]) : message.data[j];
                else
                    object.data[j] = options.longs === String ? $util.Long.prototype.toString.call(message.data[j]) : options.longs === Number ? new $util.LongBits(message.data[j].low >>> 0, message.data[j].high >>> 0).toNumber() : message.data[j];
        }
        return object;
    };

    /**
     * Converts this SInt64Array to JSON.
     * @function toJSON
     * @memberof SInt64Array
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SInt64Array.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SInt64Array
     * @function getTypeUrl
     * @memberof SInt64Array
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SInt64Array.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SInt64Array";
    };

    return SInt64Array;
})();

export const UInt32Array = $root.UInt32Array = (() => {

    /**
     * Properties of a UInt32Array.
     * @exports IUInt32Array
     * @interface IUInt32Array
     * @property {Array.<number>|null} [data] UInt32Array data
     */

    /**
     * Constructs a new UInt32Array.
     * @exports UInt32Array
     * @classdesc Represents a UInt32Array.
     * @implements IUInt32Array
     * @constructor
     * @param {IUInt32Array=} [properties] Properties to set
     */
    function UInt32Array(properties) {
        this.data = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UInt32Array data.
     * @member {Array.<number>} data
     * @memberof UInt32Array
     * @instance
     */
    UInt32Array.prototype.data = $util.emptyArray;

    /**
     * Creates a new UInt32Array instance using the specified properties.
     * @function create
     * @memberof UInt32Array
     * @static
     * @param {IUInt32Array=} [properties] Properties to set
     * @returns {UInt32Array} UInt32Array instance
     */
    UInt32Array.create = function create(properties) {
        return new UInt32Array(properties);
    };

    /**
     * Encodes the specified UInt32Array message. Does not implicitly {@link UInt32Array.verify|verify} messages.
     * @function encode
     * @memberof UInt32Array
     * @static
     * @param {IUInt32Array} message UInt32Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UInt32Array.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && message.data.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (let i = 0; i < message.data.length; ++i)
                writer.uint32(message.data[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified UInt32Array message, length delimited. Does not implicitly {@link UInt32Array.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UInt32Array
     * @static
     * @param {IUInt32Array} message UInt32Array message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UInt32Array.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UInt32Array message from the specified reader or buffer.
     * @function decode
     * @memberof UInt32Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UInt32Array} UInt32Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UInt32Array.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UInt32Array();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.data && message.data.length))
                        message.data = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.data.push(reader.uint32());
                    } else
                        message.data.push(reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a UInt32Array message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UInt32Array
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UInt32Array} UInt32Array
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UInt32Array.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UInt32Array message.
     * @function verify
     * @memberof UInt32Array
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UInt32Array.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            if (!Array.isArray(message.data))
                return "data: array expected";
            for (let i = 0; i < message.data.length; ++i)
                if (!$util.isInteger(message.data[i]))
                    return "data: integer[] expected";
        }
        return null;
    };

    /**
     * Creates a UInt32Array message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UInt32Array
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UInt32Array} UInt32Array
     */
    UInt32Array.fromObject = function fromObject(object) {
        if (object instanceof $root.UInt32Array)
            return object;
        let message = new $root.UInt32Array();
        if (object.data) {
            if (!Array.isArray(object.data))
                throw TypeError(".UInt32Array.data: array expected");
            message.data = [];
            for (let i = 0; i < object.data.length; ++i)
                message.data[i] = object.data[i] >>> 0;
        }
        return message;
    };

    /**
     * Creates a plain object from a UInt32Array message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UInt32Array
     * @static
     * @param {UInt32Array} message UInt32Array
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UInt32Array.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.data = [];
        if (message.data && message.data.length) {
            object.data = [];
            for (let j = 0; j < message.data.length; ++j)
                object.data[j] = message.data[j];
        }
        return object;
    };

    /**
     * Converts this UInt32Array to JSON.
     * @function toJSON
     * @memberof UInt32Array
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UInt32Array.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UInt32Array
     * @function getTypeUrl
     * @memberof UInt32Array
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UInt32Array.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UInt32Array";
    };

    return UInt32Array;
})();

export const StringTriggerValue = $root.StringTriggerValue = (() => {

    /**
     * Properties of a StringTriggerValue.
     * @exports IStringTriggerValue
     * @interface IStringTriggerValue
     * @property {string|null} [data] StringTriggerValue data
     */

    /**
     * Constructs a new StringTriggerValue.
     * @exports StringTriggerValue
     * @classdesc Represents a StringTriggerValue.
     * @implements IStringTriggerValue
     * @constructor
     * @param {IStringTriggerValue=} [properties] Properties to set
     */
    function StringTriggerValue(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StringTriggerValue data.
     * @member {string|null|undefined} data
     * @memberof StringTriggerValue
     * @instance
     */
    StringTriggerValue.prototype.data = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(StringTriggerValue.prototype, "_data", {
        get: $util.oneOfGetter($oneOfFields = ["data"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new StringTriggerValue instance using the specified properties.
     * @function create
     * @memberof StringTriggerValue
     * @static
     * @param {IStringTriggerValue=} [properties] Properties to set
     * @returns {StringTriggerValue} StringTriggerValue instance
     */
    StringTriggerValue.create = function create(properties) {
        return new StringTriggerValue(properties);
    };

    /**
     * Encodes the specified StringTriggerValue message. Does not implicitly {@link StringTriggerValue.verify|verify} messages.
     * @function encode
     * @memberof StringTriggerValue
     * @static
     * @param {IStringTriggerValue} message StringTriggerValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StringTriggerValue.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.data);
        return writer;
    };

    /**
     * Encodes the specified StringTriggerValue message, length delimited. Does not implicitly {@link StringTriggerValue.verify|verify} messages.
     * @function encodeDelimited
     * @memberof StringTriggerValue
     * @static
     * @param {IStringTriggerValue} message StringTriggerValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StringTriggerValue.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StringTriggerValue message from the specified reader or buffer.
     * @function decode
     * @memberof StringTriggerValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StringTriggerValue} StringTriggerValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StringTriggerValue.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StringTriggerValue();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a StringTriggerValue message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof StringTriggerValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {StringTriggerValue} StringTriggerValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StringTriggerValue.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StringTriggerValue message.
     * @function verify
     * @memberof StringTriggerValue
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StringTriggerValue.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.data != null && message.hasOwnProperty("data")) {
            properties._data = 1;
            if (!$util.isString(message.data))
                return "data: string expected";
        }
        return null;
    };

    /**
     * Creates a StringTriggerValue message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StringTriggerValue
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {StringTriggerValue} StringTriggerValue
     */
    StringTriggerValue.fromObject = function fromObject(object) {
        if (object instanceof $root.StringTriggerValue)
            return object;
        let message = new $root.StringTriggerValue();
        if (object.data != null)
            message.data = String(object.data);
        return message;
    };

    /**
     * Creates a plain object from a StringTriggerValue message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StringTriggerValue
     * @static
     * @param {StringTriggerValue} message StringTriggerValue
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StringTriggerValue.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.data != null && message.hasOwnProperty("data")) {
            object.data = message.data;
            if (options.oneofs)
                object._data = "data";
        }
        return object;
    };

    /**
     * Converts this StringTriggerValue to JSON.
     * @function toJSON
     * @memberof StringTriggerValue
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StringTriggerValue.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StringTriggerValue
     * @function getTypeUrl
     * @memberof StringTriggerValue
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StringTriggerValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StringTriggerValue";
    };

    return StringTriggerValue;
})();

export const FileURLsRequest = $root.FileURLsRequest = (() => {

    /**
     * Properties of a FileURLsRequest.
     * @exports IFileURLsRequest
     * @interface IFileURLsRequest
     * @property {string|null} [requestId] FileURLsRequest requestId
     * @property {Array.<string>|null} [fileNames] FileURLsRequest fileNames
     * @property {string|null} [sessionId] FileURLsRequest sessionId
     */

    /**
     * Constructs a new FileURLsRequest.
     * @exports FileURLsRequest
     * @classdesc Represents a FileURLsRequest.
     * @implements IFileURLsRequest
     * @constructor
     * @param {IFileURLsRequest=} [properties] Properties to set
     */
    function FileURLsRequest(properties) {
        this.fileNames = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileURLsRequest requestId.
     * @member {string} requestId
     * @memberof FileURLsRequest
     * @instance
     */
    FileURLsRequest.prototype.requestId = "";

    /**
     * FileURLsRequest fileNames.
     * @member {Array.<string>} fileNames
     * @memberof FileURLsRequest
     * @instance
     */
    FileURLsRequest.prototype.fileNames = $util.emptyArray;

    /**
     * FileURLsRequest sessionId.
     * @member {string} sessionId
     * @memberof FileURLsRequest
     * @instance
     */
    FileURLsRequest.prototype.sessionId = "";

    /**
     * Creates a new FileURLsRequest instance using the specified properties.
     * @function create
     * @memberof FileURLsRequest
     * @static
     * @param {IFileURLsRequest=} [properties] Properties to set
     * @returns {FileURLsRequest} FileURLsRequest instance
     */
    FileURLsRequest.create = function create(properties) {
        return new FileURLsRequest(properties);
    };

    /**
     * Encodes the specified FileURLsRequest message. Does not implicitly {@link FileURLsRequest.verify|verify} messages.
     * @function encode
     * @memberof FileURLsRequest
     * @static
     * @param {IFileURLsRequest} message FileURLsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
        if (message.fileNames != null && message.fileNames.length)
            for (let i = 0; i < message.fileNames.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileNames[i]);
        if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.sessionId);
        return writer;
    };

    /**
     * Encodes the specified FileURLsRequest message, length delimited. Does not implicitly {@link FileURLsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FileURLsRequest
     * @static
     * @param {IFileURLsRequest} message FileURLsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileURLsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FileURLsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FileURLsRequest} FileURLsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FileURLsRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.requestId = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.fileNames && message.fileNames.length))
                        message.fileNames = [];
                    message.fileNames.push(reader.string());
                    break;
                }
            case 3: {
                    message.sessionId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FileURLsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FileURLsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FileURLsRequest} FileURLsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileURLsRequest message.
     * @function verify
     * @memberof FileURLsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileURLsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.requestId != null && message.hasOwnProperty("requestId"))
            if (!$util.isString(message.requestId))
                return "requestId: string expected";
        if (message.fileNames != null && message.hasOwnProperty("fileNames")) {
            if (!Array.isArray(message.fileNames))
                return "fileNames: array expected";
            for (let i = 0; i < message.fileNames.length; ++i)
                if (!$util.isString(message.fileNames[i]))
                    return "fileNames: string[] expected";
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            if (!$util.isString(message.sessionId))
                return "sessionId: string expected";
        return null;
    };

    /**
     * Creates a FileURLsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FileURLsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FileURLsRequest} FileURLsRequest
     */
    FileURLsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FileURLsRequest)
            return object;
        let message = new $root.FileURLsRequest();
        if (object.requestId != null)
            message.requestId = String(object.requestId);
        if (object.fileNames) {
            if (!Array.isArray(object.fileNames))
                throw TypeError(".FileURLsRequest.fileNames: array expected");
            message.fileNames = [];
            for (let i = 0; i < object.fileNames.length; ++i)
                message.fileNames[i] = String(object.fileNames[i]);
        }
        if (object.sessionId != null)
            message.sessionId = String(object.sessionId);
        return message;
    };

    /**
     * Creates a plain object from a FileURLsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FileURLsRequest
     * @static
     * @param {FileURLsRequest} message FileURLsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileURLsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.fileNames = [];
        if (options.defaults) {
            object.requestId = "";
            object.sessionId = "";
        }
        if (message.requestId != null && message.hasOwnProperty("requestId"))
            object.requestId = message.requestId;
        if (message.fileNames && message.fileNames.length) {
            object.fileNames = [];
            for (let j = 0; j < message.fileNames.length; ++j)
                object.fileNames[j] = message.fileNames[j];
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        return object;
    };

    /**
     * Converts this FileURLsRequest to JSON.
     * @function toJSON
     * @memberof FileURLsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileURLsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileURLsRequest
     * @function getTypeUrl
     * @memberof FileURLsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileURLsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FileURLsRequest";
    };

    return FileURLsRequest;
})();

export const FileURLs = $root.FileURLs = (() => {

    /**
     * Properties of a FileURLs.
     * @exports IFileURLs
     * @interface IFileURLs
     * @property {string|null} [fileId] FileURLs fileId
     * @property {string|null} [uploadUrl] FileURLs uploadUrl
     * @property {string|null} [deleteUrl] FileURLs deleteUrl
     */

    /**
     * Constructs a new FileURLs.
     * @exports FileURLs
     * @classdesc Represents a FileURLs.
     * @implements IFileURLs
     * @constructor
     * @param {IFileURLs=} [properties] Properties to set
     */
    function FileURLs(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileURLs fileId.
     * @member {string} fileId
     * @memberof FileURLs
     * @instance
     */
    FileURLs.prototype.fileId = "";

    /**
     * FileURLs uploadUrl.
     * @member {string} uploadUrl
     * @memberof FileURLs
     * @instance
     */
    FileURLs.prototype.uploadUrl = "";

    /**
     * FileURLs deleteUrl.
     * @member {string} deleteUrl
     * @memberof FileURLs
     * @instance
     */
    FileURLs.prototype.deleteUrl = "";

    /**
     * Creates a new FileURLs instance using the specified properties.
     * @function create
     * @memberof FileURLs
     * @static
     * @param {IFileURLs=} [properties] Properties to set
     * @returns {FileURLs} FileURLs instance
     */
    FileURLs.create = function create(properties) {
        return new FileURLs(properties);
    };

    /**
     * Encodes the specified FileURLs message. Does not implicitly {@link FileURLs.verify|verify} messages.
     * @function encode
     * @memberof FileURLs
     * @static
     * @param {IFileURLs} message FileURLs message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLs.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
        if (message.uploadUrl != null && Object.hasOwnProperty.call(message, "uploadUrl"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.uploadUrl);
        if (message.deleteUrl != null && Object.hasOwnProperty.call(message, "deleteUrl"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.deleteUrl);
        return writer;
    };

    /**
     * Encodes the specified FileURLs message, length delimited. Does not implicitly {@link FileURLs.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FileURLs
     * @static
     * @param {IFileURLs} message FileURLs message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLs.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileURLs message from the specified reader or buffer.
     * @function decode
     * @memberof FileURLs
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FileURLs} FileURLs
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLs.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FileURLs();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.fileId = reader.string();
                    break;
                }
            case 2: {
                    message.uploadUrl = reader.string();
                    break;
                }
            case 3: {
                    message.deleteUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FileURLs message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FileURLs
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FileURLs} FileURLs
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLs.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileURLs message.
     * @function verify
     * @memberof FileURLs
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileURLs.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.fileId != null && message.hasOwnProperty("fileId"))
            if (!$util.isString(message.fileId))
                return "fileId: string expected";
        if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
            if (!$util.isString(message.uploadUrl))
                return "uploadUrl: string expected";
        if (message.deleteUrl != null && message.hasOwnProperty("deleteUrl"))
            if (!$util.isString(message.deleteUrl))
                return "deleteUrl: string expected";
        return null;
    };

    /**
     * Creates a FileURLs message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FileURLs
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FileURLs} FileURLs
     */
    FileURLs.fromObject = function fromObject(object) {
        if (object instanceof $root.FileURLs)
            return object;
        let message = new $root.FileURLs();
        if (object.fileId != null)
            message.fileId = String(object.fileId);
        if (object.uploadUrl != null)
            message.uploadUrl = String(object.uploadUrl);
        if (object.deleteUrl != null)
            message.deleteUrl = String(object.deleteUrl);
        return message;
    };

    /**
     * Creates a plain object from a FileURLs message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FileURLs
     * @static
     * @param {FileURLs} message FileURLs
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileURLs.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.fileId = "";
            object.uploadUrl = "";
            object.deleteUrl = "";
        }
        if (message.fileId != null && message.hasOwnProperty("fileId"))
            object.fileId = message.fileId;
        if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
            object.uploadUrl = message.uploadUrl;
        if (message.deleteUrl != null && message.hasOwnProperty("deleteUrl"))
            object.deleteUrl = message.deleteUrl;
        return object;
    };

    /**
     * Converts this FileURLs to JSON.
     * @function toJSON
     * @memberof FileURLs
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileURLs.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileURLs
     * @function getTypeUrl
     * @memberof FileURLs
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileURLs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FileURLs";
    };

    return FileURLs;
})();

export const FileURLsResponse = $root.FileURLsResponse = (() => {

    /**
     * Properties of a FileURLsResponse.
     * @exports IFileURLsResponse
     * @interface IFileURLsResponse
     * @property {string|null} [responseId] FileURLsResponse responseId
     * @property {Array.<IFileURLs>|null} [fileUrls] FileURLsResponse fileUrls
     * @property {string|null} [errorMsg] FileURLsResponse errorMsg
     */

    /**
     * Constructs a new FileURLsResponse.
     * @exports FileURLsResponse
     * @classdesc Represents a FileURLsResponse.
     * @implements IFileURLsResponse
     * @constructor
     * @param {IFileURLsResponse=} [properties] Properties to set
     */
    function FileURLsResponse(properties) {
        this.fileUrls = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileURLsResponse responseId.
     * @member {string} responseId
     * @memberof FileURLsResponse
     * @instance
     */
    FileURLsResponse.prototype.responseId = "";

    /**
     * FileURLsResponse fileUrls.
     * @member {Array.<IFileURLs>} fileUrls
     * @memberof FileURLsResponse
     * @instance
     */
    FileURLsResponse.prototype.fileUrls = $util.emptyArray;

    /**
     * FileURLsResponse errorMsg.
     * @member {string} errorMsg
     * @memberof FileURLsResponse
     * @instance
     */
    FileURLsResponse.prototype.errorMsg = "";

    /**
     * Creates a new FileURLsResponse instance using the specified properties.
     * @function create
     * @memberof FileURLsResponse
     * @static
     * @param {IFileURLsResponse=} [properties] Properties to set
     * @returns {FileURLsResponse} FileURLsResponse instance
     */
    FileURLsResponse.create = function create(properties) {
        return new FileURLsResponse(properties);
    };

    /**
     * Encodes the specified FileURLsResponse message. Does not implicitly {@link FileURLsResponse.verify|verify} messages.
     * @function encode
     * @memberof FileURLsResponse
     * @static
     * @param {IFileURLsResponse} message FileURLsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.responseId != null && Object.hasOwnProperty.call(message, "responseId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.responseId);
        if (message.fileUrls != null && message.fileUrls.length)
            for (let i = 0; i < message.fileUrls.length; ++i)
                $root.FileURLs.encode(message.fileUrls[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.errorMsg != null && Object.hasOwnProperty.call(message, "errorMsg"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorMsg);
        return writer;
    };

    /**
     * Encodes the specified FileURLsResponse message, length delimited. Does not implicitly {@link FileURLsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FileURLsResponse
     * @static
     * @param {IFileURLsResponse} message FileURLsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileURLsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileURLsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FileURLsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FileURLsResponse} FileURLsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FileURLsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseId = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.fileUrls && message.fileUrls.length))
                        message.fileUrls = [];
                    message.fileUrls.push($root.FileURLs.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.errorMsg = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FileURLsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FileURLsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FileURLsResponse} FileURLsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileURLsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileURLsResponse message.
     * @function verify
     * @memberof FileURLsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileURLsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.responseId != null && message.hasOwnProperty("responseId"))
            if (!$util.isString(message.responseId))
                return "responseId: string expected";
        if (message.fileUrls != null && message.hasOwnProperty("fileUrls")) {
            if (!Array.isArray(message.fileUrls))
                return "fileUrls: array expected";
            for (let i = 0; i < message.fileUrls.length; ++i) {
                let error = $root.FileURLs.verify(message.fileUrls[i]);
                if (error)
                    return "fileUrls." + error;
            }
        }
        if (message.errorMsg != null && message.hasOwnProperty("errorMsg"))
            if (!$util.isString(message.errorMsg))
                return "errorMsg: string expected";
        return null;
    };

    /**
     * Creates a FileURLsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FileURLsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FileURLsResponse} FileURLsResponse
     */
    FileURLsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FileURLsResponse)
            return object;
        let message = new $root.FileURLsResponse();
        if (object.responseId != null)
            message.responseId = String(object.responseId);
        if (object.fileUrls) {
            if (!Array.isArray(object.fileUrls))
                throw TypeError(".FileURLsResponse.fileUrls: array expected");
            message.fileUrls = [];
            for (let i = 0; i < object.fileUrls.length; ++i) {
                if (typeof object.fileUrls[i] !== "object")
                    throw TypeError(".FileURLsResponse.fileUrls: object expected");
                message.fileUrls[i] = $root.FileURLs.fromObject(object.fileUrls[i]);
            }
        }
        if (object.errorMsg != null)
            message.errorMsg = String(object.errorMsg);
        return message;
    };

    /**
     * Creates a plain object from a FileURLsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FileURLsResponse
     * @static
     * @param {FileURLsResponse} message FileURLsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileURLsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.fileUrls = [];
        if (options.defaults) {
            object.responseId = "";
            object.errorMsg = "";
        }
        if (message.responseId != null && message.hasOwnProperty("responseId"))
            object.responseId = message.responseId;
        if (message.fileUrls && message.fileUrls.length) {
            object.fileUrls = [];
            for (let j = 0; j < message.fileUrls.length; ++j)
                object.fileUrls[j] = $root.FileURLs.toObject(message.fileUrls[j], options);
        }
        if (message.errorMsg != null && message.hasOwnProperty("errorMsg"))
            object.errorMsg = message.errorMsg;
        return object;
    };

    /**
     * Converts this FileURLsResponse to JSON.
     * @function toJSON
     * @memberof FileURLsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileURLsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileURLsResponse
     * @function getTypeUrl
     * @memberof FileURLsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileURLsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FileURLsResponse";
    };

    return FileURLsResponse;
})();

export const UploadedFileInfo = $root.UploadedFileInfo = (() => {

    /**
     * Properties of an UploadedFileInfo.
     * @exports IUploadedFileInfo
     * @interface IUploadedFileInfo
     * @property {number|Long|null} [id] UploadedFileInfo id
     * @property {string|null} [name] UploadedFileInfo name
     * @property {number|Long|null} [size] UploadedFileInfo size
     * @property {string|null} [fileId] UploadedFileInfo fileId
     * @property {IFileURLs|null} [fileUrls] UploadedFileInfo fileUrls
     */

    /**
     * Constructs a new UploadedFileInfo.
     * @exports UploadedFileInfo
     * @classdesc Represents an UploadedFileInfo.
     * @implements IUploadedFileInfo
     * @constructor
     * @param {IUploadedFileInfo=} [properties] Properties to set
     */
    function UploadedFileInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UploadedFileInfo id.
     * @member {number|Long} id
     * @memberof UploadedFileInfo
     * @instance
     */
    UploadedFileInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * UploadedFileInfo name.
     * @member {string} name
     * @memberof UploadedFileInfo
     * @instance
     */
    UploadedFileInfo.prototype.name = "";

    /**
     * UploadedFileInfo size.
     * @member {number|Long} size
     * @memberof UploadedFileInfo
     * @instance
     */
    UploadedFileInfo.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * UploadedFileInfo fileId.
     * @member {string} fileId
     * @memberof UploadedFileInfo
     * @instance
     */
    UploadedFileInfo.prototype.fileId = "";

    /**
     * UploadedFileInfo fileUrls.
     * @member {IFileURLs|null|undefined} fileUrls
     * @memberof UploadedFileInfo
     * @instance
     */
    UploadedFileInfo.prototype.fileUrls = null;

    /**
     * Creates a new UploadedFileInfo instance using the specified properties.
     * @function create
     * @memberof UploadedFileInfo
     * @static
     * @param {IUploadedFileInfo=} [properties] Properties to set
     * @returns {UploadedFileInfo} UploadedFileInfo instance
     */
    UploadedFileInfo.create = function create(properties) {
        return new UploadedFileInfo(properties);
    };

    /**
     * Encodes the specified UploadedFileInfo message. Does not implicitly {@link UploadedFileInfo.verify|verify} messages.
     * @function encode
     * @memberof UploadedFileInfo
     * @static
     * @param {IUploadedFileInfo} message UploadedFileInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UploadedFileInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).sint64(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.size != null && Object.hasOwnProperty.call(message, "size"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.size);
        if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.fileId);
        if (message.fileUrls != null && Object.hasOwnProperty.call(message, "fileUrls"))
            $root.FileURLs.encode(message.fileUrls, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UploadedFileInfo message, length delimited. Does not implicitly {@link UploadedFileInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UploadedFileInfo
     * @static
     * @param {IUploadedFileInfo} message UploadedFileInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UploadedFileInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UploadedFileInfo message from the specified reader or buffer.
     * @function decode
     * @memberof UploadedFileInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UploadedFileInfo} UploadedFileInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UploadedFileInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UploadedFileInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.sint64();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.size = reader.uint64();
                    break;
                }
            case 4: {
                    message.fileId = reader.string();
                    break;
                }
            case 5: {
                    message.fileUrls = $root.FileURLs.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UploadedFileInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UploadedFileInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UploadedFileInfo} UploadedFileInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UploadedFileInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UploadedFileInfo message.
     * @function verify
     * @memberof UploadedFileInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UploadedFileInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.size != null && message.hasOwnProperty("size"))
            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                return "size: integer|Long expected";
        if (message.fileId != null && message.hasOwnProperty("fileId"))
            if (!$util.isString(message.fileId))
                return "fileId: string expected";
        if (message.fileUrls != null && message.hasOwnProperty("fileUrls")) {
            let error = $root.FileURLs.verify(message.fileUrls);
            if (error)
                return "fileUrls." + error;
        }
        return null;
    };

    /**
     * Creates an UploadedFileInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UploadedFileInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UploadedFileInfo} UploadedFileInfo
     */
    UploadedFileInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.UploadedFileInfo)
            return object;
        let message = new $root.UploadedFileInfo();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        if (object.name != null)
            message.name = String(object.name);
        if (object.size != null)
            if ($util.Long)
                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
            else if (typeof object.size === "string")
                message.size = parseInt(object.size, 10);
            else if (typeof object.size === "number")
                message.size = object.size;
            else if (typeof object.size === "object")
                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
        if (object.fileId != null)
            message.fileId = String(object.fileId);
        if (object.fileUrls != null) {
            if (typeof object.fileUrls !== "object")
                throw TypeError(".UploadedFileInfo.fileUrls: object expected");
            message.fileUrls = $root.FileURLs.fromObject(object.fileUrls);
        }
        return message;
    };

    /**
     * Creates a plain object from an UploadedFileInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UploadedFileInfo
     * @static
     * @param {UploadedFileInfo} message UploadedFileInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UploadedFileInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            object.name = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.size = options.longs === String ? "0" : 0;
            object.fileId = "";
            object.fileUrls = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.size != null && message.hasOwnProperty("size"))
            if (typeof message.size === "number")
                object.size = options.longs === String ? String(message.size) : message.size;
            else
                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
        if (message.fileId != null && message.hasOwnProperty("fileId"))
            object.fileId = message.fileId;
        if (message.fileUrls != null && message.hasOwnProperty("fileUrls"))
            object.fileUrls = $root.FileURLs.toObject(message.fileUrls, options);
        return object;
    };

    /**
     * Converts this UploadedFileInfo to JSON.
     * @function toJSON
     * @memberof UploadedFileInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UploadedFileInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UploadedFileInfo
     * @function getTypeUrl
     * @memberof UploadedFileInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UploadedFileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UploadedFileInfo";
    };

    return UploadedFileInfo;
})();

export const FileUploaderState = $root.FileUploaderState = (() => {

    /**
     * Properties of a FileUploaderState.
     * @exports IFileUploaderState
     * @interface IFileUploaderState
     * @property {number|Long|null} [maxFileId] FileUploaderState maxFileId
     * @property {Array.<IUploadedFileInfo>|null} [uploadedFileInfo] FileUploaderState uploadedFileInfo
     */

    /**
     * Constructs a new FileUploaderState.
     * @exports FileUploaderState
     * @classdesc Represents a FileUploaderState.
     * @implements IFileUploaderState
     * @constructor
     * @param {IFileUploaderState=} [properties] Properties to set
     */
    function FileUploaderState(properties) {
        this.uploadedFileInfo = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileUploaderState maxFileId.
     * @member {number|Long} maxFileId
     * @memberof FileUploaderState
     * @instance
     */
    FileUploaderState.prototype.maxFileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * FileUploaderState uploadedFileInfo.
     * @member {Array.<IUploadedFileInfo>} uploadedFileInfo
     * @memberof FileUploaderState
     * @instance
     */
    FileUploaderState.prototype.uploadedFileInfo = $util.emptyArray;

    /**
     * Creates a new FileUploaderState instance using the specified properties.
     * @function create
     * @memberof FileUploaderState
     * @static
     * @param {IFileUploaderState=} [properties] Properties to set
     * @returns {FileUploaderState} FileUploaderState instance
     */
    FileUploaderState.create = function create(properties) {
        return new FileUploaderState(properties);
    };

    /**
     * Encodes the specified FileUploaderState message. Does not implicitly {@link FileUploaderState.verify|verify} messages.
     * @function encode
     * @memberof FileUploaderState
     * @static
     * @param {IFileUploaderState} message FileUploaderState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileUploaderState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.maxFileId != null && Object.hasOwnProperty.call(message, "maxFileId"))
            writer.uint32(/* id 1, wireType 0 =*/8).sint64(message.maxFileId);
        if (message.uploadedFileInfo != null && message.uploadedFileInfo.length)
            for (let i = 0; i < message.uploadedFileInfo.length; ++i)
                $root.UploadedFileInfo.encode(message.uploadedFileInfo[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified FileUploaderState message, length delimited. Does not implicitly {@link FileUploaderState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FileUploaderState
     * @static
     * @param {IFileUploaderState} message FileUploaderState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileUploaderState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileUploaderState message from the specified reader or buffer.
     * @function decode
     * @memberof FileUploaderState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FileUploaderState} FileUploaderState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileUploaderState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FileUploaderState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.maxFileId = reader.sint64();
                    break;
                }
            case 2: {
                    if (!(message.uploadedFileInfo && message.uploadedFileInfo.length))
                        message.uploadedFileInfo = [];
                    message.uploadedFileInfo.push($root.UploadedFileInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FileUploaderState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FileUploaderState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FileUploaderState} FileUploaderState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileUploaderState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileUploaderState message.
     * @function verify
     * @memberof FileUploaderState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileUploaderState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.maxFileId != null && message.hasOwnProperty("maxFileId"))
            if (!$util.isInteger(message.maxFileId) && !(message.maxFileId && $util.isInteger(message.maxFileId.low) && $util.isInteger(message.maxFileId.high)))
                return "maxFileId: integer|Long expected";
        if (message.uploadedFileInfo != null && message.hasOwnProperty("uploadedFileInfo")) {
            if (!Array.isArray(message.uploadedFileInfo))
                return "uploadedFileInfo: array expected";
            for (let i = 0; i < message.uploadedFileInfo.length; ++i) {
                let error = $root.UploadedFileInfo.verify(message.uploadedFileInfo[i]);
                if (error)
                    return "uploadedFileInfo." + error;
            }
        }
        return null;
    };

    /**
     * Creates a FileUploaderState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FileUploaderState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FileUploaderState} FileUploaderState
     */
    FileUploaderState.fromObject = function fromObject(object) {
        if (object instanceof $root.FileUploaderState)
            return object;
        let message = new $root.FileUploaderState();
        if (object.maxFileId != null)
            if ($util.Long)
                (message.maxFileId = $util.Long.fromValue(object.maxFileId)).unsigned = false;
            else if (typeof object.maxFileId === "string")
                message.maxFileId = parseInt(object.maxFileId, 10);
            else if (typeof object.maxFileId === "number")
                message.maxFileId = object.maxFileId;
            else if (typeof object.maxFileId === "object")
                message.maxFileId = new $util.LongBits(object.maxFileId.low >>> 0, object.maxFileId.high >>> 0).toNumber();
        if (object.uploadedFileInfo) {
            if (!Array.isArray(object.uploadedFileInfo))
                throw TypeError(".FileUploaderState.uploadedFileInfo: array expected");
            message.uploadedFileInfo = [];
            for (let i = 0; i < object.uploadedFileInfo.length; ++i) {
                if (typeof object.uploadedFileInfo[i] !== "object")
                    throw TypeError(".FileUploaderState.uploadedFileInfo: object expected");
                message.uploadedFileInfo[i] = $root.UploadedFileInfo.fromObject(object.uploadedFileInfo[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a FileUploaderState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FileUploaderState
     * @static
     * @param {FileUploaderState} message FileUploaderState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileUploaderState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.uploadedFileInfo = [];
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.maxFileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.maxFileId = options.longs === String ? "0" : 0;
        if (message.maxFileId != null && message.hasOwnProperty("maxFileId"))
            if (typeof message.maxFileId === "number")
                object.maxFileId = options.longs === String ? String(message.maxFileId) : message.maxFileId;
            else
                object.maxFileId = options.longs === String ? $util.Long.prototype.toString.call(message.maxFileId) : options.longs === Number ? new $util.LongBits(message.maxFileId.low >>> 0, message.maxFileId.high >>> 0).toNumber() : message.maxFileId;
        if (message.uploadedFileInfo && message.uploadedFileInfo.length) {
            object.uploadedFileInfo = [];
            for (let j = 0; j < message.uploadedFileInfo.length; ++j)
                object.uploadedFileInfo[j] = $root.UploadedFileInfo.toObject(message.uploadedFileInfo[j], options);
        }
        return object;
    };

    /**
     * Converts this FileUploaderState to JSON.
     * @function toJSON
     * @memberof FileUploaderState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileUploaderState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileUploaderState
     * @function getTypeUrl
     * @memberof FileUploaderState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileUploaderState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FileUploaderState";
    };

    return FileUploaderState;
})();

export const ComponentInstance = $root.ComponentInstance = (() => {

    /**
     * Properties of a ComponentInstance.
     * @exports IComponentInstance
     * @interface IComponentInstance
     * @property {string|null} [id] ComponentInstance id
     * @property {string|null} [jsonArgs] ComponentInstance jsonArgs
     * @property {Array.<ISpecialArg>|null} [specialArgs] ComponentInstance specialArgs
     * @property {string|null} [componentName] ComponentInstance componentName
     * @property {string|null} [url] ComponentInstance url
     * @property {string|null} [formId] ComponentInstance formId
     */

    /**
     * Constructs a new ComponentInstance.
     * @exports ComponentInstance
     * @classdesc Represents a ComponentInstance.
     * @implements IComponentInstance
     * @constructor
     * @param {IComponentInstance=} [properties] Properties to set
     */
    function ComponentInstance(properties) {
        this.specialArgs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ComponentInstance id.
     * @member {string} id
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.id = "";

    /**
     * ComponentInstance jsonArgs.
     * @member {string} jsonArgs
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.jsonArgs = "";

    /**
     * ComponentInstance specialArgs.
     * @member {Array.<ISpecialArg>} specialArgs
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.specialArgs = $util.emptyArray;

    /**
     * ComponentInstance componentName.
     * @member {string} componentName
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.componentName = "";

    /**
     * ComponentInstance url.
     * @member {string} url
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.url = "";

    /**
     * ComponentInstance formId.
     * @member {string} formId
     * @memberof ComponentInstance
     * @instance
     */
    ComponentInstance.prototype.formId = "";

    /**
     * Creates a new ComponentInstance instance using the specified properties.
     * @function create
     * @memberof ComponentInstance
     * @static
     * @param {IComponentInstance=} [properties] Properties to set
     * @returns {ComponentInstance} ComponentInstance instance
     */
    ComponentInstance.create = function create(properties) {
        return new ComponentInstance(properties);
    };

    /**
     * Encodes the specified ComponentInstance message. Does not implicitly {@link ComponentInstance.verify|verify} messages.
     * @function encode
     * @memberof ComponentInstance
     * @static
     * @param {IComponentInstance} message ComponentInstance message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComponentInstance.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.jsonArgs != null && Object.hasOwnProperty.call(message, "jsonArgs"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.jsonArgs);
        if (message.specialArgs != null && message.specialArgs.length)
            for (let i = 0; i < message.specialArgs.length; ++i)
                $root.SpecialArg.encode(message.specialArgs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.componentName != null && Object.hasOwnProperty.call(message, "componentName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.componentName);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.url);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.formId);
        return writer;
    };

    /**
     * Encodes the specified ComponentInstance message, length delimited. Does not implicitly {@link ComponentInstance.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ComponentInstance
     * @static
     * @param {IComponentInstance} message ComponentInstance message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComponentInstance.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ComponentInstance message from the specified reader or buffer.
     * @function decode
     * @memberof ComponentInstance
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ComponentInstance} ComponentInstance
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComponentInstance.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ComponentInstance();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.jsonArgs = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.specialArgs && message.specialArgs.length))
                        message.specialArgs = [];
                    message.specialArgs.push($root.SpecialArg.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.componentName = reader.string();
                    break;
                }
            case 5: {
                    message.url = reader.string();
                    break;
                }
            case 6: {
                    message.formId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ComponentInstance message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ComponentInstance
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ComponentInstance} ComponentInstance
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComponentInstance.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ComponentInstance message.
     * @function verify
     * @memberof ComponentInstance
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ComponentInstance.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.jsonArgs != null && message.hasOwnProperty("jsonArgs"))
            if (!$util.isString(message.jsonArgs))
                return "jsonArgs: string expected";
        if (message.specialArgs != null && message.hasOwnProperty("specialArgs")) {
            if (!Array.isArray(message.specialArgs))
                return "specialArgs: array expected";
            for (let i = 0; i < message.specialArgs.length; ++i) {
                let error = $root.SpecialArg.verify(message.specialArgs[i]);
                if (error)
                    return "specialArgs." + error;
            }
        }
        if (message.componentName != null && message.hasOwnProperty("componentName"))
            if (!$util.isString(message.componentName))
                return "componentName: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        return null;
    };

    /**
     * Creates a ComponentInstance message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ComponentInstance
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ComponentInstance} ComponentInstance
     */
    ComponentInstance.fromObject = function fromObject(object) {
        if (object instanceof $root.ComponentInstance)
            return object;
        let message = new $root.ComponentInstance();
        if (object.id != null)
            message.id = String(object.id);
        if (object.jsonArgs != null)
            message.jsonArgs = String(object.jsonArgs);
        if (object.specialArgs) {
            if (!Array.isArray(object.specialArgs))
                throw TypeError(".ComponentInstance.specialArgs: array expected");
            message.specialArgs = [];
            for (let i = 0; i < object.specialArgs.length; ++i) {
                if (typeof object.specialArgs[i] !== "object")
                    throw TypeError(".ComponentInstance.specialArgs: object expected");
                message.specialArgs[i] = $root.SpecialArg.fromObject(object.specialArgs[i]);
            }
        }
        if (object.componentName != null)
            message.componentName = String(object.componentName);
        if (object.url != null)
            message.url = String(object.url);
        if (object.formId != null)
            message.formId = String(object.formId);
        return message;
    };

    /**
     * Creates a plain object from a ComponentInstance message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ComponentInstance
     * @static
     * @param {ComponentInstance} message ComponentInstance
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ComponentInstance.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.specialArgs = [];
        if (options.defaults) {
            object.id = "";
            object.jsonArgs = "";
            object.componentName = "";
            object.url = "";
            object.formId = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.jsonArgs != null && message.hasOwnProperty("jsonArgs"))
            object.jsonArgs = message.jsonArgs;
        if (message.specialArgs && message.specialArgs.length) {
            object.specialArgs = [];
            for (let j = 0; j < message.specialArgs.length; ++j)
                object.specialArgs[j] = $root.SpecialArg.toObject(message.specialArgs[j], options);
        }
        if (message.componentName != null && message.hasOwnProperty("componentName"))
            object.componentName = message.componentName;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        return object;
    };

    /**
     * Converts this ComponentInstance to JSON.
     * @function toJSON
     * @memberof ComponentInstance
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ComponentInstance.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ComponentInstance
     * @function getTypeUrl
     * @memberof ComponentInstance
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ComponentInstance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ComponentInstance";
    };

    return ComponentInstance;
})();

export const SpecialArg = $root.SpecialArg = (() => {

    /**
     * Properties of a SpecialArg.
     * @exports ISpecialArg
     * @interface ISpecialArg
     * @property {string|null} [key] SpecialArg key
     * @property {IArrowDataframe|null} [arrowDataframe] SpecialArg arrowDataframe
     * @property {Uint8Array|null} [bytes] SpecialArg bytes
     */

    /**
     * Constructs a new SpecialArg.
     * @exports SpecialArg
     * @classdesc Represents a SpecialArg.
     * @implements ISpecialArg
     * @constructor
     * @param {ISpecialArg=} [properties] Properties to set
     */
    function SpecialArg(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpecialArg key.
     * @member {string} key
     * @memberof SpecialArg
     * @instance
     */
    SpecialArg.prototype.key = "";

    /**
     * SpecialArg arrowDataframe.
     * @member {IArrowDataframe|null|undefined} arrowDataframe
     * @memberof SpecialArg
     * @instance
     */
    SpecialArg.prototype.arrowDataframe = null;

    /**
     * SpecialArg bytes.
     * @member {Uint8Array|null|undefined} bytes
     * @memberof SpecialArg
     * @instance
     */
    SpecialArg.prototype.bytes = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * SpecialArg value.
     * @member {"arrowDataframe"|"bytes"|undefined} value
     * @memberof SpecialArg
     * @instance
     */
    Object.defineProperty(SpecialArg.prototype, "value", {
        get: $util.oneOfGetter($oneOfFields = ["arrowDataframe", "bytes"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new SpecialArg instance using the specified properties.
     * @function create
     * @memberof SpecialArg
     * @static
     * @param {ISpecialArg=} [properties] Properties to set
     * @returns {SpecialArg} SpecialArg instance
     */
    SpecialArg.create = function create(properties) {
        return new SpecialArg(properties);
    };

    /**
     * Encodes the specified SpecialArg message. Does not implicitly {@link SpecialArg.verify|verify} messages.
     * @function encode
     * @memberof SpecialArg
     * @static
     * @param {ISpecialArg} message SpecialArg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpecialArg.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
        if (message.arrowDataframe != null && Object.hasOwnProperty.call(message, "arrowDataframe"))
            $root.ArrowDataframe.encode(message.arrowDataframe, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.bytes != null && Object.hasOwnProperty.call(message, "bytes"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.bytes);
        return writer;
    };

    /**
     * Encodes the specified SpecialArg message, length delimited. Does not implicitly {@link SpecialArg.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SpecialArg
     * @static
     * @param {ISpecialArg} message SpecialArg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpecialArg.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpecialArg message from the specified reader or buffer.
     * @function decode
     * @memberof SpecialArg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SpecialArg} SpecialArg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpecialArg.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SpecialArg();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.key = reader.string();
                    break;
                }
            case 2: {
                    message.arrowDataframe = $root.ArrowDataframe.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.bytes = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SpecialArg message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SpecialArg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SpecialArg} SpecialArg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpecialArg.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpecialArg message.
     * @function verify
     * @memberof SpecialArg
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpecialArg.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isString(message.key))
                return "key: string expected";
        if (message.arrowDataframe != null && message.hasOwnProperty("arrowDataframe")) {
            properties.value = 1;
            {
                let error = $root.ArrowDataframe.verify(message.arrowDataframe);
                if (error)
                    return "arrowDataframe." + error;
            }
        }
        if (message.bytes != null && message.hasOwnProperty("bytes")) {
            if (properties.value === 1)
                return "value: multiple values";
            properties.value = 1;
            if (!(message.bytes && typeof message.bytes.length === "number" || $util.isString(message.bytes)))
                return "bytes: buffer expected";
        }
        return null;
    };

    /**
     * Creates a SpecialArg message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SpecialArg
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SpecialArg} SpecialArg
     */
    SpecialArg.fromObject = function fromObject(object) {
        if (object instanceof $root.SpecialArg)
            return object;
        let message = new $root.SpecialArg();
        if (object.key != null)
            message.key = String(object.key);
        if (object.arrowDataframe != null) {
            if (typeof object.arrowDataframe !== "object")
                throw TypeError(".SpecialArg.arrowDataframe: object expected");
            message.arrowDataframe = $root.ArrowDataframe.fromObject(object.arrowDataframe);
        }
        if (object.bytes != null)
            if (typeof object.bytes === "string")
                $util.base64.decode(object.bytes, message.bytes = $util.newBuffer($util.base64.length(object.bytes)), 0);
            else if (object.bytes.length >= 0)
                message.bytes = object.bytes;
        return message;
    };

    /**
     * Creates a plain object from a SpecialArg message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SpecialArg
     * @static
     * @param {SpecialArg} message SpecialArg
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpecialArg.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.key = "";
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.arrowDataframe != null && message.hasOwnProperty("arrowDataframe")) {
            object.arrowDataframe = $root.ArrowDataframe.toObject(message.arrowDataframe, options);
            if (options.oneofs)
                object.value = "arrowDataframe";
        }
        if (message.bytes != null && message.hasOwnProperty("bytes")) {
            object.bytes = options.bytes === String ? $util.base64.encode(message.bytes, 0, message.bytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.bytes) : message.bytes;
            if (options.oneofs)
                object.value = "bytes";
        }
        return object;
    };

    /**
     * Converts this SpecialArg to JSON.
     * @function toJSON
     * @memberof SpecialArg
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpecialArg.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpecialArg
     * @function getTypeUrl
     * @memberof SpecialArg
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpecialArg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SpecialArg";
    };

    return SpecialArg;
})();

export const ArrowDataframe = $root.ArrowDataframe = (() => {

    /**
     * Properties of an ArrowDataframe.
     * @exports IArrowDataframe
     * @interface IArrowDataframe
     * @property {IArrowTable|null} [data] ArrowDataframe data
     * @property {number|null} [height] ArrowDataframe height
     * @property {number|null} [width] ArrowDataframe width
     */

    /**
     * Constructs a new ArrowDataframe.
     * @exports ArrowDataframe
     * @classdesc Represents an ArrowDataframe.
     * @implements IArrowDataframe
     * @constructor
     * @param {IArrowDataframe=} [properties] Properties to set
     */
    function ArrowDataframe(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ArrowDataframe data.
     * @member {IArrowTable|null|undefined} data
     * @memberof ArrowDataframe
     * @instance
     */
    ArrowDataframe.prototype.data = null;

    /**
     * ArrowDataframe height.
     * @member {number} height
     * @memberof ArrowDataframe
     * @instance
     */
    ArrowDataframe.prototype.height = 0;

    /**
     * ArrowDataframe width.
     * @member {number} width
     * @memberof ArrowDataframe
     * @instance
     */
    ArrowDataframe.prototype.width = 0;

    /**
     * Creates a new ArrowDataframe instance using the specified properties.
     * @function create
     * @memberof ArrowDataframe
     * @static
     * @param {IArrowDataframe=} [properties] Properties to set
     * @returns {ArrowDataframe} ArrowDataframe instance
     */
    ArrowDataframe.create = function create(properties) {
        return new ArrowDataframe(properties);
    };

    /**
     * Encodes the specified ArrowDataframe message. Does not implicitly {@link ArrowDataframe.verify|verify} messages.
     * @function encode
     * @memberof ArrowDataframe
     * @static
     * @param {IArrowDataframe} message ArrowDataframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowDataframe.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.ArrowTable.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.height);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.width);
        return writer;
    };

    /**
     * Encodes the specified ArrowDataframe message, length delimited. Does not implicitly {@link ArrowDataframe.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ArrowDataframe
     * @static
     * @param {IArrowDataframe} message ArrowDataframe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowDataframe.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ArrowDataframe message from the specified reader or buffer.
     * @function decode
     * @memberof ArrowDataframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ArrowDataframe} ArrowDataframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowDataframe.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ArrowDataframe();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.ArrowTable.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.height = reader.uint32();
                    break;
                }
            case 3: {
                    message.width = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ArrowDataframe message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ArrowDataframe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ArrowDataframe} ArrowDataframe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowDataframe.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ArrowDataframe message.
     * @function verify
     * @memberof ArrowDataframe
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ArrowDataframe.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.ArrowTable.verify(message.data);
            if (error)
                return "data." + error;
        }
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width))
                return "width: integer expected";
        return null;
    };

    /**
     * Creates an ArrowDataframe message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ArrowDataframe
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ArrowDataframe} ArrowDataframe
     */
    ArrowDataframe.fromObject = function fromObject(object) {
        if (object instanceof $root.ArrowDataframe)
            return object;
        let message = new $root.ArrowDataframe();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".ArrowDataframe.data: object expected");
            message.data = $root.ArrowTable.fromObject(object.data);
        }
        if (object.height != null)
            message.height = object.height >>> 0;
        if (object.width != null)
            message.width = object.width >>> 0;
        return message;
    };

    /**
     * Creates a plain object from an ArrowDataframe message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ArrowDataframe
     * @static
     * @param {ArrowDataframe} message ArrowDataframe
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ArrowDataframe.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.data = null;
            object.height = 0;
            object.width = 0;
        }
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.ArrowTable.toObject(message.data, options);
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        return object;
    };

    /**
     * Converts this ArrowDataframe to JSON.
     * @function toJSON
     * @memberof ArrowDataframe
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ArrowDataframe.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ArrowDataframe
     * @function getTypeUrl
     * @memberof ArrowDataframe
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ArrowDataframe.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ArrowDataframe";
    };

    return ArrowDataframe;
})();

export const ArrowTable = $root.ArrowTable = (() => {

    /**
     * Properties of an ArrowTable.
     * @exports IArrowTable
     * @interface IArrowTable
     * @property {Uint8Array|null} [data] ArrowTable data
     * @property {Uint8Array|null} [index] ArrowTable index
     * @property {Uint8Array|null} [columns] ArrowTable columns
     * @property {IArrowTableStyler|null} [styler] ArrowTable styler
     */

    /**
     * Constructs a new ArrowTable.
     * @exports ArrowTable
     * @classdesc Represents an ArrowTable.
     * @implements IArrowTable
     * @constructor
     * @param {IArrowTable=} [properties] Properties to set
     */
    function ArrowTable(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ArrowTable data.
     * @member {Uint8Array} data
     * @memberof ArrowTable
     * @instance
     */
    ArrowTable.prototype.data = $util.newBuffer([]);

    /**
     * ArrowTable index.
     * @member {Uint8Array} index
     * @memberof ArrowTable
     * @instance
     */
    ArrowTable.prototype.index = $util.newBuffer([]);

    /**
     * ArrowTable columns.
     * @member {Uint8Array} columns
     * @memberof ArrowTable
     * @instance
     */
    ArrowTable.prototype.columns = $util.newBuffer([]);

    /**
     * ArrowTable styler.
     * @member {IArrowTableStyler|null|undefined} styler
     * @memberof ArrowTable
     * @instance
     */
    ArrowTable.prototype.styler = null;

    /**
     * Creates a new ArrowTable instance using the specified properties.
     * @function create
     * @memberof ArrowTable
     * @static
     * @param {IArrowTable=} [properties] Properties to set
     * @returns {ArrowTable} ArrowTable instance
     */
    ArrowTable.create = function create(properties) {
        return new ArrowTable(properties);
    };

    /**
     * Encodes the specified ArrowTable message. Does not implicitly {@link ArrowTable.verify|verify} messages.
     * @function encode
     * @memberof ArrowTable
     * @static
     * @param {IArrowTable} message ArrowTable message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowTable.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
        if (message.index != null && Object.hasOwnProperty.call(message, "index"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.index);
        if (message.columns != null && Object.hasOwnProperty.call(message, "columns"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.columns);
        if (message.styler != null && Object.hasOwnProperty.call(message, "styler"))
            $root.ArrowTableStyler.encode(message.styler, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ArrowTable message, length delimited. Does not implicitly {@link ArrowTable.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ArrowTable
     * @static
     * @param {IArrowTable} message ArrowTable message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowTable.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ArrowTable message from the specified reader or buffer.
     * @function decode
     * @memberof ArrowTable
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ArrowTable} ArrowTable
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowTable.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ArrowTable();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = reader.bytes();
                    break;
                }
            case 2: {
                    message.index = reader.bytes();
                    break;
                }
            case 3: {
                    message.columns = reader.bytes();
                    break;
                }
            case 5: {
                    message.styler = $root.ArrowTableStyler.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ArrowTable message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ArrowTable
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ArrowTable} ArrowTable
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowTable.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ArrowTable message.
     * @function verify
     * @memberof ArrowTable
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ArrowTable.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        if (message.index != null && message.hasOwnProperty("index"))
            if (!(message.index && typeof message.index.length === "number" || $util.isString(message.index)))
                return "index: buffer expected";
        if (message.columns != null && message.hasOwnProperty("columns"))
            if (!(message.columns && typeof message.columns.length === "number" || $util.isString(message.columns)))
                return "columns: buffer expected";
        if (message.styler != null && message.hasOwnProperty("styler")) {
            let error = $root.ArrowTableStyler.verify(message.styler);
            if (error)
                return "styler." + error;
        }
        return null;
    };

    /**
     * Creates an ArrowTable message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ArrowTable
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ArrowTable} ArrowTable
     */
    ArrowTable.fromObject = function fromObject(object) {
        if (object instanceof $root.ArrowTable)
            return object;
        let message = new $root.ArrowTable();
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        if (object.index != null)
            if (typeof object.index === "string")
                $util.base64.decode(object.index, message.index = $util.newBuffer($util.base64.length(object.index)), 0);
            else if (object.index.length >= 0)
                message.index = object.index;
        if (object.columns != null)
            if (typeof object.columns === "string")
                $util.base64.decode(object.columns, message.columns = $util.newBuffer($util.base64.length(object.columns)), 0);
            else if (object.columns.length >= 0)
                message.columns = object.columns;
        if (object.styler != null) {
            if (typeof object.styler !== "object")
                throw TypeError(".ArrowTable.styler: object expected");
            message.styler = $root.ArrowTableStyler.fromObject(object.styler);
        }
        return message;
    };

    /**
     * Creates a plain object from an ArrowTable message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ArrowTable
     * @static
     * @param {ArrowTable} message ArrowTable
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ArrowTable.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
            if (options.bytes === String)
                object.index = "";
            else {
                object.index = [];
                if (options.bytes !== Array)
                    object.index = $util.newBuffer(object.index);
            }
            if (options.bytes === String)
                object.columns = "";
            else {
                object.columns = [];
                if (options.bytes !== Array)
                    object.columns = $util.newBuffer(object.columns);
            }
            object.styler = null;
        }
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        if (message.index != null && message.hasOwnProperty("index"))
            object.index = options.bytes === String ? $util.base64.encode(message.index, 0, message.index.length) : options.bytes === Array ? Array.prototype.slice.call(message.index) : message.index;
        if (message.columns != null && message.hasOwnProperty("columns"))
            object.columns = options.bytes === String ? $util.base64.encode(message.columns, 0, message.columns.length) : options.bytes === Array ? Array.prototype.slice.call(message.columns) : message.columns;
        if (message.styler != null && message.hasOwnProperty("styler"))
            object.styler = $root.ArrowTableStyler.toObject(message.styler, options);
        return object;
    };

    /**
     * Converts this ArrowTable to JSON.
     * @function toJSON
     * @memberof ArrowTable
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ArrowTable.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ArrowTable
     * @function getTypeUrl
     * @memberof ArrowTable
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ArrowTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ArrowTable";
    };

    return ArrowTable;
})();

export const ArrowTableStyler = $root.ArrowTableStyler = (() => {

    /**
     * Properties of an ArrowTableStyler.
     * @exports IArrowTableStyler
     * @interface IArrowTableStyler
     * @property {string|null} [uuid] ArrowTableStyler uuid
     * @property {string|null} [caption] ArrowTableStyler caption
     * @property {string|null} [styles] ArrowTableStyler styles
     * @property {Uint8Array|null} [displayValues] ArrowTableStyler displayValues
     */

    /**
     * Constructs a new ArrowTableStyler.
     * @exports ArrowTableStyler
     * @classdesc Represents an ArrowTableStyler.
     * @implements IArrowTableStyler
     * @constructor
     * @param {IArrowTableStyler=} [properties] Properties to set
     */
    function ArrowTableStyler(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ArrowTableStyler uuid.
     * @member {string} uuid
     * @memberof ArrowTableStyler
     * @instance
     */
    ArrowTableStyler.prototype.uuid = "";

    /**
     * ArrowTableStyler caption.
     * @member {string} caption
     * @memberof ArrowTableStyler
     * @instance
     */
    ArrowTableStyler.prototype.caption = "";

    /**
     * ArrowTableStyler styles.
     * @member {string} styles
     * @memberof ArrowTableStyler
     * @instance
     */
    ArrowTableStyler.prototype.styles = "";

    /**
     * ArrowTableStyler displayValues.
     * @member {Uint8Array} displayValues
     * @memberof ArrowTableStyler
     * @instance
     */
    ArrowTableStyler.prototype.displayValues = $util.newBuffer([]);

    /**
     * Creates a new ArrowTableStyler instance using the specified properties.
     * @function create
     * @memberof ArrowTableStyler
     * @static
     * @param {IArrowTableStyler=} [properties] Properties to set
     * @returns {ArrowTableStyler} ArrowTableStyler instance
     */
    ArrowTableStyler.create = function create(properties) {
        return new ArrowTableStyler(properties);
    };

    /**
     * Encodes the specified ArrowTableStyler message. Does not implicitly {@link ArrowTableStyler.verify|verify} messages.
     * @function encode
     * @memberof ArrowTableStyler
     * @static
     * @param {IArrowTableStyler} message ArrowTableStyler message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowTableStyler.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.caption);
        if (message.styles != null && Object.hasOwnProperty.call(message, "styles"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.styles);
        if (message.displayValues != null && Object.hasOwnProperty.call(message, "displayValues"))
            writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.displayValues);
        return writer;
    };

    /**
     * Encodes the specified ArrowTableStyler message, length delimited. Does not implicitly {@link ArrowTableStyler.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ArrowTableStyler
     * @static
     * @param {IArrowTableStyler} message ArrowTableStyler message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ArrowTableStyler.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ArrowTableStyler message from the specified reader or buffer.
     * @function decode
     * @memberof ArrowTableStyler
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ArrowTableStyler} ArrowTableStyler
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowTableStyler.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ArrowTableStyler();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uuid = reader.string();
                    break;
                }
            case 2: {
                    message.caption = reader.string();
                    break;
                }
            case 3: {
                    message.styles = reader.string();
                    break;
                }
            case 4: {
                    message.displayValues = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ArrowTableStyler message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ArrowTableStyler
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ArrowTableStyler} ArrowTableStyler
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ArrowTableStyler.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ArrowTableStyler message.
     * @function verify
     * @memberof ArrowTableStyler
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ArrowTableStyler.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            if (!$util.isString(message.uuid))
                return "uuid: string expected";
        if (message.caption != null && message.hasOwnProperty("caption"))
            if (!$util.isString(message.caption))
                return "caption: string expected";
        if (message.styles != null && message.hasOwnProperty("styles"))
            if (!$util.isString(message.styles))
                return "styles: string expected";
        if (message.displayValues != null && message.hasOwnProperty("displayValues"))
            if (!(message.displayValues && typeof message.displayValues.length === "number" || $util.isString(message.displayValues)))
                return "displayValues: buffer expected";
        return null;
    };

    /**
     * Creates an ArrowTableStyler message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ArrowTableStyler
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ArrowTableStyler} ArrowTableStyler
     */
    ArrowTableStyler.fromObject = function fromObject(object) {
        if (object instanceof $root.ArrowTableStyler)
            return object;
        let message = new $root.ArrowTableStyler();
        if (object.uuid != null)
            message.uuid = String(object.uuid);
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.styles != null)
            message.styles = String(object.styles);
        if (object.displayValues != null)
            if (typeof object.displayValues === "string")
                $util.base64.decode(object.displayValues, message.displayValues = $util.newBuffer($util.base64.length(object.displayValues)), 0);
            else if (object.displayValues.length >= 0)
                message.displayValues = object.displayValues;
        return message;
    };

    /**
     * Creates a plain object from an ArrowTableStyler message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ArrowTableStyler
     * @static
     * @param {ArrowTableStyler} message ArrowTableStyler
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ArrowTableStyler.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.uuid = "";
            object.caption = "";
            object.styles = "";
            if (options.bytes === String)
                object.displayValues = "";
            else {
                object.displayValues = [];
                if (options.bytes !== Array)
                    object.displayValues = $util.newBuffer(object.displayValues);
            }
        }
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            object.uuid = message.uuid;
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.styles != null && message.hasOwnProperty("styles"))
            object.styles = message.styles;
        if (message.displayValues != null && message.hasOwnProperty("displayValues"))
            object.displayValues = options.bytes === String ? $util.base64.encode(message.displayValues, 0, message.displayValues.length) : options.bytes === Array ? Array.prototype.slice.call(message.displayValues) : message.displayValues;
        return object;
    };

    /**
     * Converts this ArrowTableStyler to JSON.
     * @function toJSON
     * @memberof ArrowTableStyler
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ArrowTableStyler.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ArrowTableStyler
     * @function getTypeUrl
     * @memberof ArrowTableStyler
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ArrowTableStyler.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ArrowTableStyler";
    };

    return ArrowTableStyler;
})();

export const Balloons = $root.Balloons = (() => {

    /**
     * Properties of a Balloons.
     * @exports IBalloons
     * @interface IBalloons
     * @property {boolean|null} [show] Balloons show
     */

    /**
     * Constructs a new Balloons.
     * @exports Balloons
     * @classdesc Represents a Balloons.
     * @implements IBalloons
     * @constructor
     * @param {IBalloons=} [properties] Properties to set
     */
    function Balloons(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Balloons show.
     * @member {boolean} show
     * @memberof Balloons
     * @instance
     */
    Balloons.prototype.show = false;

    /**
     * Creates a new Balloons instance using the specified properties.
     * @function create
     * @memberof Balloons
     * @static
     * @param {IBalloons=} [properties] Properties to set
     * @returns {Balloons} Balloons instance
     */
    Balloons.create = function create(properties) {
        return new Balloons(properties);
    };

    /**
     * Encodes the specified Balloons message. Does not implicitly {@link Balloons.verify|verify} messages.
     * @function encode
     * @memberof Balloons
     * @static
     * @param {IBalloons} message Balloons message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Balloons.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.show != null && Object.hasOwnProperty.call(message, "show"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.show);
        return writer;
    };

    /**
     * Encodes the specified Balloons message, length delimited. Does not implicitly {@link Balloons.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Balloons
     * @static
     * @param {IBalloons} message Balloons message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Balloons.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Balloons message from the specified reader or buffer.
     * @function decode
     * @memberof Balloons
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Balloons} Balloons
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Balloons.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Balloons();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.show = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Balloons message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Balloons
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Balloons} Balloons
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Balloons.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Balloons message.
     * @function verify
     * @memberof Balloons
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Balloons.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.show != null && message.hasOwnProperty("show"))
            if (typeof message.show !== "boolean")
                return "show: boolean expected";
        return null;
    };

    /**
     * Creates a Balloons message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Balloons
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Balloons} Balloons
     */
    Balloons.fromObject = function fromObject(object) {
        if (object instanceof $root.Balloons)
            return object;
        let message = new $root.Balloons();
        if (object.show != null)
            message.show = Boolean(object.show);
        return message;
    };

    /**
     * Creates a plain object from a Balloons message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Balloons
     * @static
     * @param {Balloons} message Balloons
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Balloons.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.show = false;
        if (message.show != null && message.hasOwnProperty("show"))
            object.show = message.show;
        return object;
    };

    /**
     * Converts this Balloons to JSON.
     * @function toJSON
     * @memberof Balloons
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Balloons.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Balloons
     * @function getTypeUrl
     * @memberof Balloons
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Balloons.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Balloons";
    };

    return Balloons;
})();

export const Block = $root.Block = (() => {

    /**
     * Properties of a Block.
     * @exports IBlock
     * @interface IBlock
     * @property {Block.IVertical|null} [vertical] Block vertical
     * @property {Block.IHorizontal|null} [horizontal] Block horizontal
     * @property {Block.IColumn|null} [column] Block column
     * @property {Block.IExpandable|null} [expandable] Block expandable
     * @property {Block.IForm|null} [form] Block form
     * @property {Block.ITabContainer|null} [tabContainer] Block tabContainer
     * @property {Block.ITab|null} [tab] Block tab
     * @property {Block.IChatMessage|null} [chatMessage] Block chatMessage
     * @property {Block.IPopover|null} [popover] Block popover
     * @property {Block.IDialog|null} [dialog] Block dialog
     * @property {boolean|null} [allowEmpty] Block allowEmpty
     * @property {string|null} [id] Block id
     */

    /**
     * Constructs a new Block.
     * @exports Block
     * @classdesc Represents a Block.
     * @implements IBlock
     * @constructor
     * @param {IBlock=} [properties] Properties to set
     */
    function Block(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Block vertical.
     * @member {Block.IVertical|null|undefined} vertical
     * @memberof Block
     * @instance
     */
    Block.prototype.vertical = null;

    /**
     * Block horizontal.
     * @member {Block.IHorizontal|null|undefined} horizontal
     * @memberof Block
     * @instance
     */
    Block.prototype.horizontal = null;

    /**
     * Block column.
     * @member {Block.IColumn|null|undefined} column
     * @memberof Block
     * @instance
     */
    Block.prototype.column = null;

    /**
     * Block expandable.
     * @member {Block.IExpandable|null|undefined} expandable
     * @memberof Block
     * @instance
     */
    Block.prototype.expandable = null;

    /**
     * Block form.
     * @member {Block.IForm|null|undefined} form
     * @memberof Block
     * @instance
     */
    Block.prototype.form = null;

    /**
     * Block tabContainer.
     * @member {Block.ITabContainer|null|undefined} tabContainer
     * @memberof Block
     * @instance
     */
    Block.prototype.tabContainer = null;

    /**
     * Block tab.
     * @member {Block.ITab|null|undefined} tab
     * @memberof Block
     * @instance
     */
    Block.prototype.tab = null;

    /**
     * Block chatMessage.
     * @member {Block.IChatMessage|null|undefined} chatMessage
     * @memberof Block
     * @instance
     */
    Block.prototype.chatMessage = null;

    /**
     * Block popover.
     * @member {Block.IPopover|null|undefined} popover
     * @memberof Block
     * @instance
     */
    Block.prototype.popover = null;

    /**
     * Block dialog.
     * @member {Block.IDialog|null|undefined} dialog
     * @memberof Block
     * @instance
     */
    Block.prototype.dialog = null;

    /**
     * Block allowEmpty.
     * @member {boolean} allowEmpty
     * @memberof Block
     * @instance
     */
    Block.prototype.allowEmpty = false;

    /**
     * Block id.
     * @member {string|null|undefined} id
     * @memberof Block
     * @instance
     */
    Block.prototype.id = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Block type.
     * @member {"vertical"|"horizontal"|"column"|"expandable"|"form"|"tabContainer"|"tab"|"chatMessage"|"popover"|"dialog"|undefined} type
     * @memberof Block
     * @instance
     */
    Object.defineProperty(Block.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["vertical", "horizontal", "column", "expandable", "form", "tabContainer", "tab", "chatMessage", "popover", "dialog"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Block.prototype, "_id", {
        get: $util.oneOfGetter($oneOfFields = ["id"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Block instance using the specified properties.
     * @function create
     * @memberof Block
     * @static
     * @param {IBlock=} [properties] Properties to set
     * @returns {Block} Block instance
     */
    Block.create = function create(properties) {
        return new Block(properties);
    };

    /**
     * Encodes the specified Block message. Does not implicitly {@link Block.verify|verify} messages.
     * @function encode
     * @memberof Block
     * @static
     * @param {IBlock} message Block message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Block.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.vertical != null && Object.hasOwnProperty.call(message, "vertical"))
            $root.Block.Vertical.encode(message.vertical, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.horizontal != null && Object.hasOwnProperty.call(message, "horizontal"))
            $root.Block.Horizontal.encode(message.horizontal, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.column != null && Object.hasOwnProperty.call(message, "column"))
            $root.Block.Column.encode(message.column, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.expandable != null && Object.hasOwnProperty.call(message, "expandable"))
            $root.Block.Expandable.encode(message.expandable, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.form != null && Object.hasOwnProperty.call(message, "form"))
            $root.Block.Form.encode(message.form, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.tabContainer != null && Object.hasOwnProperty.call(message, "tabContainer"))
            $root.Block.TabContainer.encode(message.tabContainer, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.tab != null && Object.hasOwnProperty.call(message, "tab"))
            $root.Block.Tab.encode(message.tab, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.allowEmpty != null && Object.hasOwnProperty.call(message, "allowEmpty"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.allowEmpty);
        if (message.chatMessage != null && Object.hasOwnProperty.call(message, "chatMessage"))
            $root.Block.ChatMessage.encode(message.chatMessage, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.popover != null && Object.hasOwnProperty.call(message, "popover"))
            $root.Block.Popover.encode(message.popover, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.dialog != null && Object.hasOwnProperty.call(message, "dialog"))
            $root.Block.Dialog.encode(message.dialog, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.id);
        return writer;
    };

    /**
     * Encodes the specified Block message, length delimited. Does not implicitly {@link Block.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Block
     * @static
     * @param {IBlock} message Block message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Block.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Block message from the specified reader or buffer.
     * @function decode
     * @memberof Block
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Block} Block
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Block.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.vertical = $root.Block.Vertical.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.horizontal = $root.Block.Horizontal.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.column = $root.Block.Column.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.expandable = $root.Block.Expandable.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.form = $root.Block.Form.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.tabContainer = $root.Block.TabContainer.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.tab = $root.Block.Tab.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.chatMessage = $root.Block.ChatMessage.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.popover = $root.Block.Popover.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.dialog = $root.Block.Dialog.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.allowEmpty = reader.bool();
                    break;
                }
            case 12: {
                    message.id = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Block message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Block
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Block} Block
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Block.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Block message.
     * @function verify
     * @memberof Block
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Block.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.vertical != null && message.hasOwnProperty("vertical")) {
            properties.type = 1;
            {
                let error = $root.Block.Vertical.verify(message.vertical);
                if (error)
                    return "vertical." + error;
            }
        }
        if (message.horizontal != null && message.hasOwnProperty("horizontal")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Horizontal.verify(message.horizontal);
                if (error)
                    return "horizontal." + error;
            }
        }
        if (message.column != null && message.hasOwnProperty("column")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Column.verify(message.column);
                if (error)
                    return "column." + error;
            }
        }
        if (message.expandable != null && message.hasOwnProperty("expandable")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Expandable.verify(message.expandable);
                if (error)
                    return "expandable." + error;
            }
        }
        if (message.form != null && message.hasOwnProperty("form")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Form.verify(message.form);
                if (error)
                    return "form." + error;
            }
        }
        if (message.tabContainer != null && message.hasOwnProperty("tabContainer")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.TabContainer.verify(message.tabContainer);
                if (error)
                    return "tabContainer." + error;
            }
        }
        if (message.tab != null && message.hasOwnProperty("tab")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Tab.verify(message.tab);
                if (error)
                    return "tab." + error;
            }
        }
        if (message.chatMessage != null && message.hasOwnProperty("chatMessage")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.ChatMessage.verify(message.chatMessage);
                if (error)
                    return "chatMessage." + error;
            }
        }
        if (message.popover != null && message.hasOwnProperty("popover")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Popover.verify(message.popover);
                if (error)
                    return "popover." + error;
            }
        }
        if (message.dialog != null && message.hasOwnProperty("dialog")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.Dialog.verify(message.dialog);
                if (error)
                    return "dialog." + error;
            }
        }
        if (message.allowEmpty != null && message.hasOwnProperty("allowEmpty"))
            if (typeof message.allowEmpty !== "boolean")
                return "allowEmpty: boolean expected";
        if (message.id != null && message.hasOwnProperty("id")) {
            properties._id = 1;
            if (!$util.isString(message.id))
                return "id: string expected";
        }
        return null;
    };

    /**
     * Creates a Block message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Block
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Block} Block
     */
    Block.fromObject = function fromObject(object) {
        if (object instanceof $root.Block)
            return object;
        let message = new $root.Block();
        if (object.vertical != null) {
            if (typeof object.vertical !== "object")
                throw TypeError(".Block.vertical: object expected");
            message.vertical = $root.Block.Vertical.fromObject(object.vertical);
        }
        if (object.horizontal != null) {
            if (typeof object.horizontal !== "object")
                throw TypeError(".Block.horizontal: object expected");
            message.horizontal = $root.Block.Horizontal.fromObject(object.horizontal);
        }
        if (object.column != null) {
            if (typeof object.column !== "object")
                throw TypeError(".Block.column: object expected");
            message.column = $root.Block.Column.fromObject(object.column);
        }
        if (object.expandable != null) {
            if (typeof object.expandable !== "object")
                throw TypeError(".Block.expandable: object expected");
            message.expandable = $root.Block.Expandable.fromObject(object.expandable);
        }
        if (object.form != null) {
            if (typeof object.form !== "object")
                throw TypeError(".Block.form: object expected");
            message.form = $root.Block.Form.fromObject(object.form);
        }
        if (object.tabContainer != null) {
            if (typeof object.tabContainer !== "object")
                throw TypeError(".Block.tabContainer: object expected");
            message.tabContainer = $root.Block.TabContainer.fromObject(object.tabContainer);
        }
        if (object.tab != null) {
            if (typeof object.tab !== "object")
                throw TypeError(".Block.tab: object expected");
            message.tab = $root.Block.Tab.fromObject(object.tab);
        }
        if (object.chatMessage != null) {
            if (typeof object.chatMessage !== "object")
                throw TypeError(".Block.chatMessage: object expected");
            message.chatMessage = $root.Block.ChatMessage.fromObject(object.chatMessage);
        }
        if (object.popover != null) {
            if (typeof object.popover !== "object")
                throw TypeError(".Block.popover: object expected");
            message.popover = $root.Block.Popover.fromObject(object.popover);
        }
        if (object.dialog != null) {
            if (typeof object.dialog !== "object")
                throw TypeError(".Block.dialog: object expected");
            message.dialog = $root.Block.Dialog.fromObject(object.dialog);
        }
        if (object.allowEmpty != null)
            message.allowEmpty = Boolean(object.allowEmpty);
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    /**
     * Creates a plain object from a Block message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Block
     * @static
     * @param {Block} message Block
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Block.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.allowEmpty = false;
        if (message.vertical != null && message.hasOwnProperty("vertical")) {
            object.vertical = $root.Block.Vertical.toObject(message.vertical, options);
            if (options.oneofs)
                object.type = "vertical";
        }
        if (message.horizontal != null && message.hasOwnProperty("horizontal")) {
            object.horizontal = $root.Block.Horizontal.toObject(message.horizontal, options);
            if (options.oneofs)
                object.type = "horizontal";
        }
        if (message.column != null && message.hasOwnProperty("column")) {
            object.column = $root.Block.Column.toObject(message.column, options);
            if (options.oneofs)
                object.type = "column";
        }
        if (message.expandable != null && message.hasOwnProperty("expandable")) {
            object.expandable = $root.Block.Expandable.toObject(message.expandable, options);
            if (options.oneofs)
                object.type = "expandable";
        }
        if (message.form != null && message.hasOwnProperty("form")) {
            object.form = $root.Block.Form.toObject(message.form, options);
            if (options.oneofs)
                object.type = "form";
        }
        if (message.tabContainer != null && message.hasOwnProperty("tabContainer")) {
            object.tabContainer = $root.Block.TabContainer.toObject(message.tabContainer, options);
            if (options.oneofs)
                object.type = "tabContainer";
        }
        if (message.tab != null && message.hasOwnProperty("tab")) {
            object.tab = $root.Block.Tab.toObject(message.tab, options);
            if (options.oneofs)
                object.type = "tab";
        }
        if (message.allowEmpty != null && message.hasOwnProperty("allowEmpty"))
            object.allowEmpty = message.allowEmpty;
        if (message.chatMessage != null && message.hasOwnProperty("chatMessage")) {
            object.chatMessage = $root.Block.ChatMessage.toObject(message.chatMessage, options);
            if (options.oneofs)
                object.type = "chatMessage";
        }
        if (message.popover != null && message.hasOwnProperty("popover")) {
            object.popover = $root.Block.Popover.toObject(message.popover, options);
            if (options.oneofs)
                object.type = "popover";
        }
        if (message.dialog != null && message.hasOwnProperty("dialog")) {
            object.dialog = $root.Block.Dialog.toObject(message.dialog, options);
            if (options.oneofs)
                object.type = "dialog";
        }
        if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
            if (options.oneofs)
                object._id = "id";
        }
        return object;
    };

    /**
     * Converts this Block to JSON.
     * @function toJSON
     * @memberof Block
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Block.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Block
     * @function getTypeUrl
     * @memberof Block
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Block.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Block";
    };

    Block.Vertical = (function() {

        /**
         * Properties of a Vertical.
         * @memberof Block
         * @interface IVertical
         * @property {boolean|null} [border] Vertical border
         * @property {number|null} [height] Vertical height
         */

        /**
         * Constructs a new Vertical.
         * @memberof Block
         * @classdesc Represents a Vertical.
         * @implements IVertical
         * @constructor
         * @param {Block.IVertical=} [properties] Properties to set
         */
        function Vertical(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Vertical border.
         * @member {boolean} border
         * @memberof Block.Vertical
         * @instance
         */
        Vertical.prototype.border = false;

        /**
         * Vertical height.
         * @member {number} height
         * @memberof Block.Vertical
         * @instance
         */
        Vertical.prototype.height = 0;

        /**
         * Creates a new Vertical instance using the specified properties.
         * @function create
         * @memberof Block.Vertical
         * @static
         * @param {Block.IVertical=} [properties] Properties to set
         * @returns {Block.Vertical} Vertical instance
         */
        Vertical.create = function create(properties) {
            return new Vertical(properties);
        };

        /**
         * Encodes the specified Vertical message. Does not implicitly {@link Block.Vertical.verify|verify} messages.
         * @function encode
         * @memberof Block.Vertical
         * @static
         * @param {Block.IVertical} message Vertical message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Vertical.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.border != null && Object.hasOwnProperty.call(message, "border"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.border);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.height);
            return writer;
        };

        /**
         * Encodes the specified Vertical message, length delimited. Does not implicitly {@link Block.Vertical.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Vertical
         * @static
         * @param {Block.IVertical} message Vertical message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Vertical.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Vertical message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Vertical
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Vertical} Vertical
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Vertical.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Vertical();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.border = reader.bool();
                        break;
                    }
                case 2: {
                        message.height = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Vertical message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Vertical
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Vertical} Vertical
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Vertical.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Vertical message.
         * @function verify
         * @memberof Block.Vertical
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Vertical.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.border != null && message.hasOwnProperty("border"))
                if (typeof message.border !== "boolean")
                    return "border: boolean expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a Vertical message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Vertical
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Vertical} Vertical
         */
        Vertical.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Vertical)
                return object;
            let message = new $root.Block.Vertical();
            if (object.border != null)
                message.border = Boolean(object.border);
            if (object.height != null)
                message.height = object.height >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Vertical message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Vertical
         * @static
         * @param {Block.Vertical} message Vertical
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Vertical.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.border = false;
                object.height = 0;
            }
            if (message.border != null && message.hasOwnProperty("border"))
                object.border = message.border;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this Vertical to JSON.
         * @function toJSON
         * @memberof Block.Vertical
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Vertical.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Vertical
         * @function getTypeUrl
         * @memberof Block.Vertical
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Vertical.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Vertical";
        };

        return Vertical;
    })();

    Block.Horizontal = (function() {

        /**
         * Properties of a Horizontal.
         * @memberof Block
         * @interface IHorizontal
         * @property {string|null} [gap] Horizontal gap
         */

        /**
         * Constructs a new Horizontal.
         * @memberof Block
         * @classdesc Represents a Horizontal.
         * @implements IHorizontal
         * @constructor
         * @param {Block.IHorizontal=} [properties] Properties to set
         */
        function Horizontal(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Horizontal gap.
         * @member {string} gap
         * @memberof Block.Horizontal
         * @instance
         */
        Horizontal.prototype.gap = "";

        /**
         * Creates a new Horizontal instance using the specified properties.
         * @function create
         * @memberof Block.Horizontal
         * @static
         * @param {Block.IHorizontal=} [properties] Properties to set
         * @returns {Block.Horizontal} Horizontal instance
         */
        Horizontal.create = function create(properties) {
            return new Horizontal(properties);
        };

        /**
         * Encodes the specified Horizontal message. Does not implicitly {@link Block.Horizontal.verify|verify} messages.
         * @function encode
         * @memberof Block.Horizontal
         * @static
         * @param {Block.IHorizontal} message Horizontal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Horizontal.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gap != null && Object.hasOwnProperty.call(message, "gap"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gap);
            return writer;
        };

        /**
         * Encodes the specified Horizontal message, length delimited. Does not implicitly {@link Block.Horizontal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Horizontal
         * @static
         * @param {Block.IHorizontal} message Horizontal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Horizontal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Horizontal message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Horizontal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Horizontal} Horizontal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Horizontal.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Horizontal();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.gap = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Horizontal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Horizontal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Horizontal} Horizontal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Horizontal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Horizontal message.
         * @function verify
         * @memberof Block.Horizontal
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Horizontal.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gap != null && message.hasOwnProperty("gap"))
                if (!$util.isString(message.gap))
                    return "gap: string expected";
            return null;
        };

        /**
         * Creates a Horizontal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Horizontal
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Horizontal} Horizontal
         */
        Horizontal.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Horizontal)
                return object;
            let message = new $root.Block.Horizontal();
            if (object.gap != null)
                message.gap = String(object.gap);
            return message;
        };

        /**
         * Creates a plain object from a Horizontal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Horizontal
         * @static
         * @param {Block.Horizontal} message Horizontal
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Horizontal.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.gap = "";
            if (message.gap != null && message.hasOwnProperty("gap"))
                object.gap = message.gap;
            return object;
        };

        /**
         * Converts this Horizontal to JSON.
         * @function toJSON
         * @memberof Block.Horizontal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Horizontal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Horizontal
         * @function getTypeUrl
         * @memberof Block.Horizontal
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Horizontal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Horizontal";
        };

        return Horizontal;
    })();

    Block.Column = (function() {

        /**
         * Properties of a Column.
         * @memberof Block
         * @interface IColumn
         * @property {number|null} [weight] Column weight
         * @property {string|null} [gap] Column gap
         * @property {Block.Column.VerticalAlignment|null} [verticalAlignment] Column verticalAlignment
         * @property {boolean|null} [showBorder] Column showBorder
         */

        /**
         * Constructs a new Column.
         * @memberof Block
         * @classdesc Represents a Column.
         * @implements IColumn
         * @constructor
         * @param {Block.IColumn=} [properties] Properties to set
         */
        function Column(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Column weight.
         * @member {number} weight
         * @memberof Block.Column
         * @instance
         */
        Column.prototype.weight = 0;

        /**
         * Column gap.
         * @member {string} gap
         * @memberof Block.Column
         * @instance
         */
        Column.prototype.gap = "";

        /**
         * Column verticalAlignment.
         * @member {Block.Column.VerticalAlignment} verticalAlignment
         * @memberof Block.Column
         * @instance
         */
        Column.prototype.verticalAlignment = 0;

        /**
         * Column showBorder.
         * @member {boolean} showBorder
         * @memberof Block.Column
         * @instance
         */
        Column.prototype.showBorder = false;

        /**
         * Creates a new Column instance using the specified properties.
         * @function create
         * @memberof Block.Column
         * @static
         * @param {Block.IColumn=} [properties] Properties to set
         * @returns {Block.Column} Column instance
         */
        Column.create = function create(properties) {
            return new Column(properties);
        };

        /**
         * Encodes the specified Column message. Does not implicitly {@link Block.Column.verify|verify} messages.
         * @function encode
         * @memberof Block.Column
         * @static
         * @param {Block.IColumn} message Column message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Column.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.weight);
            if (message.gap != null && Object.hasOwnProperty.call(message, "gap"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gap);
            if (message.verticalAlignment != null && Object.hasOwnProperty.call(message, "verticalAlignment"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.verticalAlignment);
            if (message.showBorder != null && Object.hasOwnProperty.call(message, "showBorder"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.showBorder);
            return writer;
        };

        /**
         * Encodes the specified Column message, length delimited. Does not implicitly {@link Block.Column.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Column
         * @static
         * @param {Block.IColumn} message Column message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Column.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Column message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Column
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Column} Column
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Column.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Column();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.weight = reader.double();
                        break;
                    }
                case 2: {
                        message.gap = reader.string();
                        break;
                    }
                case 3: {
                        message.verticalAlignment = reader.int32();
                        break;
                    }
                case 4: {
                        message.showBorder = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Column message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Column
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Column} Column
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Column.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Column message.
         * @function verify
         * @memberof Block.Column
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Column.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (typeof message.weight !== "number")
                    return "weight: number expected";
            if (message.gap != null && message.hasOwnProperty("gap"))
                if (!$util.isString(message.gap))
                    return "gap: string expected";
            if (message.verticalAlignment != null && message.hasOwnProperty("verticalAlignment"))
                switch (message.verticalAlignment) {
                default:
                    return "verticalAlignment: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.showBorder != null && message.hasOwnProperty("showBorder"))
                if (typeof message.showBorder !== "boolean")
                    return "showBorder: boolean expected";
            return null;
        };

        /**
         * Creates a Column message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Column
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Column} Column
         */
        Column.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Column)
                return object;
            let message = new $root.Block.Column();
            if (object.weight != null)
                message.weight = Number(object.weight);
            if (object.gap != null)
                message.gap = String(object.gap);
            switch (object.verticalAlignment) {
            default:
                if (typeof object.verticalAlignment === "number") {
                    message.verticalAlignment = object.verticalAlignment;
                    break;
                }
                break;
            case "TOP":
            case 0:
                message.verticalAlignment = 0;
                break;
            case "CENTER":
            case 1:
                message.verticalAlignment = 1;
                break;
            case "BOTTOM":
            case 2:
                message.verticalAlignment = 2;
                break;
            }
            if (object.showBorder != null)
                message.showBorder = Boolean(object.showBorder);
            return message;
        };

        /**
         * Creates a plain object from a Column message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Column
         * @static
         * @param {Block.Column} message Column
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Column.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.weight = 0;
                object.gap = "";
                object.verticalAlignment = options.enums === String ? "TOP" : 0;
                object.showBorder = false;
            }
            if (message.weight != null && message.hasOwnProperty("weight"))
                object.weight = options.json && !isFinite(message.weight) ? String(message.weight) : message.weight;
            if (message.gap != null && message.hasOwnProperty("gap"))
                object.gap = message.gap;
            if (message.verticalAlignment != null && message.hasOwnProperty("verticalAlignment"))
                object.verticalAlignment = options.enums === String ? $root.Block.Column.VerticalAlignment[message.verticalAlignment] === undefined ? message.verticalAlignment : $root.Block.Column.VerticalAlignment[message.verticalAlignment] : message.verticalAlignment;
            if (message.showBorder != null && message.hasOwnProperty("showBorder"))
                object.showBorder = message.showBorder;
            return object;
        };

        /**
         * Converts this Column to JSON.
         * @function toJSON
         * @memberof Block.Column
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Column.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Column
         * @function getTypeUrl
         * @memberof Block.Column
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Column.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Column";
        };

        /**
         * VerticalAlignment enum.
         * @name Block.Column.VerticalAlignment
         * @enum {number}
         * @property {number} TOP=0 TOP value
         * @property {number} CENTER=1 CENTER value
         * @property {number} BOTTOM=2 BOTTOM value
         */
        Column.VerticalAlignment = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TOP"] = 0;
            values[valuesById[1] = "CENTER"] = 1;
            values[valuesById[2] = "BOTTOM"] = 2;
            return values;
        })();

        return Column;
    })();

    Block.Expandable = (function() {

        /**
         * Properties of an Expandable.
         * @memberof Block
         * @interface IExpandable
         * @property {string|null} [label] Expandable label
         * @property {boolean|null} [expanded] Expandable expanded
         * @property {string|null} [icon] Expandable icon
         */

        /**
         * Constructs a new Expandable.
         * @memberof Block
         * @classdesc Represents an Expandable.
         * @implements IExpandable
         * @constructor
         * @param {Block.IExpandable=} [properties] Properties to set
         */
        function Expandable(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Expandable label.
         * @member {string} label
         * @memberof Block.Expandable
         * @instance
         */
        Expandable.prototype.label = "";

        /**
         * Expandable expanded.
         * @member {boolean|null|undefined} expanded
         * @memberof Block.Expandable
         * @instance
         */
        Expandable.prototype.expanded = null;

        /**
         * Expandable icon.
         * @member {string} icon
         * @memberof Block.Expandable
         * @instance
         */
        Expandable.prototype.icon = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(Expandable.prototype, "_expanded", {
            get: $util.oneOfGetter($oneOfFields = ["expanded"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Expandable instance using the specified properties.
         * @function create
         * @memberof Block.Expandable
         * @static
         * @param {Block.IExpandable=} [properties] Properties to set
         * @returns {Block.Expandable} Expandable instance
         */
        Expandable.create = function create(properties) {
            return new Expandable(properties);
        };

        /**
         * Encodes the specified Expandable message. Does not implicitly {@link Block.Expandable.verify|verify} messages.
         * @function encode
         * @memberof Block.Expandable
         * @static
         * @param {Block.IExpandable} message Expandable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Expandable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.expanded != null && Object.hasOwnProperty.call(message, "expanded"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.expanded);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.icon);
            return writer;
        };

        /**
         * Encodes the specified Expandable message, length delimited. Does not implicitly {@link Block.Expandable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Expandable
         * @static
         * @param {Block.IExpandable} message Expandable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Expandable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Expandable message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Expandable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Expandable} Expandable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Expandable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Expandable();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.label = reader.string();
                        break;
                    }
                case 2: {
                        message.expanded = reader.bool();
                        break;
                    }
                case 3: {
                        message.icon = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Expandable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Expandable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Expandable} Expandable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Expandable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Expandable message.
         * @function verify
         * @memberof Block.Expandable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Expandable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.expanded != null && message.hasOwnProperty("expanded")) {
                properties._expanded = 1;
                if (typeof message.expanded !== "boolean")
                    return "expanded: boolean expected";
            }
            if (message.icon != null && message.hasOwnProperty("icon"))
                if (!$util.isString(message.icon))
                    return "icon: string expected";
            return null;
        };

        /**
         * Creates an Expandable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Expandable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Expandable} Expandable
         */
        Expandable.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Expandable)
                return object;
            let message = new $root.Block.Expandable();
            if (object.label != null)
                message.label = String(object.label);
            if (object.expanded != null)
                message.expanded = Boolean(object.expanded);
            if (object.icon != null)
                message.icon = String(object.icon);
            return message;
        };

        /**
         * Creates a plain object from an Expandable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Expandable
         * @static
         * @param {Block.Expandable} message Expandable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Expandable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.icon = "";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.expanded != null && message.hasOwnProperty("expanded")) {
                object.expanded = message.expanded;
                if (options.oneofs)
                    object._expanded = "expanded";
            }
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = message.icon;
            return object;
        };

        /**
         * Converts this Expandable to JSON.
         * @function toJSON
         * @memberof Block.Expandable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Expandable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Expandable
         * @function getTypeUrl
         * @memberof Block.Expandable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Expandable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Expandable";
        };

        return Expandable;
    })();

    Block.Dialog = (function() {

        /**
         * Properties of a Dialog.
         * @memberof Block
         * @interface IDialog
         * @property {string|null} [title] Dialog title
         * @property {boolean|null} [dismissible] Dialog dismissible
         * @property {Block.Dialog.DialogWidth|null} [width] Dialog width
         * @property {boolean|null} [isOpen] Dialog isOpen
         */

        /**
         * Constructs a new Dialog.
         * @memberof Block
         * @classdesc Represents a Dialog.
         * @implements IDialog
         * @constructor
         * @param {Block.IDialog=} [properties] Properties to set
         */
        function Dialog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Dialog title.
         * @member {string} title
         * @memberof Block.Dialog
         * @instance
         */
        Dialog.prototype.title = "";

        /**
         * Dialog dismissible.
         * @member {boolean} dismissible
         * @memberof Block.Dialog
         * @instance
         */
        Dialog.prototype.dismissible = false;

        /**
         * Dialog width.
         * @member {Block.Dialog.DialogWidth} width
         * @memberof Block.Dialog
         * @instance
         */
        Dialog.prototype.width = 0;

        /**
         * Dialog isOpen.
         * @member {boolean|null|undefined} isOpen
         * @memberof Block.Dialog
         * @instance
         */
        Dialog.prototype.isOpen = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(Dialog.prototype, "_isOpen", {
            get: $util.oneOfGetter($oneOfFields = ["isOpen"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Dialog instance using the specified properties.
         * @function create
         * @memberof Block.Dialog
         * @static
         * @param {Block.IDialog=} [properties] Properties to set
         * @returns {Block.Dialog} Dialog instance
         */
        Dialog.create = function create(properties) {
            return new Dialog(properties);
        };

        /**
         * Encodes the specified Dialog message. Does not implicitly {@link Block.Dialog.verify|verify} messages.
         * @function encode
         * @memberof Block.Dialog
         * @static
         * @param {Block.IDialog} message Dialog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Dialog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.dismissible != null && Object.hasOwnProperty.call(message, "dismissible"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.dismissible);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.width);
            if (message.isOpen != null && Object.hasOwnProperty.call(message, "isOpen"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isOpen);
            return writer;
        };

        /**
         * Encodes the specified Dialog message, length delimited. Does not implicitly {@link Block.Dialog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Dialog
         * @static
         * @param {Block.IDialog} message Dialog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Dialog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Dialog message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Dialog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Dialog} Dialog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Dialog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Dialog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.title = reader.string();
                        break;
                    }
                case 2: {
                        message.dismissible = reader.bool();
                        break;
                    }
                case 3: {
                        message.width = reader.int32();
                        break;
                    }
                case 4: {
                        message.isOpen = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Dialog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Dialog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Dialog} Dialog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Dialog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Dialog message.
         * @function verify
         * @memberof Block.Dialog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Dialog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.dismissible != null && message.hasOwnProperty("dismissible"))
                if (typeof message.dismissible !== "boolean")
                    return "dismissible: boolean expected";
            if (message.width != null && message.hasOwnProperty("width"))
                switch (message.width) {
                default:
                    return "width: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.isOpen != null && message.hasOwnProperty("isOpen")) {
                properties._isOpen = 1;
                if (typeof message.isOpen !== "boolean")
                    return "isOpen: boolean expected";
            }
            return null;
        };

        /**
         * Creates a Dialog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Dialog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Dialog} Dialog
         */
        Dialog.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Dialog)
                return object;
            let message = new $root.Block.Dialog();
            if (object.title != null)
                message.title = String(object.title);
            if (object.dismissible != null)
                message.dismissible = Boolean(object.dismissible);
            switch (object.width) {
            default:
                if (typeof object.width === "number") {
                    message.width = object.width;
                    break;
                }
                break;
            case "SMALL":
            case 0:
                message.width = 0;
                break;
            case "LARGE":
            case 1:
                message.width = 1;
                break;
            }
            if (object.isOpen != null)
                message.isOpen = Boolean(object.isOpen);
            return message;
        };

        /**
         * Creates a plain object from a Dialog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Dialog
         * @static
         * @param {Block.Dialog} message Dialog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Dialog.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.title = "";
                object.dismissible = false;
                object.width = options.enums === String ? "SMALL" : 0;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.dismissible != null && message.hasOwnProperty("dismissible"))
                object.dismissible = message.dismissible;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = options.enums === String ? $root.Block.Dialog.DialogWidth[message.width] === undefined ? message.width : $root.Block.Dialog.DialogWidth[message.width] : message.width;
            if (message.isOpen != null && message.hasOwnProperty("isOpen")) {
                object.isOpen = message.isOpen;
                if (options.oneofs)
                    object._isOpen = "isOpen";
            }
            return object;
        };

        /**
         * Converts this Dialog to JSON.
         * @function toJSON
         * @memberof Block.Dialog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Dialog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Dialog
         * @function getTypeUrl
         * @memberof Block.Dialog
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Dialog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Dialog";
        };

        /**
         * DialogWidth enum.
         * @name Block.Dialog.DialogWidth
         * @enum {number}
         * @property {number} SMALL=0 SMALL value
         * @property {number} LARGE=1 LARGE value
         */
        Dialog.DialogWidth = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SMALL"] = 0;
            values[valuesById[1] = "LARGE"] = 1;
            return values;
        })();

        return Dialog;
    })();

    Block.Form = (function() {

        /**
         * Properties of a Form.
         * @memberof Block
         * @interface IForm
         * @property {string|null} [formId] Form formId
         * @property {boolean|null} [clearOnSubmit] Form clearOnSubmit
         * @property {boolean|null} [border] Form border
         * @property {boolean|null} [enterToSubmit] Form enterToSubmit
         */

        /**
         * Constructs a new Form.
         * @memberof Block
         * @classdesc Represents a Form.
         * @implements IForm
         * @constructor
         * @param {Block.IForm=} [properties] Properties to set
         */
        function Form(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Form formId.
         * @member {string} formId
         * @memberof Block.Form
         * @instance
         */
        Form.prototype.formId = "";

        /**
         * Form clearOnSubmit.
         * @member {boolean} clearOnSubmit
         * @memberof Block.Form
         * @instance
         */
        Form.prototype.clearOnSubmit = false;

        /**
         * Form border.
         * @member {boolean} border
         * @memberof Block.Form
         * @instance
         */
        Form.prototype.border = false;

        /**
         * Form enterToSubmit.
         * @member {boolean} enterToSubmit
         * @memberof Block.Form
         * @instance
         */
        Form.prototype.enterToSubmit = false;

        /**
         * Creates a new Form instance using the specified properties.
         * @function create
         * @memberof Block.Form
         * @static
         * @param {Block.IForm=} [properties] Properties to set
         * @returns {Block.Form} Form instance
         */
        Form.create = function create(properties) {
            return new Form(properties);
        };

        /**
         * Encodes the specified Form message. Does not implicitly {@link Block.Form.verify|verify} messages.
         * @function encode
         * @memberof Block.Form
         * @static
         * @param {Block.IForm} message Form message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Form.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.formId);
            if (message.clearOnSubmit != null && Object.hasOwnProperty.call(message, "clearOnSubmit"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.clearOnSubmit);
            if (message.border != null && Object.hasOwnProperty.call(message, "border"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.border);
            if (message.enterToSubmit != null && Object.hasOwnProperty.call(message, "enterToSubmit"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enterToSubmit);
            return writer;
        };

        /**
         * Encodes the specified Form message, length delimited. Does not implicitly {@link Block.Form.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Form
         * @static
         * @param {Block.IForm} message Form message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Form.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Form message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Form
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Form} Form
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Form.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Form();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.formId = reader.string();
                        break;
                    }
                case 2: {
                        message.clearOnSubmit = reader.bool();
                        break;
                    }
                case 3: {
                        message.border = reader.bool();
                        break;
                    }
                case 4: {
                        message.enterToSubmit = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Form message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Form
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Form} Form
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Form.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Form message.
         * @function verify
         * @memberof Block.Form
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Form.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.formId != null && message.hasOwnProperty("formId"))
                if (!$util.isString(message.formId))
                    return "formId: string expected";
            if (message.clearOnSubmit != null && message.hasOwnProperty("clearOnSubmit"))
                if (typeof message.clearOnSubmit !== "boolean")
                    return "clearOnSubmit: boolean expected";
            if (message.border != null && message.hasOwnProperty("border"))
                if (typeof message.border !== "boolean")
                    return "border: boolean expected";
            if (message.enterToSubmit != null && message.hasOwnProperty("enterToSubmit"))
                if (typeof message.enterToSubmit !== "boolean")
                    return "enterToSubmit: boolean expected";
            return null;
        };

        /**
         * Creates a Form message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Form
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Form} Form
         */
        Form.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Form)
                return object;
            let message = new $root.Block.Form();
            if (object.formId != null)
                message.formId = String(object.formId);
            if (object.clearOnSubmit != null)
                message.clearOnSubmit = Boolean(object.clearOnSubmit);
            if (object.border != null)
                message.border = Boolean(object.border);
            if (object.enterToSubmit != null)
                message.enterToSubmit = Boolean(object.enterToSubmit);
            return message;
        };

        /**
         * Creates a plain object from a Form message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Form
         * @static
         * @param {Block.Form} message Form
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Form.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.formId = "";
                object.clearOnSubmit = false;
                object.border = false;
                object.enterToSubmit = false;
            }
            if (message.formId != null && message.hasOwnProperty("formId"))
                object.formId = message.formId;
            if (message.clearOnSubmit != null && message.hasOwnProperty("clearOnSubmit"))
                object.clearOnSubmit = message.clearOnSubmit;
            if (message.border != null && message.hasOwnProperty("border"))
                object.border = message.border;
            if (message.enterToSubmit != null && message.hasOwnProperty("enterToSubmit"))
                object.enterToSubmit = message.enterToSubmit;
            return object;
        };

        /**
         * Converts this Form to JSON.
         * @function toJSON
         * @memberof Block.Form
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Form.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Form
         * @function getTypeUrl
         * @memberof Block.Form
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Form.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Form";
        };

        return Form;
    })();

    Block.TabContainer = (function() {

        /**
         * Properties of a TabContainer.
         * @memberof Block
         * @interface ITabContainer
         */

        /**
         * Constructs a new TabContainer.
         * @memberof Block
         * @classdesc Represents a TabContainer.
         * @implements ITabContainer
         * @constructor
         * @param {Block.ITabContainer=} [properties] Properties to set
         */
        function TabContainer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TabContainer instance using the specified properties.
         * @function create
         * @memberof Block.TabContainer
         * @static
         * @param {Block.ITabContainer=} [properties] Properties to set
         * @returns {Block.TabContainer} TabContainer instance
         */
        TabContainer.create = function create(properties) {
            return new TabContainer(properties);
        };

        /**
         * Encodes the specified TabContainer message. Does not implicitly {@link Block.TabContainer.verify|verify} messages.
         * @function encode
         * @memberof Block.TabContainer
         * @static
         * @param {Block.ITabContainer} message TabContainer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TabContainer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TabContainer message, length delimited. Does not implicitly {@link Block.TabContainer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.TabContainer
         * @static
         * @param {Block.ITabContainer} message TabContainer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TabContainer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TabContainer message from the specified reader or buffer.
         * @function decode
         * @memberof Block.TabContainer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.TabContainer} TabContainer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TabContainer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.TabContainer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TabContainer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.TabContainer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.TabContainer} TabContainer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TabContainer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TabContainer message.
         * @function verify
         * @memberof Block.TabContainer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TabContainer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TabContainer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.TabContainer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.TabContainer} TabContainer
         */
        TabContainer.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.TabContainer)
                return object;
            return new $root.Block.TabContainer();
        };

        /**
         * Creates a plain object from a TabContainer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.TabContainer
         * @static
         * @param {Block.TabContainer} message TabContainer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TabContainer.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TabContainer to JSON.
         * @function toJSON
         * @memberof Block.TabContainer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TabContainer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TabContainer
         * @function getTypeUrl
         * @memberof Block.TabContainer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TabContainer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.TabContainer";
        };

        return TabContainer;
    })();

    Block.Tab = (function() {

        /**
         * Properties of a Tab.
         * @memberof Block
         * @interface ITab
         * @property {string|null} [label] Tab label
         */

        /**
         * Constructs a new Tab.
         * @memberof Block
         * @classdesc Represents a Tab.
         * @implements ITab
         * @constructor
         * @param {Block.ITab=} [properties] Properties to set
         */
        function Tab(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tab label.
         * @member {string} label
         * @memberof Block.Tab
         * @instance
         */
        Tab.prototype.label = "";

        /**
         * Creates a new Tab instance using the specified properties.
         * @function create
         * @memberof Block.Tab
         * @static
         * @param {Block.ITab=} [properties] Properties to set
         * @returns {Block.Tab} Tab instance
         */
        Tab.create = function create(properties) {
            return new Tab(properties);
        };

        /**
         * Encodes the specified Tab message. Does not implicitly {@link Block.Tab.verify|verify} messages.
         * @function encode
         * @memberof Block.Tab
         * @static
         * @param {Block.ITab} message Tab message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tab.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            return writer;
        };

        /**
         * Encodes the specified Tab message, length delimited. Does not implicitly {@link Block.Tab.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Tab
         * @static
         * @param {Block.ITab} message Tab message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tab.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Tab message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Tab
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Tab} Tab
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tab.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Tab();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.label = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Tab message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Tab
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Tab} Tab
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tab.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Tab message.
         * @function verify
         * @memberof Block.Tab
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Tab.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            return null;
        };

        /**
         * Creates a Tab message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Tab
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Tab} Tab
         */
        Tab.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Tab)
                return object;
            let message = new $root.Block.Tab();
            if (object.label != null)
                message.label = String(object.label);
            return message;
        };

        /**
         * Creates a plain object from a Tab message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Tab
         * @static
         * @param {Block.Tab} message Tab
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tab.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.label = "";
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            return object;
        };

        /**
         * Converts this Tab to JSON.
         * @function toJSON
         * @memberof Block.Tab
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tab.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Tab
         * @function getTypeUrl
         * @memberof Block.Tab
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Tab.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Tab";
        };

        return Tab;
    })();

    Block.Popover = (function() {

        /**
         * Properties of a Popover.
         * @memberof Block
         * @interface IPopover
         * @property {string|null} [label] Popover label
         * @property {boolean|null} [useContainerWidth] Popover useContainerWidth
         * @property {string|null} [help] Popover help
         * @property {boolean|null} [disabled] Popover disabled
         * @property {string|null} [icon] Popover icon
         */

        /**
         * Constructs a new Popover.
         * @memberof Block
         * @classdesc Represents a Popover.
         * @implements IPopover
         * @constructor
         * @param {Block.IPopover=} [properties] Properties to set
         */
        function Popover(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Popover label.
         * @member {string} label
         * @memberof Block.Popover
         * @instance
         */
        Popover.prototype.label = "";

        /**
         * Popover useContainerWidth.
         * @member {boolean} useContainerWidth
         * @memberof Block.Popover
         * @instance
         */
        Popover.prototype.useContainerWidth = false;

        /**
         * Popover help.
         * @member {string} help
         * @memberof Block.Popover
         * @instance
         */
        Popover.prototype.help = "";

        /**
         * Popover disabled.
         * @member {boolean} disabled
         * @memberof Block.Popover
         * @instance
         */
        Popover.prototype.disabled = false;

        /**
         * Popover icon.
         * @member {string} icon
         * @memberof Block.Popover
         * @instance
         */
        Popover.prototype.icon = "";

        /**
         * Creates a new Popover instance using the specified properties.
         * @function create
         * @memberof Block.Popover
         * @static
         * @param {Block.IPopover=} [properties] Properties to set
         * @returns {Block.Popover} Popover instance
         */
        Popover.create = function create(properties) {
            return new Popover(properties);
        };

        /**
         * Encodes the specified Popover message. Does not implicitly {@link Block.Popover.verify|verify} messages.
         * @function encode
         * @memberof Block.Popover
         * @static
         * @param {Block.IPopover} message Popover message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Popover.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.useContainerWidth);
            if (message.help != null && Object.hasOwnProperty.call(message, "help"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.help);
            if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.disabled);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.icon);
            return writer;
        };

        /**
         * Encodes the specified Popover message, length delimited. Does not implicitly {@link Block.Popover.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.Popover
         * @static
         * @param {Block.IPopover} message Popover message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Popover.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Popover message from the specified reader or buffer.
         * @function decode
         * @memberof Block.Popover
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.Popover} Popover
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Popover.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.Popover();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.label = reader.string();
                        break;
                    }
                case 2: {
                        message.useContainerWidth = reader.bool();
                        break;
                    }
                case 3: {
                        message.help = reader.string();
                        break;
                    }
                case 4: {
                        message.disabled = reader.bool();
                        break;
                    }
                case 5: {
                        message.icon = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Popover message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.Popover
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.Popover} Popover
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Popover.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Popover message.
         * @function verify
         * @memberof Block.Popover
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Popover.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
                if (typeof message.useContainerWidth !== "boolean")
                    return "useContainerWidth: boolean expected";
            if (message.help != null && message.hasOwnProperty("help"))
                if (!$util.isString(message.help))
                    return "help: string expected";
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                if (typeof message.disabled !== "boolean")
                    return "disabled: boolean expected";
            if (message.icon != null && message.hasOwnProperty("icon"))
                if (!$util.isString(message.icon))
                    return "icon: string expected";
            return null;
        };

        /**
         * Creates a Popover message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.Popover
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.Popover} Popover
         */
        Popover.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.Popover)
                return object;
            let message = new $root.Block.Popover();
            if (object.label != null)
                message.label = String(object.label);
            if (object.useContainerWidth != null)
                message.useContainerWidth = Boolean(object.useContainerWidth);
            if (object.help != null)
                message.help = String(object.help);
            if (object.disabled != null)
                message.disabled = Boolean(object.disabled);
            if (object.icon != null)
                message.icon = String(object.icon);
            return message;
        };

        /**
         * Creates a plain object from a Popover message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.Popover
         * @static
         * @param {Block.Popover} message Popover
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Popover.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.useContainerWidth = false;
                object.help = "";
                object.disabled = false;
                object.icon = "";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
                object.useContainerWidth = message.useContainerWidth;
            if (message.help != null && message.hasOwnProperty("help"))
                object.help = message.help;
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                object.disabled = message.disabled;
            if (message.icon != null && message.hasOwnProperty("icon"))
                object.icon = message.icon;
            return object;
        };

        /**
         * Converts this Popover to JSON.
         * @function toJSON
         * @memberof Block.Popover
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Popover.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Popover
         * @function getTypeUrl
         * @memberof Block.Popover
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Popover.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.Popover";
        };

        return Popover;
    })();

    Block.ChatMessage = (function() {

        /**
         * Properties of a ChatMessage.
         * @memberof Block
         * @interface IChatMessage
         * @property {string|null} [name] ChatMessage name
         * @property {string|null} [avatar] ChatMessage avatar
         * @property {Block.ChatMessage.AvatarType|null} [avatarType] ChatMessage avatarType
         */

        /**
         * Constructs a new ChatMessage.
         * @memberof Block
         * @classdesc Represents a ChatMessage.
         * @implements IChatMessage
         * @constructor
         * @param {Block.IChatMessage=} [properties] Properties to set
         */
        function ChatMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatMessage name.
         * @member {string} name
         * @memberof Block.ChatMessage
         * @instance
         */
        ChatMessage.prototype.name = "";

        /**
         * ChatMessage avatar.
         * @member {string} avatar
         * @memberof Block.ChatMessage
         * @instance
         */
        ChatMessage.prototype.avatar = "";

        /**
         * ChatMessage avatarType.
         * @member {Block.ChatMessage.AvatarType} avatarType
         * @memberof Block.ChatMessage
         * @instance
         */
        ChatMessage.prototype.avatarType = 0;

        /**
         * Creates a new ChatMessage instance using the specified properties.
         * @function create
         * @memberof Block.ChatMessage
         * @static
         * @param {Block.IChatMessage=} [properties] Properties to set
         * @returns {Block.ChatMessage} ChatMessage instance
         */
        ChatMessage.create = function create(properties) {
            return new ChatMessage(properties);
        };

        /**
         * Encodes the specified ChatMessage message. Does not implicitly {@link Block.ChatMessage.verify|verify} messages.
         * @function encode
         * @memberof Block.ChatMessage
         * @static
         * @param {Block.IChatMessage} message ChatMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
            if (message.avatarType != null && Object.hasOwnProperty.call(message, "avatarType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.avatarType);
            return writer;
        };

        /**
         * Encodes the specified ChatMessage message, length delimited. Does not implicitly {@link Block.ChatMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Block.ChatMessage
         * @static
         * @param {Block.IChatMessage} message ChatMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatMessage message from the specified reader or buffer.
         * @function decode
         * @memberof Block.ChatMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Block.ChatMessage} ChatMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Block.ChatMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.avatar = reader.string();
                        break;
                    }
                case 3: {
                        message.avatarType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Block.ChatMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Block.ChatMessage} ChatMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatMessage message.
         * @function verify
         * @memberof Block.ChatMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.avatarType != null && message.hasOwnProperty("avatarType"))
                switch (message.avatarType) {
                default:
                    return "avatarType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a ChatMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Block.ChatMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Block.ChatMessage} ChatMessage
         */
        ChatMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.Block.ChatMessage)
                return object;
            let message = new $root.Block.ChatMessage();
            if (object.name != null)
                message.name = String(object.name);
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            switch (object.avatarType) {
            default:
                if (typeof object.avatarType === "number") {
                    message.avatarType = object.avatarType;
                    break;
                }
                break;
            case "IMAGE":
            case 0:
                message.avatarType = 0;
                break;
            case "EMOJI":
            case 1:
                message.avatarType = 1;
                break;
            case "ICON":
            case 2:
                message.avatarType = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ChatMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Block.ChatMessage
         * @static
         * @param {Block.ChatMessage} message ChatMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.avatar = "";
                object.avatarType = options.enums === String ? "IMAGE" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            if (message.avatarType != null && message.hasOwnProperty("avatarType"))
                object.avatarType = options.enums === String ? $root.Block.ChatMessage.AvatarType[message.avatarType] === undefined ? message.avatarType : $root.Block.ChatMessage.AvatarType[message.avatarType] : message.avatarType;
            return object;
        };

        /**
         * Converts this ChatMessage to JSON.
         * @function toJSON
         * @memberof Block.ChatMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChatMessage
         * @function getTypeUrl
         * @memberof Block.ChatMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChatMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Block.ChatMessage";
        };

        /**
         * AvatarType enum.
         * @name Block.ChatMessage.AvatarType
         * @enum {number}
         * @property {number} IMAGE=0 IMAGE value
         * @property {number} EMOJI=1 EMOJI value
         * @property {number} ICON=2 ICON value
         */
        ChatMessage.AvatarType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "IMAGE"] = 0;
            values[valuesById[1] = "EMOJI"] = 1;
            values[valuesById[2] = "ICON"] = 2;
            return values;
        })();

        return ChatMessage;
    })();

    return Block;
})();

export const BokehChart = $root.BokehChart = (() => {

    /**
     * Properties of a BokehChart.
     * @exports IBokehChart
     * @interface IBokehChart
     * @property {string|null} [figure] BokehChart figure
     * @property {boolean|null} [useContainerWidth] BokehChart useContainerWidth
     * @property {string|null} [elementId] BokehChart elementId
     */

    /**
     * Constructs a new BokehChart.
     * @exports BokehChart
     * @classdesc Represents a BokehChart.
     * @implements IBokehChart
     * @constructor
     * @param {IBokehChart=} [properties] Properties to set
     */
    function BokehChart(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BokehChart figure.
     * @member {string} figure
     * @memberof BokehChart
     * @instance
     */
    BokehChart.prototype.figure = "";

    /**
     * BokehChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof BokehChart
     * @instance
     */
    BokehChart.prototype.useContainerWidth = false;

    /**
     * BokehChart elementId.
     * @member {string} elementId
     * @memberof BokehChart
     * @instance
     */
    BokehChart.prototype.elementId = "";

    /**
     * Creates a new BokehChart instance using the specified properties.
     * @function create
     * @memberof BokehChart
     * @static
     * @param {IBokehChart=} [properties] Properties to set
     * @returns {BokehChart} BokehChart instance
     */
    BokehChart.create = function create(properties) {
        return new BokehChart(properties);
    };

    /**
     * Encodes the specified BokehChart message. Does not implicitly {@link BokehChart.verify|verify} messages.
     * @function encode
     * @memberof BokehChart
     * @static
     * @param {IBokehChart} message BokehChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BokehChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.figure != null && Object.hasOwnProperty.call(message, "figure"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.figure);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.useContainerWidth);
        if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.elementId);
        return writer;
    };

    /**
     * Encodes the specified BokehChart message, length delimited. Does not implicitly {@link BokehChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BokehChart
     * @static
     * @param {IBokehChart} message BokehChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BokehChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BokehChart message from the specified reader or buffer.
     * @function decode
     * @memberof BokehChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BokehChart} BokehChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BokehChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BokehChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.figure = reader.string();
                    break;
                }
            case 2: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 3: {
                    message.elementId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BokehChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BokehChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BokehChart} BokehChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BokehChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BokehChart message.
     * @function verify
     * @memberof BokehChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BokehChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.figure != null && message.hasOwnProperty("figure"))
            if (!$util.isString(message.figure))
                return "figure: string expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.elementId != null && message.hasOwnProperty("elementId"))
            if (!$util.isString(message.elementId))
                return "elementId: string expected";
        return null;
    };

    /**
     * Creates a BokehChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BokehChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BokehChart} BokehChart
     */
    BokehChart.fromObject = function fromObject(object) {
        if (object instanceof $root.BokehChart)
            return object;
        let message = new $root.BokehChart();
        if (object.figure != null)
            message.figure = String(object.figure);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.elementId != null)
            message.elementId = String(object.elementId);
        return message;
    };

    /**
     * Creates a plain object from a BokehChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BokehChart
     * @static
     * @param {BokehChart} message BokehChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BokehChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.figure = "";
            object.useContainerWidth = false;
            object.elementId = "";
        }
        if (message.figure != null && message.hasOwnProperty("figure"))
            object.figure = message.figure;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.elementId != null && message.hasOwnProperty("elementId"))
            object.elementId = message.elementId;
        return object;
    };

    /**
     * Converts this BokehChart to JSON.
     * @function toJSON
     * @memberof BokehChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BokehChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BokehChart
     * @function getTypeUrl
     * @memberof BokehChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BokehChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BokehChart";
    };

    return BokehChart;
})();

export const Button = $root.Button = (() => {

    /**
     * Properties of a Button.
     * @exports IButton
     * @interface IButton
     * @property {string|null} [id] Button id
     * @property {string|null} [label] Button label
     * @property {boolean|null} ["default"] Button default
     * @property {string|null} [help] Button help
     * @property {string|null} [formId] Button formId
     * @property {boolean|null} [isFormSubmitter] Button isFormSubmitter
     * @property {string|null} [type] Button type
     * @property {boolean|null} [disabled] Button disabled
     * @property {boolean|null} [useContainerWidth] Button useContainerWidth
     * @property {string|null} [icon] Button icon
     */

    /**
     * Constructs a new Button.
     * @exports Button
     * @classdesc Represents a Button.
     * @implements IButton
     * @constructor
     * @param {IButton=} [properties] Properties to set
     */
    function Button(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Button id.
     * @member {string} id
     * @memberof Button
     * @instance
     */
    Button.prototype.id = "";

    /**
     * Button label.
     * @member {string} label
     * @memberof Button
     * @instance
     */
    Button.prototype.label = "";

    /**
     * Button default.
     * @member {boolean} default
     * @memberof Button
     * @instance
     */
    Button.prototype["default"] = false;

    /**
     * Button help.
     * @member {string} help
     * @memberof Button
     * @instance
     */
    Button.prototype.help = "";

    /**
     * Button formId.
     * @member {string} formId
     * @memberof Button
     * @instance
     */
    Button.prototype.formId = "";

    /**
     * Button isFormSubmitter.
     * @member {boolean} isFormSubmitter
     * @memberof Button
     * @instance
     */
    Button.prototype.isFormSubmitter = false;

    /**
     * Button type.
     * @member {string} type
     * @memberof Button
     * @instance
     */
    Button.prototype.type = "";

    /**
     * Button disabled.
     * @member {boolean} disabled
     * @memberof Button
     * @instance
     */
    Button.prototype.disabled = false;

    /**
     * Button useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof Button
     * @instance
     */
    Button.prototype.useContainerWidth = false;

    /**
     * Button icon.
     * @member {string} icon
     * @memberof Button
     * @instance
     */
    Button.prototype.icon = "";

    /**
     * Creates a new Button instance using the specified properties.
     * @function create
     * @memberof Button
     * @static
     * @param {IButton=} [properties] Properties to set
     * @returns {Button} Button instance
     */
    Button.create = function create(properties) {
        return new Button(properties);
    };

    /**
     * Encodes the specified Button message. Does not implicitly {@link Button.verify|verify} messages.
     * @function encode
     * @memberof Button
     * @static
     * @param {IButton} message Button message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Button.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message["default"]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.formId);
        if (message.isFormSubmitter != null && Object.hasOwnProperty.call(message, "isFormSubmitter"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isFormSubmitter);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.type);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disabled);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useContainerWidth);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.icon);
        return writer;
    };

    /**
     * Encodes the specified Button message, length delimited. Does not implicitly {@link Button.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Button
     * @static
     * @param {IButton} message Button message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Button.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Button message from the specified reader or buffer.
     * @function decode
     * @memberof Button
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Button} Button
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Button.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Button();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.bool();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.formId = reader.string();
                    break;
                }
            case 6: {
                    message.isFormSubmitter = reader.bool();
                    break;
                }
            case 7: {
                    message.type = reader.string();
                    break;
                }
            case 8: {
                    message.disabled = reader.bool();
                    break;
                }
            case 9: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 10: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Button message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Button
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Button} Button
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Button.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Button message.
     * @function verify
     * @memberof Button
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Button.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default"))
            if (typeof message["default"] !== "boolean")
                return "default: boolean expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.isFormSubmitter != null && message.hasOwnProperty("isFormSubmitter"))
            if (typeof message.isFormSubmitter !== "boolean")
                return "isFormSubmitter: boolean expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        return null;
    };

    /**
     * Creates a Button message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Button
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Button} Button
     */
    Button.fromObject = function fromObject(object) {
        if (object instanceof $root.Button)
            return object;
        let message = new $root.Button();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = Boolean(object["default"]);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.isFormSubmitter != null)
            message.isFormSubmitter = Boolean(object.isFormSubmitter);
        if (object.type != null)
            message.type = String(object.type);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    /**
     * Creates a plain object from a Button message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Button
     * @static
     * @param {Button} message Button
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Button.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object["default"] = false;
            object.help = "";
            object.formId = "";
            object.isFormSubmitter = false;
            object.type = "";
            object.disabled = false;
            object.useContainerWidth = false;
            object.icon = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default"))
            object["default"] = message["default"];
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.isFormSubmitter != null && message.hasOwnProperty("isFormSubmitter"))
            object.isFormSubmitter = message.isFormSubmitter;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    /**
     * Converts this Button to JSON.
     * @function toJSON
     * @memberof Button
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Button.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Button
     * @function getTypeUrl
     * @memberof Button
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Button.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Button";
    };

    return Button;
})();

export const ButtonGroup = $root.ButtonGroup = (() => {

    /**
     * Properties of a ButtonGroup.
     * @exports IButtonGroup
     * @interface IButtonGroup
     * @property {string|null} [id] ButtonGroup id
     * @property {Array.<ButtonGroup.IOption>|null} [options] ButtonGroup options
     * @property {Array.<number>|null} ["default"] ButtonGroup default
     * @property {boolean|null} [disabled] ButtonGroup disabled
     * @property {ButtonGroup.ClickMode|null} [clickMode] ButtonGroup clickMode
     * @property {string|null} [formId] ButtonGroup formId
     * @property {Array.<number>|null} [value] ButtonGroup value
     * @property {boolean|null} [setValue] ButtonGroup setValue
     * @property {ButtonGroup.SelectionVisualization|null} [selectionVisualization] ButtonGroup selectionVisualization
     * @property {ButtonGroup.Style|null} [style] ButtonGroup style
     * @property {string|null} [label] ButtonGroup label
     * @property {ILabelVisibilityMessage|null} [labelVisibility] ButtonGroup labelVisibility
     * @property {string|null} [help] ButtonGroup help
     */

    /**
     * Constructs a new ButtonGroup.
     * @exports ButtonGroup
     * @classdesc Represents a ButtonGroup.
     * @implements IButtonGroup
     * @constructor
     * @param {IButtonGroup=} [properties] Properties to set
     */
    function ButtonGroup(properties) {
        this.options = [];
        this["default"] = [];
        this.value = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ButtonGroup id.
     * @member {string} id
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.id = "";

    /**
     * ButtonGroup options.
     * @member {Array.<ButtonGroup.IOption>} options
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.options = $util.emptyArray;

    /**
     * ButtonGroup default.
     * @member {Array.<number>} default
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype["default"] = $util.emptyArray;

    /**
     * ButtonGroup disabled.
     * @member {boolean} disabled
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.disabled = false;

    /**
     * ButtonGroup clickMode.
     * @member {ButtonGroup.ClickMode} clickMode
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.clickMode = 0;

    /**
     * ButtonGroup formId.
     * @member {string} formId
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.formId = "";

    /**
     * ButtonGroup value.
     * @member {Array.<number>} value
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.value = $util.emptyArray;

    /**
     * ButtonGroup setValue.
     * @member {boolean} setValue
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.setValue = false;

    /**
     * ButtonGroup selectionVisualization.
     * @member {ButtonGroup.SelectionVisualization} selectionVisualization
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.selectionVisualization = 0;

    /**
     * ButtonGroup style.
     * @member {ButtonGroup.Style} style
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.style = 0;

    /**
     * ButtonGroup label.
     * @member {string} label
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.label = "";

    /**
     * ButtonGroup labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.labelVisibility = null;

    /**
     * ButtonGroup help.
     * @member {string|null|undefined} help
     * @memberof ButtonGroup
     * @instance
     */
    ButtonGroup.prototype.help = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(ButtonGroup.prototype, "_help", {
        get: $util.oneOfGetter($oneOfFields = ["help"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new ButtonGroup instance using the specified properties.
     * @function create
     * @memberof ButtonGroup
     * @static
     * @param {IButtonGroup=} [properties] Properties to set
     * @returns {ButtonGroup} ButtonGroup instance
     */
    ButtonGroup.create = function create(properties) {
        return new ButtonGroup(properties);
    };

    /**
     * Encodes the specified ButtonGroup message. Does not implicitly {@link ButtonGroup.verify|verify} messages.
     * @function encode
     * @memberof ButtonGroup
     * @static
     * @param {IButtonGroup} message ButtonGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ButtonGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                $root.ButtonGroup.Option.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message["default"] != null && message["default"].length) {
            writer.uint32(/* id 3, wireType 2 =*/26).fork();
            for (let i = 0; i < message["default"].length; ++i)
                writer.uint32(message["default"][i]);
            writer.ldelim();
        }
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.disabled);
        if (message.clickMode != null && Object.hasOwnProperty.call(message, "clickMode"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.clickMode);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.formId);
        if (message.value != null && message.value.length) {
            writer.uint32(/* id 7, wireType 2 =*/58).fork();
            for (let i = 0; i < message.value.length; ++i)
                writer.uint32(message.value[i]);
            writer.ldelim();
        }
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.setValue);
        if (message.selectionVisualization != null && Object.hasOwnProperty.call(message, "selectionVisualization"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.selectionVisualization);
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.style);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.label);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.help);
        return writer;
    };

    /**
     * Encodes the specified ButtonGroup message, length delimited. Does not implicitly {@link ButtonGroup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ButtonGroup
     * @static
     * @param {IButtonGroup} message ButtonGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ButtonGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ButtonGroup message from the specified reader or buffer.
     * @function decode
     * @memberof ButtonGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ButtonGroup} ButtonGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ButtonGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ButtonGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.ButtonGroup.Option.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    if (!(message["default"] && message["default"].length))
                        message["default"] = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["default"].push(reader.uint32());
                    } else
                        message["default"].push(reader.uint32());
                    break;
                }
            case 4: {
                    message.disabled = reader.bool();
                    break;
                }
            case 5: {
                    message.clickMode = reader.int32();
                    break;
                }
            case 6: {
                    message.formId = reader.string();
                    break;
                }
            case 7: {
                    if (!(message.value && message.value.length))
                        message.value = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.value.push(reader.uint32());
                    } else
                        message.value.push(reader.uint32());
                    break;
                }
            case 8: {
                    message.setValue = reader.bool();
                    break;
                }
            case 9: {
                    message.selectionVisualization = reader.int32();
                    break;
                }
            case 10: {
                    message.style = reader.int32();
                    break;
                }
            case 11: {
                    message.label = reader.string();
                    break;
                }
            case 12: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.help = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ButtonGroup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ButtonGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ButtonGroup} ButtonGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ButtonGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ButtonGroup message.
     * @function verify
     * @memberof ButtonGroup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ButtonGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i) {
                let error = $root.ButtonGroup.Option.verify(message.options[i]);
                if (error)
                    return "options." + error;
            }
        }
        if (message["default"] != null && message.hasOwnProperty("default")) {
            if (!Array.isArray(message["default"]))
                return "default: array expected";
            for (let i = 0; i < message["default"].length; ++i)
                if (!$util.isInteger(message["default"][i]))
                    return "default: integer[] expected";
        }
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.clickMode != null && message.hasOwnProperty("clickMode"))
            switch (message.clickMode) {
            default:
                return "clickMode: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            if (!Array.isArray(message.value))
                return "value: array expected";
            for (let i = 0; i < message.value.length; ++i)
                if (!$util.isInteger(message.value[i]))
                    return "value: integer[] expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.selectionVisualization != null && message.hasOwnProperty("selectionVisualization"))
            switch (message.selectionVisualization) {
            default:
                return "selectionVisualization: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.style != null && message.hasOwnProperty("style"))
            switch (message.style) {
            default:
                return "style: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.help != null && message.hasOwnProperty("help")) {
            properties._help = 1;
            if (!$util.isString(message.help))
                return "help: string expected";
        }
        return null;
    };

    /**
     * Creates a ButtonGroup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ButtonGroup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ButtonGroup} ButtonGroup
     */
    ButtonGroup.fromObject = function fromObject(object) {
        if (object instanceof $root.ButtonGroup)
            return object;
        let message = new $root.ButtonGroup();
        if (object.id != null)
            message.id = String(object.id);
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".ButtonGroup.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i) {
                if (typeof object.options[i] !== "object")
                    throw TypeError(".ButtonGroup.options: object expected");
                message.options[i] = $root.ButtonGroup.Option.fromObject(object.options[i]);
            }
        }
        if (object["default"]) {
            if (!Array.isArray(object["default"]))
                throw TypeError(".ButtonGroup.default: array expected");
            message["default"] = [];
            for (let i = 0; i < object["default"].length; ++i)
                message["default"][i] = object["default"][i] >>> 0;
        }
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        switch (object.clickMode) {
        default:
            if (typeof object.clickMode === "number") {
                message.clickMode = object.clickMode;
                break;
            }
            break;
        case "SINGLE_SELECT":
        case 0:
            message.clickMode = 0;
            break;
        case "MULTI_SELECT":
        case 1:
            message.clickMode = 1;
            break;
        }
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value) {
            if (!Array.isArray(object.value))
                throw TypeError(".ButtonGroup.value: array expected");
            message.value = [];
            for (let i = 0; i < object.value.length; ++i)
                message.value[i] = object.value[i] >>> 0;
        }
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        switch (object.selectionVisualization) {
        default:
            if (typeof object.selectionVisualization === "number") {
                message.selectionVisualization = object.selectionVisualization;
                break;
            }
            break;
        case "ONLY_SELECTED":
        case 0:
            message.selectionVisualization = 0;
            break;
        case "ALL_UP_TO_SELECTED":
        case 1:
            message.selectionVisualization = 1;
            break;
        }
        switch (object.style) {
        default:
            if (typeof object.style === "number") {
                message.style = object.style;
                break;
            }
            break;
        case "SEGMENTED_CONTROL":
        case 0:
            message.style = 0;
            break;
        case "PILLS":
        case 1:
            message.style = 1;
            break;
        case "BORDERLESS":
        case 2:
            message.style = 2;
            break;
        }
        if (object.label != null)
            message.label = String(object.label);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".ButtonGroup.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.help != null)
            message.help = String(object.help);
        return message;
    };

    /**
     * Creates a plain object from a ButtonGroup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ButtonGroup
     * @static
     * @param {ButtonGroup} message ButtonGroup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ButtonGroup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.options = [];
            object["default"] = [];
            object.value = [];
        }
        if (options.defaults) {
            object.id = "";
            object.disabled = false;
            object.clickMode = options.enums === String ? "SINGLE_SELECT" : 0;
            object.formId = "";
            object.setValue = false;
            object.selectionVisualization = options.enums === String ? "ONLY_SELECTED" : 0;
            object.style = options.enums === String ? "SEGMENTED_CONTROL" : 0;
            object.label = "";
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = $root.ButtonGroup.Option.toObject(message.options[j], options);
        }
        if (message["default"] && message["default"].length) {
            object["default"] = [];
            for (let j = 0; j < message["default"].length; ++j)
                object["default"][j] = message["default"][j];
        }
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.clickMode != null && message.hasOwnProperty("clickMode"))
            object.clickMode = options.enums === String ? $root.ButtonGroup.ClickMode[message.clickMode] === undefined ? message.clickMode : $root.ButtonGroup.ClickMode[message.clickMode] : message.clickMode;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value && message.value.length) {
            object.value = [];
            for (let j = 0; j < message.value.length; ++j)
                object.value[j] = message.value[j];
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.selectionVisualization != null && message.hasOwnProperty("selectionVisualization"))
            object.selectionVisualization = options.enums === String ? $root.ButtonGroup.SelectionVisualization[message.selectionVisualization] === undefined ? message.selectionVisualization : $root.ButtonGroup.SelectionVisualization[message.selectionVisualization] : message.selectionVisualization;
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = options.enums === String ? $root.ButtonGroup.Style[message.style] === undefined ? message.style : $root.ButtonGroup.Style[message.style] : message.style;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.help != null && message.hasOwnProperty("help")) {
            object.help = message.help;
            if (options.oneofs)
                object._help = "help";
        }
        return object;
    };

    /**
     * Converts this ButtonGroup to JSON.
     * @function toJSON
     * @memberof ButtonGroup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ButtonGroup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ButtonGroup
     * @function getTypeUrl
     * @memberof ButtonGroup
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ButtonGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ButtonGroup";
    };

    /**
     * ClickMode enum.
     * @name ButtonGroup.ClickMode
     * @enum {number}
     * @property {number} SINGLE_SELECT=0 SINGLE_SELECT value
     * @property {number} MULTI_SELECT=1 MULTI_SELECT value
     */
    ButtonGroup.ClickMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SINGLE_SELECT"] = 0;
        values[valuesById[1] = "MULTI_SELECT"] = 1;
        return values;
    })();

    ButtonGroup.Option = (function() {

        /**
         * Properties of an Option.
         * @memberof ButtonGroup
         * @interface IOption
         * @property {string|null} [content] Option content
         * @property {string|null} [selectedContent] Option selectedContent
         * @property {string|null} [contentIcon] Option contentIcon
         * @property {string|null} [selectedContentIcon] Option selectedContentIcon
         */

        /**
         * Constructs a new Option.
         * @memberof ButtonGroup
         * @classdesc Represents an Option.
         * @implements IOption
         * @constructor
         * @param {ButtonGroup.IOption=} [properties] Properties to set
         */
        function Option(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Option content.
         * @member {string} content
         * @memberof ButtonGroup.Option
         * @instance
         */
        Option.prototype.content = "";

        /**
         * Option selectedContent.
         * @member {string|null|undefined} selectedContent
         * @memberof ButtonGroup.Option
         * @instance
         */
        Option.prototype.selectedContent = null;

        /**
         * Option contentIcon.
         * @member {string|null|undefined} contentIcon
         * @memberof ButtonGroup.Option
         * @instance
         */
        Option.prototype.contentIcon = null;

        /**
         * Option selectedContentIcon.
         * @member {string|null|undefined} selectedContentIcon
         * @memberof ButtonGroup.Option
         * @instance
         */
        Option.prototype.selectedContentIcon = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(Option.prototype, "_selectedContent", {
            get: $util.oneOfGetter($oneOfFields = ["selectedContent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(Option.prototype, "_contentIcon", {
            get: $util.oneOfGetter($oneOfFields = ["contentIcon"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(Option.prototype, "_selectedContentIcon", {
            get: $util.oneOfGetter($oneOfFields = ["selectedContentIcon"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Option instance using the specified properties.
         * @function create
         * @memberof ButtonGroup.Option
         * @static
         * @param {ButtonGroup.IOption=} [properties] Properties to set
         * @returns {ButtonGroup.Option} Option instance
         */
        Option.create = function create(properties) {
            return new Option(properties);
        };

        /**
         * Encodes the specified Option message. Does not implicitly {@link ButtonGroup.Option.verify|verify} messages.
         * @function encode
         * @memberof ButtonGroup.Option
         * @static
         * @param {ButtonGroup.IOption} message Option message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.content);
            if (message.selectedContent != null && Object.hasOwnProperty.call(message, "selectedContent"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.selectedContent);
            if (message.contentIcon != null && Object.hasOwnProperty.call(message, "contentIcon"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.contentIcon);
            if (message.selectedContentIcon != null && Object.hasOwnProperty.call(message, "selectedContentIcon"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.selectedContentIcon);
            return writer;
        };

        /**
         * Encodes the specified Option message, length delimited. Does not implicitly {@link ButtonGroup.Option.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ButtonGroup.Option
         * @static
         * @param {ButtonGroup.IOption} message Option message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Option message from the specified reader or buffer.
         * @function decode
         * @memberof ButtonGroup.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ButtonGroup.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ButtonGroup.Option();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.content = reader.string();
                        break;
                    }
                case 2: {
                        message.selectedContent = reader.string();
                        break;
                    }
                case 3: {
                        message.contentIcon = reader.string();
                        break;
                    }
                case 4: {
                        message.selectedContentIcon = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Option message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ButtonGroup.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ButtonGroup.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Option message.
         * @function verify
         * @memberof ButtonGroup.Option
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Option.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.selectedContent != null && message.hasOwnProperty("selectedContent")) {
                properties._selectedContent = 1;
                if (!$util.isString(message.selectedContent))
                    return "selectedContent: string expected";
            }
            if (message.contentIcon != null && message.hasOwnProperty("contentIcon")) {
                properties._contentIcon = 1;
                if (!$util.isString(message.contentIcon))
                    return "contentIcon: string expected";
            }
            if (message.selectedContentIcon != null && message.hasOwnProperty("selectedContentIcon")) {
                properties._selectedContentIcon = 1;
                if (!$util.isString(message.selectedContentIcon))
                    return "selectedContentIcon: string expected";
            }
            return null;
        };

        /**
         * Creates an Option message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ButtonGroup.Option
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ButtonGroup.Option} Option
         */
        Option.fromObject = function fromObject(object) {
            if (object instanceof $root.ButtonGroup.Option)
                return object;
            let message = new $root.ButtonGroup.Option();
            if (object.content != null)
                message.content = String(object.content);
            if (object.selectedContent != null)
                message.selectedContent = String(object.selectedContent);
            if (object.contentIcon != null)
                message.contentIcon = String(object.contentIcon);
            if (object.selectedContentIcon != null)
                message.selectedContentIcon = String(object.selectedContentIcon);
            return message;
        };

        /**
         * Creates a plain object from an Option message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ButtonGroup.Option
         * @static
         * @param {ButtonGroup.Option} message Option
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Option.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.content = "";
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.selectedContent != null && message.hasOwnProperty("selectedContent")) {
                object.selectedContent = message.selectedContent;
                if (options.oneofs)
                    object._selectedContent = "selectedContent";
            }
            if (message.contentIcon != null && message.hasOwnProperty("contentIcon")) {
                object.contentIcon = message.contentIcon;
                if (options.oneofs)
                    object._contentIcon = "contentIcon";
            }
            if (message.selectedContentIcon != null && message.hasOwnProperty("selectedContentIcon")) {
                object.selectedContentIcon = message.selectedContentIcon;
                if (options.oneofs)
                    object._selectedContentIcon = "selectedContentIcon";
            }
            return object;
        };

        /**
         * Converts this Option to JSON.
         * @function toJSON
         * @memberof ButtonGroup.Option
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Option.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Option
         * @function getTypeUrl
         * @memberof ButtonGroup.Option
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ButtonGroup.Option";
        };

        return Option;
    })();

    /**
     * SelectionVisualization enum.
     * @name ButtonGroup.SelectionVisualization
     * @enum {number}
     * @property {number} ONLY_SELECTED=0 ONLY_SELECTED value
     * @property {number} ALL_UP_TO_SELECTED=1 ALL_UP_TO_SELECTED value
     */
    ButtonGroup.SelectionVisualization = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ONLY_SELECTED"] = 0;
        values[valuesById[1] = "ALL_UP_TO_SELECTED"] = 1;
        return values;
    })();

    /**
     * Style enum.
     * @name ButtonGroup.Style
     * @enum {number}
     * @property {number} SEGMENTED_CONTROL=0 SEGMENTED_CONTROL value
     * @property {number} PILLS=1 PILLS value
     * @property {number} BORDERLESS=2 BORDERLESS value
     */
    ButtonGroup.Style = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEGMENTED_CONTROL"] = 0;
        values[valuesById[1] = "PILLS"] = 1;
        values[valuesById[2] = "BORDERLESS"] = 2;
        return values;
    })();

    return ButtonGroup;
})();

export const CameraInput = $root.CameraInput = (() => {

    /**
     * Properties of a CameraInput.
     * @exports ICameraInput
     * @interface ICameraInput
     * @property {string|null} [id] CameraInput id
     * @property {string|null} [label] CameraInput label
     * @property {string|null} [help] CameraInput help
     * @property {string|null} [formId] CameraInput formId
     * @property {boolean|null} [disabled] CameraInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] CameraInput labelVisibility
     */

    /**
     * Constructs a new CameraInput.
     * @exports CameraInput
     * @classdesc Represents a CameraInput.
     * @implements ICameraInput
     * @constructor
     * @param {ICameraInput=} [properties] Properties to set
     */
    function CameraInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CameraInput id.
     * @member {string} id
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.id = "";

    /**
     * CameraInput label.
     * @member {string} label
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.label = "";

    /**
     * CameraInput help.
     * @member {string} help
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.help = "";

    /**
     * CameraInput formId.
     * @member {string} formId
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.formId = "";

    /**
     * CameraInput disabled.
     * @member {boolean} disabled
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.disabled = false;

    /**
     * CameraInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof CameraInput
     * @instance
     */
    CameraInput.prototype.labelVisibility = null;

    /**
     * Creates a new CameraInput instance using the specified properties.
     * @function create
     * @memberof CameraInput
     * @static
     * @param {ICameraInput=} [properties] Properties to set
     * @returns {CameraInput} CameraInput instance
     */
    CameraInput.create = function create(properties) {
        return new CameraInput(properties);
    };

    /**
     * Encodes the specified CameraInput message. Does not implicitly {@link CameraInput.verify|verify} messages.
     * @function encode
     * @memberof CameraInput
     * @static
     * @param {ICameraInput} message CameraInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CameraInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.formId);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CameraInput message, length delimited. Does not implicitly {@link CameraInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CameraInput
     * @static
     * @param {ICameraInput} message CameraInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CameraInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CameraInput message from the specified reader or buffer.
     * @function decode
     * @memberof CameraInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CameraInput} CameraInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CameraInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CameraInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message.help = reader.string();
                    break;
                }
            case 4: {
                    message.formId = reader.string();
                    break;
                }
            case 5: {
                    message.disabled = reader.bool();
                    break;
                }
            case 6: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CameraInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CameraInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CameraInput} CameraInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CameraInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CameraInput message.
     * @function verify
     * @memberof CameraInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CameraInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates a CameraInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CameraInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CameraInput} CameraInput
     */
    CameraInput.fromObject = function fromObject(object) {
        if (object instanceof $root.CameraInput)
            return object;
        let message = new $root.CameraInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".CameraInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from a CameraInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CameraInput
     * @static
     * @param {CameraInput} message CameraInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CameraInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this CameraInput to JSON.
     * @function toJSON
     * @memberof CameraInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CameraInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CameraInput
     * @function getTypeUrl
     * @memberof CameraInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CameraInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CameraInput";
    };

    return CameraInput;
})();

export const ChatInput = $root.ChatInput = (() => {

    /**
     * Properties of a ChatInput.
     * @exports IChatInput
     * @interface IChatInput
     * @property {string|null} [id] ChatInput id
     * @property {string|null} [placeholder] ChatInput placeholder
     * @property {number|null} [maxChars] ChatInput maxChars
     * @property {boolean|null} [disabled] ChatInput disabled
     * @property {string|null} [value] ChatInput value
     * @property {boolean|null} [setValue] ChatInput setValue
     * @property {string|null} ["default"] ChatInput default
     * @property {ChatInput.Position|null} [position] ChatInput position
     */

    /**
     * Constructs a new ChatInput.
     * @exports ChatInput
     * @classdesc Represents a ChatInput.
     * @implements IChatInput
     * @constructor
     * @param {IChatInput=} [properties] Properties to set
     */
    function ChatInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatInput id.
     * @member {string} id
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.id = "";

    /**
     * ChatInput placeholder.
     * @member {string} placeholder
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.placeholder = "";

    /**
     * ChatInput maxChars.
     * @member {number} maxChars
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.maxChars = 0;

    /**
     * ChatInput disabled.
     * @member {boolean} disabled
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.disabled = false;

    /**
     * ChatInput value.
     * @member {string} value
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.value = "";

    /**
     * ChatInput setValue.
     * @member {boolean} setValue
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.setValue = false;

    /**
     * ChatInput default.
     * @member {string} default
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype["default"] = "";

    /**
     * ChatInput position.
     * @member {ChatInput.Position} position
     * @memberof ChatInput
     * @instance
     */
    ChatInput.prototype.position = 0;

    /**
     * Creates a new ChatInput instance using the specified properties.
     * @function create
     * @memberof ChatInput
     * @static
     * @param {IChatInput=} [properties] Properties to set
     * @returns {ChatInput} ChatInput instance
     */
    ChatInput.create = function create(properties) {
        return new ChatInput(properties);
    };

    /**
     * Encodes the specified ChatInput message. Does not implicitly {@link ChatInput.verify|verify} messages.
     * @function encode
     * @memberof ChatInput
     * @static
     * @param {IChatInput} message ChatInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.placeholder);
        if (message.maxChars != null && Object.hasOwnProperty.call(message, "maxChars"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxChars);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.disabled);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.setValue);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message["default"]);
        if (message.position != null && Object.hasOwnProperty.call(message, "position"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.position);
        return writer;
    };

    /**
     * Encodes the specified ChatInput message, length delimited. Does not implicitly {@link ChatInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ChatInput
     * @static
     * @param {IChatInput} message ChatInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ChatInput message from the specified reader or buffer.
     * @function decode
     * @memberof ChatInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatInput} ChatInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.placeholder = reader.string();
                    break;
                }
            case 3: {
                    message.maxChars = reader.uint32();
                    break;
                }
            case 4: {
                    message.disabled = reader.bool();
                    break;
                }
            case 5: {
                    message.value = reader.string();
                    break;
                }
            case 6: {
                    message.setValue = reader.bool();
                    break;
                }
            case 7: {
                    message["default"] = reader.string();
                    break;
                }
            case 8: {
                    message.position = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ChatInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ChatInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ChatInput} ChatInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ChatInput message.
     * @function verify
     * @memberof ChatInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ChatInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            if (!$util.isInteger(message.maxChars))
                return "maxChars: integer expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message["default"] != null && message.hasOwnProperty("default"))
            if (!$util.isString(message["default"]))
                return "default: string expected";
        if (message.position != null && message.hasOwnProperty("position"))
            switch (message.position) {
            default:
                return "position: enum value expected";
            case 0:
                break;
            }
        return null;
    };

    /**
     * Creates a ChatInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ChatInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ChatInput} ChatInput
     */
    ChatInput.fromObject = function fromObject(object) {
        if (object instanceof $root.ChatInput)
            return object;
        let message = new $root.ChatInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        if (object.maxChars != null)
            message.maxChars = object.maxChars >>> 0;
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.value != null)
            message.value = String(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object["default"] != null)
            message["default"] = String(object["default"]);
        switch (object.position) {
        default:
            if (typeof object.position === "number") {
                message.position = object.position;
                break;
            }
            break;
        case "BOTTOM":
        case 0:
            message.position = 0;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ChatInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ChatInput
     * @static
     * @param {ChatInput} message ChatInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ChatInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.placeholder = "";
            object.maxChars = 0;
            object.disabled = false;
            object.value = "";
            object.setValue = false;
            object["default"] = "";
            object.position = options.enums === String ? "BOTTOM" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            object.maxChars = message.maxChars;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message["default"] != null && message.hasOwnProperty("default"))
            object["default"] = message["default"];
        if (message.position != null && message.hasOwnProperty("position"))
            object.position = options.enums === String ? $root.ChatInput.Position[message.position] === undefined ? message.position : $root.ChatInput.Position[message.position] : message.position;
        return object;
    };

    /**
     * Converts this ChatInput to JSON.
     * @function toJSON
     * @memberof ChatInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ChatInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ChatInput
     * @function getTypeUrl
     * @memberof ChatInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ChatInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ChatInput";
    };

    /**
     * Position enum.
     * @name ChatInput.Position
     * @enum {number}
     * @property {number} BOTTOM=0 BOTTOM value
     */
    ChatInput.Position = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BOTTOM"] = 0;
        return values;
    })();

    return ChatInput;
})();

export const Checkbox = $root.Checkbox = (() => {

    /**
     * Properties of a Checkbox.
     * @exports ICheckbox
     * @interface ICheckbox
     * @property {string|null} [id] Checkbox id
     * @property {string|null} [label] Checkbox label
     * @property {boolean|null} ["default"] Checkbox default
     * @property {string|null} [help] Checkbox help
     * @property {string|null} [formId] Checkbox formId
     * @property {boolean|null} [value] Checkbox value
     * @property {boolean|null} [setValue] Checkbox setValue
     * @property {boolean|null} [disabled] Checkbox disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] Checkbox labelVisibility
     * @property {Checkbox.StyleType|null} [type] Checkbox type
     */

    /**
     * Constructs a new Checkbox.
     * @exports Checkbox
     * @classdesc Represents a Checkbox.
     * @implements ICheckbox
     * @constructor
     * @param {ICheckbox=} [properties] Properties to set
     */
    function Checkbox(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Checkbox id.
     * @member {string} id
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.id = "";

    /**
     * Checkbox label.
     * @member {string} label
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.label = "";

    /**
     * Checkbox default.
     * @member {boolean} default
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype["default"] = false;

    /**
     * Checkbox help.
     * @member {string} help
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.help = "";

    /**
     * Checkbox formId.
     * @member {string} formId
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.formId = "";

    /**
     * Checkbox value.
     * @member {boolean} value
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.value = false;

    /**
     * Checkbox setValue.
     * @member {boolean} setValue
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.setValue = false;

    /**
     * Checkbox disabled.
     * @member {boolean} disabled
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.disabled = false;

    /**
     * Checkbox labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.labelVisibility = null;

    /**
     * Checkbox type.
     * @member {Checkbox.StyleType} type
     * @memberof Checkbox
     * @instance
     */
    Checkbox.prototype.type = 0;

    /**
     * Creates a new Checkbox instance using the specified properties.
     * @function create
     * @memberof Checkbox
     * @static
     * @param {ICheckbox=} [properties] Properties to set
     * @returns {Checkbox} Checkbox instance
     */
    Checkbox.create = function create(properties) {
        return new Checkbox(properties);
    };

    /**
     * Encodes the specified Checkbox message. Does not implicitly {@link Checkbox.verify|verify} messages.
     * @function encode
     * @memberof Checkbox
     * @static
     * @param {ICheckbox} message Checkbox message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Checkbox.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message["default"]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.type);
        return writer;
    };

    /**
     * Encodes the specified Checkbox message, length delimited. Does not implicitly {@link Checkbox.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Checkbox
     * @static
     * @param {ICheckbox} message Checkbox message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Checkbox.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Checkbox message from the specified reader or buffer.
     * @function decode
     * @memberof Checkbox
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Checkbox} Checkbox
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Checkbox.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Checkbox();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.bool();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.formId = reader.string();
                    break;
                }
            case 6: {
                    message.value = reader.bool();
                    break;
                }
            case 7: {
                    message.setValue = reader.bool();
                    break;
                }
            case 8: {
                    message.disabled = reader.bool();
                    break;
                }
            case 9: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Checkbox message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Checkbox
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Checkbox} Checkbox
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Checkbox.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Checkbox message.
     * @function verify
     * @memberof Checkbox
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Checkbox.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default"))
            if (typeof message["default"] !== "boolean")
                return "default: boolean expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (typeof message.value !== "boolean")
                return "value: boolean expected";
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
                break;
            }
        return null;
    };

    /**
     * Creates a Checkbox message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Checkbox
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Checkbox} Checkbox
     */
    Checkbox.fromObject = function fromObject(object) {
        if (object instanceof $root.Checkbox)
            return object;
        let message = new $root.Checkbox();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = Boolean(object["default"]);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = Boolean(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".Checkbox.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "DEFAULT":
        case 0:
            message.type = 0;
            break;
        case "TOGGLE":
        case 1:
            message.type = 1;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a Checkbox message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Checkbox
     * @static
     * @param {Checkbox} message Checkbox
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Checkbox.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object["default"] = false;
            object.help = "";
            object.formId = "";
            object.value = false;
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            object.type = options.enums === String ? "DEFAULT" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default"))
            object["default"] = message["default"];
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.Checkbox.StyleType[message.type] === undefined ? message.type : $root.Checkbox.StyleType[message.type] : message.type;
        return object;
    };

    /**
     * Converts this Checkbox to JSON.
     * @function toJSON
     * @memberof Checkbox
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Checkbox.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Checkbox
     * @function getTypeUrl
     * @memberof Checkbox
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Checkbox.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Checkbox";
    };

    /**
     * StyleType enum.
     * @name Checkbox.StyleType
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} TOGGLE=1 TOGGLE value
     */
    Checkbox.StyleType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "TOGGLE"] = 1;
        return values;
    })();

    return Checkbox;
})();

export const Code = $root.Code = (() => {

    /**
     * Properties of a Code.
     * @exports ICode
     * @interface ICode
     * @property {string|null} [codeText] Code codeText
     * @property {string|null} [language] Code language
     * @property {boolean|null} [showLineNumbers] Code showLineNumbers
     * @property {boolean|null} [wrapLines] Code wrapLines
     */

    /**
     * Constructs a new Code.
     * @exports Code
     * @classdesc Represents a Code.
     * @implements ICode
     * @constructor
     * @param {ICode=} [properties] Properties to set
     */
    function Code(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Code codeText.
     * @member {string} codeText
     * @memberof Code
     * @instance
     */
    Code.prototype.codeText = "";

    /**
     * Code language.
     * @member {string} language
     * @memberof Code
     * @instance
     */
    Code.prototype.language = "";

    /**
     * Code showLineNumbers.
     * @member {boolean} showLineNumbers
     * @memberof Code
     * @instance
     */
    Code.prototype.showLineNumbers = false;

    /**
     * Code wrapLines.
     * @member {boolean} wrapLines
     * @memberof Code
     * @instance
     */
    Code.prototype.wrapLines = false;

    /**
     * Creates a new Code instance using the specified properties.
     * @function create
     * @memberof Code
     * @static
     * @param {ICode=} [properties] Properties to set
     * @returns {Code} Code instance
     */
    Code.create = function create(properties) {
        return new Code(properties);
    };

    /**
     * Encodes the specified Code message. Does not implicitly {@link Code.verify|verify} messages.
     * @function encode
     * @memberof Code
     * @static
     * @param {ICode} message Code message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Code.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.codeText != null && Object.hasOwnProperty.call(message, "codeText"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.codeText);
        if (message.language != null && Object.hasOwnProperty.call(message, "language"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.language);
        if (message.showLineNumbers != null && Object.hasOwnProperty.call(message, "showLineNumbers"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.showLineNumbers);
        if (message.wrapLines != null && Object.hasOwnProperty.call(message, "wrapLines"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.wrapLines);
        return writer;
    };

    /**
     * Encodes the specified Code message, length delimited. Does not implicitly {@link Code.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Code
     * @static
     * @param {ICode} message Code message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Code.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Code message from the specified reader or buffer.
     * @function decode
     * @memberof Code
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Code} Code
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Code.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Code();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.codeText = reader.string();
                    break;
                }
            case 2: {
                    message.language = reader.string();
                    break;
                }
            case 3: {
                    message.showLineNumbers = reader.bool();
                    break;
                }
            case 4: {
                    message.wrapLines = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Code message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Code
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Code} Code
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Code.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Code message.
     * @function verify
     * @memberof Code
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Code.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.codeText != null && message.hasOwnProperty("codeText"))
            if (!$util.isString(message.codeText))
                return "codeText: string expected";
        if (message.language != null && message.hasOwnProperty("language"))
            if (!$util.isString(message.language))
                return "language: string expected";
        if (message.showLineNumbers != null && message.hasOwnProperty("showLineNumbers"))
            if (typeof message.showLineNumbers !== "boolean")
                return "showLineNumbers: boolean expected";
        if (message.wrapLines != null && message.hasOwnProperty("wrapLines"))
            if (typeof message.wrapLines !== "boolean")
                return "wrapLines: boolean expected";
        return null;
    };

    /**
     * Creates a Code message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Code
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Code} Code
     */
    Code.fromObject = function fromObject(object) {
        if (object instanceof $root.Code)
            return object;
        let message = new $root.Code();
        if (object.codeText != null)
            message.codeText = String(object.codeText);
        if (object.language != null)
            message.language = String(object.language);
        if (object.showLineNumbers != null)
            message.showLineNumbers = Boolean(object.showLineNumbers);
        if (object.wrapLines != null)
            message.wrapLines = Boolean(object.wrapLines);
        return message;
    };

    /**
     * Creates a plain object from a Code message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Code
     * @static
     * @param {Code} message Code
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Code.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.codeText = "";
            object.language = "";
            object.showLineNumbers = false;
            object.wrapLines = false;
        }
        if (message.codeText != null && message.hasOwnProperty("codeText"))
            object.codeText = message.codeText;
        if (message.language != null && message.hasOwnProperty("language"))
            object.language = message.language;
        if (message.showLineNumbers != null && message.hasOwnProperty("showLineNumbers"))
            object.showLineNumbers = message.showLineNumbers;
        if (message.wrapLines != null && message.hasOwnProperty("wrapLines"))
            object.wrapLines = message.wrapLines;
        return object;
    };

    /**
     * Converts this Code to JSON.
     * @function toJSON
     * @memberof Code
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Code.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Code
     * @function getTypeUrl
     * @memberof Code
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Code.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Code";
    };

    return Code;
})();

export const ColorPicker = $root.ColorPicker = (() => {

    /**
     * Properties of a ColorPicker.
     * @exports IColorPicker
     * @interface IColorPicker
     * @property {string|null} [id] ColorPicker id
     * @property {string|null} [label] ColorPicker label
     * @property {string|null} ["default"] ColorPicker default
     * @property {string|null} [help] ColorPicker help
     * @property {string|null} [formId] ColorPicker formId
     * @property {string|null} [value] ColorPicker value
     * @property {boolean|null} [setValue] ColorPicker setValue
     * @property {boolean|null} [disabled] ColorPicker disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] ColorPicker labelVisibility
     */

    /**
     * Constructs a new ColorPicker.
     * @exports ColorPicker
     * @classdesc Represents a ColorPicker.
     * @implements IColorPicker
     * @constructor
     * @param {IColorPicker=} [properties] Properties to set
     */
    function ColorPicker(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ColorPicker id.
     * @member {string} id
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.id = "";

    /**
     * ColorPicker label.
     * @member {string} label
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.label = "";

    /**
     * ColorPicker default.
     * @member {string} default
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype["default"] = "";

    /**
     * ColorPicker help.
     * @member {string} help
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.help = "";

    /**
     * ColorPicker formId.
     * @member {string} formId
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.formId = "";

    /**
     * ColorPicker value.
     * @member {string} value
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.value = "";

    /**
     * ColorPicker setValue.
     * @member {boolean} setValue
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.setValue = false;

    /**
     * ColorPicker disabled.
     * @member {boolean} disabled
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.disabled = false;

    /**
     * ColorPicker labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof ColorPicker
     * @instance
     */
    ColorPicker.prototype.labelVisibility = null;

    /**
     * Creates a new ColorPicker instance using the specified properties.
     * @function create
     * @memberof ColorPicker
     * @static
     * @param {IColorPicker=} [properties] Properties to set
     * @returns {ColorPicker} ColorPicker instance
     */
    ColorPicker.create = function create(properties) {
        return new ColorPicker(properties);
    };

    /**
     * Encodes the specified ColorPicker message. Does not implicitly {@link ColorPicker.verify|verify} messages.
     * @function encode
     * @memberof ColorPicker
     * @static
     * @param {IColorPicker} message ColorPicker message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ColorPicker.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message["default"]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ColorPicker message, length delimited. Does not implicitly {@link ColorPicker.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ColorPicker
     * @static
     * @param {IColorPicker} message ColorPicker message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ColorPicker.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ColorPicker message from the specified reader or buffer.
     * @function decode
     * @memberof ColorPicker
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ColorPicker} ColorPicker
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ColorPicker.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ColorPicker();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.string();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.formId = reader.string();
                    break;
                }
            case 6: {
                    message.value = reader.string();
                    break;
                }
            case 7: {
                    message.setValue = reader.bool();
                    break;
                }
            case 8: {
                    message.disabled = reader.bool();
                    break;
                }
            case 9: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ColorPicker message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ColorPicker
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ColorPicker} ColorPicker
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ColorPicker.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ColorPicker message.
     * @function verify
     * @memberof ColorPicker
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ColorPicker.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default"))
            if (!$util.isString(message["default"]))
                return "default: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates a ColorPicker message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ColorPicker
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ColorPicker} ColorPicker
     */
    ColorPicker.fromObject = function fromObject(object) {
        if (object instanceof $root.ColorPicker)
            return object;
        let message = new $root.ColorPicker();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = String(object["default"]);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = String(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".ColorPicker.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from a ColorPicker message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ColorPicker
     * @static
     * @param {ColorPicker} message ColorPicker
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ColorPicker.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object["default"] = "";
            object.help = "";
            object.formId = "";
            object.value = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default"))
            object["default"] = message["default"];
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this ColorPicker to JSON.
     * @function toJSON
     * @memberof ColorPicker
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ColorPicker.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ColorPicker
     * @function getTypeUrl
     * @memberof ColorPicker
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ColorPicker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ColorPicker";
    };

    return ColorPicker;
})();

export const DataFrame = $root.DataFrame = (() => {

    /**
     * Properties of a DataFrame.
     * @exports IDataFrame
     * @interface IDataFrame
     * @property {ITable|null} [data] DataFrame data
     * @property {IIndex|null} [index] DataFrame index
     * @property {IIndex|null} [columns] DataFrame columns
     * @property {ITableStyle|null} [style] DataFrame style
     */

    /**
     * Constructs a new DataFrame.
     * @exports DataFrame
     * @classdesc Represents a DataFrame.
     * @implements IDataFrame
     * @constructor
     * @param {IDataFrame=} [properties] Properties to set
     */
    function DataFrame(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DataFrame data.
     * @member {ITable|null|undefined} data
     * @memberof DataFrame
     * @instance
     */
    DataFrame.prototype.data = null;

    /**
     * DataFrame index.
     * @member {IIndex|null|undefined} index
     * @memberof DataFrame
     * @instance
     */
    DataFrame.prototype.index = null;

    /**
     * DataFrame columns.
     * @member {IIndex|null|undefined} columns
     * @memberof DataFrame
     * @instance
     */
    DataFrame.prototype.columns = null;

    /**
     * DataFrame style.
     * @member {ITableStyle|null|undefined} style
     * @memberof DataFrame
     * @instance
     */
    DataFrame.prototype.style = null;

    /**
     * Creates a new DataFrame instance using the specified properties.
     * @function create
     * @memberof DataFrame
     * @static
     * @param {IDataFrame=} [properties] Properties to set
     * @returns {DataFrame} DataFrame instance
     */
    DataFrame.create = function create(properties) {
        return new DataFrame(properties);
    };

    /**
     * Encodes the specified DataFrame message. Does not implicitly {@link DataFrame.verify|verify} messages.
     * @function encode
     * @memberof DataFrame
     * @static
     * @param {IDataFrame} message DataFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DataFrame.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.Table.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.index != null && Object.hasOwnProperty.call(message, "index"))
            $root.Index.encode(message.index, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.columns != null && Object.hasOwnProperty.call(message, "columns"))
            $root.Index.encode(message.columns, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            $root.TableStyle.encode(message.style, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified DataFrame message, length delimited. Does not implicitly {@link DataFrame.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DataFrame
     * @static
     * @param {IDataFrame} message DataFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DataFrame.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DataFrame message from the specified reader or buffer.
     * @function decode
     * @memberof DataFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DataFrame} DataFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DataFrame.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DataFrame();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.Table.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.index = $root.Index.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.columns = $root.Index.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.style = $root.TableStyle.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DataFrame message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DataFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DataFrame} DataFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DataFrame.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DataFrame message.
     * @function verify
     * @memberof DataFrame
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DataFrame.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.Table.verify(message.data);
            if (error)
                return "data." + error;
        }
        if (message.index != null && message.hasOwnProperty("index")) {
            let error = $root.Index.verify(message.index);
            if (error)
                return "index." + error;
        }
        if (message.columns != null && message.hasOwnProperty("columns")) {
            let error = $root.Index.verify(message.columns);
            if (error)
                return "columns." + error;
        }
        if (message.style != null && message.hasOwnProperty("style")) {
            let error = $root.TableStyle.verify(message.style);
            if (error)
                return "style." + error;
        }
        return null;
    };

    /**
     * Creates a DataFrame message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DataFrame
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DataFrame} DataFrame
     */
    DataFrame.fromObject = function fromObject(object) {
        if (object instanceof $root.DataFrame)
            return object;
        let message = new $root.DataFrame();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".DataFrame.data: object expected");
            message.data = $root.Table.fromObject(object.data);
        }
        if (object.index != null) {
            if (typeof object.index !== "object")
                throw TypeError(".DataFrame.index: object expected");
            message.index = $root.Index.fromObject(object.index);
        }
        if (object.columns != null) {
            if (typeof object.columns !== "object")
                throw TypeError(".DataFrame.columns: object expected");
            message.columns = $root.Index.fromObject(object.columns);
        }
        if (object.style != null) {
            if (typeof object.style !== "object")
                throw TypeError(".DataFrame.style: object expected");
            message.style = $root.TableStyle.fromObject(object.style);
        }
        return message;
    };

    /**
     * Creates a plain object from a DataFrame message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DataFrame
     * @static
     * @param {DataFrame} message DataFrame
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DataFrame.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.data = null;
            object.index = null;
            object.columns = null;
            object.style = null;
        }
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.Table.toObject(message.data, options);
        if (message.index != null && message.hasOwnProperty("index"))
            object.index = $root.Index.toObject(message.index, options);
        if (message.columns != null && message.hasOwnProperty("columns"))
            object.columns = $root.Index.toObject(message.columns, options);
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = $root.TableStyle.toObject(message.style, options);
        return object;
    };

    /**
     * Converts this DataFrame to JSON.
     * @function toJSON
     * @memberof DataFrame
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DataFrame.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DataFrame
     * @function getTypeUrl
     * @memberof DataFrame
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DataFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DataFrame";
    };

    return DataFrame;
})();

export const Index = $root.Index = (() => {

    /**
     * Properties of an Index.
     * @exports IIndex
     * @interface IIndex
     * @property {IPlainIndex|null} [plainIndex] Index plainIndex
     * @property {IRangeIndex|null} [rangeIndex] Index rangeIndex
     * @property {IMultiIndex|null} [multiIndex] Index multiIndex
     * @property {IDatetimeIndex|null} [datetimeIndex] Index datetimeIndex
     * @property {ITimedeltaIndex|null} [timedeltaIndex] Index timedeltaIndex
     * @property {IInt64Index|null} [int_64Index] Index int_64Index
     * @property {IFloat64Index|null} [float_64Index] Index float_64Index
     */

    /**
     * Constructs a new Index.
     * @exports Index
     * @classdesc Represents an Index.
     * @implements IIndex
     * @constructor
     * @param {IIndex=} [properties] Properties to set
     */
    function Index(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Index plainIndex.
     * @member {IPlainIndex|null|undefined} plainIndex
     * @memberof Index
     * @instance
     */
    Index.prototype.plainIndex = null;

    /**
     * Index rangeIndex.
     * @member {IRangeIndex|null|undefined} rangeIndex
     * @memberof Index
     * @instance
     */
    Index.prototype.rangeIndex = null;

    /**
     * Index multiIndex.
     * @member {IMultiIndex|null|undefined} multiIndex
     * @memberof Index
     * @instance
     */
    Index.prototype.multiIndex = null;

    /**
     * Index datetimeIndex.
     * @member {IDatetimeIndex|null|undefined} datetimeIndex
     * @memberof Index
     * @instance
     */
    Index.prototype.datetimeIndex = null;

    /**
     * Index timedeltaIndex.
     * @member {ITimedeltaIndex|null|undefined} timedeltaIndex
     * @memberof Index
     * @instance
     */
    Index.prototype.timedeltaIndex = null;

    /**
     * Index int_64Index.
     * @member {IInt64Index|null|undefined} int_64Index
     * @memberof Index
     * @instance
     */
    Index.prototype.int_64Index = null;

    /**
     * Index float_64Index.
     * @member {IFloat64Index|null|undefined} float_64Index
     * @memberof Index
     * @instance
     */
    Index.prototype.float_64Index = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Index type.
     * @member {"plainIndex"|"rangeIndex"|"multiIndex"|"datetimeIndex"|"timedeltaIndex"|"int_64Index"|"float_64Index"|undefined} type
     * @memberof Index
     * @instance
     */
    Object.defineProperty(Index.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["plainIndex", "rangeIndex", "multiIndex", "datetimeIndex", "timedeltaIndex", "int_64Index", "float_64Index"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Index instance using the specified properties.
     * @function create
     * @memberof Index
     * @static
     * @param {IIndex=} [properties] Properties to set
     * @returns {Index} Index instance
     */
    Index.create = function create(properties) {
        return new Index(properties);
    };

    /**
     * Encodes the specified Index message. Does not implicitly {@link Index.verify|verify} messages.
     * @function encode
     * @memberof Index
     * @static
     * @param {IIndex} message Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Index.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.plainIndex != null && Object.hasOwnProperty.call(message, "plainIndex"))
            $root.PlainIndex.encode(message.plainIndex, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.rangeIndex != null && Object.hasOwnProperty.call(message, "rangeIndex"))
            $root.RangeIndex.encode(message.rangeIndex, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.multiIndex != null && Object.hasOwnProperty.call(message, "multiIndex"))
            $root.MultiIndex.encode(message.multiIndex, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.datetimeIndex != null && Object.hasOwnProperty.call(message, "datetimeIndex"))
            $root.DatetimeIndex.encode(message.datetimeIndex, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.timedeltaIndex != null && Object.hasOwnProperty.call(message, "timedeltaIndex"))
            $root.TimedeltaIndex.encode(message.timedeltaIndex, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.int_64Index != null && Object.hasOwnProperty.call(message, "int_64Index"))
            $root.Int64Index.encode(message.int_64Index, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.float_64Index != null && Object.hasOwnProperty.call(message, "float_64Index"))
            $root.Float64Index.encode(message.float_64Index, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Index message, length delimited. Does not implicitly {@link Index.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Index
     * @static
     * @param {IIndex} message Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Index.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Index message from the specified reader or buffer.
     * @function decode
     * @memberof Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Index} Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Index.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Index();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.plainIndex = $root.PlainIndex.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.rangeIndex = $root.RangeIndex.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.multiIndex = $root.MultiIndex.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.datetimeIndex = $root.DatetimeIndex.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.timedeltaIndex = $root.TimedeltaIndex.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.int_64Index = $root.Int64Index.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.float_64Index = $root.Float64Index.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Index message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Index} Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Index.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Index message.
     * @function verify
     * @memberof Index
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Index.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.plainIndex != null && message.hasOwnProperty("plainIndex")) {
            properties.type = 1;
            {
                let error = $root.PlainIndex.verify(message.plainIndex);
                if (error)
                    return "plainIndex." + error;
            }
        }
        if (message.rangeIndex != null && message.hasOwnProperty("rangeIndex")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.RangeIndex.verify(message.rangeIndex);
                if (error)
                    return "rangeIndex." + error;
            }
        }
        if (message.multiIndex != null && message.hasOwnProperty("multiIndex")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.MultiIndex.verify(message.multiIndex);
                if (error)
                    return "multiIndex." + error;
            }
        }
        if (message.datetimeIndex != null && message.hasOwnProperty("datetimeIndex")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DatetimeIndex.verify(message.datetimeIndex);
                if (error)
                    return "datetimeIndex." + error;
            }
        }
        if (message.timedeltaIndex != null && message.hasOwnProperty("timedeltaIndex")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.TimedeltaIndex.verify(message.timedeltaIndex);
                if (error)
                    return "timedeltaIndex." + error;
            }
        }
        if (message.int_64Index != null && message.hasOwnProperty("int_64Index")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Int64Index.verify(message.int_64Index);
                if (error)
                    return "int_64Index." + error;
            }
        }
        if (message.float_64Index != null && message.hasOwnProperty("float_64Index")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Float64Index.verify(message.float_64Index);
                if (error)
                    return "float_64Index." + error;
            }
        }
        return null;
    };

    /**
     * Creates an Index message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Index
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Index} Index
     */
    Index.fromObject = function fromObject(object) {
        if (object instanceof $root.Index)
            return object;
        let message = new $root.Index();
        if (object.plainIndex != null) {
            if (typeof object.plainIndex !== "object")
                throw TypeError(".Index.plainIndex: object expected");
            message.plainIndex = $root.PlainIndex.fromObject(object.plainIndex);
        }
        if (object.rangeIndex != null) {
            if (typeof object.rangeIndex !== "object")
                throw TypeError(".Index.rangeIndex: object expected");
            message.rangeIndex = $root.RangeIndex.fromObject(object.rangeIndex);
        }
        if (object.multiIndex != null) {
            if (typeof object.multiIndex !== "object")
                throw TypeError(".Index.multiIndex: object expected");
            message.multiIndex = $root.MultiIndex.fromObject(object.multiIndex);
        }
        if (object.datetimeIndex != null) {
            if (typeof object.datetimeIndex !== "object")
                throw TypeError(".Index.datetimeIndex: object expected");
            message.datetimeIndex = $root.DatetimeIndex.fromObject(object.datetimeIndex);
        }
        if (object.timedeltaIndex != null) {
            if (typeof object.timedeltaIndex !== "object")
                throw TypeError(".Index.timedeltaIndex: object expected");
            message.timedeltaIndex = $root.TimedeltaIndex.fromObject(object.timedeltaIndex);
        }
        if (object.int_64Index != null) {
            if (typeof object.int_64Index !== "object")
                throw TypeError(".Index.int_64Index: object expected");
            message.int_64Index = $root.Int64Index.fromObject(object.int_64Index);
        }
        if (object.float_64Index != null) {
            if (typeof object.float_64Index !== "object")
                throw TypeError(".Index.float_64Index: object expected");
            message.float_64Index = $root.Float64Index.fromObject(object.float_64Index);
        }
        return message;
    };

    /**
     * Creates a plain object from an Index message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Index
     * @static
     * @param {Index} message Index
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Index.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.plainIndex != null && message.hasOwnProperty("plainIndex")) {
            object.plainIndex = $root.PlainIndex.toObject(message.plainIndex, options);
            if (options.oneofs)
                object.type = "plainIndex";
        }
        if (message.rangeIndex != null && message.hasOwnProperty("rangeIndex")) {
            object.rangeIndex = $root.RangeIndex.toObject(message.rangeIndex, options);
            if (options.oneofs)
                object.type = "rangeIndex";
        }
        if (message.multiIndex != null && message.hasOwnProperty("multiIndex")) {
            object.multiIndex = $root.MultiIndex.toObject(message.multiIndex, options);
            if (options.oneofs)
                object.type = "multiIndex";
        }
        if (message.datetimeIndex != null && message.hasOwnProperty("datetimeIndex")) {
            object.datetimeIndex = $root.DatetimeIndex.toObject(message.datetimeIndex, options);
            if (options.oneofs)
                object.type = "datetimeIndex";
        }
        if (message.timedeltaIndex != null && message.hasOwnProperty("timedeltaIndex")) {
            object.timedeltaIndex = $root.TimedeltaIndex.toObject(message.timedeltaIndex, options);
            if (options.oneofs)
                object.type = "timedeltaIndex";
        }
        if (message.int_64Index != null && message.hasOwnProperty("int_64Index")) {
            object.int_64Index = $root.Int64Index.toObject(message.int_64Index, options);
            if (options.oneofs)
                object.type = "int_64Index";
        }
        if (message.float_64Index != null && message.hasOwnProperty("float_64Index")) {
            object.float_64Index = $root.Float64Index.toObject(message.float_64Index, options);
            if (options.oneofs)
                object.type = "float_64Index";
        }
        return object;
    };

    /**
     * Converts this Index to JSON.
     * @function toJSON
     * @memberof Index
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Index.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Index
     * @function getTypeUrl
     * @memberof Index
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Index.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Index";
    };

    return Index;
})();

export const PlainIndex = $root.PlainIndex = (() => {

    /**
     * Properties of a PlainIndex.
     * @exports IPlainIndex
     * @interface IPlainIndex
     * @property {IAnyArray|null} [data] PlainIndex data
     */

    /**
     * Constructs a new PlainIndex.
     * @exports PlainIndex
     * @classdesc Represents a PlainIndex.
     * @implements IPlainIndex
     * @constructor
     * @param {IPlainIndex=} [properties] Properties to set
     */
    function PlainIndex(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PlainIndex data.
     * @member {IAnyArray|null|undefined} data
     * @memberof PlainIndex
     * @instance
     */
    PlainIndex.prototype.data = null;

    /**
     * Creates a new PlainIndex instance using the specified properties.
     * @function create
     * @memberof PlainIndex
     * @static
     * @param {IPlainIndex=} [properties] Properties to set
     * @returns {PlainIndex} PlainIndex instance
     */
    PlainIndex.create = function create(properties) {
        return new PlainIndex(properties);
    };

    /**
     * Encodes the specified PlainIndex message. Does not implicitly {@link PlainIndex.verify|verify} messages.
     * @function encode
     * @memberof PlainIndex
     * @static
     * @param {IPlainIndex} message PlainIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PlainIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.AnyArray.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified PlainIndex message, length delimited. Does not implicitly {@link PlainIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PlainIndex
     * @static
     * @param {IPlainIndex} message PlainIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PlainIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PlainIndex message from the specified reader or buffer.
     * @function decode
     * @memberof PlainIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PlainIndex} PlainIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PlainIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlainIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.AnyArray.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PlainIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PlainIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PlainIndex} PlainIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PlainIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PlainIndex message.
     * @function verify
     * @memberof PlainIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PlainIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.AnyArray.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates a PlainIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PlainIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PlainIndex} PlainIndex
     */
    PlainIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.PlainIndex)
            return object;
        let message = new $root.PlainIndex();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".PlainIndex.data: object expected");
            message.data = $root.AnyArray.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from a PlainIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PlainIndex
     * @static
     * @param {PlainIndex} message PlainIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PlainIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.data = null;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.AnyArray.toObject(message.data, options);
        return object;
    };

    /**
     * Converts this PlainIndex to JSON.
     * @function toJSON
     * @memberof PlainIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PlainIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PlainIndex
     * @function getTypeUrl
     * @memberof PlainIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PlainIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PlainIndex";
    };

    return PlainIndex;
})();

export const RangeIndex = $root.RangeIndex = (() => {

    /**
     * Properties of a RangeIndex.
     * @exports IRangeIndex
     * @interface IRangeIndex
     * @property {number|Long|null} [start] RangeIndex start
     * @property {number|Long|null} [stop] RangeIndex stop
     */

    /**
     * Constructs a new RangeIndex.
     * @exports RangeIndex
     * @classdesc Represents a RangeIndex.
     * @implements IRangeIndex
     * @constructor
     * @param {IRangeIndex=} [properties] Properties to set
     */
    function RangeIndex(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RangeIndex start.
     * @member {number|Long} start
     * @memberof RangeIndex
     * @instance
     */
    RangeIndex.prototype.start = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * RangeIndex stop.
     * @member {number|Long} stop
     * @memberof RangeIndex
     * @instance
     */
    RangeIndex.prototype.stop = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new RangeIndex instance using the specified properties.
     * @function create
     * @memberof RangeIndex
     * @static
     * @param {IRangeIndex=} [properties] Properties to set
     * @returns {RangeIndex} RangeIndex instance
     */
    RangeIndex.create = function create(properties) {
        return new RangeIndex(properties);
    };

    /**
     * Encodes the specified RangeIndex message. Does not implicitly {@link RangeIndex.verify|verify} messages.
     * @function encode
     * @memberof RangeIndex
     * @static
     * @param {IRangeIndex} message RangeIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RangeIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.start != null && Object.hasOwnProperty.call(message, "start"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.start);
        if (message.stop != null && Object.hasOwnProperty.call(message, "stop"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.stop);
        return writer;
    };

    /**
     * Encodes the specified RangeIndex message, length delimited. Does not implicitly {@link RangeIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RangeIndex
     * @static
     * @param {IRangeIndex} message RangeIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RangeIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RangeIndex message from the specified reader or buffer.
     * @function decode
     * @memberof RangeIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RangeIndex} RangeIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RangeIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RangeIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.start = reader.int64();
                    break;
                }
            case 2: {
                    message.stop = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RangeIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RangeIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RangeIndex} RangeIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RangeIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RangeIndex message.
     * @function verify
     * @memberof RangeIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RangeIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.start != null && message.hasOwnProperty("start"))
            if (!$util.isInteger(message.start) && !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high)))
                return "start: integer|Long expected";
        if (message.stop != null && message.hasOwnProperty("stop"))
            if (!$util.isInteger(message.stop) && !(message.stop && $util.isInteger(message.stop.low) && $util.isInteger(message.stop.high)))
                return "stop: integer|Long expected";
        return null;
    };

    /**
     * Creates a RangeIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RangeIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RangeIndex} RangeIndex
     */
    RangeIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.RangeIndex)
            return object;
        let message = new $root.RangeIndex();
        if (object.start != null)
            if ($util.Long)
                (message.start = $util.Long.fromValue(object.start)).unsigned = false;
            else if (typeof object.start === "string")
                message.start = parseInt(object.start, 10);
            else if (typeof object.start === "number")
                message.start = object.start;
            else if (typeof object.start === "object")
                message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber();
        if (object.stop != null)
            if ($util.Long)
                (message.stop = $util.Long.fromValue(object.stop)).unsigned = false;
            else if (typeof object.stop === "string")
                message.stop = parseInt(object.stop, 10);
            else if (typeof object.stop === "number")
                message.stop = object.stop;
            else if (typeof object.stop === "object")
                message.stop = new $util.LongBits(object.stop.low >>> 0, object.stop.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a RangeIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RangeIndex
     * @static
     * @param {RangeIndex} message RangeIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RangeIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.start = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.stop = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.stop = options.longs === String ? "0" : 0;
        }
        if (message.start != null && message.hasOwnProperty("start"))
            if (typeof message.start === "number")
                object.start = options.longs === String ? String(message.start) : message.start;
            else
                object.start = options.longs === String ? $util.Long.prototype.toString.call(message.start) : options.longs === Number ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber() : message.start;
        if (message.stop != null && message.hasOwnProperty("stop"))
            if (typeof message.stop === "number")
                object.stop = options.longs === String ? String(message.stop) : message.stop;
            else
                object.stop = options.longs === String ? $util.Long.prototype.toString.call(message.stop) : options.longs === Number ? new $util.LongBits(message.stop.low >>> 0, message.stop.high >>> 0).toNumber() : message.stop;
        return object;
    };

    /**
     * Converts this RangeIndex to JSON.
     * @function toJSON
     * @memberof RangeIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RangeIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RangeIndex
     * @function getTypeUrl
     * @memberof RangeIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RangeIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RangeIndex";
    };

    return RangeIndex;
})();

export const MultiIndex = $root.MultiIndex = (() => {

    /**
     * Properties of a MultiIndex.
     * @exports IMultiIndex
     * @interface IMultiIndex
     * @property {Array.<IIndex>|null} [levels] MultiIndex levels
     * @property {Array.<IInt32Array>|null} [labels] MultiIndex labels
     */

    /**
     * Constructs a new MultiIndex.
     * @exports MultiIndex
     * @classdesc Represents a MultiIndex.
     * @implements IMultiIndex
     * @constructor
     * @param {IMultiIndex=} [properties] Properties to set
     */
    function MultiIndex(properties) {
        this.levels = [];
        this.labels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiIndex levels.
     * @member {Array.<IIndex>} levels
     * @memberof MultiIndex
     * @instance
     */
    MultiIndex.prototype.levels = $util.emptyArray;

    /**
     * MultiIndex labels.
     * @member {Array.<IInt32Array>} labels
     * @memberof MultiIndex
     * @instance
     */
    MultiIndex.prototype.labels = $util.emptyArray;

    /**
     * Creates a new MultiIndex instance using the specified properties.
     * @function create
     * @memberof MultiIndex
     * @static
     * @param {IMultiIndex=} [properties] Properties to set
     * @returns {MultiIndex} MultiIndex instance
     */
    MultiIndex.create = function create(properties) {
        return new MultiIndex(properties);
    };

    /**
     * Encodes the specified MultiIndex message. Does not implicitly {@link MultiIndex.verify|verify} messages.
     * @function encode
     * @memberof MultiIndex
     * @static
     * @param {IMultiIndex} message MultiIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.levels != null && message.levels.length)
            for (let i = 0; i < message.levels.length; ++i)
                $root.Index.encode(message.levels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.labels != null && message.labels.length)
            for (let i = 0; i < message.labels.length; ++i)
                $root.Int32Array.encode(message.labels[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MultiIndex message, length delimited. Does not implicitly {@link MultiIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MultiIndex
     * @static
     * @param {IMultiIndex} message MultiIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiIndex message from the specified reader or buffer.
     * @function decode
     * @memberof MultiIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MultiIndex} MultiIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.levels && message.levels.length))
                        message.levels = [];
                    message.levels.push($root.Index.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    if (!(message.labels && message.labels.length))
                        message.labels = [];
                    message.labels.push($root.Int32Array.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MultiIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MultiIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MultiIndex} MultiIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiIndex message.
     * @function verify
     * @memberof MultiIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.levels != null && message.hasOwnProperty("levels")) {
            if (!Array.isArray(message.levels))
                return "levels: array expected";
            for (let i = 0; i < message.levels.length; ++i) {
                let error = $root.Index.verify(message.levels[i]);
                if (error)
                    return "levels." + error;
            }
        }
        if (message.labels != null && message.hasOwnProperty("labels")) {
            if (!Array.isArray(message.labels))
                return "labels: array expected";
            for (let i = 0; i < message.labels.length; ++i) {
                let error = $root.Int32Array.verify(message.labels[i]);
                if (error)
                    return "labels." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MultiIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MultiIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MultiIndex} MultiIndex
     */
    MultiIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiIndex)
            return object;
        let message = new $root.MultiIndex();
        if (object.levels) {
            if (!Array.isArray(object.levels))
                throw TypeError(".MultiIndex.levels: array expected");
            message.levels = [];
            for (let i = 0; i < object.levels.length; ++i) {
                if (typeof object.levels[i] !== "object")
                    throw TypeError(".MultiIndex.levels: object expected");
                message.levels[i] = $root.Index.fromObject(object.levels[i]);
            }
        }
        if (object.labels) {
            if (!Array.isArray(object.labels))
                throw TypeError(".MultiIndex.labels: array expected");
            message.labels = [];
            for (let i = 0; i < object.labels.length; ++i) {
                if (typeof object.labels[i] !== "object")
                    throw TypeError(".MultiIndex.labels: object expected");
                message.labels[i] = $root.Int32Array.fromObject(object.labels[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MultiIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MultiIndex
     * @static
     * @param {MultiIndex} message MultiIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.levels = [];
            object.labels = [];
        }
        if (message.levels && message.levels.length) {
            object.levels = [];
            for (let j = 0; j < message.levels.length; ++j)
                object.levels[j] = $root.Index.toObject(message.levels[j], options);
        }
        if (message.labels && message.labels.length) {
            object.labels = [];
            for (let j = 0; j < message.labels.length; ++j)
                object.labels[j] = $root.Int32Array.toObject(message.labels[j], options);
        }
        return object;
    };

    /**
     * Converts this MultiIndex to JSON.
     * @function toJSON
     * @memberof MultiIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiIndex
     * @function getTypeUrl
     * @memberof MultiIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiIndex";
    };

    return MultiIndex;
})();

export const DatetimeIndex = $root.DatetimeIndex = (() => {

    /**
     * Properties of a DatetimeIndex.
     * @exports IDatetimeIndex
     * @interface IDatetimeIndex
     * @property {IStringArray|null} [data] DatetimeIndex data
     */

    /**
     * Constructs a new DatetimeIndex.
     * @exports DatetimeIndex
     * @classdesc Represents a DatetimeIndex.
     * @implements IDatetimeIndex
     * @constructor
     * @param {IDatetimeIndex=} [properties] Properties to set
     */
    function DatetimeIndex(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DatetimeIndex data.
     * @member {IStringArray|null|undefined} data
     * @memberof DatetimeIndex
     * @instance
     */
    DatetimeIndex.prototype.data = null;

    /**
     * Creates a new DatetimeIndex instance using the specified properties.
     * @function create
     * @memberof DatetimeIndex
     * @static
     * @param {IDatetimeIndex=} [properties] Properties to set
     * @returns {DatetimeIndex} DatetimeIndex instance
     */
    DatetimeIndex.create = function create(properties) {
        return new DatetimeIndex(properties);
    };

    /**
     * Encodes the specified DatetimeIndex message. Does not implicitly {@link DatetimeIndex.verify|verify} messages.
     * @function encode
     * @memberof DatetimeIndex
     * @static
     * @param {IDatetimeIndex} message DatetimeIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DatetimeIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.StringArray.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified DatetimeIndex message, length delimited. Does not implicitly {@link DatetimeIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DatetimeIndex
     * @static
     * @param {IDatetimeIndex} message DatetimeIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DatetimeIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DatetimeIndex message from the specified reader or buffer.
     * @function decode
     * @memberof DatetimeIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DatetimeIndex} DatetimeIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DatetimeIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DatetimeIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.StringArray.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DatetimeIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DatetimeIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DatetimeIndex} DatetimeIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DatetimeIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DatetimeIndex message.
     * @function verify
     * @memberof DatetimeIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DatetimeIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.StringArray.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates a DatetimeIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DatetimeIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DatetimeIndex} DatetimeIndex
     */
    DatetimeIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.DatetimeIndex)
            return object;
        let message = new $root.DatetimeIndex();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".DatetimeIndex.data: object expected");
            message.data = $root.StringArray.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from a DatetimeIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DatetimeIndex
     * @static
     * @param {DatetimeIndex} message DatetimeIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DatetimeIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.data = null;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.StringArray.toObject(message.data, options);
        return object;
    };

    /**
     * Converts this DatetimeIndex to JSON.
     * @function toJSON
     * @memberof DatetimeIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DatetimeIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DatetimeIndex
     * @function getTypeUrl
     * @memberof DatetimeIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DatetimeIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DatetimeIndex";
    };

    return DatetimeIndex;
})();

export const TimedeltaIndex = $root.TimedeltaIndex = (() => {

    /**
     * Properties of a TimedeltaIndex.
     * @exports ITimedeltaIndex
     * @interface ITimedeltaIndex
     * @property {IInt64Array|null} [data] TimedeltaIndex data
     */

    /**
     * Constructs a new TimedeltaIndex.
     * @exports TimedeltaIndex
     * @classdesc Represents a TimedeltaIndex.
     * @implements ITimedeltaIndex
     * @constructor
     * @param {ITimedeltaIndex=} [properties] Properties to set
     */
    function TimedeltaIndex(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TimedeltaIndex data.
     * @member {IInt64Array|null|undefined} data
     * @memberof TimedeltaIndex
     * @instance
     */
    TimedeltaIndex.prototype.data = null;

    /**
     * Creates a new TimedeltaIndex instance using the specified properties.
     * @function create
     * @memberof TimedeltaIndex
     * @static
     * @param {ITimedeltaIndex=} [properties] Properties to set
     * @returns {TimedeltaIndex} TimedeltaIndex instance
     */
    TimedeltaIndex.create = function create(properties) {
        return new TimedeltaIndex(properties);
    };

    /**
     * Encodes the specified TimedeltaIndex message. Does not implicitly {@link TimedeltaIndex.verify|verify} messages.
     * @function encode
     * @memberof TimedeltaIndex
     * @static
     * @param {ITimedeltaIndex} message TimedeltaIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TimedeltaIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.Int64Array.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TimedeltaIndex message, length delimited. Does not implicitly {@link TimedeltaIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TimedeltaIndex
     * @static
     * @param {ITimedeltaIndex} message TimedeltaIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TimedeltaIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TimedeltaIndex message from the specified reader or buffer.
     * @function decode
     * @memberof TimedeltaIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TimedeltaIndex} TimedeltaIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TimedeltaIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TimedeltaIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.Int64Array.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TimedeltaIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TimedeltaIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TimedeltaIndex} TimedeltaIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TimedeltaIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TimedeltaIndex message.
     * @function verify
     * @memberof TimedeltaIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TimedeltaIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.Int64Array.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates a TimedeltaIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TimedeltaIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TimedeltaIndex} TimedeltaIndex
     */
    TimedeltaIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.TimedeltaIndex)
            return object;
        let message = new $root.TimedeltaIndex();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".TimedeltaIndex.data: object expected");
            message.data = $root.Int64Array.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from a TimedeltaIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TimedeltaIndex
     * @static
     * @param {TimedeltaIndex} message TimedeltaIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TimedeltaIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.data = null;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.Int64Array.toObject(message.data, options);
        return object;
    };

    /**
     * Converts this TimedeltaIndex to JSON.
     * @function toJSON
     * @memberof TimedeltaIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TimedeltaIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TimedeltaIndex
     * @function getTypeUrl
     * @memberof TimedeltaIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TimedeltaIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TimedeltaIndex";
    };

    return TimedeltaIndex;
})();

export const Int64Index = $root.Int64Index = (() => {

    /**
     * Properties of an Int64Index.
     * @exports IInt64Index
     * @interface IInt64Index
     * @property {IInt64Array|null} [data] Int64Index data
     */

    /**
     * Constructs a new Int64Index.
     * @exports Int64Index
     * @classdesc Represents an Int64Index.
     * @implements IInt64Index
     * @constructor
     * @param {IInt64Index=} [properties] Properties to set
     */
    function Int64Index(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Int64Index data.
     * @member {IInt64Array|null|undefined} data
     * @memberof Int64Index
     * @instance
     */
    Int64Index.prototype.data = null;

    /**
     * Creates a new Int64Index instance using the specified properties.
     * @function create
     * @memberof Int64Index
     * @static
     * @param {IInt64Index=} [properties] Properties to set
     * @returns {Int64Index} Int64Index instance
     */
    Int64Index.create = function create(properties) {
        return new Int64Index(properties);
    };

    /**
     * Encodes the specified Int64Index message. Does not implicitly {@link Int64Index.verify|verify} messages.
     * @function encode
     * @memberof Int64Index
     * @static
     * @param {IInt64Index} message Int64Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int64Index.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.Int64Array.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Int64Index message, length delimited. Does not implicitly {@link Int64Index.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Int64Index
     * @static
     * @param {IInt64Index} message Int64Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Int64Index.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Int64Index message from the specified reader or buffer.
     * @function decode
     * @memberof Int64Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Int64Index} Int64Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int64Index.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Int64Index();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.Int64Array.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Int64Index message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Int64Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Int64Index} Int64Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Int64Index.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Int64Index message.
     * @function verify
     * @memberof Int64Index
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Int64Index.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.Int64Array.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates an Int64Index message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Int64Index
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Int64Index} Int64Index
     */
    Int64Index.fromObject = function fromObject(object) {
        if (object instanceof $root.Int64Index)
            return object;
        let message = new $root.Int64Index();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".Int64Index.data: object expected");
            message.data = $root.Int64Array.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from an Int64Index message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Int64Index
     * @static
     * @param {Int64Index} message Int64Index
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Int64Index.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.data = null;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.Int64Array.toObject(message.data, options);
        return object;
    };

    /**
     * Converts this Int64Index to JSON.
     * @function toJSON
     * @memberof Int64Index
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Int64Index.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Int64Index
     * @function getTypeUrl
     * @memberof Int64Index
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Int64Index.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Int64Index";
    };

    return Int64Index;
})();

export const Float64Index = $root.Float64Index = (() => {

    /**
     * Properties of a Float64Index.
     * @exports IFloat64Index
     * @interface IFloat64Index
     * @property {IDoubleArray|null} [data] Float64Index data
     */

    /**
     * Constructs a new Float64Index.
     * @exports Float64Index
     * @classdesc Represents a Float64Index.
     * @implements IFloat64Index
     * @constructor
     * @param {IFloat64Index=} [properties] Properties to set
     */
    function Float64Index(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Float64Index data.
     * @member {IDoubleArray|null|undefined} data
     * @memberof Float64Index
     * @instance
     */
    Float64Index.prototype.data = null;

    /**
     * Creates a new Float64Index instance using the specified properties.
     * @function create
     * @memberof Float64Index
     * @static
     * @param {IFloat64Index=} [properties] Properties to set
     * @returns {Float64Index} Float64Index instance
     */
    Float64Index.create = function create(properties) {
        return new Float64Index(properties);
    };

    /**
     * Encodes the specified Float64Index message. Does not implicitly {@link Float64Index.verify|verify} messages.
     * @function encode
     * @memberof Float64Index
     * @static
     * @param {IFloat64Index} message Float64Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Float64Index.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.DoubleArray.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Float64Index message, length delimited. Does not implicitly {@link Float64Index.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Float64Index
     * @static
     * @param {IFloat64Index} message Float64Index message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Float64Index.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Float64Index message from the specified reader or buffer.
     * @function decode
     * @memberof Float64Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Float64Index} Float64Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Float64Index.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Float64Index();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.data = $root.DoubleArray.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Float64Index message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Float64Index
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Float64Index} Float64Index
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Float64Index.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Float64Index message.
     * @function verify
     * @memberof Float64Index
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Float64Index.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.DoubleArray.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates a Float64Index message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Float64Index
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Float64Index} Float64Index
     */
    Float64Index.fromObject = function fromObject(object) {
        if (object instanceof $root.Float64Index)
            return object;
        let message = new $root.Float64Index();
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".Float64Index.data: object expected");
            message.data = $root.DoubleArray.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from a Float64Index message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Float64Index
     * @static
     * @param {Float64Index} message Float64Index
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Float64Index.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.data = null;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.DoubleArray.toObject(message.data, options);
        return object;
    };

    /**
     * Converts this Float64Index to JSON.
     * @function toJSON
     * @memberof Float64Index
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Float64Index.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Float64Index
     * @function getTypeUrl
     * @memberof Float64Index
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Float64Index.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Float64Index";
    };

    return Float64Index;
})();

export const CSSStyle = $root.CSSStyle = (() => {

    /**
     * Properties of a CSSStyle.
     * @exports ICSSStyle
     * @interface ICSSStyle
     * @property {string|null} [property] CSSStyle property
     * @property {string|null} [value] CSSStyle value
     */

    /**
     * Constructs a new CSSStyle.
     * @exports CSSStyle
     * @classdesc Represents a CSSStyle.
     * @implements ICSSStyle
     * @constructor
     * @param {ICSSStyle=} [properties] Properties to set
     */
    function CSSStyle(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CSSStyle property.
     * @member {string} property
     * @memberof CSSStyle
     * @instance
     */
    CSSStyle.prototype.property = "";

    /**
     * CSSStyle value.
     * @member {string} value
     * @memberof CSSStyle
     * @instance
     */
    CSSStyle.prototype.value = "";

    /**
     * Creates a new CSSStyle instance using the specified properties.
     * @function create
     * @memberof CSSStyle
     * @static
     * @param {ICSSStyle=} [properties] Properties to set
     * @returns {CSSStyle} CSSStyle instance
     */
    CSSStyle.create = function create(properties) {
        return new CSSStyle(properties);
    };

    /**
     * Encodes the specified CSSStyle message. Does not implicitly {@link CSSStyle.verify|verify} messages.
     * @function encode
     * @memberof CSSStyle
     * @static
     * @param {ICSSStyle} message CSSStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CSSStyle.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.property != null && Object.hasOwnProperty.call(message, "property"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.property);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        return writer;
    };

    /**
     * Encodes the specified CSSStyle message, length delimited. Does not implicitly {@link CSSStyle.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CSSStyle
     * @static
     * @param {ICSSStyle} message CSSStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CSSStyle.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CSSStyle message from the specified reader or buffer.
     * @function decode
     * @memberof CSSStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CSSStyle} CSSStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CSSStyle.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CSSStyle();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.property = reader.string();
                    break;
                }
            case 2: {
                    message.value = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CSSStyle message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CSSStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CSSStyle} CSSStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CSSStyle.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CSSStyle message.
     * @function verify
     * @memberof CSSStyle
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CSSStyle.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.property != null && message.hasOwnProperty("property"))
            if (!$util.isString(message.property))
                return "property: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        return null;
    };

    /**
     * Creates a CSSStyle message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CSSStyle
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CSSStyle} CSSStyle
     */
    CSSStyle.fromObject = function fromObject(object) {
        if (object instanceof $root.CSSStyle)
            return object;
        let message = new $root.CSSStyle();
        if (object.property != null)
            message.property = String(object.property);
        if (object.value != null)
            message.value = String(object.value);
        return message;
    };

    /**
     * Creates a plain object from a CSSStyle message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CSSStyle
     * @static
     * @param {CSSStyle} message CSSStyle
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CSSStyle.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.property = "";
            object.value = "";
        }
        if (message.property != null && message.hasOwnProperty("property"))
            object.property = message.property;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        return object;
    };

    /**
     * Converts this CSSStyle to JSON.
     * @function toJSON
     * @memberof CSSStyle
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CSSStyle.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CSSStyle
     * @function getTypeUrl
     * @memberof CSSStyle
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CSSStyle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CSSStyle";
    };

    return CSSStyle;
})();

export const CellStyle = $root.CellStyle = (() => {

    /**
     * Properties of a CellStyle.
     * @exports ICellStyle
     * @interface ICellStyle
     * @property {Array.<ICSSStyle>|null} [css] CellStyle css
     * @property {string|null} [displayValue] CellStyle displayValue
     * @property {boolean|null} [hasDisplayValue] CellStyle hasDisplayValue
     */

    /**
     * Constructs a new CellStyle.
     * @exports CellStyle
     * @classdesc Represents a CellStyle.
     * @implements ICellStyle
     * @constructor
     * @param {ICellStyle=} [properties] Properties to set
     */
    function CellStyle(properties) {
        this.css = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CellStyle css.
     * @member {Array.<ICSSStyle>} css
     * @memberof CellStyle
     * @instance
     */
    CellStyle.prototype.css = $util.emptyArray;

    /**
     * CellStyle displayValue.
     * @member {string} displayValue
     * @memberof CellStyle
     * @instance
     */
    CellStyle.prototype.displayValue = "";

    /**
     * CellStyle hasDisplayValue.
     * @member {boolean} hasDisplayValue
     * @memberof CellStyle
     * @instance
     */
    CellStyle.prototype.hasDisplayValue = false;

    /**
     * Creates a new CellStyle instance using the specified properties.
     * @function create
     * @memberof CellStyle
     * @static
     * @param {ICellStyle=} [properties] Properties to set
     * @returns {CellStyle} CellStyle instance
     */
    CellStyle.create = function create(properties) {
        return new CellStyle(properties);
    };

    /**
     * Encodes the specified CellStyle message. Does not implicitly {@link CellStyle.verify|verify} messages.
     * @function encode
     * @memberof CellStyle
     * @static
     * @param {ICellStyle} message CellStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CellStyle.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.css != null && message.css.length)
            for (let i = 0; i < message.css.length; ++i)
                $root.CSSStyle.encode(message.css[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.displayValue != null && Object.hasOwnProperty.call(message, "displayValue"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.displayValue);
        if (message.hasDisplayValue != null && Object.hasOwnProperty.call(message, "hasDisplayValue"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasDisplayValue);
        return writer;
    };

    /**
     * Encodes the specified CellStyle message, length delimited. Does not implicitly {@link CellStyle.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CellStyle
     * @static
     * @param {ICellStyle} message CellStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CellStyle.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CellStyle message from the specified reader or buffer.
     * @function decode
     * @memberof CellStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CellStyle} CellStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CellStyle.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CellStyle();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.css && message.css.length))
                        message.css = [];
                    message.css.push($root.CSSStyle.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.displayValue = reader.string();
                    break;
                }
            case 3: {
                    message.hasDisplayValue = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CellStyle message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CellStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CellStyle} CellStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CellStyle.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CellStyle message.
     * @function verify
     * @memberof CellStyle
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CellStyle.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.css != null && message.hasOwnProperty("css")) {
            if (!Array.isArray(message.css))
                return "css: array expected";
            for (let i = 0; i < message.css.length; ++i) {
                let error = $root.CSSStyle.verify(message.css[i]);
                if (error)
                    return "css." + error;
            }
        }
        if (message.displayValue != null && message.hasOwnProperty("displayValue"))
            if (!$util.isString(message.displayValue))
                return "displayValue: string expected";
        if (message.hasDisplayValue != null && message.hasOwnProperty("hasDisplayValue"))
            if (typeof message.hasDisplayValue !== "boolean")
                return "hasDisplayValue: boolean expected";
        return null;
    };

    /**
     * Creates a CellStyle message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CellStyle
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CellStyle} CellStyle
     */
    CellStyle.fromObject = function fromObject(object) {
        if (object instanceof $root.CellStyle)
            return object;
        let message = new $root.CellStyle();
        if (object.css) {
            if (!Array.isArray(object.css))
                throw TypeError(".CellStyle.css: array expected");
            message.css = [];
            for (let i = 0; i < object.css.length; ++i) {
                if (typeof object.css[i] !== "object")
                    throw TypeError(".CellStyle.css: object expected");
                message.css[i] = $root.CSSStyle.fromObject(object.css[i]);
            }
        }
        if (object.displayValue != null)
            message.displayValue = String(object.displayValue);
        if (object.hasDisplayValue != null)
            message.hasDisplayValue = Boolean(object.hasDisplayValue);
        return message;
    };

    /**
     * Creates a plain object from a CellStyle message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CellStyle
     * @static
     * @param {CellStyle} message CellStyle
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CellStyle.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.css = [];
        if (options.defaults) {
            object.displayValue = "";
            object.hasDisplayValue = false;
        }
        if (message.css && message.css.length) {
            object.css = [];
            for (let j = 0; j < message.css.length; ++j)
                object.css[j] = $root.CSSStyle.toObject(message.css[j], options);
        }
        if (message.displayValue != null && message.hasOwnProperty("displayValue"))
            object.displayValue = message.displayValue;
        if (message.hasDisplayValue != null && message.hasOwnProperty("hasDisplayValue"))
            object.hasDisplayValue = message.hasDisplayValue;
        return object;
    };

    /**
     * Converts this CellStyle to JSON.
     * @function toJSON
     * @memberof CellStyle
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CellStyle.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CellStyle
     * @function getTypeUrl
     * @memberof CellStyle
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CellStyle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CellStyle";
    };

    return CellStyle;
})();

export const CellStyleArray = $root.CellStyleArray = (() => {

    /**
     * Properties of a CellStyleArray.
     * @exports ICellStyleArray
     * @interface ICellStyleArray
     * @property {Array.<ICellStyle>|null} [styles] CellStyleArray styles
     */

    /**
     * Constructs a new CellStyleArray.
     * @exports CellStyleArray
     * @classdesc Represents a CellStyleArray.
     * @implements ICellStyleArray
     * @constructor
     * @param {ICellStyleArray=} [properties] Properties to set
     */
    function CellStyleArray(properties) {
        this.styles = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CellStyleArray styles.
     * @member {Array.<ICellStyle>} styles
     * @memberof CellStyleArray
     * @instance
     */
    CellStyleArray.prototype.styles = $util.emptyArray;

    /**
     * Creates a new CellStyleArray instance using the specified properties.
     * @function create
     * @memberof CellStyleArray
     * @static
     * @param {ICellStyleArray=} [properties] Properties to set
     * @returns {CellStyleArray} CellStyleArray instance
     */
    CellStyleArray.create = function create(properties) {
        return new CellStyleArray(properties);
    };

    /**
     * Encodes the specified CellStyleArray message. Does not implicitly {@link CellStyleArray.verify|verify} messages.
     * @function encode
     * @memberof CellStyleArray
     * @static
     * @param {ICellStyleArray} message CellStyleArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CellStyleArray.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.styles != null && message.styles.length)
            for (let i = 0; i < message.styles.length; ++i)
                $root.CellStyle.encode(message.styles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CellStyleArray message, length delimited. Does not implicitly {@link CellStyleArray.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CellStyleArray
     * @static
     * @param {ICellStyleArray} message CellStyleArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CellStyleArray.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CellStyleArray message from the specified reader or buffer.
     * @function decode
     * @memberof CellStyleArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CellStyleArray} CellStyleArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CellStyleArray.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CellStyleArray();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.styles && message.styles.length))
                        message.styles = [];
                    message.styles.push($root.CellStyle.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CellStyleArray message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CellStyleArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CellStyleArray} CellStyleArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CellStyleArray.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CellStyleArray message.
     * @function verify
     * @memberof CellStyleArray
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CellStyleArray.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.styles != null && message.hasOwnProperty("styles")) {
            if (!Array.isArray(message.styles))
                return "styles: array expected";
            for (let i = 0; i < message.styles.length; ++i) {
                let error = $root.CellStyle.verify(message.styles[i]);
                if (error)
                    return "styles." + error;
            }
        }
        return null;
    };

    /**
     * Creates a CellStyleArray message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CellStyleArray
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CellStyleArray} CellStyleArray
     */
    CellStyleArray.fromObject = function fromObject(object) {
        if (object instanceof $root.CellStyleArray)
            return object;
        let message = new $root.CellStyleArray();
        if (object.styles) {
            if (!Array.isArray(object.styles))
                throw TypeError(".CellStyleArray.styles: array expected");
            message.styles = [];
            for (let i = 0; i < object.styles.length; ++i) {
                if (typeof object.styles[i] !== "object")
                    throw TypeError(".CellStyleArray.styles: object expected");
                message.styles[i] = $root.CellStyle.fromObject(object.styles[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a CellStyleArray message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CellStyleArray
     * @static
     * @param {CellStyleArray} message CellStyleArray
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CellStyleArray.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.styles = [];
        if (message.styles && message.styles.length) {
            object.styles = [];
            for (let j = 0; j < message.styles.length; ++j)
                object.styles[j] = $root.CellStyle.toObject(message.styles[j], options);
        }
        return object;
    };

    /**
     * Converts this CellStyleArray to JSON.
     * @function toJSON
     * @memberof CellStyleArray
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CellStyleArray.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CellStyleArray
     * @function getTypeUrl
     * @memberof CellStyleArray
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CellStyleArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CellStyleArray";
    };

    return CellStyleArray;
})();

export const AnyArray = $root.AnyArray = (() => {

    /**
     * Properties of an AnyArray.
     * @exports IAnyArray
     * @interface IAnyArray
     * @property {IStringArray|null} [strings] AnyArray strings
     * @property {IDoubleArray|null} [doubles] AnyArray doubles
     * @property {IInt64Array|null} [int64s] AnyArray int64s
     * @property {IStringArray|null} [datetimes] AnyArray datetimes
     * @property {IInt64Array|null} [timedeltas] AnyArray timedeltas
     */

    /**
     * Constructs a new AnyArray.
     * @exports AnyArray
     * @classdesc Represents an AnyArray.
     * @implements IAnyArray
     * @constructor
     * @param {IAnyArray=} [properties] Properties to set
     */
    function AnyArray(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AnyArray strings.
     * @member {IStringArray|null|undefined} strings
     * @memberof AnyArray
     * @instance
     */
    AnyArray.prototype.strings = null;

    /**
     * AnyArray doubles.
     * @member {IDoubleArray|null|undefined} doubles
     * @memberof AnyArray
     * @instance
     */
    AnyArray.prototype.doubles = null;

    /**
     * AnyArray int64s.
     * @member {IInt64Array|null|undefined} int64s
     * @memberof AnyArray
     * @instance
     */
    AnyArray.prototype.int64s = null;

    /**
     * AnyArray datetimes.
     * @member {IStringArray|null|undefined} datetimes
     * @memberof AnyArray
     * @instance
     */
    AnyArray.prototype.datetimes = null;

    /**
     * AnyArray timedeltas.
     * @member {IInt64Array|null|undefined} timedeltas
     * @memberof AnyArray
     * @instance
     */
    AnyArray.prototype.timedeltas = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * AnyArray type.
     * @member {"strings"|"doubles"|"int64s"|"datetimes"|"timedeltas"|undefined} type
     * @memberof AnyArray
     * @instance
     */
    Object.defineProperty(AnyArray.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["strings", "doubles", "int64s", "datetimes", "timedeltas"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new AnyArray instance using the specified properties.
     * @function create
     * @memberof AnyArray
     * @static
     * @param {IAnyArray=} [properties] Properties to set
     * @returns {AnyArray} AnyArray instance
     */
    AnyArray.create = function create(properties) {
        return new AnyArray(properties);
    };

    /**
     * Encodes the specified AnyArray message. Does not implicitly {@link AnyArray.verify|verify} messages.
     * @function encode
     * @memberof AnyArray
     * @static
     * @param {IAnyArray} message AnyArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AnyArray.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.strings != null && Object.hasOwnProperty.call(message, "strings"))
            $root.StringArray.encode(message.strings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.doubles != null && Object.hasOwnProperty.call(message, "doubles"))
            $root.DoubleArray.encode(message.doubles, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.int64s != null && Object.hasOwnProperty.call(message, "int64s"))
            $root.Int64Array.encode(message.int64s, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.datetimes != null && Object.hasOwnProperty.call(message, "datetimes"))
            $root.StringArray.encode(message.datetimes, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.timedeltas != null && Object.hasOwnProperty.call(message, "timedeltas"))
            $root.Int64Array.encode(message.timedeltas, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AnyArray message, length delimited. Does not implicitly {@link AnyArray.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AnyArray
     * @static
     * @param {IAnyArray} message AnyArray message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AnyArray.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AnyArray message from the specified reader or buffer.
     * @function decode
     * @memberof AnyArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AnyArray} AnyArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AnyArray.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AnyArray();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.strings = $root.StringArray.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.doubles = $root.DoubleArray.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.int64s = $root.Int64Array.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.datetimes = $root.StringArray.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.timedeltas = $root.Int64Array.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AnyArray message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AnyArray
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AnyArray} AnyArray
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AnyArray.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AnyArray message.
     * @function verify
     * @memberof AnyArray
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AnyArray.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.strings != null && message.hasOwnProperty("strings")) {
            properties.type = 1;
            {
                let error = $root.StringArray.verify(message.strings);
                if (error)
                    return "strings." + error;
            }
        }
        if (message.doubles != null && message.hasOwnProperty("doubles")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DoubleArray.verify(message.doubles);
                if (error)
                    return "doubles." + error;
            }
        }
        if (message.int64s != null && message.hasOwnProperty("int64s")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Int64Array.verify(message.int64s);
                if (error)
                    return "int64s." + error;
            }
        }
        if (message.datetimes != null && message.hasOwnProperty("datetimes")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.StringArray.verify(message.datetimes);
                if (error)
                    return "datetimes." + error;
            }
        }
        if (message.timedeltas != null && message.hasOwnProperty("timedeltas")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Int64Array.verify(message.timedeltas);
                if (error)
                    return "timedeltas." + error;
            }
        }
        return null;
    };

    /**
     * Creates an AnyArray message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AnyArray
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AnyArray} AnyArray
     */
    AnyArray.fromObject = function fromObject(object) {
        if (object instanceof $root.AnyArray)
            return object;
        let message = new $root.AnyArray();
        if (object.strings != null) {
            if (typeof object.strings !== "object")
                throw TypeError(".AnyArray.strings: object expected");
            message.strings = $root.StringArray.fromObject(object.strings);
        }
        if (object.doubles != null) {
            if (typeof object.doubles !== "object")
                throw TypeError(".AnyArray.doubles: object expected");
            message.doubles = $root.DoubleArray.fromObject(object.doubles);
        }
        if (object.int64s != null) {
            if (typeof object.int64s !== "object")
                throw TypeError(".AnyArray.int64s: object expected");
            message.int64s = $root.Int64Array.fromObject(object.int64s);
        }
        if (object.datetimes != null) {
            if (typeof object.datetimes !== "object")
                throw TypeError(".AnyArray.datetimes: object expected");
            message.datetimes = $root.StringArray.fromObject(object.datetimes);
        }
        if (object.timedeltas != null) {
            if (typeof object.timedeltas !== "object")
                throw TypeError(".AnyArray.timedeltas: object expected");
            message.timedeltas = $root.Int64Array.fromObject(object.timedeltas);
        }
        return message;
    };

    /**
     * Creates a plain object from an AnyArray message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AnyArray
     * @static
     * @param {AnyArray} message AnyArray
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AnyArray.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.strings != null && message.hasOwnProperty("strings")) {
            object.strings = $root.StringArray.toObject(message.strings, options);
            if (options.oneofs)
                object.type = "strings";
        }
        if (message.doubles != null && message.hasOwnProperty("doubles")) {
            object.doubles = $root.DoubleArray.toObject(message.doubles, options);
            if (options.oneofs)
                object.type = "doubles";
        }
        if (message.int64s != null && message.hasOwnProperty("int64s")) {
            object.int64s = $root.Int64Array.toObject(message.int64s, options);
            if (options.oneofs)
                object.type = "int64s";
        }
        if (message.datetimes != null && message.hasOwnProperty("datetimes")) {
            object.datetimes = $root.StringArray.toObject(message.datetimes, options);
            if (options.oneofs)
                object.type = "datetimes";
        }
        if (message.timedeltas != null && message.hasOwnProperty("timedeltas")) {
            object.timedeltas = $root.Int64Array.toObject(message.timedeltas, options);
            if (options.oneofs)
                object.type = "timedeltas";
        }
        return object;
    };

    /**
     * Converts this AnyArray to JSON.
     * @function toJSON
     * @memberof AnyArray
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AnyArray.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AnyArray
     * @function getTypeUrl
     * @memberof AnyArray
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AnyArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AnyArray";
    };

    return AnyArray;
})();

export const Table = $root.Table = (() => {

    /**
     * Properties of a Table.
     * @exports ITable
     * @interface ITable
     * @property {Array.<IAnyArray>|null} [cols] Table cols
     */

    /**
     * Constructs a new Table.
     * @exports Table
     * @classdesc Represents a Table.
     * @implements ITable
     * @constructor
     * @param {ITable=} [properties] Properties to set
     */
    function Table(properties) {
        this.cols = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Table cols.
     * @member {Array.<IAnyArray>} cols
     * @memberof Table
     * @instance
     */
    Table.prototype.cols = $util.emptyArray;

    /**
     * Creates a new Table instance using the specified properties.
     * @function create
     * @memberof Table
     * @static
     * @param {ITable=} [properties] Properties to set
     * @returns {Table} Table instance
     */
    Table.create = function create(properties) {
        return new Table(properties);
    };

    /**
     * Encodes the specified Table message. Does not implicitly {@link Table.verify|verify} messages.
     * @function encode
     * @memberof Table
     * @static
     * @param {ITable} message Table message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Table.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.cols != null && message.cols.length)
            for (let i = 0; i < message.cols.length; ++i)
                $root.AnyArray.encode(message.cols[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Table message, length delimited. Does not implicitly {@link Table.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Table
     * @static
     * @param {ITable} message Table message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Table.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Table message from the specified reader or buffer.
     * @function decode
     * @memberof Table
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Table} Table
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Table.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Table();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.cols && message.cols.length))
                        message.cols = [];
                    message.cols.push($root.AnyArray.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Table message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Table
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Table} Table
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Table.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Table message.
     * @function verify
     * @memberof Table
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Table.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.cols != null && message.hasOwnProperty("cols")) {
            if (!Array.isArray(message.cols))
                return "cols: array expected";
            for (let i = 0; i < message.cols.length; ++i) {
                let error = $root.AnyArray.verify(message.cols[i]);
                if (error)
                    return "cols." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Table message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Table
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Table} Table
     */
    Table.fromObject = function fromObject(object) {
        if (object instanceof $root.Table)
            return object;
        let message = new $root.Table();
        if (object.cols) {
            if (!Array.isArray(object.cols))
                throw TypeError(".Table.cols: array expected");
            message.cols = [];
            for (let i = 0; i < object.cols.length; ++i) {
                if (typeof object.cols[i] !== "object")
                    throw TypeError(".Table.cols: object expected");
                message.cols[i] = $root.AnyArray.fromObject(object.cols[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Table message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Table
     * @static
     * @param {Table} message Table
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Table.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.cols = [];
        if (message.cols && message.cols.length) {
            object.cols = [];
            for (let j = 0; j < message.cols.length; ++j)
                object.cols[j] = $root.AnyArray.toObject(message.cols[j], options);
        }
        return object;
    };

    /**
     * Converts this Table to JSON.
     * @function toJSON
     * @memberof Table
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Table.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Table
     * @function getTypeUrl
     * @memberof Table
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Table.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Table";
    };

    return Table;
})();

export const TableStyle = $root.TableStyle = (() => {

    /**
     * Properties of a TableStyle.
     * @exports ITableStyle
     * @interface ITableStyle
     * @property {Array.<ICellStyleArray>|null} [cols] TableStyle cols
     */

    /**
     * Constructs a new TableStyle.
     * @exports TableStyle
     * @classdesc Represents a TableStyle.
     * @implements ITableStyle
     * @constructor
     * @param {ITableStyle=} [properties] Properties to set
     */
    function TableStyle(properties) {
        this.cols = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TableStyle cols.
     * @member {Array.<ICellStyleArray>} cols
     * @memberof TableStyle
     * @instance
     */
    TableStyle.prototype.cols = $util.emptyArray;

    /**
     * Creates a new TableStyle instance using the specified properties.
     * @function create
     * @memberof TableStyle
     * @static
     * @param {ITableStyle=} [properties] Properties to set
     * @returns {TableStyle} TableStyle instance
     */
    TableStyle.create = function create(properties) {
        return new TableStyle(properties);
    };

    /**
     * Encodes the specified TableStyle message. Does not implicitly {@link TableStyle.verify|verify} messages.
     * @function encode
     * @memberof TableStyle
     * @static
     * @param {ITableStyle} message TableStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TableStyle.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.cols != null && message.cols.length)
            for (let i = 0; i < message.cols.length; ++i)
                $root.CellStyleArray.encode(message.cols[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TableStyle message, length delimited. Does not implicitly {@link TableStyle.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TableStyle
     * @static
     * @param {ITableStyle} message TableStyle message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TableStyle.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TableStyle message from the specified reader or buffer.
     * @function decode
     * @memberof TableStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TableStyle} TableStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TableStyle.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TableStyle();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.cols && message.cols.length))
                        message.cols = [];
                    message.cols.push($root.CellStyleArray.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TableStyle message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TableStyle
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TableStyle} TableStyle
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TableStyle.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TableStyle message.
     * @function verify
     * @memberof TableStyle
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TableStyle.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.cols != null && message.hasOwnProperty("cols")) {
            if (!Array.isArray(message.cols))
                return "cols: array expected";
            for (let i = 0; i < message.cols.length; ++i) {
                let error = $root.CellStyleArray.verify(message.cols[i]);
                if (error)
                    return "cols." + error;
            }
        }
        return null;
    };

    /**
     * Creates a TableStyle message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TableStyle
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TableStyle} TableStyle
     */
    TableStyle.fromObject = function fromObject(object) {
        if (object instanceof $root.TableStyle)
            return object;
        let message = new $root.TableStyle();
        if (object.cols) {
            if (!Array.isArray(object.cols))
                throw TypeError(".TableStyle.cols: array expected");
            message.cols = [];
            for (let i = 0; i < object.cols.length; ++i) {
                if (typeof object.cols[i] !== "object")
                    throw TypeError(".TableStyle.cols: object expected");
                message.cols[i] = $root.CellStyleArray.fromObject(object.cols[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a TableStyle message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TableStyle
     * @static
     * @param {TableStyle} message TableStyle
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TableStyle.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.cols = [];
        if (message.cols && message.cols.length) {
            object.cols = [];
            for (let j = 0; j < message.cols.length; ++j)
                object.cols[j] = $root.CellStyleArray.toObject(message.cols[j], options);
        }
        return object;
    };

    /**
     * Converts this TableStyle to JSON.
     * @function toJSON
     * @memberof TableStyle
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TableStyle.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TableStyle
     * @function getTypeUrl
     * @memberof TableStyle
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TableStyle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TableStyle";
    };

    return TableStyle;
})();

export const DateInput = $root.DateInput = (() => {

    /**
     * Properties of a DateInput.
     * @exports IDateInput
     * @interface IDateInput
     * @property {string|null} [id] DateInput id
     * @property {string|null} [label] DateInput label
     * @property {Array.<string>|null} ["default"] DateInput default
     * @property {string|null} [min] DateInput min
     * @property {string|null} [max] DateInput max
     * @property {boolean|null} [isRange] DateInput isRange
     * @property {string|null} [help] DateInput help
     * @property {string|null} [formId] DateInput formId
     * @property {Array.<string>|null} [value] DateInput value
     * @property {boolean|null} [setValue] DateInput setValue
     * @property {boolean|null} [disabled] DateInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] DateInput labelVisibility
     * @property {string|null} [format] DateInput format
     */

    /**
     * Constructs a new DateInput.
     * @exports DateInput
     * @classdesc Represents a DateInput.
     * @implements IDateInput
     * @constructor
     * @param {IDateInput=} [properties] Properties to set
     */
    function DateInput(properties) {
        this["default"] = [];
        this.value = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DateInput id.
     * @member {string} id
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.id = "";

    /**
     * DateInput label.
     * @member {string} label
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.label = "";

    /**
     * DateInput default.
     * @member {Array.<string>} default
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype["default"] = $util.emptyArray;

    /**
     * DateInput min.
     * @member {string} min
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.min = "";

    /**
     * DateInput max.
     * @member {string} max
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.max = "";

    /**
     * DateInput isRange.
     * @member {boolean} isRange
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.isRange = false;

    /**
     * DateInput help.
     * @member {string} help
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.help = "";

    /**
     * DateInput formId.
     * @member {string} formId
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.formId = "";

    /**
     * DateInput value.
     * @member {Array.<string>} value
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.value = $util.emptyArray;

    /**
     * DateInput setValue.
     * @member {boolean} setValue
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.setValue = false;

    /**
     * DateInput disabled.
     * @member {boolean} disabled
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.disabled = false;

    /**
     * DateInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.labelVisibility = null;

    /**
     * DateInput format.
     * @member {string} format
     * @memberof DateInput
     * @instance
     */
    DateInput.prototype.format = "";

    /**
     * Creates a new DateInput instance using the specified properties.
     * @function create
     * @memberof DateInput
     * @static
     * @param {IDateInput=} [properties] Properties to set
     * @returns {DateInput} DateInput instance
     */
    DateInput.create = function create(properties) {
        return new DateInput(properties);
    };

    /**
     * Encodes the specified DateInput message. Does not implicitly {@link DateInput.verify|verify} messages.
     * @function encode
     * @memberof DateInput
     * @static
     * @param {IDateInput} message DateInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DateInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && message["default"].length)
            for (let i = 0; i < message["default"].length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message["default"][i]);
        if (message.min != null && Object.hasOwnProperty.call(message, "min"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.min);
        if (message.max != null && Object.hasOwnProperty.call(message, "max"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.max);
        if (message.isRange != null && Object.hasOwnProperty.call(message, "isRange"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isRange);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.formId);
        if (message.value != null && message.value.length)
            for (let i = 0; i < message.value.length; ++i)
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.value[i]);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.format);
        return writer;
    };

    /**
     * Encodes the specified DateInput message, length delimited. Does not implicitly {@link DateInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DateInput
     * @static
     * @param {IDateInput} message DateInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DateInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DateInput message from the specified reader or buffer.
     * @function decode
     * @memberof DateInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DateInput} DateInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DateInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DateInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    if (!(message["default"] && message["default"].length))
                        message["default"] = [];
                    message["default"].push(reader.string());
                    break;
                }
            case 4: {
                    message.min = reader.string();
                    break;
                }
            case 5: {
                    message.max = reader.string();
                    break;
                }
            case 6: {
                    message.isRange = reader.bool();
                    break;
                }
            case 7: {
                    message.help = reader.string();
                    break;
                }
            case 8: {
                    message.formId = reader.string();
                    break;
                }
            case 9: {
                    if (!(message.value && message.value.length))
                        message.value = [];
                    message.value.push(reader.string());
                    break;
                }
            case 10: {
                    message.setValue = reader.bool();
                    break;
                }
            case 11: {
                    message.disabled = reader.bool();
                    break;
                }
            case 12: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.format = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DateInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DateInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DateInput} DateInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DateInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DateInput message.
     * @function verify
     * @memberof DateInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DateInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            if (!Array.isArray(message["default"]))
                return "default: array expected";
            for (let i = 0; i < message["default"].length; ++i)
                if (!$util.isString(message["default"][i]))
                    return "default: string[] expected";
        }
        if (message.min != null && message.hasOwnProperty("min"))
            if (!$util.isString(message.min))
                return "min: string expected";
        if (message.max != null && message.hasOwnProperty("max"))
            if (!$util.isString(message.max))
                return "max: string expected";
        if (message.isRange != null && message.hasOwnProperty("isRange"))
            if (typeof message.isRange !== "boolean")
                return "isRange: boolean expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            if (!Array.isArray(message.value))
                return "value: array expected";
            for (let i = 0; i < message.value.length; ++i)
                if (!$util.isString(message.value[i]))
                    return "value: string[] expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.format != null && message.hasOwnProperty("format"))
            if (!$util.isString(message.format))
                return "format: string expected";
        return null;
    };

    /**
     * Creates a DateInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DateInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DateInput} DateInput
     */
    DateInput.fromObject = function fromObject(object) {
        if (object instanceof $root.DateInput)
            return object;
        let message = new $root.DateInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"]) {
            if (!Array.isArray(object["default"]))
                throw TypeError(".DateInput.default: array expected");
            message["default"] = [];
            for (let i = 0; i < object["default"].length; ++i)
                message["default"][i] = String(object["default"][i]);
        }
        if (object.min != null)
            message.min = String(object.min);
        if (object.max != null)
            message.max = String(object.max);
        if (object.isRange != null)
            message.isRange = Boolean(object.isRange);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value) {
            if (!Array.isArray(object.value))
                throw TypeError(".DateInput.value: array expected");
            message.value = [];
            for (let i = 0; i < object.value.length; ++i)
                message.value[i] = String(object.value[i]);
        }
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".DateInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.format != null)
            message.format = String(object.format);
        return message;
    };

    /**
     * Creates a plain object from a DateInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DateInput
     * @static
     * @param {DateInput} message DateInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DateInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object["default"] = [];
            object.value = [];
        }
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.min = "";
            object.max = "";
            object.isRange = false;
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            object.format = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] && message["default"].length) {
            object["default"] = [];
            for (let j = 0; j < message["default"].length; ++j)
                object["default"][j] = message["default"][j];
        }
        if (message.min != null && message.hasOwnProperty("min"))
            object.min = message.min;
        if (message.max != null && message.hasOwnProperty("max"))
            object.max = message.max;
        if (message.isRange != null && message.hasOwnProperty("isRange"))
            object.isRange = message.isRange;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value && message.value.length) {
            object.value = [];
            for (let j = 0; j < message.value.length; ++j)
                object.value[j] = message.value[j];
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.format != null && message.hasOwnProperty("format"))
            object.format = message.format;
        return object;
    };

    /**
     * Converts this DateInput to JSON.
     * @function toJSON
     * @memberof DateInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DateInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DateInput
     * @function getTypeUrl
     * @memberof DateInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DateInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DateInput";
    };

    return DateInput;
})();

export const DeckGlJsonChart = $root.DeckGlJsonChart = (() => {

    /**
     * Properties of a DeckGlJsonChart.
     * @exports IDeckGlJsonChart
     * @interface IDeckGlJsonChart
     * @property {string|null} [json] DeckGlJsonChart json
     * @property {string|null} [tooltip] DeckGlJsonChart tooltip
     * @property {boolean|null} [useContainerWidth] DeckGlJsonChart useContainerWidth
     * @property {string|null} [id] DeckGlJsonChart id
     * @property {string|null} [mapboxToken] DeckGlJsonChart mapboxToken
     * @property {number|null} [width] DeckGlJsonChart width
     * @property {number|null} [height] DeckGlJsonChart height
     * @property {Array.<DeckGlJsonChart.SelectionMode>|null} [selectionMode] DeckGlJsonChart selectionMode
     * @property {string|null} [formId] DeckGlJsonChart formId
     */

    /**
     * Constructs a new DeckGlJsonChart.
     * @exports DeckGlJsonChart
     * @classdesc Represents a DeckGlJsonChart.
     * @implements IDeckGlJsonChart
     * @constructor
     * @param {IDeckGlJsonChart=} [properties] Properties to set
     */
    function DeckGlJsonChart(properties) {
        this.selectionMode = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DeckGlJsonChart json.
     * @member {string} json
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.json = "";

    /**
     * DeckGlJsonChart tooltip.
     * @member {string} tooltip
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.tooltip = "";

    /**
     * DeckGlJsonChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.useContainerWidth = false;

    /**
     * DeckGlJsonChart id.
     * @member {string} id
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.id = "";

    /**
     * DeckGlJsonChart mapboxToken.
     * @member {string} mapboxToken
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.mapboxToken = "";

    /**
     * DeckGlJsonChart width.
     * @member {number} width
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.width = 0;

    /**
     * DeckGlJsonChart height.
     * @member {number} height
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.height = 0;

    /**
     * DeckGlJsonChart selectionMode.
     * @member {Array.<DeckGlJsonChart.SelectionMode>} selectionMode
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.selectionMode = $util.emptyArray;

    /**
     * DeckGlJsonChart formId.
     * @member {string} formId
     * @memberof DeckGlJsonChart
     * @instance
     */
    DeckGlJsonChart.prototype.formId = "";

    /**
     * Creates a new DeckGlJsonChart instance using the specified properties.
     * @function create
     * @memberof DeckGlJsonChart
     * @static
     * @param {IDeckGlJsonChart=} [properties] Properties to set
     * @returns {DeckGlJsonChart} DeckGlJsonChart instance
     */
    DeckGlJsonChart.create = function create(properties) {
        return new DeckGlJsonChart(properties);
    };

    /**
     * Encodes the specified DeckGlJsonChart message. Does not implicitly {@link DeckGlJsonChart.verify|verify} messages.
     * @function encode
     * @memberof DeckGlJsonChart
     * @static
     * @param {IDeckGlJsonChart} message DeckGlJsonChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeckGlJsonChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.json != null && Object.hasOwnProperty.call(message, "json"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.json);
        if (message.tooltip != null && Object.hasOwnProperty.call(message, "tooltip"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.tooltip);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.useContainerWidth);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
        if (message.mapboxToken != null && Object.hasOwnProperty.call(message, "mapboxToken"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.mapboxToken);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.height);
        if (message.selectionMode != null && message.selectionMode.length) {
            writer.uint32(/* id 9, wireType 2 =*/74).fork();
            for (let i = 0; i < message.selectionMode.length; ++i)
                writer.int32(message.selectionMode[i]);
            writer.ldelim();
        }
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.formId);
        return writer;
    };

    /**
     * Encodes the specified DeckGlJsonChart message, length delimited. Does not implicitly {@link DeckGlJsonChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DeckGlJsonChart
     * @static
     * @param {IDeckGlJsonChart} message DeckGlJsonChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeckGlJsonChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeckGlJsonChart message from the specified reader or buffer.
     * @function decode
     * @memberof DeckGlJsonChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DeckGlJsonChart} DeckGlJsonChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeckGlJsonChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeckGlJsonChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.json = reader.string();
                    break;
                }
            case 2: {
                    message.tooltip = reader.string();
                    break;
                }
            case 4: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 5: {
                    message.id = reader.string();
                    break;
                }
            case 6: {
                    message.mapboxToken = reader.string();
                    break;
                }
            case 7: {
                    message.width = reader.uint32();
                    break;
                }
            case 8: {
                    message.height = reader.uint32();
                    break;
                }
            case 9: {
                    if (!(message.selectionMode && message.selectionMode.length))
                        message.selectionMode = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.selectionMode.push(reader.int32());
                    } else
                        message.selectionMode.push(reader.int32());
                    break;
                }
            case 10: {
                    message.formId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DeckGlJsonChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DeckGlJsonChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DeckGlJsonChart} DeckGlJsonChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeckGlJsonChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeckGlJsonChart message.
     * @function verify
     * @memberof DeckGlJsonChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeckGlJsonChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.json != null && message.hasOwnProperty("json"))
            if (!$util.isString(message.json))
                return "json: string expected";
        if (message.tooltip != null && message.hasOwnProperty("tooltip"))
            if (!$util.isString(message.tooltip))
                return "tooltip: string expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.mapboxToken != null && message.hasOwnProperty("mapboxToken"))
            if (!$util.isString(message.mapboxToken))
                return "mapboxToken: string expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width))
                return "width: integer expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        if (message.selectionMode != null && message.hasOwnProperty("selectionMode")) {
            if (!Array.isArray(message.selectionMode))
                return "selectionMode: array expected";
            for (let i = 0; i < message.selectionMode.length; ++i)
                switch (message.selectionMode[i]) {
                default:
                    return "selectionMode: enum value[] expected";
                case 0:
                case 1:
                    break;
                }
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        return null;
    };

    /**
     * Creates a DeckGlJsonChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DeckGlJsonChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DeckGlJsonChart} DeckGlJsonChart
     */
    DeckGlJsonChart.fromObject = function fromObject(object) {
        if (object instanceof $root.DeckGlJsonChart)
            return object;
        let message = new $root.DeckGlJsonChart();
        if (object.json != null)
            message.json = String(object.json);
        if (object.tooltip != null)
            message.tooltip = String(object.tooltip);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.id != null)
            message.id = String(object.id);
        if (object.mapboxToken != null)
            message.mapboxToken = String(object.mapboxToken);
        if (object.width != null)
            message.width = object.width >>> 0;
        if (object.height != null)
            message.height = object.height >>> 0;
        if (object.selectionMode) {
            if (!Array.isArray(object.selectionMode))
                throw TypeError(".DeckGlJsonChart.selectionMode: array expected");
            message.selectionMode = [];
            for (let i = 0; i < object.selectionMode.length; ++i)
                switch (object.selectionMode[i]) {
                default:
                    if (typeof object.selectionMode[i] === "number") {
                        message.selectionMode[i] = object.selectionMode[i];
                        break;
                    }
                case "SINGLE_OBJECT":
                case 0:
                    message.selectionMode[i] = 0;
                    break;
                case "MULTI_OBJECT":
                case 1:
                    message.selectionMode[i] = 1;
                    break;
                }
        }
        if (object.formId != null)
            message.formId = String(object.formId);
        return message;
    };

    /**
     * Creates a plain object from a DeckGlJsonChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DeckGlJsonChart
     * @static
     * @param {DeckGlJsonChart} message DeckGlJsonChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeckGlJsonChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.selectionMode = [];
        if (options.defaults) {
            object.json = "";
            object.tooltip = "";
            object.useContainerWidth = false;
            object.id = "";
            object.mapboxToken = "";
            object.width = 0;
            object.height = 0;
            object.formId = "";
        }
        if (message.json != null && message.hasOwnProperty("json"))
            object.json = message.json;
        if (message.tooltip != null && message.hasOwnProperty("tooltip"))
            object.tooltip = message.tooltip;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.mapboxToken != null && message.hasOwnProperty("mapboxToken"))
            object.mapboxToken = message.mapboxToken;
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.selectionMode && message.selectionMode.length) {
            object.selectionMode = [];
            for (let j = 0; j < message.selectionMode.length; ++j)
                object.selectionMode[j] = options.enums === String ? $root.DeckGlJsonChart.SelectionMode[message.selectionMode[j]] === undefined ? message.selectionMode[j] : $root.DeckGlJsonChart.SelectionMode[message.selectionMode[j]] : message.selectionMode[j];
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        return object;
    };

    /**
     * Converts this DeckGlJsonChart to JSON.
     * @function toJSON
     * @memberof DeckGlJsonChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeckGlJsonChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeckGlJsonChart
     * @function getTypeUrl
     * @memberof DeckGlJsonChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeckGlJsonChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeckGlJsonChart";
    };

    /**
     * SelectionMode enum.
     * @name DeckGlJsonChart.SelectionMode
     * @enum {number}
     * @property {number} SINGLE_OBJECT=0 SINGLE_OBJECT value
     * @property {number} MULTI_OBJECT=1 MULTI_OBJECT value
     */
    DeckGlJsonChart.SelectionMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SINGLE_OBJECT"] = 0;
        values[valuesById[1] = "MULTI_OBJECT"] = 1;
        return values;
    })();

    return DeckGlJsonChart;
})();

export const Delta = $root.Delta = (() => {

    /**
     * Properties of a Delta.
     * @exports IDelta
     * @interface IDelta
     * @property {IElement|null} [newElement] Delta newElement
     * @property {IBlock|null} [addBlock] Delta addBlock
     * @property {INamedDataSet|null} [addRows] Delta addRows
     * @property {IArrowNamedDataSet|null} [arrowAddRows] Delta arrowAddRows
     * @property {string|null} [fragmentId] Delta fragmentId
     */

    /**
     * Constructs a new Delta.
     * @exports Delta
     * @classdesc Represents a Delta.
     * @implements IDelta
     * @constructor
     * @param {IDelta=} [properties] Properties to set
     */
    function Delta(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Delta newElement.
     * @member {IElement|null|undefined} newElement
     * @memberof Delta
     * @instance
     */
    Delta.prototype.newElement = null;

    /**
     * Delta addBlock.
     * @member {IBlock|null|undefined} addBlock
     * @memberof Delta
     * @instance
     */
    Delta.prototype.addBlock = null;

    /**
     * Delta addRows.
     * @member {INamedDataSet|null|undefined} addRows
     * @memberof Delta
     * @instance
     */
    Delta.prototype.addRows = null;

    /**
     * Delta arrowAddRows.
     * @member {IArrowNamedDataSet|null|undefined} arrowAddRows
     * @memberof Delta
     * @instance
     */
    Delta.prototype.arrowAddRows = null;

    /**
     * Delta fragmentId.
     * @member {string} fragmentId
     * @memberof Delta
     * @instance
     */
    Delta.prototype.fragmentId = "";

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Delta type.
     * @member {"newElement"|"addBlock"|"addRows"|"arrowAddRows"|undefined} type
     * @memberof Delta
     * @instance
     */
    Object.defineProperty(Delta.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["newElement", "addBlock", "addRows", "arrowAddRows"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Delta instance using the specified properties.
     * @function create
     * @memberof Delta
     * @static
     * @param {IDelta=} [properties] Properties to set
     * @returns {Delta} Delta instance
     */
    Delta.create = function create(properties) {
        return new Delta(properties);
    };

    /**
     * Encodes the specified Delta message. Does not implicitly {@link Delta.verify|verify} messages.
     * @function encode
     * @memberof Delta
     * @static
     * @param {IDelta} message Delta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Delta.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.newElement != null && Object.hasOwnProperty.call(message, "newElement"))
            $root.Element.encode(message.newElement, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.addRows != null && Object.hasOwnProperty.call(message, "addRows"))
            $root.NamedDataSet.encode(message.addRows, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.addBlock != null && Object.hasOwnProperty.call(message, "addBlock"))
            $root.Block.encode(message.addBlock, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.arrowAddRows != null && Object.hasOwnProperty.call(message, "arrowAddRows"))
            $root.ArrowNamedDataSet.encode(message.arrowAddRows, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.fragmentId != null && Object.hasOwnProperty.call(message, "fragmentId"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.fragmentId);
        return writer;
    };

    /**
     * Encodes the specified Delta message, length delimited. Does not implicitly {@link Delta.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Delta
     * @static
     * @param {IDelta} message Delta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Delta.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Delta message from the specified reader or buffer.
     * @function decode
     * @memberof Delta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Delta} Delta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Delta.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Delta();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.newElement = $root.Element.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.addBlock = $root.Block.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.addRows = $root.NamedDataSet.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.arrowAddRows = $root.ArrowNamedDataSet.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.fragmentId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Delta message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Delta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Delta} Delta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Delta.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Delta message.
     * @function verify
     * @memberof Delta
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Delta.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.newElement != null && message.hasOwnProperty("newElement")) {
            properties.type = 1;
            {
                let error = $root.Element.verify(message.newElement);
                if (error)
                    return "newElement." + error;
            }
        }
        if (message.addBlock != null && message.hasOwnProperty("addBlock")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Block.verify(message.addBlock);
                if (error)
                    return "addBlock." + error;
            }
        }
        if (message.addRows != null && message.hasOwnProperty("addRows")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.NamedDataSet.verify(message.addRows);
                if (error)
                    return "addRows." + error;
            }
        }
        if (message.arrowAddRows != null && message.hasOwnProperty("arrowAddRows")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ArrowNamedDataSet.verify(message.arrowAddRows);
                if (error)
                    return "arrowAddRows." + error;
            }
        }
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            if (!$util.isString(message.fragmentId))
                return "fragmentId: string expected";
        return null;
    };

    /**
     * Creates a Delta message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Delta
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Delta} Delta
     */
    Delta.fromObject = function fromObject(object) {
        if (object instanceof $root.Delta)
            return object;
        let message = new $root.Delta();
        if (object.newElement != null) {
            if (typeof object.newElement !== "object")
                throw TypeError(".Delta.newElement: object expected");
            message.newElement = $root.Element.fromObject(object.newElement);
        }
        if (object.addBlock != null) {
            if (typeof object.addBlock !== "object")
                throw TypeError(".Delta.addBlock: object expected");
            message.addBlock = $root.Block.fromObject(object.addBlock);
        }
        if (object.addRows != null) {
            if (typeof object.addRows !== "object")
                throw TypeError(".Delta.addRows: object expected");
            message.addRows = $root.NamedDataSet.fromObject(object.addRows);
        }
        if (object.arrowAddRows != null) {
            if (typeof object.arrowAddRows !== "object")
                throw TypeError(".Delta.arrowAddRows: object expected");
            message.arrowAddRows = $root.ArrowNamedDataSet.fromObject(object.arrowAddRows);
        }
        if (object.fragmentId != null)
            message.fragmentId = String(object.fragmentId);
        return message;
    };

    /**
     * Creates a plain object from a Delta message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Delta
     * @static
     * @param {Delta} message Delta
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Delta.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.fragmentId = "";
        if (message.newElement != null && message.hasOwnProperty("newElement")) {
            object.newElement = $root.Element.toObject(message.newElement, options);
            if (options.oneofs)
                object.type = "newElement";
        }
        if (message.addRows != null && message.hasOwnProperty("addRows")) {
            object.addRows = $root.NamedDataSet.toObject(message.addRows, options);
            if (options.oneofs)
                object.type = "addRows";
        }
        if (message.addBlock != null && message.hasOwnProperty("addBlock")) {
            object.addBlock = $root.Block.toObject(message.addBlock, options);
            if (options.oneofs)
                object.type = "addBlock";
        }
        if (message.arrowAddRows != null && message.hasOwnProperty("arrowAddRows")) {
            object.arrowAddRows = $root.ArrowNamedDataSet.toObject(message.arrowAddRows, options);
            if (options.oneofs)
                object.type = "arrowAddRows";
        }
        if (message.fragmentId != null && message.hasOwnProperty("fragmentId"))
            object.fragmentId = message.fragmentId;
        return object;
    };

    /**
     * Converts this Delta to JSON.
     * @function toJSON
     * @memberof Delta
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Delta.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Delta
     * @function getTypeUrl
     * @memberof Delta
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Delta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Delta";
    };

    return Delta;
})();

export const Element = $root.Element = (() => {

    /**
     * Properties of an Element.
     * @exports IElement
     * @interface IElement
     * @property {IAlert|null} [alert] Element alert
     * @property {IArrow|null} [arrowDataFrame] Element arrowDataFrame
     * @property {IArrow|null} [arrowTable] Element arrowTable
     * @property {IArrowVegaLiteChart|null} [arrowVegaLiteChart] Element arrowVegaLiteChart
     * @property {IAudio|null} [audio] Element audio
     * @property {IAudioInput|null} [audioInput] Element audioInput
     * @property {IBalloons|null} [balloons] Element balloons
     * @property {IBokehChart|null} [bokehChart] Element bokehChart
     * @property {IButton|null} [button] Element button
     * @property {IButtonGroup|null} [buttonGroup] Element buttonGroup
     * @property {IDownloadButton|null} [downloadButton] Element downloadButton
     * @property {ICameraInput|null} [cameraInput] Element cameraInput
     * @property {IChatInput|null} [chatInput] Element chatInput
     * @property {ICheckbox|null} [checkbox] Element checkbox
     * @property {IColorPicker|null} [colorPicker] Element colorPicker
     * @property {IComponentInstance|null} [componentInstance] Element componentInstance
     * @property {IDataFrame|null} [dataFrame] Element dataFrame
     * @property {IDataFrame|null} [table] Element table
     * @property {IDateInput|null} [dateInput] Element dateInput
     * @property {IDeckGlJsonChart|null} [deckGlJsonChart] Element deckGlJsonChart
     * @property {IDocString|null} [docString] Element docString
     * @property {IEmpty|null} [empty] Element empty
     * @property {IException|null} [exception] Element exception
     * @property {IFavicon|null} [favicon] Element favicon
     * @property {IFileUploader|null} [fileUploader] Element fileUploader
     * @property {IGraphVizChart|null} [graphvizChart] Element graphvizChart
     * @property {IHtml|null} [html] Element html
     * @property {IIFrame|null} [iframe] Element iframe
     * @property {IImageList|null} [imgs] Element imgs
     * @property {IJson|null} [json] Element json
     * @property {ILinkButton|null} [linkButton] Element linkButton
     * @property {IMarkdown|null} [markdown] Element markdown
     * @property {IMetric|null} [metric] Element metric
     * @property {IMultiSelect|null} [multiselect] Element multiselect
     * @property {INumberInput|null} [numberInput] Element numberInput
     * @property {IPageLink|null} [pageLink] Element pageLink
     * @property {IPlotlyChart|null} [plotlyChart] Element plotlyChart
     * @property {IProgress|null} [progress] Element progress
     * @property {IRadio|null} [radio] Element radio
     * @property {ISelectbox|null} [selectbox] Element selectbox
     * @property {ISkeleton|null} [skeleton] Element skeleton
     * @property {ISlider|null} [slider] Element slider
     * @property {ISnow|null} [snow] Element snow
     * @property {ISpinner|null} [spinner] Element spinner
     * @property {IText|null} [text] Element text
     * @property {ITextArea|null} [textArea] Element textArea
     * @property {ITextInput|null} [textInput] Element textInput
     * @property {ITimeInput|null} [timeInput] Element timeInput
     * @property {IToast|null} [toast] Element toast
     * @property {IVegaLiteChart|null} [vegaLiteChart] Element vegaLiteChart
     * @property {IVideo|null} [video] Element video
     * @property {IHeading|null} [heading] Element heading
     * @property {ICode|null} [code] Element code
     */

    /**
     * Constructs a new Element.
     * @exports Element
     * @classdesc Represents an Element.
     * @implements IElement
     * @constructor
     * @param {IElement=} [properties] Properties to set
     */
    function Element(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Element alert.
     * @member {IAlert|null|undefined} alert
     * @memberof Element
     * @instance
     */
    Element.prototype.alert = null;

    /**
     * Element arrowDataFrame.
     * @member {IArrow|null|undefined} arrowDataFrame
     * @memberof Element
     * @instance
     */
    Element.prototype.arrowDataFrame = null;

    /**
     * Element arrowTable.
     * @member {IArrow|null|undefined} arrowTable
     * @memberof Element
     * @instance
     */
    Element.prototype.arrowTable = null;

    /**
     * Element arrowVegaLiteChart.
     * @member {IArrowVegaLiteChart|null|undefined} arrowVegaLiteChart
     * @memberof Element
     * @instance
     */
    Element.prototype.arrowVegaLiteChart = null;

    /**
     * Element audio.
     * @member {IAudio|null|undefined} audio
     * @memberof Element
     * @instance
     */
    Element.prototype.audio = null;

    /**
     * Element audioInput.
     * @member {IAudioInput|null|undefined} audioInput
     * @memberof Element
     * @instance
     */
    Element.prototype.audioInput = null;

    /**
     * Element balloons.
     * @member {IBalloons|null|undefined} balloons
     * @memberof Element
     * @instance
     */
    Element.prototype.balloons = null;

    /**
     * Element bokehChart.
     * @member {IBokehChart|null|undefined} bokehChart
     * @memberof Element
     * @instance
     */
    Element.prototype.bokehChart = null;

    /**
     * Element button.
     * @member {IButton|null|undefined} button
     * @memberof Element
     * @instance
     */
    Element.prototype.button = null;

    /**
     * Element buttonGroup.
     * @member {IButtonGroup|null|undefined} buttonGroup
     * @memberof Element
     * @instance
     */
    Element.prototype.buttonGroup = null;

    /**
     * Element downloadButton.
     * @member {IDownloadButton|null|undefined} downloadButton
     * @memberof Element
     * @instance
     */
    Element.prototype.downloadButton = null;

    /**
     * Element cameraInput.
     * @member {ICameraInput|null|undefined} cameraInput
     * @memberof Element
     * @instance
     */
    Element.prototype.cameraInput = null;

    /**
     * Element chatInput.
     * @member {IChatInput|null|undefined} chatInput
     * @memberof Element
     * @instance
     */
    Element.prototype.chatInput = null;

    /**
     * Element checkbox.
     * @member {ICheckbox|null|undefined} checkbox
     * @memberof Element
     * @instance
     */
    Element.prototype.checkbox = null;

    /**
     * Element colorPicker.
     * @member {IColorPicker|null|undefined} colorPicker
     * @memberof Element
     * @instance
     */
    Element.prototype.colorPicker = null;

    /**
     * Element componentInstance.
     * @member {IComponentInstance|null|undefined} componentInstance
     * @memberof Element
     * @instance
     */
    Element.prototype.componentInstance = null;

    /**
     * Element dataFrame.
     * @member {IDataFrame|null|undefined} dataFrame
     * @memberof Element
     * @instance
     */
    Element.prototype.dataFrame = null;

    /**
     * Element table.
     * @member {IDataFrame|null|undefined} table
     * @memberof Element
     * @instance
     */
    Element.prototype.table = null;

    /**
     * Element dateInput.
     * @member {IDateInput|null|undefined} dateInput
     * @memberof Element
     * @instance
     */
    Element.prototype.dateInput = null;

    /**
     * Element deckGlJsonChart.
     * @member {IDeckGlJsonChart|null|undefined} deckGlJsonChart
     * @memberof Element
     * @instance
     */
    Element.prototype.deckGlJsonChart = null;

    /**
     * Element docString.
     * @member {IDocString|null|undefined} docString
     * @memberof Element
     * @instance
     */
    Element.prototype.docString = null;

    /**
     * Element empty.
     * @member {IEmpty|null|undefined} empty
     * @memberof Element
     * @instance
     */
    Element.prototype.empty = null;

    /**
     * Element exception.
     * @member {IException|null|undefined} exception
     * @memberof Element
     * @instance
     */
    Element.prototype.exception = null;

    /**
     * Element favicon.
     * @member {IFavicon|null|undefined} favicon
     * @memberof Element
     * @instance
     */
    Element.prototype.favicon = null;

    /**
     * Element fileUploader.
     * @member {IFileUploader|null|undefined} fileUploader
     * @memberof Element
     * @instance
     */
    Element.prototype.fileUploader = null;

    /**
     * Element graphvizChart.
     * @member {IGraphVizChart|null|undefined} graphvizChart
     * @memberof Element
     * @instance
     */
    Element.prototype.graphvizChart = null;

    /**
     * Element html.
     * @member {IHtml|null|undefined} html
     * @memberof Element
     * @instance
     */
    Element.prototype.html = null;

    /**
     * Element iframe.
     * @member {IIFrame|null|undefined} iframe
     * @memberof Element
     * @instance
     */
    Element.prototype.iframe = null;

    /**
     * Element imgs.
     * @member {IImageList|null|undefined} imgs
     * @memberof Element
     * @instance
     */
    Element.prototype.imgs = null;

    /**
     * Element json.
     * @member {IJson|null|undefined} json
     * @memberof Element
     * @instance
     */
    Element.prototype.json = null;

    /**
     * Element linkButton.
     * @member {ILinkButton|null|undefined} linkButton
     * @memberof Element
     * @instance
     */
    Element.prototype.linkButton = null;

    /**
     * Element markdown.
     * @member {IMarkdown|null|undefined} markdown
     * @memberof Element
     * @instance
     */
    Element.prototype.markdown = null;

    /**
     * Element metric.
     * @member {IMetric|null|undefined} metric
     * @memberof Element
     * @instance
     */
    Element.prototype.metric = null;

    /**
     * Element multiselect.
     * @member {IMultiSelect|null|undefined} multiselect
     * @memberof Element
     * @instance
     */
    Element.prototype.multiselect = null;

    /**
     * Element numberInput.
     * @member {INumberInput|null|undefined} numberInput
     * @memberof Element
     * @instance
     */
    Element.prototype.numberInput = null;

    /**
     * Element pageLink.
     * @member {IPageLink|null|undefined} pageLink
     * @memberof Element
     * @instance
     */
    Element.prototype.pageLink = null;

    /**
     * Element plotlyChart.
     * @member {IPlotlyChart|null|undefined} plotlyChart
     * @memberof Element
     * @instance
     */
    Element.prototype.plotlyChart = null;

    /**
     * Element progress.
     * @member {IProgress|null|undefined} progress
     * @memberof Element
     * @instance
     */
    Element.prototype.progress = null;

    /**
     * Element radio.
     * @member {IRadio|null|undefined} radio
     * @memberof Element
     * @instance
     */
    Element.prototype.radio = null;

    /**
     * Element selectbox.
     * @member {ISelectbox|null|undefined} selectbox
     * @memberof Element
     * @instance
     */
    Element.prototype.selectbox = null;

    /**
     * Element skeleton.
     * @member {ISkeleton|null|undefined} skeleton
     * @memberof Element
     * @instance
     */
    Element.prototype.skeleton = null;

    /**
     * Element slider.
     * @member {ISlider|null|undefined} slider
     * @memberof Element
     * @instance
     */
    Element.prototype.slider = null;

    /**
     * Element snow.
     * @member {ISnow|null|undefined} snow
     * @memberof Element
     * @instance
     */
    Element.prototype.snow = null;

    /**
     * Element spinner.
     * @member {ISpinner|null|undefined} spinner
     * @memberof Element
     * @instance
     */
    Element.prototype.spinner = null;

    /**
     * Element text.
     * @member {IText|null|undefined} text
     * @memberof Element
     * @instance
     */
    Element.prototype.text = null;

    /**
     * Element textArea.
     * @member {ITextArea|null|undefined} textArea
     * @memberof Element
     * @instance
     */
    Element.prototype.textArea = null;

    /**
     * Element textInput.
     * @member {ITextInput|null|undefined} textInput
     * @memberof Element
     * @instance
     */
    Element.prototype.textInput = null;

    /**
     * Element timeInput.
     * @member {ITimeInput|null|undefined} timeInput
     * @memberof Element
     * @instance
     */
    Element.prototype.timeInput = null;

    /**
     * Element toast.
     * @member {IToast|null|undefined} toast
     * @memberof Element
     * @instance
     */
    Element.prototype.toast = null;

    /**
     * Element vegaLiteChart.
     * @member {IVegaLiteChart|null|undefined} vegaLiteChart
     * @memberof Element
     * @instance
     */
    Element.prototype.vegaLiteChart = null;

    /**
     * Element video.
     * @member {IVideo|null|undefined} video
     * @memberof Element
     * @instance
     */
    Element.prototype.video = null;

    /**
     * Element heading.
     * @member {IHeading|null|undefined} heading
     * @memberof Element
     * @instance
     */
    Element.prototype.heading = null;

    /**
     * Element code.
     * @member {ICode|null|undefined} code
     * @memberof Element
     * @instance
     */
    Element.prototype.code = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Element type.
     * @member {"alert"|"arrowDataFrame"|"arrowTable"|"arrowVegaLiteChart"|"audio"|"audioInput"|"balloons"|"bokehChart"|"button"|"buttonGroup"|"downloadButton"|"cameraInput"|"chatInput"|"checkbox"|"colorPicker"|"componentInstance"|"dataFrame"|"table"|"dateInput"|"deckGlJsonChart"|"docString"|"empty"|"exception"|"favicon"|"fileUploader"|"graphvizChart"|"html"|"iframe"|"imgs"|"json"|"linkButton"|"markdown"|"metric"|"multiselect"|"numberInput"|"pageLink"|"plotlyChart"|"progress"|"radio"|"selectbox"|"skeleton"|"slider"|"snow"|"spinner"|"text"|"textArea"|"textInput"|"timeInput"|"toast"|"vegaLiteChart"|"video"|"heading"|"code"|undefined} type
     * @memberof Element
     * @instance
     */
    Object.defineProperty(Element.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["alert", "arrowDataFrame", "arrowTable", "arrowVegaLiteChart", "audio", "audioInput", "balloons", "bokehChart", "button", "buttonGroup", "downloadButton", "cameraInput", "chatInput", "checkbox", "colorPicker", "componentInstance", "dataFrame", "table", "dateInput", "deckGlJsonChart", "docString", "empty", "exception", "favicon", "fileUploader", "graphvizChart", "html", "iframe", "imgs", "json", "linkButton", "markdown", "metric", "multiselect", "numberInput", "pageLink", "plotlyChart", "progress", "radio", "selectbox", "skeleton", "slider", "snow", "spinner", "text", "textArea", "textInput", "timeInput", "toast", "vegaLiteChart", "video", "heading", "code"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Element instance using the specified properties.
     * @function create
     * @memberof Element
     * @static
     * @param {IElement=} [properties] Properties to set
     * @returns {Element} Element instance
     */
    Element.create = function create(properties) {
        return new Element(properties);
    };

    /**
     * Encodes the specified Element message. Does not implicitly {@link Element.verify|verify} messages.
     * @function encode
     * @memberof Element
     * @static
     * @param {IElement} message Element message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Element.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            $root.Text.encode(message.text, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.empty != null && Object.hasOwnProperty.call(message, "empty"))
            $root.Empty.encode(message.empty, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.dataFrame != null && Object.hasOwnProperty.call(message, "dataFrame"))
            $root.DataFrame.encode(message.dataFrame, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
            $root.Progress.encode(message.progress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.imgs != null && Object.hasOwnProperty.call(message, "imgs"))
            $root.ImageList.encode(message.imgs, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.docString != null && Object.hasOwnProperty.call(message, "docString"))
            $root.DocString.encode(message.docString, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.exception != null && Object.hasOwnProperty.call(message, "exception"))
            $root.Exception.encode(message.exception, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.vegaLiteChart != null && Object.hasOwnProperty.call(message, "vegaLiteChart"))
            $root.VegaLiteChart.encode(message.vegaLiteChart, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.table != null && Object.hasOwnProperty.call(message, "table"))
            $root.DataFrame.encode(message.table, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.balloons != null && Object.hasOwnProperty.call(message, "balloons"))
            $root.Balloons.encode(message.balloons, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
            $root.Audio.encode(message.audio, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.video != null && Object.hasOwnProperty.call(message, "video"))
            $root.Video.encode(message.video, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.plotlyChart != null && Object.hasOwnProperty.call(message, "plotlyChart"))
            $root.PlotlyChart.encode(message.plotlyChart, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.bokehChart != null && Object.hasOwnProperty.call(message, "bokehChart"))
            $root.BokehChart.encode(message.bokehChart, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.graphvizChart != null && Object.hasOwnProperty.call(message, "graphvizChart"))
            $root.GraphVizChart.encode(message.graphvizChart, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.button != null && Object.hasOwnProperty.call(message, "button"))
            $root.Button.encode(message.button, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.checkbox != null && Object.hasOwnProperty.call(message, "checkbox"))
            $root.Checkbox.encode(message.checkbox, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.slider != null && Object.hasOwnProperty.call(message, "slider"))
            $root.Slider.encode(message.slider, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.textArea != null && Object.hasOwnProperty.call(message, "textArea"))
            $root.TextArea.encode(message.textArea, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.radio != null && Object.hasOwnProperty.call(message, "radio"))
            $root.Radio.encode(message.radio, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        if (message.textInput != null && Object.hasOwnProperty.call(message, "textInput"))
            $root.TextInput.encode(message.textInput, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.selectbox != null && Object.hasOwnProperty.call(message, "selectbox"))
            $root.Selectbox.encode(message.selectbox, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        if (message.timeInput != null && Object.hasOwnProperty.call(message, "timeInput"))
            $root.TimeInput.encode(message.timeInput, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
        if (message.dateInput != null && Object.hasOwnProperty.call(message, "dateInput"))
            $root.DateInput.encode(message.dateInput, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.multiselect != null && Object.hasOwnProperty.call(message, "multiselect"))
            $root.MultiSelect.encode(message.multiselect, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
        if (message.markdown != null && Object.hasOwnProperty.call(message, "markdown"))
            $root.Markdown.encode(message.markdown, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
        if (message.alert != null && Object.hasOwnProperty.call(message, "alert"))
            $root.Alert.encode(message.alert, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.json != null && Object.hasOwnProperty.call(message, "json"))
            $root.Json.encode(message.json, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.numberInput != null && Object.hasOwnProperty.call(message, "numberInput"))
            $root.NumberInput.encode(message.numberInput, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.fileUploader != null && Object.hasOwnProperty.call(message, "fileUploader"))
            $root.FileUploader.encode(message.fileUploader, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
        if (message.deckGlJsonChart != null && Object.hasOwnProperty.call(message, "deckGlJsonChart"))
            $root.DeckGlJsonChart.encode(message.deckGlJsonChart, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
        if (message.colorPicker != null && Object.hasOwnProperty.call(message, "colorPicker"))
            $root.ColorPicker.encode(message.colorPicker, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
        if (message.favicon != null && Object.hasOwnProperty.call(message, "favicon"))
            $root.Favicon.encode(message.favicon, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.componentInstance != null && Object.hasOwnProperty.call(message, "componentInstance"))
            $root.ComponentInstance.encode(message.componentInstance, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
        if (message.iframe != null && Object.hasOwnProperty.call(message, "iframe"))
            $root.IFrame.encode(message.iframe, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
        if (message.arrowTable != null && Object.hasOwnProperty.call(message, "arrowTable"))
            $root.Arrow.encode(message.arrowTable, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
        if (message.arrowDataFrame != null && Object.hasOwnProperty.call(message, "arrowDataFrame"))
            $root.Arrow.encode(message.arrowDataFrame, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        if (message.arrowVegaLiteChart != null && Object.hasOwnProperty.call(message, "arrowVegaLiteChart"))
            $root.ArrowVegaLiteChart.encode(message.arrowVegaLiteChart, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
        if (message.metric != null && Object.hasOwnProperty.call(message, "metric"))
            $root.Metric.encode(message.metric, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
        if (message.downloadButton != null && Object.hasOwnProperty.call(message, "downloadButton"))
            $root.DownloadButton.encode(message.downloadButton, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
        if (message.spinner != null && Object.hasOwnProperty.call(message, "spinner"))
            $root.Spinner.encode(message.spinner, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
        if (message.cameraInput != null && Object.hasOwnProperty.call(message, "cameraInput"))
            $root.CameraInput.encode(message.cameraInput, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
        if (message.snow != null && Object.hasOwnProperty.call(message, "snow"))
            $root.Snow.encode(message.snow, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
        if (message.heading != null && Object.hasOwnProperty.call(message, "heading"))
            $root.Heading.encode(message.heading, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            $root.Code.encode(message.code, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
        if (message.chatInput != null && Object.hasOwnProperty.call(message, "chatInput"))
            $root.ChatInput.encode(message.chatInput, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
        if (message.toast != null && Object.hasOwnProperty.call(message, "toast"))
            $root.Toast.encode(message.toast, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.linkButton != null && Object.hasOwnProperty.call(message, "linkButton"))
            $root.LinkButton.encode(message.linkButton, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
        if (message.skeleton != null && Object.hasOwnProperty.call(message, "skeleton"))
            $root.Skeleton.encode(message.skeleton, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
        if (message.pageLink != null && Object.hasOwnProperty.call(message, "pageLink"))
            $root.PageLink.encode(message.pageLink, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
        if (message.html != null && Object.hasOwnProperty.call(message, "html"))
            $root.Html.encode(message.html, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
        if (message.buttonGroup != null && Object.hasOwnProperty.call(message, "buttonGroup"))
            $root.ButtonGroup.encode(message.buttonGroup, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
        if (message.audioInput != null && Object.hasOwnProperty.call(message, "audioInput"))
            $root.AudioInput.encode(message.audioInput, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Element message, length delimited. Does not implicitly {@link Element.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Element
     * @static
     * @param {IElement} message Element message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Element.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Element message from the specified reader or buffer.
     * @function decode
     * @memberof Element
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Element} Element
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Element.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Element();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 30: {
                    message.alert = $root.Alert.decode(reader, reader.uint32());
                    break;
                }
            case 40: {
                    message.arrowDataFrame = $root.Arrow.decode(reader, reader.uint32());
                    break;
                }
            case 39: {
                    message.arrowTable = $root.Arrow.decode(reader, reader.uint32());
                    break;
                }
            case 41: {
                    message.arrowVegaLiteChart = $root.ArrowVegaLiteChart.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.audio = $root.Audio.decode(reader, reader.uint32());
                    break;
                }
            case 56: {
                    message.audioInput = $root.AudioInput.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.balloons = $root.Balloons.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.bokehChart = $root.BokehChart.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    message.button = $root.Button.decode(reader, reader.uint32());
                    break;
                }
            case 55: {
                    message.buttonGroup = $root.ButtonGroup.decode(reader, reader.uint32());
                    break;
                }
            case 43: {
                    message.downloadButton = $root.DownloadButton.decode(reader, reader.uint32());
                    break;
                }
            case 45: {
                    message.cameraInput = $root.CameraInput.decode(reader, reader.uint32());
                    break;
                }
            case 49: {
                    message.chatInput = $root.ChatInput.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.checkbox = $root.Checkbox.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    message.colorPicker = $root.ColorPicker.decode(reader, reader.uint32());
                    break;
                }
            case 37: {
                    message.componentInstance = $root.ComponentInstance.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.dataFrame = $root.DataFrame.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.table = $root.DataFrame.decode(reader, reader.uint32());
                    break;
                }
            case 27: {
                    message.dateInput = $root.DateInput.decode(reader, reader.uint32());
                    break;
                }
            case 34: {
                    message.deckGlJsonChart = $root.DeckGlJsonChart.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.docString = $root.DocString.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.empty = $root.Empty.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.exception = $root.Exception.decode(reader, reader.uint32());
                    break;
                }
            case 36: {
                    message.favicon = $root.Favicon.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.fileUploader = $root.FileUploader.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.graphvizChart = $root.GraphVizChart.decode(reader, reader.uint32());
                    break;
                }
            case 54: {
                    message.html = $root.Html.decode(reader, reader.uint32());
                    break;
                }
            case 38: {
                    message.iframe = $root.IFrame.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.imgs = $root.ImageList.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.json = $root.Json.decode(reader, reader.uint32());
                    break;
                }
            case 51: {
                    message.linkButton = $root.LinkButton.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.markdown = $root.Markdown.decode(reader, reader.uint32());
                    break;
                }
            case 42: {
                    message.metric = $root.Metric.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    message.multiselect = $root.MultiSelect.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.numberInput = $root.NumberInput.decode(reader, reader.uint32());
                    break;
                }
            case 53: {
                    message.pageLink = $root.PageLink.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.plotlyChart = $root.PlotlyChart.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.progress = $root.Progress.decode(reader, reader.uint32());
                    break;
                }
            case 23: {
                    message.radio = $root.Radio.decode(reader, reader.uint32());
                    break;
                }
            case 25: {
                    message.selectbox = $root.Selectbox.decode(reader, reader.uint32());
                    break;
                }
            case 52: {
                    message.skeleton = $root.Skeleton.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.slider = $root.Slider.decode(reader, reader.uint32());
                    break;
                }
            case 46: {
                    message.snow = $root.Snow.decode(reader, reader.uint32());
                    break;
                }
            case 44: {
                    message.spinner = $root.Spinner.decode(reader, reader.uint32());
                    break;
                }
            case 1: {
                    message.text = $root.Text.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.textArea = $root.TextArea.decode(reader, reader.uint32());
                    break;
                }
            case 24: {
                    message.textInput = $root.TextInput.decode(reader, reader.uint32());
                    break;
                }
            case 26: {
                    message.timeInput = $root.TimeInput.decode(reader, reader.uint32());
                    break;
                }
            case 50: {
                    message.toast = $root.Toast.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.vegaLiteChart = $root.VegaLiteChart.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.video = $root.Video.decode(reader, reader.uint32());
                    break;
                }
            case 47: {
                    message.heading = $root.Heading.decode(reader, reader.uint32());
                    break;
                }
            case 48: {
                    message.code = $root.Code.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Element message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Element
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Element} Element
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Element.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Element message.
     * @function verify
     * @memberof Element
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Element.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.alert != null && message.hasOwnProperty("alert")) {
            properties.type = 1;
            {
                let error = $root.Alert.verify(message.alert);
                if (error)
                    return "alert." + error;
            }
        }
        if (message.arrowDataFrame != null && message.hasOwnProperty("arrowDataFrame")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Arrow.verify(message.arrowDataFrame);
                if (error)
                    return "arrowDataFrame." + error;
            }
        }
        if (message.arrowTable != null && message.hasOwnProperty("arrowTable")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Arrow.verify(message.arrowTable);
                if (error)
                    return "arrowTable." + error;
            }
        }
        if (message.arrowVegaLiteChart != null && message.hasOwnProperty("arrowVegaLiteChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ArrowVegaLiteChart.verify(message.arrowVegaLiteChart);
                if (error)
                    return "arrowVegaLiteChart." + error;
            }
        }
        if (message.audio != null && message.hasOwnProperty("audio")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Audio.verify(message.audio);
                if (error)
                    return "audio." + error;
            }
        }
        if (message.audioInput != null && message.hasOwnProperty("audioInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.AudioInput.verify(message.audioInput);
                if (error)
                    return "audioInput." + error;
            }
        }
        if (message.balloons != null && message.hasOwnProperty("balloons")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Balloons.verify(message.balloons);
                if (error)
                    return "balloons." + error;
            }
        }
        if (message.bokehChart != null && message.hasOwnProperty("bokehChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.BokehChart.verify(message.bokehChart);
                if (error)
                    return "bokehChart." + error;
            }
        }
        if (message.button != null && message.hasOwnProperty("button")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Button.verify(message.button);
                if (error)
                    return "button." + error;
            }
        }
        if (message.buttonGroup != null && message.hasOwnProperty("buttonGroup")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ButtonGroup.verify(message.buttonGroup);
                if (error)
                    return "buttonGroup." + error;
            }
        }
        if (message.downloadButton != null && message.hasOwnProperty("downloadButton")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DownloadButton.verify(message.downloadButton);
                if (error)
                    return "downloadButton." + error;
            }
        }
        if (message.cameraInput != null && message.hasOwnProperty("cameraInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.CameraInput.verify(message.cameraInput);
                if (error)
                    return "cameraInput." + error;
            }
        }
        if (message.chatInput != null && message.hasOwnProperty("chatInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ChatInput.verify(message.chatInput);
                if (error)
                    return "chatInput." + error;
            }
        }
        if (message.checkbox != null && message.hasOwnProperty("checkbox")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Checkbox.verify(message.checkbox);
                if (error)
                    return "checkbox." + error;
            }
        }
        if (message.colorPicker != null && message.hasOwnProperty("colorPicker")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ColorPicker.verify(message.colorPicker);
                if (error)
                    return "colorPicker." + error;
            }
        }
        if (message.componentInstance != null && message.hasOwnProperty("componentInstance")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ComponentInstance.verify(message.componentInstance);
                if (error)
                    return "componentInstance." + error;
            }
        }
        if (message.dataFrame != null && message.hasOwnProperty("dataFrame")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DataFrame.verify(message.dataFrame);
                if (error)
                    return "dataFrame." + error;
            }
        }
        if (message.table != null && message.hasOwnProperty("table")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DataFrame.verify(message.table);
                if (error)
                    return "table." + error;
            }
        }
        if (message.dateInput != null && message.hasOwnProperty("dateInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DateInput.verify(message.dateInput);
                if (error)
                    return "dateInput." + error;
            }
        }
        if (message.deckGlJsonChart != null && message.hasOwnProperty("deckGlJsonChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DeckGlJsonChart.verify(message.deckGlJsonChart);
                if (error)
                    return "deckGlJsonChart." + error;
            }
        }
        if (message.docString != null && message.hasOwnProperty("docString")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.DocString.verify(message.docString);
                if (error)
                    return "docString." + error;
            }
        }
        if (message.empty != null && message.hasOwnProperty("empty")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Empty.verify(message.empty);
                if (error)
                    return "empty." + error;
            }
        }
        if (message.exception != null && message.hasOwnProperty("exception")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Exception.verify(message.exception);
                if (error)
                    return "exception." + error;
            }
        }
        if (message.favicon != null && message.hasOwnProperty("favicon")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Favicon.verify(message.favicon);
                if (error)
                    return "favicon." + error;
            }
        }
        if (message.fileUploader != null && message.hasOwnProperty("fileUploader")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.FileUploader.verify(message.fileUploader);
                if (error)
                    return "fileUploader." + error;
            }
        }
        if (message.graphvizChart != null && message.hasOwnProperty("graphvizChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.GraphVizChart.verify(message.graphvizChart);
                if (error)
                    return "graphvizChart." + error;
            }
        }
        if (message.html != null && message.hasOwnProperty("html")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Html.verify(message.html);
                if (error)
                    return "html." + error;
            }
        }
        if (message.iframe != null && message.hasOwnProperty("iframe")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.IFrame.verify(message.iframe);
                if (error)
                    return "iframe." + error;
            }
        }
        if (message.imgs != null && message.hasOwnProperty("imgs")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ImageList.verify(message.imgs);
                if (error)
                    return "imgs." + error;
            }
        }
        if (message.json != null && message.hasOwnProperty("json")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Json.verify(message.json);
                if (error)
                    return "json." + error;
            }
        }
        if (message.linkButton != null && message.hasOwnProperty("linkButton")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.LinkButton.verify(message.linkButton);
                if (error)
                    return "linkButton." + error;
            }
        }
        if (message.markdown != null && message.hasOwnProperty("markdown")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Markdown.verify(message.markdown);
                if (error)
                    return "markdown." + error;
            }
        }
        if (message.metric != null && message.hasOwnProperty("metric")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Metric.verify(message.metric);
                if (error)
                    return "metric." + error;
            }
        }
        if (message.multiselect != null && message.hasOwnProperty("multiselect")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.MultiSelect.verify(message.multiselect);
                if (error)
                    return "multiselect." + error;
            }
        }
        if (message.numberInput != null && message.hasOwnProperty("numberInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.NumberInput.verify(message.numberInput);
                if (error)
                    return "numberInput." + error;
            }
        }
        if (message.pageLink != null && message.hasOwnProperty("pageLink")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PageLink.verify(message.pageLink);
                if (error)
                    return "pageLink." + error;
            }
        }
        if (message.plotlyChart != null && message.hasOwnProperty("plotlyChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PlotlyChart.verify(message.plotlyChart);
                if (error)
                    return "plotlyChart." + error;
            }
        }
        if (message.progress != null && message.hasOwnProperty("progress")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Progress.verify(message.progress);
                if (error)
                    return "progress." + error;
            }
        }
        if (message.radio != null && message.hasOwnProperty("radio")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Radio.verify(message.radio);
                if (error)
                    return "radio." + error;
            }
        }
        if (message.selectbox != null && message.hasOwnProperty("selectbox")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Selectbox.verify(message.selectbox);
                if (error)
                    return "selectbox." + error;
            }
        }
        if (message.skeleton != null && message.hasOwnProperty("skeleton")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Skeleton.verify(message.skeleton);
                if (error)
                    return "skeleton." + error;
            }
        }
        if (message.slider != null && message.hasOwnProperty("slider")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Slider.verify(message.slider);
                if (error)
                    return "slider." + error;
            }
        }
        if (message.snow != null && message.hasOwnProperty("snow")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Snow.verify(message.snow);
                if (error)
                    return "snow." + error;
            }
        }
        if (message.spinner != null && message.hasOwnProperty("spinner")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Spinner.verify(message.spinner);
                if (error)
                    return "spinner." + error;
            }
        }
        if (message.text != null && message.hasOwnProperty("text")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Text.verify(message.text);
                if (error)
                    return "text." + error;
            }
        }
        if (message.textArea != null && message.hasOwnProperty("textArea")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.TextArea.verify(message.textArea);
                if (error)
                    return "textArea." + error;
            }
        }
        if (message.textInput != null && message.hasOwnProperty("textInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.TextInput.verify(message.textInput);
                if (error)
                    return "textInput." + error;
            }
        }
        if (message.timeInput != null && message.hasOwnProperty("timeInput")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.TimeInput.verify(message.timeInput);
                if (error)
                    return "timeInput." + error;
            }
        }
        if (message.toast != null && message.hasOwnProperty("toast")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Toast.verify(message.toast);
                if (error)
                    return "toast." + error;
            }
        }
        if (message.vegaLiteChart != null && message.hasOwnProperty("vegaLiteChart")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.VegaLiteChart.verify(message.vegaLiteChart);
                if (error)
                    return "vegaLiteChart." + error;
            }
        }
        if (message.video != null && message.hasOwnProperty("video")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Video.verify(message.video);
                if (error)
                    return "video." + error;
            }
        }
        if (message.heading != null && message.hasOwnProperty("heading")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Heading.verify(message.heading);
                if (error)
                    return "heading." + error;
            }
        }
        if (message.code != null && message.hasOwnProperty("code")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Code.verify(message.code);
                if (error)
                    return "code." + error;
            }
        }
        return null;
    };

    /**
     * Creates an Element message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Element
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Element} Element
     */
    Element.fromObject = function fromObject(object) {
        if (object instanceof $root.Element)
            return object;
        let message = new $root.Element();
        if (object.alert != null) {
            if (typeof object.alert !== "object")
                throw TypeError(".Element.alert: object expected");
            message.alert = $root.Alert.fromObject(object.alert);
        }
        if (object.arrowDataFrame != null) {
            if (typeof object.arrowDataFrame !== "object")
                throw TypeError(".Element.arrowDataFrame: object expected");
            message.arrowDataFrame = $root.Arrow.fromObject(object.arrowDataFrame);
        }
        if (object.arrowTable != null) {
            if (typeof object.arrowTable !== "object")
                throw TypeError(".Element.arrowTable: object expected");
            message.arrowTable = $root.Arrow.fromObject(object.arrowTable);
        }
        if (object.arrowVegaLiteChart != null) {
            if (typeof object.arrowVegaLiteChart !== "object")
                throw TypeError(".Element.arrowVegaLiteChart: object expected");
            message.arrowVegaLiteChart = $root.ArrowVegaLiteChart.fromObject(object.arrowVegaLiteChart);
        }
        if (object.audio != null) {
            if (typeof object.audio !== "object")
                throw TypeError(".Element.audio: object expected");
            message.audio = $root.Audio.fromObject(object.audio);
        }
        if (object.audioInput != null) {
            if (typeof object.audioInput !== "object")
                throw TypeError(".Element.audioInput: object expected");
            message.audioInput = $root.AudioInput.fromObject(object.audioInput);
        }
        if (object.balloons != null) {
            if (typeof object.balloons !== "object")
                throw TypeError(".Element.balloons: object expected");
            message.balloons = $root.Balloons.fromObject(object.balloons);
        }
        if (object.bokehChart != null) {
            if (typeof object.bokehChart !== "object")
                throw TypeError(".Element.bokehChart: object expected");
            message.bokehChart = $root.BokehChart.fromObject(object.bokehChart);
        }
        if (object.button != null) {
            if (typeof object.button !== "object")
                throw TypeError(".Element.button: object expected");
            message.button = $root.Button.fromObject(object.button);
        }
        if (object.buttonGroup != null) {
            if (typeof object.buttonGroup !== "object")
                throw TypeError(".Element.buttonGroup: object expected");
            message.buttonGroup = $root.ButtonGroup.fromObject(object.buttonGroup);
        }
        if (object.downloadButton != null) {
            if (typeof object.downloadButton !== "object")
                throw TypeError(".Element.downloadButton: object expected");
            message.downloadButton = $root.DownloadButton.fromObject(object.downloadButton);
        }
        if (object.cameraInput != null) {
            if (typeof object.cameraInput !== "object")
                throw TypeError(".Element.cameraInput: object expected");
            message.cameraInput = $root.CameraInput.fromObject(object.cameraInput);
        }
        if (object.chatInput != null) {
            if (typeof object.chatInput !== "object")
                throw TypeError(".Element.chatInput: object expected");
            message.chatInput = $root.ChatInput.fromObject(object.chatInput);
        }
        if (object.checkbox != null) {
            if (typeof object.checkbox !== "object")
                throw TypeError(".Element.checkbox: object expected");
            message.checkbox = $root.Checkbox.fromObject(object.checkbox);
        }
        if (object.colorPicker != null) {
            if (typeof object.colorPicker !== "object")
                throw TypeError(".Element.colorPicker: object expected");
            message.colorPicker = $root.ColorPicker.fromObject(object.colorPicker);
        }
        if (object.componentInstance != null) {
            if (typeof object.componentInstance !== "object")
                throw TypeError(".Element.componentInstance: object expected");
            message.componentInstance = $root.ComponentInstance.fromObject(object.componentInstance);
        }
        if (object.dataFrame != null) {
            if (typeof object.dataFrame !== "object")
                throw TypeError(".Element.dataFrame: object expected");
            message.dataFrame = $root.DataFrame.fromObject(object.dataFrame);
        }
        if (object.table != null) {
            if (typeof object.table !== "object")
                throw TypeError(".Element.table: object expected");
            message.table = $root.DataFrame.fromObject(object.table);
        }
        if (object.dateInput != null) {
            if (typeof object.dateInput !== "object")
                throw TypeError(".Element.dateInput: object expected");
            message.dateInput = $root.DateInput.fromObject(object.dateInput);
        }
        if (object.deckGlJsonChart != null) {
            if (typeof object.deckGlJsonChart !== "object")
                throw TypeError(".Element.deckGlJsonChart: object expected");
            message.deckGlJsonChart = $root.DeckGlJsonChart.fromObject(object.deckGlJsonChart);
        }
        if (object.docString != null) {
            if (typeof object.docString !== "object")
                throw TypeError(".Element.docString: object expected");
            message.docString = $root.DocString.fromObject(object.docString);
        }
        if (object.empty != null) {
            if (typeof object.empty !== "object")
                throw TypeError(".Element.empty: object expected");
            message.empty = $root.Empty.fromObject(object.empty);
        }
        if (object.exception != null) {
            if (typeof object.exception !== "object")
                throw TypeError(".Element.exception: object expected");
            message.exception = $root.Exception.fromObject(object.exception);
        }
        if (object.favicon != null) {
            if (typeof object.favicon !== "object")
                throw TypeError(".Element.favicon: object expected");
            message.favicon = $root.Favicon.fromObject(object.favicon);
        }
        if (object.fileUploader != null) {
            if (typeof object.fileUploader !== "object")
                throw TypeError(".Element.fileUploader: object expected");
            message.fileUploader = $root.FileUploader.fromObject(object.fileUploader);
        }
        if (object.graphvizChart != null) {
            if (typeof object.graphvizChart !== "object")
                throw TypeError(".Element.graphvizChart: object expected");
            message.graphvizChart = $root.GraphVizChart.fromObject(object.graphvizChart);
        }
        if (object.html != null) {
            if (typeof object.html !== "object")
                throw TypeError(".Element.html: object expected");
            message.html = $root.Html.fromObject(object.html);
        }
        if (object.iframe != null) {
            if (typeof object.iframe !== "object")
                throw TypeError(".Element.iframe: object expected");
            message.iframe = $root.IFrame.fromObject(object.iframe);
        }
        if (object.imgs != null) {
            if (typeof object.imgs !== "object")
                throw TypeError(".Element.imgs: object expected");
            message.imgs = $root.ImageList.fromObject(object.imgs);
        }
        if (object.json != null) {
            if (typeof object.json !== "object")
                throw TypeError(".Element.json: object expected");
            message.json = $root.Json.fromObject(object.json);
        }
        if (object.linkButton != null) {
            if (typeof object.linkButton !== "object")
                throw TypeError(".Element.linkButton: object expected");
            message.linkButton = $root.LinkButton.fromObject(object.linkButton);
        }
        if (object.markdown != null) {
            if (typeof object.markdown !== "object")
                throw TypeError(".Element.markdown: object expected");
            message.markdown = $root.Markdown.fromObject(object.markdown);
        }
        if (object.metric != null) {
            if (typeof object.metric !== "object")
                throw TypeError(".Element.metric: object expected");
            message.metric = $root.Metric.fromObject(object.metric);
        }
        if (object.multiselect != null) {
            if (typeof object.multiselect !== "object")
                throw TypeError(".Element.multiselect: object expected");
            message.multiselect = $root.MultiSelect.fromObject(object.multiselect);
        }
        if (object.numberInput != null) {
            if (typeof object.numberInput !== "object")
                throw TypeError(".Element.numberInput: object expected");
            message.numberInput = $root.NumberInput.fromObject(object.numberInput);
        }
        if (object.pageLink != null) {
            if (typeof object.pageLink !== "object")
                throw TypeError(".Element.pageLink: object expected");
            message.pageLink = $root.PageLink.fromObject(object.pageLink);
        }
        if (object.plotlyChart != null) {
            if (typeof object.plotlyChart !== "object")
                throw TypeError(".Element.plotlyChart: object expected");
            message.plotlyChart = $root.PlotlyChart.fromObject(object.plotlyChart);
        }
        if (object.progress != null) {
            if (typeof object.progress !== "object")
                throw TypeError(".Element.progress: object expected");
            message.progress = $root.Progress.fromObject(object.progress);
        }
        if (object.radio != null) {
            if (typeof object.radio !== "object")
                throw TypeError(".Element.radio: object expected");
            message.radio = $root.Radio.fromObject(object.radio);
        }
        if (object.selectbox != null) {
            if (typeof object.selectbox !== "object")
                throw TypeError(".Element.selectbox: object expected");
            message.selectbox = $root.Selectbox.fromObject(object.selectbox);
        }
        if (object.skeleton != null) {
            if (typeof object.skeleton !== "object")
                throw TypeError(".Element.skeleton: object expected");
            message.skeleton = $root.Skeleton.fromObject(object.skeleton);
        }
        if (object.slider != null) {
            if (typeof object.slider !== "object")
                throw TypeError(".Element.slider: object expected");
            message.slider = $root.Slider.fromObject(object.slider);
        }
        if (object.snow != null) {
            if (typeof object.snow !== "object")
                throw TypeError(".Element.snow: object expected");
            message.snow = $root.Snow.fromObject(object.snow);
        }
        if (object.spinner != null) {
            if (typeof object.spinner !== "object")
                throw TypeError(".Element.spinner: object expected");
            message.spinner = $root.Spinner.fromObject(object.spinner);
        }
        if (object.text != null) {
            if (typeof object.text !== "object")
                throw TypeError(".Element.text: object expected");
            message.text = $root.Text.fromObject(object.text);
        }
        if (object.textArea != null) {
            if (typeof object.textArea !== "object")
                throw TypeError(".Element.textArea: object expected");
            message.textArea = $root.TextArea.fromObject(object.textArea);
        }
        if (object.textInput != null) {
            if (typeof object.textInput !== "object")
                throw TypeError(".Element.textInput: object expected");
            message.textInput = $root.TextInput.fromObject(object.textInput);
        }
        if (object.timeInput != null) {
            if (typeof object.timeInput !== "object")
                throw TypeError(".Element.timeInput: object expected");
            message.timeInput = $root.TimeInput.fromObject(object.timeInput);
        }
        if (object.toast != null) {
            if (typeof object.toast !== "object")
                throw TypeError(".Element.toast: object expected");
            message.toast = $root.Toast.fromObject(object.toast);
        }
        if (object.vegaLiteChart != null) {
            if (typeof object.vegaLiteChart !== "object")
                throw TypeError(".Element.vegaLiteChart: object expected");
            message.vegaLiteChart = $root.VegaLiteChart.fromObject(object.vegaLiteChart);
        }
        if (object.video != null) {
            if (typeof object.video !== "object")
                throw TypeError(".Element.video: object expected");
            message.video = $root.Video.fromObject(object.video);
        }
        if (object.heading != null) {
            if (typeof object.heading !== "object")
                throw TypeError(".Element.heading: object expected");
            message.heading = $root.Heading.fromObject(object.heading);
        }
        if (object.code != null) {
            if (typeof object.code !== "object")
                throw TypeError(".Element.code: object expected");
            message.code = $root.Code.fromObject(object.code);
        }
        return message;
    };

    /**
     * Creates a plain object from an Element message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Element
     * @static
     * @param {Element} message Element
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Element.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.text != null && message.hasOwnProperty("text")) {
            object.text = $root.Text.toObject(message.text, options);
            if (options.oneofs)
                object.type = "text";
        }
        if (message.empty != null && message.hasOwnProperty("empty")) {
            object.empty = $root.Empty.toObject(message.empty, options);
            if (options.oneofs)
                object.type = "empty";
        }
        if (message.dataFrame != null && message.hasOwnProperty("dataFrame")) {
            object.dataFrame = $root.DataFrame.toObject(message.dataFrame, options);
            if (options.oneofs)
                object.type = "dataFrame";
        }
        if (message.progress != null && message.hasOwnProperty("progress")) {
            object.progress = $root.Progress.toObject(message.progress, options);
            if (options.oneofs)
                object.type = "progress";
        }
        if (message.imgs != null && message.hasOwnProperty("imgs")) {
            object.imgs = $root.ImageList.toObject(message.imgs, options);
            if (options.oneofs)
                object.type = "imgs";
        }
        if (message.docString != null && message.hasOwnProperty("docString")) {
            object.docString = $root.DocString.toObject(message.docString, options);
            if (options.oneofs)
                object.type = "docString";
        }
        if (message.exception != null && message.hasOwnProperty("exception")) {
            object.exception = $root.Exception.toObject(message.exception, options);
            if (options.oneofs)
                object.type = "exception";
        }
        if (message.vegaLiteChart != null && message.hasOwnProperty("vegaLiteChart")) {
            object.vegaLiteChart = $root.VegaLiteChart.toObject(message.vegaLiteChart, options);
            if (options.oneofs)
                object.type = "vegaLiteChart";
        }
        if (message.table != null && message.hasOwnProperty("table")) {
            object.table = $root.DataFrame.toObject(message.table, options);
            if (options.oneofs)
                object.type = "table";
        }
        if (message.balloons != null && message.hasOwnProperty("balloons")) {
            object.balloons = $root.Balloons.toObject(message.balloons, options);
            if (options.oneofs)
                object.type = "balloons";
        }
        if (message.audio != null && message.hasOwnProperty("audio")) {
            object.audio = $root.Audio.toObject(message.audio, options);
            if (options.oneofs)
                object.type = "audio";
        }
        if (message.video != null && message.hasOwnProperty("video")) {
            object.video = $root.Video.toObject(message.video, options);
            if (options.oneofs)
                object.type = "video";
        }
        if (message.plotlyChart != null && message.hasOwnProperty("plotlyChart")) {
            object.plotlyChart = $root.PlotlyChart.toObject(message.plotlyChart, options);
            if (options.oneofs)
                object.type = "plotlyChart";
        }
        if (message.bokehChart != null && message.hasOwnProperty("bokehChart")) {
            object.bokehChart = $root.BokehChart.toObject(message.bokehChart, options);
            if (options.oneofs)
                object.type = "bokehChart";
        }
        if (message.graphvizChart != null && message.hasOwnProperty("graphvizChart")) {
            object.graphvizChart = $root.GraphVizChart.toObject(message.graphvizChart, options);
            if (options.oneofs)
                object.type = "graphvizChart";
        }
        if (message.button != null && message.hasOwnProperty("button")) {
            object.button = $root.Button.toObject(message.button, options);
            if (options.oneofs)
                object.type = "button";
        }
        if (message.checkbox != null && message.hasOwnProperty("checkbox")) {
            object.checkbox = $root.Checkbox.toObject(message.checkbox, options);
            if (options.oneofs)
                object.type = "checkbox";
        }
        if (message.slider != null && message.hasOwnProperty("slider")) {
            object.slider = $root.Slider.toObject(message.slider, options);
            if (options.oneofs)
                object.type = "slider";
        }
        if (message.textArea != null && message.hasOwnProperty("textArea")) {
            object.textArea = $root.TextArea.toObject(message.textArea, options);
            if (options.oneofs)
                object.type = "textArea";
        }
        if (message.radio != null && message.hasOwnProperty("radio")) {
            object.radio = $root.Radio.toObject(message.radio, options);
            if (options.oneofs)
                object.type = "radio";
        }
        if (message.textInput != null && message.hasOwnProperty("textInput")) {
            object.textInput = $root.TextInput.toObject(message.textInput, options);
            if (options.oneofs)
                object.type = "textInput";
        }
        if (message.selectbox != null && message.hasOwnProperty("selectbox")) {
            object.selectbox = $root.Selectbox.toObject(message.selectbox, options);
            if (options.oneofs)
                object.type = "selectbox";
        }
        if (message.timeInput != null && message.hasOwnProperty("timeInput")) {
            object.timeInput = $root.TimeInput.toObject(message.timeInput, options);
            if (options.oneofs)
                object.type = "timeInput";
        }
        if (message.dateInput != null && message.hasOwnProperty("dateInput")) {
            object.dateInput = $root.DateInput.toObject(message.dateInput, options);
            if (options.oneofs)
                object.type = "dateInput";
        }
        if (message.multiselect != null && message.hasOwnProperty("multiselect")) {
            object.multiselect = $root.MultiSelect.toObject(message.multiselect, options);
            if (options.oneofs)
                object.type = "multiselect";
        }
        if (message.markdown != null && message.hasOwnProperty("markdown")) {
            object.markdown = $root.Markdown.toObject(message.markdown, options);
            if (options.oneofs)
                object.type = "markdown";
        }
        if (message.alert != null && message.hasOwnProperty("alert")) {
            object.alert = $root.Alert.toObject(message.alert, options);
            if (options.oneofs)
                object.type = "alert";
        }
        if (message.json != null && message.hasOwnProperty("json")) {
            object.json = $root.Json.toObject(message.json, options);
            if (options.oneofs)
                object.type = "json";
        }
        if (message.numberInput != null && message.hasOwnProperty("numberInput")) {
            object.numberInput = $root.NumberInput.toObject(message.numberInput, options);
            if (options.oneofs)
                object.type = "numberInput";
        }
        if (message.fileUploader != null && message.hasOwnProperty("fileUploader")) {
            object.fileUploader = $root.FileUploader.toObject(message.fileUploader, options);
            if (options.oneofs)
                object.type = "fileUploader";
        }
        if (message.deckGlJsonChart != null && message.hasOwnProperty("deckGlJsonChart")) {
            object.deckGlJsonChart = $root.DeckGlJsonChart.toObject(message.deckGlJsonChart, options);
            if (options.oneofs)
                object.type = "deckGlJsonChart";
        }
        if (message.colorPicker != null && message.hasOwnProperty("colorPicker")) {
            object.colorPicker = $root.ColorPicker.toObject(message.colorPicker, options);
            if (options.oneofs)
                object.type = "colorPicker";
        }
        if (message.favicon != null && message.hasOwnProperty("favicon")) {
            object.favicon = $root.Favicon.toObject(message.favicon, options);
            if (options.oneofs)
                object.type = "favicon";
        }
        if (message.componentInstance != null && message.hasOwnProperty("componentInstance")) {
            object.componentInstance = $root.ComponentInstance.toObject(message.componentInstance, options);
            if (options.oneofs)
                object.type = "componentInstance";
        }
        if (message.iframe != null && message.hasOwnProperty("iframe")) {
            object.iframe = $root.IFrame.toObject(message.iframe, options);
            if (options.oneofs)
                object.type = "iframe";
        }
        if (message.arrowTable != null && message.hasOwnProperty("arrowTable")) {
            object.arrowTable = $root.Arrow.toObject(message.arrowTable, options);
            if (options.oneofs)
                object.type = "arrowTable";
        }
        if (message.arrowDataFrame != null && message.hasOwnProperty("arrowDataFrame")) {
            object.arrowDataFrame = $root.Arrow.toObject(message.arrowDataFrame, options);
            if (options.oneofs)
                object.type = "arrowDataFrame";
        }
        if (message.arrowVegaLiteChart != null && message.hasOwnProperty("arrowVegaLiteChart")) {
            object.arrowVegaLiteChart = $root.ArrowVegaLiteChart.toObject(message.arrowVegaLiteChart, options);
            if (options.oneofs)
                object.type = "arrowVegaLiteChart";
        }
        if (message.metric != null && message.hasOwnProperty("metric")) {
            object.metric = $root.Metric.toObject(message.metric, options);
            if (options.oneofs)
                object.type = "metric";
        }
        if (message.downloadButton != null && message.hasOwnProperty("downloadButton")) {
            object.downloadButton = $root.DownloadButton.toObject(message.downloadButton, options);
            if (options.oneofs)
                object.type = "downloadButton";
        }
        if (message.spinner != null && message.hasOwnProperty("spinner")) {
            object.spinner = $root.Spinner.toObject(message.spinner, options);
            if (options.oneofs)
                object.type = "spinner";
        }
        if (message.cameraInput != null && message.hasOwnProperty("cameraInput")) {
            object.cameraInput = $root.CameraInput.toObject(message.cameraInput, options);
            if (options.oneofs)
                object.type = "cameraInput";
        }
        if (message.snow != null && message.hasOwnProperty("snow")) {
            object.snow = $root.Snow.toObject(message.snow, options);
            if (options.oneofs)
                object.type = "snow";
        }
        if (message.heading != null && message.hasOwnProperty("heading")) {
            object.heading = $root.Heading.toObject(message.heading, options);
            if (options.oneofs)
                object.type = "heading";
        }
        if (message.code != null && message.hasOwnProperty("code")) {
            object.code = $root.Code.toObject(message.code, options);
            if (options.oneofs)
                object.type = "code";
        }
        if (message.chatInput != null && message.hasOwnProperty("chatInput")) {
            object.chatInput = $root.ChatInput.toObject(message.chatInput, options);
            if (options.oneofs)
                object.type = "chatInput";
        }
        if (message.toast != null && message.hasOwnProperty("toast")) {
            object.toast = $root.Toast.toObject(message.toast, options);
            if (options.oneofs)
                object.type = "toast";
        }
        if (message.linkButton != null && message.hasOwnProperty("linkButton")) {
            object.linkButton = $root.LinkButton.toObject(message.linkButton, options);
            if (options.oneofs)
                object.type = "linkButton";
        }
        if (message.skeleton != null && message.hasOwnProperty("skeleton")) {
            object.skeleton = $root.Skeleton.toObject(message.skeleton, options);
            if (options.oneofs)
                object.type = "skeleton";
        }
        if (message.pageLink != null && message.hasOwnProperty("pageLink")) {
            object.pageLink = $root.PageLink.toObject(message.pageLink, options);
            if (options.oneofs)
                object.type = "pageLink";
        }
        if (message.html != null && message.hasOwnProperty("html")) {
            object.html = $root.Html.toObject(message.html, options);
            if (options.oneofs)
                object.type = "html";
        }
        if (message.buttonGroup != null && message.hasOwnProperty("buttonGroup")) {
            object.buttonGroup = $root.ButtonGroup.toObject(message.buttonGroup, options);
            if (options.oneofs)
                object.type = "buttonGroup";
        }
        if (message.audioInput != null && message.hasOwnProperty("audioInput")) {
            object.audioInput = $root.AudioInput.toObject(message.audioInput, options);
            if (options.oneofs)
                object.type = "audioInput";
        }
        return object;
    };

    /**
     * Converts this Element to JSON.
     * @function toJSON
     * @memberof Element
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Element.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Element
     * @function getTypeUrl
     * @memberof Element
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Element.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Element";
    };

    return Element;
})();

export const DownloadButton = $root.DownloadButton = (() => {

    /**
     * Properties of a DownloadButton.
     * @exports IDownloadButton
     * @interface IDownloadButton
     * @property {string|null} [id] DownloadButton id
     * @property {string|null} [label] DownloadButton label
     * @property {boolean|null} ["default"] DownloadButton default
     * @property {string|null} [help] DownloadButton help
     * @property {string|null} [formId] DownloadButton formId
     * @property {string|null} [url] DownloadButton url
     * @property {boolean|null} [disabled] DownloadButton disabled
     * @property {boolean|null} [useContainerWidth] DownloadButton useContainerWidth
     * @property {string|null} [type] DownloadButton type
     * @property {string|null} [icon] DownloadButton icon
     */

    /**
     * Constructs a new DownloadButton.
     * @exports DownloadButton
     * @classdesc Represents a DownloadButton.
     * @implements IDownloadButton
     * @constructor
     * @param {IDownloadButton=} [properties] Properties to set
     */
    function DownloadButton(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DownloadButton id.
     * @member {string} id
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.id = "";

    /**
     * DownloadButton label.
     * @member {string} label
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.label = "";

    /**
     * DownloadButton default.
     * @member {boolean} default
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype["default"] = false;

    /**
     * DownloadButton help.
     * @member {string} help
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.help = "";

    /**
     * DownloadButton formId.
     * @member {string} formId
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.formId = "";

    /**
     * DownloadButton url.
     * @member {string} url
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.url = "";

    /**
     * DownloadButton disabled.
     * @member {boolean} disabled
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.disabled = false;

    /**
     * DownloadButton useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.useContainerWidth = false;

    /**
     * DownloadButton type.
     * @member {string} type
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.type = "";

    /**
     * DownloadButton icon.
     * @member {string} icon
     * @memberof DownloadButton
     * @instance
     */
    DownloadButton.prototype.icon = "";

    /**
     * Creates a new DownloadButton instance using the specified properties.
     * @function create
     * @memberof DownloadButton
     * @static
     * @param {IDownloadButton=} [properties] Properties to set
     * @returns {DownloadButton} DownloadButton instance
     */
    DownloadButton.create = function create(properties) {
        return new DownloadButton(properties);
    };

    /**
     * Encodes the specified DownloadButton message. Does not implicitly {@link DownloadButton.verify|verify} messages.
     * @function encode
     * @memberof DownloadButton
     * @static
     * @param {IDownloadButton} message DownloadButton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DownloadButton.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message["default"]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.formId);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.disabled);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.useContainerWidth);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.icon);
        return writer;
    };

    /**
     * Encodes the specified DownloadButton message, length delimited. Does not implicitly {@link DownloadButton.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DownloadButton
     * @static
     * @param {IDownloadButton} message DownloadButton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DownloadButton.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DownloadButton message from the specified reader or buffer.
     * @function decode
     * @memberof DownloadButton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DownloadButton} DownloadButton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DownloadButton.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DownloadButton();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.bool();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.formId = reader.string();
                    break;
                }
            case 6: {
                    message.url = reader.string();
                    break;
                }
            case 7: {
                    message.disabled = reader.bool();
                    break;
                }
            case 8: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 9: {
                    message.type = reader.string();
                    break;
                }
            case 10: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DownloadButton message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DownloadButton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DownloadButton} DownloadButton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DownloadButton.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DownloadButton message.
     * @function verify
     * @memberof DownloadButton
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DownloadButton.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default"))
            if (typeof message["default"] !== "boolean")
                return "default: boolean expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        return null;
    };

    /**
     * Creates a DownloadButton message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DownloadButton
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DownloadButton} DownloadButton
     */
    DownloadButton.fromObject = function fromObject(object) {
        if (object instanceof $root.DownloadButton)
            return object;
        let message = new $root.DownloadButton();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = Boolean(object["default"]);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.url != null)
            message.url = String(object.url);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.type != null)
            message.type = String(object.type);
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    /**
     * Creates a plain object from a DownloadButton message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DownloadButton
     * @static
     * @param {DownloadButton} message DownloadButton
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DownloadButton.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object["default"] = false;
            object.help = "";
            object.formId = "";
            object.url = "";
            object.disabled = false;
            object.useContainerWidth = false;
            object.type = "";
            object.icon = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default"))
            object["default"] = message["default"];
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    /**
     * Converts this DownloadButton to JSON.
     * @function toJSON
     * @memberof DownloadButton
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DownloadButton.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DownloadButton
     * @function getTypeUrl
     * @memberof DownloadButton
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DownloadButton.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DownloadButton";
    };

    return DownloadButton;
})();

export const DocString = $root.DocString = (() => {

    /**
     * Properties of a DocString.
     * @exports IDocString
     * @interface IDocString
     * @property {string|null} [docString] DocString docString
     * @property {string|null} [type] DocString type
     * @property {string|null} [name] DocString name
     * @property {string|null} [value] DocString value
     * @property {Array.<IMember>|null} [members] DocString members
     */

    /**
     * Constructs a new DocString.
     * @exports DocString
     * @classdesc Represents a DocString.
     * @implements IDocString
     * @constructor
     * @param {IDocString=} [properties] Properties to set
     */
    function DocString(properties) {
        this.members = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DocString docString.
     * @member {string} docString
     * @memberof DocString
     * @instance
     */
    DocString.prototype.docString = "";

    /**
     * DocString type.
     * @member {string} type
     * @memberof DocString
     * @instance
     */
    DocString.prototype.type = "";

    /**
     * DocString name.
     * @member {string} name
     * @memberof DocString
     * @instance
     */
    DocString.prototype.name = "";

    /**
     * DocString value.
     * @member {string} value
     * @memberof DocString
     * @instance
     */
    DocString.prototype.value = "";

    /**
     * DocString members.
     * @member {Array.<IMember>} members
     * @memberof DocString
     * @instance
     */
    DocString.prototype.members = $util.emptyArray;

    /**
     * Creates a new DocString instance using the specified properties.
     * @function create
     * @memberof DocString
     * @static
     * @param {IDocString=} [properties] Properties to set
     * @returns {DocString} DocString instance
     */
    DocString.create = function create(properties) {
        return new DocString(properties);
    };

    /**
     * Encodes the specified DocString message. Does not implicitly {@link DocString.verify|verify} messages.
     * @function encode
     * @memberof DocString
     * @static
     * @param {IDocString} message DocString message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DocString.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.docString != null && Object.hasOwnProperty.call(message, "docString"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.docString);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.type);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.value);
        if (message.members != null && message.members.length)
            for (let i = 0; i < message.members.length; ++i)
                $root.Member.encode(message.members[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified DocString message, length delimited. Does not implicitly {@link DocString.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DocString
     * @static
     * @param {IDocString} message DocString message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DocString.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DocString message from the specified reader or buffer.
     * @function decode
     * @memberof DocString
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DocString} DocString
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DocString.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DocString();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.docString = reader.string();
                    break;
                }
            case 4: {
                    message.type = reader.string();
                    break;
                }
            case 6: {
                    message.name = reader.string();
                    break;
                }
            case 7: {
                    message.value = reader.string();
                    break;
                }
            case 8: {
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.Member.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DocString message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DocString
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DocString} DocString
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DocString.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DocString message.
     * @function verify
     * @memberof DocString
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DocString.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.docString != null && message.hasOwnProperty("docString"))
            if (!$util.isString(message.docString))
                return "docString: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        if (message.members != null && message.hasOwnProperty("members")) {
            if (!Array.isArray(message.members))
                return "members: array expected";
            for (let i = 0; i < message.members.length; ++i) {
                let error = $root.Member.verify(message.members[i]);
                if (error)
                    return "members." + error;
            }
        }
        return null;
    };

    /**
     * Creates a DocString message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DocString
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DocString} DocString
     */
    DocString.fromObject = function fromObject(object) {
        if (object instanceof $root.DocString)
            return object;
        let message = new $root.DocString();
        if (object.docString != null)
            message.docString = String(object.docString);
        if (object.type != null)
            message.type = String(object.type);
        if (object.name != null)
            message.name = String(object.name);
        if (object.value != null)
            message.value = String(object.value);
        if (object.members) {
            if (!Array.isArray(object.members))
                throw TypeError(".DocString.members: array expected");
            message.members = [];
            for (let i = 0; i < object.members.length; ++i) {
                if (typeof object.members[i] !== "object")
                    throw TypeError(".DocString.members: object expected");
                message.members[i] = $root.Member.fromObject(object.members[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a DocString message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DocString
     * @static
     * @param {DocString} message DocString
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DocString.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.members = [];
        if (options.defaults) {
            object.docString = "";
            object.type = "";
            object.name = "";
            object.value = "";
        }
        if (message.docString != null && message.hasOwnProperty("docString"))
            object.docString = message.docString;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.members && message.members.length) {
            object.members = [];
            for (let j = 0; j < message.members.length; ++j)
                object.members[j] = $root.Member.toObject(message.members[j], options);
        }
        return object;
    };

    /**
     * Converts this DocString to JSON.
     * @function toJSON
     * @memberof DocString
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DocString.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DocString
     * @function getTypeUrl
     * @memberof DocString
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DocString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DocString";
    };

    return DocString;
})();

export const Member = $root.Member = (() => {

    /**
     * Properties of a Member.
     * @exports IMember
     * @interface IMember
     * @property {string|null} [name] Member name
     * @property {string|null} [type] Member type
     * @property {string|null} [value] Member value
     * @property {string|null} [docString] Member docString
     */

    /**
     * Constructs a new Member.
     * @exports Member
     * @classdesc Represents a Member.
     * @implements IMember
     * @constructor
     * @param {IMember=} [properties] Properties to set
     */
    function Member(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Member name.
     * @member {string} name
     * @memberof Member
     * @instance
     */
    Member.prototype.name = "";

    /**
     * Member type.
     * @member {string} type
     * @memberof Member
     * @instance
     */
    Member.prototype.type = "";

    /**
     * Member value.
     * @member {string|null|undefined} value
     * @memberof Member
     * @instance
     */
    Member.prototype.value = null;

    /**
     * Member docString.
     * @member {string|null|undefined} docString
     * @memberof Member
     * @instance
     */
    Member.prototype.docString = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Member contents.
     * @member {"value"|"docString"|undefined} contents
     * @memberof Member
     * @instance
     */
    Object.defineProperty(Member.prototype, "contents", {
        get: $util.oneOfGetter($oneOfFields = ["value", "docString"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Member instance using the specified properties.
     * @function create
     * @memberof Member
     * @static
     * @param {IMember=} [properties] Properties to set
     * @returns {Member} Member instance
     */
    Member.create = function create(properties) {
        return new Member(properties);
    };

    /**
     * Encodes the specified Member message. Does not implicitly {@link Member.verify|verify} messages.
     * @function encode
     * @memberof Member
     * @static
     * @param {IMember} message Member message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Member.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
        if (message.docString != null && Object.hasOwnProperty.call(message, "docString"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.docString);
        return writer;
    };

    /**
     * Encodes the specified Member message, length delimited. Does not implicitly {@link Member.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Member
     * @static
     * @param {IMember} message Member message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Member.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Member message from the specified reader or buffer.
     * @function decode
     * @memberof Member
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Member} Member
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Member.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Member();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.type = reader.string();
                    break;
                }
            case 3: {
                    message.value = reader.string();
                    break;
                }
            case 4: {
                    message.docString = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Member message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Member
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Member} Member
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Member.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Member message.
     * @function verify
     * @memberof Member
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Member.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties.contents = 1;
            if (!$util.isString(message.value))
                return "value: string expected";
        }
        if (message.docString != null && message.hasOwnProperty("docString")) {
            if (properties.contents === 1)
                return "contents: multiple values";
            properties.contents = 1;
            if (!$util.isString(message.docString))
                return "docString: string expected";
        }
        return null;
    };

    /**
     * Creates a Member message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Member
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Member} Member
     */
    Member.fromObject = function fromObject(object) {
        if (object instanceof $root.Member)
            return object;
        let message = new $root.Member();
        if (object.name != null)
            message.name = String(object.name);
        if (object.type != null)
            message.type = String(object.type);
        if (object.value != null)
            message.value = String(object.value);
        if (object.docString != null)
            message.docString = String(object.docString);
        return message;
    };

    /**
     * Creates a plain object from a Member message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Member
     * @static
     * @param {Member} message Member
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Member.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            object.type = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object.contents = "value";
        }
        if (message.docString != null && message.hasOwnProperty("docString")) {
            object.docString = message.docString;
            if (options.oneofs)
                object.contents = "docString";
        }
        return object;
    };

    /**
     * Converts this Member to JSON.
     * @function toJSON
     * @memberof Member
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Member.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Member
     * @function getTypeUrl
     * @memberof Member
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Member.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Member";
    };

    return Member;
})();

export const Empty = $root.Empty = (() => {

    /**
     * Properties of an Empty.
     * @exports IEmpty
     * @interface IEmpty
     */

    /**
     * Constructs a new Empty.
     * @exports Empty
     * @classdesc Represents an Empty.
     * @implements IEmpty
     * @constructor
     * @param {IEmpty=} [properties] Properties to set
     */
    function Empty(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new Empty instance using the specified properties.
     * @function create
     * @memberof Empty
     * @static
     * @param {IEmpty=} [properties] Properties to set
     * @returns {Empty} Empty instance
     */
    Empty.create = function create(properties) {
        return new Empty(properties);
    };

    /**
     * Encodes the specified Empty message. Does not implicitly {@link Empty.verify|verify} messages.
     * @function encode
     * @memberof Empty
     * @static
     * @param {IEmpty} message Empty message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Empty.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified Empty message, length delimited. Does not implicitly {@link Empty.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Empty
     * @static
     * @param {IEmpty} message Empty message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Empty.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Empty message from the specified reader or buffer.
     * @function decode
     * @memberof Empty
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Empty} Empty
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Empty.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Empty();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Empty message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Empty
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Empty} Empty
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Empty.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Empty message.
     * @function verify
     * @memberof Empty
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Empty.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates an Empty message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Empty
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Empty} Empty
     */
    Empty.fromObject = function fromObject(object) {
        if (object instanceof $root.Empty)
            return object;
        return new $root.Empty();
    };

    /**
     * Creates a plain object from an Empty message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Empty
     * @static
     * @param {Empty} message Empty
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Empty.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Empty to JSON.
     * @function toJSON
     * @memberof Empty
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Empty.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Empty
     * @function getTypeUrl
     * @memberof Empty
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Empty";
    };

    return Empty;
})();

export const Exception = $root.Exception = (() => {

    /**
     * Properties of an Exception.
     * @exports IException
     * @interface IException
     * @property {string|null} [type] Exception type
     * @property {string|null} [message] Exception message
     * @property {boolean|null} [messageIsMarkdown] Exception messageIsMarkdown
     * @property {Array.<string>|null} [stackTrace] Exception stackTrace
     * @property {boolean|null} [isWarning] Exception isWarning
     */

    /**
     * Constructs a new Exception.
     * @exports Exception
     * @classdesc Represents an Exception.
     * @implements IException
     * @constructor
     * @param {IException=} [properties] Properties to set
     */
    function Exception(properties) {
        this.stackTrace = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Exception type.
     * @member {string} type
     * @memberof Exception
     * @instance
     */
    Exception.prototype.type = "";

    /**
     * Exception message.
     * @member {string} message
     * @memberof Exception
     * @instance
     */
    Exception.prototype.message = "";

    /**
     * Exception messageIsMarkdown.
     * @member {boolean} messageIsMarkdown
     * @memberof Exception
     * @instance
     */
    Exception.prototype.messageIsMarkdown = false;

    /**
     * Exception stackTrace.
     * @member {Array.<string>} stackTrace
     * @memberof Exception
     * @instance
     */
    Exception.prototype.stackTrace = $util.emptyArray;

    /**
     * Exception isWarning.
     * @member {boolean} isWarning
     * @memberof Exception
     * @instance
     */
    Exception.prototype.isWarning = false;

    /**
     * Creates a new Exception instance using the specified properties.
     * @function create
     * @memberof Exception
     * @static
     * @param {IException=} [properties] Properties to set
     * @returns {Exception} Exception instance
     */
    Exception.create = function create(properties) {
        return new Exception(properties);
    };

    /**
     * Encodes the specified Exception message. Does not implicitly {@link Exception.verify|verify} messages.
     * @function encode
     * @memberof Exception
     * @static
     * @param {IException} message Exception message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Exception.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
        if (message.stackTrace != null && message.stackTrace.length)
            for (let i = 0; i < message.stackTrace.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.stackTrace[i]);
        if (message.messageIsMarkdown != null && Object.hasOwnProperty.call(message, "messageIsMarkdown"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.messageIsMarkdown);
        if (message.isWarning != null && Object.hasOwnProperty.call(message, "isWarning"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isWarning);
        return writer;
    };

    /**
     * Encodes the specified Exception message, length delimited. Does not implicitly {@link Exception.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Exception
     * @static
     * @param {IException} message Exception message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Exception.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Exception message from the specified reader or buffer.
     * @function decode
     * @memberof Exception
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Exception} Exception
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Exception.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Exception();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.string();
                    break;
                }
            case 2: {
                    message.message = reader.string();
                    break;
                }
            case 4: {
                    message.messageIsMarkdown = reader.bool();
                    break;
                }
            case 3: {
                    if (!(message.stackTrace && message.stackTrace.length))
                        message.stackTrace = [];
                    message.stackTrace.push(reader.string());
                    break;
                }
            case 5: {
                    message.isWarning = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Exception message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Exception
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Exception} Exception
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Exception.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Exception message.
     * @function verify
     * @memberof Exception
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Exception.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        if (message.messageIsMarkdown != null && message.hasOwnProperty("messageIsMarkdown"))
            if (typeof message.messageIsMarkdown !== "boolean")
                return "messageIsMarkdown: boolean expected";
        if (message.stackTrace != null && message.hasOwnProperty("stackTrace")) {
            if (!Array.isArray(message.stackTrace))
                return "stackTrace: array expected";
            for (let i = 0; i < message.stackTrace.length; ++i)
                if (!$util.isString(message.stackTrace[i]))
                    return "stackTrace: string[] expected";
        }
        if (message.isWarning != null && message.hasOwnProperty("isWarning"))
            if (typeof message.isWarning !== "boolean")
                return "isWarning: boolean expected";
        return null;
    };

    /**
     * Creates an Exception message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Exception
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Exception} Exception
     */
    Exception.fromObject = function fromObject(object) {
        if (object instanceof $root.Exception)
            return object;
        let message = new $root.Exception();
        if (object.type != null)
            message.type = String(object.type);
        if (object.message != null)
            message.message = String(object.message);
        if (object.messageIsMarkdown != null)
            message.messageIsMarkdown = Boolean(object.messageIsMarkdown);
        if (object.stackTrace) {
            if (!Array.isArray(object.stackTrace))
                throw TypeError(".Exception.stackTrace: array expected");
            message.stackTrace = [];
            for (let i = 0; i < object.stackTrace.length; ++i)
                message.stackTrace[i] = String(object.stackTrace[i]);
        }
        if (object.isWarning != null)
            message.isWarning = Boolean(object.isWarning);
        return message;
    };

    /**
     * Creates a plain object from an Exception message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Exception
     * @static
     * @param {Exception} message Exception
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Exception.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.stackTrace = [];
        if (options.defaults) {
            object.type = "";
            object.message = "";
            object.messageIsMarkdown = false;
            object.isWarning = false;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        if (message.stackTrace && message.stackTrace.length) {
            object.stackTrace = [];
            for (let j = 0; j < message.stackTrace.length; ++j)
                object.stackTrace[j] = message.stackTrace[j];
        }
        if (message.messageIsMarkdown != null && message.hasOwnProperty("messageIsMarkdown"))
            object.messageIsMarkdown = message.messageIsMarkdown;
        if (message.isWarning != null && message.hasOwnProperty("isWarning"))
            object.isWarning = message.isWarning;
        return object;
    };

    /**
     * Converts this Exception to JSON.
     * @function toJSON
     * @memberof Exception
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Exception.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Exception
     * @function getTypeUrl
     * @memberof Exception
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Exception.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Exception";
    };

    return Exception;
})();

export const Favicon = $root.Favicon = (() => {

    /**
     * Properties of a Favicon.
     * @exports IFavicon
     * @interface IFavicon
     * @property {string|null} [url] Favicon url
     */

    /**
     * Constructs a new Favicon.
     * @exports Favicon
     * @classdesc Represents a Favicon.
     * @implements IFavicon
     * @constructor
     * @param {IFavicon=} [properties] Properties to set
     */
    function Favicon(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Favicon url.
     * @member {string} url
     * @memberof Favicon
     * @instance
     */
    Favicon.prototype.url = "";

    /**
     * Creates a new Favicon instance using the specified properties.
     * @function create
     * @memberof Favicon
     * @static
     * @param {IFavicon=} [properties] Properties to set
     * @returns {Favicon} Favicon instance
     */
    Favicon.create = function create(properties) {
        return new Favicon(properties);
    };

    /**
     * Encodes the specified Favicon message. Does not implicitly {@link Favicon.verify|verify} messages.
     * @function encode
     * @memberof Favicon
     * @static
     * @param {IFavicon} message Favicon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Favicon.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
        return writer;
    };

    /**
     * Encodes the specified Favicon message, length delimited. Does not implicitly {@link Favicon.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Favicon
     * @static
     * @param {IFavicon} message Favicon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Favicon.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Favicon message from the specified reader or buffer.
     * @function decode
     * @memberof Favicon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Favicon} Favicon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Favicon.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Favicon();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.url = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Favicon message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Favicon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Favicon} Favicon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Favicon.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Favicon message.
     * @function verify
     * @memberof Favicon
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Favicon.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        return null;
    };

    /**
     * Creates a Favicon message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Favicon
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Favicon} Favicon
     */
    Favicon.fromObject = function fromObject(object) {
        if (object instanceof $root.Favicon)
            return object;
        let message = new $root.Favicon();
        if (object.url != null)
            message.url = String(object.url);
        return message;
    };

    /**
     * Creates a plain object from a Favicon message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Favicon
     * @static
     * @param {Favicon} message Favicon
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Favicon.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.url = "";
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        return object;
    };

    /**
     * Converts this Favicon to JSON.
     * @function toJSON
     * @memberof Favicon
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Favicon.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Favicon
     * @function getTypeUrl
     * @memberof Favicon
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Favicon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Favicon";
    };

    return Favicon;
})();

export const FileUploader = $root.FileUploader = (() => {

    /**
     * Properties of a FileUploader.
     * @exports IFileUploader
     * @interface IFileUploader
     * @property {string|null} [id] FileUploader id
     * @property {string|null} [label] FileUploader label
     * @property {Array.<string>|null} [type] FileUploader type
     * @property {number|null} [maxUploadSizeMb] FileUploader maxUploadSizeMb
     * @property {boolean|null} [multipleFiles] FileUploader multipleFiles
     * @property {string|null} [help] FileUploader help
     * @property {string|null} [formId] FileUploader formId
     * @property {boolean|null} [disabled] FileUploader disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] FileUploader labelVisibility
     */

    /**
     * Constructs a new FileUploader.
     * @exports FileUploader
     * @classdesc Represents a FileUploader.
     * @implements IFileUploader
     * @constructor
     * @param {IFileUploader=} [properties] Properties to set
     */
    function FileUploader(properties) {
        this.type = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileUploader id.
     * @member {string} id
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.id = "";

    /**
     * FileUploader label.
     * @member {string} label
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.label = "";

    /**
     * FileUploader type.
     * @member {Array.<string>} type
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.type = $util.emptyArray;

    /**
     * FileUploader maxUploadSizeMb.
     * @member {number} maxUploadSizeMb
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.maxUploadSizeMb = 0;

    /**
     * FileUploader multipleFiles.
     * @member {boolean} multipleFiles
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.multipleFiles = false;

    /**
     * FileUploader help.
     * @member {string} help
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.help = "";

    /**
     * FileUploader formId.
     * @member {string} formId
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.formId = "";

    /**
     * FileUploader disabled.
     * @member {boolean} disabled
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.disabled = false;

    /**
     * FileUploader labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof FileUploader
     * @instance
     */
    FileUploader.prototype.labelVisibility = null;

    /**
     * Creates a new FileUploader instance using the specified properties.
     * @function create
     * @memberof FileUploader
     * @static
     * @param {IFileUploader=} [properties] Properties to set
     * @returns {FileUploader} FileUploader instance
     */
    FileUploader.create = function create(properties) {
        return new FileUploader(properties);
    };

    /**
     * Encodes the specified FileUploader message. Does not implicitly {@link FileUploader.verify|verify} messages.
     * @function encode
     * @memberof FileUploader
     * @static
     * @param {IFileUploader} message FileUploader message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileUploader.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.type != null && message.type.length)
            for (let i = 0; i < message.type.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type[i]);
        if (message.maxUploadSizeMb != null && Object.hasOwnProperty.call(message, "maxUploadSizeMb"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.maxUploadSizeMb);
        if (message.multipleFiles != null && Object.hasOwnProperty.call(message, "multipleFiles"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.multipleFiles);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.formId);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified FileUploader message, length delimited. Does not implicitly {@link FileUploader.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FileUploader
     * @static
     * @param {IFileUploader} message FileUploader message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileUploader.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileUploader message from the specified reader or buffer.
     * @function decode
     * @memberof FileUploader
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FileUploader} FileUploader
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileUploader.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FileUploader();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.type && message.type.length))
                        message.type = [];
                    message.type.push(reader.string());
                    break;
                }
            case 4: {
                    message.maxUploadSizeMb = reader.int32();
                    break;
                }
            case 6: {
                    message.multipleFiles = reader.bool();
                    break;
                }
            case 7: {
                    message.help = reader.string();
                    break;
                }
            case 8: {
                    message.formId = reader.string();
                    break;
                }
            case 9: {
                    message.disabled = reader.bool();
                    break;
                }
            case 10: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FileUploader message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FileUploader
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FileUploader} FileUploader
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileUploader.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileUploader message.
     * @function verify
     * @memberof FileUploader
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileUploader.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.type != null && message.hasOwnProperty("type")) {
            if (!Array.isArray(message.type))
                return "type: array expected";
            for (let i = 0; i < message.type.length; ++i)
                if (!$util.isString(message.type[i]))
                    return "type: string[] expected";
        }
        if (message.maxUploadSizeMb != null && message.hasOwnProperty("maxUploadSizeMb"))
            if (!$util.isInteger(message.maxUploadSizeMb))
                return "maxUploadSizeMb: integer expected";
        if (message.multipleFiles != null && message.hasOwnProperty("multipleFiles"))
            if (typeof message.multipleFiles !== "boolean")
                return "multipleFiles: boolean expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates a FileUploader message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FileUploader
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FileUploader} FileUploader
     */
    FileUploader.fromObject = function fromObject(object) {
        if (object instanceof $root.FileUploader)
            return object;
        let message = new $root.FileUploader();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object.type) {
            if (!Array.isArray(object.type))
                throw TypeError(".FileUploader.type: array expected");
            message.type = [];
            for (let i = 0; i < object.type.length; ++i)
                message.type[i] = String(object.type[i]);
        }
        if (object.maxUploadSizeMb != null)
            message.maxUploadSizeMb = object.maxUploadSizeMb | 0;
        if (object.multipleFiles != null)
            message.multipleFiles = Boolean(object.multipleFiles);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".FileUploader.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from a FileUploader message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FileUploader
     * @static
     * @param {FileUploader} message FileUploader
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileUploader.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.type = [];
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.maxUploadSizeMb = 0;
            object.multipleFiles = false;
            object.help = "";
            object.formId = "";
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.type && message.type.length) {
            object.type = [];
            for (let j = 0; j < message.type.length; ++j)
                object.type[j] = message.type[j];
        }
        if (message.maxUploadSizeMb != null && message.hasOwnProperty("maxUploadSizeMb"))
            object.maxUploadSizeMb = message.maxUploadSizeMb;
        if (message.multipleFiles != null && message.hasOwnProperty("multipleFiles"))
            object.multipleFiles = message.multipleFiles;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this FileUploader to JSON.
     * @function toJSON
     * @memberof FileUploader
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileUploader.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileUploader
     * @function getTypeUrl
     * @memberof FileUploader
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileUploader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FileUploader";
    };

    return FileUploader;
})();

export const GraphVizChart = $root.GraphVizChart = (() => {

    /**
     * Properties of a GraphVizChart.
     * @exports IGraphVizChart
     * @interface IGraphVizChart
     * @property {string|null} [spec] GraphVizChart spec
     * @property {boolean|null} [useContainerWidth] GraphVizChart useContainerWidth
     * @property {string|null} [elementId] GraphVizChart elementId
     * @property {string|null} [engine] GraphVizChart engine
     */

    /**
     * Constructs a new GraphVizChart.
     * @exports GraphVizChart
     * @classdesc Represents a GraphVizChart.
     * @implements IGraphVizChart
     * @constructor
     * @param {IGraphVizChart=} [properties] Properties to set
     */
    function GraphVizChart(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GraphVizChart spec.
     * @member {string} spec
     * @memberof GraphVizChart
     * @instance
     */
    GraphVizChart.prototype.spec = "";

    /**
     * GraphVizChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof GraphVizChart
     * @instance
     */
    GraphVizChart.prototype.useContainerWidth = false;

    /**
     * GraphVizChart elementId.
     * @member {string} elementId
     * @memberof GraphVizChart
     * @instance
     */
    GraphVizChart.prototype.elementId = "";

    /**
     * GraphVizChart engine.
     * @member {string} engine
     * @memberof GraphVizChart
     * @instance
     */
    GraphVizChart.prototype.engine = "";

    /**
     * Creates a new GraphVizChart instance using the specified properties.
     * @function create
     * @memberof GraphVizChart
     * @static
     * @param {IGraphVizChart=} [properties] Properties to set
     * @returns {GraphVizChart} GraphVizChart instance
     */
    GraphVizChart.create = function create(properties) {
        return new GraphVizChart(properties);
    };

    /**
     * Encodes the specified GraphVizChart message. Does not implicitly {@link GraphVizChart.verify|verify} messages.
     * @function encode
     * @memberof GraphVizChart
     * @static
     * @param {IGraphVizChart} message GraphVizChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GraphVizChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.spec);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.useContainerWidth);
        if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.elementId);
        if (message.engine != null && Object.hasOwnProperty.call(message, "engine"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.engine);
        return writer;
    };

    /**
     * Encodes the specified GraphVizChart message, length delimited. Does not implicitly {@link GraphVizChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GraphVizChart
     * @static
     * @param {IGraphVizChart} message GraphVizChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GraphVizChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GraphVizChart message from the specified reader or buffer.
     * @function decode
     * @memberof GraphVizChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GraphVizChart} GraphVizChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GraphVizChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GraphVizChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.spec = reader.string();
                    break;
                }
            case 4: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 5: {
                    message.elementId = reader.string();
                    break;
                }
            case 6: {
                    message.engine = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GraphVizChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GraphVizChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GraphVizChart} GraphVizChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GraphVizChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GraphVizChart message.
     * @function verify
     * @memberof GraphVizChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GraphVizChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.spec != null && message.hasOwnProperty("spec"))
            if (!$util.isString(message.spec))
                return "spec: string expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.elementId != null && message.hasOwnProperty("elementId"))
            if (!$util.isString(message.elementId))
                return "elementId: string expected";
        if (message.engine != null && message.hasOwnProperty("engine"))
            if (!$util.isString(message.engine))
                return "engine: string expected";
        return null;
    };

    /**
     * Creates a GraphVizChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GraphVizChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GraphVizChart} GraphVizChart
     */
    GraphVizChart.fromObject = function fromObject(object) {
        if (object instanceof $root.GraphVizChart)
            return object;
        let message = new $root.GraphVizChart();
        if (object.spec != null)
            message.spec = String(object.spec);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.elementId != null)
            message.elementId = String(object.elementId);
        if (object.engine != null)
            message.engine = String(object.engine);
        return message;
    };

    /**
     * Creates a plain object from a GraphVizChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GraphVizChart
     * @static
     * @param {GraphVizChart} message GraphVizChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GraphVizChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.spec = "";
            object.useContainerWidth = false;
            object.elementId = "";
            object.engine = "";
        }
        if (message.spec != null && message.hasOwnProperty("spec"))
            object.spec = message.spec;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.elementId != null && message.hasOwnProperty("elementId"))
            object.elementId = message.elementId;
        if (message.engine != null && message.hasOwnProperty("engine"))
            object.engine = message.engine;
        return object;
    };

    /**
     * Converts this GraphVizChart to JSON.
     * @function toJSON
     * @memberof GraphVizChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GraphVizChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GraphVizChart
     * @function getTypeUrl
     * @memberof GraphVizChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GraphVizChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GraphVizChart";
    };

    return GraphVizChart;
})();

export const Html = $root.Html = (() => {

    /**
     * Properties of a Html.
     * @exports IHtml
     * @interface IHtml
     * @property {string|null} [body] Html body
     */

    /**
     * Constructs a new Html.
     * @exports Html
     * @classdesc Represents a Html.
     * @implements IHtml
     * @constructor
     * @param {IHtml=} [properties] Properties to set
     */
    function Html(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Html body.
     * @member {string} body
     * @memberof Html
     * @instance
     */
    Html.prototype.body = "";

    /**
     * Creates a new Html instance using the specified properties.
     * @function create
     * @memberof Html
     * @static
     * @param {IHtml=} [properties] Properties to set
     * @returns {Html} Html instance
     */
    Html.create = function create(properties) {
        return new Html(properties);
    };

    /**
     * Encodes the specified Html message. Does not implicitly {@link Html.verify|verify} messages.
     * @function encode
     * @memberof Html
     * @static
     * @param {IHtml} message Html message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Html.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        return writer;
    };

    /**
     * Encodes the specified Html message, length delimited. Does not implicitly {@link Html.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Html
     * @static
     * @param {IHtml} message Html message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Html.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Html message from the specified reader or buffer.
     * @function decode
     * @memberof Html
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Html} Html
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Html.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Html();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Html message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Html
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Html} Html
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Html.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Html message.
     * @function verify
     * @memberof Html
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Html.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        return null;
    };

    /**
     * Creates a Html message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Html
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Html} Html
     */
    Html.fromObject = function fromObject(object) {
        if (object instanceof $root.Html)
            return object;
        let message = new $root.Html();
        if (object.body != null)
            message.body = String(object.body);
        return message;
    };

    /**
     * Creates a plain object from a Html message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Html
     * @static
     * @param {Html} message Html
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Html.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.body = "";
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        return object;
    };

    /**
     * Converts this Html to JSON.
     * @function toJSON
     * @memberof Html
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Html.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Html
     * @function getTypeUrl
     * @memberof Html
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Html.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Html";
    };

    return Html;
})();

export const IFrame = $root.IFrame = (() => {

    /**
     * Properties of a IFrame.
     * @exports IIFrame
     * @interface IIFrame
     * @property {string|null} [src] IFrame src
     * @property {string|null} [srcdoc] IFrame srcdoc
     * @property {number|null} [width] IFrame width
     * @property {boolean|null} [hasWidth] IFrame hasWidth
     * @property {number|null} [height] IFrame height
     * @property {boolean|null} [scrolling] IFrame scrolling
     */

    /**
     * Constructs a new IFrame.
     * @exports IFrame
     * @classdesc Represents a IFrame.
     * @implements IIFrame
     * @constructor
     * @param {IIFrame=} [properties] Properties to set
     */
    function IFrame(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IFrame src.
     * @member {string|null|undefined} src
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.src = null;

    /**
     * IFrame srcdoc.
     * @member {string|null|undefined} srcdoc
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.srcdoc = null;

    /**
     * IFrame width.
     * @member {number} width
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.width = 0;

    /**
     * IFrame hasWidth.
     * @member {boolean} hasWidth
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.hasWidth = false;

    /**
     * IFrame height.
     * @member {number} height
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.height = 0;

    /**
     * IFrame scrolling.
     * @member {boolean} scrolling
     * @memberof IFrame
     * @instance
     */
    IFrame.prototype.scrolling = false;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * IFrame type.
     * @member {"src"|"srcdoc"|undefined} type
     * @memberof IFrame
     * @instance
     */
    Object.defineProperty(IFrame.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["src", "srcdoc"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new IFrame instance using the specified properties.
     * @function create
     * @memberof IFrame
     * @static
     * @param {IIFrame=} [properties] Properties to set
     * @returns {IFrame} IFrame instance
     */
    IFrame.create = function create(properties) {
        return new IFrame(properties);
    };

    /**
     * Encodes the specified IFrame message. Does not implicitly {@link IFrame.verify|verify} messages.
     * @function encode
     * @memberof IFrame
     * @static
     * @param {IIFrame} message IFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IFrame.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.src != null && Object.hasOwnProperty.call(message, "src"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.src);
        if (message.srcdoc != null && Object.hasOwnProperty.call(message, "srcdoc"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.srcdoc);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.width);
        if (message.hasWidth != null && Object.hasOwnProperty.call(message, "hasWidth"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hasWidth);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 5, wireType 5 =*/45).float(message.height);
        if (message.scrolling != null && Object.hasOwnProperty.call(message, "scrolling"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.scrolling);
        return writer;
    };

    /**
     * Encodes the specified IFrame message, length delimited. Does not implicitly {@link IFrame.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IFrame
     * @static
     * @param {IIFrame} message IFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IFrame.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a IFrame message from the specified reader or buffer.
     * @function decode
     * @memberof IFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IFrame} IFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IFrame.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IFrame();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.src = reader.string();
                    break;
                }
            case 2: {
                    message.srcdoc = reader.string();
                    break;
                }
            case 3: {
                    message.width = reader.float();
                    break;
                }
            case 4: {
                    message.hasWidth = reader.bool();
                    break;
                }
            case 5: {
                    message.height = reader.float();
                    break;
                }
            case 7: {
                    message.scrolling = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a IFrame message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IFrame} IFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IFrame.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a IFrame message.
     * @function verify
     * @memberof IFrame
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IFrame.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.src != null && message.hasOwnProperty("src")) {
            properties.type = 1;
            if (!$util.isString(message.src))
                return "src: string expected";
        }
        if (message.srcdoc != null && message.hasOwnProperty("srcdoc")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (!$util.isString(message.srcdoc))
                return "srcdoc: string expected";
        }
        if (message.width != null && message.hasOwnProperty("width"))
            if (typeof message.width !== "number")
                return "width: number expected";
        if (message.hasWidth != null && message.hasOwnProperty("hasWidth"))
            if (typeof message.hasWidth !== "boolean")
                return "hasWidth: boolean expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (typeof message.height !== "number")
                return "height: number expected";
        if (message.scrolling != null && message.hasOwnProperty("scrolling"))
            if (typeof message.scrolling !== "boolean")
                return "scrolling: boolean expected";
        return null;
    };

    /**
     * Creates a IFrame message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IFrame
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IFrame} IFrame
     */
    IFrame.fromObject = function fromObject(object) {
        if (object instanceof $root.IFrame)
            return object;
        let message = new $root.IFrame();
        if (object.src != null)
            message.src = String(object.src);
        if (object.srcdoc != null)
            message.srcdoc = String(object.srcdoc);
        if (object.width != null)
            message.width = Number(object.width);
        if (object.hasWidth != null)
            message.hasWidth = Boolean(object.hasWidth);
        if (object.height != null)
            message.height = Number(object.height);
        if (object.scrolling != null)
            message.scrolling = Boolean(object.scrolling);
        return message;
    };

    /**
     * Creates a plain object from a IFrame message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IFrame
     * @static
     * @param {IFrame} message IFrame
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IFrame.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.width = 0;
            object.hasWidth = false;
            object.height = 0;
            object.scrolling = false;
        }
        if (message.src != null && message.hasOwnProperty("src")) {
            object.src = message.src;
            if (options.oneofs)
                object.type = "src";
        }
        if (message.srcdoc != null && message.hasOwnProperty("srcdoc")) {
            object.srcdoc = message.srcdoc;
            if (options.oneofs)
                object.type = "srcdoc";
        }
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
        if (message.hasWidth != null && message.hasOwnProperty("hasWidth"))
            object.hasWidth = message.hasWidth;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
        if (message.scrolling != null && message.hasOwnProperty("scrolling"))
            object.scrolling = message.scrolling;
        return object;
    };

    /**
     * Converts this IFrame to JSON.
     * @function toJSON
     * @memberof IFrame
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IFrame.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for IFrame
     * @function getTypeUrl
     * @memberof IFrame
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    IFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IFrame";
    };

    return IFrame;
})();

export const Image = $root.Image = (() => {

    /**
     * Properties of an Image.
     * @exports IImage
     * @interface IImage
     * @property {string|null} [url] Image url
     * @property {string|null} [caption] Image caption
     * @property {string|null} [markup] Image markup
     */

    /**
     * Constructs a new Image.
     * @exports Image
     * @classdesc Represents an Image.
     * @implements IImage
     * @constructor
     * @param {IImage=} [properties] Properties to set
     */
    function Image(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Image url.
     * @member {string} url
     * @memberof Image
     * @instance
     */
    Image.prototype.url = "";

    /**
     * Image caption.
     * @member {string} caption
     * @memberof Image
     * @instance
     */
    Image.prototype.caption = "";

    /**
     * Image markup.
     * @member {string} markup
     * @memberof Image
     * @instance
     */
    Image.prototype.markup = "";

    /**
     * Creates a new Image instance using the specified properties.
     * @function create
     * @memberof Image
     * @static
     * @param {IImage=} [properties] Properties to set
     * @returns {Image} Image instance
     */
    Image.create = function create(properties) {
        return new Image(properties);
    };

    /**
     * Encodes the specified Image message. Does not implicitly {@link Image.verify|verify} messages.
     * @function encode
     * @memberof Image
     * @static
     * @param {IImage} message Image message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Image.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.caption);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
        if (message.markup != null && Object.hasOwnProperty.call(message, "markup"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.markup);
        return writer;
    };

    /**
     * Encodes the specified Image message, length delimited. Does not implicitly {@link Image.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Image
     * @static
     * @param {IImage} message Image message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Image.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Image message from the specified reader or buffer.
     * @function decode
     * @memberof Image
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Image} Image
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Image.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Image();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.url = reader.string();
                    break;
                }
            case 2: {
                    message.caption = reader.string();
                    break;
                }
            case 4: {
                    message.markup = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Image message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Image
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Image} Image
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Image.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Image message.
     * @function verify
     * @memberof Image
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Image.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.caption != null && message.hasOwnProperty("caption"))
            if (!$util.isString(message.caption))
                return "caption: string expected";
        if (message.markup != null && message.hasOwnProperty("markup"))
            if (!$util.isString(message.markup))
                return "markup: string expected";
        return null;
    };

    /**
     * Creates an Image message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Image
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Image} Image
     */
    Image.fromObject = function fromObject(object) {
        if (object instanceof $root.Image)
            return object;
        let message = new $root.Image();
        if (object.url != null)
            message.url = String(object.url);
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.markup != null)
            message.markup = String(object.markup);
        return message;
    };

    /**
     * Creates a plain object from an Image message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Image
     * @static
     * @param {Image} message Image
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Image.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.caption = "";
            object.url = "";
            object.markup = "";
        }
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.markup != null && message.hasOwnProperty("markup"))
            object.markup = message.markup;
        return object;
    };

    /**
     * Converts this Image to JSON.
     * @function toJSON
     * @memberof Image
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Image.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Image
     * @function getTypeUrl
     * @memberof Image
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Image.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Image";
    };

    return Image;
})();

export const ImageList = $root.ImageList = (() => {

    /**
     * Properties of an ImageList.
     * @exports IImageList
     * @interface IImageList
     * @property {Array.<IImage>|null} [imgs] ImageList imgs
     * @property {number|null} [width] ImageList width
     */

    /**
     * Constructs a new ImageList.
     * @exports ImageList
     * @classdesc Represents an ImageList.
     * @implements IImageList
     * @constructor
     * @param {IImageList=} [properties] Properties to set
     */
    function ImageList(properties) {
        this.imgs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImageList imgs.
     * @member {Array.<IImage>} imgs
     * @memberof ImageList
     * @instance
     */
    ImageList.prototype.imgs = $util.emptyArray;

    /**
     * ImageList width.
     * @member {number} width
     * @memberof ImageList
     * @instance
     */
    ImageList.prototype.width = 0;

    /**
     * Creates a new ImageList instance using the specified properties.
     * @function create
     * @memberof ImageList
     * @static
     * @param {IImageList=} [properties] Properties to set
     * @returns {ImageList} ImageList instance
     */
    ImageList.create = function create(properties) {
        return new ImageList(properties);
    };

    /**
     * Encodes the specified ImageList message. Does not implicitly {@link ImageList.verify|verify} messages.
     * @function encode
     * @memberof ImageList
     * @static
     * @param {IImageList} message ImageList message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImageList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.imgs != null && message.imgs.length)
            for (let i = 0; i < message.imgs.length; ++i)
                $root.Image.encode(message.imgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.width);
        return writer;
    };

    /**
     * Encodes the specified ImageList message, length delimited. Does not implicitly {@link ImageList.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImageList
     * @static
     * @param {IImageList} message ImageList message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImageList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImageList message from the specified reader or buffer.
     * @function decode
     * @memberof ImageList
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImageList} ImageList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImageList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImageList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.imgs && message.imgs.length))
                        message.imgs = [];
                    message.imgs.push($root.Image.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.width = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ImageList message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImageList
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImageList} ImageList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImageList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImageList message.
     * @function verify
     * @memberof ImageList
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImageList.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.imgs != null && message.hasOwnProperty("imgs")) {
            if (!Array.isArray(message.imgs))
                return "imgs: array expected";
            for (let i = 0; i < message.imgs.length; ++i) {
                let error = $root.Image.verify(message.imgs[i]);
                if (error)
                    return "imgs." + error;
            }
        }
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width))
                return "width: integer expected";
        return null;
    };

    /**
     * Creates an ImageList message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImageList
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImageList} ImageList
     */
    ImageList.fromObject = function fromObject(object) {
        if (object instanceof $root.ImageList)
            return object;
        let message = new $root.ImageList();
        if (object.imgs) {
            if (!Array.isArray(object.imgs))
                throw TypeError(".ImageList.imgs: array expected");
            message.imgs = [];
            for (let i = 0; i < object.imgs.length; ++i) {
                if (typeof object.imgs[i] !== "object")
                    throw TypeError(".ImageList.imgs: object expected");
                message.imgs[i] = $root.Image.fromObject(object.imgs[i]);
            }
        }
        if (object.width != null)
            message.width = object.width | 0;
        return message;
    };

    /**
     * Creates a plain object from an ImageList message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImageList
     * @static
     * @param {ImageList} message ImageList
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImageList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.imgs = [];
        if (options.defaults)
            object.width = 0;
        if (message.imgs && message.imgs.length) {
            object.imgs = [];
            for (let j = 0; j < message.imgs.length; ++j)
                object.imgs[j] = $root.Image.toObject(message.imgs[j], options);
        }
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        return object;
    };

    /**
     * Converts this ImageList to JSON.
     * @function toJSON
     * @memberof ImageList
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImageList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ImageList
     * @function getTypeUrl
     * @memberof ImageList
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ImageList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ImageList";
    };

    return ImageList;
})();

export const Json = $root.Json = (() => {

    /**
     * Properties of a Json.
     * @exports IJson
     * @interface IJson
     * @property {string|null} [body] Json body
     * @property {boolean|null} [expanded] Json expanded
     * @property {number|null} [maxExpandDepth] Json maxExpandDepth
     */

    /**
     * Constructs a new Json.
     * @exports Json
     * @classdesc Represents a Json.
     * @implements IJson
     * @constructor
     * @param {IJson=} [properties] Properties to set
     */
    function Json(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Json body.
     * @member {string} body
     * @memberof Json
     * @instance
     */
    Json.prototype.body = "";

    /**
     * Json expanded.
     * @member {boolean} expanded
     * @memberof Json
     * @instance
     */
    Json.prototype.expanded = false;

    /**
     * Json maxExpandDepth.
     * @member {number|null|undefined} maxExpandDepth
     * @memberof Json
     * @instance
     */
    Json.prototype.maxExpandDepth = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Json.prototype, "_maxExpandDepth", {
        get: $util.oneOfGetter($oneOfFields = ["maxExpandDepth"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Json instance using the specified properties.
     * @function create
     * @memberof Json
     * @static
     * @param {IJson=} [properties] Properties to set
     * @returns {Json} Json instance
     */
    Json.create = function create(properties) {
        return new Json(properties);
    };

    /**
     * Encodes the specified Json message. Does not implicitly {@link Json.verify|verify} messages.
     * @function encode
     * @memberof Json
     * @static
     * @param {IJson} message Json message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Json.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        if (message.expanded != null && Object.hasOwnProperty.call(message, "expanded"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.expanded);
        if (message.maxExpandDepth != null && Object.hasOwnProperty.call(message, "maxExpandDepth"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxExpandDepth);
        return writer;
    };

    /**
     * Encodes the specified Json message, length delimited. Does not implicitly {@link Json.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Json
     * @static
     * @param {IJson} message Json message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Json.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Json message from the specified reader or buffer.
     * @function decode
     * @memberof Json
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Json} Json
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Json.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Json();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            case 2: {
                    message.expanded = reader.bool();
                    break;
                }
            case 3: {
                    message.maxExpandDepth = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Json message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Json
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Json} Json
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Json.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Json message.
     * @function verify
     * @memberof Json
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Json.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.expanded != null && message.hasOwnProperty("expanded"))
            if (typeof message.expanded !== "boolean")
                return "expanded: boolean expected";
        if (message.maxExpandDepth != null && message.hasOwnProperty("maxExpandDepth")) {
            properties._maxExpandDepth = 1;
            if (!$util.isInteger(message.maxExpandDepth))
                return "maxExpandDepth: integer expected";
        }
        return null;
    };

    /**
     * Creates a Json message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Json
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Json} Json
     */
    Json.fromObject = function fromObject(object) {
        if (object instanceof $root.Json)
            return object;
        let message = new $root.Json();
        if (object.body != null)
            message.body = String(object.body);
        if (object.expanded != null)
            message.expanded = Boolean(object.expanded);
        if (object.maxExpandDepth != null)
            message.maxExpandDepth = object.maxExpandDepth | 0;
        return message;
    };

    /**
     * Creates a plain object from a Json message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Json
     * @static
     * @param {Json} message Json
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Json.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.body = "";
            object.expanded = false;
        }
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.expanded != null && message.hasOwnProperty("expanded"))
            object.expanded = message.expanded;
        if (message.maxExpandDepth != null && message.hasOwnProperty("maxExpandDepth")) {
            object.maxExpandDepth = message.maxExpandDepth;
            if (options.oneofs)
                object._maxExpandDepth = "maxExpandDepth";
        }
        return object;
    };

    /**
     * Converts this Json to JSON.
     * @function toJSON
     * @memberof Json
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Json.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Json
     * @function getTypeUrl
     * @memberof Json
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Json.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Json";
    };

    return Json;
})();

export const LinkButton = $root.LinkButton = (() => {

    /**
     * Properties of a LinkButton.
     * @exports ILinkButton
     * @interface ILinkButton
     * @property {string|null} [label] LinkButton label
     * @property {string|null} [help] LinkButton help
     * @property {string|null} [url] LinkButton url
     * @property {boolean|null} [disabled] LinkButton disabled
     * @property {boolean|null} [useContainerWidth] LinkButton useContainerWidth
     * @property {string|null} [type] LinkButton type
     * @property {string|null} [icon] LinkButton icon
     */

    /**
     * Constructs a new LinkButton.
     * @exports LinkButton
     * @classdesc Represents a LinkButton.
     * @implements ILinkButton
     * @constructor
     * @param {ILinkButton=} [properties] Properties to set
     */
    function LinkButton(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LinkButton label.
     * @member {string} label
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.label = "";

    /**
     * LinkButton help.
     * @member {string} help
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.help = "";

    /**
     * LinkButton url.
     * @member {string} url
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.url = "";

    /**
     * LinkButton disabled.
     * @member {boolean} disabled
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.disabled = false;

    /**
     * LinkButton useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.useContainerWidth = false;

    /**
     * LinkButton type.
     * @member {string} type
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.type = "";

    /**
     * LinkButton icon.
     * @member {string} icon
     * @memberof LinkButton
     * @instance
     */
    LinkButton.prototype.icon = "";

    /**
     * Creates a new LinkButton instance using the specified properties.
     * @function create
     * @memberof LinkButton
     * @static
     * @param {ILinkButton=} [properties] Properties to set
     * @returns {LinkButton} LinkButton instance
     */
    LinkButton.create = function create(properties) {
        return new LinkButton(properties);
    };

    /**
     * Encodes the specified LinkButton message. Does not implicitly {@link LinkButton.verify|verify} messages.
     * @function encode
     * @memberof LinkButton
     * @static
     * @param {ILinkButton} message LinkButton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LinkButton.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.disabled);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.useContainerWidth);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.icon);
        return writer;
    };

    /**
     * Encodes the specified LinkButton message, length delimited. Does not implicitly {@link LinkButton.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LinkButton
     * @static
     * @param {ILinkButton} message LinkButton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LinkButton.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LinkButton message from the specified reader or buffer.
     * @function decode
     * @memberof LinkButton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LinkButton} LinkButton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LinkButton.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LinkButton();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 6: {
                    message.url = reader.string();
                    break;
                }
            case 7: {
                    message.disabled = reader.bool();
                    break;
                }
            case 8: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 9: {
                    message.type = reader.string();
                    break;
                }
            case 10: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LinkButton message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LinkButton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LinkButton} LinkButton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LinkButton.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LinkButton message.
     * @function verify
     * @memberof LinkButton
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LinkButton.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        return null;
    };

    /**
     * Creates a LinkButton message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LinkButton
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LinkButton} LinkButton
     */
    LinkButton.fromObject = function fromObject(object) {
        if (object instanceof $root.LinkButton)
            return object;
        let message = new $root.LinkButton();
        if (object.label != null)
            message.label = String(object.label);
        if (object.help != null)
            message.help = String(object.help);
        if (object.url != null)
            message.url = String(object.url);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.type != null)
            message.type = String(object.type);
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    /**
     * Creates a plain object from a LinkButton message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LinkButton
     * @static
     * @param {LinkButton} message LinkButton
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LinkButton.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.label = "";
            object.help = "";
            object.url = "";
            object.disabled = false;
            object.useContainerWidth = false;
            object.type = "";
            object.icon = "";
        }
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    /**
     * Converts this LinkButton to JSON.
     * @function toJSON
     * @memberof LinkButton
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LinkButton.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LinkButton
     * @function getTypeUrl
     * @memberof LinkButton
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LinkButton.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LinkButton";
    };

    return LinkButton;
})();

export const NumberInput = $root.NumberInput = (() => {

    /**
     * Properties of a NumberInput.
     * @exports INumberInput
     * @interface INumberInput
     * @property {string|null} [id] NumberInput id
     * @property {string|null} [label] NumberInput label
     * @property {string|null} [formId] NumberInput formId
     * @property {string|null} [format] NumberInput format
     * @property {boolean|null} [hasMin] NumberInput hasMin
     * @property {boolean|null} [hasMax] NumberInput hasMax
     * @property {NumberInput.DataType|null} [dataType] NumberInput dataType
     * @property {number|null} ["default"] NumberInput default
     * @property {number|null} [step] NumberInput step
     * @property {number|null} [min] NumberInput min
     * @property {number|null} [max] NumberInput max
     * @property {string|null} [help] NumberInput help
     * @property {number|null} [value] NumberInput value
     * @property {boolean|null} [setValue] NumberInput setValue
     * @property {boolean|null} [disabled] NumberInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] NumberInput labelVisibility
     * @property {string|null} [placeholder] NumberInput placeholder
     */

    /**
     * Constructs a new NumberInput.
     * @exports NumberInput
     * @classdesc Represents a NumberInput.
     * @implements INumberInput
     * @constructor
     * @param {INumberInput=} [properties] Properties to set
     */
    function NumberInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NumberInput id.
     * @member {string} id
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.id = "";

    /**
     * NumberInput label.
     * @member {string} label
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.label = "";

    /**
     * NumberInput formId.
     * @member {string} formId
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.formId = "";

    /**
     * NumberInput format.
     * @member {string} format
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.format = "";

    /**
     * NumberInput hasMin.
     * @member {boolean} hasMin
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.hasMin = false;

    /**
     * NumberInput hasMax.
     * @member {boolean} hasMax
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.hasMax = false;

    /**
     * NumberInput dataType.
     * @member {NumberInput.DataType} dataType
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.dataType = 0;

    /**
     * NumberInput default.
     * @member {number|null|undefined} default
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype["default"] = null;

    /**
     * NumberInput step.
     * @member {number} step
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.step = 0;

    /**
     * NumberInput min.
     * @member {number} min
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.min = 0;

    /**
     * NumberInput max.
     * @member {number} max
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.max = 0;

    /**
     * NumberInput help.
     * @member {string} help
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.help = "";

    /**
     * NumberInput value.
     * @member {number|null|undefined} value
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.value = null;

    /**
     * NumberInput setValue.
     * @member {boolean} setValue
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.setValue = false;

    /**
     * NumberInput disabled.
     * @member {boolean} disabled
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.disabled = false;

    /**
     * NumberInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.labelVisibility = null;

    /**
     * NumberInput placeholder.
     * @member {string} placeholder
     * @memberof NumberInput
     * @instance
     */
    NumberInput.prototype.placeholder = "";

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(NumberInput.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(NumberInput.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new NumberInput instance using the specified properties.
     * @function create
     * @memberof NumberInput
     * @static
     * @param {INumberInput=} [properties] Properties to set
     * @returns {NumberInput} NumberInput instance
     */
    NumberInput.create = function create(properties) {
        return new NumberInput(properties);
    };

    /**
     * Encodes the specified NumberInput message. Does not implicitly {@link NumberInput.verify|verify} messages.
     * @function encode
     * @memberof NumberInput
     * @static
     * @param {INumberInput} message NumberInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NumberInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.formId);
        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.format);
        if (message.hasMin != null && Object.hasOwnProperty.call(message, "hasMin"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.hasMin);
        if (message.hasMax != null && Object.hasOwnProperty.call(message, "hasMax"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hasMax);
        if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.dataType);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 14, wireType 1 =*/113).double(message["default"]);
        if (message.step != null && Object.hasOwnProperty.call(message, "step"))
            writer.uint32(/* id 15, wireType 1 =*/121).double(message.step);
        if (message.min != null && Object.hasOwnProperty.call(message, "min"))
            writer.uint32(/* id 16, wireType 1 =*/129).double(message.min);
        if (message.max != null && Object.hasOwnProperty.call(message, "max"))
            writer.uint32(/* id 17, wireType 1 =*/137).double(message.max);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.help);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 19, wireType 1 =*/153).double(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.placeholder);
        return writer;
    };

    /**
     * Encodes the specified NumberInput message, length delimited. Does not implicitly {@link NumberInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof NumberInput
     * @static
     * @param {INumberInput} message NumberInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NumberInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NumberInput message from the specified reader or buffer.
     * @function decode
     * @memberof NumberInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NumberInput} NumberInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NumberInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NumberInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message.formId = reader.string();
                    break;
                }
            case 8: {
                    message.format = reader.string();
                    break;
                }
            case 11: {
                    message.hasMin = reader.bool();
                    break;
                }
            case 12: {
                    message.hasMax = reader.bool();
                    break;
                }
            case 13: {
                    message.dataType = reader.int32();
                    break;
                }
            case 14: {
                    message["default"] = reader.double();
                    break;
                }
            case 15: {
                    message.step = reader.double();
                    break;
                }
            case 16: {
                    message.min = reader.double();
                    break;
                }
            case 17: {
                    message.max = reader.double();
                    break;
                }
            case 18: {
                    message.help = reader.string();
                    break;
                }
            case 19: {
                    message.value = reader.double();
                    break;
                }
            case 20: {
                    message.setValue = reader.bool();
                    break;
                }
            case 21: {
                    message.disabled = reader.bool();
                    break;
                }
            case 22: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 23: {
                    message.placeholder = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a NumberInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof NumberInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {NumberInput} NumberInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NumberInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NumberInput message.
     * @function verify
     * @memberof NumberInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NumberInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.format != null && message.hasOwnProperty("format"))
            if (!$util.isString(message.format))
                return "format: string expected";
        if (message.hasMin != null && message.hasOwnProperty("hasMin"))
            if (typeof message.hasMin !== "boolean")
                return "hasMin: boolean expected";
        if (message.hasMax != null && message.hasOwnProperty("hasMax"))
            if (typeof message.hasMax !== "boolean")
                return "hasMax: boolean expected";
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            switch (message.dataType) {
            default:
                return "dataType: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (typeof message["default"] !== "number")
                return "default: number expected";
        }
        if (message.step != null && message.hasOwnProperty("step"))
            if (typeof message.step !== "number")
                return "step: number expected";
        if (message.min != null && message.hasOwnProperty("min"))
            if (typeof message.min !== "number")
                return "min: number expected";
        if (message.max != null && message.hasOwnProperty("max"))
            if (typeof message.max !== "number")
                return "max: number expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (typeof message.value !== "number")
                return "value: number expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        return null;
    };

    /**
     * Creates a NumberInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof NumberInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {NumberInput} NumberInput
     */
    NumberInput.fromObject = function fromObject(object) {
        if (object instanceof $root.NumberInput)
            return object;
        let message = new $root.NumberInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.format != null)
            message.format = String(object.format);
        if (object.hasMin != null)
            message.hasMin = Boolean(object.hasMin);
        if (object.hasMax != null)
            message.hasMax = Boolean(object.hasMax);
        switch (object.dataType) {
        default:
            if (typeof object.dataType === "number") {
                message.dataType = object.dataType;
                break;
            }
            break;
        case "INT":
        case 0:
            message.dataType = 0;
            break;
        case "FLOAT":
        case 1:
            message.dataType = 1;
            break;
        }
        if (object["default"] != null)
            message["default"] = Number(object["default"]);
        if (object.step != null)
            message.step = Number(object.step);
        if (object.min != null)
            message.min = Number(object.min);
        if (object.max != null)
            message.max = Number(object.max);
        if (object.help != null)
            message.help = String(object.help);
        if (object.value != null)
            message.value = Number(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".NumberInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        return message;
    };

    /**
     * Creates a plain object from a NumberInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof NumberInput
     * @static
     * @param {NumberInput} message NumberInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NumberInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.formId = "";
            object.format = "";
            object.hasMin = false;
            object.hasMax = false;
            object.dataType = options.enums === String ? "INT" : 0;
            object.step = 0;
            object.min = 0;
            object.max = 0;
            object.help = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            object.placeholder = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.format != null && message.hasOwnProperty("format"))
            object.format = message.format;
        if (message.hasMin != null && message.hasOwnProperty("hasMin"))
            object.hasMin = message.hasMin;
        if (message.hasMax != null && message.hasOwnProperty("hasMax"))
            object.hasMax = message.hasMax;
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            object.dataType = options.enums === String ? $root.NumberInput.DataType[message.dataType] === undefined ? message.dataType : $root.NumberInput.DataType[message.dataType] : message.dataType;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = options.json && !isFinite(message["default"]) ? String(message["default"]) : message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.step != null && message.hasOwnProperty("step"))
            object.step = options.json && !isFinite(message.step) ? String(message.step) : message.step;
        if (message.min != null && message.hasOwnProperty("min"))
            object.min = options.json && !isFinite(message.min) ? String(message.min) : message.min;
        if (message.max != null && message.hasOwnProperty("max"))
            object.max = options.json && !isFinite(message.max) ? String(message.max) : message.max;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        return object;
    };

    /**
     * Converts this NumberInput to JSON.
     * @function toJSON
     * @memberof NumberInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NumberInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NumberInput
     * @function getTypeUrl
     * @memberof NumberInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NumberInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NumberInput";
    };

    /**
     * DataType enum.
     * @name NumberInput.DataType
     * @enum {number}
     * @property {number} INT=0 INT value
     * @property {number} FLOAT=1 FLOAT value
     */
    NumberInput.DataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INT"] = 0;
        values[valuesById[1] = "FLOAT"] = 1;
        return values;
    })();

    return NumberInput;
})();

export const Markdown = $root.Markdown = (() => {

    /**
     * Properties of a Markdown.
     * @exports IMarkdown
     * @interface IMarkdown
     * @property {string|null} [body] Markdown body
     * @property {boolean|null} [allowHtml] Markdown allowHtml
     * @property {boolean|null} [isCaption] Markdown isCaption
     * @property {Markdown.Type|null} [elementType] Markdown elementType
     * @property {string|null} [help] Markdown help
     */

    /**
     * Constructs a new Markdown.
     * @exports Markdown
     * @classdesc Represents a Markdown.
     * @implements IMarkdown
     * @constructor
     * @param {IMarkdown=} [properties] Properties to set
     */
    function Markdown(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Markdown body.
     * @member {string} body
     * @memberof Markdown
     * @instance
     */
    Markdown.prototype.body = "";

    /**
     * Markdown allowHtml.
     * @member {boolean} allowHtml
     * @memberof Markdown
     * @instance
     */
    Markdown.prototype.allowHtml = false;

    /**
     * Markdown isCaption.
     * @member {boolean} isCaption
     * @memberof Markdown
     * @instance
     */
    Markdown.prototype.isCaption = false;

    /**
     * Markdown elementType.
     * @member {Markdown.Type} elementType
     * @memberof Markdown
     * @instance
     */
    Markdown.prototype.elementType = 0;

    /**
     * Markdown help.
     * @member {string} help
     * @memberof Markdown
     * @instance
     */
    Markdown.prototype.help = "";

    /**
     * Creates a new Markdown instance using the specified properties.
     * @function create
     * @memberof Markdown
     * @static
     * @param {IMarkdown=} [properties] Properties to set
     * @returns {Markdown} Markdown instance
     */
    Markdown.create = function create(properties) {
        return new Markdown(properties);
    };

    /**
     * Encodes the specified Markdown message. Does not implicitly {@link Markdown.verify|verify} messages.
     * @function encode
     * @memberof Markdown
     * @static
     * @param {IMarkdown} message Markdown message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Markdown.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        if (message.allowHtml != null && Object.hasOwnProperty.call(message, "allowHtml"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowHtml);
        if (message.isCaption != null && Object.hasOwnProperty.call(message, "isCaption"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isCaption);
        if (message.elementType != null && Object.hasOwnProperty.call(message, "elementType"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.elementType);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.help);
        return writer;
    };

    /**
     * Encodes the specified Markdown message, length delimited. Does not implicitly {@link Markdown.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Markdown
     * @static
     * @param {IMarkdown} message Markdown message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Markdown.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Markdown message from the specified reader or buffer.
     * @function decode
     * @memberof Markdown
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Markdown} Markdown
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Markdown.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Markdown();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            case 2: {
                    message.allowHtml = reader.bool();
                    break;
                }
            case 3: {
                    message.isCaption = reader.bool();
                    break;
                }
            case 4: {
                    message.elementType = reader.int32();
                    break;
                }
            case 5: {
                    message.help = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Markdown message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Markdown
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Markdown} Markdown
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Markdown.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Markdown message.
     * @function verify
     * @memberof Markdown
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Markdown.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.allowHtml != null && message.hasOwnProperty("allowHtml"))
            if (typeof message.allowHtml !== "boolean")
                return "allowHtml: boolean expected";
        if (message.isCaption != null && message.hasOwnProperty("isCaption"))
            if (typeof message.isCaption !== "boolean")
                return "isCaption: boolean expected";
        if (message.elementType != null && message.hasOwnProperty("elementType"))
            switch (message.elementType) {
            default:
                return "elementType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        return null;
    };

    /**
     * Creates a Markdown message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Markdown
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Markdown} Markdown
     */
    Markdown.fromObject = function fromObject(object) {
        if (object instanceof $root.Markdown)
            return object;
        let message = new $root.Markdown();
        if (object.body != null)
            message.body = String(object.body);
        if (object.allowHtml != null)
            message.allowHtml = Boolean(object.allowHtml);
        if (object.isCaption != null)
            message.isCaption = Boolean(object.isCaption);
        switch (object.elementType) {
        default:
            if (typeof object.elementType === "number") {
                message.elementType = object.elementType;
                break;
            }
            break;
        case "UNSPECIFIED":
        case 0:
            message.elementType = 0;
            break;
        case "NATIVE":
        case 1:
            message.elementType = 1;
            break;
        case "CAPTION":
        case 2:
            message.elementType = 2;
            break;
        case "CODE":
        case 3:
            message.elementType = 3;
            break;
        case "LATEX":
        case 4:
            message.elementType = 4;
            break;
        case "DIVIDER":
        case 5:
            message.elementType = 5;
            break;
        }
        if (object.help != null)
            message.help = String(object.help);
        return message;
    };

    /**
     * Creates a plain object from a Markdown message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Markdown
     * @static
     * @param {Markdown} message Markdown
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Markdown.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.body = "";
            object.allowHtml = false;
            object.isCaption = false;
            object.elementType = options.enums === String ? "UNSPECIFIED" : 0;
            object.help = "";
        }
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.allowHtml != null && message.hasOwnProperty("allowHtml"))
            object.allowHtml = message.allowHtml;
        if (message.isCaption != null && message.hasOwnProperty("isCaption"))
            object.isCaption = message.isCaption;
        if (message.elementType != null && message.hasOwnProperty("elementType"))
            object.elementType = options.enums === String ? $root.Markdown.Type[message.elementType] === undefined ? message.elementType : $root.Markdown.Type[message.elementType] : message.elementType;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        return object;
    };

    /**
     * Converts this Markdown to JSON.
     * @function toJSON
     * @memberof Markdown
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Markdown.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Markdown
     * @function getTypeUrl
     * @memberof Markdown
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Markdown.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Markdown";
    };

    /**
     * Type enum.
     * @name Markdown.Type
     * @enum {number}
     * @property {number} UNSPECIFIED=0 UNSPECIFIED value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} CAPTION=2 CAPTION value
     * @property {number} CODE=3 CODE value
     * @property {number} LATEX=4 LATEX value
     * @property {number} DIVIDER=5 DIVIDER value
     */
    Markdown.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "CAPTION"] = 2;
        values[valuesById[3] = "CODE"] = 3;
        values[valuesById[4] = "LATEX"] = 4;
        values[valuesById[5] = "DIVIDER"] = 5;
        return values;
    })();

    return Markdown;
})();

export const Metric = $root.Metric = (() => {

    /**
     * Properties of a Metric.
     * @exports IMetric
     * @interface IMetric
     * @property {string|null} [label] Metric label
     * @property {string|null} [body] Metric body
     * @property {string|null} [delta] Metric delta
     * @property {Metric.MetricDirection|null} [direction] Metric direction
     * @property {Metric.MetricColor|null} [color] Metric color
     * @property {string|null} [help] Metric help
     * @property {ILabelVisibilityMessage|null} [labelVisibility] Metric labelVisibility
     * @property {boolean|null} [showBorder] Metric showBorder
     */

    /**
     * Constructs a new Metric.
     * @exports Metric
     * @classdesc Represents a Metric.
     * @implements IMetric
     * @constructor
     * @param {IMetric=} [properties] Properties to set
     */
    function Metric(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Metric label.
     * @member {string} label
     * @memberof Metric
     * @instance
     */
    Metric.prototype.label = "";

    /**
     * Metric body.
     * @member {string} body
     * @memberof Metric
     * @instance
     */
    Metric.prototype.body = "";

    /**
     * Metric delta.
     * @member {string} delta
     * @memberof Metric
     * @instance
     */
    Metric.prototype.delta = "";

    /**
     * Metric direction.
     * @member {Metric.MetricDirection} direction
     * @memberof Metric
     * @instance
     */
    Metric.prototype.direction = 0;

    /**
     * Metric color.
     * @member {Metric.MetricColor} color
     * @memberof Metric
     * @instance
     */
    Metric.prototype.color = 0;

    /**
     * Metric help.
     * @member {string} help
     * @memberof Metric
     * @instance
     */
    Metric.prototype.help = "";

    /**
     * Metric labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof Metric
     * @instance
     */
    Metric.prototype.labelVisibility = null;

    /**
     * Metric showBorder.
     * @member {boolean} showBorder
     * @memberof Metric
     * @instance
     */
    Metric.prototype.showBorder = false;

    /**
     * Creates a new Metric instance using the specified properties.
     * @function create
     * @memberof Metric
     * @static
     * @param {IMetric=} [properties] Properties to set
     * @returns {Metric} Metric instance
     */
    Metric.create = function create(properties) {
        return new Metric(properties);
    };

    /**
     * Encodes the specified Metric message. Does not implicitly {@link Metric.verify|verify} messages.
     * @function encode
     * @memberof Metric
     * @static
     * @param {IMetric} message Metric message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Metric.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.body);
        if (message.delta != null && Object.hasOwnProperty.call(message, "delta"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.delta);
        if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.direction);
        if (message.color != null && Object.hasOwnProperty.call(message, "color"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.color);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.help);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.showBorder != null && Object.hasOwnProperty.call(message, "showBorder"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.showBorder);
        return writer;
    };

    /**
     * Encodes the specified Metric message, length delimited. Does not implicitly {@link Metric.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Metric
     * @static
     * @param {IMetric} message Metric message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Metric.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Metric message from the specified reader or buffer.
     * @function decode
     * @memberof Metric
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Metric} Metric
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Metric.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Metric();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.label = reader.string();
                    break;
                }
            case 2: {
                    message.body = reader.string();
                    break;
                }
            case 3: {
                    message.delta = reader.string();
                    break;
                }
            case 4: {
                    message.direction = reader.int32();
                    break;
                }
            case 5: {
                    message.color = reader.int32();
                    break;
                }
            case 6: {
                    message.help = reader.string();
                    break;
                }
            case 7: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.showBorder = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Metric message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Metric
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Metric} Metric
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Metric.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Metric message.
     * @function verify
     * @memberof Metric
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Metric.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.delta != null && message.hasOwnProperty("delta"))
            if (!$util.isString(message.delta))
                return "delta: string expected";
        if (message.direction != null && message.hasOwnProperty("direction"))
            switch (message.direction) {
            default:
                return "direction: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.color != null && message.hasOwnProperty("color"))
            switch (message.color) {
            default:
                return "color: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.showBorder != null && message.hasOwnProperty("showBorder"))
            if (typeof message.showBorder !== "boolean")
                return "showBorder: boolean expected";
        return null;
    };

    /**
     * Creates a Metric message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Metric
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Metric} Metric
     */
    Metric.fromObject = function fromObject(object) {
        if (object instanceof $root.Metric)
            return object;
        let message = new $root.Metric();
        if (object.label != null)
            message.label = String(object.label);
        if (object.body != null)
            message.body = String(object.body);
        if (object.delta != null)
            message.delta = String(object.delta);
        switch (object.direction) {
        default:
            if (typeof object.direction === "number") {
                message.direction = object.direction;
                break;
            }
            break;
        case "DOWN":
        case 0:
            message.direction = 0;
            break;
        case "UP":
        case 1:
            message.direction = 1;
            break;
        case "NONE":
        case 2:
            message.direction = 2;
            break;
        }
        switch (object.color) {
        default:
            if (typeof object.color === "number") {
                message.color = object.color;
                break;
            }
            break;
        case "RED":
        case 0:
            message.color = 0;
            break;
        case "GREEN":
        case 1:
            message.color = 1;
            break;
        case "GRAY":
        case 2:
            message.color = 2;
            break;
        }
        if (object.help != null)
            message.help = String(object.help);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".Metric.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.showBorder != null)
            message.showBorder = Boolean(object.showBorder);
        return message;
    };

    /**
     * Creates a plain object from a Metric message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Metric
     * @static
     * @param {Metric} message Metric
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Metric.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.label = "";
            object.body = "";
            object.delta = "";
            object.direction = options.enums === String ? "DOWN" : 0;
            object.color = options.enums === String ? "RED" : 0;
            object.help = "";
            object.labelVisibility = null;
            object.showBorder = false;
        }
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.delta != null && message.hasOwnProperty("delta"))
            object.delta = message.delta;
        if (message.direction != null && message.hasOwnProperty("direction"))
            object.direction = options.enums === String ? $root.Metric.MetricDirection[message.direction] === undefined ? message.direction : $root.Metric.MetricDirection[message.direction] : message.direction;
        if (message.color != null && message.hasOwnProperty("color"))
            object.color = options.enums === String ? $root.Metric.MetricColor[message.color] === undefined ? message.color : $root.Metric.MetricColor[message.color] : message.color;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.showBorder != null && message.hasOwnProperty("showBorder"))
            object.showBorder = message.showBorder;
        return object;
    };

    /**
     * Converts this Metric to JSON.
     * @function toJSON
     * @memberof Metric
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Metric.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Metric
     * @function getTypeUrl
     * @memberof Metric
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Metric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Metric";
    };

    /**
     * MetricColor enum.
     * @name Metric.MetricColor
     * @enum {number}
     * @property {number} RED=0 RED value
     * @property {number} GREEN=1 GREEN value
     * @property {number} GRAY=2 GRAY value
     */
    Metric.MetricColor = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RED"] = 0;
        values[valuesById[1] = "GREEN"] = 1;
        values[valuesById[2] = "GRAY"] = 2;
        return values;
    })();

    /**
     * MetricDirection enum.
     * @name Metric.MetricDirection
     * @enum {number}
     * @property {number} DOWN=0 DOWN value
     * @property {number} UP=1 UP value
     * @property {number} NONE=2 NONE value
     */
    Metric.MetricDirection = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DOWN"] = 0;
        values[valuesById[1] = "UP"] = 1;
        values[valuesById[2] = "NONE"] = 2;
        return values;
    })();

    return Metric;
})();

export const MultiSelect = $root.MultiSelect = (() => {

    /**
     * Properties of a MultiSelect.
     * @exports IMultiSelect
     * @interface IMultiSelect
     * @property {string|null} [id] MultiSelect id
     * @property {string|null} [label] MultiSelect label
     * @property {Array.<number>|null} ["default"] MultiSelect default
     * @property {Array.<string>|null} [options] MultiSelect options
     * @property {string|null} [help] MultiSelect help
     * @property {string|null} [formId] MultiSelect formId
     * @property {Array.<number>|null} [value] MultiSelect value
     * @property {boolean|null} [setValue] MultiSelect setValue
     * @property {boolean|null} [disabled] MultiSelect disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] MultiSelect labelVisibility
     * @property {number|null} [maxSelections] MultiSelect maxSelections
     * @property {string|null} [placeholder] MultiSelect placeholder
     */

    /**
     * Constructs a new MultiSelect.
     * @exports MultiSelect
     * @classdesc Represents a MultiSelect.
     * @implements IMultiSelect
     * @constructor
     * @param {IMultiSelect=} [properties] Properties to set
     */
    function MultiSelect(properties) {
        this["default"] = [];
        this.options = [];
        this.value = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiSelect id.
     * @member {string} id
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.id = "";

    /**
     * MultiSelect label.
     * @member {string} label
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.label = "";

    /**
     * MultiSelect default.
     * @member {Array.<number>} default
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype["default"] = $util.emptyArray;

    /**
     * MultiSelect options.
     * @member {Array.<string>} options
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.options = $util.emptyArray;

    /**
     * MultiSelect help.
     * @member {string} help
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.help = "";

    /**
     * MultiSelect formId.
     * @member {string} formId
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.formId = "";

    /**
     * MultiSelect value.
     * @member {Array.<number>} value
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.value = $util.emptyArray;

    /**
     * MultiSelect setValue.
     * @member {boolean} setValue
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.setValue = false;

    /**
     * MultiSelect disabled.
     * @member {boolean} disabled
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.disabled = false;

    /**
     * MultiSelect labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.labelVisibility = null;

    /**
     * MultiSelect maxSelections.
     * @member {number} maxSelections
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.maxSelections = 0;

    /**
     * MultiSelect placeholder.
     * @member {string} placeholder
     * @memberof MultiSelect
     * @instance
     */
    MultiSelect.prototype.placeholder = "";

    /**
     * Creates a new MultiSelect instance using the specified properties.
     * @function create
     * @memberof MultiSelect
     * @static
     * @param {IMultiSelect=} [properties] Properties to set
     * @returns {MultiSelect} MultiSelect instance
     */
    MultiSelect.create = function create(properties) {
        return new MultiSelect(properties);
    };

    /**
     * Encodes the specified MultiSelect message. Does not implicitly {@link MultiSelect.verify|verify} messages.
     * @function encode
     * @memberof MultiSelect
     * @static
     * @param {IMultiSelect} message MultiSelect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiSelect.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && message["default"].length) {
            writer.uint32(/* id 3, wireType 2 =*/26).fork();
            for (let i = 0; i < message["default"].length; ++i)
                writer.int32(message["default"][i]);
            writer.ldelim();
        }
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.options[i]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.formId);
        if (message.value != null && message.value.length) {
            writer.uint32(/* id 7, wireType 2 =*/58).fork();
            for (let i = 0; i < message.value.length; ++i)
                writer.int32(message.value[i]);
            writer.ldelim();
        }
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.maxSelections != null && Object.hasOwnProperty.call(message, "maxSelections"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.maxSelections);
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.placeholder);
        return writer;
    };

    /**
     * Encodes the specified MultiSelect message, length delimited. Does not implicitly {@link MultiSelect.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MultiSelect
     * @static
     * @param {IMultiSelect} message MultiSelect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiSelect.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiSelect message from the specified reader or buffer.
     * @function decode
     * @memberof MultiSelect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MultiSelect} MultiSelect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiSelect.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiSelect();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    if (!(message["default"] && message["default"].length))
                        message["default"] = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["default"].push(reader.int32());
                    } else
                        message["default"].push(reader.int32());
                    break;
                }
            case 4: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push(reader.string());
                    break;
                }
            case 5: {
                    message.help = reader.string();
                    break;
                }
            case 6: {
                    message.formId = reader.string();
                    break;
                }
            case 7: {
                    if (!(message.value && message.value.length))
                        message.value = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.value.push(reader.int32());
                    } else
                        message.value.push(reader.int32());
                    break;
                }
            case 8: {
                    message.setValue = reader.bool();
                    break;
                }
            case 9: {
                    message.disabled = reader.bool();
                    break;
                }
            case 10: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.maxSelections = reader.int32();
                    break;
                }
            case 12: {
                    message.placeholder = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MultiSelect message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MultiSelect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MultiSelect} MultiSelect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiSelect.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiSelect message.
     * @function verify
     * @memberof MultiSelect
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiSelect.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            if (!Array.isArray(message["default"]))
                return "default: array expected";
            for (let i = 0; i < message["default"].length; ++i)
                if (!$util.isInteger(message["default"][i]))
                    return "default: integer[] expected";
        }
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i)
                if (!$util.isString(message.options[i]))
                    return "options: string[] expected";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            if (!Array.isArray(message.value))
                return "value: array expected";
            for (let i = 0; i < message.value.length; ++i)
                if (!$util.isInteger(message.value[i]))
                    return "value: integer[] expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
            if (!$util.isInteger(message.maxSelections))
                return "maxSelections: integer expected";
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        return null;
    };

    /**
     * Creates a MultiSelect message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MultiSelect
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MultiSelect} MultiSelect
     */
    MultiSelect.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiSelect)
            return object;
        let message = new $root.MultiSelect();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"]) {
            if (!Array.isArray(object["default"]))
                throw TypeError(".MultiSelect.default: array expected");
            message["default"] = [];
            for (let i = 0; i < object["default"].length; ++i)
                message["default"][i] = object["default"][i] | 0;
        }
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".MultiSelect.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i)
                message.options[i] = String(object.options[i]);
        }
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value) {
            if (!Array.isArray(object.value))
                throw TypeError(".MultiSelect.value: array expected");
            message.value = [];
            for (let i = 0; i < object.value.length; ++i)
                message.value[i] = object.value[i] | 0;
        }
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".MultiSelect.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.maxSelections != null)
            message.maxSelections = object.maxSelections | 0;
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        return message;
    };

    /**
     * Creates a plain object from a MultiSelect message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MultiSelect
     * @static
     * @param {MultiSelect} message MultiSelect
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiSelect.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object["default"] = [];
            object.options = [];
            object.value = [];
        }
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            object.maxSelections = 0;
            object.placeholder = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] && message["default"].length) {
            object["default"] = [];
            for (let j = 0; j < message["default"].length; ++j)
                object["default"][j] = message["default"][j];
        }
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = message.options[j];
        }
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value && message.value.length) {
            object.value = [];
            for (let j = 0; j < message.value.length; ++j)
                object.value[j] = message.value[j];
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
            object.maxSelections = message.maxSelections;
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        return object;
    };

    /**
     * Converts this MultiSelect to JSON.
     * @function toJSON
     * @memberof MultiSelect
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiSelect.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiSelect
     * @function getTypeUrl
     * @memberof MultiSelect
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiSelect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiSelect";
    };

    return MultiSelect;
})();

export const PageLink = $root.PageLink = (() => {

    /**
     * Properties of a PageLink.
     * @exports IPageLink
     * @interface IPageLink
     * @property {string|null} [page] PageLink page
     * @property {string|null} [label] PageLink label
     * @property {string|null} [icon] PageLink icon
     * @property {string|null} [pageScriptHash] PageLink pageScriptHash
     * @property {string|null} [help] PageLink help
     * @property {boolean|null} [useContainerWidth] PageLink useContainerWidth
     * @property {boolean|null} [disabled] PageLink disabled
     * @property {boolean|null} [external] PageLink external
     */

    /**
     * Constructs a new PageLink.
     * @exports PageLink
     * @classdesc Represents a PageLink.
     * @implements IPageLink
     * @constructor
     * @param {IPageLink=} [properties] Properties to set
     */
    function PageLink(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageLink page.
     * @member {string} page
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.page = "";

    /**
     * PageLink label.
     * @member {string} label
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.label = "";

    /**
     * PageLink icon.
     * @member {string} icon
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.icon = "";

    /**
     * PageLink pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.pageScriptHash = "";

    /**
     * PageLink help.
     * @member {string} help
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.help = "";

    /**
     * PageLink useContainerWidth.
     * @member {boolean|null|undefined} useContainerWidth
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.useContainerWidth = null;

    /**
     * PageLink disabled.
     * @member {boolean} disabled
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.disabled = false;

    /**
     * PageLink external.
     * @member {boolean} external
     * @memberof PageLink
     * @instance
     */
    PageLink.prototype.external = false;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(PageLink.prototype, "_useContainerWidth", {
        get: $util.oneOfGetter($oneOfFields = ["useContainerWidth"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new PageLink instance using the specified properties.
     * @function create
     * @memberof PageLink
     * @static
     * @param {IPageLink=} [properties] Properties to set
     * @returns {PageLink} PageLink instance
     */
    PageLink.create = function create(properties) {
        return new PageLink(properties);
    };

    /**
     * Encodes the specified PageLink message. Does not implicitly {@link PageLink.verify|verify} messages.
     * @function encode
     * @memberof PageLink
     * @static
     * @param {IPageLink} message PageLink message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageLink.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.page);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.icon);
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageScriptHash);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.help);
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.useContainerWidth);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.disabled);
        if (message.external != null && Object.hasOwnProperty.call(message, "external"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.external);
        return writer;
    };

    /**
     * Encodes the specified PageLink message, length delimited. Does not implicitly {@link PageLink.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PageLink
     * @static
     * @param {IPageLink} message PageLink message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageLink.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageLink message from the specified reader or buffer.
     * @function decode
     * @memberof PageLink
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PageLink} PageLink
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageLink.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageLink();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.page = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message.icon = reader.string();
                    break;
                }
            case 4: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 5: {
                    message.help = reader.string();
                    break;
                }
            case 6: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 7: {
                    message.disabled = reader.bool();
                    break;
                }
            case 8: {
                    message.external = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PageLink message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PageLink
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PageLink} PageLink
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageLink.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageLink message.
     * @function verify
     * @memberof PageLink
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageLink.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.page != null && message.hasOwnProperty("page"))
            if (!$util.isString(message.page))
                return "page: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth")) {
            properties._useContainerWidth = 1;
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        }
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.external != null && message.hasOwnProperty("external"))
            if (typeof message.external !== "boolean")
                return "external: boolean expected";
        return null;
    };

    /**
     * Creates a PageLink message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PageLink
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PageLink} PageLink
     */
    PageLink.fromObject = function fromObject(object) {
        if (object instanceof $root.PageLink)
            return object;
        let message = new $root.PageLink();
        if (object.page != null)
            message.page = String(object.page);
        if (object.label != null)
            message.label = String(object.label);
        if (object.icon != null)
            message.icon = String(object.icon);
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.help != null)
            message.help = String(object.help);
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.external != null)
            message.external = Boolean(object.external);
        return message;
    };

    /**
     * Creates a plain object from a PageLink message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PageLink
     * @static
     * @param {PageLink} message PageLink
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageLink.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.page = "";
            object.label = "";
            object.icon = "";
            object.pageScriptHash = "";
            object.help = "";
            object.disabled = false;
            object.external = false;
        }
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = message.page;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth")) {
            object.useContainerWidth = message.useContainerWidth;
            if (options.oneofs)
                object._useContainerWidth = "useContainerWidth";
        }
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.external != null && message.hasOwnProperty("external"))
            object.external = message.external;
        return object;
    };

    /**
     * Converts this PageLink to JSON.
     * @function toJSON
     * @memberof PageLink
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageLink.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PageLink
     * @function getTypeUrl
     * @memberof PageLink
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PageLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageLink";
    };

    return PageLink;
})();

export const PlotlyChart = $root.PlotlyChart = (() => {

    /**
     * Properties of a PlotlyChart.
     * @exports IPlotlyChart
     * @interface IPlotlyChart
     * @property {boolean|null} [useContainerWidth] PlotlyChart useContainerWidth
     * @property {string|null} [theme] PlotlyChart theme
     * @property {string|null} [id] PlotlyChart id
     * @property {Array.<PlotlyChart.SelectionMode>|null} [selectionMode] PlotlyChart selectionMode
     * @property {string|null} [formId] PlotlyChart formId
     * @property {string|null} [spec] PlotlyChart spec
     * @property {string|null} [config] PlotlyChart config
     * @property {string|null} [url] PlotlyChart url
     * @property {IFigure|null} [figure] PlotlyChart figure
     */

    /**
     * Constructs a new PlotlyChart.
     * @exports PlotlyChart
     * @classdesc Represents a PlotlyChart.
     * @implements IPlotlyChart
     * @constructor
     * @param {IPlotlyChart=} [properties] Properties to set
     */
    function PlotlyChart(properties) {
        this.selectionMode = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PlotlyChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.useContainerWidth = false;

    /**
     * PlotlyChart theme.
     * @member {string} theme
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.theme = "";

    /**
     * PlotlyChart id.
     * @member {string} id
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.id = "";

    /**
     * PlotlyChart selectionMode.
     * @member {Array.<PlotlyChart.SelectionMode>} selectionMode
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.selectionMode = $util.emptyArray;

    /**
     * PlotlyChart formId.
     * @member {string} formId
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.formId = "";

    /**
     * PlotlyChart spec.
     * @member {string} spec
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.spec = "";

    /**
     * PlotlyChart config.
     * @member {string} config
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.config = "";

    /**
     * PlotlyChart url.
     * @member {string|null|undefined} url
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.url = null;

    /**
     * PlotlyChart figure.
     * @member {IFigure|null|undefined} figure
     * @memberof PlotlyChart
     * @instance
     */
    PlotlyChart.prototype.figure = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * PlotlyChart chart.
     * @member {"url"|"figure"|undefined} chart
     * @memberof PlotlyChart
     * @instance
     */
    Object.defineProperty(PlotlyChart.prototype, "chart", {
        get: $util.oneOfGetter($oneOfFields = ["url", "figure"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new PlotlyChart instance using the specified properties.
     * @function create
     * @memberof PlotlyChart
     * @static
     * @param {IPlotlyChart=} [properties] Properties to set
     * @returns {PlotlyChart} PlotlyChart instance
     */
    PlotlyChart.create = function create(properties) {
        return new PlotlyChart(properties);
    };

    /**
     * Encodes the specified PlotlyChart message. Does not implicitly {@link PlotlyChart.verify|verify} messages.
     * @function encode
     * @memberof PlotlyChart
     * @static
     * @param {IPlotlyChart} message PlotlyChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PlotlyChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
        if (message.figure != null && Object.hasOwnProperty.call(message, "figure"))
            $root.Figure.encode(message.figure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useContainerWidth);
        if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.theme);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.id);
        if (message.selectionMode != null && message.selectionMode.length) {
            writer.uint32(/* id 8, wireType 2 =*/66).fork();
            for (let i = 0; i < message.selectionMode.length; ++i)
                writer.int32(message.selectionMode[i]);
            writer.ldelim();
        }
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.formId);
        if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.spec);
        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.config);
        return writer;
    };

    /**
     * Encodes the specified PlotlyChart message, length delimited. Does not implicitly {@link PlotlyChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PlotlyChart
     * @static
     * @param {IPlotlyChart} message PlotlyChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PlotlyChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PlotlyChart message from the specified reader or buffer.
     * @function decode
     * @memberof PlotlyChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PlotlyChart} PlotlyChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PlotlyChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlotlyChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 5: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            case 6: {
                    message.theme = reader.string();
                    break;
                }
            case 7: {
                    message.id = reader.string();
                    break;
                }
            case 8: {
                    if (!(message.selectionMode && message.selectionMode.length))
                        message.selectionMode = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.selectionMode.push(reader.int32());
                    } else
                        message.selectionMode.push(reader.int32());
                    break;
                }
            case 9: {
                    message.formId = reader.string();
                    break;
                }
            case 10: {
                    message.spec = reader.string();
                    break;
                }
            case 11: {
                    message.config = reader.string();
                    break;
                }
            case 1: {
                    message.url = reader.string();
                    break;
                }
            case 2: {
                    message.figure = $root.Figure.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PlotlyChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PlotlyChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PlotlyChart} PlotlyChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PlotlyChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PlotlyChart message.
     * @function verify
     * @memberof PlotlyChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PlotlyChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        if (message.theme != null && message.hasOwnProperty("theme"))
            if (!$util.isString(message.theme))
                return "theme: string expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.selectionMode != null && message.hasOwnProperty("selectionMode")) {
            if (!Array.isArray(message.selectionMode))
                return "selectionMode: array expected";
            for (let i = 0; i < message.selectionMode.length; ++i)
                switch (message.selectionMode[i]) {
                default:
                    return "selectionMode: enum value[] expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.spec != null && message.hasOwnProperty("spec"))
            if (!$util.isString(message.spec))
                return "spec: string expected";
        if (message.config != null && message.hasOwnProperty("config"))
            if (!$util.isString(message.config))
                return "config: string expected";
        if (message.url != null && message.hasOwnProperty("url")) {
            properties.chart = 1;
            if (!$util.isString(message.url))
                return "url: string expected";
        }
        if (message.figure != null && message.hasOwnProperty("figure")) {
            if (properties.chart === 1)
                return "chart: multiple values";
            properties.chart = 1;
            {
                let error = $root.Figure.verify(message.figure);
                if (error)
                    return "figure." + error;
            }
        }
        return null;
    };

    /**
     * Creates a PlotlyChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PlotlyChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PlotlyChart} PlotlyChart
     */
    PlotlyChart.fromObject = function fromObject(object) {
        if (object instanceof $root.PlotlyChart)
            return object;
        let message = new $root.PlotlyChart();
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        if (object.theme != null)
            message.theme = String(object.theme);
        if (object.id != null)
            message.id = String(object.id);
        if (object.selectionMode) {
            if (!Array.isArray(object.selectionMode))
                throw TypeError(".PlotlyChart.selectionMode: array expected");
            message.selectionMode = [];
            for (let i = 0; i < object.selectionMode.length; ++i)
                switch (object.selectionMode[i]) {
                default:
                    if (typeof object.selectionMode[i] === "number") {
                        message.selectionMode[i] = object.selectionMode[i];
                        break;
                    }
                case "POINTS":
                case 0:
                    message.selectionMode[i] = 0;
                    break;
                case "BOX":
                case 1:
                    message.selectionMode[i] = 1;
                    break;
                case "LASSO":
                case 2:
                    message.selectionMode[i] = 2;
                    break;
                }
        }
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.spec != null)
            message.spec = String(object.spec);
        if (object.config != null)
            message.config = String(object.config);
        if (object.url != null)
            message.url = String(object.url);
        if (object.figure != null) {
            if (typeof object.figure !== "object")
                throw TypeError(".PlotlyChart.figure: object expected");
            message.figure = $root.Figure.fromObject(object.figure);
        }
        return message;
    };

    /**
     * Creates a plain object from a PlotlyChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PlotlyChart
     * @static
     * @param {PlotlyChart} message PlotlyChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PlotlyChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.selectionMode = [];
        if (options.defaults) {
            object.useContainerWidth = false;
            object.theme = "";
            object.id = "";
            object.formId = "";
            object.spec = "";
            object.config = "";
        }
        if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
            if (options.oneofs)
                object.chart = "url";
        }
        if (message.figure != null && message.hasOwnProperty("figure")) {
            object.figure = $root.Figure.toObject(message.figure, options);
            if (options.oneofs)
                object.chart = "figure";
        }
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        if (message.theme != null && message.hasOwnProperty("theme"))
            object.theme = message.theme;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.selectionMode && message.selectionMode.length) {
            object.selectionMode = [];
            for (let j = 0; j < message.selectionMode.length; ++j)
                object.selectionMode[j] = options.enums === String ? $root.PlotlyChart.SelectionMode[message.selectionMode[j]] === undefined ? message.selectionMode[j] : $root.PlotlyChart.SelectionMode[message.selectionMode[j]] : message.selectionMode[j];
        }
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.spec != null && message.hasOwnProperty("spec"))
            object.spec = message.spec;
        if (message.config != null && message.hasOwnProperty("config"))
            object.config = message.config;
        return object;
    };

    /**
     * Converts this PlotlyChart to JSON.
     * @function toJSON
     * @memberof PlotlyChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PlotlyChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PlotlyChart
     * @function getTypeUrl
     * @memberof PlotlyChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PlotlyChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PlotlyChart";
    };

    /**
     * SelectionMode enum.
     * @name PlotlyChart.SelectionMode
     * @enum {number}
     * @property {number} POINTS=0 POINTS value
     * @property {number} BOX=1 BOX value
     * @property {number} LASSO=2 LASSO value
     */
    PlotlyChart.SelectionMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "POINTS"] = 0;
        values[valuesById[1] = "BOX"] = 1;
        values[valuesById[2] = "LASSO"] = 2;
        return values;
    })();

    return PlotlyChart;
})();

export const Figure = $root.Figure = (() => {

    /**
     * Properties of a Figure.
     * @exports IFigure
     * @interface IFigure
     * @property {string|null} [spec] Figure spec
     * @property {string|null} [config] Figure config
     */

    /**
     * Constructs a new Figure.
     * @exports Figure
     * @classdesc Represents a Figure.
     * @implements IFigure
     * @constructor
     * @param {IFigure=} [properties] Properties to set
     */
    function Figure(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Figure spec.
     * @member {string} spec
     * @memberof Figure
     * @instance
     */
    Figure.prototype.spec = "";

    /**
     * Figure config.
     * @member {string} config
     * @memberof Figure
     * @instance
     */
    Figure.prototype.config = "";

    /**
     * Creates a new Figure instance using the specified properties.
     * @function create
     * @memberof Figure
     * @static
     * @param {IFigure=} [properties] Properties to set
     * @returns {Figure} Figure instance
     */
    Figure.create = function create(properties) {
        return new Figure(properties);
    };

    /**
     * Encodes the specified Figure message. Does not implicitly {@link Figure.verify|verify} messages.
     * @function encode
     * @memberof Figure
     * @static
     * @param {IFigure} message Figure message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Figure.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.spec);
        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.config);
        return writer;
    };

    /**
     * Encodes the specified Figure message, length delimited. Does not implicitly {@link Figure.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Figure
     * @static
     * @param {IFigure} message Figure message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Figure.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Figure message from the specified reader or buffer.
     * @function decode
     * @memberof Figure
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Figure} Figure
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Figure.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Figure();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.spec = reader.string();
                    break;
                }
            case 2: {
                    message.config = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Figure message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Figure
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Figure} Figure
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Figure.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Figure message.
     * @function verify
     * @memberof Figure
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Figure.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.spec != null && message.hasOwnProperty("spec"))
            if (!$util.isString(message.spec))
                return "spec: string expected";
        if (message.config != null && message.hasOwnProperty("config"))
            if (!$util.isString(message.config))
                return "config: string expected";
        return null;
    };

    /**
     * Creates a Figure message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Figure
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Figure} Figure
     */
    Figure.fromObject = function fromObject(object) {
        if (object instanceof $root.Figure)
            return object;
        let message = new $root.Figure();
        if (object.spec != null)
            message.spec = String(object.spec);
        if (object.config != null)
            message.config = String(object.config);
        return message;
    };

    /**
     * Creates a plain object from a Figure message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Figure
     * @static
     * @param {Figure} message Figure
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Figure.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.spec = "";
            object.config = "";
        }
        if (message.spec != null && message.hasOwnProperty("spec"))
            object.spec = message.spec;
        if (message.config != null && message.hasOwnProperty("config"))
            object.config = message.config;
        return object;
    };

    /**
     * Converts this Figure to JSON.
     * @function toJSON
     * @memberof Figure
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Figure.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Figure
     * @function getTypeUrl
     * @memberof Figure
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Figure.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Figure";
    };

    return Figure;
})();

export const Progress = $root.Progress = (() => {

    /**
     * Properties of a Progress.
     * @exports IProgress
     * @interface IProgress
     * @property {number|null} [value] Progress value
     * @property {string|null} [text] Progress text
     */

    /**
     * Constructs a new Progress.
     * @exports Progress
     * @classdesc Represents a Progress.
     * @implements IProgress
     * @constructor
     * @param {IProgress=} [properties] Properties to set
     */
    function Progress(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Progress value.
     * @member {number} value
     * @memberof Progress
     * @instance
     */
    Progress.prototype.value = 0;

    /**
     * Progress text.
     * @member {string} text
     * @memberof Progress
     * @instance
     */
    Progress.prototype.text = "";

    /**
     * Creates a new Progress instance using the specified properties.
     * @function create
     * @memberof Progress
     * @static
     * @param {IProgress=} [properties] Properties to set
     * @returns {Progress} Progress instance
     */
    Progress.create = function create(properties) {
        return new Progress(properties);
    };

    /**
     * Encodes the specified Progress message. Does not implicitly {@link Progress.verify|verify} messages.
     * @function encode
     * @memberof Progress
     * @static
     * @param {IProgress} message Progress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Progress.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
        return writer;
    };

    /**
     * Encodes the specified Progress message, length delimited. Does not implicitly {@link Progress.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Progress
     * @static
     * @param {IProgress} message Progress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Progress.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Progress message from the specified reader or buffer.
     * @function decode
     * @memberof Progress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Progress} Progress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Progress.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Progress();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.value = reader.uint32();
                    break;
                }
            case 2: {
                    message.text = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Progress message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Progress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Progress} Progress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Progress.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Progress message.
     * @function verify
     * @memberof Progress
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Progress.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isInteger(message.value))
                return "value: integer expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        return null;
    };

    /**
     * Creates a Progress message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Progress
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Progress} Progress
     */
    Progress.fromObject = function fromObject(object) {
        if (object instanceof $root.Progress)
            return object;
        let message = new $root.Progress();
        if (object.value != null)
            message.value = object.value >>> 0;
        if (object.text != null)
            message.text = String(object.text);
        return message;
    };

    /**
     * Creates a plain object from a Progress message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Progress
     * @static
     * @param {Progress} message Progress
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Progress.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.value = 0;
            object.text = "";
        }
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        return object;
    };

    /**
     * Converts this Progress to JSON.
     * @function toJSON
     * @memberof Progress
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Progress.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Progress
     * @function getTypeUrl
     * @memberof Progress
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Progress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Progress";
    };

    return Progress;
})();

export const Snow = $root.Snow = (() => {

    /**
     * Properties of a Snow.
     * @exports ISnow
     * @interface ISnow
     * @property {boolean|null} [show] Snow show
     */

    /**
     * Constructs a new Snow.
     * @exports Snow
     * @classdesc Represents a Snow.
     * @implements ISnow
     * @constructor
     * @param {ISnow=} [properties] Properties to set
     */
    function Snow(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Snow show.
     * @member {boolean} show
     * @memberof Snow
     * @instance
     */
    Snow.prototype.show = false;

    /**
     * Creates a new Snow instance using the specified properties.
     * @function create
     * @memberof Snow
     * @static
     * @param {ISnow=} [properties] Properties to set
     * @returns {Snow} Snow instance
     */
    Snow.create = function create(properties) {
        return new Snow(properties);
    };

    /**
     * Encodes the specified Snow message. Does not implicitly {@link Snow.verify|verify} messages.
     * @function encode
     * @memberof Snow
     * @static
     * @param {ISnow} message Snow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Snow.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.show != null && Object.hasOwnProperty.call(message, "show"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.show);
        return writer;
    };

    /**
     * Encodes the specified Snow message, length delimited. Does not implicitly {@link Snow.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Snow
     * @static
     * @param {ISnow} message Snow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Snow.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Snow message from the specified reader or buffer.
     * @function decode
     * @memberof Snow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Snow} Snow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Snow.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Snow();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.show = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Snow message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Snow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Snow} Snow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Snow.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Snow message.
     * @function verify
     * @memberof Snow
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Snow.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.show != null && message.hasOwnProperty("show"))
            if (typeof message.show !== "boolean")
                return "show: boolean expected";
        return null;
    };

    /**
     * Creates a Snow message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Snow
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Snow} Snow
     */
    Snow.fromObject = function fromObject(object) {
        if (object instanceof $root.Snow)
            return object;
        let message = new $root.Snow();
        if (object.show != null)
            message.show = Boolean(object.show);
        return message;
    };

    /**
     * Creates a plain object from a Snow message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Snow
     * @static
     * @param {Snow} message Snow
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Snow.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.show = false;
        if (message.show != null && message.hasOwnProperty("show"))
            object.show = message.show;
        return object;
    };

    /**
     * Converts this Snow to JSON.
     * @function toJSON
     * @memberof Snow
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Snow.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Snow
     * @function getTypeUrl
     * @memberof Snow
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Snow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Snow";
    };

    return Snow;
})();

export const Spinner = $root.Spinner = (() => {

    /**
     * Properties of a Spinner.
     * @exports ISpinner
     * @interface ISpinner
     * @property {string|null} [text] Spinner text
     * @property {boolean|null} [cache] Spinner cache
     */

    /**
     * Constructs a new Spinner.
     * @exports Spinner
     * @classdesc Represents a Spinner.
     * @implements ISpinner
     * @constructor
     * @param {ISpinner=} [properties] Properties to set
     */
    function Spinner(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Spinner text.
     * @member {string} text
     * @memberof Spinner
     * @instance
     */
    Spinner.prototype.text = "";

    /**
     * Spinner cache.
     * @member {boolean} cache
     * @memberof Spinner
     * @instance
     */
    Spinner.prototype.cache = false;

    /**
     * Creates a new Spinner instance using the specified properties.
     * @function create
     * @memberof Spinner
     * @static
     * @param {ISpinner=} [properties] Properties to set
     * @returns {Spinner} Spinner instance
     */
    Spinner.create = function create(properties) {
        return new Spinner(properties);
    };

    /**
     * Encodes the specified Spinner message. Does not implicitly {@link Spinner.verify|verify} messages.
     * @function encode
     * @memberof Spinner
     * @static
     * @param {ISpinner} message Spinner message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Spinner.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
        if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.cache);
        return writer;
    };

    /**
     * Encodes the specified Spinner message, length delimited. Does not implicitly {@link Spinner.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Spinner
     * @static
     * @param {ISpinner} message Spinner message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Spinner.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Spinner message from the specified reader or buffer.
     * @function decode
     * @memberof Spinner
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Spinner} Spinner
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Spinner.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Spinner();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.text = reader.string();
                    break;
                }
            case 2: {
                    message.cache = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Spinner message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Spinner
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Spinner} Spinner
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Spinner.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Spinner message.
     * @function verify
     * @memberof Spinner
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Spinner.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.cache != null && message.hasOwnProperty("cache"))
            if (typeof message.cache !== "boolean")
                return "cache: boolean expected";
        return null;
    };

    /**
     * Creates a Spinner message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Spinner
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Spinner} Spinner
     */
    Spinner.fromObject = function fromObject(object) {
        if (object instanceof $root.Spinner)
            return object;
        let message = new $root.Spinner();
        if (object.text != null)
            message.text = String(object.text);
        if (object.cache != null)
            message.cache = Boolean(object.cache);
        return message;
    };

    /**
     * Creates a plain object from a Spinner message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Spinner
     * @static
     * @param {Spinner} message Spinner
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Spinner.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.text = "";
            object.cache = false;
        }
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.cache != null && message.hasOwnProperty("cache"))
            object.cache = message.cache;
        return object;
    };

    /**
     * Converts this Spinner to JSON.
     * @function toJSON
     * @memberof Spinner
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Spinner.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Spinner
     * @function getTypeUrl
     * @memberof Spinner
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Spinner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Spinner";
    };

    return Spinner;
})();

export const Radio = $root.Radio = (() => {

    /**
     * Properties of a Radio.
     * @exports IRadio
     * @interface IRadio
     * @property {string|null} [id] Radio id
     * @property {string|null} [label] Radio label
     * @property {number|null} ["default"] Radio default
     * @property {Array.<string>|null} [options] Radio options
     * @property {string|null} [help] Radio help
     * @property {string|null} [formId] Radio formId
     * @property {number|null} [value] Radio value
     * @property {boolean|null} [setValue] Radio setValue
     * @property {boolean|null} [disabled] Radio disabled
     * @property {boolean|null} [horizontal] Radio horizontal
     * @property {ILabelVisibilityMessage|null} [labelVisibility] Radio labelVisibility
     * @property {Array.<string>|null} [captions] Radio captions
     */

    /**
     * Constructs a new Radio.
     * @exports Radio
     * @classdesc Represents a Radio.
     * @implements IRadio
     * @constructor
     * @param {IRadio=} [properties] Properties to set
     */
    function Radio(properties) {
        this.options = [];
        this.captions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Radio id.
     * @member {string} id
     * @memberof Radio
     * @instance
     */
    Radio.prototype.id = "";

    /**
     * Radio label.
     * @member {string} label
     * @memberof Radio
     * @instance
     */
    Radio.prototype.label = "";

    /**
     * Radio default.
     * @member {number|null|undefined} default
     * @memberof Radio
     * @instance
     */
    Radio.prototype["default"] = null;

    /**
     * Radio options.
     * @member {Array.<string>} options
     * @memberof Radio
     * @instance
     */
    Radio.prototype.options = $util.emptyArray;

    /**
     * Radio help.
     * @member {string} help
     * @memberof Radio
     * @instance
     */
    Radio.prototype.help = "";

    /**
     * Radio formId.
     * @member {string} formId
     * @memberof Radio
     * @instance
     */
    Radio.prototype.formId = "";

    /**
     * Radio value.
     * @member {number|null|undefined} value
     * @memberof Radio
     * @instance
     */
    Radio.prototype.value = null;

    /**
     * Radio setValue.
     * @member {boolean} setValue
     * @memberof Radio
     * @instance
     */
    Radio.prototype.setValue = false;

    /**
     * Radio disabled.
     * @member {boolean} disabled
     * @memberof Radio
     * @instance
     */
    Radio.prototype.disabled = false;

    /**
     * Radio horizontal.
     * @member {boolean} horizontal
     * @memberof Radio
     * @instance
     */
    Radio.prototype.horizontal = false;

    /**
     * Radio labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof Radio
     * @instance
     */
    Radio.prototype.labelVisibility = null;

    /**
     * Radio captions.
     * @member {Array.<string>} captions
     * @memberof Radio
     * @instance
     */
    Radio.prototype.captions = $util.emptyArray;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Radio.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Radio.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Radio instance using the specified properties.
     * @function create
     * @memberof Radio
     * @static
     * @param {IRadio=} [properties] Properties to set
     * @returns {Radio} Radio instance
     */
    Radio.create = function create(properties) {
        return new Radio(properties);
    };

    /**
     * Encodes the specified Radio message. Does not implicitly {@link Radio.verify|verify} messages.
     * @function encode
     * @memberof Radio
     * @static
     * @param {IRadio} message Radio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Radio.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message["default"]);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.options[i]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.disabled);
        if (message.horizontal != null && Object.hasOwnProperty.call(message, "horizontal"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.horizontal);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.captions != null && message.captions.length)
            for (let i = 0; i < message.captions.length; ++i)
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.captions[i]);
        return writer;
    };

    /**
     * Encodes the specified Radio message, length delimited. Does not implicitly {@link Radio.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Radio
     * @static
     * @param {IRadio} message Radio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Radio.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Radio message from the specified reader or buffer.
     * @function decode
     * @memberof Radio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Radio} Radio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Radio.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Radio();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.int32();
                    break;
                }
            case 4: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push(reader.string());
                    break;
                }
            case 5: {
                    message.help = reader.string();
                    break;
                }
            case 6: {
                    message.formId = reader.string();
                    break;
                }
            case 7: {
                    message.value = reader.int32();
                    break;
                }
            case 8: {
                    message.setValue = reader.bool();
                    break;
                }
            case 9: {
                    message.disabled = reader.bool();
                    break;
                }
            case 10: {
                    message.horizontal = reader.bool();
                    break;
                }
            case 11: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    if (!(message.captions && message.captions.length))
                        message.captions = [];
                    message.captions.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Radio message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Radio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Radio} Radio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Radio.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Radio message.
     * @function verify
     * @memberof Radio
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Radio.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (!$util.isInteger(message["default"]))
                return "default: integer expected";
        }
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i)
                if (!$util.isString(message.options[i]))
                    return "options: string[] expected";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (!$util.isInteger(message.value))
                return "value: integer expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.horizontal != null && message.hasOwnProperty("horizontal"))
            if (typeof message.horizontal !== "boolean")
                return "horizontal: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.captions != null && message.hasOwnProperty("captions")) {
            if (!Array.isArray(message.captions))
                return "captions: array expected";
            for (let i = 0; i < message.captions.length; ++i)
                if (!$util.isString(message.captions[i]))
                    return "captions: string[] expected";
        }
        return null;
    };

    /**
     * Creates a Radio message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Radio
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Radio} Radio
     */
    Radio.fromObject = function fromObject(object) {
        if (object instanceof $root.Radio)
            return object;
        let message = new $root.Radio();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = object["default"] | 0;
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".Radio.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i)
                message.options[i] = String(object.options[i]);
        }
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = object.value | 0;
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.horizontal != null)
            message.horizontal = Boolean(object.horizontal);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".Radio.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.captions) {
            if (!Array.isArray(object.captions))
                throw TypeError(".Radio.captions: array expected");
            message.captions = [];
            for (let i = 0; i < object.captions.length; ++i)
                message.captions[i] = String(object.captions[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a Radio message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Radio
     * @static
     * @param {Radio} message Radio
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Radio.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.options = [];
            object.captions = [];
        }
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.disabled = false;
            object.horizontal = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = message.options[j];
        }
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.horizontal != null && message.hasOwnProperty("horizontal"))
            object.horizontal = message.horizontal;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.captions && message.captions.length) {
            object.captions = [];
            for (let j = 0; j < message.captions.length; ++j)
                object.captions[j] = message.captions[j];
        }
        return object;
    };

    /**
     * Converts this Radio to JSON.
     * @function toJSON
     * @memberof Radio
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Radio.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Radio
     * @function getTypeUrl
     * @memberof Radio
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Radio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Radio";
    };

    return Radio;
})();

export const Selectbox = $root.Selectbox = (() => {

    /**
     * Properties of a Selectbox.
     * @exports ISelectbox
     * @interface ISelectbox
     * @property {string|null} [id] Selectbox id
     * @property {string|null} [label] Selectbox label
     * @property {number|null} ["default"] Selectbox default
     * @property {Array.<string>|null} [options] Selectbox options
     * @property {string|null} [help] Selectbox help
     * @property {string|null} [formId] Selectbox formId
     * @property {number|null} [value] Selectbox value
     * @property {boolean|null} [setValue] Selectbox setValue
     * @property {boolean|null} [disabled] Selectbox disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] Selectbox labelVisibility
     * @property {string|null} [placeholder] Selectbox placeholder
     */

    /**
     * Constructs a new Selectbox.
     * @exports Selectbox
     * @classdesc Represents a Selectbox.
     * @implements ISelectbox
     * @constructor
     * @param {ISelectbox=} [properties] Properties to set
     */
    function Selectbox(properties) {
        this.options = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Selectbox id.
     * @member {string} id
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.id = "";

    /**
     * Selectbox label.
     * @member {string} label
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.label = "";

    /**
     * Selectbox default.
     * @member {number|null|undefined} default
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype["default"] = null;

    /**
     * Selectbox options.
     * @member {Array.<string>} options
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.options = $util.emptyArray;

    /**
     * Selectbox help.
     * @member {string} help
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.help = "";

    /**
     * Selectbox formId.
     * @member {string} formId
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.formId = "";

    /**
     * Selectbox value.
     * @member {number|null|undefined} value
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.value = null;

    /**
     * Selectbox setValue.
     * @member {boolean} setValue
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.setValue = false;

    /**
     * Selectbox disabled.
     * @member {boolean} disabled
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.disabled = false;

    /**
     * Selectbox labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.labelVisibility = null;

    /**
     * Selectbox placeholder.
     * @member {string} placeholder
     * @memberof Selectbox
     * @instance
     */
    Selectbox.prototype.placeholder = "";

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Selectbox.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Selectbox.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Selectbox instance using the specified properties.
     * @function create
     * @memberof Selectbox
     * @static
     * @param {ISelectbox=} [properties] Properties to set
     * @returns {Selectbox} Selectbox instance
     */
    Selectbox.create = function create(properties) {
        return new Selectbox(properties);
    };

    /**
     * Encodes the specified Selectbox message. Does not implicitly {@link Selectbox.verify|verify} messages.
     * @function encode
     * @memberof Selectbox
     * @static
     * @param {ISelectbox} message Selectbox message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Selectbox.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message["default"]);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.options[i]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.placeholder);
        return writer;
    };

    /**
     * Encodes the specified Selectbox message, length delimited. Does not implicitly {@link Selectbox.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Selectbox
     * @static
     * @param {ISelectbox} message Selectbox message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Selectbox.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Selectbox message from the specified reader or buffer.
     * @function decode
     * @memberof Selectbox
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Selectbox} Selectbox
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Selectbox.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Selectbox();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.int32();
                    break;
                }
            case 4: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push(reader.string());
                    break;
                }
            case 5: {
                    message.help = reader.string();
                    break;
                }
            case 6: {
                    message.formId = reader.string();
                    break;
                }
            case 7: {
                    message.value = reader.int32();
                    break;
                }
            case 8: {
                    message.setValue = reader.bool();
                    break;
                }
            case 9: {
                    message.disabled = reader.bool();
                    break;
                }
            case 10: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.placeholder = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Selectbox message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Selectbox
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Selectbox} Selectbox
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Selectbox.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Selectbox message.
     * @function verify
     * @memberof Selectbox
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Selectbox.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (!$util.isInteger(message["default"]))
                return "default: integer expected";
        }
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i)
                if (!$util.isString(message.options[i]))
                    return "options: string[] expected";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (!$util.isInteger(message.value))
                return "value: integer expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        return null;
    };

    /**
     * Creates a Selectbox message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Selectbox
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Selectbox} Selectbox
     */
    Selectbox.fromObject = function fromObject(object) {
        if (object instanceof $root.Selectbox)
            return object;
        let message = new $root.Selectbox();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = object["default"] | 0;
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".Selectbox.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i)
                message.options[i] = String(object.options[i]);
        }
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = object.value | 0;
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".Selectbox.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        return message;
    };

    /**
     * Creates a plain object from a Selectbox message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Selectbox
     * @static
     * @param {Selectbox} message Selectbox
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Selectbox.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.options = [];
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            object.placeholder = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = message.options[j];
        }
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        return object;
    };

    /**
     * Converts this Selectbox to JSON.
     * @function toJSON
     * @memberof Selectbox
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Selectbox.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Selectbox
     * @function getTypeUrl
     * @memberof Selectbox
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Selectbox.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Selectbox";
    };

    return Selectbox;
})();

export const Skeleton = $root.Skeleton = (() => {

    /**
     * Properties of a Skeleton.
     * @exports ISkeleton
     * @interface ISkeleton
     * @property {Skeleton.SkeletonStyle|null} [style] Skeleton style
     * @property {number|null} [height] Skeleton height
     */

    /**
     * Constructs a new Skeleton.
     * @exports Skeleton
     * @classdesc Represents a Skeleton.
     * @implements ISkeleton
     * @constructor
     * @param {ISkeleton=} [properties] Properties to set
     */
    function Skeleton(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Skeleton style.
     * @member {Skeleton.SkeletonStyle} style
     * @memberof Skeleton
     * @instance
     */
    Skeleton.prototype.style = 0;

    /**
     * Skeleton height.
     * @member {number|null|undefined} height
     * @memberof Skeleton
     * @instance
     */
    Skeleton.prototype.height = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(Skeleton.prototype, "_height", {
        get: $util.oneOfGetter($oneOfFields = ["height"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Skeleton instance using the specified properties.
     * @function create
     * @memberof Skeleton
     * @static
     * @param {ISkeleton=} [properties] Properties to set
     * @returns {Skeleton} Skeleton instance
     */
    Skeleton.create = function create(properties) {
        return new Skeleton(properties);
    };

    /**
     * Encodes the specified Skeleton message. Does not implicitly {@link Skeleton.verify|verify} messages.
     * @function encode
     * @memberof Skeleton
     * @static
     * @param {ISkeleton} message Skeleton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Skeleton.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.style);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.height);
        return writer;
    };

    /**
     * Encodes the specified Skeleton message, length delimited. Does not implicitly {@link Skeleton.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Skeleton
     * @static
     * @param {ISkeleton} message Skeleton message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Skeleton.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Skeleton message from the specified reader or buffer.
     * @function decode
     * @memberof Skeleton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Skeleton} Skeleton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Skeleton.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Skeleton();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.style = reader.int32();
                    break;
                }
            case 2: {
                    message.height = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Skeleton message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Skeleton
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Skeleton} Skeleton
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Skeleton.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Skeleton message.
     * @function verify
     * @memberof Skeleton
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Skeleton.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.style != null && message.hasOwnProperty("style"))
            switch (message.style) {
            default:
                return "style: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.height != null && message.hasOwnProperty("height")) {
            properties._height = 1;
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        }
        return null;
    };

    /**
     * Creates a Skeleton message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Skeleton
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Skeleton} Skeleton
     */
    Skeleton.fromObject = function fromObject(object) {
        if (object instanceof $root.Skeleton)
            return object;
        let message = new $root.Skeleton();
        switch (object.style) {
        default:
            if (typeof object.style === "number") {
                message.style = object.style;
                break;
            }
            break;
        case "ELEMENT":
        case 0:
            message.style = 0;
            break;
        case "APP":
        case 1:
            message.style = 1;
            break;
        }
        if (object.height != null)
            message.height = object.height | 0;
        return message;
    };

    /**
     * Creates a plain object from a Skeleton message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Skeleton
     * @static
     * @param {Skeleton} message Skeleton
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Skeleton.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.style = options.enums === String ? "ELEMENT" : 0;
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = options.enums === String ? $root.Skeleton.SkeletonStyle[message.style] === undefined ? message.style : $root.Skeleton.SkeletonStyle[message.style] : message.style;
        if (message.height != null && message.hasOwnProperty("height")) {
            object.height = message.height;
            if (options.oneofs)
                object._height = "height";
        }
        return object;
    };

    /**
     * Converts this Skeleton to JSON.
     * @function toJSON
     * @memberof Skeleton
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Skeleton.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Skeleton
     * @function getTypeUrl
     * @memberof Skeleton
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Skeleton.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Skeleton";
    };

    /**
     * SkeletonStyle enum.
     * @name Skeleton.SkeletonStyle
     * @enum {number}
     * @property {number} ELEMENT=0 ELEMENT value
     * @property {number} APP=1 APP value
     */
    Skeleton.SkeletonStyle = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ELEMENT"] = 0;
        values[valuesById[1] = "APP"] = 1;
        return values;
    })();

    return Skeleton;
})();

export const Slider = $root.Slider = (() => {

    /**
     * Properties of a Slider.
     * @exports ISlider
     * @interface ISlider
     * @property {string|null} [id] Slider id
     * @property {string|null} [formId] Slider formId
     * @property {string|null} [label] Slider label
     * @property {string|null} [format] Slider format
     * @property {Slider.DataType|null} [dataType] Slider dataType
     * @property {Array.<number>|null} ["default"] Slider default
     * @property {number|null} [min] Slider min
     * @property {number|null} [max] Slider max
     * @property {number|null} [step] Slider step
     * @property {Array.<number>|null} [value] Slider value
     * @property {boolean|null} [setValue] Slider setValue
     * @property {Array.<string>|null} [options] Slider options
     * @property {string|null} [help] Slider help
     * @property {boolean|null} [disabled] Slider disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] Slider labelVisibility
     * @property {Slider.Type|null} [type] Slider type
     */

    /**
     * Constructs a new Slider.
     * @exports Slider
     * @classdesc Represents a Slider.
     * @implements ISlider
     * @constructor
     * @param {ISlider=} [properties] Properties to set
     */
    function Slider(properties) {
        this["default"] = [];
        this.value = [];
        this.options = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Slider id.
     * @member {string} id
     * @memberof Slider
     * @instance
     */
    Slider.prototype.id = "";

    /**
     * Slider formId.
     * @member {string} formId
     * @memberof Slider
     * @instance
     */
    Slider.prototype.formId = "";

    /**
     * Slider label.
     * @member {string} label
     * @memberof Slider
     * @instance
     */
    Slider.prototype.label = "";

    /**
     * Slider format.
     * @member {string} format
     * @memberof Slider
     * @instance
     */
    Slider.prototype.format = "";

    /**
     * Slider dataType.
     * @member {Slider.DataType} dataType
     * @memberof Slider
     * @instance
     */
    Slider.prototype.dataType = 0;

    /**
     * Slider default.
     * @member {Array.<number>} default
     * @memberof Slider
     * @instance
     */
    Slider.prototype["default"] = $util.emptyArray;

    /**
     * Slider min.
     * @member {number} min
     * @memberof Slider
     * @instance
     */
    Slider.prototype.min = 0;

    /**
     * Slider max.
     * @member {number} max
     * @memberof Slider
     * @instance
     */
    Slider.prototype.max = 0;

    /**
     * Slider step.
     * @member {number} step
     * @memberof Slider
     * @instance
     */
    Slider.prototype.step = 0;

    /**
     * Slider value.
     * @member {Array.<number>} value
     * @memberof Slider
     * @instance
     */
    Slider.prototype.value = $util.emptyArray;

    /**
     * Slider setValue.
     * @member {boolean} setValue
     * @memberof Slider
     * @instance
     */
    Slider.prototype.setValue = false;

    /**
     * Slider options.
     * @member {Array.<string>} options
     * @memberof Slider
     * @instance
     */
    Slider.prototype.options = $util.emptyArray;

    /**
     * Slider help.
     * @member {string} help
     * @memberof Slider
     * @instance
     */
    Slider.prototype.help = "";

    /**
     * Slider disabled.
     * @member {boolean} disabled
     * @memberof Slider
     * @instance
     */
    Slider.prototype.disabled = false;

    /**
     * Slider labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof Slider
     * @instance
     */
    Slider.prototype.labelVisibility = null;

    /**
     * Slider type.
     * @member {Slider.Type} type
     * @memberof Slider
     * @instance
     */
    Slider.prototype.type = 0;

    /**
     * Creates a new Slider instance using the specified properties.
     * @function create
     * @memberof Slider
     * @static
     * @param {ISlider=} [properties] Properties to set
     * @returns {Slider} Slider instance
     */
    Slider.create = function create(properties) {
        return new Slider(properties);
    };

    /**
     * Encodes the specified Slider message. Does not implicitly {@link Slider.verify|verify} messages.
     * @function encode
     * @memberof Slider
     * @static
     * @param {ISlider} message Slider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Slider.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.formId);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.format);
        if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dataType);
        if (message["default"] != null && message["default"].length) {
            writer.uint32(/* id 6, wireType 2 =*/50).fork();
            for (let i = 0; i < message["default"].length; ++i)
                writer.double(message["default"][i]);
            writer.ldelim();
        }
        if (message.min != null && Object.hasOwnProperty.call(message, "min"))
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.min);
        if (message.max != null && Object.hasOwnProperty.call(message, "max"))
            writer.uint32(/* id 8, wireType 1 =*/65).double(message.max);
        if (message.step != null && Object.hasOwnProperty.call(message, "step"))
            writer.uint32(/* id 9, wireType 1 =*/73).double(message.step);
        if (message.value != null && message.value.length) {
            writer.uint32(/* id 10, wireType 2 =*/82).fork();
            for (let i = 0; i < message.value.length; ++i)
                writer.double(message.value[i]);
            writer.ldelim();
        }
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.setValue);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.options[i]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.help);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.type);
        return writer;
    };

    /**
     * Encodes the specified Slider message, length delimited. Does not implicitly {@link Slider.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Slider
     * @static
     * @param {ISlider} message Slider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Slider.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Slider message from the specified reader or buffer.
     * @function decode
     * @memberof Slider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Slider} Slider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Slider.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Slider();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.formId = reader.string();
                    break;
                }
            case 3: {
                    message.label = reader.string();
                    break;
                }
            case 4: {
                    message.format = reader.string();
                    break;
                }
            case 5: {
                    message.dataType = reader.int32();
                    break;
                }
            case 6: {
                    if (!(message["default"] && message["default"].length))
                        message["default"] = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["default"].push(reader.double());
                    } else
                        message["default"].push(reader.double());
                    break;
                }
            case 7: {
                    message.min = reader.double();
                    break;
                }
            case 8: {
                    message.max = reader.double();
                    break;
                }
            case 9: {
                    message.step = reader.double();
                    break;
                }
            case 10: {
                    if (!(message.value && message.value.length))
                        message.value = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.value.push(reader.double());
                    } else
                        message.value.push(reader.double());
                    break;
                }
            case 11: {
                    message.setValue = reader.bool();
                    break;
                }
            case 13: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push(reader.string());
                    break;
                }
            case 14: {
                    message.help = reader.string();
                    break;
                }
            case 15: {
                    message.disabled = reader.bool();
                    break;
                }
            case 16: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Slider message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Slider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Slider} Slider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Slider.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Slider message.
     * @function verify
     * @memberof Slider
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Slider.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.format != null && message.hasOwnProperty("format"))
            if (!$util.isString(message.format))
                return "format: string expected";
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            switch (message.dataType) {
            default:
                return "dataType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message["default"] != null && message.hasOwnProperty("default")) {
            if (!Array.isArray(message["default"]))
                return "default: array expected";
            for (let i = 0; i < message["default"].length; ++i)
                if (typeof message["default"][i] !== "number")
                    return "default: number[] expected";
        }
        if (message.min != null && message.hasOwnProperty("min"))
            if (typeof message.min !== "number")
                return "min: number expected";
        if (message.max != null && message.hasOwnProperty("max"))
            if (typeof message.max !== "number")
                return "max: number expected";
        if (message.step != null && message.hasOwnProperty("step"))
            if (typeof message.step !== "number")
                return "step: number expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            if (!Array.isArray(message.value))
                return "value: array expected";
            for (let i = 0; i < message.value.length; ++i)
                if (typeof message.value[i] !== "number")
                    return "value: number[] expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.options != null && message.hasOwnProperty("options")) {
            if (!Array.isArray(message.options))
                return "options: array expected";
            for (let i = 0; i < message.options.length; ++i)
                if (!$util.isString(message.options[i]))
                    return "options: string[] expected";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        return null;
    };

    /**
     * Creates a Slider message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Slider
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Slider} Slider
     */
    Slider.fromObject = function fromObject(object) {
        if (object instanceof $root.Slider)
            return object;
        let message = new $root.Slider();
        if (object.id != null)
            message.id = String(object.id);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.label != null)
            message.label = String(object.label);
        if (object.format != null)
            message.format = String(object.format);
        switch (object.dataType) {
        default:
            if (typeof object.dataType === "number") {
                message.dataType = object.dataType;
                break;
            }
            break;
        case "INT":
        case 0:
            message.dataType = 0;
            break;
        case "FLOAT":
        case 1:
            message.dataType = 1;
            break;
        case "DATETIME":
        case 2:
            message.dataType = 2;
            break;
        case "DATE":
        case 3:
            message.dataType = 3;
            break;
        case "TIME":
        case 4:
            message.dataType = 4;
            break;
        }
        if (object["default"]) {
            if (!Array.isArray(object["default"]))
                throw TypeError(".Slider.default: array expected");
            message["default"] = [];
            for (let i = 0; i < object["default"].length; ++i)
                message["default"][i] = Number(object["default"][i]);
        }
        if (object.min != null)
            message.min = Number(object.min);
        if (object.max != null)
            message.max = Number(object.max);
        if (object.step != null)
            message.step = Number(object.step);
        if (object.value) {
            if (!Array.isArray(object.value))
                throw TypeError(".Slider.value: array expected");
            message.value = [];
            for (let i = 0; i < object.value.length; ++i)
                message.value[i] = Number(object.value[i]);
        }
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".Slider.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i)
                message.options[i] = String(object.options[i]);
        }
        if (object.help != null)
            message.help = String(object.help);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".Slider.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNSPECIFIED":
        case 0:
            message.type = 0;
            break;
        case "SLIDER":
        case 1:
            message.type = 1;
            break;
        case "SELECT_SLIDER":
        case 2:
            message.type = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a Slider message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Slider
     * @static
     * @param {Slider} message Slider
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Slider.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object["default"] = [];
            object.value = [];
            object.options = [];
        }
        if (options.defaults) {
            object.id = "";
            object.formId = "";
            object.label = "";
            object.format = "";
            object.dataType = options.enums === String ? "INT" : 0;
            object.min = 0;
            object.max = 0;
            object.step = 0;
            object.setValue = false;
            object.help = "";
            object.disabled = false;
            object.labelVisibility = null;
            object.type = options.enums === String ? "UNSPECIFIED" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.format != null && message.hasOwnProperty("format"))
            object.format = message.format;
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            object.dataType = options.enums === String ? $root.Slider.DataType[message.dataType] === undefined ? message.dataType : $root.Slider.DataType[message.dataType] : message.dataType;
        if (message["default"] && message["default"].length) {
            object["default"] = [];
            for (let j = 0; j < message["default"].length; ++j)
                object["default"][j] = options.json && !isFinite(message["default"][j]) ? String(message["default"][j]) : message["default"][j];
        }
        if (message.min != null && message.hasOwnProperty("min"))
            object.min = options.json && !isFinite(message.min) ? String(message.min) : message.min;
        if (message.max != null && message.hasOwnProperty("max"))
            object.max = options.json && !isFinite(message.max) ? String(message.max) : message.max;
        if (message.step != null && message.hasOwnProperty("step"))
            object.step = options.json && !isFinite(message.step) ? String(message.step) : message.step;
        if (message.value && message.value.length) {
            object.value = [];
            for (let j = 0; j < message.value.length; ++j)
                object.value[j] = options.json && !isFinite(message.value[j]) ? String(message.value[j]) : message.value[j];
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = message.options[j];
        }
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.Slider.Type[message.type] === undefined ? message.type : $root.Slider.Type[message.type] : message.type;
        return object;
    };

    /**
     * Converts this Slider to JSON.
     * @function toJSON
     * @memberof Slider
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Slider.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Slider
     * @function getTypeUrl
     * @memberof Slider
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Slider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Slider";
    };

    /**
     * DataType enum.
     * @name Slider.DataType
     * @enum {number}
     * @property {number} INT=0 INT value
     * @property {number} FLOAT=1 FLOAT value
     * @property {number} DATETIME=2 DATETIME value
     * @property {number} DATE=3 DATE value
     * @property {number} TIME=4 TIME value
     */
    Slider.DataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INT"] = 0;
        values[valuesById[1] = "FLOAT"] = 1;
        values[valuesById[2] = "DATETIME"] = 2;
        values[valuesById[3] = "DATE"] = 3;
        values[valuesById[4] = "TIME"] = 4;
        return values;
    })();

    /**
     * Type enum.
     * @name Slider.Type
     * @enum {number}
     * @property {number} UNSPECIFIED=0 UNSPECIFIED value
     * @property {number} SLIDER=1 SLIDER value
     * @property {number} SELECT_SLIDER=2 SELECT_SLIDER value
     */
    Slider.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED"] = 0;
        values[valuesById[1] = "SLIDER"] = 1;
        values[valuesById[2] = "SELECT_SLIDER"] = 2;
        return values;
    })();

    return Slider;
})();

export const Text = $root.Text = (() => {

    /**
     * Properties of a Text.
     * @exports IText
     * @interface IText
     * @property {string|null} [body] Text body
     * @property {string|null} [help] Text help
     */

    /**
     * Constructs a new Text.
     * @exports Text
     * @classdesc Represents a Text.
     * @implements IText
     * @constructor
     * @param {IText=} [properties] Properties to set
     */
    function Text(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Text body.
     * @member {string} body
     * @memberof Text
     * @instance
     */
    Text.prototype.body = "";

    /**
     * Text help.
     * @member {string} help
     * @memberof Text
     * @instance
     */
    Text.prototype.help = "";

    /**
     * Creates a new Text instance using the specified properties.
     * @function create
     * @memberof Text
     * @static
     * @param {IText=} [properties] Properties to set
     * @returns {Text} Text instance
     */
    Text.create = function create(properties) {
        return new Text(properties);
    };

    /**
     * Encodes the specified Text message. Does not implicitly {@link Text.verify|verify} messages.
     * @function encode
     * @memberof Text
     * @static
     * @param {IText} message Text message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Text.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.help);
        return writer;
    };

    /**
     * Encodes the specified Text message, length delimited. Does not implicitly {@link Text.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Text
     * @static
     * @param {IText} message Text message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Text.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Text message from the specified reader or buffer.
     * @function decode
     * @memberof Text
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Text} Text
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Text.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Text();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            case 2: {
                    message.help = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Text message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Text
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Text} Text
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Text.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Text message.
     * @function verify
     * @memberof Text
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Text.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        return null;
    };

    /**
     * Creates a Text message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Text
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Text} Text
     */
    Text.fromObject = function fromObject(object) {
        if (object instanceof $root.Text)
            return object;
        let message = new $root.Text();
        if (object.body != null)
            message.body = String(object.body);
        if (object.help != null)
            message.help = String(object.help);
        return message;
    };

    /**
     * Creates a plain object from a Text message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Text
     * @static
     * @param {Text} message Text
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Text.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.body = "";
            object.help = "";
        }
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        return object;
    };

    /**
     * Converts this Text to JSON.
     * @function toJSON
     * @memberof Text
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Text.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Text
     * @function getTypeUrl
     * @memberof Text
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Text";
    };

    return Text;
})();

export const TextArea = $root.TextArea = (() => {

    /**
     * Properties of a TextArea.
     * @exports ITextArea
     * @interface ITextArea
     * @property {string|null} [id] TextArea id
     * @property {string|null} [label] TextArea label
     * @property {string|null} ["default"] TextArea default
     * @property {number|null} [height] TextArea height
     * @property {number|null} [maxChars] TextArea maxChars
     * @property {string|null} [help] TextArea help
     * @property {string|null} [formId] TextArea formId
     * @property {string|null} [value] TextArea value
     * @property {boolean|null} [setValue] TextArea setValue
     * @property {string|null} [placeholder] TextArea placeholder
     * @property {boolean|null} [disabled] TextArea disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] TextArea labelVisibility
     */

    /**
     * Constructs a new TextArea.
     * @exports TextArea
     * @classdesc Represents a TextArea.
     * @implements ITextArea
     * @constructor
     * @param {ITextArea=} [properties] Properties to set
     */
    function TextArea(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TextArea id.
     * @member {string} id
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.id = "";

    /**
     * TextArea label.
     * @member {string} label
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.label = "";

    /**
     * TextArea default.
     * @member {string|null|undefined} default
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype["default"] = null;

    /**
     * TextArea height.
     * @member {number} height
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.height = 0;

    /**
     * TextArea maxChars.
     * @member {number} maxChars
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.maxChars = 0;

    /**
     * TextArea help.
     * @member {string} help
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.help = "";

    /**
     * TextArea formId.
     * @member {string} formId
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.formId = "";

    /**
     * TextArea value.
     * @member {string|null|undefined} value
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.value = null;

    /**
     * TextArea setValue.
     * @member {boolean} setValue
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.setValue = false;

    /**
     * TextArea placeholder.
     * @member {string} placeholder
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.placeholder = "";

    /**
     * TextArea disabled.
     * @member {boolean} disabled
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.disabled = false;

    /**
     * TextArea labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof TextArea
     * @instance
     */
    TextArea.prototype.labelVisibility = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TextArea.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TextArea.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TextArea instance using the specified properties.
     * @function create
     * @memberof TextArea
     * @static
     * @param {ITextArea=} [properties] Properties to set
     * @returns {TextArea} TextArea instance
     */
    TextArea.create = function create(properties) {
        return new TextArea(properties);
    };

    /**
     * Encodes the specified TextArea message. Does not implicitly {@link TextArea.verify|verify} messages.
     * @function encode
     * @memberof TextArea
     * @static
     * @param {ITextArea} message TextArea message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextArea.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message["default"]);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.height);
        if (message.maxChars != null && Object.hasOwnProperty.call(message, "maxChars"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.maxChars);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.setValue);
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.placeholder);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TextArea message, length delimited. Does not implicitly {@link TextArea.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TextArea
     * @static
     * @param {ITextArea} message TextArea message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextArea.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TextArea message from the specified reader or buffer.
     * @function decode
     * @memberof TextArea
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TextArea} TextArea
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextArea.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TextArea();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.string();
                    break;
                }
            case 4: {
                    message.height = reader.uint32();
                    break;
                }
            case 5: {
                    message.maxChars = reader.uint32();
                    break;
                }
            case 6: {
                    message.help = reader.string();
                    break;
                }
            case 7: {
                    message.formId = reader.string();
                    break;
                }
            case 8: {
                    message.value = reader.string();
                    break;
                }
            case 9: {
                    message.setValue = reader.bool();
                    break;
                }
            case 10: {
                    message.placeholder = reader.string();
                    break;
                }
            case 11: {
                    message.disabled = reader.bool();
                    break;
                }
            case 12: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TextArea message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TextArea
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TextArea} TextArea
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextArea.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TextArea message.
     * @function verify
     * @memberof TextArea
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TextArea.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (!$util.isString(message["default"]))
                return "default: string expected";
        }
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            if (!$util.isInteger(message.maxChars))
                return "maxChars: integer expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (!$util.isString(message.value))
                return "value: string expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates a TextArea message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TextArea
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TextArea} TextArea
     */
    TextArea.fromObject = function fromObject(object) {
        if (object instanceof $root.TextArea)
            return object;
        let message = new $root.TextArea();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = String(object["default"]);
        if (object.height != null)
            message.height = object.height >>> 0;
        if (object.maxChars != null)
            message.maxChars = object.maxChars >>> 0;
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = String(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".TextArea.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from a TextArea message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TextArea
     * @static
     * @param {TextArea} message TextArea
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TextArea.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.height = 0;
            object.maxChars = 0;
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.placeholder = "";
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            object.maxChars = message.maxChars;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this TextArea to JSON.
     * @function toJSON
     * @memberof TextArea
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TextArea.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TextArea
     * @function getTypeUrl
     * @memberof TextArea
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TextArea.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TextArea";
    };

    return TextArea;
})();

export const TextInput = $root.TextInput = (() => {

    /**
     * Properties of a TextInput.
     * @exports ITextInput
     * @interface ITextInput
     * @property {string|null} [id] TextInput id
     * @property {string|null} [label] TextInput label
     * @property {string|null} ["default"] TextInput default
     * @property {TextInput.Type|null} [type] TextInput type
     * @property {number|null} [maxChars] TextInput maxChars
     * @property {string|null} [help] TextInput help
     * @property {string|null} [formId] TextInput formId
     * @property {string|null} [value] TextInput value
     * @property {boolean|null} [setValue] TextInput setValue
     * @property {string|null} [autocomplete] TextInput autocomplete
     * @property {string|null} [placeholder] TextInput placeholder
     * @property {boolean|null} [disabled] TextInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] TextInput labelVisibility
     */

    /**
     * Constructs a new TextInput.
     * @exports TextInput
     * @classdesc Represents a TextInput.
     * @implements ITextInput
     * @constructor
     * @param {ITextInput=} [properties] Properties to set
     */
    function TextInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TextInput id.
     * @member {string} id
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.id = "";

    /**
     * TextInput label.
     * @member {string} label
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.label = "";

    /**
     * TextInput default.
     * @member {string|null|undefined} default
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype["default"] = null;

    /**
     * TextInput type.
     * @member {TextInput.Type} type
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.type = 0;

    /**
     * TextInput maxChars.
     * @member {number} maxChars
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.maxChars = 0;

    /**
     * TextInput help.
     * @member {string} help
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.help = "";

    /**
     * TextInput formId.
     * @member {string} formId
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.formId = "";

    /**
     * TextInput value.
     * @member {string|null|undefined} value
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.value = null;

    /**
     * TextInput setValue.
     * @member {boolean} setValue
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.setValue = false;

    /**
     * TextInput autocomplete.
     * @member {string} autocomplete
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.autocomplete = "";

    /**
     * TextInput placeholder.
     * @member {string} placeholder
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.placeholder = "";

    /**
     * TextInput disabled.
     * @member {boolean} disabled
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.disabled = false;

    /**
     * TextInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof TextInput
     * @instance
     */
    TextInput.prototype.labelVisibility = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TextInput.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TextInput.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TextInput instance using the specified properties.
     * @function create
     * @memberof TextInput
     * @static
     * @param {ITextInput=} [properties] Properties to set
     * @returns {TextInput} TextInput instance
     */
    TextInput.create = function create(properties) {
        return new TextInput(properties);
    };

    /**
     * Encodes the specified TextInput message. Does not implicitly {@link TextInput.verify|verify} messages.
     * @function encode
     * @memberof TextInput
     * @static
     * @param {ITextInput} message TextInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message["default"]);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
        if (message.maxChars != null && Object.hasOwnProperty.call(message, "maxChars"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.maxChars);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.setValue);
        if (message.autocomplete != null && Object.hasOwnProperty.call(message, "autocomplete"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.autocomplete);
        if (message.placeholder != null && Object.hasOwnProperty.call(message, "placeholder"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.placeholder);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified TextInput message, length delimited. Does not implicitly {@link TextInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TextInput
     * @static
     * @param {ITextInput} message TextInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TextInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TextInput message from the specified reader or buffer.
     * @function decode
     * @memberof TextInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TextInput} TextInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TextInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.string();
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 5: {
                    message.maxChars = reader.uint32();
                    break;
                }
            case 6: {
                    message.help = reader.string();
                    break;
                }
            case 7: {
                    message.formId = reader.string();
                    break;
                }
            case 8: {
                    message.value = reader.string();
                    break;
                }
            case 9: {
                    message.setValue = reader.bool();
                    break;
                }
            case 10: {
                    message.autocomplete = reader.string();
                    break;
                }
            case 11: {
                    message.placeholder = reader.string();
                    break;
                }
            case 12: {
                    message.disabled = reader.bool();
                    break;
                }
            case 13: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TextInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TextInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TextInput} TextInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TextInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TextInput message.
     * @function verify
     * @memberof TextInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TextInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (!$util.isString(message["default"]))
                return "default: string expected";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            if (!$util.isInteger(message.maxChars))
                return "maxChars: integer expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (!$util.isString(message.value))
                return "value: string expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.autocomplete != null && message.hasOwnProperty("autocomplete"))
            if (!$util.isString(message.autocomplete))
                return "autocomplete: string expected";
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            if (!$util.isString(message.placeholder))
                return "placeholder: string expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        return null;
    };

    /**
     * Creates a TextInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TextInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TextInput} TextInput
     */
    TextInput.fromObject = function fromObject(object) {
        if (object instanceof $root.TextInput)
            return object;
        let message = new $root.TextInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = String(object["default"]);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "DEFAULT":
        case 0:
            message.type = 0;
            break;
        case "PASSWORD":
        case 1:
            message.type = 1;
            break;
        }
        if (object.maxChars != null)
            message.maxChars = object.maxChars >>> 0;
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = String(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.autocomplete != null)
            message.autocomplete = String(object.autocomplete);
        if (object.placeholder != null)
            message.placeholder = String(object.placeholder);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".TextInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        return message;
    };

    /**
     * Creates a plain object from a TextInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TextInput
     * @static
     * @param {TextInput} message TextInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TextInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.type = options.enums === String ? "DEFAULT" : 0;
            object.maxChars = 0;
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.autocomplete = "";
            object.placeholder = "";
            object.disabled = false;
            object.labelVisibility = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.TextInput.Type[message.type] === undefined ? message.type : $root.TextInput.Type[message.type] : message.type;
        if (message.maxChars != null && message.hasOwnProperty("maxChars"))
            object.maxChars = message.maxChars;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.autocomplete != null && message.hasOwnProperty("autocomplete"))
            object.autocomplete = message.autocomplete;
        if (message.placeholder != null && message.hasOwnProperty("placeholder"))
            object.placeholder = message.placeholder;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        return object;
    };

    /**
     * Converts this TextInput to JSON.
     * @function toJSON
     * @memberof TextInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TextInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TextInput
     * @function getTypeUrl
     * @memberof TextInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TextInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TextInput";
    };

    /**
     * Type enum.
     * @name TextInput.Type
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} PASSWORD=1 PASSWORD value
     */
    TextInput.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "PASSWORD"] = 1;
        return values;
    })();

    return TextInput;
})();

export const TimeInput = $root.TimeInput = (() => {

    /**
     * Properties of a TimeInput.
     * @exports ITimeInput
     * @interface ITimeInput
     * @property {string|null} [id] TimeInput id
     * @property {string|null} [label] TimeInput label
     * @property {string|null} ["default"] TimeInput default
     * @property {string|null} [help] TimeInput help
     * @property {string|null} [formId] TimeInput formId
     * @property {string|null} [value] TimeInput value
     * @property {boolean|null} [setValue] TimeInput setValue
     * @property {boolean|null} [disabled] TimeInput disabled
     * @property {ILabelVisibilityMessage|null} [labelVisibility] TimeInput labelVisibility
     * @property {number|Long|null} [step] TimeInput step
     */

    /**
     * Constructs a new TimeInput.
     * @exports TimeInput
     * @classdesc Represents a TimeInput.
     * @implements ITimeInput
     * @constructor
     * @param {ITimeInput=} [properties] Properties to set
     */
    function TimeInput(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TimeInput id.
     * @member {string} id
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.id = "";

    /**
     * TimeInput label.
     * @member {string} label
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.label = "";

    /**
     * TimeInput default.
     * @member {string|null|undefined} default
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype["default"] = null;

    /**
     * TimeInput help.
     * @member {string} help
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.help = "";

    /**
     * TimeInput formId.
     * @member {string} formId
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.formId = "";

    /**
     * TimeInput value.
     * @member {string|null|undefined} value
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.value = null;

    /**
     * TimeInput setValue.
     * @member {boolean} setValue
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.setValue = false;

    /**
     * TimeInput disabled.
     * @member {boolean} disabled
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.disabled = false;

    /**
     * TimeInput labelVisibility.
     * @member {ILabelVisibilityMessage|null|undefined} labelVisibility
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.labelVisibility = null;

    /**
     * TimeInput step.
     * @member {number|Long} step
     * @memberof TimeInput
     * @instance
     */
    TimeInput.prototype.step = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TimeInput.prototype, "_default", {
        get: $util.oneOfGetter($oneOfFields = ["default"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    // Virtual OneOf for proto3 optional field
    Object.defineProperty(TimeInput.prototype, "_value", {
        get: $util.oneOfGetter($oneOfFields = ["value"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new TimeInput instance using the specified properties.
     * @function create
     * @memberof TimeInput
     * @static
     * @param {ITimeInput=} [properties] Properties to set
     * @returns {TimeInput} TimeInput instance
     */
    TimeInput.create = function create(properties) {
        return new TimeInput(properties);
    };

    /**
     * Encodes the specified TimeInput message. Does not implicitly {@link TimeInput.verify|verify} messages.
     * @function encode
     * @memberof TimeInput
     * @static
     * @param {ITimeInput} message TimeInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TimeInput.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
        if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message["default"]);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.formId != null && Object.hasOwnProperty.call(message, "formId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.formId);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.value);
        if (message.setValue != null && Object.hasOwnProperty.call(message, "setValue"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.setValue);
        if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disabled);
        if (message.labelVisibility != null && Object.hasOwnProperty.call(message, "labelVisibility"))
            $root.LabelVisibilityMessage.encode(message.labelVisibility, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.step != null && Object.hasOwnProperty.call(message, "step"))
            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.step);
        return writer;
    };

    /**
     * Encodes the specified TimeInput message, length delimited. Does not implicitly {@link TimeInput.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TimeInput
     * @static
     * @param {ITimeInput} message TimeInput message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TimeInput.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TimeInput message from the specified reader or buffer.
     * @function decode
     * @memberof TimeInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TimeInput} TimeInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TimeInput.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TimeInput();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.label = reader.string();
                    break;
                }
            case 3: {
                    message["default"] = reader.string();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.formId = reader.string();
                    break;
                }
            case 6: {
                    message.value = reader.string();
                    break;
                }
            case 7: {
                    message.setValue = reader.bool();
                    break;
                }
            case 8: {
                    message.disabled = reader.bool();
                    break;
                }
            case 9: {
                    message.labelVisibility = $root.LabelVisibilityMessage.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.step = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TimeInput message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TimeInput
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TimeInput} TimeInput
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TimeInput.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TimeInput message.
     * @function verify
     * @memberof TimeInput
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TimeInput.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message["default"] != null && message.hasOwnProperty("default")) {
            properties._default = 1;
            if (!$util.isString(message["default"]))
                return "default: string expected";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.formId != null && message.hasOwnProperty("formId"))
            if (!$util.isString(message.formId))
                return "formId: string expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            properties._value = 1;
            if (!$util.isString(message.value))
                return "value: string expected";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            if (typeof message.setValue !== "boolean")
                return "setValue: boolean expected";
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            if (typeof message.disabled !== "boolean")
                return "disabled: boolean expected";
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility")) {
            let error = $root.LabelVisibilityMessage.verify(message.labelVisibility);
            if (error)
                return "labelVisibility." + error;
        }
        if (message.step != null && message.hasOwnProperty("step"))
            if (!$util.isInteger(message.step) && !(message.step && $util.isInteger(message.step.low) && $util.isInteger(message.step.high)))
                return "step: integer|Long expected";
        return null;
    };

    /**
     * Creates a TimeInput message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TimeInput
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TimeInput} TimeInput
     */
    TimeInput.fromObject = function fromObject(object) {
        if (object instanceof $root.TimeInput)
            return object;
        let message = new $root.TimeInput();
        if (object.id != null)
            message.id = String(object.id);
        if (object.label != null)
            message.label = String(object.label);
        if (object["default"] != null)
            message["default"] = String(object["default"]);
        if (object.help != null)
            message.help = String(object.help);
        if (object.formId != null)
            message.formId = String(object.formId);
        if (object.value != null)
            message.value = String(object.value);
        if (object.setValue != null)
            message.setValue = Boolean(object.setValue);
        if (object.disabled != null)
            message.disabled = Boolean(object.disabled);
        if (object.labelVisibility != null) {
            if (typeof object.labelVisibility !== "object")
                throw TypeError(".TimeInput.labelVisibility: object expected");
            message.labelVisibility = $root.LabelVisibilityMessage.fromObject(object.labelVisibility);
        }
        if (object.step != null)
            if ($util.Long)
                (message.step = $util.Long.fromValue(object.step)).unsigned = false;
            else if (typeof object.step === "string")
                message.step = parseInt(object.step, 10);
            else if (typeof object.step === "number")
                message.step = object.step;
            else if (typeof object.step === "object")
                message.step = new $util.LongBits(object.step.low >>> 0, object.step.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a TimeInput message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TimeInput
     * @static
     * @param {TimeInput} message TimeInput
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TimeInput.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.label = "";
            object.help = "";
            object.formId = "";
            object.setValue = false;
            object.disabled = false;
            object.labelVisibility = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.step = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.step = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message["default"] != null && message.hasOwnProperty("default")) {
            object["default"] = message["default"];
            if (options.oneofs)
                object._default = "default";
        }
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.formId != null && message.hasOwnProperty("formId"))
            object.formId = message.formId;
        if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
            if (options.oneofs)
                object._value = "value";
        }
        if (message.setValue != null && message.hasOwnProperty("setValue"))
            object.setValue = message.setValue;
        if (message.disabled != null && message.hasOwnProperty("disabled"))
            object.disabled = message.disabled;
        if (message.labelVisibility != null && message.hasOwnProperty("labelVisibility"))
            object.labelVisibility = $root.LabelVisibilityMessage.toObject(message.labelVisibility, options);
        if (message.step != null && message.hasOwnProperty("step"))
            if (typeof message.step === "number")
                object.step = options.longs === String ? String(message.step) : message.step;
            else
                object.step = options.longs === String ? $util.Long.prototype.toString.call(message.step) : options.longs === Number ? new $util.LongBits(message.step.low >>> 0, message.step.high >>> 0).toNumber() : message.step;
        return object;
    };

    /**
     * Converts this TimeInput to JSON.
     * @function toJSON
     * @memberof TimeInput
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TimeInput.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TimeInput
     * @function getTypeUrl
     * @memberof TimeInput
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TimeInput.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TimeInput";
    };

    return TimeInput;
})();

export const Toast = $root.Toast = (() => {

    /**
     * Properties of a Toast.
     * @exports IToast
     * @interface IToast
     * @property {string|null} [body] Toast body
     * @property {string|null} [icon] Toast icon
     */

    /**
     * Constructs a new Toast.
     * @exports Toast
     * @classdesc Represents a Toast.
     * @implements IToast
     * @constructor
     * @param {IToast=} [properties] Properties to set
     */
    function Toast(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Toast body.
     * @member {string} body
     * @memberof Toast
     * @instance
     */
    Toast.prototype.body = "";

    /**
     * Toast icon.
     * @member {string} icon
     * @memberof Toast
     * @instance
     */
    Toast.prototype.icon = "";

    /**
     * Creates a new Toast instance using the specified properties.
     * @function create
     * @memberof Toast
     * @static
     * @param {IToast=} [properties] Properties to set
     * @returns {Toast} Toast instance
     */
    Toast.create = function create(properties) {
        return new Toast(properties);
    };

    /**
     * Encodes the specified Toast message. Does not implicitly {@link Toast.verify|verify} messages.
     * @function encode
     * @memberof Toast
     * @static
     * @param {IToast} message Toast message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Toast.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
        if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.icon);
        return writer;
    };

    /**
     * Encodes the specified Toast message, length delimited. Does not implicitly {@link Toast.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Toast
     * @static
     * @param {IToast} message Toast message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Toast.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Toast message from the specified reader or buffer.
     * @function decode
     * @memberof Toast
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Toast} Toast
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Toast.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Toast();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.body = reader.string();
                    break;
                }
            case 2: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Toast message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Toast
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Toast} Toast
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Toast.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Toast message.
     * @function verify
     * @memberof Toast
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Toast.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.icon != null && message.hasOwnProperty("icon"))
            if (!$util.isString(message.icon))
                return "icon: string expected";
        return null;
    };

    /**
     * Creates a Toast message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Toast
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Toast} Toast
     */
    Toast.fromObject = function fromObject(object) {
        if (object instanceof $root.Toast)
            return object;
        let message = new $root.Toast();
        if (object.body != null)
            message.body = String(object.body);
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    /**
     * Creates a plain object from a Toast message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Toast
     * @static
     * @param {Toast} message Toast
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Toast.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.body = "";
            object.icon = "";
        }
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    /**
     * Converts this Toast to JSON.
     * @function toJSON
     * @memberof Toast
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Toast.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Toast
     * @function getTypeUrl
     * @memberof Toast
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Toast.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Toast";
    };

    return Toast;
})();

export const VegaLiteChart = $root.VegaLiteChart = (() => {

    /**
     * Properties of a VegaLiteChart.
     * @exports IVegaLiteChart
     * @interface IVegaLiteChart
     * @property {string|null} [spec] VegaLiteChart spec
     * @property {IDataFrame|null} [data] VegaLiteChart data
     * @property {Array.<INamedDataSet>|null} [datasets] VegaLiteChart datasets
     * @property {boolean|null} [useContainerWidth] VegaLiteChart useContainerWidth
     */

    /**
     * Constructs a new VegaLiteChart.
     * @exports VegaLiteChart
     * @classdesc Represents a VegaLiteChart.
     * @implements IVegaLiteChart
     * @constructor
     * @param {IVegaLiteChart=} [properties] Properties to set
     */
    function VegaLiteChart(properties) {
        this.datasets = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VegaLiteChart spec.
     * @member {string} spec
     * @memberof VegaLiteChart
     * @instance
     */
    VegaLiteChart.prototype.spec = "";

    /**
     * VegaLiteChart data.
     * @member {IDataFrame|null|undefined} data
     * @memberof VegaLiteChart
     * @instance
     */
    VegaLiteChart.prototype.data = null;

    /**
     * VegaLiteChart datasets.
     * @member {Array.<INamedDataSet>} datasets
     * @memberof VegaLiteChart
     * @instance
     */
    VegaLiteChart.prototype.datasets = $util.emptyArray;

    /**
     * VegaLiteChart useContainerWidth.
     * @member {boolean} useContainerWidth
     * @memberof VegaLiteChart
     * @instance
     */
    VegaLiteChart.prototype.useContainerWidth = false;

    /**
     * Creates a new VegaLiteChart instance using the specified properties.
     * @function create
     * @memberof VegaLiteChart
     * @static
     * @param {IVegaLiteChart=} [properties] Properties to set
     * @returns {VegaLiteChart} VegaLiteChart instance
     */
    VegaLiteChart.create = function create(properties) {
        return new VegaLiteChart(properties);
    };

    /**
     * Encodes the specified VegaLiteChart message. Does not implicitly {@link VegaLiteChart.verify|verify} messages.
     * @function encode
     * @memberof VegaLiteChart
     * @static
     * @param {IVegaLiteChart} message VegaLiteChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VegaLiteChart.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.spec != null && Object.hasOwnProperty.call(message, "spec"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.spec);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.DataFrame.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.datasets != null && message.datasets.length)
            for (let i = 0; i < message.datasets.length; ++i)
                $root.NamedDataSet.encode(message.datasets[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.useContainerWidth != null && Object.hasOwnProperty.call(message, "useContainerWidth"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.useContainerWidth);
        return writer;
    };

    /**
     * Encodes the specified VegaLiteChart message, length delimited. Does not implicitly {@link VegaLiteChart.verify|verify} messages.
     * @function encodeDelimited
     * @memberof VegaLiteChart
     * @static
     * @param {IVegaLiteChart} message VegaLiteChart message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VegaLiteChart.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VegaLiteChart message from the specified reader or buffer.
     * @function decode
     * @memberof VegaLiteChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VegaLiteChart} VegaLiteChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VegaLiteChart.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VegaLiteChart();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.spec = reader.string();
                    break;
                }
            case 2: {
                    message.data = $root.DataFrame.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    if (!(message.datasets && message.datasets.length))
                        message.datasets = [];
                    message.datasets.push($root.NamedDataSet.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.useContainerWidth = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a VegaLiteChart message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof VegaLiteChart
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {VegaLiteChart} VegaLiteChart
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VegaLiteChart.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VegaLiteChart message.
     * @function verify
     * @memberof VegaLiteChart
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VegaLiteChart.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.spec != null && message.hasOwnProperty("spec"))
            if (!$util.isString(message.spec))
                return "spec: string expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.DataFrame.verify(message.data);
            if (error)
                return "data." + error;
        }
        if (message.datasets != null && message.hasOwnProperty("datasets")) {
            if (!Array.isArray(message.datasets))
                return "datasets: array expected";
            for (let i = 0; i < message.datasets.length; ++i) {
                let error = $root.NamedDataSet.verify(message.datasets[i]);
                if (error)
                    return "datasets." + error;
            }
        }
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            if (typeof message.useContainerWidth !== "boolean")
                return "useContainerWidth: boolean expected";
        return null;
    };

    /**
     * Creates a VegaLiteChart message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof VegaLiteChart
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {VegaLiteChart} VegaLiteChart
     */
    VegaLiteChart.fromObject = function fromObject(object) {
        if (object instanceof $root.VegaLiteChart)
            return object;
        let message = new $root.VegaLiteChart();
        if (object.spec != null)
            message.spec = String(object.spec);
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".VegaLiteChart.data: object expected");
            message.data = $root.DataFrame.fromObject(object.data);
        }
        if (object.datasets) {
            if (!Array.isArray(object.datasets))
                throw TypeError(".VegaLiteChart.datasets: array expected");
            message.datasets = [];
            for (let i = 0; i < object.datasets.length; ++i) {
                if (typeof object.datasets[i] !== "object")
                    throw TypeError(".VegaLiteChart.datasets: object expected");
                message.datasets[i] = $root.NamedDataSet.fromObject(object.datasets[i]);
            }
        }
        if (object.useContainerWidth != null)
            message.useContainerWidth = Boolean(object.useContainerWidth);
        return message;
    };

    /**
     * Creates a plain object from a VegaLiteChart message. Also converts values to other types if specified.
     * @function toObject
     * @memberof VegaLiteChart
     * @static
     * @param {VegaLiteChart} message VegaLiteChart
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VegaLiteChart.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.datasets = [];
        if (options.defaults) {
            object.spec = "";
            object.data = null;
            object.useContainerWidth = false;
        }
        if (message.spec != null && message.hasOwnProperty("spec"))
            object.spec = message.spec;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.DataFrame.toObject(message.data, options);
        if (message.datasets && message.datasets.length) {
            object.datasets = [];
            for (let j = 0; j < message.datasets.length; ++j)
                object.datasets[j] = $root.NamedDataSet.toObject(message.datasets[j], options);
        }
        if (message.useContainerWidth != null && message.hasOwnProperty("useContainerWidth"))
            object.useContainerWidth = message.useContainerWidth;
        return object;
    };

    /**
     * Converts this VegaLiteChart to JSON.
     * @function toJSON
     * @memberof VegaLiteChart
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VegaLiteChart.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for VegaLiteChart
     * @function getTypeUrl
     * @memberof VegaLiteChart
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    VegaLiteChart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VegaLiteChart";
    };

    return VegaLiteChart;
})();

export const NamedDataSet = $root.NamedDataSet = (() => {

    /**
     * Properties of a NamedDataSet.
     * @exports INamedDataSet
     * @interface INamedDataSet
     * @property {string|null} [name] NamedDataSet name
     * @property {boolean|null} [hasName] NamedDataSet hasName
     * @property {IDataFrame|null} [data] NamedDataSet data
     */

    /**
     * Constructs a new NamedDataSet.
     * @exports NamedDataSet
     * @classdesc Represents a NamedDataSet.
     * @implements INamedDataSet
     * @constructor
     * @param {INamedDataSet=} [properties] Properties to set
     */
    function NamedDataSet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NamedDataSet name.
     * @member {string} name
     * @memberof NamedDataSet
     * @instance
     */
    NamedDataSet.prototype.name = "";

    /**
     * NamedDataSet hasName.
     * @member {boolean} hasName
     * @memberof NamedDataSet
     * @instance
     */
    NamedDataSet.prototype.hasName = false;

    /**
     * NamedDataSet data.
     * @member {IDataFrame|null|undefined} data
     * @memberof NamedDataSet
     * @instance
     */
    NamedDataSet.prototype.data = null;

    /**
     * Creates a new NamedDataSet instance using the specified properties.
     * @function create
     * @memberof NamedDataSet
     * @static
     * @param {INamedDataSet=} [properties] Properties to set
     * @returns {NamedDataSet} NamedDataSet instance
     */
    NamedDataSet.create = function create(properties) {
        return new NamedDataSet(properties);
    };

    /**
     * Encodes the specified NamedDataSet message. Does not implicitly {@link NamedDataSet.verify|verify} messages.
     * @function encode
     * @memberof NamedDataSet
     * @static
     * @param {INamedDataSet} message NamedDataSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NamedDataSet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            $root.DataFrame.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.hasName != null && Object.hasOwnProperty.call(message, "hasName"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasName);
        return writer;
    };

    /**
     * Encodes the specified NamedDataSet message, length delimited. Does not implicitly {@link NamedDataSet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof NamedDataSet
     * @static
     * @param {INamedDataSet} message NamedDataSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NamedDataSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NamedDataSet message from the specified reader or buffer.
     * @function decode
     * @memberof NamedDataSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NamedDataSet} NamedDataSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NamedDataSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NamedDataSet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.hasName = reader.bool();
                    break;
                }
            case 2: {
                    message.data = $root.DataFrame.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a NamedDataSet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof NamedDataSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {NamedDataSet} NamedDataSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NamedDataSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NamedDataSet message.
     * @function verify
     * @memberof NamedDataSet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NamedDataSet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.hasName != null && message.hasOwnProperty("hasName"))
            if (typeof message.hasName !== "boolean")
                return "hasName: boolean expected";
        if (message.data != null && message.hasOwnProperty("data")) {
            let error = $root.DataFrame.verify(message.data);
            if (error)
                return "data." + error;
        }
        return null;
    };

    /**
     * Creates a NamedDataSet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof NamedDataSet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {NamedDataSet} NamedDataSet
     */
    NamedDataSet.fromObject = function fromObject(object) {
        if (object instanceof $root.NamedDataSet)
            return object;
        let message = new $root.NamedDataSet();
        if (object.name != null)
            message.name = String(object.name);
        if (object.hasName != null)
            message.hasName = Boolean(object.hasName);
        if (object.data != null) {
            if (typeof object.data !== "object")
                throw TypeError(".NamedDataSet.data: object expected");
            message.data = $root.DataFrame.fromObject(object.data);
        }
        return message;
    };

    /**
     * Creates a plain object from a NamedDataSet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof NamedDataSet
     * @static
     * @param {NamedDataSet} message NamedDataSet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NamedDataSet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            object.data = null;
            object.hasName = false;
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = $root.DataFrame.toObject(message.data, options);
        if (message.hasName != null && message.hasOwnProperty("hasName"))
            object.hasName = message.hasName;
        return object;
    };

    /**
     * Converts this NamedDataSet to JSON.
     * @function toJSON
     * @memberof NamedDataSet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NamedDataSet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NamedDataSet
     * @function getTypeUrl
     * @memberof NamedDataSet
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NamedDataSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NamedDataSet";
    };

    return NamedDataSet;
})();

export const SubtitleTrack = $root.SubtitleTrack = (() => {

    /**
     * Properties of a SubtitleTrack.
     * @exports ISubtitleTrack
     * @interface ISubtitleTrack
     * @property {string|null} [label] SubtitleTrack label
     * @property {string|null} [url] SubtitleTrack url
     */

    /**
     * Constructs a new SubtitleTrack.
     * @exports SubtitleTrack
     * @classdesc Represents a SubtitleTrack.
     * @implements ISubtitleTrack
     * @constructor
     * @param {ISubtitleTrack=} [properties] Properties to set
     */
    function SubtitleTrack(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubtitleTrack label.
     * @member {string} label
     * @memberof SubtitleTrack
     * @instance
     */
    SubtitleTrack.prototype.label = "";

    /**
     * SubtitleTrack url.
     * @member {string} url
     * @memberof SubtitleTrack
     * @instance
     */
    SubtitleTrack.prototype.url = "";

    /**
     * Creates a new SubtitleTrack instance using the specified properties.
     * @function create
     * @memberof SubtitleTrack
     * @static
     * @param {ISubtitleTrack=} [properties] Properties to set
     * @returns {SubtitleTrack} SubtitleTrack instance
     */
    SubtitleTrack.create = function create(properties) {
        return new SubtitleTrack(properties);
    };

    /**
     * Encodes the specified SubtitleTrack message. Does not implicitly {@link SubtitleTrack.verify|verify} messages.
     * @function encode
     * @memberof SubtitleTrack
     * @static
     * @param {ISubtitleTrack} message SubtitleTrack message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubtitleTrack.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
        return writer;
    };

    /**
     * Encodes the specified SubtitleTrack message, length delimited. Does not implicitly {@link SubtitleTrack.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubtitleTrack
     * @static
     * @param {ISubtitleTrack} message SubtitleTrack message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubtitleTrack.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubtitleTrack message from the specified reader or buffer.
     * @function decode
     * @memberof SubtitleTrack
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubtitleTrack} SubtitleTrack
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubtitleTrack.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubtitleTrack();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.label = reader.string();
                    break;
                }
            case 2: {
                    message.url = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubtitleTrack message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubtitleTrack
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubtitleTrack} SubtitleTrack
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubtitleTrack.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubtitleTrack message.
     * @function verify
     * @memberof SubtitleTrack
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubtitleTrack.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        return null;
    };

    /**
     * Creates a SubtitleTrack message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubtitleTrack
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubtitleTrack} SubtitleTrack
     */
    SubtitleTrack.fromObject = function fromObject(object) {
        if (object instanceof $root.SubtitleTrack)
            return object;
        let message = new $root.SubtitleTrack();
        if (object.label != null)
            message.label = String(object.label);
        if (object.url != null)
            message.url = String(object.url);
        return message;
    };

    /**
     * Creates a plain object from a SubtitleTrack message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubtitleTrack
     * @static
     * @param {SubtitleTrack} message SubtitleTrack
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubtitleTrack.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.label = "";
            object.url = "";
        }
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        return object;
    };

    /**
     * Converts this SubtitleTrack to JSON.
     * @function toJSON
     * @memberof SubtitleTrack
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubtitleTrack.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubtitleTrack
     * @function getTypeUrl
     * @memberof SubtitleTrack
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubtitleTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubtitleTrack";
    };

    return SubtitleTrack;
})();

export const Video = $root.Video = (() => {

    /**
     * Properties of a Video.
     * @exports IVideo
     * @interface IVideo
     * @property {string|null} [url] Video url
     * @property {number|null} [startTime] Video startTime
     * @property {Video.Type|null} [type] Video type
     * @property {Array.<ISubtitleTrack>|null} [subtitles] Video subtitles
     * @property {number|null} [endTime] Video endTime
     * @property {boolean|null} [loop] Video loop
     * @property {boolean|null} [autoplay] Video autoplay
     * @property {boolean|null} [muted] Video muted
     * @property {string|null} [id] Video id
     */

    /**
     * Constructs a new Video.
     * @exports Video
     * @classdesc Represents a Video.
     * @implements IVideo
     * @constructor
     * @param {IVideo=} [properties] Properties to set
     */
    function Video(properties) {
        this.subtitles = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Video url.
     * @member {string} url
     * @memberof Video
     * @instance
     */
    Video.prototype.url = "";

    /**
     * Video startTime.
     * @member {number} startTime
     * @memberof Video
     * @instance
     */
    Video.prototype.startTime = 0;

    /**
     * Video type.
     * @member {Video.Type} type
     * @memberof Video
     * @instance
     */
    Video.prototype.type = 0;

    /**
     * Video subtitles.
     * @member {Array.<ISubtitleTrack>} subtitles
     * @memberof Video
     * @instance
     */
    Video.prototype.subtitles = $util.emptyArray;

    /**
     * Video endTime.
     * @member {number} endTime
     * @memberof Video
     * @instance
     */
    Video.prototype.endTime = 0;

    /**
     * Video loop.
     * @member {boolean} loop
     * @memberof Video
     * @instance
     */
    Video.prototype.loop = false;

    /**
     * Video autoplay.
     * @member {boolean} autoplay
     * @memberof Video
     * @instance
     */
    Video.prototype.autoplay = false;

    /**
     * Video muted.
     * @member {boolean} muted
     * @memberof Video
     * @instance
     */
    Video.prototype.muted = false;

    /**
     * Video id.
     * @member {string} id
     * @memberof Video
     * @instance
     */
    Video.prototype.id = "";

    /**
     * Creates a new Video instance using the specified properties.
     * @function create
     * @memberof Video
     * @static
     * @param {IVideo=} [properties] Properties to set
     * @returns {Video} Video instance
     */
    Video.create = function create(properties) {
        return new Video(properties);
    };

    /**
     * Encodes the specified Video message. Does not implicitly {@link Video.verify|verify} messages.
     * @function encode
     * @memberof Video
     * @static
     * @param {IVideo} message Video message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Video.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startTime);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
        if (message.subtitles != null && message.subtitles.length)
            for (let i = 0; i < message.subtitles.length; ++i)
                $root.SubtitleTrack.encode(message.subtitles[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.endTime);
        if (message.loop != null && Object.hasOwnProperty.call(message, "loop"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.loop);
        if (message.autoplay != null && Object.hasOwnProperty.call(message, "autoplay"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.autoplay);
        if (message.muted != null && Object.hasOwnProperty.call(message, "muted"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.muted);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.id);
        return writer;
    };

    /**
     * Encodes the specified Video message, length delimited. Does not implicitly {@link Video.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Video
     * @static
     * @param {IVideo} message Video message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Video.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Video message from the specified reader or buffer.
     * @function decode
     * @memberof Video
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Video} Video
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Video.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Video();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 6: {
                    message.url = reader.string();
                    break;
                }
            case 3: {
                    message.startTime = reader.int32();
                    break;
                }
            case 5: {
                    message.type = reader.int32();
                    break;
                }
            case 7: {
                    if (!(message.subtitles && message.subtitles.length))
                        message.subtitles = [];
                    message.subtitles.push($root.SubtitleTrack.decode(reader, reader.uint32()));
                    break;
                }
            case 8: {
                    message.endTime = reader.int32();
                    break;
                }
            case 9: {
                    message.loop = reader.bool();
                    break;
                }
            case 10: {
                    message.autoplay = reader.bool();
                    break;
                }
            case 11: {
                    message.muted = reader.bool();
                    break;
                }
            case 12: {
                    message.id = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Video message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Video
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Video} Video
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Video.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Video message.
     * @function verify
     * @memberof Video
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Video.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            if (!$util.isInteger(message.startTime))
                return "startTime: integer expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.subtitles != null && message.hasOwnProperty("subtitles")) {
            if (!Array.isArray(message.subtitles))
                return "subtitles: array expected";
            for (let i = 0; i < message.subtitles.length; ++i) {
                let error = $root.SubtitleTrack.verify(message.subtitles[i]);
                if (error)
                    return "subtitles." + error;
            }
        }
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            if (!$util.isInteger(message.endTime))
                return "endTime: integer expected";
        if (message.loop != null && message.hasOwnProperty("loop"))
            if (typeof message.loop !== "boolean")
                return "loop: boolean expected";
        if (message.autoplay != null && message.hasOwnProperty("autoplay"))
            if (typeof message.autoplay !== "boolean")
                return "autoplay: boolean expected";
        if (message.muted != null && message.hasOwnProperty("muted"))
            if (typeof message.muted !== "boolean")
                return "muted: boolean expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        return null;
    };

    /**
     * Creates a Video message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Video
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Video} Video
     */
    Video.fromObject = function fromObject(object) {
        if (object instanceof $root.Video)
            return object;
        let message = new $root.Video();
        if (object.url != null)
            message.url = String(object.url);
        if (object.startTime != null)
            message.startTime = object.startTime | 0;
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNUSED":
        case 0:
            message.type = 0;
            break;
        case "NATIVE":
        case 1:
            message.type = 1;
            break;
        case "YOUTUBE_IFRAME":
        case 2:
            message.type = 2;
            break;
        }
        if (object.subtitles) {
            if (!Array.isArray(object.subtitles))
                throw TypeError(".Video.subtitles: array expected");
            message.subtitles = [];
            for (let i = 0; i < object.subtitles.length; ++i) {
                if (typeof object.subtitles[i] !== "object")
                    throw TypeError(".Video.subtitles: object expected");
                message.subtitles[i] = $root.SubtitleTrack.fromObject(object.subtitles[i]);
            }
        }
        if (object.endTime != null)
            message.endTime = object.endTime | 0;
        if (object.loop != null)
            message.loop = Boolean(object.loop);
        if (object.autoplay != null)
            message.autoplay = Boolean(object.autoplay);
        if (object.muted != null)
            message.muted = Boolean(object.muted);
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    /**
     * Creates a plain object from a Video message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Video
     * @static
     * @param {Video} message Video
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Video.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.subtitles = [];
        if (options.defaults) {
            object.startTime = 0;
            object.type = options.enums === String ? "UNUSED" : 0;
            object.url = "";
            object.endTime = 0;
            object.loop = false;
            object.autoplay = false;
            object.muted = false;
            object.id = "";
        }
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = message.startTime;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.Video.Type[message.type] === undefined ? message.type : $root.Video.Type[message.type] : message.type;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.subtitles && message.subtitles.length) {
            object.subtitles = [];
            for (let j = 0; j < message.subtitles.length; ++j)
                object.subtitles[j] = $root.SubtitleTrack.toObject(message.subtitles[j], options);
        }
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = message.endTime;
        if (message.loop != null && message.hasOwnProperty("loop"))
            object.loop = message.loop;
        if (message.autoplay != null && message.hasOwnProperty("autoplay"))
            object.autoplay = message.autoplay;
        if (message.muted != null && message.hasOwnProperty("muted"))
            object.muted = message.muted;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        return object;
    };

    /**
     * Converts this Video to JSON.
     * @function toJSON
     * @memberof Video
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Video.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Video
     * @function getTypeUrl
     * @memberof Video
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Video.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Video";
    };

    /**
     * Type enum.
     * @name Video.Type
     * @enum {number}
     * @property {number} UNUSED=0 UNUSED value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} YOUTUBE_IFRAME=2 YOUTUBE_IFRAME value
     */
    Video.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNUSED"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "YOUTUBE_IFRAME"] = 2;
        return values;
    })();

    return Video;
})();

export const Heading = $root.Heading = (() => {

    /**
     * Properties of a Heading.
     * @exports IHeading
     * @interface IHeading
     * @property {string|null} [tag] Heading tag
     * @property {string|null} [anchor] Heading anchor
     * @property {string|null} [body] Heading body
     * @property {string|null} [help] Heading help
     * @property {boolean|null} [hideAnchor] Heading hideAnchor
     * @property {string|null} [divider] Heading divider
     */

    /**
     * Constructs a new Heading.
     * @exports Heading
     * @classdesc Represents a Heading.
     * @implements IHeading
     * @constructor
     * @param {IHeading=} [properties] Properties to set
     */
    function Heading(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Heading tag.
     * @member {string} tag
     * @memberof Heading
     * @instance
     */
    Heading.prototype.tag = "";

    /**
     * Heading anchor.
     * @member {string} anchor
     * @memberof Heading
     * @instance
     */
    Heading.prototype.anchor = "";

    /**
     * Heading body.
     * @member {string} body
     * @memberof Heading
     * @instance
     */
    Heading.prototype.body = "";

    /**
     * Heading help.
     * @member {string} help
     * @memberof Heading
     * @instance
     */
    Heading.prototype.help = "";

    /**
     * Heading hideAnchor.
     * @member {boolean} hideAnchor
     * @memberof Heading
     * @instance
     */
    Heading.prototype.hideAnchor = false;

    /**
     * Heading divider.
     * @member {string} divider
     * @memberof Heading
     * @instance
     */
    Heading.prototype.divider = "";

    /**
     * Creates a new Heading instance using the specified properties.
     * @function create
     * @memberof Heading
     * @static
     * @param {IHeading=} [properties] Properties to set
     * @returns {Heading} Heading instance
     */
    Heading.create = function create(properties) {
        return new Heading(properties);
    };

    /**
     * Encodes the specified Heading message. Does not implicitly {@link Heading.verify|verify} messages.
     * @function encode
     * @memberof Heading
     * @static
     * @param {IHeading} message Heading message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heading.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.tag);
        if (message.anchor != null && Object.hasOwnProperty.call(message, "anchor"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.anchor);
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.body);
        if (message.help != null && Object.hasOwnProperty.call(message, "help"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
        if (message.hideAnchor != null && Object.hasOwnProperty.call(message, "hideAnchor"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.hideAnchor);
        if (message.divider != null && Object.hasOwnProperty.call(message, "divider"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.divider);
        return writer;
    };

    /**
     * Encodes the specified Heading message, length delimited. Does not implicitly {@link Heading.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Heading
     * @static
     * @param {IHeading} message Heading message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heading.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Heading message from the specified reader or buffer.
     * @function decode
     * @memberof Heading
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Heading} Heading
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heading.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Heading();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.tag = reader.string();
                    break;
                }
            case 2: {
                    message.anchor = reader.string();
                    break;
                }
            case 3: {
                    message.body = reader.string();
                    break;
                }
            case 4: {
                    message.help = reader.string();
                    break;
                }
            case 5: {
                    message.hideAnchor = reader.bool();
                    break;
                }
            case 6: {
                    message.divider = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Heading message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Heading
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Heading} Heading
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heading.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Heading message.
     * @function verify
     * @memberof Heading
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Heading.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tag != null && message.hasOwnProperty("tag"))
            if (!$util.isString(message.tag))
                return "tag: string expected";
        if (message.anchor != null && message.hasOwnProperty("anchor"))
            if (!$util.isString(message.anchor))
                return "anchor: string expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!$util.isString(message.body))
                return "body: string expected";
        if (message.help != null && message.hasOwnProperty("help"))
            if (!$util.isString(message.help))
                return "help: string expected";
        if (message.hideAnchor != null && message.hasOwnProperty("hideAnchor"))
            if (typeof message.hideAnchor !== "boolean")
                return "hideAnchor: boolean expected";
        if (message.divider != null && message.hasOwnProperty("divider"))
            if (!$util.isString(message.divider))
                return "divider: string expected";
        return null;
    };

    /**
     * Creates a Heading message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Heading
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Heading} Heading
     */
    Heading.fromObject = function fromObject(object) {
        if (object instanceof $root.Heading)
            return object;
        let message = new $root.Heading();
        if (object.tag != null)
            message.tag = String(object.tag);
        if (object.anchor != null)
            message.anchor = String(object.anchor);
        if (object.body != null)
            message.body = String(object.body);
        if (object.help != null)
            message.help = String(object.help);
        if (object.hideAnchor != null)
            message.hideAnchor = Boolean(object.hideAnchor);
        if (object.divider != null)
            message.divider = String(object.divider);
        return message;
    };

    /**
     * Creates a plain object from a Heading message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Heading
     * @static
     * @param {Heading} message Heading
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Heading.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.tag = "";
            object.anchor = "";
            object.body = "";
            object.help = "";
            object.hideAnchor = false;
            object.divider = "";
        }
        if (message.tag != null && message.hasOwnProperty("tag"))
            object.tag = message.tag;
        if (message.anchor != null && message.hasOwnProperty("anchor"))
            object.anchor = message.anchor;
        if (message.body != null && message.hasOwnProperty("body"))
            object.body = message.body;
        if (message.help != null && message.hasOwnProperty("help"))
            object.help = message.help;
        if (message.hideAnchor != null && message.hasOwnProperty("hideAnchor"))
            object.hideAnchor = message.hideAnchor;
        if (message.divider != null && message.hasOwnProperty("divider"))
            object.divider = message.divider;
        return object;
    };

    /**
     * Converts this Heading to JSON.
     * @function toJSON
     * @memberof Heading
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Heading.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Heading
     * @function getTypeUrl
     * @memberof Heading
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Heading.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Heading";
    };

    return Heading;
})();

export const ForwardMsg = $root.ForwardMsg = (() => {

    /**
     * Properties of a ForwardMsg.
     * @exports IForwardMsg
     * @interface IForwardMsg
     * @property {string|null} [hash] ForwardMsg hash
     * @property {IForwardMsgMetadata|null} [metadata] ForwardMsg metadata
     * @property {INewSession|null} [newSession] ForwardMsg newSession
     * @property {IDelta|null} [delta] ForwardMsg delta
     * @property {IPageInfo|null} [pageInfoChanged] ForwardMsg pageInfoChanged
     * @property {IPageConfig|null} [pageConfigChanged] ForwardMsg pageConfigChanged
     * @property {ForwardMsg.ScriptFinishedStatus|null} [scriptFinished] ForwardMsg scriptFinished
     * @property {IGitInfo|null} [gitInfoChanged] ForwardMsg gitInfoChanged
     * @property {IPageProfile|null} [pageProfile] ForwardMsg pageProfile
     * @property {ISessionStatus|null} [sessionStatusChanged] ForwardMsg sessionStatusChanged
     * @property {ISessionEvent|null} [sessionEvent] ForwardMsg sessionEvent
     * @property {INavigation|null} [navigation] ForwardMsg navigation
     * @property {IPageNotFound|null} [pageNotFound] ForwardMsg pageNotFound
     * @property {IPagesChanged|null} [pagesChanged] ForwardMsg pagesChanged
     * @property {IFileURLsResponse|null} [fileUrlsResponse] ForwardMsg fileUrlsResponse
     * @property {IAutoRerun|null} [autoRerun] ForwardMsg autoRerun
     * @property {ILogo|null} [logo] ForwardMsg logo
     * @property {IParentMessage|null} [parentMessage] ForwardMsg parentMessage
     * @property {string|null} [refHash] ForwardMsg refHash
     * @property {string|null} [debugLastBackmsgId] ForwardMsg debugLastBackmsgId
     */

    /**
     * Constructs a new ForwardMsg.
     * @exports ForwardMsg
     * @classdesc Represents a ForwardMsg.
     * @implements IForwardMsg
     * @constructor
     * @param {IForwardMsg=} [properties] Properties to set
     */
    function ForwardMsg(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardMsg hash.
     * @member {string} hash
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.hash = "";

    /**
     * ForwardMsg metadata.
     * @member {IForwardMsgMetadata|null|undefined} metadata
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.metadata = null;

    /**
     * ForwardMsg newSession.
     * @member {INewSession|null|undefined} newSession
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.newSession = null;

    /**
     * ForwardMsg delta.
     * @member {IDelta|null|undefined} delta
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.delta = null;

    /**
     * ForwardMsg pageInfoChanged.
     * @member {IPageInfo|null|undefined} pageInfoChanged
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.pageInfoChanged = null;

    /**
     * ForwardMsg pageConfigChanged.
     * @member {IPageConfig|null|undefined} pageConfigChanged
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.pageConfigChanged = null;

    /**
     * ForwardMsg scriptFinished.
     * @member {ForwardMsg.ScriptFinishedStatus|null|undefined} scriptFinished
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.scriptFinished = null;

    /**
     * ForwardMsg gitInfoChanged.
     * @member {IGitInfo|null|undefined} gitInfoChanged
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.gitInfoChanged = null;

    /**
     * ForwardMsg pageProfile.
     * @member {IPageProfile|null|undefined} pageProfile
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.pageProfile = null;

    /**
     * ForwardMsg sessionStatusChanged.
     * @member {ISessionStatus|null|undefined} sessionStatusChanged
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.sessionStatusChanged = null;

    /**
     * ForwardMsg sessionEvent.
     * @member {ISessionEvent|null|undefined} sessionEvent
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.sessionEvent = null;

    /**
     * ForwardMsg navigation.
     * @member {INavigation|null|undefined} navigation
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.navigation = null;

    /**
     * ForwardMsg pageNotFound.
     * @member {IPageNotFound|null|undefined} pageNotFound
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.pageNotFound = null;

    /**
     * ForwardMsg pagesChanged.
     * @member {IPagesChanged|null|undefined} pagesChanged
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.pagesChanged = null;

    /**
     * ForwardMsg fileUrlsResponse.
     * @member {IFileURLsResponse|null|undefined} fileUrlsResponse
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.fileUrlsResponse = null;

    /**
     * ForwardMsg autoRerun.
     * @member {IAutoRerun|null|undefined} autoRerun
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.autoRerun = null;

    /**
     * ForwardMsg logo.
     * @member {ILogo|null|undefined} logo
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.logo = null;

    /**
     * ForwardMsg parentMessage.
     * @member {IParentMessage|null|undefined} parentMessage
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.parentMessage = null;

    /**
     * ForwardMsg refHash.
     * @member {string|null|undefined} refHash
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.refHash = null;

    /**
     * ForwardMsg debugLastBackmsgId.
     * @member {string} debugLastBackmsgId
     * @memberof ForwardMsg
     * @instance
     */
    ForwardMsg.prototype.debugLastBackmsgId = "";

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * ForwardMsg type.
     * @member {"newSession"|"delta"|"pageInfoChanged"|"pageConfigChanged"|"scriptFinished"|"gitInfoChanged"|"pageProfile"|"sessionStatusChanged"|"sessionEvent"|"navigation"|"pageNotFound"|"pagesChanged"|"fileUrlsResponse"|"autoRerun"|"logo"|"parentMessage"|"refHash"|undefined} type
     * @memberof ForwardMsg
     * @instance
     */
    Object.defineProperty(ForwardMsg.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["newSession", "delta", "pageInfoChanged", "pageConfigChanged", "scriptFinished", "gitInfoChanged", "pageProfile", "sessionStatusChanged", "sessionEvent", "navigation", "pageNotFound", "pagesChanged", "fileUrlsResponse", "autoRerun", "logo", "parentMessage", "refHash"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new ForwardMsg instance using the specified properties.
     * @function create
     * @memberof ForwardMsg
     * @static
     * @param {IForwardMsg=} [properties] Properties to set
     * @returns {ForwardMsg} ForwardMsg instance
     */
    ForwardMsg.create = function create(properties) {
        return new ForwardMsg(properties);
    };

    /**
     * Encodes the specified ForwardMsg message. Does not implicitly {@link ForwardMsg.verify|verify} messages.
     * @function encode
     * @memberof ForwardMsg
     * @static
     * @param {IForwardMsg} message ForwardMsg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardMsg.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
        if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
            $root.ForwardMsgMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.newSession != null && Object.hasOwnProperty.call(message, "newSession"))
            $root.NewSession.encode(message.newSession, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.delta != null && Object.hasOwnProperty.call(message, "delta"))
            $root.Delta.encode(message.delta, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.scriptFinished != null && Object.hasOwnProperty.call(message, "scriptFinished"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.scriptFinished);
        if (message.sessionStatusChanged != null && Object.hasOwnProperty.call(message, "sessionStatusChanged"))
            $root.SessionStatus.encode(message.sessionStatusChanged, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.sessionEvent != null && Object.hasOwnProperty.call(message, "sessionEvent"))
            $root.SessionEvent.encode(message.sessionEvent, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.refHash != null && Object.hasOwnProperty.call(message, "refHash"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.refHash);
        if (message.pageInfoChanged != null && Object.hasOwnProperty.call(message, "pageInfoChanged"))
            $root.PageInfo.encode(message.pageInfoChanged, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.pageConfigChanged != null && Object.hasOwnProperty.call(message, "pageConfigChanged"))
            $root.PageConfig.encode(message.pageConfigChanged, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.gitInfoChanged != null && Object.hasOwnProperty.call(message, "gitInfoChanged"))
            $root.GitInfo.encode(message.gitInfoChanged, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.pageNotFound != null && Object.hasOwnProperty.call(message, "pageNotFound"))
            $root.PageNotFound.encode(message.pageNotFound, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.pagesChanged != null && Object.hasOwnProperty.call(message, "pagesChanged"))
            $root.PagesChanged.encode(message.pagesChanged, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.debugLastBackmsgId != null && Object.hasOwnProperty.call(message, "debugLastBackmsgId"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.debugLastBackmsgId);
        if (message.pageProfile != null && Object.hasOwnProperty.call(message, "pageProfile"))
            $root.PageProfile.encode(message.pageProfile, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.fileUrlsResponse != null && Object.hasOwnProperty.call(message, "fileUrlsResponse"))
            $root.FileURLsResponse.encode(message.fileUrlsResponse, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.parentMessage != null && Object.hasOwnProperty.call(message, "parentMessage"))
            $root.ParentMessage.encode(message.parentMessage, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.autoRerun != null && Object.hasOwnProperty.call(message, "autoRerun"))
            $root.AutoRerun.encode(message.autoRerun, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.logo != null && Object.hasOwnProperty.call(message, "logo"))
            $root.Logo.encode(message.logo, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.navigation != null && Object.hasOwnProperty.call(message, "navigation"))
            $root.Navigation.encode(message.navigation, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ForwardMsg message, length delimited. Does not implicitly {@link ForwardMsg.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ForwardMsg
     * @static
     * @param {IForwardMsg} message ForwardMsg message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardMsg.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ForwardMsg message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardMsg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardMsg} ForwardMsg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardMsg.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardMsg();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.hash = reader.string();
                    break;
                }
            case 2: {
                    message.metadata = $root.ForwardMsgMetadata.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.newSession = $root.NewSession.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.delta = $root.Delta.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.pageInfoChanged = $root.PageInfo.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.pageConfigChanged = $root.PageConfig.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.scriptFinished = reader.int32();
                    break;
                }
            case 14: {
                    message.gitInfoChanged = $root.GitInfo.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.pageProfile = $root.PageProfile.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.sessionStatusChanged = $root.SessionStatus.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.sessionEvent = $root.SessionEvent.decode(reader, reader.uint32());
                    break;
                }
            case 23: {
                    message.navigation = $root.Navigation.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.pageNotFound = $root.PageNotFound.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.pagesChanged = $root.PagesChanged.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    message.fileUrlsResponse = $root.FileURLsResponse.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.autoRerun = $root.AutoRerun.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.logo = $root.Logo.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.parentMessage = $root.ParentMessage.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.refHash = reader.string();
                    break;
                }
            case 17: {
                    message.debugLastBackmsgId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ForwardMsg message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ForwardMsg
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ForwardMsg} ForwardMsg
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardMsg.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ForwardMsg message.
     * @function verify
     * @memberof ForwardMsg
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ForwardMsg.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.hash != null && message.hasOwnProperty("hash"))
            if (!$util.isString(message.hash))
                return "hash: string expected";
        if (message.metadata != null && message.hasOwnProperty("metadata")) {
            let error = $root.ForwardMsgMetadata.verify(message.metadata);
            if (error)
                return "metadata." + error;
        }
        if (message.newSession != null && message.hasOwnProperty("newSession")) {
            properties.type = 1;
            {
                let error = $root.NewSession.verify(message.newSession);
                if (error)
                    return "newSession." + error;
            }
        }
        if (message.delta != null && message.hasOwnProperty("delta")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Delta.verify(message.delta);
                if (error)
                    return "delta." + error;
            }
        }
        if (message.pageInfoChanged != null && message.hasOwnProperty("pageInfoChanged")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PageInfo.verify(message.pageInfoChanged);
                if (error)
                    return "pageInfoChanged." + error;
            }
        }
        if (message.pageConfigChanged != null && message.hasOwnProperty("pageConfigChanged")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PageConfig.verify(message.pageConfigChanged);
                if (error)
                    return "pageConfigChanged." + error;
            }
        }
        if (message.scriptFinished != null && message.hasOwnProperty("scriptFinished")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            switch (message.scriptFinished) {
            default:
                return "scriptFinished: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        }
        if (message.gitInfoChanged != null && message.hasOwnProperty("gitInfoChanged")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.GitInfo.verify(message.gitInfoChanged);
                if (error)
                    return "gitInfoChanged." + error;
            }
        }
        if (message.pageProfile != null && message.hasOwnProperty("pageProfile")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PageProfile.verify(message.pageProfile);
                if (error)
                    return "pageProfile." + error;
            }
        }
        if (message.sessionStatusChanged != null && message.hasOwnProperty("sessionStatusChanged")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.SessionStatus.verify(message.sessionStatusChanged);
                if (error)
                    return "sessionStatusChanged." + error;
            }
        }
        if (message.sessionEvent != null && message.hasOwnProperty("sessionEvent")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.SessionEvent.verify(message.sessionEvent);
                if (error)
                    return "sessionEvent." + error;
            }
        }
        if (message.navigation != null && message.hasOwnProperty("navigation")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Navigation.verify(message.navigation);
                if (error)
                    return "navigation." + error;
            }
        }
        if (message.pageNotFound != null && message.hasOwnProperty("pageNotFound")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PageNotFound.verify(message.pageNotFound);
                if (error)
                    return "pageNotFound." + error;
            }
        }
        if (message.pagesChanged != null && message.hasOwnProperty("pagesChanged")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.PagesChanged.verify(message.pagesChanged);
                if (error)
                    return "pagesChanged." + error;
            }
        }
        if (message.fileUrlsResponse != null && message.hasOwnProperty("fileUrlsResponse")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.FileURLsResponse.verify(message.fileUrlsResponse);
                if (error)
                    return "fileUrlsResponse." + error;
            }
        }
        if (message.autoRerun != null && message.hasOwnProperty("autoRerun")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.AutoRerun.verify(message.autoRerun);
                if (error)
                    return "autoRerun." + error;
            }
        }
        if (message.logo != null && message.hasOwnProperty("logo")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Logo.verify(message.logo);
                if (error)
                    return "logo." + error;
            }
        }
        if (message.parentMessage != null && message.hasOwnProperty("parentMessage")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.ParentMessage.verify(message.parentMessage);
                if (error)
                    return "parentMessage." + error;
            }
        }
        if (message.refHash != null && message.hasOwnProperty("refHash")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (!$util.isString(message.refHash))
                return "refHash: string expected";
        }
        if (message.debugLastBackmsgId != null && message.hasOwnProperty("debugLastBackmsgId"))
            if (!$util.isString(message.debugLastBackmsgId))
                return "debugLastBackmsgId: string expected";
        return null;
    };

    /**
     * Creates a ForwardMsg message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ForwardMsg
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ForwardMsg} ForwardMsg
     */
    ForwardMsg.fromObject = function fromObject(object) {
        if (object instanceof $root.ForwardMsg)
            return object;
        let message = new $root.ForwardMsg();
        if (object.hash != null)
            message.hash = String(object.hash);
        if (object.metadata != null) {
            if (typeof object.metadata !== "object")
                throw TypeError(".ForwardMsg.metadata: object expected");
            message.metadata = $root.ForwardMsgMetadata.fromObject(object.metadata);
        }
        if (object.newSession != null) {
            if (typeof object.newSession !== "object")
                throw TypeError(".ForwardMsg.newSession: object expected");
            message.newSession = $root.NewSession.fromObject(object.newSession);
        }
        if (object.delta != null) {
            if (typeof object.delta !== "object")
                throw TypeError(".ForwardMsg.delta: object expected");
            message.delta = $root.Delta.fromObject(object.delta);
        }
        if (object.pageInfoChanged != null) {
            if (typeof object.pageInfoChanged !== "object")
                throw TypeError(".ForwardMsg.pageInfoChanged: object expected");
            message.pageInfoChanged = $root.PageInfo.fromObject(object.pageInfoChanged);
        }
        if (object.pageConfigChanged != null) {
            if (typeof object.pageConfigChanged !== "object")
                throw TypeError(".ForwardMsg.pageConfigChanged: object expected");
            message.pageConfigChanged = $root.PageConfig.fromObject(object.pageConfigChanged);
        }
        switch (object.scriptFinished) {
        default:
            if (typeof object.scriptFinished === "number") {
                message.scriptFinished = object.scriptFinished;
                break;
            }
            break;
        case "FINISHED_SUCCESSFULLY":
        case 0:
            message.scriptFinished = 0;
            break;
        case "FINISHED_WITH_COMPILE_ERROR":
        case 1:
            message.scriptFinished = 1;
            break;
        case "FINISHED_EARLY_FOR_RERUN":
        case 2:
            message.scriptFinished = 2;
            break;
        case "FINISHED_FRAGMENT_RUN_SUCCESSFULLY":
        case 3:
            message.scriptFinished = 3;
            break;
        }
        if (object.gitInfoChanged != null) {
            if (typeof object.gitInfoChanged !== "object")
                throw TypeError(".ForwardMsg.gitInfoChanged: object expected");
            message.gitInfoChanged = $root.GitInfo.fromObject(object.gitInfoChanged);
        }
        if (object.pageProfile != null) {
            if (typeof object.pageProfile !== "object")
                throw TypeError(".ForwardMsg.pageProfile: object expected");
            message.pageProfile = $root.PageProfile.fromObject(object.pageProfile);
        }
        if (object.sessionStatusChanged != null) {
            if (typeof object.sessionStatusChanged !== "object")
                throw TypeError(".ForwardMsg.sessionStatusChanged: object expected");
            message.sessionStatusChanged = $root.SessionStatus.fromObject(object.sessionStatusChanged);
        }
        if (object.sessionEvent != null) {
            if (typeof object.sessionEvent !== "object")
                throw TypeError(".ForwardMsg.sessionEvent: object expected");
            message.sessionEvent = $root.SessionEvent.fromObject(object.sessionEvent);
        }
        if (object.navigation != null) {
            if (typeof object.navigation !== "object")
                throw TypeError(".ForwardMsg.navigation: object expected");
            message.navigation = $root.Navigation.fromObject(object.navigation);
        }
        if (object.pageNotFound != null) {
            if (typeof object.pageNotFound !== "object")
                throw TypeError(".ForwardMsg.pageNotFound: object expected");
            message.pageNotFound = $root.PageNotFound.fromObject(object.pageNotFound);
        }
        if (object.pagesChanged != null) {
            if (typeof object.pagesChanged !== "object")
                throw TypeError(".ForwardMsg.pagesChanged: object expected");
            message.pagesChanged = $root.PagesChanged.fromObject(object.pagesChanged);
        }
        if (object.fileUrlsResponse != null) {
            if (typeof object.fileUrlsResponse !== "object")
                throw TypeError(".ForwardMsg.fileUrlsResponse: object expected");
            message.fileUrlsResponse = $root.FileURLsResponse.fromObject(object.fileUrlsResponse);
        }
        if (object.autoRerun != null) {
            if (typeof object.autoRerun !== "object")
                throw TypeError(".ForwardMsg.autoRerun: object expected");
            message.autoRerun = $root.AutoRerun.fromObject(object.autoRerun);
        }
        if (object.logo != null) {
            if (typeof object.logo !== "object")
                throw TypeError(".ForwardMsg.logo: object expected");
            message.logo = $root.Logo.fromObject(object.logo);
        }
        if (object.parentMessage != null) {
            if (typeof object.parentMessage !== "object")
                throw TypeError(".ForwardMsg.parentMessage: object expected");
            message.parentMessage = $root.ParentMessage.fromObject(object.parentMessage);
        }
        if (object.refHash != null)
            message.refHash = String(object.refHash);
        if (object.debugLastBackmsgId != null)
            message.debugLastBackmsgId = String(object.debugLastBackmsgId);
        return message;
    };

    /**
     * Creates a plain object from a ForwardMsg message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ForwardMsg
     * @static
     * @param {ForwardMsg} message ForwardMsg
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ForwardMsg.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.hash = "";
            object.metadata = null;
            object.debugLastBackmsgId = "";
        }
        if (message.hash != null && message.hasOwnProperty("hash"))
            object.hash = message.hash;
        if (message.metadata != null && message.hasOwnProperty("metadata"))
            object.metadata = $root.ForwardMsgMetadata.toObject(message.metadata, options);
        if (message.newSession != null && message.hasOwnProperty("newSession")) {
            object.newSession = $root.NewSession.toObject(message.newSession, options);
            if (options.oneofs)
                object.type = "newSession";
        }
        if (message.delta != null && message.hasOwnProperty("delta")) {
            object.delta = $root.Delta.toObject(message.delta, options);
            if (options.oneofs)
                object.type = "delta";
        }
        if (message.scriptFinished != null && message.hasOwnProperty("scriptFinished")) {
            object.scriptFinished = options.enums === String ? $root.ForwardMsg.ScriptFinishedStatus[message.scriptFinished] === undefined ? message.scriptFinished : $root.ForwardMsg.ScriptFinishedStatus[message.scriptFinished] : message.scriptFinished;
            if (options.oneofs)
                object.type = "scriptFinished";
        }
        if (message.sessionStatusChanged != null && message.hasOwnProperty("sessionStatusChanged")) {
            object.sessionStatusChanged = $root.SessionStatus.toObject(message.sessionStatusChanged, options);
            if (options.oneofs)
                object.type = "sessionStatusChanged";
        }
        if (message.sessionEvent != null && message.hasOwnProperty("sessionEvent")) {
            object.sessionEvent = $root.SessionEvent.toObject(message.sessionEvent, options);
            if (options.oneofs)
                object.type = "sessionEvent";
        }
        if (message.refHash != null && message.hasOwnProperty("refHash")) {
            object.refHash = message.refHash;
            if (options.oneofs)
                object.type = "refHash";
        }
        if (message.pageInfoChanged != null && message.hasOwnProperty("pageInfoChanged")) {
            object.pageInfoChanged = $root.PageInfo.toObject(message.pageInfoChanged, options);
            if (options.oneofs)
                object.type = "pageInfoChanged";
        }
        if (message.pageConfigChanged != null && message.hasOwnProperty("pageConfigChanged")) {
            object.pageConfigChanged = $root.PageConfig.toObject(message.pageConfigChanged, options);
            if (options.oneofs)
                object.type = "pageConfigChanged";
        }
        if (message.gitInfoChanged != null && message.hasOwnProperty("gitInfoChanged")) {
            object.gitInfoChanged = $root.GitInfo.toObject(message.gitInfoChanged, options);
            if (options.oneofs)
                object.type = "gitInfoChanged";
        }
        if (message.pageNotFound != null && message.hasOwnProperty("pageNotFound")) {
            object.pageNotFound = $root.PageNotFound.toObject(message.pageNotFound, options);
            if (options.oneofs)
                object.type = "pageNotFound";
        }
        if (message.pagesChanged != null && message.hasOwnProperty("pagesChanged")) {
            object.pagesChanged = $root.PagesChanged.toObject(message.pagesChanged, options);
            if (options.oneofs)
                object.type = "pagesChanged";
        }
        if (message.debugLastBackmsgId != null && message.hasOwnProperty("debugLastBackmsgId"))
            object.debugLastBackmsgId = message.debugLastBackmsgId;
        if (message.pageProfile != null && message.hasOwnProperty("pageProfile")) {
            object.pageProfile = $root.PageProfile.toObject(message.pageProfile, options);
            if (options.oneofs)
                object.type = "pageProfile";
        }
        if (message.fileUrlsResponse != null && message.hasOwnProperty("fileUrlsResponse")) {
            object.fileUrlsResponse = $root.FileURLsResponse.toObject(message.fileUrlsResponse, options);
            if (options.oneofs)
                object.type = "fileUrlsResponse";
        }
        if (message.parentMessage != null && message.hasOwnProperty("parentMessage")) {
            object.parentMessage = $root.ParentMessage.toObject(message.parentMessage, options);
            if (options.oneofs)
                object.type = "parentMessage";
        }
        if (message.autoRerun != null && message.hasOwnProperty("autoRerun")) {
            object.autoRerun = $root.AutoRerun.toObject(message.autoRerun, options);
            if (options.oneofs)
                object.type = "autoRerun";
        }
        if (message.logo != null && message.hasOwnProperty("logo")) {
            object.logo = $root.Logo.toObject(message.logo, options);
            if (options.oneofs)
                object.type = "logo";
        }
        if (message.navigation != null && message.hasOwnProperty("navigation")) {
            object.navigation = $root.Navigation.toObject(message.navigation, options);
            if (options.oneofs)
                object.type = "navigation";
        }
        return object;
    };

    /**
     * Converts this ForwardMsg to JSON.
     * @function toJSON
     * @memberof ForwardMsg
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ForwardMsg.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ForwardMsg
     * @function getTypeUrl
     * @memberof ForwardMsg
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ForwardMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ForwardMsg";
    };

    /**
     * ScriptFinishedStatus enum.
     * @name ForwardMsg.ScriptFinishedStatus
     * @enum {number}
     * @property {number} FINISHED_SUCCESSFULLY=0 FINISHED_SUCCESSFULLY value
     * @property {number} FINISHED_WITH_COMPILE_ERROR=1 FINISHED_WITH_COMPILE_ERROR value
     * @property {number} FINISHED_EARLY_FOR_RERUN=2 FINISHED_EARLY_FOR_RERUN value
     * @property {number} FINISHED_FRAGMENT_RUN_SUCCESSFULLY=3 FINISHED_FRAGMENT_RUN_SUCCESSFULLY value
     */
    ForwardMsg.ScriptFinishedStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FINISHED_SUCCESSFULLY"] = 0;
        values[valuesById[1] = "FINISHED_WITH_COMPILE_ERROR"] = 1;
        values[valuesById[2] = "FINISHED_EARLY_FOR_RERUN"] = 2;
        values[valuesById[3] = "FINISHED_FRAGMENT_RUN_SUCCESSFULLY"] = 3;
        return values;
    })();

    return ForwardMsg;
})();

export const ForwardMsgMetadata = $root.ForwardMsgMetadata = (() => {

    /**
     * Properties of a ForwardMsgMetadata.
     * @exports IForwardMsgMetadata
     * @interface IForwardMsgMetadata
     * @property {boolean|null} [cacheable] ForwardMsgMetadata cacheable
     * @property {Array.<number>|null} [deltaPath] ForwardMsgMetadata deltaPath
     * @property {IElementDimensionSpec|null} [elementDimensionSpec] ForwardMsgMetadata elementDimensionSpec
     * @property {string|null} [activeScriptHash] ForwardMsgMetadata activeScriptHash
     */

    /**
     * Constructs a new ForwardMsgMetadata.
     * @exports ForwardMsgMetadata
     * @classdesc Represents a ForwardMsgMetadata.
     * @implements IForwardMsgMetadata
     * @constructor
     * @param {IForwardMsgMetadata=} [properties] Properties to set
     */
    function ForwardMsgMetadata(properties) {
        this.deltaPath = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardMsgMetadata cacheable.
     * @member {boolean} cacheable
     * @memberof ForwardMsgMetadata
     * @instance
     */
    ForwardMsgMetadata.prototype.cacheable = false;

    /**
     * ForwardMsgMetadata deltaPath.
     * @member {Array.<number>} deltaPath
     * @memberof ForwardMsgMetadata
     * @instance
     */
    ForwardMsgMetadata.prototype.deltaPath = $util.emptyArray;

    /**
     * ForwardMsgMetadata elementDimensionSpec.
     * @member {IElementDimensionSpec|null|undefined} elementDimensionSpec
     * @memberof ForwardMsgMetadata
     * @instance
     */
    ForwardMsgMetadata.prototype.elementDimensionSpec = null;

    /**
     * ForwardMsgMetadata activeScriptHash.
     * @member {string} activeScriptHash
     * @memberof ForwardMsgMetadata
     * @instance
     */
    ForwardMsgMetadata.prototype.activeScriptHash = "";

    /**
     * Creates a new ForwardMsgMetadata instance using the specified properties.
     * @function create
     * @memberof ForwardMsgMetadata
     * @static
     * @param {IForwardMsgMetadata=} [properties] Properties to set
     * @returns {ForwardMsgMetadata} ForwardMsgMetadata instance
     */
    ForwardMsgMetadata.create = function create(properties) {
        return new ForwardMsgMetadata(properties);
    };

    /**
     * Encodes the specified ForwardMsgMetadata message. Does not implicitly {@link ForwardMsgMetadata.verify|verify} messages.
     * @function encode
     * @memberof ForwardMsgMetadata
     * @static
     * @param {IForwardMsgMetadata} message ForwardMsgMetadata message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardMsgMetadata.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.cacheable != null && Object.hasOwnProperty.call(message, "cacheable"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.cacheable);
        if (message.deltaPath != null && message.deltaPath.length) {
            writer.uint32(/* id 2, wireType 2 =*/18).fork();
            for (let i = 0; i < message.deltaPath.length; ++i)
                writer.uint32(message.deltaPath[i]);
            writer.ldelim();
        }
        if (message.elementDimensionSpec != null && Object.hasOwnProperty.call(message, "elementDimensionSpec"))
            $root.ElementDimensionSpec.encode(message.elementDimensionSpec, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.activeScriptHash != null && Object.hasOwnProperty.call(message, "activeScriptHash"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.activeScriptHash);
        return writer;
    };

    /**
     * Encodes the specified ForwardMsgMetadata message, length delimited. Does not implicitly {@link ForwardMsgMetadata.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ForwardMsgMetadata
     * @static
     * @param {IForwardMsgMetadata} message ForwardMsgMetadata message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardMsgMetadata.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ForwardMsgMetadata message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardMsgMetadata
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardMsgMetadata} ForwardMsgMetadata
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardMsgMetadata.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardMsgMetadata();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.cacheable = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.deltaPath && message.deltaPath.length))
                        message.deltaPath = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.deltaPath.push(reader.uint32());
                    } else
                        message.deltaPath.push(reader.uint32());
                    break;
                }
            case 3: {
                    message.elementDimensionSpec = $root.ElementDimensionSpec.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.activeScriptHash = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ForwardMsgMetadata message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ForwardMsgMetadata
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ForwardMsgMetadata} ForwardMsgMetadata
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardMsgMetadata.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ForwardMsgMetadata message.
     * @function verify
     * @memberof ForwardMsgMetadata
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ForwardMsgMetadata.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.cacheable != null && message.hasOwnProperty("cacheable"))
            if (typeof message.cacheable !== "boolean")
                return "cacheable: boolean expected";
        if (message.deltaPath != null && message.hasOwnProperty("deltaPath")) {
            if (!Array.isArray(message.deltaPath))
                return "deltaPath: array expected";
            for (let i = 0; i < message.deltaPath.length; ++i)
                if (!$util.isInteger(message.deltaPath[i]))
                    return "deltaPath: integer[] expected";
        }
        if (message.elementDimensionSpec != null && message.hasOwnProperty("elementDimensionSpec")) {
            let error = $root.ElementDimensionSpec.verify(message.elementDimensionSpec);
            if (error)
                return "elementDimensionSpec." + error;
        }
        if (message.activeScriptHash != null && message.hasOwnProperty("activeScriptHash"))
            if (!$util.isString(message.activeScriptHash))
                return "activeScriptHash: string expected";
        return null;
    };

    /**
     * Creates a ForwardMsgMetadata message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ForwardMsgMetadata
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ForwardMsgMetadata} ForwardMsgMetadata
     */
    ForwardMsgMetadata.fromObject = function fromObject(object) {
        if (object instanceof $root.ForwardMsgMetadata)
            return object;
        let message = new $root.ForwardMsgMetadata();
        if (object.cacheable != null)
            message.cacheable = Boolean(object.cacheable);
        if (object.deltaPath) {
            if (!Array.isArray(object.deltaPath))
                throw TypeError(".ForwardMsgMetadata.deltaPath: array expected");
            message.deltaPath = [];
            for (let i = 0; i < object.deltaPath.length; ++i)
                message.deltaPath[i] = object.deltaPath[i] >>> 0;
        }
        if (object.elementDimensionSpec != null) {
            if (typeof object.elementDimensionSpec !== "object")
                throw TypeError(".ForwardMsgMetadata.elementDimensionSpec: object expected");
            message.elementDimensionSpec = $root.ElementDimensionSpec.fromObject(object.elementDimensionSpec);
        }
        if (object.activeScriptHash != null)
            message.activeScriptHash = String(object.activeScriptHash);
        return message;
    };

    /**
     * Creates a plain object from a ForwardMsgMetadata message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ForwardMsgMetadata
     * @static
     * @param {ForwardMsgMetadata} message ForwardMsgMetadata
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ForwardMsgMetadata.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.deltaPath = [];
        if (options.defaults) {
            object.cacheable = false;
            object.elementDimensionSpec = null;
            object.activeScriptHash = "";
        }
        if (message.cacheable != null && message.hasOwnProperty("cacheable"))
            object.cacheable = message.cacheable;
        if (message.deltaPath && message.deltaPath.length) {
            object.deltaPath = [];
            for (let j = 0; j < message.deltaPath.length; ++j)
                object.deltaPath[j] = message.deltaPath[j];
        }
        if (message.elementDimensionSpec != null && message.hasOwnProperty("elementDimensionSpec"))
            object.elementDimensionSpec = $root.ElementDimensionSpec.toObject(message.elementDimensionSpec, options);
        if (message.activeScriptHash != null && message.hasOwnProperty("activeScriptHash"))
            object.activeScriptHash = message.activeScriptHash;
        return object;
    };

    /**
     * Converts this ForwardMsgMetadata to JSON.
     * @function toJSON
     * @memberof ForwardMsgMetadata
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ForwardMsgMetadata.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ForwardMsgMetadata
     * @function getTypeUrl
     * @memberof ForwardMsgMetadata
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ForwardMsgMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ForwardMsgMetadata";
    };

    return ForwardMsgMetadata;
})();

export const ElementDimensionSpec = $root.ElementDimensionSpec = (() => {

    /**
     * Properties of an ElementDimensionSpec.
     * @exports IElementDimensionSpec
     * @interface IElementDimensionSpec
     * @property {number|null} [width] ElementDimensionSpec width
     * @property {number|null} [height] ElementDimensionSpec height
     */

    /**
     * Constructs a new ElementDimensionSpec.
     * @exports ElementDimensionSpec
     * @classdesc Represents an ElementDimensionSpec.
     * @implements IElementDimensionSpec
     * @constructor
     * @param {IElementDimensionSpec=} [properties] Properties to set
     */
    function ElementDimensionSpec(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ElementDimensionSpec width.
     * @member {number} width
     * @memberof ElementDimensionSpec
     * @instance
     */
    ElementDimensionSpec.prototype.width = 0;

    /**
     * ElementDimensionSpec height.
     * @member {number} height
     * @memberof ElementDimensionSpec
     * @instance
     */
    ElementDimensionSpec.prototype.height = 0;

    /**
     * Creates a new ElementDimensionSpec instance using the specified properties.
     * @function create
     * @memberof ElementDimensionSpec
     * @static
     * @param {IElementDimensionSpec=} [properties] Properties to set
     * @returns {ElementDimensionSpec} ElementDimensionSpec instance
     */
    ElementDimensionSpec.create = function create(properties) {
        return new ElementDimensionSpec(properties);
    };

    /**
     * Encodes the specified ElementDimensionSpec message. Does not implicitly {@link ElementDimensionSpec.verify|verify} messages.
     * @function encode
     * @memberof ElementDimensionSpec
     * @static
     * @param {IElementDimensionSpec} message ElementDimensionSpec message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ElementDimensionSpec.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.height);
        return writer;
    };

    /**
     * Encodes the specified ElementDimensionSpec message, length delimited. Does not implicitly {@link ElementDimensionSpec.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ElementDimensionSpec
     * @static
     * @param {IElementDimensionSpec} message ElementDimensionSpec message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ElementDimensionSpec.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ElementDimensionSpec message from the specified reader or buffer.
     * @function decode
     * @memberof ElementDimensionSpec
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ElementDimensionSpec} ElementDimensionSpec
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ElementDimensionSpec.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ElementDimensionSpec();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.width = reader.uint32();
                    break;
                }
            case 2: {
                    message.height = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ElementDimensionSpec message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ElementDimensionSpec
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ElementDimensionSpec} ElementDimensionSpec
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ElementDimensionSpec.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ElementDimensionSpec message.
     * @function verify
     * @memberof ElementDimensionSpec
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ElementDimensionSpec.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isInteger(message.width))
                return "width: integer expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isInteger(message.height))
                return "height: integer expected";
        return null;
    };

    /**
     * Creates an ElementDimensionSpec message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ElementDimensionSpec
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ElementDimensionSpec} ElementDimensionSpec
     */
    ElementDimensionSpec.fromObject = function fromObject(object) {
        if (object instanceof $root.ElementDimensionSpec)
            return object;
        let message = new $root.ElementDimensionSpec();
        if (object.width != null)
            message.width = object.width >>> 0;
        if (object.height != null)
            message.height = object.height >>> 0;
        return message;
    };

    /**
     * Creates a plain object from an ElementDimensionSpec message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ElementDimensionSpec
     * @static
     * @param {ElementDimensionSpec} message ElementDimensionSpec
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ElementDimensionSpec.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.width = 0;
            object.height = 0;
        }
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        return object;
    };

    /**
     * Converts this ElementDimensionSpec to JSON.
     * @function toJSON
     * @memberof ElementDimensionSpec
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ElementDimensionSpec.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ElementDimensionSpec
     * @function getTypeUrl
     * @memberof ElementDimensionSpec
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ElementDimensionSpec.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ElementDimensionSpec";
    };

    return ElementDimensionSpec;
})();

export const GitInfo = $root.GitInfo = (() => {

    /**
     * Properties of a GitInfo.
     * @exports IGitInfo
     * @interface IGitInfo
     * @property {string|null} [repository] GitInfo repository
     * @property {string|null} [branch] GitInfo branch
     * @property {string|null} [module] GitInfo module
     * @property {Array.<string>|null} [untrackedFiles] GitInfo untrackedFiles
     * @property {Array.<string>|null} [uncommittedFiles] GitInfo uncommittedFiles
     * @property {GitInfo.GitStates|null} [state] GitInfo state
     */

    /**
     * Constructs a new GitInfo.
     * @exports GitInfo
     * @classdesc Represents a GitInfo.
     * @implements IGitInfo
     * @constructor
     * @param {IGitInfo=} [properties] Properties to set
     */
    function GitInfo(properties) {
        this.untrackedFiles = [];
        this.uncommittedFiles = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GitInfo repository.
     * @member {string} repository
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.repository = "";

    /**
     * GitInfo branch.
     * @member {string} branch
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.branch = "";

    /**
     * GitInfo module.
     * @member {string} module
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.module = "";

    /**
     * GitInfo untrackedFiles.
     * @member {Array.<string>} untrackedFiles
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.untrackedFiles = $util.emptyArray;

    /**
     * GitInfo uncommittedFiles.
     * @member {Array.<string>} uncommittedFiles
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.uncommittedFiles = $util.emptyArray;

    /**
     * GitInfo state.
     * @member {GitInfo.GitStates} state
     * @memberof GitInfo
     * @instance
     */
    GitInfo.prototype.state = 0;

    /**
     * Creates a new GitInfo instance using the specified properties.
     * @function create
     * @memberof GitInfo
     * @static
     * @param {IGitInfo=} [properties] Properties to set
     * @returns {GitInfo} GitInfo instance
     */
    GitInfo.create = function create(properties) {
        return new GitInfo(properties);
    };

    /**
     * Encodes the specified GitInfo message. Does not implicitly {@link GitInfo.verify|verify} messages.
     * @function encode
     * @memberof GitInfo
     * @static
     * @param {IGitInfo} message GitInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GitInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.repository != null && Object.hasOwnProperty.call(message, "repository"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.repository);
        if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.branch);
        if (message.module != null && Object.hasOwnProperty.call(message, "module"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.module);
        if (message.untrackedFiles != null && message.untrackedFiles.length)
            for (let i = 0; i < message.untrackedFiles.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.untrackedFiles[i]);
        if (message.uncommittedFiles != null && message.uncommittedFiles.length)
            for (let i = 0; i < message.uncommittedFiles.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.uncommittedFiles[i]);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified GitInfo message, length delimited. Does not implicitly {@link GitInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GitInfo
     * @static
     * @param {IGitInfo} message GitInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GitInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GitInfo message from the specified reader or buffer.
     * @function decode
     * @memberof GitInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GitInfo} GitInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GitInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GitInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.repository = reader.string();
                    break;
                }
            case 2: {
                    message.branch = reader.string();
                    break;
                }
            case 3: {
                    message.module = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.untrackedFiles && message.untrackedFiles.length))
                        message.untrackedFiles = [];
                    message.untrackedFiles.push(reader.string());
                    break;
                }
            case 5: {
                    if (!(message.uncommittedFiles && message.uncommittedFiles.length))
                        message.uncommittedFiles = [];
                    message.uncommittedFiles.push(reader.string());
                    break;
                }
            case 6: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GitInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GitInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GitInfo} GitInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GitInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GitInfo message.
     * @function verify
     * @memberof GitInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GitInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.repository != null && message.hasOwnProperty("repository"))
            if (!$util.isString(message.repository))
                return "repository: string expected";
        if (message.branch != null && message.hasOwnProperty("branch"))
            if (!$util.isString(message.branch))
                return "branch: string expected";
        if (message.module != null && message.hasOwnProperty("module"))
            if (!$util.isString(message.module))
                return "module: string expected";
        if (message.untrackedFiles != null && message.hasOwnProperty("untrackedFiles")) {
            if (!Array.isArray(message.untrackedFiles))
                return "untrackedFiles: array expected";
            for (let i = 0; i < message.untrackedFiles.length; ++i)
                if (!$util.isString(message.untrackedFiles[i]))
                    return "untrackedFiles: string[] expected";
        }
        if (message.uncommittedFiles != null && message.hasOwnProperty("uncommittedFiles")) {
            if (!Array.isArray(message.uncommittedFiles))
                return "uncommittedFiles: array expected";
            for (let i = 0; i < message.uncommittedFiles.length; ++i)
                if (!$util.isString(message.uncommittedFiles[i]))
                    return "uncommittedFiles: string[] expected";
        }
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        return null;
    };

    /**
     * Creates a GitInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GitInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GitInfo} GitInfo
     */
    GitInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.GitInfo)
            return object;
        let message = new $root.GitInfo();
        if (object.repository != null)
            message.repository = String(object.repository);
        if (object.branch != null)
            message.branch = String(object.branch);
        if (object.module != null)
            message.module = String(object.module);
        if (object.untrackedFiles) {
            if (!Array.isArray(object.untrackedFiles))
                throw TypeError(".GitInfo.untrackedFiles: array expected");
            message.untrackedFiles = [];
            for (let i = 0; i < object.untrackedFiles.length; ++i)
                message.untrackedFiles[i] = String(object.untrackedFiles[i]);
        }
        if (object.uncommittedFiles) {
            if (!Array.isArray(object.uncommittedFiles))
                throw TypeError(".GitInfo.uncommittedFiles: array expected");
            message.uncommittedFiles = [];
            for (let i = 0; i < object.uncommittedFiles.length; ++i)
                message.uncommittedFiles[i] = String(object.uncommittedFiles[i]);
        }
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "DEFAULT":
        case 0:
            message.state = 0;
            break;
        case "HEAD_DETACHED":
        case 1:
            message.state = 1;
            break;
        case "AHEAD_OF_REMOTE":
        case 2:
            message.state = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a GitInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GitInfo
     * @static
     * @param {GitInfo} message GitInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GitInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.untrackedFiles = [];
            object.uncommittedFiles = [];
        }
        if (options.defaults) {
            object.repository = "";
            object.branch = "";
            object.module = "";
            object.state = options.enums === String ? "DEFAULT" : 0;
        }
        if (message.repository != null && message.hasOwnProperty("repository"))
            object.repository = message.repository;
        if (message.branch != null && message.hasOwnProperty("branch"))
            object.branch = message.branch;
        if (message.module != null && message.hasOwnProperty("module"))
            object.module = message.module;
        if (message.untrackedFiles && message.untrackedFiles.length) {
            object.untrackedFiles = [];
            for (let j = 0; j < message.untrackedFiles.length; ++j)
                object.untrackedFiles[j] = message.untrackedFiles[j];
        }
        if (message.uncommittedFiles && message.uncommittedFiles.length) {
            object.uncommittedFiles = [];
            for (let j = 0; j < message.uncommittedFiles.length; ++j)
                object.uncommittedFiles[j] = message.uncommittedFiles[j];
        }
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.GitInfo.GitStates[message.state] === undefined ? message.state : $root.GitInfo.GitStates[message.state] : message.state;
        return object;
    };

    /**
     * Converts this GitInfo to JSON.
     * @function toJSON
     * @memberof GitInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GitInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GitInfo
     * @function getTypeUrl
     * @memberof GitInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GitInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GitInfo";
    };

    /**
     * GitStates enum.
     * @name GitInfo.GitStates
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} HEAD_DETACHED=1 HEAD_DETACHED value
     * @property {number} AHEAD_OF_REMOTE=2 AHEAD_OF_REMOTE value
     */
    GitInfo.GitStates = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "HEAD_DETACHED"] = 1;
        values[valuesById[2] = "AHEAD_OF_REMOTE"] = 2;
        return values;
    })();

    return GitInfo;
})();

export const Logo = $root.Logo = (() => {

    /**
     * Properties of a Logo.
     * @exports ILogo
     * @interface ILogo
     * @property {string|null} [image] Logo image
     * @property {string|null} [link] Logo link
     * @property {string|null} [iconImage] Logo iconImage
     * @property {string|null} [size] Logo size
     */

    /**
     * Constructs a new Logo.
     * @exports Logo
     * @classdesc Represents a Logo.
     * @implements ILogo
     * @constructor
     * @param {ILogo=} [properties] Properties to set
     */
    function Logo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Logo image.
     * @member {string} image
     * @memberof Logo
     * @instance
     */
    Logo.prototype.image = "";

    /**
     * Logo link.
     * @member {string} link
     * @memberof Logo
     * @instance
     */
    Logo.prototype.link = "";

    /**
     * Logo iconImage.
     * @member {string} iconImage
     * @memberof Logo
     * @instance
     */
    Logo.prototype.iconImage = "";

    /**
     * Logo size.
     * @member {string} size
     * @memberof Logo
     * @instance
     */
    Logo.prototype.size = "";

    /**
     * Creates a new Logo instance using the specified properties.
     * @function create
     * @memberof Logo
     * @static
     * @param {ILogo=} [properties] Properties to set
     * @returns {Logo} Logo instance
     */
    Logo.create = function create(properties) {
        return new Logo(properties);
    };

    /**
     * Encodes the specified Logo message. Does not implicitly {@link Logo.verify|verify} messages.
     * @function encode
     * @memberof Logo
     * @static
     * @param {ILogo} message Logo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Logo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.image != null && Object.hasOwnProperty.call(message, "image"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.image);
        if (message.link != null && Object.hasOwnProperty.call(message, "link"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
        if (message.iconImage != null && Object.hasOwnProperty.call(message, "iconImage"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.iconImage);
        if (message.size != null && Object.hasOwnProperty.call(message, "size"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.size);
        return writer;
    };

    /**
     * Encodes the specified Logo message, length delimited. Does not implicitly {@link Logo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Logo
     * @static
     * @param {ILogo} message Logo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Logo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Logo message from the specified reader or buffer.
     * @function decode
     * @memberof Logo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Logo} Logo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Logo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Logo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.image = reader.string();
                    break;
                }
            case 2: {
                    message.link = reader.string();
                    break;
                }
            case 3: {
                    message.iconImage = reader.string();
                    break;
                }
            case 4: {
                    message.size = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Logo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Logo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Logo} Logo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Logo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Logo message.
     * @function verify
     * @memberof Logo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Logo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.image != null && message.hasOwnProperty("image"))
            if (!$util.isString(message.image))
                return "image: string expected";
        if (message.link != null && message.hasOwnProperty("link"))
            if (!$util.isString(message.link))
                return "link: string expected";
        if (message.iconImage != null && message.hasOwnProperty("iconImage"))
            if (!$util.isString(message.iconImage))
                return "iconImage: string expected";
        if (message.size != null && message.hasOwnProperty("size"))
            if (!$util.isString(message.size))
                return "size: string expected";
        return null;
    };

    /**
     * Creates a Logo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Logo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Logo} Logo
     */
    Logo.fromObject = function fromObject(object) {
        if (object instanceof $root.Logo)
            return object;
        let message = new $root.Logo();
        if (object.image != null)
            message.image = String(object.image);
        if (object.link != null)
            message.link = String(object.link);
        if (object.iconImage != null)
            message.iconImage = String(object.iconImage);
        if (object.size != null)
            message.size = String(object.size);
        return message;
    };

    /**
     * Creates a plain object from a Logo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Logo
     * @static
     * @param {Logo} message Logo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Logo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.image = "";
            object.link = "";
            object.iconImage = "";
            object.size = "";
        }
        if (message.image != null && message.hasOwnProperty("image"))
            object.image = message.image;
        if (message.link != null && message.hasOwnProperty("link"))
            object.link = message.link;
        if (message.iconImage != null && message.hasOwnProperty("iconImage"))
            object.iconImage = message.iconImage;
        if (message.size != null && message.hasOwnProperty("size"))
            object.size = message.size;
        return object;
    };

    /**
     * Converts this Logo to JSON.
     * @function toJSON
     * @memberof Logo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Logo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Logo
     * @function getTypeUrl
     * @memberof Logo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Logo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Logo";
    };

    return Logo;
})();

export const Navigation = $root.Navigation = (() => {

    /**
     * Properties of a Navigation.
     * @exports INavigation
     * @interface INavigation
     * @property {Array.<string>|null} [sections] Navigation sections
     * @property {Array.<IAppPage>|null} [appPages] Navigation appPages
     * @property {Navigation.Position|null} [position] Navigation position
     * @property {string|null} [pageScriptHash] Navigation pageScriptHash
     * @property {boolean|null} [expanded] Navigation expanded
     */

    /**
     * Constructs a new Navigation.
     * @exports Navigation
     * @classdesc Represents a Navigation.
     * @implements INavigation
     * @constructor
     * @param {INavigation=} [properties] Properties to set
     */
    function Navigation(properties) {
        this.sections = [];
        this.appPages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Navigation sections.
     * @member {Array.<string>} sections
     * @memberof Navigation
     * @instance
     */
    Navigation.prototype.sections = $util.emptyArray;

    /**
     * Navigation appPages.
     * @member {Array.<IAppPage>} appPages
     * @memberof Navigation
     * @instance
     */
    Navigation.prototype.appPages = $util.emptyArray;

    /**
     * Navigation position.
     * @member {Navigation.Position} position
     * @memberof Navigation
     * @instance
     */
    Navigation.prototype.position = 0;

    /**
     * Navigation pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof Navigation
     * @instance
     */
    Navigation.prototype.pageScriptHash = "";

    /**
     * Navigation expanded.
     * @member {boolean} expanded
     * @memberof Navigation
     * @instance
     */
    Navigation.prototype.expanded = false;

    /**
     * Creates a new Navigation instance using the specified properties.
     * @function create
     * @memberof Navigation
     * @static
     * @param {INavigation=} [properties] Properties to set
     * @returns {Navigation} Navigation instance
     */
    Navigation.create = function create(properties) {
        return new Navigation(properties);
    };

    /**
     * Encodes the specified Navigation message. Does not implicitly {@link Navigation.verify|verify} messages.
     * @function encode
     * @memberof Navigation
     * @static
     * @param {INavigation} message Navigation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Navigation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sections != null && message.sections.length)
            for (let i = 0; i < message.sections.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sections[i]);
        if (message.appPages != null && message.appPages.length)
            for (let i = 0; i < message.appPages.length; ++i)
                $root.AppPage.encode(message.appPages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.position != null && Object.hasOwnProperty.call(message, "position"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.position);
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.pageScriptHash);
        if (message.expanded != null && Object.hasOwnProperty.call(message, "expanded"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.expanded);
        return writer;
    };

    /**
     * Encodes the specified Navigation message, length delimited. Does not implicitly {@link Navigation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Navigation
     * @static
     * @param {INavigation} message Navigation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Navigation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Navigation message from the specified reader or buffer.
     * @function decode
     * @memberof Navigation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Navigation} Navigation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Navigation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Navigation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.sections && message.sections.length))
                        message.sections = [];
                    message.sections.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.appPages && message.appPages.length))
                        message.appPages = [];
                    message.appPages.push($root.AppPage.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.position = reader.int32();
                    break;
                }
            case 4: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 5: {
                    message.expanded = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Navigation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Navigation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Navigation} Navigation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Navigation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Navigation message.
     * @function verify
     * @memberof Navigation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Navigation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sections != null && message.hasOwnProperty("sections")) {
            if (!Array.isArray(message.sections))
                return "sections: array expected";
            for (let i = 0; i < message.sections.length; ++i)
                if (!$util.isString(message.sections[i]))
                    return "sections: string[] expected";
        }
        if (message.appPages != null && message.hasOwnProperty("appPages")) {
            if (!Array.isArray(message.appPages))
                return "appPages: array expected";
            for (let i = 0; i < message.appPages.length; ++i) {
                let error = $root.AppPage.verify(message.appPages[i]);
                if (error)
                    return "appPages." + error;
            }
        }
        if (message.position != null && message.hasOwnProperty("position"))
            switch (message.position) {
            default:
                return "position: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.expanded != null && message.hasOwnProperty("expanded"))
            if (typeof message.expanded !== "boolean")
                return "expanded: boolean expected";
        return null;
    };

    /**
     * Creates a Navigation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Navigation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Navigation} Navigation
     */
    Navigation.fromObject = function fromObject(object) {
        if (object instanceof $root.Navigation)
            return object;
        let message = new $root.Navigation();
        if (object.sections) {
            if (!Array.isArray(object.sections))
                throw TypeError(".Navigation.sections: array expected");
            message.sections = [];
            for (let i = 0; i < object.sections.length; ++i)
                message.sections[i] = String(object.sections[i]);
        }
        if (object.appPages) {
            if (!Array.isArray(object.appPages))
                throw TypeError(".Navigation.appPages: array expected");
            message.appPages = [];
            for (let i = 0; i < object.appPages.length; ++i) {
                if (typeof object.appPages[i] !== "object")
                    throw TypeError(".Navigation.appPages: object expected");
                message.appPages[i] = $root.AppPage.fromObject(object.appPages[i]);
            }
        }
        switch (object.position) {
        default:
            if (typeof object.position === "number") {
                message.position = object.position;
                break;
            }
            break;
        case "HIDDEN":
        case 0:
            message.position = 0;
            break;
        case "SIDEBAR":
        case 1:
            message.position = 1;
            break;
        }
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.expanded != null)
            message.expanded = Boolean(object.expanded);
        return message;
    };

    /**
     * Creates a plain object from a Navigation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Navigation
     * @static
     * @param {Navigation} message Navigation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Navigation.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.sections = [];
            object.appPages = [];
        }
        if (options.defaults) {
            object.position = options.enums === String ? "HIDDEN" : 0;
            object.pageScriptHash = "";
            object.expanded = false;
        }
        if (message.sections && message.sections.length) {
            object.sections = [];
            for (let j = 0; j < message.sections.length; ++j)
                object.sections[j] = message.sections[j];
        }
        if (message.appPages && message.appPages.length) {
            object.appPages = [];
            for (let j = 0; j < message.appPages.length; ++j)
                object.appPages[j] = $root.AppPage.toObject(message.appPages[j], options);
        }
        if (message.position != null && message.hasOwnProperty("position"))
            object.position = options.enums === String ? $root.Navigation.Position[message.position] === undefined ? message.position : $root.Navigation.Position[message.position] : message.position;
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.expanded != null && message.hasOwnProperty("expanded"))
            object.expanded = message.expanded;
        return object;
    };

    /**
     * Converts this Navigation to JSON.
     * @function toJSON
     * @memberof Navigation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Navigation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Navigation
     * @function getTypeUrl
     * @memberof Navigation
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Navigation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Navigation";
    };

    /**
     * Position enum.
     * @name Navigation.Position
     * @enum {number}
     * @property {number} HIDDEN=0 HIDDEN value
     * @property {number} SIDEBAR=1 SIDEBAR value
     */
    Navigation.Position = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "HIDDEN"] = 0;
        values[valuesById[1] = "SIDEBAR"] = 1;
        return values;
    })();

    return Navigation;
})();

export const NewSession = $root.NewSession = (() => {

    /**
     * Properties of a NewSession.
     * @exports INewSession
     * @interface INewSession
     * @property {IInitialize|null} [initialize] NewSession initialize
     * @property {string|null} [scriptRunId] NewSession scriptRunId
     * @property {string|null} [name] NewSession name
     * @property {string|null} [mainScriptPath] NewSession mainScriptPath
     * @property {IConfig|null} [config] NewSession config
     * @property {ICustomThemeConfig|null} [customTheme] NewSession customTheme
     * @property {Array.<IAppPage>|null} [appPages] NewSession appPages
     * @property {string|null} [pageScriptHash] NewSession pageScriptHash
     * @property {Array.<string>|null} [fragmentIdsThisRun] NewSession fragmentIdsThisRun
     * @property {string|null} [mainScriptHash] NewSession mainScriptHash
     */

    /**
     * Constructs a new NewSession.
     * @exports NewSession
     * @classdesc Represents a NewSession.
     * @implements INewSession
     * @constructor
     * @param {INewSession=} [properties] Properties to set
     */
    function NewSession(properties) {
        this.appPages = [];
        this.fragmentIdsThisRun = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NewSession initialize.
     * @member {IInitialize|null|undefined} initialize
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.initialize = null;

    /**
     * NewSession scriptRunId.
     * @member {string} scriptRunId
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.scriptRunId = "";

    /**
     * NewSession name.
     * @member {string} name
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.name = "";

    /**
     * NewSession mainScriptPath.
     * @member {string} mainScriptPath
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.mainScriptPath = "";

    /**
     * NewSession config.
     * @member {IConfig|null|undefined} config
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.config = null;

    /**
     * NewSession customTheme.
     * @member {ICustomThemeConfig|null|undefined} customTheme
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.customTheme = null;

    /**
     * NewSession appPages.
     * @member {Array.<IAppPage>} appPages
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.appPages = $util.emptyArray;

    /**
     * NewSession pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.pageScriptHash = "";

    /**
     * NewSession fragmentIdsThisRun.
     * @member {Array.<string>} fragmentIdsThisRun
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.fragmentIdsThisRun = $util.emptyArray;

    /**
     * NewSession mainScriptHash.
     * @member {string} mainScriptHash
     * @memberof NewSession
     * @instance
     */
    NewSession.prototype.mainScriptHash = "";

    /**
     * Creates a new NewSession instance using the specified properties.
     * @function create
     * @memberof NewSession
     * @static
     * @param {INewSession=} [properties] Properties to set
     * @returns {NewSession} NewSession instance
     */
    NewSession.create = function create(properties) {
        return new NewSession(properties);
    };

    /**
     * Encodes the specified NewSession message. Does not implicitly {@link NewSession.verify|verify} messages.
     * @function encode
     * @memberof NewSession
     * @static
     * @param {INewSession} message NewSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NewSession.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.initialize != null && Object.hasOwnProperty.call(message, "initialize"))
            $root.Initialize.encode(message.initialize, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.scriptRunId != null && Object.hasOwnProperty.call(message, "scriptRunId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.scriptRunId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
        if (message.mainScriptPath != null && Object.hasOwnProperty.call(message, "mainScriptPath"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.mainScriptPath);
        if (message.config != null && Object.hasOwnProperty.call(message, "config"))
            $root.Config.encode(message.config, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.customTheme != null && Object.hasOwnProperty.call(message, "customTheme"))
            $root.CustomThemeConfig.encode(message.customTheme, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.appPages != null && message.appPages.length)
            for (let i = 0; i < message.appPages.length; ++i)
                $root.AppPage.encode(message.appPages[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.pageScriptHash);
        if (message.fragmentIdsThisRun != null && message.fragmentIdsThisRun.length)
            for (let i = 0; i < message.fragmentIdsThisRun.length; ++i)
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.fragmentIdsThisRun[i]);
        if (message.mainScriptHash != null && Object.hasOwnProperty.call(message, "mainScriptHash"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.mainScriptHash);
        return writer;
    };

    /**
     * Encodes the specified NewSession message, length delimited. Does not implicitly {@link NewSession.verify|verify} messages.
     * @function encodeDelimited
     * @memberof NewSession
     * @static
     * @param {INewSession} message NewSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NewSession.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NewSession message from the specified reader or buffer.
     * @function decode
     * @memberof NewSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NewSession} NewSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NewSession.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NewSession();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.initialize = $root.Initialize.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.scriptRunId = reader.string();
                    break;
                }
            case 3: {
                    message.name = reader.string();
                    break;
                }
            case 4: {
                    message.mainScriptPath = reader.string();
                    break;
                }
            case 6: {
                    message.config = $root.Config.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.customTheme = $root.CustomThemeConfig.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    if (!(message.appPages && message.appPages.length))
                        message.appPages = [];
                    message.appPages.push($root.AppPage.decode(reader, reader.uint32()));
                    break;
                }
            case 9: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 10: {
                    if (!(message.fragmentIdsThisRun && message.fragmentIdsThisRun.length))
                        message.fragmentIdsThisRun = [];
                    message.fragmentIdsThisRun.push(reader.string());
                    break;
                }
            case 11: {
                    message.mainScriptHash = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a NewSession message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof NewSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {NewSession} NewSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NewSession.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NewSession message.
     * @function verify
     * @memberof NewSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NewSession.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.initialize != null && message.hasOwnProperty("initialize")) {
            let error = $root.Initialize.verify(message.initialize);
            if (error)
                return "initialize." + error;
        }
        if (message.scriptRunId != null && message.hasOwnProperty("scriptRunId"))
            if (!$util.isString(message.scriptRunId))
                return "scriptRunId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.mainScriptPath != null && message.hasOwnProperty("mainScriptPath"))
            if (!$util.isString(message.mainScriptPath))
                return "mainScriptPath: string expected";
        if (message.config != null && message.hasOwnProperty("config")) {
            let error = $root.Config.verify(message.config);
            if (error)
                return "config." + error;
        }
        if (message.customTheme != null && message.hasOwnProperty("customTheme")) {
            let error = $root.CustomThemeConfig.verify(message.customTheme);
            if (error)
                return "customTheme." + error;
        }
        if (message.appPages != null && message.hasOwnProperty("appPages")) {
            if (!Array.isArray(message.appPages))
                return "appPages: array expected";
            for (let i = 0; i < message.appPages.length; ++i) {
                let error = $root.AppPage.verify(message.appPages[i]);
                if (error)
                    return "appPages." + error;
            }
        }
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.fragmentIdsThisRun != null && message.hasOwnProperty("fragmentIdsThisRun")) {
            if (!Array.isArray(message.fragmentIdsThisRun))
                return "fragmentIdsThisRun: array expected";
            for (let i = 0; i < message.fragmentIdsThisRun.length; ++i)
                if (!$util.isString(message.fragmentIdsThisRun[i]))
                    return "fragmentIdsThisRun: string[] expected";
        }
        if (message.mainScriptHash != null && message.hasOwnProperty("mainScriptHash"))
            if (!$util.isString(message.mainScriptHash))
                return "mainScriptHash: string expected";
        return null;
    };

    /**
     * Creates a NewSession message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof NewSession
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {NewSession} NewSession
     */
    NewSession.fromObject = function fromObject(object) {
        if (object instanceof $root.NewSession)
            return object;
        let message = new $root.NewSession();
        if (object.initialize != null) {
            if (typeof object.initialize !== "object")
                throw TypeError(".NewSession.initialize: object expected");
            message.initialize = $root.Initialize.fromObject(object.initialize);
        }
        if (object.scriptRunId != null)
            message.scriptRunId = String(object.scriptRunId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.mainScriptPath != null)
            message.mainScriptPath = String(object.mainScriptPath);
        if (object.config != null) {
            if (typeof object.config !== "object")
                throw TypeError(".NewSession.config: object expected");
            message.config = $root.Config.fromObject(object.config);
        }
        if (object.customTheme != null) {
            if (typeof object.customTheme !== "object")
                throw TypeError(".NewSession.customTheme: object expected");
            message.customTheme = $root.CustomThemeConfig.fromObject(object.customTheme);
        }
        if (object.appPages) {
            if (!Array.isArray(object.appPages))
                throw TypeError(".NewSession.appPages: array expected");
            message.appPages = [];
            for (let i = 0; i < object.appPages.length; ++i) {
                if (typeof object.appPages[i] !== "object")
                    throw TypeError(".NewSession.appPages: object expected");
                message.appPages[i] = $root.AppPage.fromObject(object.appPages[i]);
            }
        }
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.fragmentIdsThisRun) {
            if (!Array.isArray(object.fragmentIdsThisRun))
                throw TypeError(".NewSession.fragmentIdsThisRun: array expected");
            message.fragmentIdsThisRun = [];
            for (let i = 0; i < object.fragmentIdsThisRun.length; ++i)
                message.fragmentIdsThisRun[i] = String(object.fragmentIdsThisRun[i]);
        }
        if (object.mainScriptHash != null)
            message.mainScriptHash = String(object.mainScriptHash);
        return message;
    };

    /**
     * Creates a plain object from a NewSession message. Also converts values to other types if specified.
     * @function toObject
     * @memberof NewSession
     * @static
     * @param {NewSession} message NewSession
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NewSession.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.appPages = [];
            object.fragmentIdsThisRun = [];
        }
        if (options.defaults) {
            object.initialize = null;
            object.scriptRunId = "";
            object.name = "";
            object.mainScriptPath = "";
            object.config = null;
            object.customTheme = null;
            object.pageScriptHash = "";
            object.mainScriptHash = "";
        }
        if (message.initialize != null && message.hasOwnProperty("initialize"))
            object.initialize = $root.Initialize.toObject(message.initialize, options);
        if (message.scriptRunId != null && message.hasOwnProperty("scriptRunId"))
            object.scriptRunId = message.scriptRunId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.mainScriptPath != null && message.hasOwnProperty("mainScriptPath"))
            object.mainScriptPath = message.mainScriptPath;
        if (message.config != null && message.hasOwnProperty("config"))
            object.config = $root.Config.toObject(message.config, options);
        if (message.customTheme != null && message.hasOwnProperty("customTheme"))
            object.customTheme = $root.CustomThemeConfig.toObject(message.customTheme, options);
        if (message.appPages && message.appPages.length) {
            object.appPages = [];
            for (let j = 0; j < message.appPages.length; ++j)
                object.appPages[j] = $root.AppPage.toObject(message.appPages[j], options);
        }
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.fragmentIdsThisRun && message.fragmentIdsThisRun.length) {
            object.fragmentIdsThisRun = [];
            for (let j = 0; j < message.fragmentIdsThisRun.length; ++j)
                object.fragmentIdsThisRun[j] = message.fragmentIdsThisRun[j];
        }
        if (message.mainScriptHash != null && message.hasOwnProperty("mainScriptHash"))
            object.mainScriptHash = message.mainScriptHash;
        return object;
    };

    /**
     * Converts this NewSession to JSON.
     * @function toJSON
     * @memberof NewSession
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NewSession.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NewSession
     * @function getTypeUrl
     * @memberof NewSession
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NewSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NewSession";
    };

    return NewSession;
})();

export const Initialize = $root.Initialize = (() => {

    /**
     * Properties of an Initialize.
     * @exports IInitialize
     * @interface IInitialize
     * @property {IUserInfo|null} [userInfo] Initialize userInfo
     * @property {IEnvironmentInfo|null} [environmentInfo] Initialize environmentInfo
     * @property {ISessionStatus|null} [sessionStatus] Initialize sessionStatus
     * @property {string|null} [commandLine] Initialize commandLine
     * @property {string|null} [sessionId] Initialize sessionId
     * @property {boolean|null} [isHello] Initialize isHello
     */

    /**
     * Constructs a new Initialize.
     * @exports Initialize
     * @classdesc Represents an Initialize.
     * @implements IInitialize
     * @constructor
     * @param {IInitialize=} [properties] Properties to set
     */
    function Initialize(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Initialize userInfo.
     * @member {IUserInfo|null|undefined} userInfo
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.userInfo = null;

    /**
     * Initialize environmentInfo.
     * @member {IEnvironmentInfo|null|undefined} environmentInfo
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.environmentInfo = null;

    /**
     * Initialize sessionStatus.
     * @member {ISessionStatus|null|undefined} sessionStatus
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.sessionStatus = null;

    /**
     * Initialize commandLine.
     * @member {string} commandLine
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.commandLine = "";

    /**
     * Initialize sessionId.
     * @member {string} sessionId
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.sessionId = "";

    /**
     * Initialize isHello.
     * @member {boolean} isHello
     * @memberof Initialize
     * @instance
     */
    Initialize.prototype.isHello = false;

    /**
     * Creates a new Initialize instance using the specified properties.
     * @function create
     * @memberof Initialize
     * @static
     * @param {IInitialize=} [properties] Properties to set
     * @returns {Initialize} Initialize instance
     */
    Initialize.create = function create(properties) {
        return new Initialize(properties);
    };

    /**
     * Encodes the specified Initialize message. Does not implicitly {@link Initialize.verify|verify} messages.
     * @function encode
     * @memberof Initialize
     * @static
     * @param {IInitialize} message Initialize message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Initialize.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.userInfo != null && Object.hasOwnProperty.call(message, "userInfo"))
            $root.UserInfo.encode(message.userInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.environmentInfo != null && Object.hasOwnProperty.call(message, "environmentInfo"))
            $root.EnvironmentInfo.encode(message.environmentInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.sessionStatus != null && Object.hasOwnProperty.call(message, "sessionStatus"))
            $root.SessionStatus.encode(message.sessionStatus, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.commandLine != null && Object.hasOwnProperty.call(message, "commandLine"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.commandLine);
        if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.sessionId);
        if (message.isHello != null && Object.hasOwnProperty.call(message, "isHello"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isHello);
        return writer;
    };

    /**
     * Encodes the specified Initialize message, length delimited. Does not implicitly {@link Initialize.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Initialize
     * @static
     * @param {IInitialize} message Initialize message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Initialize.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Initialize message from the specified reader or buffer.
     * @function decode
     * @memberof Initialize
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Initialize} Initialize
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Initialize.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Initialize();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.userInfo = $root.UserInfo.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.environmentInfo = $root.EnvironmentInfo.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.sessionStatus = $root.SessionStatus.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.commandLine = reader.string();
                    break;
                }
            case 6: {
                    message.sessionId = reader.string();
                    break;
                }
            case 7: {
                    message.isHello = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Initialize message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Initialize
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Initialize} Initialize
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Initialize.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Initialize message.
     * @function verify
     * @memberof Initialize
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Initialize.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.userInfo != null && message.hasOwnProperty("userInfo")) {
            let error = $root.UserInfo.verify(message.userInfo);
            if (error)
                return "userInfo." + error;
        }
        if (message.environmentInfo != null && message.hasOwnProperty("environmentInfo")) {
            let error = $root.EnvironmentInfo.verify(message.environmentInfo);
            if (error)
                return "environmentInfo." + error;
        }
        if (message.sessionStatus != null && message.hasOwnProperty("sessionStatus")) {
            let error = $root.SessionStatus.verify(message.sessionStatus);
            if (error)
                return "sessionStatus." + error;
        }
        if (message.commandLine != null && message.hasOwnProperty("commandLine"))
            if (!$util.isString(message.commandLine))
                return "commandLine: string expected";
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            if (!$util.isString(message.sessionId))
                return "sessionId: string expected";
        if (message.isHello != null && message.hasOwnProperty("isHello"))
            if (typeof message.isHello !== "boolean")
                return "isHello: boolean expected";
        return null;
    };

    /**
     * Creates an Initialize message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Initialize
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Initialize} Initialize
     */
    Initialize.fromObject = function fromObject(object) {
        if (object instanceof $root.Initialize)
            return object;
        let message = new $root.Initialize();
        if (object.userInfo != null) {
            if (typeof object.userInfo !== "object")
                throw TypeError(".Initialize.userInfo: object expected");
            message.userInfo = $root.UserInfo.fromObject(object.userInfo);
        }
        if (object.environmentInfo != null) {
            if (typeof object.environmentInfo !== "object")
                throw TypeError(".Initialize.environmentInfo: object expected");
            message.environmentInfo = $root.EnvironmentInfo.fromObject(object.environmentInfo);
        }
        if (object.sessionStatus != null) {
            if (typeof object.sessionStatus !== "object")
                throw TypeError(".Initialize.sessionStatus: object expected");
            message.sessionStatus = $root.SessionStatus.fromObject(object.sessionStatus);
        }
        if (object.commandLine != null)
            message.commandLine = String(object.commandLine);
        if (object.sessionId != null)
            message.sessionId = String(object.sessionId);
        if (object.isHello != null)
            message.isHello = Boolean(object.isHello);
        return message;
    };

    /**
     * Creates a plain object from an Initialize message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Initialize
     * @static
     * @param {Initialize} message Initialize
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Initialize.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.userInfo = null;
            object.environmentInfo = null;
            object.sessionStatus = null;
            object.commandLine = "";
            object.sessionId = "";
            object.isHello = false;
        }
        if (message.userInfo != null && message.hasOwnProperty("userInfo"))
            object.userInfo = $root.UserInfo.toObject(message.userInfo, options);
        if (message.environmentInfo != null && message.hasOwnProperty("environmentInfo"))
            object.environmentInfo = $root.EnvironmentInfo.toObject(message.environmentInfo, options);
        if (message.sessionStatus != null && message.hasOwnProperty("sessionStatus"))
            object.sessionStatus = $root.SessionStatus.toObject(message.sessionStatus, options);
        if (message.commandLine != null && message.hasOwnProperty("commandLine"))
            object.commandLine = message.commandLine;
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.isHello != null && message.hasOwnProperty("isHello"))
            object.isHello = message.isHello;
        return object;
    };

    /**
     * Converts this Initialize to JSON.
     * @function toJSON
     * @memberof Initialize
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Initialize.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Initialize
     * @function getTypeUrl
     * @memberof Initialize
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Initialize.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Initialize";
    };

    return Initialize;
})();

export const Config = $root.Config = (() => {

    /**
     * Properties of a Config.
     * @exports IConfig
     * @interface IConfig
     * @property {boolean|null} [gatherUsageStats] Config gatherUsageStats
     * @property {number|null} [maxCachedMessageAge] Config maxCachedMessageAge
     * @property {string|null} [mapboxToken] Config mapboxToken
     * @property {boolean|null} [allowRunOnSave] Config allowRunOnSave
     * @property {boolean|null} [hideTopBar] Config hideTopBar
     * @property {boolean|null} [hideSidebarNav] Config hideSidebarNav
     * @property {Config.ToolbarMode|null} [toolbarMode] Config toolbarMode
     */

    /**
     * Constructs a new Config.
     * @exports Config
     * @classdesc Represents a Config.
     * @implements IConfig
     * @constructor
     * @param {IConfig=} [properties] Properties to set
     */
    function Config(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Config gatherUsageStats.
     * @member {boolean} gatherUsageStats
     * @memberof Config
     * @instance
     */
    Config.prototype.gatherUsageStats = false;

    /**
     * Config maxCachedMessageAge.
     * @member {number} maxCachedMessageAge
     * @memberof Config
     * @instance
     */
    Config.prototype.maxCachedMessageAge = 0;

    /**
     * Config mapboxToken.
     * @member {string} mapboxToken
     * @memberof Config
     * @instance
     */
    Config.prototype.mapboxToken = "";

    /**
     * Config allowRunOnSave.
     * @member {boolean} allowRunOnSave
     * @memberof Config
     * @instance
     */
    Config.prototype.allowRunOnSave = false;

    /**
     * Config hideTopBar.
     * @member {boolean} hideTopBar
     * @memberof Config
     * @instance
     */
    Config.prototype.hideTopBar = false;

    /**
     * Config hideSidebarNav.
     * @member {boolean} hideSidebarNav
     * @memberof Config
     * @instance
     */
    Config.prototype.hideSidebarNav = false;

    /**
     * Config toolbarMode.
     * @member {Config.ToolbarMode} toolbarMode
     * @memberof Config
     * @instance
     */
    Config.prototype.toolbarMode = 0;

    /**
     * Creates a new Config instance using the specified properties.
     * @function create
     * @memberof Config
     * @static
     * @param {IConfig=} [properties] Properties to set
     * @returns {Config} Config instance
     */
    Config.create = function create(properties) {
        return new Config(properties);
    };

    /**
     * Encodes the specified Config message. Does not implicitly {@link Config.verify|verify} messages.
     * @function encode
     * @memberof Config
     * @static
     * @param {IConfig} message Config message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Config.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.gatherUsageStats != null && Object.hasOwnProperty.call(message, "gatherUsageStats"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.gatherUsageStats);
        if (message.maxCachedMessageAge != null && Object.hasOwnProperty.call(message, "maxCachedMessageAge"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxCachedMessageAge);
        if (message.mapboxToken != null && Object.hasOwnProperty.call(message, "mapboxToken"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.mapboxToken);
        if (message.allowRunOnSave != null && Object.hasOwnProperty.call(message, "allowRunOnSave"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.allowRunOnSave);
        if (message.hideTopBar != null && Object.hasOwnProperty.call(message, "hideTopBar"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.hideTopBar);
        if (message.hideSidebarNav != null && Object.hasOwnProperty.call(message, "hideSidebarNav"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.hideSidebarNav);
        if (message.toolbarMode != null && Object.hasOwnProperty.call(message, "toolbarMode"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.toolbarMode);
        return writer;
    };

    /**
     * Encodes the specified Config message, length delimited. Does not implicitly {@link Config.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Config
     * @static
     * @param {IConfig} message Config message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Config.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Config message from the specified reader or buffer.
     * @function decode
     * @memberof Config
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Config} Config
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Config.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Config();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.gatherUsageStats = reader.bool();
                    break;
                }
            case 3: {
                    message.maxCachedMessageAge = reader.int32();
                    break;
                }
            case 4: {
                    message.mapboxToken = reader.string();
                    break;
                }
            case 5: {
                    message.allowRunOnSave = reader.bool();
                    break;
                }
            case 6: {
                    message.hideTopBar = reader.bool();
                    break;
                }
            case 7: {
                    message.hideSidebarNav = reader.bool();
                    break;
                }
            case 8: {
                    message.toolbarMode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Config message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Config
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Config} Config
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Config.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Config message.
     * @function verify
     * @memberof Config
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Config.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.gatherUsageStats != null && message.hasOwnProperty("gatherUsageStats"))
            if (typeof message.gatherUsageStats !== "boolean")
                return "gatherUsageStats: boolean expected";
        if (message.maxCachedMessageAge != null && message.hasOwnProperty("maxCachedMessageAge"))
            if (!$util.isInteger(message.maxCachedMessageAge))
                return "maxCachedMessageAge: integer expected";
        if (message.mapboxToken != null && message.hasOwnProperty("mapboxToken"))
            if (!$util.isString(message.mapboxToken))
                return "mapboxToken: string expected";
        if (message.allowRunOnSave != null && message.hasOwnProperty("allowRunOnSave"))
            if (typeof message.allowRunOnSave !== "boolean")
                return "allowRunOnSave: boolean expected";
        if (message.hideTopBar != null && message.hasOwnProperty("hideTopBar"))
            if (typeof message.hideTopBar !== "boolean")
                return "hideTopBar: boolean expected";
        if (message.hideSidebarNav != null && message.hasOwnProperty("hideSidebarNav"))
            if (typeof message.hideSidebarNav !== "boolean")
                return "hideSidebarNav: boolean expected";
        if (message.toolbarMode != null && message.hasOwnProperty("toolbarMode"))
            switch (message.toolbarMode) {
            default:
                return "toolbarMode: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        return null;
    };

    /**
     * Creates a Config message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Config
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Config} Config
     */
    Config.fromObject = function fromObject(object) {
        if (object instanceof $root.Config)
            return object;
        let message = new $root.Config();
        if (object.gatherUsageStats != null)
            message.gatherUsageStats = Boolean(object.gatherUsageStats);
        if (object.maxCachedMessageAge != null)
            message.maxCachedMessageAge = object.maxCachedMessageAge | 0;
        if (object.mapboxToken != null)
            message.mapboxToken = String(object.mapboxToken);
        if (object.allowRunOnSave != null)
            message.allowRunOnSave = Boolean(object.allowRunOnSave);
        if (object.hideTopBar != null)
            message.hideTopBar = Boolean(object.hideTopBar);
        if (object.hideSidebarNav != null)
            message.hideSidebarNav = Boolean(object.hideSidebarNav);
        switch (object.toolbarMode) {
        default:
            if (typeof object.toolbarMode === "number") {
                message.toolbarMode = object.toolbarMode;
                break;
            }
            break;
        case "AUTO":
        case 0:
            message.toolbarMode = 0;
            break;
        case "DEVELOPER":
        case 1:
            message.toolbarMode = 1;
            break;
        case "VIEWER":
        case 2:
            message.toolbarMode = 2;
            break;
        case "MINIMAL":
        case 3:
            message.toolbarMode = 3;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a Config message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Config
     * @static
     * @param {Config} message Config
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Config.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.gatherUsageStats = false;
            object.maxCachedMessageAge = 0;
            object.mapboxToken = "";
            object.allowRunOnSave = false;
            object.hideTopBar = false;
            object.hideSidebarNav = false;
            object.toolbarMode = options.enums === String ? "AUTO" : 0;
        }
        if (message.gatherUsageStats != null && message.hasOwnProperty("gatherUsageStats"))
            object.gatherUsageStats = message.gatherUsageStats;
        if (message.maxCachedMessageAge != null && message.hasOwnProperty("maxCachedMessageAge"))
            object.maxCachedMessageAge = message.maxCachedMessageAge;
        if (message.mapboxToken != null && message.hasOwnProperty("mapboxToken"))
            object.mapboxToken = message.mapboxToken;
        if (message.allowRunOnSave != null && message.hasOwnProperty("allowRunOnSave"))
            object.allowRunOnSave = message.allowRunOnSave;
        if (message.hideTopBar != null && message.hasOwnProperty("hideTopBar"))
            object.hideTopBar = message.hideTopBar;
        if (message.hideSidebarNav != null && message.hasOwnProperty("hideSidebarNav"))
            object.hideSidebarNav = message.hideSidebarNav;
        if (message.toolbarMode != null && message.hasOwnProperty("toolbarMode"))
            object.toolbarMode = options.enums === String ? $root.Config.ToolbarMode[message.toolbarMode] === undefined ? message.toolbarMode : $root.Config.ToolbarMode[message.toolbarMode] : message.toolbarMode;
        return object;
    };

    /**
     * Converts this Config to JSON.
     * @function toJSON
     * @memberof Config
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Config.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Config
     * @function getTypeUrl
     * @memberof Config
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Config.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Config";
    };

    /**
     * ToolbarMode enum.
     * @name Config.ToolbarMode
     * @enum {number}
     * @property {number} AUTO=0 AUTO value
     * @property {number} DEVELOPER=1 DEVELOPER value
     * @property {number} VIEWER=2 VIEWER value
     * @property {number} MINIMAL=3 MINIMAL value
     */
    Config.ToolbarMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUTO"] = 0;
        values[valuesById[1] = "DEVELOPER"] = 1;
        values[valuesById[2] = "VIEWER"] = 2;
        values[valuesById[3] = "MINIMAL"] = 3;
        return values;
    })();

    return Config;
})();

export const CustomThemeConfig = $root.CustomThemeConfig = (() => {

    /**
     * Properties of a CustomThemeConfig.
     * @exports ICustomThemeConfig
     * @interface ICustomThemeConfig
     * @property {string|null} [primaryColor] CustomThemeConfig primaryColor
     * @property {string|null} [secondaryBackgroundColor] CustomThemeConfig secondaryBackgroundColor
     * @property {string|null} [backgroundColor] CustomThemeConfig backgroundColor
     * @property {string|null} [textColor] CustomThemeConfig textColor
     * @property {CustomThemeConfig.FontFamily|null} [font] CustomThemeConfig font
     * @property {CustomThemeConfig.BaseTheme|null} [base] CustomThemeConfig base
     * @property {string|null} [widgetBackgroundColor] CustomThemeConfig widgetBackgroundColor
     * @property {string|null} [widgetBorderColor] CustomThemeConfig widgetBorderColor
     * @property {IRadii|null} [radii] CustomThemeConfig radii
     * @property {string|null} [bodyFont] CustomThemeConfig bodyFont
     * @property {string|null} [codeFont] CustomThemeConfig codeFont
     * @property {Array.<IFontFace>|null} [fontFaces] CustomThemeConfig fontFaces
     * @property {IFontSizes|null} [fontSizes] CustomThemeConfig fontSizes
     * @property {string|null} [skeletonBackgroundColor] CustomThemeConfig skeletonBackgroundColor
     */

    /**
     * Constructs a new CustomThemeConfig.
     * @exports CustomThemeConfig
     * @classdesc Represents a CustomThemeConfig.
     * @implements ICustomThemeConfig
     * @constructor
     * @param {ICustomThemeConfig=} [properties] Properties to set
     */
    function CustomThemeConfig(properties) {
        this.fontFaces = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CustomThemeConfig primaryColor.
     * @member {string} primaryColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.primaryColor = "";

    /**
     * CustomThemeConfig secondaryBackgroundColor.
     * @member {string} secondaryBackgroundColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.secondaryBackgroundColor = "";

    /**
     * CustomThemeConfig backgroundColor.
     * @member {string} backgroundColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.backgroundColor = "";

    /**
     * CustomThemeConfig textColor.
     * @member {string} textColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.textColor = "";

    /**
     * CustomThemeConfig font.
     * @member {CustomThemeConfig.FontFamily} font
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.font = 0;

    /**
     * CustomThemeConfig base.
     * @member {CustomThemeConfig.BaseTheme} base
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.base = 0;

    /**
     * CustomThemeConfig widgetBackgroundColor.
     * @member {string} widgetBackgroundColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.widgetBackgroundColor = "";

    /**
     * CustomThemeConfig widgetBorderColor.
     * @member {string} widgetBorderColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.widgetBorderColor = "";

    /**
     * CustomThemeConfig radii.
     * @member {IRadii|null|undefined} radii
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.radii = null;

    /**
     * CustomThemeConfig bodyFont.
     * @member {string} bodyFont
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.bodyFont = "";

    /**
     * CustomThemeConfig codeFont.
     * @member {string} codeFont
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.codeFont = "";

    /**
     * CustomThemeConfig fontFaces.
     * @member {Array.<IFontFace>} fontFaces
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.fontFaces = $util.emptyArray;

    /**
     * CustomThemeConfig fontSizes.
     * @member {IFontSizes|null|undefined} fontSizes
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.fontSizes = null;

    /**
     * CustomThemeConfig skeletonBackgroundColor.
     * @member {string} skeletonBackgroundColor
     * @memberof CustomThemeConfig
     * @instance
     */
    CustomThemeConfig.prototype.skeletonBackgroundColor = "";

    /**
     * Creates a new CustomThemeConfig instance using the specified properties.
     * @function create
     * @memberof CustomThemeConfig
     * @static
     * @param {ICustomThemeConfig=} [properties] Properties to set
     * @returns {CustomThemeConfig} CustomThemeConfig instance
     */
    CustomThemeConfig.create = function create(properties) {
        return new CustomThemeConfig(properties);
    };

    /**
     * Encodes the specified CustomThemeConfig message. Does not implicitly {@link CustomThemeConfig.verify|verify} messages.
     * @function encode
     * @memberof CustomThemeConfig
     * @static
     * @param {ICustomThemeConfig} message CustomThemeConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CustomThemeConfig.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.primaryColor != null && Object.hasOwnProperty.call(message, "primaryColor"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.primaryColor);
        if (message.secondaryBackgroundColor != null && Object.hasOwnProperty.call(message, "secondaryBackgroundColor"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.secondaryBackgroundColor);
        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.backgroundColor);
        if (message.textColor != null && Object.hasOwnProperty.call(message, "textColor"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.textColor);
        if (message.font != null && Object.hasOwnProperty.call(message, "font"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.font);
        if (message.base != null && Object.hasOwnProperty.call(message, "base"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.base);
        if (message.widgetBackgroundColor != null && Object.hasOwnProperty.call(message, "widgetBackgroundColor"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.widgetBackgroundColor);
        if (message.widgetBorderColor != null && Object.hasOwnProperty.call(message, "widgetBorderColor"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.widgetBorderColor);
        if (message.radii != null && Object.hasOwnProperty.call(message, "radii"))
            $root.Radii.encode(message.radii, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.bodyFont != null && Object.hasOwnProperty.call(message, "bodyFont"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.bodyFont);
        if (message.codeFont != null && Object.hasOwnProperty.call(message, "codeFont"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.codeFont);
        if (message.fontFaces != null && message.fontFaces.length)
            for (let i = 0; i < message.fontFaces.length; ++i)
                $root.FontFace.encode(message.fontFaces[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.fontSizes != null && Object.hasOwnProperty.call(message, "fontSizes"))
            $root.FontSizes.encode(message.fontSizes, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.skeletonBackgroundColor != null && Object.hasOwnProperty.call(message, "skeletonBackgroundColor"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.skeletonBackgroundColor);
        return writer;
    };

    /**
     * Encodes the specified CustomThemeConfig message, length delimited. Does not implicitly {@link CustomThemeConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CustomThemeConfig
     * @static
     * @param {ICustomThemeConfig} message CustomThemeConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CustomThemeConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CustomThemeConfig message from the specified reader or buffer.
     * @function decode
     * @memberof CustomThemeConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CustomThemeConfig} CustomThemeConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CustomThemeConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CustomThemeConfig();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.primaryColor = reader.string();
                    break;
                }
            case 2: {
                    message.secondaryBackgroundColor = reader.string();
                    break;
                }
            case 3: {
                    message.backgroundColor = reader.string();
                    break;
                }
            case 4: {
                    message.textColor = reader.string();
                    break;
                }
            case 5: {
                    message.font = reader.int32();
                    break;
                }
            case 6: {
                    message.base = reader.int32();
                    break;
                }
            case 7: {
                    message.widgetBackgroundColor = reader.string();
                    break;
                }
            case 8: {
                    message.widgetBorderColor = reader.string();
                    break;
                }
            case 9: {
                    message.radii = $root.Radii.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.bodyFont = reader.string();
                    break;
                }
            case 14: {
                    message.codeFont = reader.string();
                    break;
                }
            case 15: {
                    if (!(message.fontFaces && message.fontFaces.length))
                        message.fontFaces = [];
                    message.fontFaces.push($root.FontFace.decode(reader, reader.uint32()));
                    break;
                }
            case 16: {
                    message.fontSizes = $root.FontSizes.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.skeletonBackgroundColor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CustomThemeConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CustomThemeConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CustomThemeConfig} CustomThemeConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CustomThemeConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CustomThemeConfig message.
     * @function verify
     * @memberof CustomThemeConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CustomThemeConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            if (!$util.isString(message.primaryColor))
                return "primaryColor: string expected";
        if (message.secondaryBackgroundColor != null && message.hasOwnProperty("secondaryBackgroundColor"))
            if (!$util.isString(message.secondaryBackgroundColor))
                return "secondaryBackgroundColor: string expected";
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            if (!$util.isString(message.backgroundColor))
                return "backgroundColor: string expected";
        if (message.textColor != null && message.hasOwnProperty("textColor"))
            if (!$util.isString(message.textColor))
                return "textColor: string expected";
        if (message.font != null && message.hasOwnProperty("font"))
            switch (message.font) {
            default:
                return "font: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.base != null && message.hasOwnProperty("base"))
            switch (message.base) {
            default:
                return "base: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.widgetBackgroundColor != null && message.hasOwnProperty("widgetBackgroundColor"))
            if (!$util.isString(message.widgetBackgroundColor))
                return "widgetBackgroundColor: string expected";
        if (message.widgetBorderColor != null && message.hasOwnProperty("widgetBorderColor"))
            if (!$util.isString(message.widgetBorderColor))
                return "widgetBorderColor: string expected";
        if (message.radii != null && message.hasOwnProperty("radii")) {
            let error = $root.Radii.verify(message.radii);
            if (error)
                return "radii." + error;
        }
        if (message.bodyFont != null && message.hasOwnProperty("bodyFont"))
            if (!$util.isString(message.bodyFont))
                return "bodyFont: string expected";
        if (message.codeFont != null && message.hasOwnProperty("codeFont"))
            if (!$util.isString(message.codeFont))
                return "codeFont: string expected";
        if (message.fontFaces != null && message.hasOwnProperty("fontFaces")) {
            if (!Array.isArray(message.fontFaces))
                return "fontFaces: array expected";
            for (let i = 0; i < message.fontFaces.length; ++i) {
                let error = $root.FontFace.verify(message.fontFaces[i]);
                if (error)
                    return "fontFaces." + error;
            }
        }
        if (message.fontSizes != null && message.hasOwnProperty("fontSizes")) {
            let error = $root.FontSizes.verify(message.fontSizes);
            if (error)
                return "fontSizes." + error;
        }
        if (message.skeletonBackgroundColor != null && message.hasOwnProperty("skeletonBackgroundColor"))
            if (!$util.isString(message.skeletonBackgroundColor))
                return "skeletonBackgroundColor: string expected";
        return null;
    };

    /**
     * Creates a CustomThemeConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CustomThemeConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CustomThemeConfig} CustomThemeConfig
     */
    CustomThemeConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.CustomThemeConfig)
            return object;
        let message = new $root.CustomThemeConfig();
        if (object.primaryColor != null)
            message.primaryColor = String(object.primaryColor);
        if (object.secondaryBackgroundColor != null)
            message.secondaryBackgroundColor = String(object.secondaryBackgroundColor);
        if (object.backgroundColor != null)
            message.backgroundColor = String(object.backgroundColor);
        if (object.textColor != null)
            message.textColor = String(object.textColor);
        switch (object.font) {
        default:
            if (typeof object.font === "number") {
                message.font = object.font;
                break;
            }
            break;
        case "SANS_SERIF":
        case 0:
            message.font = 0;
            break;
        case "SERIF":
        case 1:
            message.font = 1;
            break;
        case "MONOSPACE":
        case 2:
            message.font = 2;
            break;
        }
        switch (object.base) {
        default:
            if (typeof object.base === "number") {
                message.base = object.base;
                break;
            }
            break;
        case "LIGHT":
        case 0:
            message.base = 0;
            break;
        case "DARK":
        case 1:
            message.base = 1;
            break;
        }
        if (object.widgetBackgroundColor != null)
            message.widgetBackgroundColor = String(object.widgetBackgroundColor);
        if (object.widgetBorderColor != null)
            message.widgetBorderColor = String(object.widgetBorderColor);
        if (object.radii != null) {
            if (typeof object.radii !== "object")
                throw TypeError(".CustomThemeConfig.radii: object expected");
            message.radii = $root.Radii.fromObject(object.radii);
        }
        if (object.bodyFont != null)
            message.bodyFont = String(object.bodyFont);
        if (object.codeFont != null)
            message.codeFont = String(object.codeFont);
        if (object.fontFaces) {
            if (!Array.isArray(object.fontFaces))
                throw TypeError(".CustomThemeConfig.fontFaces: array expected");
            message.fontFaces = [];
            for (let i = 0; i < object.fontFaces.length; ++i) {
                if (typeof object.fontFaces[i] !== "object")
                    throw TypeError(".CustomThemeConfig.fontFaces: object expected");
                message.fontFaces[i] = $root.FontFace.fromObject(object.fontFaces[i]);
            }
        }
        if (object.fontSizes != null) {
            if (typeof object.fontSizes !== "object")
                throw TypeError(".CustomThemeConfig.fontSizes: object expected");
            message.fontSizes = $root.FontSizes.fromObject(object.fontSizes);
        }
        if (object.skeletonBackgroundColor != null)
            message.skeletonBackgroundColor = String(object.skeletonBackgroundColor);
        return message;
    };

    /**
     * Creates a plain object from a CustomThemeConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CustomThemeConfig
     * @static
     * @param {CustomThemeConfig} message CustomThemeConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CustomThemeConfig.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.fontFaces = [];
        if (options.defaults) {
            object.primaryColor = "";
            object.secondaryBackgroundColor = "";
            object.backgroundColor = "";
            object.textColor = "";
            object.font = options.enums === String ? "SANS_SERIF" : 0;
            object.base = options.enums === String ? "LIGHT" : 0;
            object.widgetBackgroundColor = "";
            object.widgetBorderColor = "";
            object.radii = null;
            object.bodyFont = "";
            object.codeFont = "";
            object.fontSizes = null;
            object.skeletonBackgroundColor = "";
        }
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            object.primaryColor = message.primaryColor;
        if (message.secondaryBackgroundColor != null && message.hasOwnProperty("secondaryBackgroundColor"))
            object.secondaryBackgroundColor = message.secondaryBackgroundColor;
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            object.backgroundColor = message.backgroundColor;
        if (message.textColor != null && message.hasOwnProperty("textColor"))
            object.textColor = message.textColor;
        if (message.font != null && message.hasOwnProperty("font"))
            object.font = options.enums === String ? $root.CustomThemeConfig.FontFamily[message.font] === undefined ? message.font : $root.CustomThemeConfig.FontFamily[message.font] : message.font;
        if (message.base != null && message.hasOwnProperty("base"))
            object.base = options.enums === String ? $root.CustomThemeConfig.BaseTheme[message.base] === undefined ? message.base : $root.CustomThemeConfig.BaseTheme[message.base] : message.base;
        if (message.widgetBackgroundColor != null && message.hasOwnProperty("widgetBackgroundColor"))
            object.widgetBackgroundColor = message.widgetBackgroundColor;
        if (message.widgetBorderColor != null && message.hasOwnProperty("widgetBorderColor"))
            object.widgetBorderColor = message.widgetBorderColor;
        if (message.radii != null && message.hasOwnProperty("radii"))
            object.radii = $root.Radii.toObject(message.radii, options);
        if (message.bodyFont != null && message.hasOwnProperty("bodyFont"))
            object.bodyFont = message.bodyFont;
        if (message.codeFont != null && message.hasOwnProperty("codeFont"))
            object.codeFont = message.codeFont;
        if (message.fontFaces && message.fontFaces.length) {
            object.fontFaces = [];
            for (let j = 0; j < message.fontFaces.length; ++j)
                object.fontFaces[j] = $root.FontFace.toObject(message.fontFaces[j], options);
        }
        if (message.fontSizes != null && message.hasOwnProperty("fontSizes"))
            object.fontSizes = $root.FontSizes.toObject(message.fontSizes, options);
        if (message.skeletonBackgroundColor != null && message.hasOwnProperty("skeletonBackgroundColor"))
            object.skeletonBackgroundColor = message.skeletonBackgroundColor;
        return object;
    };

    /**
     * Converts this CustomThemeConfig to JSON.
     * @function toJSON
     * @memberof CustomThemeConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CustomThemeConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CustomThemeConfig
     * @function getTypeUrl
     * @memberof CustomThemeConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CustomThemeConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CustomThemeConfig";
    };

    /**
     * BaseTheme enum.
     * @name CustomThemeConfig.BaseTheme
     * @enum {number}
     * @property {number} LIGHT=0 LIGHT value
     * @property {number} DARK=1 DARK value
     */
    CustomThemeConfig.BaseTheme = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LIGHT"] = 0;
        values[valuesById[1] = "DARK"] = 1;
        return values;
    })();

    /**
     * FontFamily enum.
     * @name CustomThemeConfig.FontFamily
     * @enum {number}
     * @property {number} SANS_SERIF=0 SANS_SERIF value
     * @property {number} SERIF=1 SERIF value
     * @property {number} MONOSPACE=2 MONOSPACE value
     */
    CustomThemeConfig.FontFamily = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SANS_SERIF"] = 0;
        values[valuesById[1] = "SERIF"] = 1;
        values[valuesById[2] = "MONOSPACE"] = 2;
        return values;
    })();

    return CustomThemeConfig;
})();

export const FontFace = $root.FontFace = (() => {

    /**
     * Properties of a FontFace.
     * @exports IFontFace
     * @interface IFontFace
     * @property {string|null} [url] FontFace url
     * @property {string|null} [family] FontFace family
     * @property {number|null} [weight] FontFace weight
     * @property {string|null} [style] FontFace style
     */

    /**
     * Constructs a new FontFace.
     * @exports FontFace
     * @classdesc Represents a FontFace.
     * @implements IFontFace
     * @constructor
     * @param {IFontFace=} [properties] Properties to set
     */
    function FontFace(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FontFace url.
     * @member {string} url
     * @memberof FontFace
     * @instance
     */
    FontFace.prototype.url = "";

    /**
     * FontFace family.
     * @member {string} family
     * @memberof FontFace
     * @instance
     */
    FontFace.prototype.family = "";

    /**
     * FontFace weight.
     * @member {number} weight
     * @memberof FontFace
     * @instance
     */
    FontFace.prototype.weight = 0;

    /**
     * FontFace style.
     * @member {string} style
     * @memberof FontFace
     * @instance
     */
    FontFace.prototype.style = "";

    /**
     * Creates a new FontFace instance using the specified properties.
     * @function create
     * @memberof FontFace
     * @static
     * @param {IFontFace=} [properties] Properties to set
     * @returns {FontFace} FontFace instance
     */
    FontFace.create = function create(properties) {
        return new FontFace(properties);
    };

    /**
     * Encodes the specified FontFace message. Does not implicitly {@link FontFace.verify|verify} messages.
     * @function encode
     * @memberof FontFace
     * @static
     * @param {IFontFace} message FontFace message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FontFace.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
        if (message.family != null && Object.hasOwnProperty.call(message, "family"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.family);
        if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.weight);
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.style);
        return writer;
    };

    /**
     * Encodes the specified FontFace message, length delimited. Does not implicitly {@link FontFace.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FontFace
     * @static
     * @param {IFontFace} message FontFace message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FontFace.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FontFace message from the specified reader or buffer.
     * @function decode
     * @memberof FontFace
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FontFace} FontFace
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FontFace.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FontFace();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.url = reader.string();
                    break;
                }
            case 2: {
                    message.family = reader.string();
                    break;
                }
            case 3: {
                    message.weight = reader.int32();
                    break;
                }
            case 4: {
                    message.style = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FontFace message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FontFace
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FontFace} FontFace
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FontFace.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FontFace message.
     * @function verify
     * @memberof FontFace
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FontFace.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.family != null && message.hasOwnProperty("family"))
            if (!$util.isString(message.family))
                return "family: string expected";
        if (message.weight != null && message.hasOwnProperty("weight"))
            if (!$util.isInteger(message.weight))
                return "weight: integer expected";
        if (message.style != null && message.hasOwnProperty("style"))
            if (!$util.isString(message.style))
                return "style: string expected";
        return null;
    };

    /**
     * Creates a FontFace message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FontFace
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FontFace} FontFace
     */
    FontFace.fromObject = function fromObject(object) {
        if (object instanceof $root.FontFace)
            return object;
        let message = new $root.FontFace();
        if (object.url != null)
            message.url = String(object.url);
        if (object.family != null)
            message.family = String(object.family);
        if (object.weight != null)
            message.weight = object.weight | 0;
        if (object.style != null)
            message.style = String(object.style);
        return message;
    };

    /**
     * Creates a plain object from a FontFace message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FontFace
     * @static
     * @param {FontFace} message FontFace
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FontFace.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.url = "";
            object.family = "";
            object.weight = 0;
            object.style = "";
        }
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.family != null && message.hasOwnProperty("family"))
            object.family = message.family;
        if (message.weight != null && message.hasOwnProperty("weight"))
            object.weight = message.weight;
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = message.style;
        return object;
    };

    /**
     * Converts this FontFace to JSON.
     * @function toJSON
     * @memberof FontFace
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FontFace.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FontFace
     * @function getTypeUrl
     * @memberof FontFace
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FontFace.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FontFace";
    };

    return FontFace;
})();

export const Radii = $root.Radii = (() => {

    /**
     * Properties of a Radii.
     * @exports IRadii
     * @interface IRadii
     * @property {number|null} [baseWidgetRadius] Radii baseWidgetRadius
     * @property {number|null} [checkboxRadius] Radii checkboxRadius
     */

    /**
     * Constructs a new Radii.
     * @exports Radii
     * @classdesc Represents a Radii.
     * @implements IRadii
     * @constructor
     * @param {IRadii=} [properties] Properties to set
     */
    function Radii(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Radii baseWidgetRadius.
     * @member {number} baseWidgetRadius
     * @memberof Radii
     * @instance
     */
    Radii.prototype.baseWidgetRadius = 0;

    /**
     * Radii checkboxRadius.
     * @member {number} checkboxRadius
     * @memberof Radii
     * @instance
     */
    Radii.prototype.checkboxRadius = 0;

    /**
     * Creates a new Radii instance using the specified properties.
     * @function create
     * @memberof Radii
     * @static
     * @param {IRadii=} [properties] Properties to set
     * @returns {Radii} Radii instance
     */
    Radii.create = function create(properties) {
        return new Radii(properties);
    };

    /**
     * Encodes the specified Radii message. Does not implicitly {@link Radii.verify|verify} messages.
     * @function encode
     * @memberof Radii
     * @static
     * @param {IRadii} message Radii message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Radii.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.baseWidgetRadius != null && Object.hasOwnProperty.call(message, "baseWidgetRadius"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.baseWidgetRadius);
        if (message.checkboxRadius != null && Object.hasOwnProperty.call(message, "checkboxRadius"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.checkboxRadius);
        return writer;
    };

    /**
     * Encodes the specified Radii message, length delimited. Does not implicitly {@link Radii.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Radii
     * @static
     * @param {IRadii} message Radii message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Radii.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Radii message from the specified reader or buffer.
     * @function decode
     * @memberof Radii
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Radii} Radii
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Radii.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Radii();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.baseWidgetRadius = reader.int32();
                    break;
                }
            case 2: {
                    message.checkboxRadius = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Radii message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Radii
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Radii} Radii
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Radii.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Radii message.
     * @function verify
     * @memberof Radii
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Radii.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.baseWidgetRadius != null && message.hasOwnProperty("baseWidgetRadius"))
            if (!$util.isInteger(message.baseWidgetRadius))
                return "baseWidgetRadius: integer expected";
        if (message.checkboxRadius != null && message.hasOwnProperty("checkboxRadius"))
            if (!$util.isInteger(message.checkboxRadius))
                return "checkboxRadius: integer expected";
        return null;
    };

    /**
     * Creates a Radii message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Radii
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Radii} Radii
     */
    Radii.fromObject = function fromObject(object) {
        if (object instanceof $root.Radii)
            return object;
        let message = new $root.Radii();
        if (object.baseWidgetRadius != null)
            message.baseWidgetRadius = object.baseWidgetRadius | 0;
        if (object.checkboxRadius != null)
            message.checkboxRadius = object.checkboxRadius | 0;
        return message;
    };

    /**
     * Creates a plain object from a Radii message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Radii
     * @static
     * @param {Radii} message Radii
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Radii.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.baseWidgetRadius = 0;
            object.checkboxRadius = 0;
        }
        if (message.baseWidgetRadius != null && message.hasOwnProperty("baseWidgetRadius"))
            object.baseWidgetRadius = message.baseWidgetRadius;
        if (message.checkboxRadius != null && message.hasOwnProperty("checkboxRadius"))
            object.checkboxRadius = message.checkboxRadius;
        return object;
    };

    /**
     * Converts this Radii to JSON.
     * @function toJSON
     * @memberof Radii
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Radii.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Radii
     * @function getTypeUrl
     * @memberof Radii
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Radii.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Radii";
    };

    return Radii;
})();

export const FontSizes = $root.FontSizes = (() => {

    /**
     * Properties of a FontSizes.
     * @exports IFontSizes
     * @interface IFontSizes
     * @property {number|null} [tinyFontSize] FontSizes tinyFontSize
     * @property {number|null} [smallFontSize] FontSizes smallFontSize
     * @property {number|null} [baseFontSize] FontSizes baseFontSize
     */

    /**
     * Constructs a new FontSizes.
     * @exports FontSizes
     * @classdesc Represents a FontSizes.
     * @implements IFontSizes
     * @constructor
     * @param {IFontSizes=} [properties] Properties to set
     */
    function FontSizes(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FontSizes tinyFontSize.
     * @member {number} tinyFontSize
     * @memberof FontSizes
     * @instance
     */
    FontSizes.prototype.tinyFontSize = 0;

    /**
     * FontSizes smallFontSize.
     * @member {number} smallFontSize
     * @memberof FontSizes
     * @instance
     */
    FontSizes.prototype.smallFontSize = 0;

    /**
     * FontSizes baseFontSize.
     * @member {number} baseFontSize
     * @memberof FontSizes
     * @instance
     */
    FontSizes.prototype.baseFontSize = 0;

    /**
     * Creates a new FontSizes instance using the specified properties.
     * @function create
     * @memberof FontSizes
     * @static
     * @param {IFontSizes=} [properties] Properties to set
     * @returns {FontSizes} FontSizes instance
     */
    FontSizes.create = function create(properties) {
        return new FontSizes(properties);
    };

    /**
     * Encodes the specified FontSizes message. Does not implicitly {@link FontSizes.verify|verify} messages.
     * @function encode
     * @memberof FontSizes
     * @static
     * @param {IFontSizes} message FontSizes message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FontSizes.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tinyFontSize != null && Object.hasOwnProperty.call(message, "tinyFontSize"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tinyFontSize);
        if (message.smallFontSize != null && Object.hasOwnProperty.call(message, "smallFontSize"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.smallFontSize);
        if (message.baseFontSize != null && Object.hasOwnProperty.call(message, "baseFontSize"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.baseFontSize);
        return writer;
    };

    /**
     * Encodes the specified FontSizes message, length delimited. Does not implicitly {@link FontSizes.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FontSizes
     * @static
     * @param {IFontSizes} message FontSizes message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FontSizes.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FontSizes message from the specified reader or buffer.
     * @function decode
     * @memberof FontSizes
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FontSizes} FontSizes
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FontSizes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FontSizes();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.tinyFontSize = reader.int32();
                    break;
                }
            case 2: {
                    message.smallFontSize = reader.int32();
                    break;
                }
            case 3: {
                    message.baseFontSize = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FontSizes message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FontSizes
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FontSizes} FontSizes
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FontSizes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FontSizes message.
     * @function verify
     * @memberof FontSizes
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FontSizes.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tinyFontSize != null && message.hasOwnProperty("tinyFontSize"))
            if (!$util.isInteger(message.tinyFontSize))
                return "tinyFontSize: integer expected";
        if (message.smallFontSize != null && message.hasOwnProperty("smallFontSize"))
            if (!$util.isInteger(message.smallFontSize))
                return "smallFontSize: integer expected";
        if (message.baseFontSize != null && message.hasOwnProperty("baseFontSize"))
            if (!$util.isInteger(message.baseFontSize))
                return "baseFontSize: integer expected";
        return null;
    };

    /**
     * Creates a FontSizes message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FontSizes
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FontSizes} FontSizes
     */
    FontSizes.fromObject = function fromObject(object) {
        if (object instanceof $root.FontSizes)
            return object;
        let message = new $root.FontSizes();
        if (object.tinyFontSize != null)
            message.tinyFontSize = object.tinyFontSize | 0;
        if (object.smallFontSize != null)
            message.smallFontSize = object.smallFontSize | 0;
        if (object.baseFontSize != null)
            message.baseFontSize = object.baseFontSize | 0;
        return message;
    };

    /**
     * Creates a plain object from a FontSizes message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FontSizes
     * @static
     * @param {FontSizes} message FontSizes
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FontSizes.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.tinyFontSize = 0;
            object.smallFontSize = 0;
            object.baseFontSize = 0;
        }
        if (message.tinyFontSize != null && message.hasOwnProperty("tinyFontSize"))
            object.tinyFontSize = message.tinyFontSize;
        if (message.smallFontSize != null && message.hasOwnProperty("smallFontSize"))
            object.smallFontSize = message.smallFontSize;
        if (message.baseFontSize != null && message.hasOwnProperty("baseFontSize"))
            object.baseFontSize = message.baseFontSize;
        return object;
    };

    /**
     * Converts this FontSizes to JSON.
     * @function toJSON
     * @memberof FontSizes
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FontSizes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FontSizes
     * @function getTypeUrl
     * @memberof FontSizes
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FontSizes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FontSizes";
    };

    return FontSizes;
})();

export const UserInfo = $root.UserInfo = (() => {

    /**
     * Properties of a UserInfo.
     * @exports IUserInfo
     * @interface IUserInfo
     * @property {string|null} [installationId] UserInfo installationId
     * @property {string|null} [installationIdV3] UserInfo installationIdV3
     */

    /**
     * Constructs a new UserInfo.
     * @exports UserInfo
     * @classdesc Represents a UserInfo.
     * @implements IUserInfo
     * @constructor
     * @param {IUserInfo=} [properties] Properties to set
     */
    function UserInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserInfo installationId.
     * @member {string} installationId
     * @memberof UserInfo
     * @instance
     */
    UserInfo.prototype.installationId = "";

    /**
     * UserInfo installationIdV3.
     * @member {string} installationIdV3
     * @memberof UserInfo
     * @instance
     */
    UserInfo.prototype.installationIdV3 = "";

    /**
     * Creates a new UserInfo instance using the specified properties.
     * @function create
     * @memberof UserInfo
     * @static
     * @param {IUserInfo=} [properties] Properties to set
     * @returns {UserInfo} UserInfo instance
     */
    UserInfo.create = function create(properties) {
        return new UserInfo(properties);
    };

    /**
     * Encodes the specified UserInfo message. Does not implicitly {@link UserInfo.verify|verify} messages.
     * @function encode
     * @memberof UserInfo
     * @static
     * @param {IUserInfo} message UserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.installationId != null && Object.hasOwnProperty.call(message, "installationId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.installationId);
        if (message.installationIdV3 != null && Object.hasOwnProperty.call(message, "installationIdV3"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.installationIdV3);
        return writer;
    };

    /**
     * Encodes the specified UserInfo message, length delimited. Does not implicitly {@link UserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UserInfo
     * @static
     * @param {IUserInfo} message UserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof UserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UserInfo} UserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.installationId = reader.string();
                    break;
                }
            case 5: {
                    message.installationIdV3 = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a UserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UserInfo} UserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserInfo message.
     * @function verify
     * @memberof UserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.installationId != null && message.hasOwnProperty("installationId"))
            if (!$util.isString(message.installationId))
                return "installationId: string expected";
        if (message.installationIdV3 != null && message.hasOwnProperty("installationIdV3"))
            if (!$util.isString(message.installationIdV3))
                return "installationIdV3: string expected";
        return null;
    };

    /**
     * Creates a UserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UserInfo} UserInfo
     */
    UserInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.UserInfo)
            return object;
        let message = new $root.UserInfo();
        if (object.installationId != null)
            message.installationId = String(object.installationId);
        if (object.installationIdV3 != null)
            message.installationIdV3 = String(object.installationIdV3);
        return message;
    };

    /**
     * Creates a plain object from a UserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UserInfo
     * @static
     * @param {UserInfo} message UserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.installationId = "";
            object.installationIdV3 = "";
        }
        if (message.installationId != null && message.hasOwnProperty("installationId"))
            object.installationId = message.installationId;
        if (message.installationIdV3 != null && message.hasOwnProperty("installationIdV3"))
            object.installationIdV3 = message.installationIdV3;
        return object;
    };

    /**
     * Converts this UserInfo to JSON.
     * @function toJSON
     * @memberof UserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserInfo
     * @function getTypeUrl
     * @memberof UserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UserInfo";
    };

    return UserInfo;
})();

export const EnvironmentInfo = $root.EnvironmentInfo = (() => {

    /**
     * Properties of an EnvironmentInfo.
     * @exports IEnvironmentInfo
     * @interface IEnvironmentInfo
     * @property {string|null} [streamlitVersion] EnvironmentInfo streamlitVersion
     * @property {string|null} [pythonVersion] EnvironmentInfo pythonVersion
     */

    /**
     * Constructs a new EnvironmentInfo.
     * @exports EnvironmentInfo
     * @classdesc Represents an EnvironmentInfo.
     * @implements IEnvironmentInfo
     * @constructor
     * @param {IEnvironmentInfo=} [properties] Properties to set
     */
    function EnvironmentInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnvironmentInfo streamlitVersion.
     * @member {string} streamlitVersion
     * @memberof EnvironmentInfo
     * @instance
     */
    EnvironmentInfo.prototype.streamlitVersion = "";

    /**
     * EnvironmentInfo pythonVersion.
     * @member {string} pythonVersion
     * @memberof EnvironmentInfo
     * @instance
     */
    EnvironmentInfo.prototype.pythonVersion = "";

    /**
     * Creates a new EnvironmentInfo instance using the specified properties.
     * @function create
     * @memberof EnvironmentInfo
     * @static
     * @param {IEnvironmentInfo=} [properties] Properties to set
     * @returns {EnvironmentInfo} EnvironmentInfo instance
     */
    EnvironmentInfo.create = function create(properties) {
        return new EnvironmentInfo(properties);
    };

    /**
     * Encodes the specified EnvironmentInfo message. Does not implicitly {@link EnvironmentInfo.verify|verify} messages.
     * @function encode
     * @memberof EnvironmentInfo
     * @static
     * @param {IEnvironmentInfo} message EnvironmentInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnvironmentInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamlitVersion != null && Object.hasOwnProperty.call(message, "streamlitVersion"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.streamlitVersion);
        if (message.pythonVersion != null && Object.hasOwnProperty.call(message, "pythonVersion"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pythonVersion);
        return writer;
    };

    /**
     * Encodes the specified EnvironmentInfo message, length delimited. Does not implicitly {@link EnvironmentInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof EnvironmentInfo
     * @static
     * @param {IEnvironmentInfo} message EnvironmentInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnvironmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnvironmentInfo message from the specified reader or buffer.
     * @function decode
     * @memberof EnvironmentInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {EnvironmentInfo} EnvironmentInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnvironmentInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.EnvironmentInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamlitVersion = reader.string();
                    break;
                }
            case 2: {
                    message.pythonVersion = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an EnvironmentInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof EnvironmentInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {EnvironmentInfo} EnvironmentInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnvironmentInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnvironmentInfo message.
     * @function verify
     * @memberof EnvironmentInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnvironmentInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.streamlitVersion != null && message.hasOwnProperty("streamlitVersion"))
            if (!$util.isString(message.streamlitVersion))
                return "streamlitVersion: string expected";
        if (message.pythonVersion != null && message.hasOwnProperty("pythonVersion"))
            if (!$util.isString(message.pythonVersion))
                return "pythonVersion: string expected";
        return null;
    };

    /**
     * Creates an EnvironmentInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof EnvironmentInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {EnvironmentInfo} EnvironmentInfo
     */
    EnvironmentInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.EnvironmentInfo)
            return object;
        let message = new $root.EnvironmentInfo();
        if (object.streamlitVersion != null)
            message.streamlitVersion = String(object.streamlitVersion);
        if (object.pythonVersion != null)
            message.pythonVersion = String(object.pythonVersion);
        return message;
    };

    /**
     * Creates a plain object from an EnvironmentInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof EnvironmentInfo
     * @static
     * @param {EnvironmentInfo} message EnvironmentInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnvironmentInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.streamlitVersion = "";
            object.pythonVersion = "";
        }
        if (message.streamlitVersion != null && message.hasOwnProperty("streamlitVersion"))
            object.streamlitVersion = message.streamlitVersion;
        if (message.pythonVersion != null && message.hasOwnProperty("pythonVersion"))
            object.pythonVersion = message.pythonVersion;
        return object;
    };

    /**
     * Converts this EnvironmentInfo to JSON.
     * @function toJSON
     * @memberof EnvironmentInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnvironmentInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnvironmentInfo
     * @function getTypeUrl
     * @memberof EnvironmentInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnvironmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/EnvironmentInfo";
    };

    return EnvironmentInfo;
})();

export const SessionStatus = $root.SessionStatus = (() => {

    /**
     * Properties of a SessionStatus.
     * @exports ISessionStatus
     * @interface ISessionStatus
     * @property {boolean|null} [runOnSave] SessionStatus runOnSave
     * @property {boolean|null} [scriptIsRunning] SessionStatus scriptIsRunning
     */

    /**
     * Constructs a new SessionStatus.
     * @exports SessionStatus
     * @classdesc Represents a SessionStatus.
     * @implements ISessionStatus
     * @constructor
     * @param {ISessionStatus=} [properties] Properties to set
     */
    function SessionStatus(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SessionStatus runOnSave.
     * @member {boolean} runOnSave
     * @memberof SessionStatus
     * @instance
     */
    SessionStatus.prototype.runOnSave = false;

    /**
     * SessionStatus scriptIsRunning.
     * @member {boolean} scriptIsRunning
     * @memberof SessionStatus
     * @instance
     */
    SessionStatus.prototype.scriptIsRunning = false;

    /**
     * Creates a new SessionStatus instance using the specified properties.
     * @function create
     * @memberof SessionStatus
     * @static
     * @param {ISessionStatus=} [properties] Properties to set
     * @returns {SessionStatus} SessionStatus instance
     */
    SessionStatus.create = function create(properties) {
        return new SessionStatus(properties);
    };

    /**
     * Encodes the specified SessionStatus message. Does not implicitly {@link SessionStatus.verify|verify} messages.
     * @function encode
     * @memberof SessionStatus
     * @static
     * @param {ISessionStatus} message SessionStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SessionStatus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.runOnSave != null && Object.hasOwnProperty.call(message, "runOnSave"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.runOnSave);
        if (message.scriptIsRunning != null && Object.hasOwnProperty.call(message, "scriptIsRunning"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.scriptIsRunning);
        return writer;
    };

    /**
     * Encodes the specified SessionStatus message, length delimited. Does not implicitly {@link SessionStatus.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SessionStatus
     * @static
     * @param {ISessionStatus} message SessionStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SessionStatus.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SessionStatus message from the specified reader or buffer.
     * @function decode
     * @memberof SessionStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SessionStatus} SessionStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SessionStatus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SessionStatus();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.runOnSave = reader.bool();
                    break;
                }
            case 2: {
                    message.scriptIsRunning = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SessionStatus message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SessionStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SessionStatus} SessionStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SessionStatus.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SessionStatus message.
     * @function verify
     * @memberof SessionStatus
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SessionStatus.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.runOnSave != null && message.hasOwnProperty("runOnSave"))
            if (typeof message.runOnSave !== "boolean")
                return "runOnSave: boolean expected";
        if (message.scriptIsRunning != null && message.hasOwnProperty("scriptIsRunning"))
            if (typeof message.scriptIsRunning !== "boolean")
                return "scriptIsRunning: boolean expected";
        return null;
    };

    /**
     * Creates a SessionStatus message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SessionStatus
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SessionStatus} SessionStatus
     */
    SessionStatus.fromObject = function fromObject(object) {
        if (object instanceof $root.SessionStatus)
            return object;
        let message = new $root.SessionStatus();
        if (object.runOnSave != null)
            message.runOnSave = Boolean(object.runOnSave);
        if (object.scriptIsRunning != null)
            message.scriptIsRunning = Boolean(object.scriptIsRunning);
        return message;
    };

    /**
     * Creates a plain object from a SessionStatus message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SessionStatus
     * @static
     * @param {SessionStatus} message SessionStatus
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SessionStatus.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.runOnSave = false;
            object.scriptIsRunning = false;
        }
        if (message.runOnSave != null && message.hasOwnProperty("runOnSave"))
            object.runOnSave = message.runOnSave;
        if (message.scriptIsRunning != null && message.hasOwnProperty("scriptIsRunning"))
            object.scriptIsRunning = message.scriptIsRunning;
        return object;
    };

    /**
     * Converts this SessionStatus to JSON.
     * @function toJSON
     * @memberof SessionStatus
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SessionStatus.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SessionStatus
     * @function getTypeUrl
     * @memberof SessionStatus
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SessionStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SessionStatus";
    };

    return SessionStatus;
})();

export const PageConfig = $root.PageConfig = (() => {

    /**
     * Properties of a PageConfig.
     * @exports IPageConfig
     * @interface IPageConfig
     * @property {string|null} [title] PageConfig title
     * @property {string|null} [favicon] PageConfig favicon
     * @property {PageConfig.Layout|null} [layout] PageConfig layout
     * @property {PageConfig.SidebarState|null} [initialSidebarState] PageConfig initialSidebarState
     * @property {PageConfig.IMenuItems|null} [menuItems] PageConfig menuItems
     */

    /**
     * Constructs a new PageConfig.
     * @exports PageConfig
     * @classdesc Represents a PageConfig.
     * @implements IPageConfig
     * @constructor
     * @param {IPageConfig=} [properties] Properties to set
     */
    function PageConfig(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageConfig title.
     * @member {string} title
     * @memberof PageConfig
     * @instance
     */
    PageConfig.prototype.title = "";

    /**
     * PageConfig favicon.
     * @member {string} favicon
     * @memberof PageConfig
     * @instance
     */
    PageConfig.prototype.favicon = "";

    /**
     * PageConfig layout.
     * @member {PageConfig.Layout} layout
     * @memberof PageConfig
     * @instance
     */
    PageConfig.prototype.layout = 0;

    /**
     * PageConfig initialSidebarState.
     * @member {PageConfig.SidebarState} initialSidebarState
     * @memberof PageConfig
     * @instance
     */
    PageConfig.prototype.initialSidebarState = 0;

    /**
     * PageConfig menuItems.
     * @member {PageConfig.IMenuItems|null|undefined} menuItems
     * @memberof PageConfig
     * @instance
     */
    PageConfig.prototype.menuItems = null;

    /**
     * Creates a new PageConfig instance using the specified properties.
     * @function create
     * @memberof PageConfig
     * @static
     * @param {IPageConfig=} [properties] Properties to set
     * @returns {PageConfig} PageConfig instance
     */
    PageConfig.create = function create(properties) {
        return new PageConfig(properties);
    };

    /**
     * Encodes the specified PageConfig message. Does not implicitly {@link PageConfig.verify|verify} messages.
     * @function encode
     * @memberof PageConfig
     * @static
     * @param {IPageConfig} message PageConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageConfig.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
        if (message.favicon != null && Object.hasOwnProperty.call(message, "favicon"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.favicon);
        if (message.layout != null && Object.hasOwnProperty.call(message, "layout"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.layout);
        if (message.initialSidebarState != null && Object.hasOwnProperty.call(message, "initialSidebarState"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.initialSidebarState);
        if (message.menuItems != null && Object.hasOwnProperty.call(message, "menuItems"))
            $root.PageConfig.MenuItems.encode(message.menuItems, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified PageConfig message, length delimited. Does not implicitly {@link PageConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PageConfig
     * @static
     * @param {IPageConfig} message PageConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageConfig message from the specified reader or buffer.
     * @function decode
     * @memberof PageConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PageConfig} PageConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageConfig();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.title = reader.string();
                    break;
                }
            case 2: {
                    message.favicon = reader.string();
                    break;
                }
            case 3: {
                    message.layout = reader.int32();
                    break;
                }
            case 4: {
                    message.initialSidebarState = reader.int32();
                    break;
                }
            case 5: {
                    message.menuItems = $root.PageConfig.MenuItems.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PageConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PageConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PageConfig} PageConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageConfig message.
     * @function verify
     * @memberof PageConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.favicon != null && message.hasOwnProperty("favicon"))
            if (!$util.isString(message.favicon))
                return "favicon: string expected";
        if (message.layout != null && message.hasOwnProperty("layout"))
            switch (message.layout) {
            default:
                return "layout: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.initialSidebarState != null && message.hasOwnProperty("initialSidebarState"))
            switch (message.initialSidebarState) {
            default:
                return "initialSidebarState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
            let error = $root.PageConfig.MenuItems.verify(message.menuItems);
            if (error)
                return "menuItems." + error;
        }
        return null;
    };

    /**
     * Creates a PageConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PageConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PageConfig} PageConfig
     */
    PageConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.PageConfig)
            return object;
        let message = new $root.PageConfig();
        if (object.title != null)
            message.title = String(object.title);
        if (object.favicon != null)
            message.favicon = String(object.favicon);
        switch (object.layout) {
        default:
            if (typeof object.layout === "number") {
                message.layout = object.layout;
                break;
            }
            break;
        case "CENTERED":
        case 0:
            message.layout = 0;
            break;
        case "WIDE":
        case 1:
            message.layout = 1;
            break;
        }
        switch (object.initialSidebarState) {
        default:
            if (typeof object.initialSidebarState === "number") {
                message.initialSidebarState = object.initialSidebarState;
                break;
            }
            break;
        case "AUTO":
        case 0:
            message.initialSidebarState = 0;
            break;
        case "EXPANDED":
        case 1:
            message.initialSidebarState = 1;
            break;
        case "COLLAPSED":
        case 2:
            message.initialSidebarState = 2;
            break;
        }
        if (object.menuItems != null) {
            if (typeof object.menuItems !== "object")
                throw TypeError(".PageConfig.menuItems: object expected");
            message.menuItems = $root.PageConfig.MenuItems.fromObject(object.menuItems);
        }
        return message;
    };

    /**
     * Creates a plain object from a PageConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PageConfig
     * @static
     * @param {PageConfig} message PageConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageConfig.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.title = "";
            object.favicon = "";
            object.layout = options.enums === String ? "CENTERED" : 0;
            object.initialSidebarState = options.enums === String ? "AUTO" : 0;
            object.menuItems = null;
        }
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.favicon != null && message.hasOwnProperty("favicon"))
            object.favicon = message.favicon;
        if (message.layout != null && message.hasOwnProperty("layout"))
            object.layout = options.enums === String ? $root.PageConfig.Layout[message.layout] === undefined ? message.layout : $root.PageConfig.Layout[message.layout] : message.layout;
        if (message.initialSidebarState != null && message.hasOwnProperty("initialSidebarState"))
            object.initialSidebarState = options.enums === String ? $root.PageConfig.SidebarState[message.initialSidebarState] === undefined ? message.initialSidebarState : $root.PageConfig.SidebarState[message.initialSidebarState] : message.initialSidebarState;
        if (message.menuItems != null && message.hasOwnProperty("menuItems"))
            object.menuItems = $root.PageConfig.MenuItems.toObject(message.menuItems, options);
        return object;
    };

    /**
     * Converts this PageConfig to JSON.
     * @function toJSON
     * @memberof PageConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PageConfig
     * @function getTypeUrl
     * @memberof PageConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PageConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageConfig";
    };

    PageConfig.MenuItems = (function() {

        /**
         * Properties of a MenuItems.
         * @memberof PageConfig
         * @interface IMenuItems
         * @property {string|null} [getHelpUrl] MenuItems getHelpUrl
         * @property {boolean|null} [hideGetHelp] MenuItems hideGetHelp
         * @property {string|null} [reportABugUrl] MenuItems reportABugUrl
         * @property {boolean|null} [hideReportABug] MenuItems hideReportABug
         * @property {string|null} [aboutSectionMd] MenuItems aboutSectionMd
         */

        /**
         * Constructs a new MenuItems.
         * @memberof PageConfig
         * @classdesc Represents a MenuItems.
         * @implements IMenuItems
         * @constructor
         * @param {PageConfig.IMenuItems=} [properties] Properties to set
         */
        function MenuItems(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MenuItems getHelpUrl.
         * @member {string} getHelpUrl
         * @memberof PageConfig.MenuItems
         * @instance
         */
        MenuItems.prototype.getHelpUrl = "";

        /**
         * MenuItems hideGetHelp.
         * @member {boolean} hideGetHelp
         * @memberof PageConfig.MenuItems
         * @instance
         */
        MenuItems.prototype.hideGetHelp = false;

        /**
         * MenuItems reportABugUrl.
         * @member {string} reportABugUrl
         * @memberof PageConfig.MenuItems
         * @instance
         */
        MenuItems.prototype.reportABugUrl = "";

        /**
         * MenuItems hideReportABug.
         * @member {boolean} hideReportABug
         * @memberof PageConfig.MenuItems
         * @instance
         */
        MenuItems.prototype.hideReportABug = false;

        /**
         * MenuItems aboutSectionMd.
         * @member {string} aboutSectionMd
         * @memberof PageConfig.MenuItems
         * @instance
         */
        MenuItems.prototype.aboutSectionMd = "";

        /**
         * Creates a new MenuItems instance using the specified properties.
         * @function create
         * @memberof PageConfig.MenuItems
         * @static
         * @param {PageConfig.IMenuItems=} [properties] Properties to set
         * @returns {PageConfig.MenuItems} MenuItems instance
         */
        MenuItems.create = function create(properties) {
            return new MenuItems(properties);
        };

        /**
         * Encodes the specified MenuItems message. Does not implicitly {@link PageConfig.MenuItems.verify|verify} messages.
         * @function encode
         * @memberof PageConfig.MenuItems
         * @static
         * @param {PageConfig.IMenuItems} message MenuItems message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MenuItems.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.getHelpUrl != null && Object.hasOwnProperty.call(message, "getHelpUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.getHelpUrl);
            if (message.hideGetHelp != null && Object.hasOwnProperty.call(message, "hideGetHelp"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hideGetHelp);
            if (message.reportABugUrl != null && Object.hasOwnProperty.call(message, "reportABugUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reportABugUrl);
            if (message.hideReportABug != null && Object.hasOwnProperty.call(message, "hideReportABug"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hideReportABug);
            if (message.aboutSectionMd != null && Object.hasOwnProperty.call(message, "aboutSectionMd"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.aboutSectionMd);
            return writer;
        };

        /**
         * Encodes the specified MenuItems message, length delimited. Does not implicitly {@link PageConfig.MenuItems.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PageConfig.MenuItems
         * @static
         * @param {PageConfig.IMenuItems} message MenuItems message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MenuItems.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MenuItems message from the specified reader or buffer.
         * @function decode
         * @memberof PageConfig.MenuItems
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PageConfig.MenuItems} MenuItems
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MenuItems.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageConfig.MenuItems();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.getHelpUrl = reader.string();
                        break;
                    }
                case 2: {
                        message.hideGetHelp = reader.bool();
                        break;
                    }
                case 3: {
                        message.reportABugUrl = reader.string();
                        break;
                    }
                case 4: {
                        message.hideReportABug = reader.bool();
                        break;
                    }
                case 5: {
                        message.aboutSectionMd = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MenuItems message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PageConfig.MenuItems
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PageConfig.MenuItems} MenuItems
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MenuItems.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MenuItems message.
         * @function verify
         * @memberof PageConfig.MenuItems
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MenuItems.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.getHelpUrl != null && message.hasOwnProperty("getHelpUrl"))
                if (!$util.isString(message.getHelpUrl))
                    return "getHelpUrl: string expected";
            if (message.hideGetHelp != null && message.hasOwnProperty("hideGetHelp"))
                if (typeof message.hideGetHelp !== "boolean")
                    return "hideGetHelp: boolean expected";
            if (message.reportABugUrl != null && message.hasOwnProperty("reportABugUrl"))
                if (!$util.isString(message.reportABugUrl))
                    return "reportABugUrl: string expected";
            if (message.hideReportABug != null && message.hasOwnProperty("hideReportABug"))
                if (typeof message.hideReportABug !== "boolean")
                    return "hideReportABug: boolean expected";
            if (message.aboutSectionMd != null && message.hasOwnProperty("aboutSectionMd"))
                if (!$util.isString(message.aboutSectionMd))
                    return "aboutSectionMd: string expected";
            return null;
        };

        /**
         * Creates a MenuItems message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PageConfig.MenuItems
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PageConfig.MenuItems} MenuItems
         */
        MenuItems.fromObject = function fromObject(object) {
            if (object instanceof $root.PageConfig.MenuItems)
                return object;
            let message = new $root.PageConfig.MenuItems();
            if (object.getHelpUrl != null)
                message.getHelpUrl = String(object.getHelpUrl);
            if (object.hideGetHelp != null)
                message.hideGetHelp = Boolean(object.hideGetHelp);
            if (object.reportABugUrl != null)
                message.reportABugUrl = String(object.reportABugUrl);
            if (object.hideReportABug != null)
                message.hideReportABug = Boolean(object.hideReportABug);
            if (object.aboutSectionMd != null)
                message.aboutSectionMd = String(object.aboutSectionMd);
            return message;
        };

        /**
         * Creates a plain object from a MenuItems message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PageConfig.MenuItems
         * @static
         * @param {PageConfig.MenuItems} message MenuItems
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MenuItems.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.getHelpUrl = "";
                object.hideGetHelp = false;
                object.reportABugUrl = "";
                object.hideReportABug = false;
                object.aboutSectionMd = "";
            }
            if (message.getHelpUrl != null && message.hasOwnProperty("getHelpUrl"))
                object.getHelpUrl = message.getHelpUrl;
            if (message.hideGetHelp != null && message.hasOwnProperty("hideGetHelp"))
                object.hideGetHelp = message.hideGetHelp;
            if (message.reportABugUrl != null && message.hasOwnProperty("reportABugUrl"))
                object.reportABugUrl = message.reportABugUrl;
            if (message.hideReportABug != null && message.hasOwnProperty("hideReportABug"))
                object.hideReportABug = message.hideReportABug;
            if (message.aboutSectionMd != null && message.hasOwnProperty("aboutSectionMd"))
                object.aboutSectionMd = message.aboutSectionMd;
            return object;
        };

        /**
         * Converts this MenuItems to JSON.
         * @function toJSON
         * @memberof PageConfig.MenuItems
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MenuItems.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MenuItems
         * @function getTypeUrl
         * @memberof PageConfig.MenuItems
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MenuItems.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PageConfig.MenuItems";
        };

        return MenuItems;
    })();

    /**
     * Layout enum.
     * @name PageConfig.Layout
     * @enum {number}
     * @property {number} CENTERED=0 CENTERED value
     * @property {number} WIDE=1 WIDE value
     */
    PageConfig.Layout = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CENTERED"] = 0;
        values[valuesById[1] = "WIDE"] = 1;
        return values;
    })();

    /**
     * SidebarState enum.
     * @name PageConfig.SidebarState
     * @enum {number}
     * @property {number} AUTO=0 AUTO value
     * @property {number} EXPANDED=1 EXPANDED value
     * @property {number} COLLAPSED=2 COLLAPSED value
     */
    PageConfig.SidebarState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUTO"] = 0;
        values[valuesById[1] = "EXPANDED"] = 1;
        values[valuesById[2] = "COLLAPSED"] = 2;
        return values;
    })();

    return PageConfig;
})();

export const PageInfo = $root.PageInfo = (() => {

    /**
     * Properties of a PageInfo.
     * @exports IPageInfo
     * @interface IPageInfo
     * @property {string|null} [queryString] PageInfo queryString
     */

    /**
     * Constructs a new PageInfo.
     * @exports PageInfo
     * @classdesc Represents a PageInfo.
     * @implements IPageInfo
     * @constructor
     * @param {IPageInfo=} [properties] Properties to set
     */
    function PageInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageInfo queryString.
     * @member {string} queryString
     * @memberof PageInfo
     * @instance
     */
    PageInfo.prototype.queryString = "";

    /**
     * Creates a new PageInfo instance using the specified properties.
     * @function create
     * @memberof PageInfo
     * @static
     * @param {IPageInfo=} [properties] Properties to set
     * @returns {PageInfo} PageInfo instance
     */
    PageInfo.create = function create(properties) {
        return new PageInfo(properties);
    };

    /**
     * Encodes the specified PageInfo message. Does not implicitly {@link PageInfo.verify|verify} messages.
     * @function encode
     * @memberof PageInfo
     * @static
     * @param {IPageInfo} message PageInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.queryString != null && Object.hasOwnProperty.call(message, "queryString"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryString);
        return writer;
    };

    /**
     * Encodes the specified PageInfo message, length delimited. Does not implicitly {@link PageInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PageInfo
     * @static
     * @param {IPageInfo} message PageInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageInfo message from the specified reader or buffer.
     * @function decode
     * @memberof PageInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PageInfo} PageInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.queryString = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PageInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PageInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PageInfo} PageInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageInfo message.
     * @function verify
     * @memberof PageInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.queryString != null && message.hasOwnProperty("queryString"))
            if (!$util.isString(message.queryString))
                return "queryString: string expected";
        return null;
    };

    /**
     * Creates a PageInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PageInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PageInfo} PageInfo
     */
    PageInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.PageInfo)
            return object;
        let message = new $root.PageInfo();
        if (object.queryString != null)
            message.queryString = String(object.queryString);
        return message;
    };

    /**
     * Creates a plain object from a PageInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PageInfo
     * @static
     * @param {PageInfo} message PageInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.queryString = "";
        if (message.queryString != null && message.hasOwnProperty("queryString"))
            object.queryString = message.queryString;
        return object;
    };

    /**
     * Converts this PageInfo to JSON.
     * @function toJSON
     * @memberof PageInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PageInfo
     * @function getTypeUrl
     * @memberof PageInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PageInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageInfo";
    };

    return PageInfo;
})();

export const PageProfile = $root.PageProfile = (() => {

    /**
     * Properties of a PageProfile.
     * @exports IPageProfile
     * @interface IPageProfile
     * @property {Array.<ICommand>|null} [commands] PageProfile commands
     * @property {number|Long|null} [execTime] PageProfile execTime
     * @property {number|Long|null} [prepTime] PageProfile prepTime
     * @property {Array.<string>|null} [config] PageProfile config
     * @property {string|null} [uncaughtException] PageProfile uncaughtException
     * @property {Array.<string>|null} [attributions] PageProfile attributions
     * @property {string|null} [os] PageProfile os
     * @property {string|null} [timezone] PageProfile timezone
     * @property {boolean|null} [headless] PageProfile headless
     * @property {boolean|null} [isFragmentRun] PageProfile isFragmentRun
     */

    /**
     * Constructs a new PageProfile.
     * @exports PageProfile
     * @classdesc Represents a PageProfile.
     * @implements IPageProfile
     * @constructor
     * @param {IPageProfile=} [properties] Properties to set
     */
    function PageProfile(properties) {
        this.commands = [];
        this.config = [];
        this.attributions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageProfile commands.
     * @member {Array.<ICommand>} commands
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.commands = $util.emptyArray;

    /**
     * PageProfile execTime.
     * @member {number|Long} execTime
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.execTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * PageProfile prepTime.
     * @member {number|Long} prepTime
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.prepTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * PageProfile config.
     * @member {Array.<string>} config
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.config = $util.emptyArray;

    /**
     * PageProfile uncaughtException.
     * @member {string} uncaughtException
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.uncaughtException = "";

    /**
     * PageProfile attributions.
     * @member {Array.<string>} attributions
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.attributions = $util.emptyArray;

    /**
     * PageProfile os.
     * @member {string} os
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.os = "";

    /**
     * PageProfile timezone.
     * @member {string} timezone
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.timezone = "";

    /**
     * PageProfile headless.
     * @member {boolean} headless
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.headless = false;

    /**
     * PageProfile isFragmentRun.
     * @member {boolean} isFragmentRun
     * @memberof PageProfile
     * @instance
     */
    PageProfile.prototype.isFragmentRun = false;

    /**
     * Creates a new PageProfile instance using the specified properties.
     * @function create
     * @memberof PageProfile
     * @static
     * @param {IPageProfile=} [properties] Properties to set
     * @returns {PageProfile} PageProfile instance
     */
    PageProfile.create = function create(properties) {
        return new PageProfile(properties);
    };

    /**
     * Encodes the specified PageProfile message. Does not implicitly {@link PageProfile.verify|verify} messages.
     * @function encode
     * @memberof PageProfile
     * @static
     * @param {IPageProfile} message PageProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageProfile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.commands != null && message.commands.length)
            for (let i = 0; i < message.commands.length; ++i)
                $root.Command.encode(message.commands[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.execTime != null && Object.hasOwnProperty.call(message, "execTime"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.execTime);
        if (message.prepTime != null && Object.hasOwnProperty.call(message, "prepTime"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.prepTime);
        if (message.config != null && message.config.length)
            for (let i = 0; i < message.config.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.config[i]);
        if (message.uncaughtException != null && Object.hasOwnProperty.call(message, "uncaughtException"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.uncaughtException);
        if (message.attributions != null && message.attributions.length)
            for (let i = 0; i < message.attributions.length; ++i)
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.attributions[i]);
        if (message.os != null && Object.hasOwnProperty.call(message, "os"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.os);
        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.timezone);
        if (message.headless != null && Object.hasOwnProperty.call(message, "headless"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.headless);
        if (message.isFragmentRun != null && Object.hasOwnProperty.call(message, "isFragmentRun"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isFragmentRun);
        return writer;
    };

    /**
     * Encodes the specified PageProfile message, length delimited. Does not implicitly {@link PageProfile.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PageProfile
     * @static
     * @param {IPageProfile} message PageProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageProfile.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageProfile message from the specified reader or buffer.
     * @function decode
     * @memberof PageProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PageProfile} PageProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageProfile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageProfile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.commands && message.commands.length))
                        message.commands = [];
                    message.commands.push($root.Command.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.execTime = reader.int64();
                    break;
                }
            case 3: {
                    message.prepTime = reader.int64();
                    break;
                }
            case 5: {
                    if (!(message.config && message.config.length))
                        message.config = [];
                    message.config.push(reader.string());
                    break;
                }
            case 6: {
                    message.uncaughtException = reader.string();
                    break;
                }
            case 7: {
                    if (!(message.attributions && message.attributions.length))
                        message.attributions = [];
                    message.attributions.push(reader.string());
                    break;
                }
            case 8: {
                    message.os = reader.string();
                    break;
                }
            case 9: {
                    message.timezone = reader.string();
                    break;
                }
            case 10: {
                    message.headless = reader.bool();
                    break;
                }
            case 11: {
                    message.isFragmentRun = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PageProfile message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PageProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PageProfile} PageProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageProfile.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageProfile message.
     * @function verify
     * @memberof PageProfile
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageProfile.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.commands != null && message.hasOwnProperty("commands")) {
            if (!Array.isArray(message.commands))
                return "commands: array expected";
            for (let i = 0; i < message.commands.length; ++i) {
                let error = $root.Command.verify(message.commands[i]);
                if (error)
                    return "commands." + error;
            }
        }
        if (message.execTime != null && message.hasOwnProperty("execTime"))
            if (!$util.isInteger(message.execTime) && !(message.execTime && $util.isInteger(message.execTime.low) && $util.isInteger(message.execTime.high)))
                return "execTime: integer|Long expected";
        if (message.prepTime != null && message.hasOwnProperty("prepTime"))
            if (!$util.isInteger(message.prepTime) && !(message.prepTime && $util.isInteger(message.prepTime.low) && $util.isInteger(message.prepTime.high)))
                return "prepTime: integer|Long expected";
        if (message.config != null && message.hasOwnProperty("config")) {
            if (!Array.isArray(message.config))
                return "config: array expected";
            for (let i = 0; i < message.config.length; ++i)
                if (!$util.isString(message.config[i]))
                    return "config: string[] expected";
        }
        if (message.uncaughtException != null && message.hasOwnProperty("uncaughtException"))
            if (!$util.isString(message.uncaughtException))
                return "uncaughtException: string expected";
        if (message.attributions != null && message.hasOwnProperty("attributions")) {
            if (!Array.isArray(message.attributions))
                return "attributions: array expected";
            for (let i = 0; i < message.attributions.length; ++i)
                if (!$util.isString(message.attributions[i]))
                    return "attributions: string[] expected";
        }
        if (message.os != null && message.hasOwnProperty("os"))
            if (!$util.isString(message.os))
                return "os: string expected";
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            if (!$util.isString(message.timezone))
                return "timezone: string expected";
        if (message.headless != null && message.hasOwnProperty("headless"))
            if (typeof message.headless !== "boolean")
                return "headless: boolean expected";
        if (message.isFragmentRun != null && message.hasOwnProperty("isFragmentRun"))
            if (typeof message.isFragmentRun !== "boolean")
                return "isFragmentRun: boolean expected";
        return null;
    };

    /**
     * Creates a PageProfile message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PageProfile
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PageProfile} PageProfile
     */
    PageProfile.fromObject = function fromObject(object) {
        if (object instanceof $root.PageProfile)
            return object;
        let message = new $root.PageProfile();
        if (object.commands) {
            if (!Array.isArray(object.commands))
                throw TypeError(".PageProfile.commands: array expected");
            message.commands = [];
            for (let i = 0; i < object.commands.length; ++i) {
                if (typeof object.commands[i] !== "object")
                    throw TypeError(".PageProfile.commands: object expected");
                message.commands[i] = $root.Command.fromObject(object.commands[i]);
            }
        }
        if (object.execTime != null)
            if ($util.Long)
                (message.execTime = $util.Long.fromValue(object.execTime)).unsigned = false;
            else if (typeof object.execTime === "string")
                message.execTime = parseInt(object.execTime, 10);
            else if (typeof object.execTime === "number")
                message.execTime = object.execTime;
            else if (typeof object.execTime === "object")
                message.execTime = new $util.LongBits(object.execTime.low >>> 0, object.execTime.high >>> 0).toNumber();
        if (object.prepTime != null)
            if ($util.Long)
                (message.prepTime = $util.Long.fromValue(object.prepTime)).unsigned = false;
            else if (typeof object.prepTime === "string")
                message.prepTime = parseInt(object.prepTime, 10);
            else if (typeof object.prepTime === "number")
                message.prepTime = object.prepTime;
            else if (typeof object.prepTime === "object")
                message.prepTime = new $util.LongBits(object.prepTime.low >>> 0, object.prepTime.high >>> 0).toNumber();
        if (object.config) {
            if (!Array.isArray(object.config))
                throw TypeError(".PageProfile.config: array expected");
            message.config = [];
            for (let i = 0; i < object.config.length; ++i)
                message.config[i] = String(object.config[i]);
        }
        if (object.uncaughtException != null)
            message.uncaughtException = String(object.uncaughtException);
        if (object.attributions) {
            if (!Array.isArray(object.attributions))
                throw TypeError(".PageProfile.attributions: array expected");
            message.attributions = [];
            for (let i = 0; i < object.attributions.length; ++i)
                message.attributions[i] = String(object.attributions[i]);
        }
        if (object.os != null)
            message.os = String(object.os);
        if (object.timezone != null)
            message.timezone = String(object.timezone);
        if (object.headless != null)
            message.headless = Boolean(object.headless);
        if (object.isFragmentRun != null)
            message.isFragmentRun = Boolean(object.isFragmentRun);
        return message;
    };

    /**
     * Creates a plain object from a PageProfile message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PageProfile
     * @static
     * @param {PageProfile} message PageProfile
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageProfile.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.commands = [];
            object.config = [];
            object.attributions = [];
        }
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.execTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.execTime = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.prepTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.prepTime = options.longs === String ? "0" : 0;
            object.uncaughtException = "";
            object.os = "";
            object.timezone = "";
            object.headless = false;
            object.isFragmentRun = false;
        }
        if (message.commands && message.commands.length) {
            object.commands = [];
            for (let j = 0; j < message.commands.length; ++j)
                object.commands[j] = $root.Command.toObject(message.commands[j], options);
        }
        if (message.execTime != null && message.hasOwnProperty("execTime"))
            if (typeof message.execTime === "number")
                object.execTime = options.longs === String ? String(message.execTime) : message.execTime;
            else
                object.execTime = options.longs === String ? $util.Long.prototype.toString.call(message.execTime) : options.longs === Number ? new $util.LongBits(message.execTime.low >>> 0, message.execTime.high >>> 0).toNumber() : message.execTime;
        if (message.prepTime != null && message.hasOwnProperty("prepTime"))
            if (typeof message.prepTime === "number")
                object.prepTime = options.longs === String ? String(message.prepTime) : message.prepTime;
            else
                object.prepTime = options.longs === String ? $util.Long.prototype.toString.call(message.prepTime) : options.longs === Number ? new $util.LongBits(message.prepTime.low >>> 0, message.prepTime.high >>> 0).toNumber() : message.prepTime;
        if (message.config && message.config.length) {
            object.config = [];
            for (let j = 0; j < message.config.length; ++j)
                object.config[j] = message.config[j];
        }
        if (message.uncaughtException != null && message.hasOwnProperty("uncaughtException"))
            object.uncaughtException = message.uncaughtException;
        if (message.attributions && message.attributions.length) {
            object.attributions = [];
            for (let j = 0; j < message.attributions.length; ++j)
                object.attributions[j] = message.attributions[j];
        }
        if (message.os != null && message.hasOwnProperty("os"))
            object.os = message.os;
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            object.timezone = message.timezone;
        if (message.headless != null && message.hasOwnProperty("headless"))
            object.headless = message.headless;
        if (message.isFragmentRun != null && message.hasOwnProperty("isFragmentRun"))
            object.isFragmentRun = message.isFragmentRun;
        return object;
    };

    /**
     * Converts this PageProfile to JSON.
     * @function toJSON
     * @memberof PageProfile
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageProfile.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PageProfile
     * @function getTypeUrl
     * @memberof PageProfile
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PageProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageProfile";
    };

    return PageProfile;
})();

export const Argument = $root.Argument = (() => {

    /**
     * Properties of an Argument.
     * @exports IArgument
     * @interface IArgument
     * @property {string|null} [k] Argument k
     * @property {string|null} [t] Argument t
     * @property {string|null} [m] Argument m
     * @property {number|null} [p] Argument p
     */

    /**
     * Constructs a new Argument.
     * @exports Argument
     * @classdesc Represents an Argument.
     * @implements IArgument
     * @constructor
     * @param {IArgument=} [properties] Properties to set
     */
    function Argument(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Argument k.
     * @member {string} k
     * @memberof Argument
     * @instance
     */
    Argument.prototype.k = "";

    /**
     * Argument t.
     * @member {string} t
     * @memberof Argument
     * @instance
     */
    Argument.prototype.t = "";

    /**
     * Argument m.
     * @member {string} m
     * @memberof Argument
     * @instance
     */
    Argument.prototype.m = "";

    /**
     * Argument p.
     * @member {number} p
     * @memberof Argument
     * @instance
     */
    Argument.prototype.p = 0;

    /**
     * Creates a new Argument instance using the specified properties.
     * @function create
     * @memberof Argument
     * @static
     * @param {IArgument=} [properties] Properties to set
     * @returns {Argument} Argument instance
     */
    Argument.create = function create(properties) {
        return new Argument(properties);
    };

    /**
     * Encodes the specified Argument message. Does not implicitly {@link Argument.verify|verify} messages.
     * @function encode
     * @memberof Argument
     * @static
     * @param {IArgument} message Argument message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Argument.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.k != null && Object.hasOwnProperty.call(message, "k"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.k);
        if (message.t != null && Object.hasOwnProperty.call(message, "t"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.t);
        if (message.m != null && Object.hasOwnProperty.call(message, "m"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.m);
        if (message.p != null && Object.hasOwnProperty.call(message, "p"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.p);
        return writer;
    };

    /**
     * Encodes the specified Argument message, length delimited. Does not implicitly {@link Argument.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Argument
     * @static
     * @param {IArgument} message Argument message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Argument.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Argument message from the specified reader or buffer.
     * @function decode
     * @memberof Argument
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Argument} Argument
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Argument.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Argument();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.k = reader.string();
                    break;
                }
            case 2: {
                    message.t = reader.string();
                    break;
                }
            case 3: {
                    message.m = reader.string();
                    break;
                }
            case 5: {
                    message.p = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Argument message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Argument
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Argument} Argument
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Argument.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Argument message.
     * @function verify
     * @memberof Argument
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Argument.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.k != null && message.hasOwnProperty("k"))
            if (!$util.isString(message.k))
                return "k: string expected";
        if (message.t != null && message.hasOwnProperty("t"))
            if (!$util.isString(message.t))
                return "t: string expected";
        if (message.m != null && message.hasOwnProperty("m"))
            if (!$util.isString(message.m))
                return "m: string expected";
        if (message.p != null && message.hasOwnProperty("p"))
            if (!$util.isInteger(message.p))
                return "p: integer expected";
        return null;
    };

    /**
     * Creates an Argument message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Argument
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Argument} Argument
     */
    Argument.fromObject = function fromObject(object) {
        if (object instanceof $root.Argument)
            return object;
        let message = new $root.Argument();
        if (object.k != null)
            message.k = String(object.k);
        if (object.t != null)
            message.t = String(object.t);
        if (object.m != null)
            message.m = String(object.m);
        if (object.p != null)
            message.p = object.p | 0;
        return message;
    };

    /**
     * Creates a plain object from an Argument message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Argument
     * @static
     * @param {Argument} message Argument
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Argument.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.k = "";
            object.t = "";
            object.m = "";
            object.p = 0;
        }
        if (message.k != null && message.hasOwnProperty("k"))
            object.k = message.k;
        if (message.t != null && message.hasOwnProperty("t"))
            object.t = message.t;
        if (message.m != null && message.hasOwnProperty("m"))
            object.m = message.m;
        if (message.p != null && message.hasOwnProperty("p"))
            object.p = message.p;
        return object;
    };

    /**
     * Converts this Argument to JSON.
     * @function toJSON
     * @memberof Argument
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Argument.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Argument
     * @function getTypeUrl
     * @memberof Argument
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Argument.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Argument";
    };

    return Argument;
})();

export const Command = $root.Command = (() => {

    /**
     * Properties of a Command.
     * @exports ICommand
     * @interface ICommand
     * @property {string|null} [name] Command name
     * @property {Array.<IArgument>|null} [args] Command args
     * @property {number|Long|null} [time] Command time
     */

    /**
     * Constructs a new Command.
     * @exports Command
     * @classdesc Represents a Command.
     * @implements ICommand
     * @constructor
     * @param {ICommand=} [properties] Properties to set
     */
    function Command(properties) {
        this.args = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Command name.
     * @member {string} name
     * @memberof Command
     * @instance
     */
    Command.prototype.name = "";

    /**
     * Command args.
     * @member {Array.<IArgument>} args
     * @memberof Command
     * @instance
     */
    Command.prototype.args = $util.emptyArray;

    /**
     * Command time.
     * @member {number|Long} time
     * @memberof Command
     * @instance
     */
    Command.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new Command instance using the specified properties.
     * @function create
     * @memberof Command
     * @static
     * @param {ICommand=} [properties] Properties to set
     * @returns {Command} Command instance
     */
    Command.create = function create(properties) {
        return new Command(properties);
    };

    /**
     * Encodes the specified Command message. Does not implicitly {@link Command.verify|verify} messages.
     * @function encode
     * @memberof Command
     * @static
     * @param {ICommand} message Command message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Command.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.args != null && message.args.length)
            for (let i = 0; i < message.args.length; ++i)
                $root.Argument.encode(message.args[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.time);
        return writer;
    };

    /**
     * Encodes the specified Command message, length delimited. Does not implicitly {@link Command.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Command
     * @static
     * @param {ICommand} message Command message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Command.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Command message from the specified reader or buffer.
     * @function decode
     * @memberof Command
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Command} Command
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Command.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Command();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.args && message.args.length))
                        message.args = [];
                    message.args.push($root.Argument.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.time = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Command message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Command
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Command} Command
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Command.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Command message.
     * @function verify
     * @memberof Command
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Command.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.args != null && message.hasOwnProperty("args")) {
            if (!Array.isArray(message.args))
                return "args: array expected";
            for (let i = 0; i < message.args.length; ++i) {
                let error = $root.Argument.verify(message.args[i]);
                if (error)
                    return "args." + error;
            }
        }
        if (message.time != null && message.hasOwnProperty("time"))
            if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                return "time: integer|Long expected";
        return null;
    };

    /**
     * Creates a Command message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Command
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Command} Command
     */
    Command.fromObject = function fromObject(object) {
        if (object instanceof $root.Command)
            return object;
        let message = new $root.Command();
        if (object.name != null)
            message.name = String(object.name);
        if (object.args) {
            if (!Array.isArray(object.args))
                throw TypeError(".Command.args: array expected");
            message.args = [];
            for (let i = 0; i < object.args.length; ++i) {
                if (typeof object.args[i] !== "object")
                    throw TypeError(".Command.args: object expected");
                message.args[i] = $root.Argument.fromObject(object.args[i]);
            }
        }
        if (object.time != null)
            if ($util.Long)
                (message.time = $util.Long.fromValue(object.time)).unsigned = false;
            else if (typeof object.time === "string")
                message.time = parseInt(object.time, 10);
            else if (typeof object.time === "number")
                message.time = object.time;
            else if (typeof object.time === "object")
                message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a Command message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Command
     * @static
     * @param {Command} message Command
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Command.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.args = [];
        if (options.defaults) {
            object.name = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.time = options.longs === String ? "0" : 0;
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.args && message.args.length) {
            object.args = [];
            for (let j = 0; j < message.args.length; ++j)
                object.args[j] = $root.Argument.toObject(message.args[j], options);
        }
        if (message.time != null && message.hasOwnProperty("time"))
            if (typeof message.time === "number")
                object.time = options.longs === String ? String(message.time) : message.time;
            else
                object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
        return object;
    };

    /**
     * Converts this Command to JSON.
     * @function toJSON
     * @memberof Command
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Command.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Command
     * @function getTypeUrl
     * @memberof Command
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Command.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Command";
    };

    return Command;
})();

export const PageNotFound = $root.PageNotFound = (() => {

    /**
     * Properties of a PageNotFound.
     * @exports IPageNotFound
     * @interface IPageNotFound
     * @property {string|null} [pageName] PageNotFound pageName
     */

    /**
     * Constructs a new PageNotFound.
     * @exports PageNotFound
     * @classdesc Represents a PageNotFound.
     * @implements IPageNotFound
     * @constructor
     * @param {IPageNotFound=} [properties] Properties to set
     */
    function PageNotFound(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageNotFound pageName.
     * @member {string} pageName
     * @memberof PageNotFound
     * @instance
     */
    PageNotFound.prototype.pageName = "";

    /**
     * Creates a new PageNotFound instance using the specified properties.
     * @function create
     * @memberof PageNotFound
     * @static
     * @param {IPageNotFound=} [properties] Properties to set
     * @returns {PageNotFound} PageNotFound instance
     */
    PageNotFound.create = function create(properties) {
        return new PageNotFound(properties);
    };

    /**
     * Encodes the specified PageNotFound message. Does not implicitly {@link PageNotFound.verify|verify} messages.
     * @function encode
     * @memberof PageNotFound
     * @static
     * @param {IPageNotFound} message PageNotFound message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageNotFound.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pageName != null && Object.hasOwnProperty.call(message, "pageName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.pageName);
        return writer;
    };

    /**
     * Encodes the specified PageNotFound message, length delimited. Does not implicitly {@link PageNotFound.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PageNotFound
     * @static
     * @param {IPageNotFound} message PageNotFound message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageNotFound.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageNotFound message from the specified reader or buffer.
     * @function decode
     * @memberof PageNotFound
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PageNotFound} PageNotFound
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageNotFound.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageNotFound();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pageName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PageNotFound message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PageNotFound
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PageNotFound} PageNotFound
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageNotFound.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageNotFound message.
     * @function verify
     * @memberof PageNotFound
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageNotFound.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            if (!$util.isString(message.pageName))
                return "pageName: string expected";
        return null;
    };

    /**
     * Creates a PageNotFound message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PageNotFound
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PageNotFound} PageNotFound
     */
    PageNotFound.fromObject = function fromObject(object) {
        if (object instanceof $root.PageNotFound)
            return object;
        let message = new $root.PageNotFound();
        if (object.pageName != null)
            message.pageName = String(object.pageName);
        return message;
    };

    /**
     * Creates a plain object from a PageNotFound message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PageNotFound
     * @static
     * @param {PageNotFound} message PageNotFound
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageNotFound.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.pageName = "";
        if (message.pageName != null && message.hasOwnProperty("pageName"))
            object.pageName = message.pageName;
        return object;
    };

    /**
     * Converts this PageNotFound to JSON.
     * @function toJSON
     * @memberof PageNotFound
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageNotFound.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PageNotFound
     * @function getTypeUrl
     * @memberof PageNotFound
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PageNotFound.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageNotFound";
    };

    return PageNotFound;
})();

export const PagesChanged = $root.PagesChanged = (() => {

    /**
     * Properties of a PagesChanged.
     * @exports IPagesChanged
     * @interface IPagesChanged
     * @property {Array.<IAppPage>|null} [appPages] PagesChanged appPages
     */

    /**
     * Constructs a new PagesChanged.
     * @exports PagesChanged
     * @classdesc Represents a PagesChanged.
     * @implements IPagesChanged
     * @constructor
     * @param {IPagesChanged=} [properties] Properties to set
     */
    function PagesChanged(properties) {
        this.appPages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PagesChanged appPages.
     * @member {Array.<IAppPage>} appPages
     * @memberof PagesChanged
     * @instance
     */
    PagesChanged.prototype.appPages = $util.emptyArray;

    /**
     * Creates a new PagesChanged instance using the specified properties.
     * @function create
     * @memberof PagesChanged
     * @static
     * @param {IPagesChanged=} [properties] Properties to set
     * @returns {PagesChanged} PagesChanged instance
     */
    PagesChanged.create = function create(properties) {
        return new PagesChanged(properties);
    };

    /**
     * Encodes the specified PagesChanged message. Does not implicitly {@link PagesChanged.verify|verify} messages.
     * @function encode
     * @memberof PagesChanged
     * @static
     * @param {IPagesChanged} message PagesChanged message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PagesChanged.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.appPages != null && message.appPages.length)
            for (let i = 0; i < message.appPages.length; ++i)
                $root.AppPage.encode(message.appPages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified PagesChanged message, length delimited. Does not implicitly {@link PagesChanged.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PagesChanged
     * @static
     * @param {IPagesChanged} message PagesChanged message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PagesChanged.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PagesChanged message from the specified reader or buffer.
     * @function decode
     * @memberof PagesChanged
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PagesChanged} PagesChanged
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PagesChanged.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PagesChanged();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.appPages && message.appPages.length))
                        message.appPages = [];
                    message.appPages.push($root.AppPage.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PagesChanged message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PagesChanged
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PagesChanged} PagesChanged
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PagesChanged.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PagesChanged message.
     * @function verify
     * @memberof PagesChanged
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PagesChanged.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.appPages != null && message.hasOwnProperty("appPages")) {
            if (!Array.isArray(message.appPages))
                return "appPages: array expected";
            for (let i = 0; i < message.appPages.length; ++i) {
                let error = $root.AppPage.verify(message.appPages[i]);
                if (error)
                    return "appPages." + error;
            }
        }
        return null;
    };

    /**
     * Creates a PagesChanged message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PagesChanged
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PagesChanged} PagesChanged
     */
    PagesChanged.fromObject = function fromObject(object) {
        if (object instanceof $root.PagesChanged)
            return object;
        let message = new $root.PagesChanged();
        if (object.appPages) {
            if (!Array.isArray(object.appPages))
                throw TypeError(".PagesChanged.appPages: array expected");
            message.appPages = [];
            for (let i = 0; i < object.appPages.length; ++i) {
                if (typeof object.appPages[i] !== "object")
                    throw TypeError(".PagesChanged.appPages: object expected");
                message.appPages[i] = $root.AppPage.fromObject(object.appPages[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a PagesChanged message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PagesChanged
     * @static
     * @param {PagesChanged} message PagesChanged
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PagesChanged.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.appPages = [];
        if (message.appPages && message.appPages.length) {
            object.appPages = [];
            for (let j = 0; j < message.appPages.length; ++j)
                object.appPages[j] = $root.AppPage.toObject(message.appPages[j], options);
        }
        return object;
    };

    /**
     * Converts this PagesChanged to JSON.
     * @function toJSON
     * @memberof PagesChanged
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PagesChanged.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PagesChanged
     * @function getTypeUrl
     * @memberof PagesChanged
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PagesChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PagesChanged";
    };

    return PagesChanged;
})();

export const ParentMessage = $root.ParentMessage = (() => {

    /**
     * Properties of a ParentMessage.
     * @exports IParentMessage
     * @interface IParentMessage
     * @property {string|null} [message] ParentMessage message
     */

    /**
     * Constructs a new ParentMessage.
     * @exports ParentMessage
     * @classdesc Represents a ParentMessage.
     * @implements IParentMessage
     * @constructor
     * @param {IParentMessage=} [properties] Properties to set
     */
    function ParentMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ParentMessage message.
     * @member {string} message
     * @memberof ParentMessage
     * @instance
     */
    ParentMessage.prototype.message = "";

    /**
     * Creates a new ParentMessage instance using the specified properties.
     * @function create
     * @memberof ParentMessage
     * @static
     * @param {IParentMessage=} [properties] Properties to set
     * @returns {ParentMessage} ParentMessage instance
     */
    ParentMessage.create = function create(properties) {
        return new ParentMessage(properties);
    };

    /**
     * Encodes the specified ParentMessage message. Does not implicitly {@link ParentMessage.verify|verify} messages.
     * @function encode
     * @memberof ParentMessage
     * @static
     * @param {IParentMessage} message ParentMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ParentMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified ParentMessage message, length delimited. Does not implicitly {@link ParentMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ParentMessage
     * @static
     * @param {IParentMessage} message ParentMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ParentMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ParentMessage message from the specified reader or buffer.
     * @function decode
     * @memberof ParentMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ParentMessage} ParentMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ParentMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ParentMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ParentMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ParentMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ParentMessage} ParentMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ParentMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ParentMessage message.
     * @function verify
     * @memberof ParentMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ParentMessage.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        return null;
    };

    /**
     * Creates a ParentMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ParentMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ParentMessage} ParentMessage
     */
    ParentMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.ParentMessage)
            return object;
        let message = new $root.ParentMessage();
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from a ParentMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ParentMessage
     * @static
     * @param {ParentMessage} message ParentMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ParentMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.message = "";
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this ParentMessage to JSON.
     * @function toJSON
     * @memberof ParentMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ParentMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ParentMessage
     * @function getTypeUrl
     * @memberof ParentMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ParentMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ParentMessage";
    };

    return ParentMessage;
})();

export const SessionEvent = $root.SessionEvent = (() => {

    /**
     * Properties of a SessionEvent.
     * @exports ISessionEvent
     * @interface ISessionEvent
     * @property {boolean|null} [scriptChangedOnDisk] SessionEvent scriptChangedOnDisk
     * @property {boolean|null} [scriptWasManuallyStopped] SessionEvent scriptWasManuallyStopped
     * @property {IException|null} [scriptCompilationException] SessionEvent scriptCompilationException
     */

    /**
     * Constructs a new SessionEvent.
     * @exports SessionEvent
     * @classdesc Represents a SessionEvent.
     * @implements ISessionEvent
     * @constructor
     * @param {ISessionEvent=} [properties] Properties to set
     */
    function SessionEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SessionEvent scriptChangedOnDisk.
     * @member {boolean|null|undefined} scriptChangedOnDisk
     * @memberof SessionEvent
     * @instance
     */
    SessionEvent.prototype.scriptChangedOnDisk = null;

    /**
     * SessionEvent scriptWasManuallyStopped.
     * @member {boolean|null|undefined} scriptWasManuallyStopped
     * @memberof SessionEvent
     * @instance
     */
    SessionEvent.prototype.scriptWasManuallyStopped = null;

    /**
     * SessionEvent scriptCompilationException.
     * @member {IException|null|undefined} scriptCompilationException
     * @memberof SessionEvent
     * @instance
     */
    SessionEvent.prototype.scriptCompilationException = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * SessionEvent type.
     * @member {"scriptChangedOnDisk"|"scriptWasManuallyStopped"|"scriptCompilationException"|undefined} type
     * @memberof SessionEvent
     * @instance
     */
    Object.defineProperty(SessionEvent.prototype, "type", {
        get: $util.oneOfGetter($oneOfFields = ["scriptChangedOnDisk", "scriptWasManuallyStopped", "scriptCompilationException"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new SessionEvent instance using the specified properties.
     * @function create
     * @memberof SessionEvent
     * @static
     * @param {ISessionEvent=} [properties] Properties to set
     * @returns {SessionEvent} SessionEvent instance
     */
    SessionEvent.create = function create(properties) {
        return new SessionEvent(properties);
    };

    /**
     * Encodes the specified SessionEvent message. Does not implicitly {@link SessionEvent.verify|verify} messages.
     * @function encode
     * @memberof SessionEvent
     * @static
     * @param {ISessionEvent} message SessionEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SessionEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.scriptChangedOnDisk != null && Object.hasOwnProperty.call(message, "scriptChangedOnDisk"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.scriptChangedOnDisk);
        if (message.scriptWasManuallyStopped != null && Object.hasOwnProperty.call(message, "scriptWasManuallyStopped"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.scriptWasManuallyStopped);
        if (message.scriptCompilationException != null && Object.hasOwnProperty.call(message, "scriptCompilationException"))
            $root.Exception.encode(message.scriptCompilationException, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SessionEvent message, length delimited. Does not implicitly {@link SessionEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SessionEvent
     * @static
     * @param {ISessionEvent} message SessionEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SessionEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SessionEvent message from the specified reader or buffer.
     * @function decode
     * @memberof SessionEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SessionEvent} SessionEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SessionEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SessionEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.scriptChangedOnDisk = reader.bool();
                    break;
                }
            case 2: {
                    message.scriptWasManuallyStopped = reader.bool();
                    break;
                }
            case 3: {
                    message.scriptCompilationException = $root.Exception.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SessionEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SessionEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SessionEvent} SessionEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SessionEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SessionEvent message.
     * @function verify
     * @memberof SessionEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SessionEvent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.scriptChangedOnDisk != null && message.hasOwnProperty("scriptChangedOnDisk")) {
            properties.type = 1;
            if (typeof message.scriptChangedOnDisk !== "boolean")
                return "scriptChangedOnDisk: boolean expected";
        }
        if (message.scriptWasManuallyStopped != null && message.hasOwnProperty("scriptWasManuallyStopped")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            if (typeof message.scriptWasManuallyStopped !== "boolean")
                return "scriptWasManuallyStopped: boolean expected";
        }
        if (message.scriptCompilationException != null && message.hasOwnProperty("scriptCompilationException")) {
            if (properties.type === 1)
                return "type: multiple values";
            properties.type = 1;
            {
                let error = $root.Exception.verify(message.scriptCompilationException);
                if (error)
                    return "scriptCompilationException." + error;
            }
        }
        return null;
    };

    /**
     * Creates a SessionEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SessionEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SessionEvent} SessionEvent
     */
    SessionEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.SessionEvent)
            return object;
        let message = new $root.SessionEvent();
        if (object.scriptChangedOnDisk != null)
            message.scriptChangedOnDisk = Boolean(object.scriptChangedOnDisk);
        if (object.scriptWasManuallyStopped != null)
            message.scriptWasManuallyStopped = Boolean(object.scriptWasManuallyStopped);
        if (object.scriptCompilationException != null) {
            if (typeof object.scriptCompilationException !== "object")
                throw TypeError(".SessionEvent.scriptCompilationException: object expected");
            message.scriptCompilationException = $root.Exception.fromObject(object.scriptCompilationException);
        }
        return message;
    };

    /**
     * Creates a plain object from a SessionEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SessionEvent
     * @static
     * @param {SessionEvent} message SessionEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SessionEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.scriptChangedOnDisk != null && message.hasOwnProperty("scriptChangedOnDisk")) {
            object.scriptChangedOnDisk = message.scriptChangedOnDisk;
            if (options.oneofs)
                object.type = "scriptChangedOnDisk";
        }
        if (message.scriptWasManuallyStopped != null && message.hasOwnProperty("scriptWasManuallyStopped")) {
            object.scriptWasManuallyStopped = message.scriptWasManuallyStopped;
            if (options.oneofs)
                object.type = "scriptWasManuallyStopped";
        }
        if (message.scriptCompilationException != null && message.hasOwnProperty("scriptCompilationException")) {
            object.scriptCompilationException = $root.Exception.toObject(message.scriptCompilationException, options);
            if (options.oneofs)
                object.type = "scriptCompilationException";
        }
        return object;
    };

    /**
     * Converts this SessionEvent to JSON.
     * @function toJSON
     * @memberof SessionEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SessionEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SessionEvent
     * @function getTypeUrl
     * @memberof SessionEvent
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SessionEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SessionEvent";
    };

    return SessionEvent;
})();

export const MetricsEvent = $root.MetricsEvent = (() => {

    /**
     * Properties of a MetricsEvent.
     * @exports IMetricsEvent
     * @interface IMetricsEvent
     * @property {string|null} [event] MetricsEvent event
     * @property {string|null} [anonymousId] MetricsEvent anonymousId
     * @property {string|null} [machineIdV3] MetricsEvent machineIdV3
     * @property {string|null} [reportHash] MetricsEvent reportHash
     * @property {boolean|null} [dev] MetricsEvent dev
     * @property {string|null} [source] MetricsEvent source
     * @property {string|null} [streamlitVersion] MetricsEvent streamlitVersion
     * @property {boolean|null} [isHello] MetricsEvent isHello
     * @property {string|null} [hostedAt] MetricsEvent hostedAt
     * @property {string|null} [owner] MetricsEvent owner
     * @property {string|null} [repo] MetricsEvent repo
     * @property {string|null} [branch] MetricsEvent branch
     * @property {string|null} [mainModule] MetricsEvent mainModule
     * @property {string|null} [creatorId] MetricsEvent creatorId
     * @property {string|null} [contextPageUrl] MetricsEvent contextPageUrl
     * @property {string|null} [contextPageTitle] MetricsEvent contextPageTitle
     * @property {string|null} [contextPagePath] MetricsEvent contextPagePath
     * @property {string|null} [contextPageReferrer] MetricsEvent contextPageReferrer
     * @property {string|null} [contextPageSearch] MetricsEvent contextPageSearch
     * @property {string|null} [contextLocale] MetricsEvent contextLocale
     * @property {string|null} [contextUserAgent] MetricsEvent contextUserAgent
     * @property {string|null} [label] MetricsEvent label
     * @property {Array.<ICommand>|null} [commands] MetricsEvent commands
     * @property {number|Long|null} [execTime] MetricsEvent execTime
     * @property {number|Long|null} [prepTime] MetricsEvent prepTime
     * @property {Array.<string>|null} [config] MetricsEvent config
     * @property {string|null} [uncaughtException] MetricsEvent uncaughtException
     * @property {Array.<string>|null} [attributions] MetricsEvent attributions
     * @property {string|null} [os] MetricsEvent os
     * @property {string|null} [timezone] MetricsEvent timezone
     * @property {boolean|null} [headless] MetricsEvent headless
     * @property {boolean|null} [isFragmentRun] MetricsEvent isFragmentRun
     * @property {string|null} [appId] MetricsEvent appId
     * @property {number|Long|null} [numPages] MetricsEvent numPages
     * @property {string|null} [sessionId] MetricsEvent sessionId
     * @property {string|null} [pythonVersion] MetricsEvent pythonVersion
     * @property {string|null} [pageScriptHash] MetricsEvent pageScriptHash
     * @property {string|null} [activeTheme] MetricsEvent activeTheme
     * @property {number|Long|null} [totalLoadTime] MetricsEvent totalLoadTime
     * @property {IBrowserInfo|null} [browserInfo] MetricsEvent browserInfo
     */

    /**
     * Constructs a new MetricsEvent.
     * @exports MetricsEvent
     * @classdesc Represents a MetricsEvent.
     * @implements IMetricsEvent
     * @constructor
     * @param {IMetricsEvent=} [properties] Properties to set
     */
    function MetricsEvent(properties) {
        this.commands = [];
        this.config = [];
        this.attributions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MetricsEvent event.
     * @member {string} event
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.event = "";

    /**
     * MetricsEvent anonymousId.
     * @member {string} anonymousId
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.anonymousId = "";

    /**
     * MetricsEvent machineIdV3.
     * @member {string} machineIdV3
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.machineIdV3 = "";

    /**
     * MetricsEvent reportHash.
     * @member {string} reportHash
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.reportHash = "";

    /**
     * MetricsEvent dev.
     * @member {boolean} dev
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.dev = false;

    /**
     * MetricsEvent source.
     * @member {string} source
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.source = "";

    /**
     * MetricsEvent streamlitVersion.
     * @member {string} streamlitVersion
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.streamlitVersion = "";

    /**
     * MetricsEvent isHello.
     * @member {boolean} isHello
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.isHello = false;

    /**
     * MetricsEvent hostedAt.
     * @member {string} hostedAt
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.hostedAt = "";

    /**
     * MetricsEvent owner.
     * @member {string} owner
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.owner = "";

    /**
     * MetricsEvent repo.
     * @member {string} repo
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.repo = "";

    /**
     * MetricsEvent branch.
     * @member {string} branch
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.branch = "";

    /**
     * MetricsEvent mainModule.
     * @member {string} mainModule
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.mainModule = "";

    /**
     * MetricsEvent creatorId.
     * @member {string} creatorId
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.creatorId = "";

    /**
     * MetricsEvent contextPageUrl.
     * @member {string} contextPageUrl
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextPageUrl = "";

    /**
     * MetricsEvent contextPageTitle.
     * @member {string} contextPageTitle
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextPageTitle = "";

    /**
     * MetricsEvent contextPagePath.
     * @member {string} contextPagePath
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextPagePath = "";

    /**
     * MetricsEvent contextPageReferrer.
     * @member {string} contextPageReferrer
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextPageReferrer = "";

    /**
     * MetricsEvent contextPageSearch.
     * @member {string} contextPageSearch
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextPageSearch = "";

    /**
     * MetricsEvent contextLocale.
     * @member {string} contextLocale
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextLocale = "";

    /**
     * MetricsEvent contextUserAgent.
     * @member {string} contextUserAgent
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.contextUserAgent = "";

    /**
     * MetricsEvent label.
     * @member {string} label
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.label = "";

    /**
     * MetricsEvent commands.
     * @member {Array.<ICommand>} commands
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.commands = $util.emptyArray;

    /**
     * MetricsEvent execTime.
     * @member {number|Long} execTime
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.execTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * MetricsEvent prepTime.
     * @member {number|Long} prepTime
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.prepTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * MetricsEvent config.
     * @member {Array.<string>} config
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.config = $util.emptyArray;

    /**
     * MetricsEvent uncaughtException.
     * @member {string} uncaughtException
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.uncaughtException = "";

    /**
     * MetricsEvent attributions.
     * @member {Array.<string>} attributions
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.attributions = $util.emptyArray;

    /**
     * MetricsEvent os.
     * @member {string} os
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.os = "";

    /**
     * MetricsEvent timezone.
     * @member {string} timezone
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.timezone = "";

    /**
     * MetricsEvent headless.
     * @member {boolean} headless
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.headless = false;

    /**
     * MetricsEvent isFragmentRun.
     * @member {boolean} isFragmentRun
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.isFragmentRun = false;

    /**
     * MetricsEvent appId.
     * @member {string} appId
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.appId = "";

    /**
     * MetricsEvent numPages.
     * @member {number|Long} numPages
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.numPages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * MetricsEvent sessionId.
     * @member {string} sessionId
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.sessionId = "";

    /**
     * MetricsEvent pythonVersion.
     * @member {string} pythonVersion
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.pythonVersion = "";

    /**
     * MetricsEvent pageScriptHash.
     * @member {string} pageScriptHash
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.pageScriptHash = "";

    /**
     * MetricsEvent activeTheme.
     * @member {string} activeTheme
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.activeTheme = "";

    /**
     * MetricsEvent totalLoadTime.
     * @member {number|Long} totalLoadTime
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.totalLoadTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * MetricsEvent browserInfo.
     * @member {IBrowserInfo|null|undefined} browserInfo
     * @memberof MetricsEvent
     * @instance
     */
    MetricsEvent.prototype.browserInfo = null;

    /**
     * Creates a new MetricsEvent instance using the specified properties.
     * @function create
     * @memberof MetricsEvent
     * @static
     * @param {IMetricsEvent=} [properties] Properties to set
     * @returns {MetricsEvent} MetricsEvent instance
     */
    MetricsEvent.create = function create(properties) {
        return new MetricsEvent(properties);
    };

    /**
     * Encodes the specified MetricsEvent message. Does not implicitly {@link MetricsEvent.verify|verify} messages.
     * @function encode
     * @memberof MetricsEvent
     * @static
     * @param {IMetricsEvent} message MetricsEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MetricsEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.event);
        if (message.anonymousId != null && Object.hasOwnProperty.call(message, "anonymousId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.anonymousId);
        if (message.machineIdV3 != null && Object.hasOwnProperty.call(message, "machineIdV3"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.machineIdV3);
        if (message.reportHash != null && Object.hasOwnProperty.call(message, "reportHash"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reportHash);
        if (message.dev != null && Object.hasOwnProperty.call(message, "dev"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.dev);
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.source);
        if (message.streamlitVersion != null && Object.hasOwnProperty.call(message, "streamlitVersion"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.streamlitVersion);
        if (message.isHello != null && Object.hasOwnProperty.call(message, "isHello"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isHello);
        if (message.hostedAt != null && Object.hasOwnProperty.call(message, "hostedAt"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.hostedAt);
        if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.owner);
        if (message.repo != null && Object.hasOwnProperty.call(message, "repo"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.repo);
        if (message.branch != null && Object.hasOwnProperty.call(message, "branch"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.branch);
        if (message.mainModule != null && Object.hasOwnProperty.call(message, "mainModule"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.mainModule);
        if (message.creatorId != null && Object.hasOwnProperty.call(message, "creatorId"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.creatorId);
        if (message.contextPageUrl != null && Object.hasOwnProperty.call(message, "contextPageUrl"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.contextPageUrl);
        if (message.contextPageTitle != null && Object.hasOwnProperty.call(message, "contextPageTitle"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.contextPageTitle);
        if (message.contextPagePath != null && Object.hasOwnProperty.call(message, "contextPagePath"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.contextPagePath);
        if (message.contextPageReferrer != null && Object.hasOwnProperty.call(message, "contextPageReferrer"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.contextPageReferrer);
        if (message.contextPageSearch != null && Object.hasOwnProperty.call(message, "contextPageSearch"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.contextPageSearch);
        if (message.contextLocale != null && Object.hasOwnProperty.call(message, "contextLocale"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.contextLocale);
        if (message.contextUserAgent != null && Object.hasOwnProperty.call(message, "contextUserAgent"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.contextUserAgent);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.label);
        if (message.commands != null && message.commands.length)
            for (let i = 0; i < message.commands.length; ++i)
                $root.Command.encode(message.commands[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        if (message.execTime != null && Object.hasOwnProperty.call(message, "execTime"))
            writer.uint32(/* id 24, wireType 0 =*/192).int64(message.execTime);
        if (message.prepTime != null && Object.hasOwnProperty.call(message, "prepTime"))
            writer.uint32(/* id 25, wireType 0 =*/200).int64(message.prepTime);
        if (message.config != null && message.config.length)
            for (let i = 0; i < message.config.length; ++i)
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.config[i]);
        if (message.uncaughtException != null && Object.hasOwnProperty.call(message, "uncaughtException"))
            writer.uint32(/* id 27, wireType 2 =*/218).string(message.uncaughtException);
        if (message.attributions != null && message.attributions.length)
            for (let i = 0; i < message.attributions.length; ++i)
                writer.uint32(/* id 28, wireType 2 =*/226).string(message.attributions[i]);
        if (message.os != null && Object.hasOwnProperty.call(message, "os"))
            writer.uint32(/* id 29, wireType 2 =*/234).string(message.os);
        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
            writer.uint32(/* id 30, wireType 2 =*/242).string(message.timezone);
        if (message.headless != null && Object.hasOwnProperty.call(message, "headless"))
            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.headless);
        if (message.isFragmentRun != null && Object.hasOwnProperty.call(message, "isFragmentRun"))
            writer.uint32(/* id 32, wireType 0 =*/256).bool(message.isFragmentRun);
        if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
            writer.uint32(/* id 33, wireType 2 =*/266).string(message.appId);
        if (message.numPages != null && Object.hasOwnProperty.call(message, "numPages"))
            writer.uint32(/* id 34, wireType 0 =*/272).int64(message.numPages);
        if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
            writer.uint32(/* id 35, wireType 2 =*/282).string(message.sessionId);
        if (message.pythonVersion != null && Object.hasOwnProperty.call(message, "pythonVersion"))
            writer.uint32(/* id 36, wireType 2 =*/290).string(message.pythonVersion);
        if (message.pageScriptHash != null && Object.hasOwnProperty.call(message, "pageScriptHash"))
            writer.uint32(/* id 37, wireType 2 =*/298).string(message.pageScriptHash);
        if (message.activeTheme != null && Object.hasOwnProperty.call(message, "activeTheme"))
            writer.uint32(/* id 38, wireType 2 =*/306).string(message.activeTheme);
        if (message.totalLoadTime != null && Object.hasOwnProperty.call(message, "totalLoadTime"))
            writer.uint32(/* id 39, wireType 0 =*/312).int64(message.totalLoadTime);
        if (message.browserInfo != null && Object.hasOwnProperty.call(message, "browserInfo"))
            $root.BrowserInfo.encode(message.browserInfo, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MetricsEvent message, length delimited. Does not implicitly {@link MetricsEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MetricsEvent
     * @static
     * @param {IMetricsEvent} message MetricsEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MetricsEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MetricsEvent message from the specified reader or buffer.
     * @function decode
     * @memberof MetricsEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MetricsEvent} MetricsEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MetricsEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MetricsEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.event = reader.string();
                    break;
                }
            case 2: {
                    message.anonymousId = reader.string();
                    break;
                }
            case 3: {
                    message.machineIdV3 = reader.string();
                    break;
                }
            case 4: {
                    message.reportHash = reader.string();
                    break;
                }
            case 5: {
                    message.dev = reader.bool();
                    break;
                }
            case 6: {
                    message.source = reader.string();
                    break;
                }
            case 7: {
                    message.streamlitVersion = reader.string();
                    break;
                }
            case 8: {
                    message.isHello = reader.bool();
                    break;
                }
            case 9: {
                    message.hostedAt = reader.string();
                    break;
                }
            case 10: {
                    message.owner = reader.string();
                    break;
                }
            case 11: {
                    message.repo = reader.string();
                    break;
                }
            case 12: {
                    message.branch = reader.string();
                    break;
                }
            case 13: {
                    message.mainModule = reader.string();
                    break;
                }
            case 14: {
                    message.creatorId = reader.string();
                    break;
                }
            case 15: {
                    message.contextPageUrl = reader.string();
                    break;
                }
            case 16: {
                    message.contextPageTitle = reader.string();
                    break;
                }
            case 17: {
                    message.contextPagePath = reader.string();
                    break;
                }
            case 18: {
                    message.contextPageReferrer = reader.string();
                    break;
                }
            case 19: {
                    message.contextPageSearch = reader.string();
                    break;
                }
            case 20: {
                    message.contextLocale = reader.string();
                    break;
                }
            case 21: {
                    message.contextUserAgent = reader.string();
                    break;
                }
            case 22: {
                    message.label = reader.string();
                    break;
                }
            case 23: {
                    if (!(message.commands && message.commands.length))
                        message.commands = [];
                    message.commands.push($root.Command.decode(reader, reader.uint32()));
                    break;
                }
            case 24: {
                    message.execTime = reader.int64();
                    break;
                }
            case 25: {
                    message.prepTime = reader.int64();
                    break;
                }
            case 26: {
                    if (!(message.config && message.config.length))
                        message.config = [];
                    message.config.push(reader.string());
                    break;
                }
            case 27: {
                    message.uncaughtException = reader.string();
                    break;
                }
            case 28: {
                    if (!(message.attributions && message.attributions.length))
                        message.attributions = [];
                    message.attributions.push(reader.string());
                    break;
                }
            case 29: {
                    message.os = reader.string();
                    break;
                }
            case 30: {
                    message.timezone = reader.string();
                    break;
                }
            case 31: {
                    message.headless = reader.bool();
                    break;
                }
            case 32: {
                    message.isFragmentRun = reader.bool();
                    break;
                }
            case 33: {
                    message.appId = reader.string();
                    break;
                }
            case 34: {
                    message.numPages = reader.int64();
                    break;
                }
            case 35: {
                    message.sessionId = reader.string();
                    break;
                }
            case 36: {
                    message.pythonVersion = reader.string();
                    break;
                }
            case 37: {
                    message.pageScriptHash = reader.string();
                    break;
                }
            case 38: {
                    message.activeTheme = reader.string();
                    break;
                }
            case 39: {
                    message.totalLoadTime = reader.int64();
                    break;
                }
            case 40: {
                    message.browserInfo = $root.BrowserInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MetricsEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MetricsEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MetricsEvent} MetricsEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MetricsEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MetricsEvent message.
     * @function verify
     * @memberof MetricsEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MetricsEvent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.event != null && message.hasOwnProperty("event"))
            if (!$util.isString(message.event))
                return "event: string expected";
        if (message.anonymousId != null && message.hasOwnProperty("anonymousId"))
            if (!$util.isString(message.anonymousId))
                return "anonymousId: string expected";
        if (message.machineIdV3 != null && message.hasOwnProperty("machineIdV3"))
            if (!$util.isString(message.machineIdV3))
                return "machineIdV3: string expected";
        if (message.reportHash != null && message.hasOwnProperty("reportHash"))
            if (!$util.isString(message.reportHash))
                return "reportHash: string expected";
        if (message.dev != null && message.hasOwnProperty("dev"))
            if (typeof message.dev !== "boolean")
                return "dev: boolean expected";
        if (message.source != null && message.hasOwnProperty("source"))
            if (!$util.isString(message.source))
                return "source: string expected";
        if (message.streamlitVersion != null && message.hasOwnProperty("streamlitVersion"))
            if (!$util.isString(message.streamlitVersion))
                return "streamlitVersion: string expected";
        if (message.isHello != null && message.hasOwnProperty("isHello"))
            if (typeof message.isHello !== "boolean")
                return "isHello: boolean expected";
        if (message.hostedAt != null && message.hasOwnProperty("hostedAt"))
            if (!$util.isString(message.hostedAt))
                return "hostedAt: string expected";
        if (message.owner != null && message.hasOwnProperty("owner"))
            if (!$util.isString(message.owner))
                return "owner: string expected";
        if (message.repo != null && message.hasOwnProperty("repo"))
            if (!$util.isString(message.repo))
                return "repo: string expected";
        if (message.branch != null && message.hasOwnProperty("branch"))
            if (!$util.isString(message.branch))
                return "branch: string expected";
        if (message.mainModule != null && message.hasOwnProperty("mainModule"))
            if (!$util.isString(message.mainModule))
                return "mainModule: string expected";
        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
            if (!$util.isString(message.creatorId))
                return "creatorId: string expected";
        if (message.contextPageUrl != null && message.hasOwnProperty("contextPageUrl"))
            if (!$util.isString(message.contextPageUrl))
                return "contextPageUrl: string expected";
        if (message.contextPageTitle != null && message.hasOwnProperty("contextPageTitle"))
            if (!$util.isString(message.contextPageTitle))
                return "contextPageTitle: string expected";
        if (message.contextPagePath != null && message.hasOwnProperty("contextPagePath"))
            if (!$util.isString(message.contextPagePath))
                return "contextPagePath: string expected";
        if (message.contextPageReferrer != null && message.hasOwnProperty("contextPageReferrer"))
            if (!$util.isString(message.contextPageReferrer))
                return "contextPageReferrer: string expected";
        if (message.contextPageSearch != null && message.hasOwnProperty("contextPageSearch"))
            if (!$util.isString(message.contextPageSearch))
                return "contextPageSearch: string expected";
        if (message.contextLocale != null && message.hasOwnProperty("contextLocale"))
            if (!$util.isString(message.contextLocale))
                return "contextLocale: string expected";
        if (message.contextUserAgent != null && message.hasOwnProperty("contextUserAgent"))
            if (!$util.isString(message.contextUserAgent))
                return "contextUserAgent: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.commands != null && message.hasOwnProperty("commands")) {
            if (!Array.isArray(message.commands))
                return "commands: array expected";
            for (let i = 0; i < message.commands.length; ++i) {
                let error = $root.Command.verify(message.commands[i]);
                if (error)
                    return "commands." + error;
            }
        }
        if (message.execTime != null && message.hasOwnProperty("execTime"))
            if (!$util.isInteger(message.execTime) && !(message.execTime && $util.isInteger(message.execTime.low) && $util.isInteger(message.execTime.high)))
                return "execTime: integer|Long expected";
        if (message.prepTime != null && message.hasOwnProperty("prepTime"))
            if (!$util.isInteger(message.prepTime) && !(message.prepTime && $util.isInteger(message.prepTime.low) && $util.isInteger(message.prepTime.high)))
                return "prepTime: integer|Long expected";
        if (message.config != null && message.hasOwnProperty("config")) {
            if (!Array.isArray(message.config))
                return "config: array expected";
            for (let i = 0; i < message.config.length; ++i)
                if (!$util.isString(message.config[i]))
                    return "config: string[] expected";
        }
        if (message.uncaughtException != null && message.hasOwnProperty("uncaughtException"))
            if (!$util.isString(message.uncaughtException))
                return "uncaughtException: string expected";
        if (message.attributions != null && message.hasOwnProperty("attributions")) {
            if (!Array.isArray(message.attributions))
                return "attributions: array expected";
            for (let i = 0; i < message.attributions.length; ++i)
                if (!$util.isString(message.attributions[i]))
                    return "attributions: string[] expected";
        }
        if (message.os != null && message.hasOwnProperty("os"))
            if (!$util.isString(message.os))
                return "os: string expected";
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            if (!$util.isString(message.timezone))
                return "timezone: string expected";
        if (message.headless != null && message.hasOwnProperty("headless"))
            if (typeof message.headless !== "boolean")
                return "headless: boolean expected";
        if (message.isFragmentRun != null && message.hasOwnProperty("isFragmentRun"))
            if (typeof message.isFragmentRun !== "boolean")
                return "isFragmentRun: boolean expected";
        if (message.appId != null && message.hasOwnProperty("appId"))
            if (!$util.isString(message.appId))
                return "appId: string expected";
        if (message.numPages != null && message.hasOwnProperty("numPages"))
            if (!$util.isInteger(message.numPages) && !(message.numPages && $util.isInteger(message.numPages.low) && $util.isInteger(message.numPages.high)))
                return "numPages: integer|Long expected";
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            if (!$util.isString(message.sessionId))
                return "sessionId: string expected";
        if (message.pythonVersion != null && message.hasOwnProperty("pythonVersion"))
            if (!$util.isString(message.pythonVersion))
                return "pythonVersion: string expected";
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            if (!$util.isString(message.pageScriptHash))
                return "pageScriptHash: string expected";
        if (message.activeTheme != null && message.hasOwnProperty("activeTheme"))
            if (!$util.isString(message.activeTheme))
                return "activeTheme: string expected";
        if (message.totalLoadTime != null && message.hasOwnProperty("totalLoadTime"))
            if (!$util.isInteger(message.totalLoadTime) && !(message.totalLoadTime && $util.isInteger(message.totalLoadTime.low) && $util.isInteger(message.totalLoadTime.high)))
                return "totalLoadTime: integer|Long expected";
        if (message.browserInfo != null && message.hasOwnProperty("browserInfo")) {
            let error = $root.BrowserInfo.verify(message.browserInfo);
            if (error)
                return "browserInfo." + error;
        }
        return null;
    };

    /**
     * Creates a MetricsEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MetricsEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MetricsEvent} MetricsEvent
     */
    MetricsEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.MetricsEvent)
            return object;
        let message = new $root.MetricsEvent();
        if (object.event != null)
            message.event = String(object.event);
        if (object.anonymousId != null)
            message.anonymousId = String(object.anonymousId);
        if (object.machineIdV3 != null)
            message.machineIdV3 = String(object.machineIdV3);
        if (object.reportHash != null)
            message.reportHash = String(object.reportHash);
        if (object.dev != null)
            message.dev = Boolean(object.dev);
        if (object.source != null)
            message.source = String(object.source);
        if (object.streamlitVersion != null)
            message.streamlitVersion = String(object.streamlitVersion);
        if (object.isHello != null)
            message.isHello = Boolean(object.isHello);
        if (object.hostedAt != null)
            message.hostedAt = String(object.hostedAt);
        if (object.owner != null)
            message.owner = String(object.owner);
        if (object.repo != null)
            message.repo = String(object.repo);
        if (object.branch != null)
            message.branch = String(object.branch);
        if (object.mainModule != null)
            message.mainModule = String(object.mainModule);
        if (object.creatorId != null)
            message.creatorId = String(object.creatorId);
        if (object.contextPageUrl != null)
            message.contextPageUrl = String(object.contextPageUrl);
        if (object.contextPageTitle != null)
            message.contextPageTitle = String(object.contextPageTitle);
        if (object.contextPagePath != null)
            message.contextPagePath = String(object.contextPagePath);
        if (object.contextPageReferrer != null)
            message.contextPageReferrer = String(object.contextPageReferrer);
        if (object.contextPageSearch != null)
            message.contextPageSearch = String(object.contextPageSearch);
        if (object.contextLocale != null)
            message.contextLocale = String(object.contextLocale);
        if (object.contextUserAgent != null)
            message.contextUserAgent = String(object.contextUserAgent);
        if (object.label != null)
            message.label = String(object.label);
        if (object.commands) {
            if (!Array.isArray(object.commands))
                throw TypeError(".MetricsEvent.commands: array expected");
            message.commands = [];
            for (let i = 0; i < object.commands.length; ++i) {
                if (typeof object.commands[i] !== "object")
                    throw TypeError(".MetricsEvent.commands: object expected");
                message.commands[i] = $root.Command.fromObject(object.commands[i]);
            }
        }
        if (object.execTime != null)
            if ($util.Long)
                (message.execTime = $util.Long.fromValue(object.execTime)).unsigned = false;
            else if (typeof object.execTime === "string")
                message.execTime = parseInt(object.execTime, 10);
            else if (typeof object.execTime === "number")
                message.execTime = object.execTime;
            else if (typeof object.execTime === "object")
                message.execTime = new $util.LongBits(object.execTime.low >>> 0, object.execTime.high >>> 0).toNumber();
        if (object.prepTime != null)
            if ($util.Long)
                (message.prepTime = $util.Long.fromValue(object.prepTime)).unsigned = false;
            else if (typeof object.prepTime === "string")
                message.prepTime = parseInt(object.prepTime, 10);
            else if (typeof object.prepTime === "number")
                message.prepTime = object.prepTime;
            else if (typeof object.prepTime === "object")
                message.prepTime = new $util.LongBits(object.prepTime.low >>> 0, object.prepTime.high >>> 0).toNumber();
        if (object.config) {
            if (!Array.isArray(object.config))
                throw TypeError(".MetricsEvent.config: array expected");
            message.config = [];
            for (let i = 0; i < object.config.length; ++i)
                message.config[i] = String(object.config[i]);
        }
        if (object.uncaughtException != null)
            message.uncaughtException = String(object.uncaughtException);
        if (object.attributions) {
            if (!Array.isArray(object.attributions))
                throw TypeError(".MetricsEvent.attributions: array expected");
            message.attributions = [];
            for (let i = 0; i < object.attributions.length; ++i)
                message.attributions[i] = String(object.attributions[i]);
        }
        if (object.os != null)
            message.os = String(object.os);
        if (object.timezone != null)
            message.timezone = String(object.timezone);
        if (object.headless != null)
            message.headless = Boolean(object.headless);
        if (object.isFragmentRun != null)
            message.isFragmentRun = Boolean(object.isFragmentRun);
        if (object.appId != null)
            message.appId = String(object.appId);
        if (object.numPages != null)
            if ($util.Long)
                (message.numPages = $util.Long.fromValue(object.numPages)).unsigned = false;
            else if (typeof object.numPages === "string")
                message.numPages = parseInt(object.numPages, 10);
            else if (typeof object.numPages === "number")
                message.numPages = object.numPages;
            else if (typeof object.numPages === "object")
                message.numPages = new $util.LongBits(object.numPages.low >>> 0, object.numPages.high >>> 0).toNumber();
        if (object.sessionId != null)
            message.sessionId = String(object.sessionId);
        if (object.pythonVersion != null)
            message.pythonVersion = String(object.pythonVersion);
        if (object.pageScriptHash != null)
            message.pageScriptHash = String(object.pageScriptHash);
        if (object.activeTheme != null)
            message.activeTheme = String(object.activeTheme);
        if (object.totalLoadTime != null)
            if ($util.Long)
                (message.totalLoadTime = $util.Long.fromValue(object.totalLoadTime)).unsigned = false;
            else if (typeof object.totalLoadTime === "string")
                message.totalLoadTime = parseInt(object.totalLoadTime, 10);
            else if (typeof object.totalLoadTime === "number")
                message.totalLoadTime = object.totalLoadTime;
            else if (typeof object.totalLoadTime === "object")
                message.totalLoadTime = new $util.LongBits(object.totalLoadTime.low >>> 0, object.totalLoadTime.high >>> 0).toNumber();
        if (object.browserInfo != null) {
            if (typeof object.browserInfo !== "object")
                throw TypeError(".MetricsEvent.browserInfo: object expected");
            message.browserInfo = $root.BrowserInfo.fromObject(object.browserInfo);
        }
        return message;
    };

    /**
     * Creates a plain object from a MetricsEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MetricsEvent
     * @static
     * @param {MetricsEvent} message MetricsEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MetricsEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.commands = [];
            object.config = [];
            object.attributions = [];
        }
        if (options.defaults) {
            object.event = "";
            object.anonymousId = "";
            object.machineIdV3 = "";
            object.reportHash = "";
            object.dev = false;
            object.source = "";
            object.streamlitVersion = "";
            object.isHello = false;
            object.hostedAt = "";
            object.owner = "";
            object.repo = "";
            object.branch = "";
            object.mainModule = "";
            object.creatorId = "";
            object.contextPageUrl = "";
            object.contextPageTitle = "";
            object.contextPagePath = "";
            object.contextPageReferrer = "";
            object.contextPageSearch = "";
            object.contextLocale = "";
            object.contextUserAgent = "";
            object.label = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.execTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.execTime = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.prepTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.prepTime = options.longs === String ? "0" : 0;
            object.uncaughtException = "";
            object.os = "";
            object.timezone = "";
            object.headless = false;
            object.isFragmentRun = false;
            object.appId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.numPages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.numPages = options.longs === String ? "0" : 0;
            object.sessionId = "";
            object.pythonVersion = "";
            object.pageScriptHash = "";
            object.activeTheme = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.totalLoadTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalLoadTime = options.longs === String ? "0" : 0;
            object.browserInfo = null;
        }
        if (message.event != null && message.hasOwnProperty("event"))
            object.event = message.event;
        if (message.anonymousId != null && message.hasOwnProperty("anonymousId"))
            object.anonymousId = message.anonymousId;
        if (message.machineIdV3 != null && message.hasOwnProperty("machineIdV3"))
            object.machineIdV3 = message.machineIdV3;
        if (message.reportHash != null && message.hasOwnProperty("reportHash"))
            object.reportHash = message.reportHash;
        if (message.dev != null && message.hasOwnProperty("dev"))
            object.dev = message.dev;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = message.source;
        if (message.streamlitVersion != null && message.hasOwnProperty("streamlitVersion"))
            object.streamlitVersion = message.streamlitVersion;
        if (message.isHello != null && message.hasOwnProperty("isHello"))
            object.isHello = message.isHello;
        if (message.hostedAt != null && message.hasOwnProperty("hostedAt"))
            object.hostedAt = message.hostedAt;
        if (message.owner != null && message.hasOwnProperty("owner"))
            object.owner = message.owner;
        if (message.repo != null && message.hasOwnProperty("repo"))
            object.repo = message.repo;
        if (message.branch != null && message.hasOwnProperty("branch"))
            object.branch = message.branch;
        if (message.mainModule != null && message.hasOwnProperty("mainModule"))
            object.mainModule = message.mainModule;
        if (message.creatorId != null && message.hasOwnProperty("creatorId"))
            object.creatorId = message.creatorId;
        if (message.contextPageUrl != null && message.hasOwnProperty("contextPageUrl"))
            object.contextPageUrl = message.contextPageUrl;
        if (message.contextPageTitle != null && message.hasOwnProperty("contextPageTitle"))
            object.contextPageTitle = message.contextPageTitle;
        if (message.contextPagePath != null && message.hasOwnProperty("contextPagePath"))
            object.contextPagePath = message.contextPagePath;
        if (message.contextPageReferrer != null && message.hasOwnProperty("contextPageReferrer"))
            object.contextPageReferrer = message.contextPageReferrer;
        if (message.contextPageSearch != null && message.hasOwnProperty("contextPageSearch"))
            object.contextPageSearch = message.contextPageSearch;
        if (message.contextLocale != null && message.hasOwnProperty("contextLocale"))
            object.contextLocale = message.contextLocale;
        if (message.contextUserAgent != null && message.hasOwnProperty("contextUserAgent"))
            object.contextUserAgent = message.contextUserAgent;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.commands && message.commands.length) {
            object.commands = [];
            for (let j = 0; j < message.commands.length; ++j)
                object.commands[j] = $root.Command.toObject(message.commands[j], options);
        }
        if (message.execTime != null && message.hasOwnProperty("execTime"))
            if (typeof message.execTime === "number")
                object.execTime = options.longs === String ? String(message.execTime) : message.execTime;
            else
                object.execTime = options.longs === String ? $util.Long.prototype.toString.call(message.execTime) : options.longs === Number ? new $util.LongBits(message.execTime.low >>> 0, message.execTime.high >>> 0).toNumber() : message.execTime;
        if (message.prepTime != null && message.hasOwnProperty("prepTime"))
            if (typeof message.prepTime === "number")
                object.prepTime = options.longs === String ? String(message.prepTime) : message.prepTime;
            else
                object.prepTime = options.longs === String ? $util.Long.prototype.toString.call(message.prepTime) : options.longs === Number ? new $util.LongBits(message.prepTime.low >>> 0, message.prepTime.high >>> 0).toNumber() : message.prepTime;
        if (message.config && message.config.length) {
            object.config = [];
            for (let j = 0; j < message.config.length; ++j)
                object.config[j] = message.config[j];
        }
        if (message.uncaughtException != null && message.hasOwnProperty("uncaughtException"))
            object.uncaughtException = message.uncaughtException;
        if (message.attributions && message.attributions.length) {
            object.attributions = [];
            for (let j = 0; j < message.attributions.length; ++j)
                object.attributions[j] = message.attributions[j];
        }
        if (message.os != null && message.hasOwnProperty("os"))
            object.os = message.os;
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            object.timezone = message.timezone;
        if (message.headless != null && message.hasOwnProperty("headless"))
            object.headless = message.headless;
        if (message.isFragmentRun != null && message.hasOwnProperty("isFragmentRun"))
            object.isFragmentRun = message.isFragmentRun;
        if (message.appId != null && message.hasOwnProperty("appId"))
            object.appId = message.appId;
        if (message.numPages != null && message.hasOwnProperty("numPages"))
            if (typeof message.numPages === "number")
                object.numPages = options.longs === String ? String(message.numPages) : message.numPages;
            else
                object.numPages = options.longs === String ? $util.Long.prototype.toString.call(message.numPages) : options.longs === Number ? new $util.LongBits(message.numPages.low >>> 0, message.numPages.high >>> 0).toNumber() : message.numPages;
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.pythonVersion != null && message.hasOwnProperty("pythonVersion"))
            object.pythonVersion = message.pythonVersion;
        if (message.pageScriptHash != null && message.hasOwnProperty("pageScriptHash"))
            object.pageScriptHash = message.pageScriptHash;
        if (message.activeTheme != null && message.hasOwnProperty("activeTheme"))
            object.activeTheme = message.activeTheme;
        if (message.totalLoadTime != null && message.hasOwnProperty("totalLoadTime"))
            if (typeof message.totalLoadTime === "number")
                object.totalLoadTime = options.longs === String ? String(message.totalLoadTime) : message.totalLoadTime;
            else
                object.totalLoadTime = options.longs === String ? $util.Long.prototype.toString.call(message.totalLoadTime) : options.longs === Number ? new $util.LongBits(message.totalLoadTime.low >>> 0, message.totalLoadTime.high >>> 0).toNumber() : message.totalLoadTime;
        if (message.browserInfo != null && message.hasOwnProperty("browserInfo"))
            object.browserInfo = $root.BrowserInfo.toObject(message.browserInfo, options);
        return object;
    };

    /**
     * Converts this MetricsEvent to JSON.
     * @function toJSON
     * @memberof MetricsEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MetricsEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MetricsEvent
     * @function getTypeUrl
     * @memberof MetricsEvent
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MetricsEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MetricsEvent";
    };

    return MetricsEvent;
})();

export const BrowserInfo = $root.BrowserInfo = (() => {

    /**
     * Properties of a BrowserInfo.
     * @exports IBrowserInfo
     * @interface IBrowserInfo
     * @property {string|null} [browserName] BrowserInfo browserName
     * @property {string|null} [browserVersion] BrowserInfo browserVersion
     * @property {string|null} [deviceType] BrowserInfo deviceType
     * @property {string|null} [os] BrowserInfo os
     */

    /**
     * Constructs a new BrowserInfo.
     * @exports BrowserInfo
     * @classdesc Represents a BrowserInfo.
     * @implements IBrowserInfo
     * @constructor
     * @param {IBrowserInfo=} [properties] Properties to set
     */
    function BrowserInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BrowserInfo browserName.
     * @member {string} browserName
     * @memberof BrowserInfo
     * @instance
     */
    BrowserInfo.prototype.browserName = "";

    /**
     * BrowserInfo browserVersion.
     * @member {string} browserVersion
     * @memberof BrowserInfo
     * @instance
     */
    BrowserInfo.prototype.browserVersion = "";

    /**
     * BrowserInfo deviceType.
     * @member {string} deviceType
     * @memberof BrowserInfo
     * @instance
     */
    BrowserInfo.prototype.deviceType = "";

    /**
     * BrowserInfo os.
     * @member {string} os
     * @memberof BrowserInfo
     * @instance
     */
    BrowserInfo.prototype.os = "";

    /**
     * Creates a new BrowserInfo instance using the specified properties.
     * @function create
     * @memberof BrowserInfo
     * @static
     * @param {IBrowserInfo=} [properties] Properties to set
     * @returns {BrowserInfo} BrowserInfo instance
     */
    BrowserInfo.create = function create(properties) {
        return new BrowserInfo(properties);
    };

    /**
     * Encodes the specified BrowserInfo message. Does not implicitly {@link BrowserInfo.verify|verify} messages.
     * @function encode
     * @memberof BrowserInfo
     * @static
     * @param {IBrowserInfo} message BrowserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BrowserInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.browserName != null && Object.hasOwnProperty.call(message, "browserName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.browserName);
        if (message.browserVersion != null && Object.hasOwnProperty.call(message, "browserVersion"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.browserVersion);
        if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.deviceType);
        if (message.os != null && Object.hasOwnProperty.call(message, "os"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.os);
        return writer;
    };

    /**
     * Encodes the specified BrowserInfo message, length delimited. Does not implicitly {@link BrowserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BrowserInfo
     * @static
     * @param {IBrowserInfo} message BrowserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BrowserInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BrowserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof BrowserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BrowserInfo} BrowserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BrowserInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BrowserInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.browserName = reader.string();
                    break;
                }
            case 2: {
                    message.browserVersion = reader.string();
                    break;
                }
            case 3: {
                    message.deviceType = reader.string();
                    break;
                }
            case 4: {
                    message.os = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BrowserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BrowserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BrowserInfo} BrowserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BrowserInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BrowserInfo message.
     * @function verify
     * @memberof BrowserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BrowserInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.browserName != null && message.hasOwnProperty("browserName"))
            if (!$util.isString(message.browserName))
                return "browserName: string expected";
        if (message.browserVersion != null && message.hasOwnProperty("browserVersion"))
            if (!$util.isString(message.browserVersion))
                return "browserVersion: string expected";
        if (message.deviceType != null && message.hasOwnProperty("deviceType"))
            if (!$util.isString(message.deviceType))
                return "deviceType: string expected";
        if (message.os != null && message.hasOwnProperty("os"))
            if (!$util.isString(message.os))
                return "os: string expected";
        return null;
    };

    /**
     * Creates a BrowserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BrowserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BrowserInfo} BrowserInfo
     */
    BrowserInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.BrowserInfo)
            return object;
        let message = new $root.BrowserInfo();
        if (object.browserName != null)
            message.browserName = String(object.browserName);
        if (object.browserVersion != null)
            message.browserVersion = String(object.browserVersion);
        if (object.deviceType != null)
            message.deviceType = String(object.deviceType);
        if (object.os != null)
            message.os = String(object.os);
        return message;
    };

    /**
     * Creates a plain object from a BrowserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BrowserInfo
     * @static
     * @param {BrowserInfo} message BrowserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BrowserInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.browserName = "";
            object.browserVersion = "";
            object.deviceType = "";
            object.os = "";
        }
        if (message.browserName != null && message.hasOwnProperty("browserName"))
            object.browserName = message.browserName;
        if (message.browserVersion != null && message.hasOwnProperty("browserVersion"))
            object.browserVersion = message.browserVersion;
        if (message.deviceType != null && message.hasOwnProperty("deviceType"))
            object.deviceType = message.deviceType;
        if (message.os != null && message.hasOwnProperty("os"))
            object.os = message.os;
        return object;
    };

    /**
     * Converts this BrowserInfo to JSON.
     * @function toJSON
     * @memberof BrowserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BrowserInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BrowserInfo
     * @function getTypeUrl
     * @memberof BrowserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BrowserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BrowserInfo";
    };

    return BrowserInfo;
})();

/**
 * RootContainer enum.
 * @exports RootContainer
 * @enum {number}
 * @property {number} MAIN=0 MAIN value
 * @property {number} SIDEBAR=1 SIDEBAR value
 * @property {number} EVENT=2 EVENT value
 * @property {number} BOTTOM=3 BOTTOM value
 */
export const RootContainer = $root.RootContainer = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "MAIN"] = 0;
    values[valuesById[1] = "SIDEBAR"] = 1;
    values[valuesById[2] = "EVENT"] = 2;
    values[valuesById[3] = "BOTTOM"] = 3;
    return values;
})();

export const openmetrics = $root.openmetrics = (() => {

    /**
     * Namespace openmetrics.
     * @exports openmetrics
     * @namespace
     */
    const openmetrics = {};

    openmetrics.MetricSet = (function() {

        /**
         * Properties of a MetricSet.
         * @memberof openmetrics
         * @interface IMetricSet
         * @property {Array.<openmetrics.IMetricFamily>|null} [metricFamilies] MetricSet metricFamilies
         */

        /**
         * Constructs a new MetricSet.
         * @memberof openmetrics
         * @classdesc Represents a MetricSet.
         * @implements IMetricSet
         * @constructor
         * @param {openmetrics.IMetricSet=} [properties] Properties to set
         */
        function MetricSet(properties) {
            this.metricFamilies = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetricSet metricFamilies.
         * @member {Array.<openmetrics.IMetricFamily>} metricFamilies
         * @memberof openmetrics.MetricSet
         * @instance
         */
        MetricSet.prototype.metricFamilies = $util.emptyArray;

        /**
         * Creates a new MetricSet instance using the specified properties.
         * @function create
         * @memberof openmetrics.MetricSet
         * @static
         * @param {openmetrics.IMetricSet=} [properties] Properties to set
         * @returns {openmetrics.MetricSet} MetricSet instance
         */
        MetricSet.create = function create(properties) {
            return new MetricSet(properties);
        };

        /**
         * Encodes the specified MetricSet message. Does not implicitly {@link openmetrics.MetricSet.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.MetricSet
         * @static
         * @param {openmetrics.IMetricSet} message MetricSet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricSet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.metricFamilies != null && message.metricFamilies.length)
                for (let i = 0; i < message.metricFamilies.length; ++i)
                    $root.openmetrics.MetricFamily.encode(message.metricFamilies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MetricSet message, length delimited. Does not implicitly {@link openmetrics.MetricSet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.MetricSet
         * @static
         * @param {openmetrics.IMetricSet} message MetricSet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricSet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MetricSet message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.MetricSet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.MetricSet} MetricSet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricSet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.MetricSet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.metricFamilies && message.metricFamilies.length))
                            message.metricFamilies = [];
                        message.metricFamilies.push($root.openmetrics.MetricFamily.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MetricSet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.MetricSet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.MetricSet} MetricSet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricSet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MetricSet message.
         * @function verify
         * @memberof openmetrics.MetricSet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MetricSet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.metricFamilies != null && message.hasOwnProperty("metricFamilies")) {
                if (!Array.isArray(message.metricFamilies))
                    return "metricFamilies: array expected";
                for (let i = 0; i < message.metricFamilies.length; ++i) {
                    let error = $root.openmetrics.MetricFamily.verify(message.metricFamilies[i]);
                    if (error)
                        return "metricFamilies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MetricSet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.MetricSet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.MetricSet} MetricSet
         */
        MetricSet.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.MetricSet)
                return object;
            let message = new $root.openmetrics.MetricSet();
            if (object.metricFamilies) {
                if (!Array.isArray(object.metricFamilies))
                    throw TypeError(".openmetrics.MetricSet.metricFamilies: array expected");
                message.metricFamilies = [];
                for (let i = 0; i < object.metricFamilies.length; ++i) {
                    if (typeof object.metricFamilies[i] !== "object")
                        throw TypeError(".openmetrics.MetricSet.metricFamilies: object expected");
                    message.metricFamilies[i] = $root.openmetrics.MetricFamily.fromObject(object.metricFamilies[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MetricSet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.MetricSet
         * @static
         * @param {openmetrics.MetricSet} message MetricSet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MetricSet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.metricFamilies = [];
            if (message.metricFamilies && message.metricFamilies.length) {
                object.metricFamilies = [];
                for (let j = 0; j < message.metricFamilies.length; ++j)
                    object.metricFamilies[j] = $root.openmetrics.MetricFamily.toObject(message.metricFamilies[j], options);
            }
            return object;
        };

        /**
         * Converts this MetricSet to JSON.
         * @function toJSON
         * @memberof openmetrics.MetricSet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MetricSet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MetricSet
         * @function getTypeUrl
         * @memberof openmetrics.MetricSet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetricSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.MetricSet";
        };

        return MetricSet;
    })();

    openmetrics.MetricFamily = (function() {

        /**
         * Properties of a MetricFamily.
         * @memberof openmetrics
         * @interface IMetricFamily
         * @property {string|null} [name] MetricFamily name
         * @property {openmetrics.MetricType|null} [type] MetricFamily type
         * @property {string|null} [unit] MetricFamily unit
         * @property {string|null} [help] MetricFamily help
         * @property {Array.<openmetrics.IMetric>|null} [metrics] MetricFamily metrics
         */

        /**
         * Constructs a new MetricFamily.
         * @memberof openmetrics
         * @classdesc Represents a MetricFamily.
         * @implements IMetricFamily
         * @constructor
         * @param {openmetrics.IMetricFamily=} [properties] Properties to set
         */
        function MetricFamily(properties) {
            this.metrics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetricFamily name.
         * @member {string} name
         * @memberof openmetrics.MetricFamily
         * @instance
         */
        MetricFamily.prototype.name = "";

        /**
         * MetricFamily type.
         * @member {openmetrics.MetricType} type
         * @memberof openmetrics.MetricFamily
         * @instance
         */
        MetricFamily.prototype.type = 0;

        /**
         * MetricFamily unit.
         * @member {string} unit
         * @memberof openmetrics.MetricFamily
         * @instance
         */
        MetricFamily.prototype.unit = "";

        /**
         * MetricFamily help.
         * @member {string} help
         * @memberof openmetrics.MetricFamily
         * @instance
         */
        MetricFamily.prototype.help = "";

        /**
         * MetricFamily metrics.
         * @member {Array.<openmetrics.IMetric>} metrics
         * @memberof openmetrics.MetricFamily
         * @instance
         */
        MetricFamily.prototype.metrics = $util.emptyArray;

        /**
         * Creates a new MetricFamily instance using the specified properties.
         * @function create
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {openmetrics.IMetricFamily=} [properties] Properties to set
         * @returns {openmetrics.MetricFamily} MetricFamily instance
         */
        MetricFamily.create = function create(properties) {
            return new MetricFamily(properties);
        };

        /**
         * Encodes the specified MetricFamily message. Does not implicitly {@link openmetrics.MetricFamily.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {openmetrics.IMetricFamily} message MetricFamily message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricFamily.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.unit);
            if (message.help != null && Object.hasOwnProperty.call(message, "help"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.help);
            if (message.metrics != null && message.metrics.length)
                for (let i = 0; i < message.metrics.length; ++i)
                    $root.openmetrics.Metric.encode(message.metrics[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MetricFamily message, length delimited. Does not implicitly {@link openmetrics.MetricFamily.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {openmetrics.IMetricFamily} message MetricFamily message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricFamily.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MetricFamily message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.MetricFamily} MetricFamily
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricFamily.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.MetricFamily();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.unit = reader.string();
                        break;
                    }
                case 4: {
                        message.help = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.metrics && message.metrics.length))
                            message.metrics = [];
                        message.metrics.push($root.openmetrics.Metric.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MetricFamily message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.MetricFamily} MetricFamily
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricFamily.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MetricFamily message.
         * @function verify
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MetricFamily.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.unit != null && message.hasOwnProperty("unit"))
                if (!$util.isString(message.unit))
                    return "unit: string expected";
            if (message.help != null && message.hasOwnProperty("help"))
                if (!$util.isString(message.help))
                    return "help: string expected";
            if (message.metrics != null && message.hasOwnProperty("metrics")) {
                if (!Array.isArray(message.metrics))
                    return "metrics: array expected";
                for (let i = 0; i < message.metrics.length; ++i) {
                    let error = $root.openmetrics.Metric.verify(message.metrics[i]);
                    if (error)
                        return "metrics." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MetricFamily message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.MetricFamily} MetricFamily
         */
        MetricFamily.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.MetricFamily)
                return object;
            let message = new $root.openmetrics.MetricFamily();
            if (object.name != null)
                message.name = String(object.name);
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "GAUGE":
            case 1:
                message.type = 1;
                break;
            case "COUNTER":
            case 2:
                message.type = 2;
                break;
            case "STATE_SET":
            case 3:
                message.type = 3;
                break;
            case "INFO":
            case 4:
                message.type = 4;
                break;
            case "HISTOGRAM":
            case 5:
                message.type = 5;
                break;
            case "GAUGE_HISTOGRAM":
            case 6:
                message.type = 6;
                break;
            case "SUMMARY":
            case 7:
                message.type = 7;
                break;
            }
            if (object.unit != null)
                message.unit = String(object.unit);
            if (object.help != null)
                message.help = String(object.help);
            if (object.metrics) {
                if (!Array.isArray(object.metrics))
                    throw TypeError(".openmetrics.MetricFamily.metrics: array expected");
                message.metrics = [];
                for (let i = 0; i < object.metrics.length; ++i) {
                    if (typeof object.metrics[i] !== "object")
                        throw TypeError(".openmetrics.MetricFamily.metrics: object expected");
                    message.metrics[i] = $root.openmetrics.Metric.fromObject(object.metrics[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MetricFamily message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {openmetrics.MetricFamily} message MetricFamily
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MetricFamily.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.metrics = [];
            if (options.defaults) {
                object.name = "";
                object.type = options.enums === String ? "UNKNOWN" : 0;
                object.unit = "";
                object.help = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.openmetrics.MetricType[message.type] === undefined ? message.type : $root.openmetrics.MetricType[message.type] : message.type;
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = message.unit;
            if (message.help != null && message.hasOwnProperty("help"))
                object.help = message.help;
            if (message.metrics && message.metrics.length) {
                object.metrics = [];
                for (let j = 0; j < message.metrics.length; ++j)
                    object.metrics[j] = $root.openmetrics.Metric.toObject(message.metrics[j], options);
            }
            return object;
        };

        /**
         * Converts this MetricFamily to JSON.
         * @function toJSON
         * @memberof openmetrics.MetricFamily
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MetricFamily.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MetricFamily
         * @function getTypeUrl
         * @memberof openmetrics.MetricFamily
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetricFamily.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.MetricFamily";
        };

        return MetricFamily;
    })();

    /**
     * MetricType enum.
     * @name openmetrics.MetricType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} GAUGE=1 GAUGE value
     * @property {number} COUNTER=2 COUNTER value
     * @property {number} STATE_SET=3 STATE_SET value
     * @property {number} INFO=4 INFO value
     * @property {number} HISTOGRAM=5 HISTOGRAM value
     * @property {number} GAUGE_HISTOGRAM=6 GAUGE_HISTOGRAM value
     * @property {number} SUMMARY=7 SUMMARY value
     */
    openmetrics.MetricType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "GAUGE"] = 1;
        values[valuesById[2] = "COUNTER"] = 2;
        values[valuesById[3] = "STATE_SET"] = 3;
        values[valuesById[4] = "INFO"] = 4;
        values[valuesById[5] = "HISTOGRAM"] = 5;
        values[valuesById[6] = "GAUGE_HISTOGRAM"] = 6;
        values[valuesById[7] = "SUMMARY"] = 7;
        return values;
    })();

    openmetrics.Metric = (function() {

        /**
         * Properties of a Metric.
         * @memberof openmetrics
         * @interface IMetric
         * @property {Array.<openmetrics.ILabel>|null} [labels] Metric labels
         * @property {Array.<openmetrics.IMetricPoint>|null} [metricPoints] Metric metricPoints
         */

        /**
         * Constructs a new Metric.
         * @memberof openmetrics
         * @classdesc Represents a Metric.
         * @implements IMetric
         * @constructor
         * @param {openmetrics.IMetric=} [properties] Properties to set
         */
        function Metric(properties) {
            this.labels = [];
            this.metricPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metric labels.
         * @member {Array.<openmetrics.ILabel>} labels
         * @memberof openmetrics.Metric
         * @instance
         */
        Metric.prototype.labels = $util.emptyArray;

        /**
         * Metric metricPoints.
         * @member {Array.<openmetrics.IMetricPoint>} metricPoints
         * @memberof openmetrics.Metric
         * @instance
         */
        Metric.prototype.metricPoints = $util.emptyArray;

        /**
         * Creates a new Metric instance using the specified properties.
         * @function create
         * @memberof openmetrics.Metric
         * @static
         * @param {openmetrics.IMetric=} [properties] Properties to set
         * @returns {openmetrics.Metric} Metric instance
         */
        Metric.create = function create(properties) {
            return new Metric(properties);
        };

        /**
         * Encodes the specified Metric message. Does not implicitly {@link openmetrics.Metric.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.Metric
         * @static
         * @param {openmetrics.IMetric} message Metric message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metric.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.labels != null && message.labels.length)
                for (let i = 0; i < message.labels.length; ++i)
                    $root.openmetrics.Label.encode(message.labels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metricPoints != null && message.metricPoints.length)
                for (let i = 0; i < message.metricPoints.length; ++i)
                    $root.openmetrics.MetricPoint.encode(message.metricPoints[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Metric message, length delimited. Does not implicitly {@link openmetrics.Metric.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.Metric
         * @static
         * @param {openmetrics.IMetric} message Metric message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metric.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Metric message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.Metric
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.Metric} Metric
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metric.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.Metric();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.labels && message.labels.length))
                            message.labels = [];
                        message.labels.push($root.openmetrics.Label.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.metricPoints && message.metricPoints.length))
                            message.metricPoints = [];
                        message.metricPoints.push($root.openmetrics.MetricPoint.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Metric message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.Metric
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.Metric} Metric
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metric.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Metric message.
         * @function verify
         * @memberof openmetrics.Metric
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Metric.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.labels != null && message.hasOwnProperty("labels")) {
                if (!Array.isArray(message.labels))
                    return "labels: array expected";
                for (let i = 0; i < message.labels.length; ++i) {
                    let error = $root.openmetrics.Label.verify(message.labels[i]);
                    if (error)
                        return "labels." + error;
                }
            }
            if (message.metricPoints != null && message.hasOwnProperty("metricPoints")) {
                if (!Array.isArray(message.metricPoints))
                    return "metricPoints: array expected";
                for (let i = 0; i < message.metricPoints.length; ++i) {
                    let error = $root.openmetrics.MetricPoint.verify(message.metricPoints[i]);
                    if (error)
                        return "metricPoints." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Metric message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.Metric
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.Metric} Metric
         */
        Metric.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.Metric)
                return object;
            let message = new $root.openmetrics.Metric();
            if (object.labels) {
                if (!Array.isArray(object.labels))
                    throw TypeError(".openmetrics.Metric.labels: array expected");
                message.labels = [];
                for (let i = 0; i < object.labels.length; ++i) {
                    if (typeof object.labels[i] !== "object")
                        throw TypeError(".openmetrics.Metric.labels: object expected");
                    message.labels[i] = $root.openmetrics.Label.fromObject(object.labels[i]);
                }
            }
            if (object.metricPoints) {
                if (!Array.isArray(object.metricPoints))
                    throw TypeError(".openmetrics.Metric.metricPoints: array expected");
                message.metricPoints = [];
                for (let i = 0; i < object.metricPoints.length; ++i) {
                    if (typeof object.metricPoints[i] !== "object")
                        throw TypeError(".openmetrics.Metric.metricPoints: object expected");
                    message.metricPoints[i] = $root.openmetrics.MetricPoint.fromObject(object.metricPoints[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Metric message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.Metric
         * @static
         * @param {openmetrics.Metric} message Metric
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Metric.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.labels = [];
                object.metricPoints = [];
            }
            if (message.labels && message.labels.length) {
                object.labels = [];
                for (let j = 0; j < message.labels.length; ++j)
                    object.labels[j] = $root.openmetrics.Label.toObject(message.labels[j], options);
            }
            if (message.metricPoints && message.metricPoints.length) {
                object.metricPoints = [];
                for (let j = 0; j < message.metricPoints.length; ++j)
                    object.metricPoints[j] = $root.openmetrics.MetricPoint.toObject(message.metricPoints[j], options);
            }
            return object;
        };

        /**
         * Converts this Metric to JSON.
         * @function toJSON
         * @memberof openmetrics.Metric
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Metric.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Metric
         * @function getTypeUrl
         * @memberof openmetrics.Metric
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Metric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.Metric";
        };

        return Metric;
    })();

    openmetrics.Label = (function() {

        /**
         * Properties of a Label.
         * @memberof openmetrics
         * @interface ILabel
         * @property {string|null} [name] Label name
         * @property {string|null} [value] Label value
         */

        /**
         * Constructs a new Label.
         * @memberof openmetrics
         * @classdesc Represents a Label.
         * @implements ILabel
         * @constructor
         * @param {openmetrics.ILabel=} [properties] Properties to set
         */
        function Label(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Label name.
         * @member {string} name
         * @memberof openmetrics.Label
         * @instance
         */
        Label.prototype.name = "";

        /**
         * Label value.
         * @member {string} value
         * @memberof openmetrics.Label
         * @instance
         */
        Label.prototype.value = "";

        /**
         * Creates a new Label instance using the specified properties.
         * @function create
         * @memberof openmetrics.Label
         * @static
         * @param {openmetrics.ILabel=} [properties] Properties to set
         * @returns {openmetrics.Label} Label instance
         */
        Label.create = function create(properties) {
            return new Label(properties);
        };

        /**
         * Encodes the specified Label message. Does not implicitly {@link openmetrics.Label.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.Label
         * @static
         * @param {openmetrics.ILabel} message Label message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Label.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified Label message, length delimited. Does not implicitly {@link openmetrics.Label.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.Label
         * @static
         * @param {openmetrics.ILabel} message Label message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Label.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Label message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.Label
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.Label} Label
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Label.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.Label();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Label message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.Label
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.Label} Label
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Label.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Label message.
         * @function verify
         * @memberof openmetrics.Label
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Label.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a Label message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.Label
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.Label} Label
         */
        Label.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.Label)
                return object;
            let message = new $root.openmetrics.Label();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a Label message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.Label
         * @static
         * @param {openmetrics.Label} message Label
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Label.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.value = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this Label to JSON.
         * @function toJSON
         * @memberof openmetrics.Label
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Label.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Label
         * @function getTypeUrl
         * @memberof openmetrics.Label
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Label.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.Label";
        };

        return Label;
    })();

    openmetrics.MetricPoint = (function() {

        /**
         * Properties of a MetricPoint.
         * @memberof openmetrics
         * @interface IMetricPoint
         * @property {openmetrics.IUnknownValue|null} [unknownValue] MetricPoint unknownValue
         * @property {openmetrics.IGaugeValue|null} [gaugeValue] MetricPoint gaugeValue
         * @property {openmetrics.ICounterValue|null} [counterValue] MetricPoint counterValue
         * @property {openmetrics.IHistogramValue|null} [histogramValue] MetricPoint histogramValue
         * @property {openmetrics.IStateSetValue|null} [stateSetValue] MetricPoint stateSetValue
         * @property {openmetrics.IInfoValue|null} [infoValue] MetricPoint infoValue
         * @property {openmetrics.ISummaryValue|null} [summaryValue] MetricPoint summaryValue
         * @property {google.protobuf.ITimestamp|null} [timestamp] MetricPoint timestamp
         */

        /**
         * Constructs a new MetricPoint.
         * @memberof openmetrics
         * @classdesc Represents a MetricPoint.
         * @implements IMetricPoint
         * @constructor
         * @param {openmetrics.IMetricPoint=} [properties] Properties to set
         */
        function MetricPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetricPoint unknownValue.
         * @member {openmetrics.IUnknownValue|null|undefined} unknownValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.unknownValue = null;

        /**
         * MetricPoint gaugeValue.
         * @member {openmetrics.IGaugeValue|null|undefined} gaugeValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.gaugeValue = null;

        /**
         * MetricPoint counterValue.
         * @member {openmetrics.ICounterValue|null|undefined} counterValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.counterValue = null;

        /**
         * MetricPoint histogramValue.
         * @member {openmetrics.IHistogramValue|null|undefined} histogramValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.histogramValue = null;

        /**
         * MetricPoint stateSetValue.
         * @member {openmetrics.IStateSetValue|null|undefined} stateSetValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.stateSetValue = null;

        /**
         * MetricPoint infoValue.
         * @member {openmetrics.IInfoValue|null|undefined} infoValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.infoValue = null;

        /**
         * MetricPoint summaryValue.
         * @member {openmetrics.ISummaryValue|null|undefined} summaryValue
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.summaryValue = null;

        /**
         * MetricPoint timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        MetricPoint.prototype.timestamp = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MetricPoint value.
         * @member {"unknownValue"|"gaugeValue"|"counterValue"|"histogramValue"|"stateSetValue"|"infoValue"|"summaryValue"|undefined} value
         * @memberof openmetrics.MetricPoint
         * @instance
         */
        Object.defineProperty(MetricPoint.prototype, "value", {
            get: $util.oneOfGetter($oneOfFields = ["unknownValue", "gaugeValue", "counterValue", "histogramValue", "stateSetValue", "infoValue", "summaryValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MetricPoint instance using the specified properties.
         * @function create
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {openmetrics.IMetricPoint=} [properties] Properties to set
         * @returns {openmetrics.MetricPoint} MetricPoint instance
         */
        MetricPoint.create = function create(properties) {
            return new MetricPoint(properties);
        };

        /**
         * Encodes the specified MetricPoint message. Does not implicitly {@link openmetrics.MetricPoint.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {openmetrics.IMetricPoint} message MetricPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unknownValue != null && Object.hasOwnProperty.call(message, "unknownValue"))
                $root.openmetrics.UnknownValue.encode(message.unknownValue, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.gaugeValue != null && Object.hasOwnProperty.call(message, "gaugeValue"))
                $root.openmetrics.GaugeValue.encode(message.gaugeValue, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.counterValue != null && Object.hasOwnProperty.call(message, "counterValue"))
                $root.openmetrics.CounterValue.encode(message.counterValue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.histogramValue != null && Object.hasOwnProperty.call(message, "histogramValue"))
                $root.openmetrics.HistogramValue.encode(message.histogramValue, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.stateSetValue != null && Object.hasOwnProperty.call(message, "stateSetValue"))
                $root.openmetrics.StateSetValue.encode(message.stateSetValue, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.infoValue != null && Object.hasOwnProperty.call(message, "infoValue"))
                $root.openmetrics.InfoValue.encode(message.infoValue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.summaryValue != null && Object.hasOwnProperty.call(message, "summaryValue"))
                $root.openmetrics.SummaryValue.encode(message.summaryValue, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MetricPoint message, length delimited. Does not implicitly {@link openmetrics.MetricPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {openmetrics.IMetricPoint} message MetricPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetricPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MetricPoint message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.MetricPoint} MetricPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.MetricPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.unknownValue = $root.openmetrics.UnknownValue.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.gaugeValue = $root.openmetrics.GaugeValue.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.counterValue = $root.openmetrics.CounterValue.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.histogramValue = $root.openmetrics.HistogramValue.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.stateSetValue = $root.openmetrics.StateSetValue.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.infoValue = $root.openmetrics.InfoValue.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.summaryValue = $root.openmetrics.SummaryValue.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MetricPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.MetricPoint} MetricPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetricPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MetricPoint message.
         * @function verify
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MetricPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.unknownValue != null && message.hasOwnProperty("unknownValue")) {
                properties.value = 1;
                {
                    let error = $root.openmetrics.UnknownValue.verify(message.unknownValue);
                    if (error)
                        return "unknownValue." + error;
                }
            }
            if (message.gaugeValue != null && message.hasOwnProperty("gaugeValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.GaugeValue.verify(message.gaugeValue);
                    if (error)
                        return "gaugeValue." + error;
                }
            }
            if (message.counterValue != null && message.hasOwnProperty("counterValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.CounterValue.verify(message.counterValue);
                    if (error)
                        return "counterValue." + error;
                }
            }
            if (message.histogramValue != null && message.hasOwnProperty("histogramValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.HistogramValue.verify(message.histogramValue);
                    if (error)
                        return "histogramValue." + error;
                }
            }
            if (message.stateSetValue != null && message.hasOwnProperty("stateSetValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.StateSetValue.verify(message.stateSetValue);
                    if (error)
                        return "stateSetValue." + error;
                }
            }
            if (message.infoValue != null && message.hasOwnProperty("infoValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.InfoValue.verify(message.infoValue);
                    if (error)
                        return "infoValue." + error;
                }
            }
            if (message.summaryValue != null && message.hasOwnProperty("summaryValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.openmetrics.SummaryValue.verify(message.summaryValue);
                    if (error)
                        return "summaryValue." + error;
                }
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                let error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                if (error)
                    return "timestamp." + error;
            }
            return null;
        };

        /**
         * Creates a MetricPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.MetricPoint} MetricPoint
         */
        MetricPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.MetricPoint)
                return object;
            let message = new $root.openmetrics.MetricPoint();
            if (object.unknownValue != null) {
                if (typeof object.unknownValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.unknownValue: object expected");
                message.unknownValue = $root.openmetrics.UnknownValue.fromObject(object.unknownValue);
            }
            if (object.gaugeValue != null) {
                if (typeof object.gaugeValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.gaugeValue: object expected");
                message.gaugeValue = $root.openmetrics.GaugeValue.fromObject(object.gaugeValue);
            }
            if (object.counterValue != null) {
                if (typeof object.counterValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.counterValue: object expected");
                message.counterValue = $root.openmetrics.CounterValue.fromObject(object.counterValue);
            }
            if (object.histogramValue != null) {
                if (typeof object.histogramValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.histogramValue: object expected");
                message.histogramValue = $root.openmetrics.HistogramValue.fromObject(object.histogramValue);
            }
            if (object.stateSetValue != null) {
                if (typeof object.stateSetValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.stateSetValue: object expected");
                message.stateSetValue = $root.openmetrics.StateSetValue.fromObject(object.stateSetValue);
            }
            if (object.infoValue != null) {
                if (typeof object.infoValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.infoValue: object expected");
                message.infoValue = $root.openmetrics.InfoValue.fromObject(object.infoValue);
            }
            if (object.summaryValue != null) {
                if (typeof object.summaryValue !== "object")
                    throw TypeError(".openmetrics.MetricPoint.summaryValue: object expected");
                message.summaryValue = $root.openmetrics.SummaryValue.fromObject(object.summaryValue);
            }
            if (object.timestamp != null) {
                if (typeof object.timestamp !== "object")
                    throw TypeError(".openmetrics.MetricPoint.timestamp: object expected");
                message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
            }
            return message;
        };

        /**
         * Creates a plain object from a MetricPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {openmetrics.MetricPoint} message MetricPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MetricPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.timestamp = null;
            if (message.unknownValue != null && message.hasOwnProperty("unknownValue")) {
                object.unknownValue = $root.openmetrics.UnknownValue.toObject(message.unknownValue, options);
                if (options.oneofs)
                    object.value = "unknownValue";
            }
            if (message.gaugeValue != null && message.hasOwnProperty("gaugeValue")) {
                object.gaugeValue = $root.openmetrics.GaugeValue.toObject(message.gaugeValue, options);
                if (options.oneofs)
                    object.value = "gaugeValue";
            }
            if (message.counterValue != null && message.hasOwnProperty("counterValue")) {
                object.counterValue = $root.openmetrics.CounterValue.toObject(message.counterValue, options);
                if (options.oneofs)
                    object.value = "counterValue";
            }
            if (message.histogramValue != null && message.hasOwnProperty("histogramValue")) {
                object.histogramValue = $root.openmetrics.HistogramValue.toObject(message.histogramValue, options);
                if (options.oneofs)
                    object.value = "histogramValue";
            }
            if (message.stateSetValue != null && message.hasOwnProperty("stateSetValue")) {
                object.stateSetValue = $root.openmetrics.StateSetValue.toObject(message.stateSetValue, options);
                if (options.oneofs)
                    object.value = "stateSetValue";
            }
            if (message.infoValue != null && message.hasOwnProperty("infoValue")) {
                object.infoValue = $root.openmetrics.InfoValue.toObject(message.infoValue, options);
                if (options.oneofs)
                    object.value = "infoValue";
            }
            if (message.summaryValue != null && message.hasOwnProperty("summaryValue")) {
                object.summaryValue = $root.openmetrics.SummaryValue.toObject(message.summaryValue, options);
                if (options.oneofs)
                    object.value = "summaryValue";
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
            return object;
        };

        /**
         * Converts this MetricPoint to JSON.
         * @function toJSON
         * @memberof openmetrics.MetricPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MetricPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MetricPoint
         * @function getTypeUrl
         * @memberof openmetrics.MetricPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetricPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.MetricPoint";
        };

        return MetricPoint;
    })();

    openmetrics.UnknownValue = (function() {

        /**
         * Properties of an UnknownValue.
         * @memberof openmetrics
         * @interface IUnknownValue
         * @property {number|null} [doubleValue] UnknownValue doubleValue
         * @property {number|Long|null} [intValue] UnknownValue intValue
         */

        /**
         * Constructs a new UnknownValue.
         * @memberof openmetrics
         * @classdesc Represents an UnknownValue.
         * @implements IUnknownValue
         * @constructor
         * @param {openmetrics.IUnknownValue=} [properties] Properties to set
         */
        function UnknownValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnknownValue doubleValue.
         * @member {number|null|undefined} doubleValue
         * @memberof openmetrics.UnknownValue
         * @instance
         */
        UnknownValue.prototype.doubleValue = null;

        /**
         * UnknownValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof openmetrics.UnknownValue
         * @instance
         */
        UnknownValue.prototype.intValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * UnknownValue value.
         * @member {"doubleValue"|"intValue"|undefined} value
         * @memberof openmetrics.UnknownValue
         * @instance
         */
        Object.defineProperty(UnknownValue.prototype, "value", {
            get: $util.oneOfGetter($oneOfFields = ["doubleValue", "intValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UnknownValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {openmetrics.IUnknownValue=} [properties] Properties to set
         * @returns {openmetrics.UnknownValue} UnknownValue instance
         */
        UnknownValue.create = function create(properties) {
            return new UnknownValue(properties);
        };

        /**
         * Encodes the specified UnknownValue message. Does not implicitly {@link openmetrics.UnknownValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {openmetrics.IUnknownValue} message UnknownValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnknownValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.doubleValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intValue);
            return writer;
        };

        /**
         * Encodes the specified UnknownValue message, length delimited. Does not implicitly {@link openmetrics.UnknownValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {openmetrics.IUnknownValue} message UnknownValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnknownValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnknownValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.UnknownValue} UnknownValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnknownValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.UnknownValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.doubleValue = reader.double();
                        break;
                    }
                case 2: {
                        message.intValue = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnknownValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.UnknownValue} UnknownValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnknownValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnknownValue message.
         * @function verify
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnknownValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                properties.value = 1;
                if (typeof message.doubleValue !== "number")
                    return "doubleValue: number expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates an UnknownValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.UnknownValue} UnknownValue
         */
        UnknownValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.UnknownValue)
                return object;
            let message = new $root.openmetrics.UnknownValue();
            if (object.doubleValue != null)
                message.doubleValue = Number(object.doubleValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an UnknownValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {openmetrics.UnknownValue} message UnknownValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnknownValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (options.oneofs)
                    object.value = "doubleValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
                if (options.oneofs)
                    object.value = "intValue";
            }
            return object;
        };

        /**
         * Converts this UnknownValue to JSON.
         * @function toJSON
         * @memberof openmetrics.UnknownValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnknownValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnknownValue
         * @function getTypeUrl
         * @memberof openmetrics.UnknownValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnknownValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.UnknownValue";
        };

        return UnknownValue;
    })();

    openmetrics.GaugeValue = (function() {

        /**
         * Properties of a GaugeValue.
         * @memberof openmetrics
         * @interface IGaugeValue
         * @property {number|null} [doubleValue] GaugeValue doubleValue
         * @property {number|Long|null} [intValue] GaugeValue intValue
         */

        /**
         * Constructs a new GaugeValue.
         * @memberof openmetrics
         * @classdesc Represents a GaugeValue.
         * @implements IGaugeValue
         * @constructor
         * @param {openmetrics.IGaugeValue=} [properties] Properties to set
         */
        function GaugeValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GaugeValue doubleValue.
         * @member {number|null|undefined} doubleValue
         * @memberof openmetrics.GaugeValue
         * @instance
         */
        GaugeValue.prototype.doubleValue = null;

        /**
         * GaugeValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof openmetrics.GaugeValue
         * @instance
         */
        GaugeValue.prototype.intValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GaugeValue value.
         * @member {"doubleValue"|"intValue"|undefined} value
         * @memberof openmetrics.GaugeValue
         * @instance
         */
        Object.defineProperty(GaugeValue.prototype, "value", {
            get: $util.oneOfGetter($oneOfFields = ["doubleValue", "intValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GaugeValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {openmetrics.IGaugeValue=} [properties] Properties to set
         * @returns {openmetrics.GaugeValue} GaugeValue instance
         */
        GaugeValue.create = function create(properties) {
            return new GaugeValue(properties);
        };

        /**
         * Encodes the specified GaugeValue message. Does not implicitly {@link openmetrics.GaugeValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {openmetrics.IGaugeValue} message GaugeValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GaugeValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.doubleValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intValue);
            return writer;
        };

        /**
         * Encodes the specified GaugeValue message, length delimited. Does not implicitly {@link openmetrics.GaugeValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {openmetrics.IGaugeValue} message GaugeValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GaugeValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GaugeValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.GaugeValue} GaugeValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GaugeValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.GaugeValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.doubleValue = reader.double();
                        break;
                    }
                case 2: {
                        message.intValue = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GaugeValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.GaugeValue} GaugeValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GaugeValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GaugeValue message.
         * @function verify
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GaugeValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                properties.value = 1;
                if (typeof message.doubleValue !== "number")
                    return "doubleValue: number expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a GaugeValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.GaugeValue} GaugeValue
         */
        GaugeValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.GaugeValue)
                return object;
            let message = new $root.openmetrics.GaugeValue();
            if (object.doubleValue != null)
                message.doubleValue = Number(object.doubleValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GaugeValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {openmetrics.GaugeValue} message GaugeValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GaugeValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (options.oneofs)
                    object.value = "doubleValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
                if (options.oneofs)
                    object.value = "intValue";
            }
            return object;
        };

        /**
         * Converts this GaugeValue to JSON.
         * @function toJSON
         * @memberof openmetrics.GaugeValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GaugeValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GaugeValue
         * @function getTypeUrl
         * @memberof openmetrics.GaugeValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GaugeValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.GaugeValue";
        };

        return GaugeValue;
    })();

    openmetrics.CounterValue = (function() {

        /**
         * Properties of a CounterValue.
         * @memberof openmetrics
         * @interface ICounterValue
         * @property {number|null} [doubleValue] CounterValue doubleValue
         * @property {number|Long|null} [intValue] CounterValue intValue
         * @property {google.protobuf.ITimestamp|null} [created] CounterValue created
         * @property {openmetrics.IExemplar|null} [exemplar] CounterValue exemplar
         */

        /**
         * Constructs a new CounterValue.
         * @memberof openmetrics
         * @classdesc Represents a CounterValue.
         * @implements ICounterValue
         * @constructor
         * @param {openmetrics.ICounterValue=} [properties] Properties to set
         */
        function CounterValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CounterValue doubleValue.
         * @member {number|null|undefined} doubleValue
         * @memberof openmetrics.CounterValue
         * @instance
         */
        CounterValue.prototype.doubleValue = null;

        /**
         * CounterValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof openmetrics.CounterValue
         * @instance
         */
        CounterValue.prototype.intValue = null;

        /**
         * CounterValue created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof openmetrics.CounterValue
         * @instance
         */
        CounterValue.prototype.created = null;

        /**
         * CounterValue exemplar.
         * @member {openmetrics.IExemplar|null|undefined} exemplar
         * @memberof openmetrics.CounterValue
         * @instance
         */
        CounterValue.prototype.exemplar = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CounterValue total.
         * @member {"doubleValue"|"intValue"|undefined} total
         * @memberof openmetrics.CounterValue
         * @instance
         */
        Object.defineProperty(CounterValue.prototype, "total", {
            get: $util.oneOfGetter($oneOfFields = ["doubleValue", "intValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CounterValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.CounterValue
         * @static
         * @param {openmetrics.ICounterValue=} [properties] Properties to set
         * @returns {openmetrics.CounterValue} CounterValue instance
         */
        CounterValue.create = function create(properties) {
            return new CounterValue(properties);
        };

        /**
         * Encodes the specified CounterValue message. Does not implicitly {@link openmetrics.CounterValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.CounterValue
         * @static
         * @param {openmetrics.ICounterValue} message CounterValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CounterValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.doubleValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.intValue);
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.exemplar != null && Object.hasOwnProperty.call(message, "exemplar"))
                $root.openmetrics.Exemplar.encode(message.exemplar, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CounterValue message, length delimited. Does not implicitly {@link openmetrics.CounterValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.CounterValue
         * @static
         * @param {openmetrics.ICounterValue} message CounterValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CounterValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CounterValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.CounterValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.CounterValue} CounterValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CounterValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.CounterValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.doubleValue = reader.double();
                        break;
                    }
                case 2: {
                        message.intValue = reader.uint64();
                        break;
                    }
                case 3: {
                        message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.exemplar = $root.openmetrics.Exemplar.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CounterValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.CounterValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.CounterValue} CounterValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CounterValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CounterValue message.
         * @function verify
         * @memberof openmetrics.CounterValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CounterValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                properties.total = 1;
                if (typeof message.doubleValue !== "number")
                    return "doubleValue: number expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.total === 1)
                    return "total: multiple values";
                properties.total = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.exemplar != null && message.hasOwnProperty("exemplar")) {
                let error = $root.openmetrics.Exemplar.verify(message.exemplar);
                if (error)
                    return "exemplar." + error;
            }
            return null;
        };

        /**
         * Creates a CounterValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.CounterValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.CounterValue} CounterValue
         */
        CounterValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.CounterValue)
                return object;
            let message = new $root.openmetrics.CounterValue();
            if (object.doubleValue != null)
                message.doubleValue = Number(object.doubleValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = true;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber(true);
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".openmetrics.CounterValue.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.exemplar != null) {
                if (typeof object.exemplar !== "object")
                    throw TypeError(".openmetrics.CounterValue.exemplar: object expected");
                message.exemplar = $root.openmetrics.Exemplar.fromObject(object.exemplar);
            }
            return message;
        };

        /**
         * Creates a plain object from a CounterValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.CounterValue
         * @static
         * @param {openmetrics.CounterValue} message CounterValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CounterValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.created = null;
                object.exemplar = null;
            }
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (options.oneofs)
                    object.total = "doubleValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber(true) : message.intValue;
                if (options.oneofs)
                    object.total = "intValue";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.exemplar != null && message.hasOwnProperty("exemplar"))
                object.exemplar = $root.openmetrics.Exemplar.toObject(message.exemplar, options);
            return object;
        };

        /**
         * Converts this CounterValue to JSON.
         * @function toJSON
         * @memberof openmetrics.CounterValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CounterValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CounterValue
         * @function getTypeUrl
         * @memberof openmetrics.CounterValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CounterValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.CounterValue";
        };

        return CounterValue;
    })();

    openmetrics.HistogramValue = (function() {

        /**
         * Properties of a HistogramValue.
         * @memberof openmetrics
         * @interface IHistogramValue
         * @property {number|null} [doubleValue] HistogramValue doubleValue
         * @property {number|Long|null} [intValue] HistogramValue intValue
         * @property {number|Long|null} [count] HistogramValue count
         * @property {google.protobuf.ITimestamp|null} [created] HistogramValue created
         * @property {Array.<openmetrics.HistogramValue.IBucket>|null} [buckets] HistogramValue buckets
         */

        /**
         * Constructs a new HistogramValue.
         * @memberof openmetrics
         * @classdesc Represents a HistogramValue.
         * @implements IHistogramValue
         * @constructor
         * @param {openmetrics.IHistogramValue=} [properties] Properties to set
         */
        function HistogramValue(properties) {
            this.buckets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistogramValue doubleValue.
         * @member {number|null|undefined} doubleValue
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        HistogramValue.prototype.doubleValue = null;

        /**
         * HistogramValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        HistogramValue.prototype.intValue = null;

        /**
         * HistogramValue count.
         * @member {number|Long} count
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        HistogramValue.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HistogramValue created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        HistogramValue.prototype.created = null;

        /**
         * HistogramValue buckets.
         * @member {Array.<openmetrics.HistogramValue.IBucket>} buckets
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        HistogramValue.prototype.buckets = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * HistogramValue sum.
         * @member {"doubleValue"|"intValue"|undefined} sum
         * @memberof openmetrics.HistogramValue
         * @instance
         */
        Object.defineProperty(HistogramValue.prototype, "sum", {
            get: $util.oneOfGetter($oneOfFields = ["doubleValue", "intValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new HistogramValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {openmetrics.IHistogramValue=} [properties] Properties to set
         * @returns {openmetrics.HistogramValue} HistogramValue instance
         */
        HistogramValue.create = function create(properties) {
            return new HistogramValue(properties);
        };

        /**
         * Encodes the specified HistogramValue message. Does not implicitly {@link openmetrics.HistogramValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {openmetrics.IHistogramValue} message HistogramValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistogramValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.doubleValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intValue);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.count);
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.buckets != null && message.buckets.length)
                for (let i = 0; i < message.buckets.length; ++i)
                    $root.openmetrics.HistogramValue.Bucket.encode(message.buckets[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HistogramValue message, length delimited. Does not implicitly {@link openmetrics.HistogramValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {openmetrics.IHistogramValue} message HistogramValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistogramValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistogramValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.HistogramValue} HistogramValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistogramValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.HistogramValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.doubleValue = reader.double();
                        break;
                    }
                case 2: {
                        message.intValue = reader.int64();
                        break;
                    }
                case 3: {
                        message.count = reader.uint64();
                        break;
                    }
                case 4: {
                        message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        if (!(message.buckets && message.buckets.length))
                            message.buckets = [];
                        message.buckets.push($root.openmetrics.HistogramValue.Bucket.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistogramValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.HistogramValue} HistogramValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistogramValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistogramValue message.
         * @function verify
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistogramValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                properties.sum = 1;
                if (typeof message.doubleValue !== "number")
                    return "doubleValue: number expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.sum === 1)
                    return "sum: multiple values";
                properties.sum = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                    return "count: integer|Long expected";
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.buckets != null && message.hasOwnProperty("buckets")) {
                if (!Array.isArray(message.buckets))
                    return "buckets: array expected";
                for (let i = 0; i < message.buckets.length; ++i) {
                    let error = $root.openmetrics.HistogramValue.Bucket.verify(message.buckets[i]);
                    if (error)
                        return "buckets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HistogramValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.HistogramValue} HistogramValue
         */
        HistogramValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.HistogramValue)
                return object;
            let message = new $root.openmetrics.HistogramValue();
            if (object.doubleValue != null)
                message.doubleValue = Number(object.doubleValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            if (object.count != null)
                if ($util.Long)
                    (message.count = $util.Long.fromValue(object.count)).unsigned = true;
                else if (typeof object.count === "string")
                    message.count = parseInt(object.count, 10);
                else if (typeof object.count === "number")
                    message.count = object.count;
                else if (typeof object.count === "object")
                    message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber(true);
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".openmetrics.HistogramValue.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.buckets) {
                if (!Array.isArray(object.buckets))
                    throw TypeError(".openmetrics.HistogramValue.buckets: array expected");
                message.buckets = [];
                for (let i = 0; i < object.buckets.length; ++i) {
                    if (typeof object.buckets[i] !== "object")
                        throw TypeError(".openmetrics.HistogramValue.buckets: object expected");
                    message.buckets[i] = $root.openmetrics.HistogramValue.Bucket.fromObject(object.buckets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HistogramValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {openmetrics.HistogramValue} message HistogramValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistogramValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.buckets = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.count = options.longs === String ? "0" : 0;
                object.created = null;
            }
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (options.oneofs)
                    object.sum = "doubleValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
                if (options.oneofs)
                    object.sum = "intValue";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                if (typeof message.count === "number")
                    object.count = options.longs === String ? String(message.count) : message.count;
                else
                    object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber(true) : message.count;
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.buckets && message.buckets.length) {
                object.buckets = [];
                for (let j = 0; j < message.buckets.length; ++j)
                    object.buckets[j] = $root.openmetrics.HistogramValue.Bucket.toObject(message.buckets[j], options);
            }
            return object;
        };

        /**
         * Converts this HistogramValue to JSON.
         * @function toJSON
         * @memberof openmetrics.HistogramValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistogramValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HistogramValue
         * @function getTypeUrl
         * @memberof openmetrics.HistogramValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HistogramValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.HistogramValue";
        };

        HistogramValue.Bucket = (function() {

            /**
             * Properties of a Bucket.
             * @memberof openmetrics.HistogramValue
             * @interface IBucket
             * @property {number|Long|null} [count] Bucket count
             * @property {number|null} [upperBound] Bucket upperBound
             * @property {openmetrics.IExemplar|null} [exemplar] Bucket exemplar
             */

            /**
             * Constructs a new Bucket.
             * @memberof openmetrics.HistogramValue
             * @classdesc Represents a Bucket.
             * @implements IBucket
             * @constructor
             * @param {openmetrics.HistogramValue.IBucket=} [properties] Properties to set
             */
            function Bucket(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Bucket count.
             * @member {number|Long} count
             * @memberof openmetrics.HistogramValue.Bucket
             * @instance
             */
            Bucket.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bucket upperBound.
             * @member {number} upperBound
             * @memberof openmetrics.HistogramValue.Bucket
             * @instance
             */
            Bucket.prototype.upperBound = 0;

            /**
             * Bucket exemplar.
             * @member {openmetrics.IExemplar|null|undefined} exemplar
             * @memberof openmetrics.HistogramValue.Bucket
             * @instance
             */
            Bucket.prototype.exemplar = null;

            /**
             * Creates a new Bucket instance using the specified properties.
             * @function create
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {openmetrics.HistogramValue.IBucket=} [properties] Properties to set
             * @returns {openmetrics.HistogramValue.Bucket} Bucket instance
             */
            Bucket.create = function create(properties) {
                return new Bucket(properties);
            };

            /**
             * Encodes the specified Bucket message. Does not implicitly {@link openmetrics.HistogramValue.Bucket.verify|verify} messages.
             * @function encode
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {openmetrics.HistogramValue.IBucket} message Bucket message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Bucket.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.count);
                if (message.upperBound != null && Object.hasOwnProperty.call(message, "upperBound"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.upperBound);
                if (message.exemplar != null && Object.hasOwnProperty.call(message, "exemplar"))
                    $root.openmetrics.Exemplar.encode(message.exemplar, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Bucket message, length delimited. Does not implicitly {@link openmetrics.HistogramValue.Bucket.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {openmetrics.HistogramValue.IBucket} message Bucket message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Bucket.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Bucket message from the specified reader or buffer.
             * @function decode
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openmetrics.HistogramValue.Bucket} Bucket
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Bucket.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.HistogramValue.Bucket();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.count = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.upperBound = reader.double();
                            break;
                        }
                    case 3: {
                            message.exemplar = $root.openmetrics.Exemplar.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Bucket message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openmetrics.HistogramValue.Bucket} Bucket
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Bucket.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Bucket message.
             * @function verify
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Bucket.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                        return "count: integer|Long expected";
                if (message.upperBound != null && message.hasOwnProperty("upperBound"))
                    if (typeof message.upperBound !== "number")
                        return "upperBound: number expected";
                if (message.exemplar != null && message.hasOwnProperty("exemplar")) {
                    let error = $root.openmetrics.Exemplar.verify(message.exemplar);
                    if (error)
                        return "exemplar." + error;
                }
                return null;
            };

            /**
             * Creates a Bucket message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openmetrics.HistogramValue.Bucket} Bucket
             */
            Bucket.fromObject = function fromObject(object) {
                if (object instanceof $root.openmetrics.HistogramValue.Bucket)
                    return object;
                let message = new $root.openmetrics.HistogramValue.Bucket();
                if (object.count != null)
                    if ($util.Long)
                        (message.count = $util.Long.fromValue(object.count)).unsigned = true;
                    else if (typeof object.count === "string")
                        message.count = parseInt(object.count, 10);
                    else if (typeof object.count === "number")
                        message.count = object.count;
                    else if (typeof object.count === "object")
                        message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber(true);
                if (object.upperBound != null)
                    message.upperBound = Number(object.upperBound);
                if (object.exemplar != null) {
                    if (typeof object.exemplar !== "object")
                        throw TypeError(".openmetrics.HistogramValue.Bucket.exemplar: object expected");
                    message.exemplar = $root.openmetrics.Exemplar.fromObject(object.exemplar);
                }
                return message;
            };

            /**
             * Creates a plain object from a Bucket message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {openmetrics.HistogramValue.Bucket} message Bucket
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Bucket.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.count = options.longs === String ? "0" : 0;
                    object.upperBound = 0;
                    object.exemplar = null;
                }
                if (message.count != null && message.hasOwnProperty("count"))
                    if (typeof message.count === "number")
                        object.count = options.longs === String ? String(message.count) : message.count;
                    else
                        object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber(true) : message.count;
                if (message.upperBound != null && message.hasOwnProperty("upperBound"))
                    object.upperBound = options.json && !isFinite(message.upperBound) ? String(message.upperBound) : message.upperBound;
                if (message.exemplar != null && message.hasOwnProperty("exemplar"))
                    object.exemplar = $root.openmetrics.Exemplar.toObject(message.exemplar, options);
                return object;
            };

            /**
             * Converts this Bucket to JSON.
             * @function toJSON
             * @memberof openmetrics.HistogramValue.Bucket
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Bucket.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Bucket
             * @function getTypeUrl
             * @memberof openmetrics.HistogramValue.Bucket
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Bucket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/openmetrics.HistogramValue.Bucket";
            };

            return Bucket;
        })();

        return HistogramValue;
    })();

    openmetrics.Exemplar = (function() {

        /**
         * Properties of an Exemplar.
         * @memberof openmetrics
         * @interface IExemplar
         * @property {number|null} [value] Exemplar value
         * @property {google.protobuf.ITimestamp|null} [timestamp] Exemplar timestamp
         * @property {Array.<openmetrics.ILabel>|null} [label] Exemplar label
         */

        /**
         * Constructs a new Exemplar.
         * @memberof openmetrics
         * @classdesc Represents an Exemplar.
         * @implements IExemplar
         * @constructor
         * @param {openmetrics.IExemplar=} [properties] Properties to set
         */
        function Exemplar(properties) {
            this.label = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Exemplar value.
         * @member {number} value
         * @memberof openmetrics.Exemplar
         * @instance
         */
        Exemplar.prototype.value = 0;

        /**
         * Exemplar timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof openmetrics.Exemplar
         * @instance
         */
        Exemplar.prototype.timestamp = null;

        /**
         * Exemplar label.
         * @member {Array.<openmetrics.ILabel>} label
         * @memberof openmetrics.Exemplar
         * @instance
         */
        Exemplar.prototype.label = $util.emptyArray;

        /**
         * Creates a new Exemplar instance using the specified properties.
         * @function create
         * @memberof openmetrics.Exemplar
         * @static
         * @param {openmetrics.IExemplar=} [properties] Properties to set
         * @returns {openmetrics.Exemplar} Exemplar instance
         */
        Exemplar.create = function create(properties) {
            return new Exemplar(properties);
        };

        /**
         * Encodes the specified Exemplar message. Does not implicitly {@link openmetrics.Exemplar.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.Exemplar
         * @static
         * @param {openmetrics.IExemplar} message Exemplar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Exemplar.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.label != null && message.label.length)
                for (let i = 0; i < message.label.length; ++i)
                    $root.openmetrics.Label.encode(message.label[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Exemplar message, length delimited. Does not implicitly {@link openmetrics.Exemplar.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.Exemplar
         * @static
         * @param {openmetrics.IExemplar} message Exemplar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Exemplar.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Exemplar message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.Exemplar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.Exemplar} Exemplar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Exemplar.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.Exemplar();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.double();
                        break;
                    }
                case 2: {
                        message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.label && message.label.length))
                            message.label = [];
                        message.label.push($root.openmetrics.Label.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Exemplar message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.Exemplar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.Exemplar} Exemplar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Exemplar.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Exemplar message.
         * @function verify
         * @memberof openmetrics.Exemplar
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Exemplar.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "number")
                    return "value: number expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                let error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                if (error)
                    return "timestamp." + error;
            }
            if (message.label != null && message.hasOwnProperty("label")) {
                if (!Array.isArray(message.label))
                    return "label: array expected";
                for (let i = 0; i < message.label.length; ++i) {
                    let error = $root.openmetrics.Label.verify(message.label[i]);
                    if (error)
                        return "label." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Exemplar message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.Exemplar
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.Exemplar} Exemplar
         */
        Exemplar.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.Exemplar)
                return object;
            let message = new $root.openmetrics.Exemplar();
            if (object.value != null)
                message.value = Number(object.value);
            if (object.timestamp != null) {
                if (typeof object.timestamp !== "object")
                    throw TypeError(".openmetrics.Exemplar.timestamp: object expected");
                message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
            }
            if (object.label) {
                if (!Array.isArray(object.label))
                    throw TypeError(".openmetrics.Exemplar.label: array expected");
                message.label = [];
                for (let i = 0; i < object.label.length; ++i) {
                    if (typeof object.label[i] !== "object")
                        throw TypeError(".openmetrics.Exemplar.label: object expected");
                    message.label[i] = $root.openmetrics.Label.fromObject(object.label[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an Exemplar message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.Exemplar
         * @static
         * @param {openmetrics.Exemplar} message Exemplar
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Exemplar.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.label = [];
            if (options.defaults) {
                object.value = 0;
                object.timestamp = null;
            }
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
            if (message.label && message.label.length) {
                object.label = [];
                for (let j = 0; j < message.label.length; ++j)
                    object.label[j] = $root.openmetrics.Label.toObject(message.label[j], options);
            }
            return object;
        };

        /**
         * Converts this Exemplar to JSON.
         * @function toJSON
         * @memberof openmetrics.Exemplar
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Exemplar.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Exemplar
         * @function getTypeUrl
         * @memberof openmetrics.Exemplar
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Exemplar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.Exemplar";
        };

        return Exemplar;
    })();

    openmetrics.StateSetValue = (function() {

        /**
         * Properties of a StateSetValue.
         * @memberof openmetrics
         * @interface IStateSetValue
         * @property {Array.<openmetrics.StateSetValue.IState>|null} [states] StateSetValue states
         */

        /**
         * Constructs a new StateSetValue.
         * @memberof openmetrics
         * @classdesc Represents a StateSetValue.
         * @implements IStateSetValue
         * @constructor
         * @param {openmetrics.IStateSetValue=} [properties] Properties to set
         */
        function StateSetValue(properties) {
            this.states = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StateSetValue states.
         * @member {Array.<openmetrics.StateSetValue.IState>} states
         * @memberof openmetrics.StateSetValue
         * @instance
         */
        StateSetValue.prototype.states = $util.emptyArray;

        /**
         * Creates a new StateSetValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {openmetrics.IStateSetValue=} [properties] Properties to set
         * @returns {openmetrics.StateSetValue} StateSetValue instance
         */
        StateSetValue.create = function create(properties) {
            return new StateSetValue(properties);
        };

        /**
         * Encodes the specified StateSetValue message. Does not implicitly {@link openmetrics.StateSetValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {openmetrics.IStateSetValue} message StateSetValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StateSetValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.states != null && message.states.length)
                for (let i = 0; i < message.states.length; ++i)
                    $root.openmetrics.StateSetValue.State.encode(message.states[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StateSetValue message, length delimited. Does not implicitly {@link openmetrics.StateSetValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {openmetrics.IStateSetValue} message StateSetValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StateSetValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StateSetValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.StateSetValue} StateSetValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StateSetValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.StateSetValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.states && message.states.length))
                            message.states = [];
                        message.states.push($root.openmetrics.StateSetValue.State.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StateSetValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.StateSetValue} StateSetValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StateSetValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StateSetValue message.
         * @function verify
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StateSetValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.states != null && message.hasOwnProperty("states")) {
                if (!Array.isArray(message.states))
                    return "states: array expected";
                for (let i = 0; i < message.states.length; ++i) {
                    let error = $root.openmetrics.StateSetValue.State.verify(message.states[i]);
                    if (error)
                        return "states." + error;
                }
            }
            return null;
        };

        /**
         * Creates a StateSetValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.StateSetValue} StateSetValue
         */
        StateSetValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.StateSetValue)
                return object;
            let message = new $root.openmetrics.StateSetValue();
            if (object.states) {
                if (!Array.isArray(object.states))
                    throw TypeError(".openmetrics.StateSetValue.states: array expected");
                message.states = [];
                for (let i = 0; i < object.states.length; ++i) {
                    if (typeof object.states[i] !== "object")
                        throw TypeError(".openmetrics.StateSetValue.states: object expected");
                    message.states[i] = $root.openmetrics.StateSetValue.State.fromObject(object.states[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a StateSetValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {openmetrics.StateSetValue} message StateSetValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StateSetValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.states = [];
            if (message.states && message.states.length) {
                object.states = [];
                for (let j = 0; j < message.states.length; ++j)
                    object.states[j] = $root.openmetrics.StateSetValue.State.toObject(message.states[j], options);
            }
            return object;
        };

        /**
         * Converts this StateSetValue to JSON.
         * @function toJSON
         * @memberof openmetrics.StateSetValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StateSetValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StateSetValue
         * @function getTypeUrl
         * @memberof openmetrics.StateSetValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StateSetValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.StateSetValue";
        };

        StateSetValue.State = (function() {

            /**
             * Properties of a State.
             * @memberof openmetrics.StateSetValue
             * @interface IState
             * @property {boolean|null} [enabled] State enabled
             * @property {string|null} [name] State name
             */

            /**
             * Constructs a new State.
             * @memberof openmetrics.StateSetValue
             * @classdesc Represents a State.
             * @implements IState
             * @constructor
             * @param {openmetrics.StateSetValue.IState=} [properties] Properties to set
             */
            function State(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * State enabled.
             * @member {boolean} enabled
             * @memberof openmetrics.StateSetValue.State
             * @instance
             */
            State.prototype.enabled = false;

            /**
             * State name.
             * @member {string} name
             * @memberof openmetrics.StateSetValue.State
             * @instance
             */
            State.prototype.name = "";

            /**
             * Creates a new State instance using the specified properties.
             * @function create
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {openmetrics.StateSetValue.IState=} [properties] Properties to set
             * @returns {openmetrics.StateSetValue.State} State instance
             */
            State.create = function create(properties) {
                return new State(properties);
            };

            /**
             * Encodes the specified State message. Does not implicitly {@link openmetrics.StateSetValue.State.verify|verify} messages.
             * @function encode
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {openmetrics.StateSetValue.IState} message State message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            State.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified State message, length delimited. Does not implicitly {@link openmetrics.StateSetValue.State.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {openmetrics.StateSetValue.IState} message State message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            State.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a State message from the specified reader or buffer.
             * @function decode
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openmetrics.StateSetValue.State} State
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            State.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.StateSetValue.State();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.enabled = reader.bool();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a State message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openmetrics.StateSetValue.State} State
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            State.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a State message.
             * @function verify
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            State.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.enabled != null && message.hasOwnProperty("enabled"))
                    if (typeof message.enabled !== "boolean")
                        return "enabled: boolean expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a State message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openmetrics.StateSetValue.State} State
             */
            State.fromObject = function fromObject(object) {
                if (object instanceof $root.openmetrics.StateSetValue.State)
                    return object;
                let message = new $root.openmetrics.StateSetValue.State();
                if (object.enabled != null)
                    message.enabled = Boolean(object.enabled);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a State message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {openmetrics.StateSetValue.State} message State
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            State.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.enabled = false;
                    object.name = "";
                }
                if (message.enabled != null && message.hasOwnProperty("enabled"))
                    object.enabled = message.enabled;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this State to JSON.
             * @function toJSON
             * @memberof openmetrics.StateSetValue.State
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            State.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for State
             * @function getTypeUrl
             * @memberof openmetrics.StateSetValue.State
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            State.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/openmetrics.StateSetValue.State";
            };

            return State;
        })();

        return StateSetValue;
    })();

    openmetrics.InfoValue = (function() {

        /**
         * Properties of an InfoValue.
         * @memberof openmetrics
         * @interface IInfoValue
         * @property {Array.<openmetrics.ILabel>|null} [info] InfoValue info
         */

        /**
         * Constructs a new InfoValue.
         * @memberof openmetrics
         * @classdesc Represents an InfoValue.
         * @implements IInfoValue
         * @constructor
         * @param {openmetrics.IInfoValue=} [properties] Properties to set
         */
        function InfoValue(properties) {
            this.info = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InfoValue info.
         * @member {Array.<openmetrics.ILabel>} info
         * @memberof openmetrics.InfoValue
         * @instance
         */
        InfoValue.prototype.info = $util.emptyArray;

        /**
         * Creates a new InfoValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.InfoValue
         * @static
         * @param {openmetrics.IInfoValue=} [properties] Properties to set
         * @returns {openmetrics.InfoValue} InfoValue instance
         */
        InfoValue.create = function create(properties) {
            return new InfoValue(properties);
        };

        /**
         * Encodes the specified InfoValue message. Does not implicitly {@link openmetrics.InfoValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.InfoValue
         * @static
         * @param {openmetrics.IInfoValue} message InfoValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InfoValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && message.info.length)
                for (let i = 0; i < message.info.length; ++i)
                    $root.openmetrics.Label.encode(message.info[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InfoValue message, length delimited. Does not implicitly {@link openmetrics.InfoValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.InfoValue
         * @static
         * @param {openmetrics.IInfoValue} message InfoValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InfoValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InfoValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.InfoValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.InfoValue} InfoValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InfoValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.InfoValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.info && message.info.length))
                            message.info = [];
                        message.info.push($root.openmetrics.Label.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InfoValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.InfoValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.InfoValue} InfoValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InfoValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InfoValue message.
         * @function verify
         * @memberof openmetrics.InfoValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InfoValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.info != null && message.hasOwnProperty("info")) {
                if (!Array.isArray(message.info))
                    return "info: array expected";
                for (let i = 0; i < message.info.length; ++i) {
                    let error = $root.openmetrics.Label.verify(message.info[i]);
                    if (error)
                        return "info." + error;
                }
            }
            return null;
        };

        /**
         * Creates an InfoValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.InfoValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.InfoValue} InfoValue
         */
        InfoValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.InfoValue)
                return object;
            let message = new $root.openmetrics.InfoValue();
            if (object.info) {
                if (!Array.isArray(object.info))
                    throw TypeError(".openmetrics.InfoValue.info: array expected");
                message.info = [];
                for (let i = 0; i < object.info.length; ++i) {
                    if (typeof object.info[i] !== "object")
                        throw TypeError(".openmetrics.InfoValue.info: object expected");
                    message.info[i] = $root.openmetrics.Label.fromObject(object.info[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an InfoValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.InfoValue
         * @static
         * @param {openmetrics.InfoValue} message InfoValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InfoValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.info = [];
            if (message.info && message.info.length) {
                object.info = [];
                for (let j = 0; j < message.info.length; ++j)
                    object.info[j] = $root.openmetrics.Label.toObject(message.info[j], options);
            }
            return object;
        };

        /**
         * Converts this InfoValue to JSON.
         * @function toJSON
         * @memberof openmetrics.InfoValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InfoValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InfoValue
         * @function getTypeUrl
         * @memberof openmetrics.InfoValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InfoValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.InfoValue";
        };

        return InfoValue;
    })();

    openmetrics.SummaryValue = (function() {

        /**
         * Properties of a SummaryValue.
         * @memberof openmetrics
         * @interface ISummaryValue
         * @property {number|null} [doubleValue] SummaryValue doubleValue
         * @property {number|Long|null} [intValue] SummaryValue intValue
         * @property {number|Long|null} [count] SummaryValue count
         * @property {google.protobuf.ITimestamp|null} [created] SummaryValue created
         * @property {Array.<openmetrics.SummaryValue.IQuantile>|null} [quantile] SummaryValue quantile
         */

        /**
         * Constructs a new SummaryValue.
         * @memberof openmetrics
         * @classdesc Represents a SummaryValue.
         * @implements ISummaryValue
         * @constructor
         * @param {openmetrics.ISummaryValue=} [properties] Properties to set
         */
        function SummaryValue(properties) {
            this.quantile = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SummaryValue doubleValue.
         * @member {number|null|undefined} doubleValue
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        SummaryValue.prototype.doubleValue = null;

        /**
         * SummaryValue intValue.
         * @member {number|Long|null|undefined} intValue
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        SummaryValue.prototype.intValue = null;

        /**
         * SummaryValue count.
         * @member {number|Long} count
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        SummaryValue.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SummaryValue created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        SummaryValue.prototype.created = null;

        /**
         * SummaryValue quantile.
         * @member {Array.<openmetrics.SummaryValue.IQuantile>} quantile
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        SummaryValue.prototype.quantile = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SummaryValue sum.
         * @member {"doubleValue"|"intValue"|undefined} sum
         * @memberof openmetrics.SummaryValue
         * @instance
         */
        Object.defineProperty(SummaryValue.prototype, "sum", {
            get: $util.oneOfGetter($oneOfFields = ["doubleValue", "intValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SummaryValue instance using the specified properties.
         * @function create
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {openmetrics.ISummaryValue=} [properties] Properties to set
         * @returns {openmetrics.SummaryValue} SummaryValue instance
         */
        SummaryValue.create = function create(properties) {
            return new SummaryValue(properties);
        };

        /**
         * Encodes the specified SummaryValue message. Does not implicitly {@link openmetrics.SummaryValue.verify|verify} messages.
         * @function encode
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {openmetrics.ISummaryValue} message SummaryValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SummaryValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.doubleValue);
            if (message.intValue != null && Object.hasOwnProperty.call(message, "intValue"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intValue);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.count);
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.quantile != null && message.quantile.length)
                for (let i = 0; i < message.quantile.length; ++i)
                    $root.openmetrics.SummaryValue.Quantile.encode(message.quantile[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SummaryValue message, length delimited. Does not implicitly {@link openmetrics.SummaryValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {openmetrics.ISummaryValue} message SummaryValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SummaryValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SummaryValue message from the specified reader or buffer.
         * @function decode
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {openmetrics.SummaryValue} SummaryValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SummaryValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.SummaryValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.doubleValue = reader.double();
                        break;
                    }
                case 2: {
                        message.intValue = reader.int64();
                        break;
                    }
                case 3: {
                        message.count = reader.uint64();
                        break;
                    }
                case 4: {
                        message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        if (!(message.quantile && message.quantile.length))
                            message.quantile = [];
                        message.quantile.push($root.openmetrics.SummaryValue.Quantile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SummaryValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {openmetrics.SummaryValue} SummaryValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SummaryValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SummaryValue message.
         * @function verify
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SummaryValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                properties.sum = 1;
                if (typeof message.doubleValue !== "number")
                    return "doubleValue: number expected";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (properties.sum === 1)
                    return "sum: multiple values";
                properties.sum = 1;
                if (!$util.isInteger(message.intValue) && !(message.intValue && $util.isInteger(message.intValue.low) && $util.isInteger(message.intValue.high)))
                    return "intValue: integer|Long expected";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                    return "count: integer|Long expected";
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.quantile != null && message.hasOwnProperty("quantile")) {
                if (!Array.isArray(message.quantile))
                    return "quantile: array expected";
                for (let i = 0; i < message.quantile.length; ++i) {
                    let error = $root.openmetrics.SummaryValue.Quantile.verify(message.quantile[i]);
                    if (error)
                        return "quantile." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SummaryValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {openmetrics.SummaryValue} SummaryValue
         */
        SummaryValue.fromObject = function fromObject(object) {
            if (object instanceof $root.openmetrics.SummaryValue)
                return object;
            let message = new $root.openmetrics.SummaryValue();
            if (object.doubleValue != null)
                message.doubleValue = Number(object.doubleValue);
            if (object.intValue != null)
                if ($util.Long)
                    (message.intValue = $util.Long.fromValue(object.intValue)).unsigned = false;
                else if (typeof object.intValue === "string")
                    message.intValue = parseInt(object.intValue, 10);
                else if (typeof object.intValue === "number")
                    message.intValue = object.intValue;
                else if (typeof object.intValue === "object")
                    message.intValue = new $util.LongBits(object.intValue.low >>> 0, object.intValue.high >>> 0).toNumber();
            if (object.count != null)
                if ($util.Long)
                    (message.count = $util.Long.fromValue(object.count)).unsigned = true;
                else if (typeof object.count === "string")
                    message.count = parseInt(object.count, 10);
                else if (typeof object.count === "number")
                    message.count = object.count;
                else if (typeof object.count === "object")
                    message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber(true);
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".openmetrics.SummaryValue.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.quantile) {
                if (!Array.isArray(object.quantile))
                    throw TypeError(".openmetrics.SummaryValue.quantile: array expected");
                message.quantile = [];
                for (let i = 0; i < object.quantile.length; ++i) {
                    if (typeof object.quantile[i] !== "object")
                        throw TypeError(".openmetrics.SummaryValue.quantile: object expected");
                    message.quantile[i] = $root.openmetrics.SummaryValue.Quantile.fromObject(object.quantile[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SummaryValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {openmetrics.SummaryValue} message SummaryValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SummaryValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.quantile = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.count = options.longs === String ? "0" : 0;
                object.created = null;
            }
            if (message.doubleValue != null && message.hasOwnProperty("doubleValue")) {
                object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (options.oneofs)
                    object.sum = "doubleValue";
            }
            if (message.intValue != null && message.hasOwnProperty("intValue")) {
                if (typeof message.intValue === "number")
                    object.intValue = options.longs === String ? String(message.intValue) : message.intValue;
                else
                    object.intValue = options.longs === String ? $util.Long.prototype.toString.call(message.intValue) : options.longs === Number ? new $util.LongBits(message.intValue.low >>> 0, message.intValue.high >>> 0).toNumber() : message.intValue;
                if (options.oneofs)
                    object.sum = "intValue";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                if (typeof message.count === "number")
                    object.count = options.longs === String ? String(message.count) : message.count;
                else
                    object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber(true) : message.count;
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.quantile && message.quantile.length) {
                object.quantile = [];
                for (let j = 0; j < message.quantile.length; ++j)
                    object.quantile[j] = $root.openmetrics.SummaryValue.Quantile.toObject(message.quantile[j], options);
            }
            return object;
        };

        /**
         * Converts this SummaryValue to JSON.
         * @function toJSON
         * @memberof openmetrics.SummaryValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SummaryValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SummaryValue
         * @function getTypeUrl
         * @memberof openmetrics.SummaryValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SummaryValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/openmetrics.SummaryValue";
        };

        SummaryValue.Quantile = (function() {

            /**
             * Properties of a Quantile.
             * @memberof openmetrics.SummaryValue
             * @interface IQuantile
             * @property {number|null} [quantile] Quantile quantile
             * @property {number|null} [value] Quantile value
             */

            /**
             * Constructs a new Quantile.
             * @memberof openmetrics.SummaryValue
             * @classdesc Represents a Quantile.
             * @implements IQuantile
             * @constructor
             * @param {openmetrics.SummaryValue.IQuantile=} [properties] Properties to set
             */
            function Quantile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Quantile quantile.
             * @member {number} quantile
             * @memberof openmetrics.SummaryValue.Quantile
             * @instance
             */
            Quantile.prototype.quantile = 0;

            /**
             * Quantile value.
             * @member {number} value
             * @memberof openmetrics.SummaryValue.Quantile
             * @instance
             */
            Quantile.prototype.value = 0;

            /**
             * Creates a new Quantile instance using the specified properties.
             * @function create
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {openmetrics.SummaryValue.IQuantile=} [properties] Properties to set
             * @returns {openmetrics.SummaryValue.Quantile} Quantile instance
             */
            Quantile.create = function create(properties) {
                return new Quantile(properties);
            };

            /**
             * Encodes the specified Quantile message. Does not implicitly {@link openmetrics.SummaryValue.Quantile.verify|verify} messages.
             * @function encode
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {openmetrics.SummaryValue.IQuantile} message Quantile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Quantile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quantile != null && Object.hasOwnProperty.call(message, "quantile"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.quantile);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.value);
                return writer;
            };

            /**
             * Encodes the specified Quantile message, length delimited. Does not implicitly {@link openmetrics.SummaryValue.Quantile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {openmetrics.SummaryValue.IQuantile} message Quantile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Quantile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Quantile message from the specified reader or buffer.
             * @function decode
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openmetrics.SummaryValue.Quantile} Quantile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Quantile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.openmetrics.SummaryValue.Quantile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.quantile = reader.double();
                            break;
                        }
                    case 2: {
                            message.value = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Quantile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openmetrics.SummaryValue.Quantile} Quantile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Quantile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Quantile message.
             * @function verify
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Quantile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quantile != null && message.hasOwnProperty("quantile"))
                    if (typeof message.quantile !== "number")
                        return "quantile: number expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };

            /**
             * Creates a Quantile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openmetrics.SummaryValue.Quantile} Quantile
             */
            Quantile.fromObject = function fromObject(object) {
                if (object instanceof $root.openmetrics.SummaryValue.Quantile)
                    return object;
                let message = new $root.openmetrics.SummaryValue.Quantile();
                if (object.quantile != null)
                    message.quantile = Number(object.quantile);
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a Quantile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {openmetrics.SummaryValue.Quantile} message Quantile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Quantile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.quantile = 0;
                    object.value = 0;
                }
                if (message.quantile != null && message.hasOwnProperty("quantile"))
                    object.quantile = options.json && !isFinite(message.quantile) ? String(message.quantile) : message.quantile;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Quantile to JSON.
             * @function toJSON
             * @memberof openmetrics.SummaryValue.Quantile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Quantile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Quantile
             * @function getTypeUrl
             * @memberof openmetrics.SummaryValue.Quantile
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Quantile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/openmetrics.SummaryValue.Quantile";
            };

            return Quantile;
        })();

        return SummaryValue;
    })();

    return openmetrics;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
